import { useEffect } from 'react'
import getOfferListKey from 'lib/offer/offerListKey'
import { pushOfferToListBottom } from 'actions/OfferActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

/**
 * Pushes a sold out offer to the bottom of the list if it's not already adjusted
 * @param offerId
 * @param filters
 * @param soldOut
 */
export function useOfferSoldOutPushDown(offerId: string, filters: App.OfferListFilters | undefined, soldOut: boolean) {
  const dispatch = useAppDispatch()
  const offerListKey = getOfferListKey(filters ?? {})
  const posAdjusted = useAppSelector(state => state.offer.offerListAdjustedOffers[offerListKey]?.[offerId])
  const isPropertySearch = filters?.propertyId && !filters?.searchNearby

  useEffect(() => {
    if (filters && !posAdjusted && soldOut && !isPropertySearch) {
      const listKey = getOfferListKey(filters)
      dispatch(pushOfferToListBottom(offerId, listKey, 'sold-out'))
    }
  }, [dispatch, filters, isPropertySearch, offerId, posAdjusted, soldOut])
}
