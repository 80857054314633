import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import { UPDATE_APPLE_PAY_AVAILABLE, UPDATE_GOOGLE_PAY_AVAILABLE, API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS } from 'actions/actionConstants'
import { FETCH_MERCHANT_FEE_CONFIG, FETCH_PAYTO_SUPPORTED_BANKS, FETCH_DEPOSIT_CONFIG } from 'actions/apiActionConstants'

export const initialSystemState: App.SystemState = {
  appBannerCookie: false,
  customerPortalURL: undefined,
  originalCanonicalURL: undefined,
  headlessMode: false,
  canPayWithApplePay: false,
  canPayWithGooglePay: false,
  storeMode: false,
  referrer: '',
  userAgent: {
    osName: '',
    browserName: '',
    rawUserAgentString: '',
  },
  previewMode: false,
  merchantFeeConfigs: [],
  payToSupportedBanks: [],
  depositConfigs: undefined,
}

const apiRequests = reducerSwitch<App.SystemState>({
  [FETCH_MERCHANT_FEE_CONFIG]: () => ({
    merchantFeeConfigs: [],
  }),
  [FETCH_PAYTO_SUPPORTED_BANKS]: () => ({
    payToSupportedBanks: [],
  }),
  [FETCH_DEPOSIT_CONFIG]: () => ({
    depositConfigs: undefined,
  }),
})

const apiSuccesses = reducerSwitch<App.SystemState>({
  [FETCH_MERCHANT_FEE_CONFIG]: (_state, action) => ({
    merchantFeeConfigs: action.data,
  }),
  [FETCH_PAYTO_SUPPORTED_BANKS]: (_state, action) => ({
    payToSupportedBanks: action.data,
  }),
  [FETCH_DEPOSIT_CONFIG]: (_state, action) => ({
    depositConfigs: action.data,
  }),
})

const apiFailures = reducerSwitch<App.SystemState>({
})

export default createReducer<App.SystemState>(initialSystemState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),

  [UPDATE_APPLE_PAY_AVAILABLE]: (_state, action) => ({
    canPayWithApplePay: action.value,
  }),

  [UPDATE_GOOGLE_PAY_AVAILABLE]: (_state, action) => ({
    canPayWithGooglePay: action.value,
  }),
})
