import React, { useEffect, useState } from 'react'
import SessionTimeoutModal from './SessionTimeoutModal'
import moment from 'moment'
import config from 'constants/config'
import { getCookies, setCookie } from 'lib/web/cookieUtils'

const FLIGHTS_SESSION_EXPIRATION_TIME_IN_MINUTES = 25

export const DEFAULT_FLIGHTS_TIMER_KEY = 'flights-timer-expiration-timestamp'

const COOKIE_THIRTY_MINUTE_TTL = 3600
const COOKIE_PATH = '/'
const COOKIE_SAME_SITE = 'Lax'

export function resetFlightsTimer() {
  const expirationTimestamp = moment().add(FLIGHTS_SESSION_EXPIRATION_TIME_IN_MINUTES, 'minutes').toDate().getTime()
  setCookie(DEFAULT_FLIGHTS_TIMER_KEY, expirationTimestamp.toString(), COOKIE_THIRTY_MINUTE_TTL, COOKIE_PATH, COOKIE_SAME_SITE)
}

export function expireFlightsTimer() {
  setCookie(DEFAULT_FLIGHTS_TIMER_KEY, '0', COOKIE_THIRTY_MINUTE_TTL, COOKIE_PATH, COOKIE_SAME_SITE)
}

export function getFlightsTimerExpirationTimestamp() {
  const cookies = getCookies()
  const timestamp = Number(cookies[DEFAULT_FLIGHTS_TIMER_KEY])
  return isNaN(timestamp) ? 0 : timestamp
}

interface Props {
  backBtnTitle?: string;
  onReload: () => void;
  onGoBack?: () => void;
  startFlightsTimer: boolean;
}

export function hasFlightSessionExpired() {
  if (!config.ENABLE_FLIGHTS_SESSION_TIMER) {
    // no timer, not expired
    return false
  }
  return !getFlightsTimerExpirationTimestamp() || moment(getFlightsTimerExpirationTimestamp()).isBefore()
}

function FlightsSessionTimer(props: Props) {
  const [expired, setExpired] = useState<boolean>()

  useEffect(() => {
    if (props.startFlightsTimer) {
      const interval = setInterval(() => {
        setExpired(hasFlightSessionExpired())
      }, 1000)

      return () => clearInterval(interval)
    } else {
      setExpired(false)
    }
    // This will update the session storage every second
  }, [props.startFlightsTimer])

  return (
    <SessionTimeoutModal
      {...props}
      isOpen={!!(props.startFlightsTimer && expired)}
    />
  )
}

export default FlightsSessionTimer
