import { useMemo } from 'react'
import extractTourV2VariationDetails, { formatTourV2VariationDetailCounts } from 'lib/tours/extractTourV2VariationDetails'

function useTourV2VariationDetails(
  variation?: Tours.TourV2OfferVariation,
): ReturnType<typeof extractTourV2VariationDetails | any> {
  return useMemo(() =>
    variation ? extractTourV2VariationDetails(variation) : {}, [variation])
}

export function useFormattedTourV2VariationDetailsCount(variation: Tours.TourV2OfferVariation) {
  return useMemo(() => formatTourV2VariationDetailCounts(variation), [variation])
}

export default useTourV2VariationDetails
