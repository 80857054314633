import { Interceptor } from 'api/requestUtils'

export default function browserErrorLogger(): Interceptor {
  return f => f.catch((error: any) => {
    // eslint-disable-next-line no-console
    console.error('API Error', error)

    throw error
  })
}
