import React, { useReducer } from 'react'
import SearchWidget from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import FlightSearchWidgetStateProvider from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateProvider'
import flightSearchWidgetStateReducer, { initialState } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import { getFlightsRecentSearch } from 'lib/flights/flightUtils'

function GlobalFlightsSearchTabContent() {
  const [state, stateDispatch] = useReducer(flightSearchWidgetStateReducer, {
    ...initialState,
  })

  return <FlightSearchWidgetStateProvider state={state} dispatch={stateDispatch}>
    <SearchWidget searchData={{ lastSearch: getFlightsRecentSearch() }} placement="common-search" />
  </FlightSearchWidgetStateProvider>
}

export default GlobalFlightsSearchTabContent
