import React from 'react'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import { ImageParams } from 'components/Common/ResponsiveImage'

const MEDIA_IMAGE_PARAMS: ImageParams = {
  quality: 'eco',
  mobileAspectRatio: '16:9',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '16:11',
  largeDesktopAspectRatio: '16:11',
  mobileWidth: '100vw',
  tabletWidth: '100vw',
  desktopWidth: '500px',
  largeDesktopWidth: '500px',
}

interface Props {
  images: Array<App.Image>;
  isVelocityEnabled: boolean;
  productType?: App.ProductType;
  className?: string;
  onImageChange?: (idx: number, image?: App.Image) => void
  onImageLoad?: (idx: number, image?: App.Image) => void
}

function CruiseTileImageCarousel(props: Props) {
  const {
    images,
    isVelocityEnabled,
    productType,
    className,
    onImageChange,
    onImageLoad,
  } = props

  return <ImageCarousel
    topLeft={<LabelGroup>
      {productType && <ProductTypeLabel productType={productType} />}
      {isVelocityEnabled && <PartnershipLabel canEarn={false} canUse />}
    </LabelGroup>}
    images={images}
    imageParams={MEDIA_IMAGE_PARAMS}
    className={className}
    onImageChange={onImageChange}
    onImageLoad={onImageLoad}
  />
}

export default CruiseTileImageCarousel
