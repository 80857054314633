import createSelector from 'lib/web/createSelector'
import { EmptyArray, sortBy, last, sum } from 'lib/array/arrayUtils'
import { isCruiseItem } from 'lib/checkout/checkoutUtils'
import { CheckoutPageId } from 'checkout/constants/pages'
import {
  getSessionByCruiseItem,
  getCabinListByCruiseItem,
  getRateListByCruiseItem,
  getCabinDetailsByCruiseItem,
  getRateDetailsByCruiseItem,
  getCabinSelectionByCruiseItem,
  getCabinPricingByCruiseItem,
  getCabinReleaseByCruiseItem,
  getBookingCabinsByCategory,
  buildCabinsWithPrice,
  getCabinLowestPrice,
  setDeckLowestPrice,
} from 'checkout/lib/utils/cruises/booking'

// Cart Data Selectors
export const getCruiseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.CruiseItem> => items.filter(isCruiseItem),
)

export const getSelectedCruiseItem = createSelector(
  (state: App.State) => state.cruise.multiBooking.selectedCruiseItemId,
  getCruiseItems,
  (selectedCruiseItemId, cruiseItems): App.Checkout.CruiseItem => {
    const cruiseItem = cruiseItems.find(item => item.itemId === selectedCruiseItemId)
    return cruiseItem ?? cruiseItems[0]
  },
)

// Checkout validation selectors
export const getIsCruiseCheckoutReady = createSelector(
  getCruiseItems,
  (state: App.State) => state.cruise.cruiseOffers,
  (state: App.State) => state.cruise.cruiseDeparture,
  (state: App.State) => state.cruise.multiBooking.cabinPricing,
  (state: App.State) => state.checkout.processing,
  (cruiseItems, offers, departureMap, allCabinPricingData, checkoutProcessing): boolean => {
    if (!cruiseItems.length) return false

    const { offerId, departureId } = cruiseItems[0]
    const offer = offers[offerId]
    const departure = departureMap[departureId]
    const isCabinPricingLoading = Object.values(allCabinPricingData).filter(Boolean).some(data => data.loading)

    return (
      !!offer &&
      !!departure &&
      !isCabinPricingLoading &&
      !checkoutProcessing // prevent user from changing step while processing the purchase
    )
  },
)

// Offer Data Selectors
export const getBookingCruiseOffer = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.cruiseOffers,
  (cruiseItem, offers): App.CruiseOffer => offers[cruiseItem?.offerId],
)

export const getBookingDepartures = createSelector(
  getBookingCruiseOffer,
  (offer): Array<App.CruiseDepartureDetails> => {
    return offer?.departures ? Object.values(offer.departures).flat() : EmptyArray
  },
)

export const getBookingDeparture = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.cruiseDeparture,
  (cruiseItem, departureMap): App.CruiseDepartureView => {
    return departureMap[cruiseItem?.departureId]
  },
)

export const getBookingCabinPositions = createSelector(
  (state: App.State) => state.cruise.deckSelections,
  getSelectedCruiseItem,
  (deckSelections, cruiseItem): Array<App.ShipCabinPosition> => {
    if (!deckSelections || !cruiseItem?.deckId) return []
    const deckDetails = deckSelections[cruiseItem.deckId]
    return deckDetails?.shipCabinPosition ?? EmptyArray
  },
)

export const getBookingCustomShipCategoryGroups = createSelector(
  getBookingCruiseOffer,
  (state: App.State) => state.cruise.ships,
  (offer, ships): Array<Cruises.ShipCabinCategoriesGroup> => {
    return ships[offer.ship.id]?.cabinCategoriesGroups ?? EmptyArray
  },
)

/* BOOKING DATA - SELECTED CRUISE ITEM
 * In booking flow with multi cabins, the user will have one cabin (cruise item) selected at a time
 * These selectors are used to get the booking data (rates, cabins...) for the current selected cruise item.
 */
export const getBookingSessionData = createSelector(
  getSelectedCruiseItem,
  getCruiseItems,
  (state: App.State) => state.cruise.multiBooking.session,
  (cruiseItem, cruiseItems, sessions): Cruises.BookingSessionData => {
    return getSessionByCruiseItem(cruiseItem, sessions, cruiseItems)
  },
)

export const getBookingRateListData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.rateList,
  (cruiseItem, rateList): Cruises.CruiseBookingRateListDataWithStatus => {
    return getRateListByCruiseItem(cruiseItem, rateList)
  },
)

export const getBookingCabinListData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.cabinList,
  (cruiseItem, cabinList): Cruises.BookingCabinListDataWithStatus => {
    return getCabinListByCruiseItem(cruiseItem, cabinList)
  },
)

export const getBookingCabinDetailsData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.cabinDetails,
  (cruiseItem, cabinDetails): Cruises.BookingCabinDetailsListData => {
    return getCabinDetailsByCruiseItem(cruiseItem, cabinDetails)
  },
)

export const getBookingRateDetailsData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.rateDetails,
  (cruiseItem, rateDetails): Cruises.BookingRateDetailsData => {
    return getRateDetailsByCruiseItem(cruiseItem, rateDetails)
  },
)

export const getBookingCabinSelectionData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.cabinSelection,
  (cruiseItem, cabinSelection): Cruises.BookingCabinSelectionDataWithStatus => {
    return getCabinSelectionByCruiseItem(cruiseItem, cabinSelection)
  },
)

export const getBookingCabinPricingData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.cabinPricing,
  (cruiseItem, cabinPricing): Cruises.BookingCabinPricingDataWithStatus => {
    return getCabinPricingByCruiseItem(cruiseItem, cabinPricing)
  },
)

export const getBookingCabinReleaseData = createSelector(
  getSelectedCruiseItem,
  (state: App.State) => state.cruise.multiBooking.cabinRelease,
  (cruiseItem, cabinRelease): Cruises.BookingCabinReleaseData => {
    return getCabinReleaseByCruiseItem(cruiseItem, cabinRelease)
  },
)

export const getBookingAllCabinsWithPrice = createSelector(
  getBookingCruiseOffer,
  getBookingCabinListData,
  getBookingRateListData,
  getBookingCabinDetailsData,
  getSelectedCruiseItem,
  getCruiseItems,
  (offer, cabinLisData, rateListData, cabinDetails, cruiseItem, cruiseItems): Array<Cruises.BookingCabinWithPrice> => {
    const selectedRates = rateListData.rates.filter(rate => (
      rate.cabinType === cruiseItem.cabinType
    ))

    return buildCabinsWithPrice(
      cruiseItem,
      cruiseItems,
      cabinLisData,
      cabinDetails,
      selectedRates,
      offer.evergreenInclusions,
    )
  },
)

export const getBookingCabinsWithPrice = createSelector(
  getBookingCruiseOffer,
  getBookingCabinListData,
  getBookingRateListData,
  getBookingCabinDetailsData,
  getSelectedCruiseItem,
  getCruiseItems,
  (offer, cabinLisData, rateListData, cabinDetails, cruiseItem, cruiseItems): Array<Cruises.BookingCabinWithPrice> => {
    const cabinCodes = cruiseItem.cabinCodes || []

    const selectedRates = rateListData.rates.filter(rate => (
      rate.cabinType === cruiseItem.cabinType &&
      cabinCodes.includes(rate.pricedCategoryCode)
    ))

    return buildCabinsWithPrice(
      cruiseItem,
      cruiseItems,
      cabinLisData,
      cabinDetails,
      selectedRates,
      offer.evergreenInclusions,
    )
  },
)

export const getBookingDecksAvailable = createSelector(
  getBookingCruiseOffer,
  getBookingCabinsWithPrice,
  (offer, cabinsWithPrice): Array<App.CruiseDeck> => {
    // We want to display the decks even if there are no cabins
    // this reduce also removes duplicate decks that have the same name
    const decksByName: { [deckName: string]: App.CruiseDeck } = offer.ship.decks?.reduce((acc, deck) => {
      const cabins = cabinsWithPrice.filter(cabin => (
        cabin.deckId === String(deck.externalId) &&
        !cabin.isGuaranteed
      ))

      const groupedDeck: App.CruiseDeck | null = acc[deck.name]
      if (groupedDeck && !!groupedDeck.cabinAmount) {
        return acc
      }

      const cabinLowestPrice = getCabinLowestPrice(cabins)

      return {
        ...acc,
        [deck.name]: {
          deckId: String(deck.externalId),
          deckName: deck.name || 'Deck',
          deckNumber: deck.deckNumber,
          image: deck.imageId ?? '',
          shipCabinPositionList: deck.shipCabinPosition ?? [],
          cabinAmount: cabins.length,
          isDeckWithLowestPrice: false,
          cabinLowestPrice: cabinLowestPrice?.price,
          cabinCategoryWithLowestPrice: cabinLowestPrice?.cabinCategory,
        },
      }
    }, {}) ?? {}

    const decks = setDeckLowestPrice(Object.values(decksByName))

    return sortBy(decks, (deck) => deck.deckNumber, 'desc')
  },
)

export const getBookingCabinCategories = createSelector(
  getBookingRateListData,
  getBookingAllCabinsWithPrice,
  getSelectedCruiseItem,
  getBookingCruiseOffer,
  getBookingCustomShipCategoryGroups,
  (rateListData, cabinsWithPrice, cruiseItem, offer, customCabinCategoriesGroups): Array<Cruises.BookingCabinCategory> => {
    const categories = offer.ship.cabinCategories ?? []
    const offerEvergreenInclusions = offer.evergreenInclusions ?? []
    const cabinsByCategory = getBookingCabinsByCategory(cabinsWithPrice)

    const categoriesWithPrice = categories.map((category) => {
      const customCategoryGroup = customCabinCategoriesGroups.find(
        (cabinCategoryGroup) => cabinCategoryGroup.cabinCategories?.some(cabinCategory => cabinCategory.code === category.code),
      )

      const [lowestRateItem] = rateListData.rates.filter((rate) => (
        rate.pricedCategoryCode === category.code &&
        rate.cabinType === cruiseItem.cabinType
      ))

      const lowestRatePrice = lowestRateItem?.priceDetails
      const cabins = cabinsByCategory[category.code] ?? []
      const cabinsCount = cabins.filter(cabin => !cabin.isGuaranteed).length
      const [cabin] = cabins

      const evergreenInclusions = offerEvergreenInclusions.filter((inclusion) => (
        inclusion.cabinTypes.includes(cruiseItem.cabinType!) &&
        inclusion.cabinCategories.includes(category.code)
      ))

      const hasGuaranteedCabin = cabins.some(cabin => cabin.isGuaranteed)
      const hasAccessibleCabin = cabins.some(cabin => cabin.isAccessibleCabin)
      const hasConnectingCabin = cabins.some(cabin => cabin.connectingCabinAvailable)

      const categoryImageIds = customCategoryGroup?.images?.map(image => image.imageId) ?? []
      const imageIds = categoryImageIds.length ? categoryImageIds : [category.imageId]
      const images = imageIds.filter(Boolean).map(imageId => ({ id: imageId }))

      const categoryInfo = category.cabinCategoryInfo?.[0]

      const name = categoryInfo?.name ?? category.cabinType
      const description = customCategoryGroup?.description ?? categoryInfo?.description
      const color = category.color || ''

      return {
        ...category,
        color,
        images,
        name,
        description,
        cabin,
        cabinsCount,
        evergreenInclusions,
        lowestRatePrice,
        hasAccessibleCabin,
        hasConnectingCabin,
        hasGuaranteedCabin,
      }
    })
      .filter((category) => category.lowestRatePrice)

    const parsedCategories = categoriesWithPrice.map((category) => {
      const name = category.name
      const groupingKeywordList = ['Guarantee', 'Some Accessible', 'Accessible', 'Interconnecting', 'Connecting']
      const keyword = groupingKeywordList.find((group) => category.name.includes(group))

      if (keyword) {
        const parsedName = name
          .replaceAll(keyword ?? '', '') // Remove keyword occurrence
          .replace(/\(\s*\)/g, '') // Remove empty parentheses
          .replace(/-\s*$/, '') // Remove trailing hyphen
          .replace(/^\s*-\s*/, '') // Remove leading hyphen
          .trim()

        return {
          ...category,
          name: parsedName,
        }
      }

      return category
    })

    return sortBy(
      parsedCategories,
      (category) => category.lowestRatePrice.price, 'asc',
    )
  },
)

// Cruise summary selectors
export const getCurrentCheckoutPage = createSelector(
  (state: App.State) => state.router.location.pathname,
  (pathname) => {
    return last(pathname.split('/'))
  },
)

export const showCruiseSummaryTotals = createSelector(
  getCurrentCheckoutPage,
  getSelectedCruiseItem,
  getBookingCabinSelectionData,
  (currentCheckoutPage, isCruiseCheckout, cabinSelectionData) => {
    return (
      !isCruiseCheckout ||
      (
        currentCheckoutPage !== CheckoutPageId.CruiseV2 &&
        !cabinSelectionData.loading
      )
    )
  },
)

// Price change modal selectors
export const getBookingCabinsWithPriceChanges = createSelector(
  getCruiseItems,
  (state: App.State) => state.cruise.multiBooking.cabinSelection,
  (state: App.State) => state.cruise.multiBooking.cabinPricing,
  (cruiseItems, cabinSelectionMap, cabinPricingMap): Array<Cruises.CabinWithPriceChanges> => {
    const cabins: Array<Cruises.CabinWithPriceChanges> = []

    cruiseItems.map((cruiseItem, index) => {
      const { cabinSelection } = getCabinSelectionByCruiseItem(cruiseItem, cabinSelectionMap)
      const { cabinPricing } = getCabinPricingByCruiseItem(cruiseItem, cabinPricingMap)

      const oldPriceAmount = cabinSelection?.pricing.total.amount ?? 0
      const newPriceAmount = cabinPricing?.pricing.total.amount ?? 0
      const isChanged = cabinSelection && cabinPricing && oldPriceAmount !== newPriceAmount

      if (isChanged) {
        cabins.push({
          name: `Cabin ${index + 1}`,
          oldPriceAmount,
          newPriceAmount,
          currency: cabinPricing?.pricing.currency,
        })
      }
    })

    if (cabins.length > 1) {
      const oldPriceTotalAmount = sum(cabins, cabin => cabin.oldPriceAmount)
      const newPriceTotalAmount = sum(cabins, cabin => cabin.newPriceAmount)
      const currency = cabins[0].currency

      cabins.push({
        name: 'Total',
        oldPriceAmount: oldPriceTotalAmount,
        newPriceAmount: newPriceTotalAmount,
        currency,
      })
    }

    return cabins
  },
)
