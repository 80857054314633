import { CHECKOUT_ITEM_TYPE_FLIGHT } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'
import { FlightViewTypes } from 'constants/flight'

function getJourneyFields(
  journey: App.JourneyV2,
) {
  const isV2ReturnFare = journey.fareType === FlightViewTypes.RETURN

  return {
    journeyId: journey.id,
    journeyKey: journey.bookingInfo.journeyKey,
    provider: journey.provider,
    providerSearchId: journey.bookingInfo.providerSearchId,
    cost: isV2ReturnFare ? journey.price.all.totalRoundTripPrice : journey.price.all.totalFare,
    carrierImage: {
      url: journey.carrierLogo,
      title: journey.carrierName,
    },
  }
}

function getViewType(
  departing?: App.AnyJourney,
  returning?: App.AnyJourney,
  viewType?: string,
) {
  const { RETURN, TWO_ONE_WAYS_AND_RETURN } = FlightViewTypes
  const isReturnCombinedFlight = departing?.fareType === RETURN && returning?.fareType === RETURN
  return isReturnCombinedFlight ? TWO_ONE_WAYS_AND_RETURN : viewType
}

interface FlightItemUpdate {
  originAirportCode?: string;
  destinationAirportCode?: string;
  searchId?: string;
  forceBundleId?: string;
}

const EmptyBaggage: App.Checkout.FlightItemPaxBaggageMap = { baggage: {}, carryOnBaggage: {} }

export function setFlightCheckoutItemJourney(
  checkoutItem: App.Checkout.FlightItem,
  journey: App.JourneyV2,
  segment: 'departing' | 'returning',
  options: {
    fareFamily?: App.FlightFareFamily;
    baggage?: App.Checkout.FlightItemPaxBaggageMap;
    forceBundleId?: string;
  },
) {
  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    // our flight is changing, reset the fare fetching behaviour
    hasFinalFare: false,
    fareFetchError: undefined,
    journeyType: journey.itemType,
    forceBundleId: options.forceBundleId ?? checkoutItem.forceBundleId,
    totalFare: 0,
    searchId: checkoutItem.searchId,
    viewType: journey.fareType === FlightViewTypes.RETURN ? FlightViewTypes.TWO_ONE_WAYS_AND_RETURN : checkoutItem.viewType,
  }

  if (segment === 'departing') {
    updatedItem.departing = {
      ...getJourneyFields(journey),
      extras: options.baggage ?? EmptyBaggage,
      fareFamily: options.fareFamily,
    }

    if (journey.itemType === 'journeyV2') {
      updatedItem.returning = undefined
    }
  } else {
    updatedItem.returning = {
      ...getJourneyFields(journey),
      extras: options.baggage ?? EmptyBaggage,
      fareFamily: options.fareFamily,
    }
  }

  return updatedItem
}

export function resetFlightCheckoutItem(
  checkoutItem: App.Checkout.FlightItem,
) {
  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    departing: undefined,
    returning: undefined,
  }

  return updatedItem
}

export function setFlightCheckoutItemJourneyFareFamily(
  checkoutItem: App.Checkout.FlightItem,
  segment: 'departing' | 'returning',
  options: {
    fareFamily?: App.FlightFareFamily;
  },
) {
  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
  }

  if (segment === 'departing') {
    updatedItem.departing = {
      ...updatedItem.departing!,
      fareFamily: options.fareFamily,
    }
  } else {
    updatedItem.returning = {
      ...updatedItem.returning!,
      fareFamily: options.fareFamily,
    }
  }

  return updatedItem
}

export function updateFlightCheckoutItem(
  checkoutItem: App.Checkout.FlightItem,
  updates: FlightItemUpdate,
): App.Checkout.FlightItem {
  const {
    originAirportCode,
    destinationAirportCode,
    searchId,
    forceBundleId,
  } = updates

  const updatedItem: App.Checkout.FlightItem = {
    ...checkoutItem,
    originAirportCode: originAirportCode ?? checkoutItem.originAirportCode,
    destinationAirportCode: destinationAirportCode ?? checkoutItem.destinationAirportCode,
    searchId,
    forceBundleId,
    // we just updated the item, fare may have changed
    hasFinalFare: false,
    fareFetchError: undefined,
  }

  return updatedItem
}

interface GenerateItemFields {
  originAirportCode: string,
  destinationAirportCode?: string,
  occupants: App.Occupants,
  passengers: Array<App.Checkout.FlightPassenger>,
  fareType: 'return' | 'oneWay' | 'multiCity',
  viewType: string,
  searchId?: string,
  isFlightCredit?: boolean,
  itemId?: string,
  departing?: App.JourneyV2,
  departingFareFamily?: App.FlightFareFamily,
  departingBaggage?: App.Checkout.FlightItemPaxBaggageMap,
  returning?: App.JourneyV2,
  returningFareFamily?: App.FlightFareFamily,
  returningBaggage?: App.Checkout.FlightItemPaxBaggageMap,
  bundledItemIds?: Array<string>,
  quotedFare?: number;
}

export function generateFlightCheckoutItem(fields: GenerateItemFields): App.Checkout.FlightItem {
  const {
    searchId,
    departing,
    departingFareFamily,
    returning,
    returningFareFamily,
    occupants,
    passengers,
    fareType,
    viewType,
    isFlightCredit,
    itemId,
    departingBaggage,
    returningBaggage,
    bundledItemIds,
    originAirportCode,
    destinationAirportCode,
    quotedFare,
  } = fields
  const departingFlight = departing ? getJourneyFields(departing) : undefined
  const returningFlight = returning ? getJourneyFields(returning) : undefined

  return {
    itemId: itemId ?? uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_FLIGHT,
    originAirportCode,
    destinationAirportCode: destinationAirportCode!,
    passengers,
    occupants,
    fareType,
    viewType: getViewType(departing, returning, viewType),
    quotedFare,
    journeyType: departing?.itemType,
    isFlightCredit: !!isFlightCredit,
    searchId,
    bundledItemIds,
    departing: departingFlight ? {
      journeyId: departingFlight.journeyId,
      journeyKey: departingFlight.journeyKey,
      provider: departingFlight.provider,
      providerSearchId: departingFlight.providerSearchId,
      fareFamily: departingFareFamily,
      extras: departingBaggage ?? EmptyBaggage,
      cost: departingFlight.cost,
      carrierImage: departingFlight.carrierImage,
    } : undefined,
    returning: returningFlight ? {
      journeyId: returningFlight.journeyId,
      journeyKey: returningFlight.journeyKey,
      fareFamily: returningFareFamily,
      provider: returningFlight.provider,
      providerSearchId: returningFlight.providerSearchId,
      extras: returningBaggage ?? EmptyBaggage,
      cost: returningFlight.cost,
      carrierImage: returningFlight.carrierImage,
    } : undefined,
    hasFinalFare: false,
  }
}
