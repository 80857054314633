import React, { useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import TextArea from 'components/Common/Form/Input/TextArea'

const Container = styled.div`
  text-align: left;

  > * + * {
    margin-top: ${rem(24)};
  }
`

const RadioWrapper = styled.div`
  margin-top: ${rem(6)};
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};

  > * + * {
    border-top: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  }
`

const RefundReason = styled.div`
  padding: ${rem(12)};
`

const StyledBodyText = styled(BodyText)`
  margin-top: ${rem(8)};
`

const StyledTextArea = styled(TextArea)`
  margin-top: ${rem(8)};
`

const StyledRadioButtonInput = styled(RadioInput)`
  width: 100%;
`

interface Props {
  onNext: (reason: string, comment?: string) => void;
}

const OTHER_OPTION:string = 'Other'
const CHEAPER_OPTION:string = 'Found a Cheaper Deal'

export const REFUND_REASONS: Array<string> = [
  CHEAPER_OPTION,
  'Change in Travel Plans',
  'Personal Reasons',
  'Financial Constraints',
  'Travel Restrictions',
  'Better Loyalty Program or Rewards',
  OTHER_OPTION,
]

function HotelCancelationReason({ onNext }: Props) {
  const [selectedReason, setSelectedReason] = useState<string>('')
  const [customReason, setCustomReason] = useState<string>()
  const [cheaperDealText, setCheaperDealText] = useState<string>()

  const onClickAgree = () => {
    let comment:string | undefined
    if (selectedReason === OTHER_OPTION) {
      comment = customReason
    } else if (selectedReason === CHEAPER_OPTION) {
      comment = CHEAPER_OPTION + ': ' + cheaperDealText
    }
    onNext(selectedReason, comment)
  }

  const noReadyToSubmit = (): boolean => {
    if (selectedReason === OTHER_OPTION) {
      return customReason?.trim().length === 0
    } else if (selectedReason === CHEAPER_OPTION) {
      return cheaperDealText?.trim().length === 0
    }
    return selectedReason.length === 0
  }

  return (
    <Container>
      <Group direction="vertical" gap={8}>
        <BodyText variant="medium">
          Before canceling your booking, please select a reason; your feedback will help us enhance the Luxury Escapes experience.
        </BodyText>
        <StyledBodyText variant="medium" weight="bold">
          Select your reason for cancelling
        </StyledBodyText>
        <RadioWrapper>
          {REFUND_REASONS.map((option) => (
            <RefundReason data-testid="refund-reason" key={option}>
              <StyledRadioButtonInput
                checked={selectedReason === option}
                value={option}
                name="Refund reason"
                required
                onChange={() => setSelectedReason(option)}
              >
                {option}
                {selectedReason === OTHER_OPTION && option === OTHER_OPTION && (
                  <StyledTextArea
                  placeholder="Specify reason"
                  value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)}
                  name="Other reason"
                  data-testid="other-reason"
                  required
                />
                )}
                {selectedReason === CHEAPER_OPTION && option === CHEAPER_OPTION && (
                  <StyledTextArea
                  placeholder="Who had the cheaper deal?"
                  value={cheaperDealText}
                  onChange={(e) => setCheaperDealText(e.target.value)}
                  required
                />
                )}
              </StyledRadioButtonInput>
            </RefundReason>
          ))}
        </RadioWrapper>
        <Group horizontalAlign="end" direction="horizontal">
          <TextButton
            kind="primary"
            data-testid="cancel-booking-confirm-button"
            disabled={noReadyToSubmit()}
            onClick={onClickAgree}
          >
            Continue
          </TextButton>
        </Group>
      </Group>
    </Container>
  )
}

export default HotelCancelationReason
