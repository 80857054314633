import createSelector from 'lib/web/createSelector'
import { match, P } from 'ts-pattern'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_TRANSFER,
} from 'constants/checkout'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { JOURNEY_TYPE_V2, getJourneyV2IdKey } from 'lib/flights/flightUtils'
import { excludeNullOrUndefined } from 'checkout/utils'
import { last, max, min } from 'lib/array/arrayUtils'
import { formatMinutesToHoursMinutes } from 'lib/datetime/time'

const hotelItemView = createSelector(
  (state: App.State, item: App.Checkout.AccommodationItem) => state.offer.offers[item.offerId],
  (state: App.State, item: App.Checkout.AccommodationItem) => item,
  (offer: App.Offer | undefined, item): App.Checkout.CartItemView => {
    const property = offer?.property
    const dates = 'checkIn' in item ? `${item.checkIn} ~ ${item.checkOut}` :
      'startDate' in item ? `${item.startDate} ~ ${item.endDate}` : 'BNBL'
    return {
      id: item.itemId,
      label: property?.name ?? offer?.name ?? item.offerId,
      subtitle: `${item.itemType} • ${dates}`,
    }
  },
)

const bedbankItemView = createSelector(
  (state: App.State, item: App.Checkout.BedbankHotelItem) => state.offer.offers[item.offerId],
  (state: App.State, item: App.Checkout.BedbankHotelItem) => item,
  (offer: App.Offer | undefined, item): App.Checkout.CartItemView => {
    const dates = `${item.checkIn} ~ ${item.checkOut}`
    return {
      id: item.itemId,
      label: offer?.name ?? item.offerId,
      subtitle: `bedbank hotel • ${dates}`,
    }
  },
)

const experienceItemView = createSelector(
  (state: App.State, item: App.Checkout.ExperienceItem) => state.experience.experiences[item.experienceId],
  (state: App.State, item: App.Checkout.ExperienceItem) => item,
  (experience: App.ExperienceOffer | undefined, item): App.Checkout.CartItemView => {
    const ticketQuantity = item.tickets.reduce((acc, ticket) => acc + ticket.count, 0)
    return {
      id: item.itemId,
      label: experience?.name ?? item.experienceId,
      subtitle: [
        item.itemType,
        ...(experience?.hasCalendar ? [item.date] : []),
        ticketQuantity,
      ].join(' • '),
    }
  },
)

const transferItemView = createSelector(
  (state: App.State, item: App.Checkout.TransferItem) => state.experience.experiences[item.experienceId],
  (state: App.State, item: App.Checkout.TransferItem) => item,
  (transfer: App.ExperienceOffer | undefined, item): App.Checkout.CartItemView => {
    return {
      id: item.itemId,
      label: transfer?.name ?? item.experienceId,
      subtitle: `${item.itemType} • ${item.transfer.title}`,
    }
  },
)

function isJourneyV2(journey: App.AnyJourney): journey is App.JourneyV2 {
  return journey.itemType === JOURNEY_TYPE_V2
}

function getJourneyLabel(journey: App.AnyJourney, isRoundTrip: boolean): string {
  const arrow = isRoundTrip ? '↔' : '→'
  if (isJourneyV2(journey)) {
    const flights = journey.flightGroup.flights
    const from = flights[0].departureAirportName
    const fromCode = flights[0].departureAirport
    const to = flights[flights.length - 1].arrivalAirportName
    const toCode = flights[flights.length - 1].arrivalAirport
    return `${from} (${fromCode}) ${arrow} ${to} (${toCode})`
  } else {
    const flights = journey.departing.flights
    const from = flights[0].departingAirportName
    const fromCode = flights[0].departingAirport
    const to = flights[flights.length - 1].arrivalAirportName
    const toCode = flights[flights.length - 1].arrivalAirport
    return `${from} (${fromCode}) ${arrow} ${to} (${toCode})`
  }
}

function getJourneyDate(journey: App.AnyJourney): Array<string> {
  if (isJourneyV2(journey)) {
    const flights = journey.flightGroup.flights
    return [flights[0].departingDate]
  } else {
    const departingDate = journey.departing.flights[0].departingDate
    const returningDate = journey.returning ? last(journey.returning.flights).arrivalDate : undefined
    return [departingDate, returningDate].filter(excludeNullOrUndefined)
  }
}

function getJourneyDates(journeys: Array<App.AnyJourney>): string {
  const dates = journeys.flatMap(getJourneyDate)
  return dates.join(' ~ ')
}

function getJourneyKeys(item: App.Checkout.FlightItem): Array<string> {
  const keys = item.journeyType == JOURNEY_TYPE_V2 ? [
    getJourneyV2IdKey(item.departing.journeyId, item.searchId, item.departing.fareFamily?.id),
    item.returning ? getJourneyV2IdKey(item.returning.journeyId, item.searchId, item.returning.fareFamily?.id) : undefined,
  ] : [item.searchId]
  return keys.filter(excludeNullOrUndefined)
}

const flightItemView = createSelector(
  (state: App.State) => state.flights.journeysById,
  (state: App.State, item: App.Checkout.FlightItem) => item,
  (journeysById, item): App.Checkout.CartItemView => {
    const journeyKeys = getJourneyKeys(item)
    const journeys = journeyKeys.map(key => journeysById[key])
    const isRoundTrip = !!item.returning
    const dates = journeys[0] ? getJourneyDates(journeys) : undefined
    return {
      id: item.itemId,
      label: journeys[0] ? getJourneyLabel(journeys[0], isRoundTrip) : item.departing?.journeyKey ?? '',
      subtitle: [
        item.itemType,
        item.fareType,
        ...(dates ? [dates] : []),
        generateOccupancyStringByRoom(item.occupants),
      ].join(' • '),
    }
  },
)

const tourV2ItemView = createSelector(
  (state: App.State, item: App.Checkout.TourV2Item) => state.offer.tourV2Offers[item.offerId],
  (state: App.State, item: App.Checkout.TourV2Item) => item,
  (offer: Tours.TourV2Offer | undefined, item): App.Checkout.CartItemView => {
    const dates = `${item.startDate} ~ ${item.endDate}`
    return {
      id: item.itemId,
      label: offer?.name ?? item.offerId,
      subtitle: `${item.itemType} • ${dates}`,
    }
  },
)

const tourV2ExperienceItemView = createSelector(
  (state: App.State, item: App.Checkout.TourV2ExperienceItem) => item,
  (tourExperienceItem: App.Checkout.TourV2ExperienceItem): App.Checkout.CartItemView => {
    const { name, timeSlot, date, day, duration } = tourExperienceItem.purchasableOption
    const dates = `Day ${day} (${date}) ${timeSlot ? ' • ' + timeSlot : ''} ${duration ? ' • ' + formatMinutesToHoursMinutes(duration) : ''}`
    return {
      id: tourExperienceItem.itemId,
      label: name ?? tourExperienceItem.offerId,
      subtitle: `Tour experience • ${dates}`,
    }
  },
)

const cruiseItemView = createSelector(
  (state: App.State, item: App.Checkout.CruiseItem) => state.cruise.cruiseOffers[item.offerId],
  (state: App.State, item: App.Checkout.CruiseItem) => item,
  (offer: App.CruiseOffer | undefined, item): App.Checkout.CartItemView => {
    const dates = `${item.startDate} ~ ${item.endDate}`
    return {
      id: item.itemId,
      label: offer?.name ?? item.offerId,
      subtitle: `${item.itemType} • ${dates}`,
    }
  },
)

const carHireItemView = createSelector(
  (state: App.State, item: App.Checkout.CarHireItem) => item,
  (item): App.Checkout.CartItemView => {
    const dates = `${item.pickUpDate} ~ ${item.dropOffDate}`
    return {
      id: item.itemId,
      label: item.offer.name,
      subtitle: [
        'car hire',
        dates,
      ].join(' • '),
    }
  },
)

const bundledSaveItemView = createSelector(
  (state: App.State, item: App.Checkout.BundleAndSaveItem) => state.offer.offers[item.offerId],
  (state: App.State, item: App.Checkout.BundleAndSaveItem) => item,
  (offer: App.Offer | undefined, item): App.Checkout.CartItemView => {
    const dates = Object.values(item.dates)
    const checkIn = min(dates.map((date) => date.checkIn), (date) => new Date(date))
    const checkOut = max(dates.map((date) => date.checkOut), (date) => new Date(date))

    return {
      id: item.itemId,
      label: offer?.name ?? item.offerId,
      subtitle: `Bundle and Save • ${checkIn} ~ ${checkOut}`,
    }
  },
)

const unknownItemView = createSelector(
  (state: App.State, item: App.Checkout.AnyItem) => item,
  (item): App.Checkout.CartItemView => ({
    id: item.itemId,
    label: 'Unsupported item',
    subtitle: item.itemType,
  }),
)

export const checkoutCartItemViews = createSelector(
  (state: App.State) => state,
  (state: App.State) => state.checkout.cart.items,
  (state, items): Array<App.Checkout.CartItemView> => {
    const itemViews = items.map((item) => match(item)
      .with({ itemType: P.union(CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_TOUR_V1) }, (item: App.Checkout.AccommodationItem) => hotelItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_BEDBANK }, (item: App.Checkout.BedbankHotelItem) => bedbankItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_FLIGHT }, (item: App.Checkout.FlightItem) => flightItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_TOUR_V2 }, (item: App.Checkout.TourV2Item) => tourV2ItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE }, (item: App.Checkout.TourV2ExperienceItem) => tourV2ExperienceItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_EXPERIENCE }, (item: App.Checkout.ExperienceItem) => experienceItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_TRANSFER }, (item: App.Checkout.TransferItem) => transferItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_CRUISE }, (item: App.Checkout.CruiseItem) => cruiseItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_CAR_HIRE }, (item: App.Checkout.CarHireItem) => carHireItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE }, (item: App.Checkout.BundleAndSaveItem) => bundledSaveItemView(state, item))
      .with({ itemType: CHECKOUT_ITEM_TYPE_INSURANCE }, () => null) // insurance item is excluded from cart
      .otherwise((item) => unknownItemView(state, item)))
    return itemViews
      .filter(excludeNullOrUndefined)
  },
)
