import moment from 'moment/moment'
import { useMemo } from 'react'
import { useAppSelector } from './reduxHooks'
import { getCurrentLoyaltyMemberTier } from 'selectors/loyaltySelectors'
import { extractPkgInclusions } from 'lib/offer/inclusionUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'

interface Options {
  checkIn?: moment.Moment;
  checkOut?: moment.Moment;
  /**
   * Will default to the current members loyalty tier
   * Use this prop to override it
   * (e.g. in a preview context or maybe in the checkout)
   */
  loyaltyTier?: App.MembershipSubscriptionTier;
}

export function useOfferInclusions(
  pkg: App.Package | undefined,
  options: Options = {},
): {
    inclusions: Array<App.PackageInclusion>,
    bonusInclusions: Array<App.PackageInclusion>,
    tacticalInclusions: Array<App.PackageInclusion>,
    loyaltyInclusions: Array<App.PackageInclusion>,
  } {
  const { checkIn, checkOut, loyaltyTier } = options
  const currentLoyaltyMemberTier = useAppSelector(state => getCurrentLoyaltyMemberTier(state)) ?? 'base'
  const viewableForFreePreviewOrSubscribedTier = currentLoyaltyMemberTier === 'free_preview' ? 'base' : currentLoyaltyMemberTier
  const inclusionsLoyaltyTier = loyaltyTier ?? viewableForFreePreviewOrSubscribedTier
  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))

  const [inclusions, bonusInclusions, loyaltyInclusions, tacticalInclusions] = useMemo(() => {
    return extractPkgInclusions(pkg, { checkIn, checkOut, loyaltyTier: inclusionsLoyaltyTier })
  }, [checkIn, checkOut, inclusionsLoyaltyTier, pkg])

  if (!luxPlusEnabled) {
    return {
      inclusions,
      bonusInclusions,
      tacticalInclusions,
      loyaltyInclusions: [],
    }
  }

  return {
    inclusions,
    bonusInclusions,
    tacticalInclusions,
    loyaltyInclusions,
  }
}
