import { createSelector } from 'reselect'
import { getCurrentLoyaltyMemberTier } from 'selectors/loyaltySelectors'
import { getLuxPlusSubscriptionItems } from './view/luxPlusSubscription'

export const getCheckoutLoyaltyTier = createSelector(
  getCurrentLoyaltyMemberTier,
  getLuxPlusSubscriptionItems,
  (currentTier, subscriptionItems): App.MembershipSubscriptionTier => {
    const itemTier = subscriptionItems[0]?.tier
    const validCurrentTierForCheckout = currentTier !== 'free_preview' ? currentTier : 'base'
    return itemTier ?? validCurrentTierForCheckout
  },
)
