/** This is a bundle offer search tile
 *  This tile will first check whether (1) the search performed are property search and (2) the result has been soldout
 *  Then it will render either the (1) Expansed or (2) Condensed search results.
 * */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import SearchBundleOfferTileCondensed from './SearchBundleOfferTileCondensed'
import styled from 'styled-components'
import { getPlural } from 'lib/string/pluralize'
import Pane from 'components/Common/Pane'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'

import useImpressionHandler from 'hooks/useImpressionHandler'
import useBestPriceBundleAndSavePackage from 'hooks/useBestPriceBundleAndSavePackage'

import SearchOfferTileLoadingSkeleton from './SearchOfferTileLoadingSkeleton'
import SearchBundleOfferTileExpanded from './SearchBundleOfferTileExpanded'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled(Pane)`
  position: relative;
`

interface Props {
  offer: App.BundleOffer;
  offerMetaData?: App.OfferListMetaData;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl?: string;
  onImageChange?: (idx: number, image?: App.Image) => void
}

function SearchBundleOfferTile(props: Props) {
  const {
    offer,
    filters,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    onImageChange,
  } = props

  const {
    bestPricePackage,
    bestPriceError,
    fetching,
    soldOut,
    available,
    useBestPrice,
  } = useBestPriceBundleAndSavePackage(offer, offerMetaData?.bundledOfferId, filters)
  const [imageLoaded, setImageLoaded] = useState(false)

  const impressionRef = useImpressionHandler(offer.id)

  const showHighlight = filters?.propertyId && !filters?.searchNearby
  let highlightMessage: string
  let tileType: 'loading' | 'expanded' | 'condensed'
  const hasDates = !!(filters?.checkIn && filters?.checkOut)

  useOfferSoldOutPushDown(offer.id, filters, !fetching && soldOut)
  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetching))) {
      onEvent(OfferListEvents.offerReady, {
        available,
      })
    }
  }, [imageLoaded, fetching, available, onEvent, hasDates])

  if (useBestPrice) {
    if (fetching) {
      tileType = 'loading'
    } else if (available || bestPriceError) {
      tileType = 'expanded'
      highlightMessage = 'We found a limited time deal for your hotel. Book now before it ends!'
    } else {
      tileType = 'condensed'
      highlightMessage = `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
    }
  } else {
    tileType = 'expanded'
    highlightMessage = 'We found a limited time deal for your hotel. Choose dates and book now before it ends!'
  }

  if (tileType === 'loading') {
    return <SearchOfferTileLoadingSkeleton />
  }

  if (offer.isSoldOut || !bestPricePackage) {
    tileType = 'condensed'
    highlightMessage = `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
  }

  return <Root id={`bundleOfferId-${offer.id}`} type="clean" ref={impressionRef}>
    {showHighlight && <HighlightMessage message={highlightMessage} />}
    {tileType === 'expanded' && <SearchBundleOfferTileExpanded
      offer={offer}
      filters={filters}
      soldOut={soldOut}
      bestPricePackage={bestPricePackage}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
    {tileType === 'condensed' && <SearchBundleOfferTileCondensed
      offer={offer}
      filters={filters}
      eagerLoadFirstImage={eagerLoadFirstImage}
      onImageLoad={handleImageLoaded}
    />}
  </Root>
}

SearchBundleOfferTile.defaultProps = {
  filters: {},
}

export default SearchBundleOfferTile
