import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext } from 'react'
import LuxPlusLabel from '../LuxPlusLabel'
import { isActiveLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import LuxPlusHiddenOfferModal from '../modals/LuxPlusHiddenOfferModal'

interface MappedProps {
  isActiveLuxPlusMember: boolean
}
interface Props extends MappedProps {
  className?: string
}

function LuxPlusHiddenOfferLabel({ isActiveLuxPlusMember }: Props) {
  const showModal = useContext(ModalContext)

  const toggleModal = useCallback(() => {
    showModal<boolean>(<LuxPlusHiddenOfferModal />)
  }, [showModal])

  return (
    <LuxPlusLabel type="member-only-offer" onClick={isActiveLuxPlusMember ? undefined : toggleModal}/>
  )
}

const mapStateToProps = (state: App.State):MappedProps => ({
  isActiveLuxPlusMember: isActiveLuxPlusMember(state),
})

export default connect(mapStateToProps)(LuxPlusHiddenOfferLabel)
