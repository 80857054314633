import config from 'constants/config'
import { isCruiseItemUpcoming, isItemBedbankUpcoming, isItemTourUpcoming, isItemUpcoming } from 'lib/order/isUpcoming'
import {
  ITEM_STATUS_COMPLETED,
  ITEM_STATUS_CANCELLED,
  ORDER_STATUS_CANCELLED,
} from 'constants/cart'
import { OFFER_TYPE_LAST_MINUTE } from 'lib/../constants/offer'
import { isEmpty, last, max, min, nonNullable, sortBy, sum } from 'lib/array/arrayUtils'
import {
  isCoverGeniusEnabled,
  isCoverGeniusEndDateValid,
} from 'lib/insurance/insuranceHelpers'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { isCruiseOffer } from 'lib/offer/offerTypes'

type AllOrderItemTypes = App.OrderItem |
  App.OrderBedbankItem |
  Tours.TourV2OrderItem |
  App.OrderAddonItem |
  App.OrderFlightItem |
  App.OrderBookingProtectionItem |
  App.OrderTransferItem |
  App.CarHireOrderItem |
  App.CustomOfferItem |
  App.OrderExperienceItem |
  App.CruiseOrderItem |
  App.OrderItemLuxPlusSubscription |
  App.OrderGiftCardItem |
  App.OrderInsuranceItem

export function getLatestCompletedReservation(order: App.Order): App.OrderItem | undefined {
  // Filter for only the order items with completed, non-last-minute, reservations
  const completedReservations = order.items.filter(i =>
    !!i.reservation &&
    i.status === ITEM_STATUS_COMPLETED &&
    i.offer.type !== OFFER_TYPE_LAST_MINUTE,
  )
  if (completedReservations.length > 0) {
    const itemWithLatestResDate = max(completedReservations, item => item.reservation ? new Date(item.reservation.endDate).getTime() : new Date().getTime())
    return itemWithLatestResDate
  }

  return undefined
}

export function orderCanAddInsurance(order: App.Order, offer?: App.Offer | App.CruiseOffer | App.BedbankOffer) {
  // Bundle and save doesn't support insurance yet
  if (order.items.some(i => i.offer.bundleOfferId)) return false
  // Order must not already have insurance (unless it's cancelled)
  if (order.insuranceItems.some(i => i.status !== ITEM_STATUS_CANCELLED)) return false
  // reservation must be in the future
  if (order.reserveForZeroDetails?.is_active) return false

  let endDate: string | undefined
  if (order.items.length) {
    // Getting just the latest completed reservation in case there are more than 1
    const latestItem = max(order.items.filter(item => item.reservation && isItemUpcoming(item)), item => +new Date(item.reservation!.endDate))
    // Order must have a completed (paid for, not awaiting dates etc) reservation
    if (!latestItem) return false
    endDate = latestItem.reservation!.endDate
  } else if (order.bedbankItems.length) {
    // Getting just the latest completed reservation in case there are more than 1
    const latestBedbankItem = max(order.bedbankItems.filter(item => item.reservationId && isItemBedbankUpcoming(item)), item => +new Date(item.checkOut))
    // Order must have a completed (paid for, not awaiting dates etc) reservation
    if (!latestBedbankItem) return false
    endDate = latestBedbankItem.checkOut
  } else if (order.tourItems.length) {
    const latestTourItem = max(order.tourItems.filter(isItemTourUpcoming), item => +new Date(item.tour.endDate))
    if (!latestTourItem) return false
    endDate = latestTourItem.tour.endDate
  } else if (order.cruiseItems.length) {
    const latestCruiseItem = max(order.cruiseItems.filter(isCruiseItemUpcoming), item => +new Date(item.arrivalDate))
    if (!latestCruiseItem) return false
    endDate = latestCruiseItem.arrivalDate
  }

  // reservation must not be >= 366 days in the future for cover genius
  if (!endDate || !isCoverGeniusEndDateValid(endDate)) return false

  if (isCruiseOffer(offer)) {
    // past cruises orders doesn't contain passengers, in this case we don't want to add insurance
    return order.cruiseItems.every(item => !!item.passengers.length)
  }
  return true
}

const isItemCancelled = (item: App.OrderItem | App.OrderBedbankItem | Tours.TourV2OrderItem | App.OrderExperienceItem | App.CruiseOrderItem | App.OrderFlightItem | App.OrderTransferItem | App.CarHireOrderItem | App.CustomOfferItem) => item.status === ITEM_STATUS_CANCELLED

export function canAddInsuranceToOrder(order?: App.Order, region: string = '', offer?: App.Offer | App.CruiseOffer | App.BedbankOffer) {
  return isCoverGeniusEnabled(region) &&
    config.ADD_INSURANCE_AFTER_PURCHASE &&
    order &&
    orderCanAddInsurance(order, offer)
}

export function isOrderCancelled(order: App.Order) {
  const orderCancelled = order.status === ORDER_STATUS_CANCELLED
  const everyItemCancelled = order.items.every(isItemCancelled) &&
    order.bedbankItems.every(isItemCancelled) &&
    order.tourItems.every(isItemCancelled) &&
    order.cruiseItems.every(isItemCancelled) &&
    order.experienceItems.every(isItemCancelled) &&
    order.flightItems.every(isItemCancelled) &&
    order.carHireItems.every(isItemCancelled) &&
    order.transferItems.every(isItemCancelled) &&
    order.customOfferItems.every(isItemCancelled)

  return orderCancelled || everyItemCancelled
}

export function getCombinedItemIds(order: App.Order): Array<string> {
  const vals: Array<string> = []

  return vals.concat(
    order.items.map(i => i.id),
    order.giftCardItems.map(i => i.itemId),
    order.insuranceItems.map(i => i.id),
    order.addonItems.map(i => i.id),
    order.flightItems.map(i => i.itemId),
    order.bedbankItems.map(i => i.itemId),
    order.experienceItems.map(i => i.id),
    order.transferItems.map(i => i.id),
  )
}

export function getDateRangeFromItems(items: Array<App.OrderItem>): { earliestCheckInDate: string, latestCheckOutDate: string } {
  const completeItemsWithDates = items.filter(item => item.status === 'completed' && item.reservation)
  const sortedItems = sortBy(completeItemsWithDates, item => item.reservation!.startDate, 'asc')
  return {
    earliestCheckInDate: sortedItems[0]?.reservation!.startDate,
    latestCheckOutDate: last(sortedItems)?.reservation!.endDate,
  }
}

// For each order, get the dates from all items of the order. Returns the earliest date for the order so that all orders can be sorted by each order's earliest date.
export function sortOrdersByDeparture(orders: Array<App.Order>) {
  return sortBy(orders, (order) => {
    const allOrderItemDates = nonNullable([
      ...order.items.filter(item => item.status !== 'cancelled').map(item => item.reservation?.startDate),
      ...order.bedbankItems.filter(item => item.status !== 'cancelled').map(item => item.checkIn),
      ...order.tourItems.filter(item => item.status !== 'cancelled').map(item => item.tour.startDate),
      ...order.experienceItems.filter(item => item.status !== 'cancelled').map(item => item.date ?? item.bookByDate),
      ...order.flightItems.filter(item => item.status !== 'cancelled').map(item => item.departureDate),
      ...order.transferItems.filter(item => item.status !== 'cancelled').map(item => item.date),
      ...order.carHireItems.filter(item => item.status !== 'cancelled' && item.status !== 'failed').map(item => item.reservation?.pickUp.date),
    ]).map(date => moment(date))
    // None of the order items have dates, will be sorted to top of the list of orders
    if (isEmpty(allOrderItemDates)) return moment()

    return moment.min(allOrderItemDates)
  }, 'asc')
}

function getTotalOrderItemCount(order: App.Order) {
  return sum([
    order.items.length,
    order.giftCardItems.length,
    order.insuranceItems.length,
    order.addonItems.length,
    order.flightItems.length,
    order.bedbankItems.length,
    order.experienceItems.length,
    order.carHireItems.length,
    order.transferItems.length,
  ])
}

export function isStandaloneOrder(order: App.Order, type: 'flights' | 'experiences' | 'transfers') {
  // is considered standalone if the type requested are the only item types in the order
  const count = getTotalOrderItemCount(order)
  switch (type) {
    case 'flights':
      return count === order.flightItems.length
    case 'experiences':
      return count === order.experienceItems.length
    case 'transfers':
      return count === order.transferItems.length
  }
}

export function isStandaloneFlightsOrder(order: App.Order):boolean {
  return isStandaloneOrder(order, 'flights')
}

export function haveOrdersBeenFetched(state: App.State, orderType: App.OrderListTypes = 'all') {
  return state.orders.ordersFetched.all || state.orders.ordersFetched[orderType]
}

export function areOrdersBeingFetched(state: App.State, orderType: App.OrderListTypes = 'all') {
  return state.orders.fetching.all || state.orders.fetching[orderType]
}

export function getOrderExperienceDateRange(order: App.Order) {
  const range = getDateRangeFromItems(order.items)
  return {
    from: range.earliestCheckInDate ? moment(range.earliestCheckInDate).add(-7, 'days').format(ISO_DATE_FORMAT) : undefined,
    to: range.latestCheckOutDate ? moment(range.latestCheckOutDate).add(7, 'days').format(ISO_DATE_FORMAT) : undefined,
  }
}

export function isBundleAndSaveOrder(order: App.Order) {
  return order.items.some(item => item.offer.bundleOfferId !== undefined)
}

export function isVillaOrder(order: App.Order) {
  return order.items.some(item => item.offer.type === 'rental')
}

export function isSingleHotelOrder(order: App.Order) {
  return order && order.items.length === 1 &&
    !order.bedbankItems.length &&
    !order.insuranceItems.length &&
    !order.flightItems.length &&
    !order.tourItems.length &&
    !order.experienceItems.length &&
    !order.cruiseItems.length &&
    !order.carHireItems.length &&
    !order.transferItems.length &&
    !order.giftCardItems.length &&
    !order.addonItems.length &&
    !order.customOfferItems.length &&
    !order.orderGift &&
    !order.bookingProtectionItems.length
}

export function isBedbankOrderItem(item: App.OrderItem | App.OrderBedbankItem): item is App.OrderBedbankItem {
  return item.offer.type === 'bedbank_hotel'
}

export function isTourV2OrderItem(item: App.OrderItem | Tours.TourV2OrderItem): item is Tours.TourV2OrderItem {
  return item.offer.type === 'tour_v2'
}

export function isOrderItem(item: AllOrderItemTypes): item is App.OrderItem {
  if ('offer' in item) {
    return item.offer.type === 'hotel' ||
    item.offer.type === 'bundle_and_save' ||
    item.offer.type === 'rental' ||
    item.offer.type === 'tactical_ao_hotel' ||
    item.offer.type === 'last_minute_hotel'
  }
  return false
}

export function getOrderLocation(order: App.Order) {
  let location: string | undefined

  if (order.items.length > 0) {
    const offer = order.items[0].offer
    location = [offer.property?.locationHeading, offer.property?.locationSubheading].filter(t => t).join(', ')
  } else if (order.bedbankItems.length > 0) {
    const offer = order.bedbankItems[0].offer
    location = [offer.property?.address?.city, offer.property?.address?.stateProvinceName].filter(t => t).join(', ')
  }

  return location
}

export function getOrderTravelDuration(order: App.Order) {
  const travelDurations = [
    ...nonNullable(order.items.map(item => ({
      startDate: item.reservation?.startDate,
      endDate: item.reservation?.endDate,
    }))),
    ...nonNullable(order.bedbankItems.map(bedbankItem => ({
      startDate: bedbankItem.checkIn.format('YYYY-MM-DD'),
      endDate: bedbankItem.checkOut.format('YYYY-MM-DD'),
    }))),
    ...nonNullable(order.cruiseItems.map(cruiseItem => ({
      startDate: cruiseItem.departureDate,
      endDate: cruiseItem.arrivalDate,
    }))),
    ...nonNullable(order.flightItems.map(flightItem => ({
      startDate: flightItem.departureDate,
      endDate: flightItem.arrivalDate,
    }))),
    ...nonNullable(order.tourItems.map(tourItem => ({
      startDate: tourItem.tour.startDate,
      endDate: tourItem.tour.endDate,
    }))),
  ]
  return {
    earliest: min(travelDurations, item => new Date(item.startDate))?.startDate,
    latest: max(travelDurations, item => new Date(item.endDate))?.endDate,
  }
}
