import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import SoldOutMessage from '../Support/SoldOutMessage'
import { ImageParams } from 'components/Common/ResponsiveImage'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import { connect } from 'react-redux'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'
import useImpressionHandler from 'hooks/useImpressionHandler'
import { getPlural } from 'lib/string/pluralize'
import BedbankOfferTilePriceDetails from 'components/OfferList/OfferListTiles/Support/BedbankOfferTilePriceDetails'
import BedbankCancellationPolicy from 'components/OfferPage/Bedbank/BedbankCancellationPolicy'
import TileInclusions from 'components/OfferList/OfferListTiles/Support/OfferTileInclusions'
import { useBedbankSearchTilePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import BedbankOfferTileSnippetExpanded from '../Support/BedbankOfferTileSnippetExpanded'
import TextButton from 'components/Luxkit/Button/TextButton'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { queryKeySelectedOfferIds } from 'constants/url'
import { encodeOfferIds } from 'lib/search/searchUtils'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import { DAYS_TO_BOOK } from 'constants/bedbank'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import BedbankUpsellPromotion from '../Support/BedbankUpsellPromotion'
import moment from 'moment'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import { Location } from 'history'
import Group from 'components/utils/Group'
import OfferTilePropertyHeading from './TileSections/OfferTilePropertyHeading'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import { Main, MainImageSegment, DetailSegment } from './SearchTileStyles'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BedbankTileFlightPrice from '../Support/BedbankTileFlightPrice'
import cn from 'clsx'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import { SEARCH_VERTICALS } from 'constants/search'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled.div`
  position: relative;
`

const RightDetailSegment = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: ${rem(16)};
  position: relative;
  width: auto;
  min-width: ${rem(180)};
  align-items: flex-start;

  > * + * {
    align-items: flex-start;
    margin-top: ${rem(8)};
  }

  &:not(.mapTile) {
      ${mediaQueryUp.desktop} {
      margin-left: ${rem(20)};
    }
  }
`

const SoldOutContainer = styled.div`
  margin-top: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
`

const FooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${rem(16)};
`

function getFilterHighlightMessage(offer: App.BedbankOffer | App.BedbankOfferSummary, hasRate: boolean, hasDates: boolean, filters?: App.OfferListFilters) {
  if (filters?.propertyId && !filters?.searchNearby) {
    // This is a property search result

    if (hasDates) {
      if (!hasRate) {
        return `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
      }
      return 'We found a great price for your hotel. Book now to secure this rate!'
    }
    else {
      return 'We found the property you were interested in. Select your dates to book now.'
    }
  }
}

interface MappedStateProps {
  location: Location;
}

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  onImageChange?: (idx: number, image?: App.Image) => void,
  flightPrice: number | undefined
  flightsEnabled: boolean;
  hotelOnlyRates: Array<App.BedbankRate>,
  hotelBundleRates: Array<App.BedbankRate>,
  fetchingRates?: boolean,
}

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

function BedbankOfferTileExpanded(props: Props & MappedStateProps) {
  const {
    offer,
    filters,
    eagerLoadFirstImage,
    offerUrl,
    location,
    onImageChange,
    flightPrice,
    flightsEnabled,
    hotelOnlyRates,
    hotelBundleRates,
    fetchingRates,
  } = props

  const {
    id,
    property,
    tagline,
  } = offer

  const [imageLoaded, setImageLoaded] = useState(false)
  const rate = hotelOnlyRates[0]
  const bundleRate = hotelBundleRates[0] || rate
  const promoteAsBundle = flightsEnabled && !!flightPrice && offer.promoteAsBundle
  const shownRate = promoteAsBundle ? bundleRate : rate
  const promotions = useBedbankSearchTilePromotions(offer.promotions, shownRate, filters?.checkIn, filters?.checkOut)
  const promotion = promotions.length > 0 ? promotions[0] : null

  const propertyFees = rate?.totals?.propertyFees ?? 0
  const bundlePropertyFees = bundleRate?.totals.propertyFees || propertyFees
  const hotelPrice = rate ? rate.totals.inclusive : offer.sell?.price
  const bundlePrice = bundleRate?.totals.inclusive || hotelPrice

  const hotelNights = rate?.nights ?? offer?.sell?.los

  const totalPrice = promoteAsBundle ? bundlePrice + bundlePropertyFees : hotelPrice + propertyFees

  const addressArray: Array<string> = []
  const { city, countryName } = property.address
  if (city) addressArray.push(city)
  if (countryName) addressArray.push(countryName)

  const locationTitle = property.locationTitleOverride ? property.locationTitleOverride : addressArray.join(', ')

  const { onBedbankSelectDatesPrompt, searchTargetLabel, offerDistanceFromSearchTarget } = useContext(GlobalSearchStateContext)
  const searchTargetDistance = offerDistanceFromSearchTarget?.[offer.id]

  const impressionRef = useImpressionHandler(id)
  const hasDates = !!(filters?.checkIn && filters?.checkOut)
  const highlightMessage = getFilterHighlightMessage(offer, !!shownRate, hasDates, filters)

  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const isMobileScreen = useIsMobileScreen()

  const onSelectDatesPrompt = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (isMobileScreen) {
      toggleSearchVertical(SEARCH_VERTICALS.HOTELS)
    } else {
      onBedbankSelectDatesPrompt?.(offer.id, offer.type, location.search)
    }
  }, [isMobileScreen, offer.id, offer.type, onBedbankSelectDatesPrompt, toggleSearchVertical, location.search])

  const isMapPage = location.pathname.includes('/map')

  const mapSearchUrl = `/search/map?${setSearchParamValue(location.search, queryKeySelectedOfferIds, encodeOfferIds([offer]))}`
  const bedBankOfferPromotionEndDate = offer.promotions[0]?.periodSchedule.to
  const priceNotDiscernible = !hasDates && !offer.sell
  const canUpsell = (hasDates && !promotion && // has dates and not already promoted
    offer.promotions?.length > 0 && // has promotions to upsell
    (offer as App.BedbankOffer).packages?.find(pkg => offer.promotions?.[0]?.id === pkg.promotionId && pkg.sell?.price) && // has package for promo with discounts
    offer.promotions.at(0).los > moment(filters?.checkOut).diff(filters?.checkIn, 'days')) // promo is for longer los than current duration

  const isSoldOut = !shownRate && hasDates
  useOfferSoldOutPushDown(offer.id, filters, isSoldOut)

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingRates))) {
      onEvent(OfferListEvents.offerReady, {
        available: !isSoldOut,
      })
    }
  }, [imageLoaded, onEvent, hasDates, fetchingRates, isSoldOut])

  return (
    <Root ref={impressionRef}>
      {highlightMessage && <HighlightMessage message={highlightMessage} />}
      <Main className={cn({ mapTile: isMapPage })}>
        <MainImageSegment>
          <ImageCarousel
            images={offer.images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            onImageChange={onImageChange}
            onImageLoad={handleImageLoaded}
            />
          <CSSBreakpoint max="mobile">
            <OffsetBookmarkContainer>
              <BookmarkButton offer={offer} />
            </OffsetBookmarkContainer>
          </CSSBreakpoint>
        </MainImageSegment>
        <DetailSegment className={cn({ mapTile: isMapPage })}>
          <TripGuard>
          </TripGuard>
          <Group gap={8} direction="vertical">
            <Group gap={4} direction="vertical">
              {showUserReviewsRating(property.rating, property.reviewsTotal) && (
                <NumberRating
                variant="medium"
                rating={property.rating ?? 0}
                total={property.reviewsTotal}
                ratingSubtitle={property.reviewsSource === 'google' ? 'From online reviews' : undefined}
              />
              )}
              <OfferTileLocationSection
                location={locationTitle}
                mapSearchUrl={mapSearchUrl}
                searchTargetDistance={searchTargetDistance}
                searchTargetLabel={searchTargetLabel}
              />
              {!hasDates && (
                <TripGuard>
                  <CSSBreakpoint min="tablet">
                    <FloatingBookmarkContainer>
                      <BookmarkButton offer={offer} />
                    </FloatingBookmarkContainer>
                  </CSSBreakpoint>
                </TripGuard>
              )}

              {promotion && <Group direction="horizontal" verticalAlign="center" horizontalAlign="start" gap={8}>
                {bedBankOfferPromotionEndDate && <OfferUrgencyCountdownLabel endDate={bedBankOfferPromotionEndDate} daysToBook={DAYS_TO_BOOK}/>}
              </Group>}
              <div>
                <OfferTilePropertyHeading name={property.name} descriptiveName={tagline} />
              </div>
              {promotion && <div>
                <ProductTypeLabel kind="plain" productType="limited_time_special" />
              </div>}
              {shownRate && (
                <BedbankCancellationPolicy rate={shownRate} offer={offer} showNonCancellation/>
              )}
            </Group>
            <Group gap={4} direction="vertical">
              <TileInclusions
              offerTitle={offer.property?.name ? offer.property.name : offer.name}
              offerURL={offerUrl}
              heading=""
              content={promotion?.rateInclusionsLong}
              tileInclusionsList={offer.inclusions}
              filters={filters}
              maxInclusionsCount={3}
            />
              {priceNotDiscernible && <BedbankOfferTileSnippetExpanded offer={offer as App.BedbankOffer} />}
            </Group>
          </Group>
          {isSoldOut && <SoldOutContainer>
            <SoldOutMessage offer={offer} filters={filters} />
          </SoldOutContainer>}
          {!isSoldOut && <>
            {!canUpsell && <RightDetailSegment className={cn({ mapTile: isMapPage })}>
              {(shownRate || offer.sell) &&
                <BedbankOfferTilePriceDetails
                  rate={rate}
                  bundleRate={bundleRate}
                  rooms={filters?.rooms}
                  offer={offer}
                  filters={filters}
                  offerUrl={offerUrl}
                  promotions={promotions}
                  flightPrice={flightPrice}
                  flightsEnabled={flightsEnabled}
                />}
              {priceNotDiscernible && <FooterButtons>
                <TextButton kind="primary" onClick={onSelectDatesPrompt}>Select your dates</TextButton>
                <TextButton kind="tertiary">View details</TextButton>
              </FooterButtons>}
              {!priceNotDiscernible && <FooterButtons>
                <TextButton fit="full-width" kind="primary">View Offer</TextButton>
              </FooterButtons>}
              {flightsEnabled && !offer.promoteAsBundle && <BedbankTileFlightPrice offer={offer} filters={filters} totalPrice={totalPrice} hotelNights={hotelNights} />}
            </RightDetailSegment>}
            {canUpsell && <BedbankUpsellPromotion
              offer={offer as App.BedbankOffer}
              rate={rate}
              bundleRate={bundleRate}
              rooms={filters.rooms}
              filters={filters}
              flightPrice={flightPrice}
              flightsEnabled={flightsEnabled}
              offerUrl={offerUrl} />}
          </>}
        </DetailSegment>
      </Main>
    </Root>
  )
}

const mapStateToProps = (appState: App.State): MappedStateProps => {
  return {
    location: appState.router.location,
  }
}

export default connect(mapStateToProps)(BedbankOfferTileExpanded)
