import config from 'constants/config'

export default function getStaticPagesByType(
  pageTypes: Array<string>,
  /** If true, return the pages in the same order as `pageTypes` instead of in the order from the config */
  preserveOrder: boolean = false,
) {
  // Handle undefined config arrays
  const allPages: Array<any> = [
    ...(config.staticPages ? config.staticPages : []),
    ...(config.externalPages ? config.externalPages : []),
  ]

  const pages = allPages.filter((page) => pageTypes.includes(page.type))
  if (preserveOrder) {
    pages.sort((a, b) => pageTypes.indexOf(a.type) - pageTypes.indexOf(b.type))
  }
  return pages
}
