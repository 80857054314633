import List from 'components/Common/List'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import ModalContext from 'contexts/ModalContext'
import React, { ComponentProps, MouseEventHandler, useCallback, useContext } from 'react'

interface Props {
  size: 'M' | 'L'
  commissionPercentage: number
  productType: 'hotel' | 'cruise' | 'tour' | 'experience'
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

function PriceRowCommission(props: Props) {
  const {
    size,
    commissionPercentage,
    productType,
  } = props

  const showModal = useContext(ModalContext)
  const openModal = useCallback<MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    showModal(<Modal title="Commission details">
      <VerticalSpacer gap={8}>
        <BodyText variant="large">
          Earn a {commissionPercentage}% commission on this deal.
        </BodyText>
        <BodyText variant="large" weight="bold">
          How it works:
        </BodyText>
        <List>
          <BodyText variant="large" as="li">
            Your commission is calculated from the total booking amount for this {productType}.
          </BodyText>
          <BodyText variant="large" as="li">
            Make the net payment, which accounts for the deduction of the commission from your total booking cost.
          </BodyText>
          <BodyText variant="large" as="li">
            Easily monitor your commissions in real-time through your agent dashboard.
          </BodyText>
        </List>
      </VerticalSpacer>
    </Modal>)
  }, [commissionPercentage, productType, showModal])

  return <Caption
    className={PriceRowCommission.name}
    variant={SIZES_CAPTION_VARIANTS[size]}
  >
    <TextLink
      variant="highlight"
      endIcon={<SolidInfoCircleIcon colour="neutral-one" />}
      onClick={openModal}
    >
      Earn {commissionPercentage}% commission
    </TextLink>
  </Caption>
}

export default PriceRowCommission
