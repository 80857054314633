import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import cn from 'clsx'

import Clickable from 'components/Common/Clickable'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'

const HiddenClickable = styled(Clickable)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%; /* The left and right properties should take care of this, but it doesn't work in Firefox for some reason */
  pointer-events: none;

  @supports not (selector(:has(*))) {
    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
    }
  }
`

const Root = styled.div`
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &:has(${HiddenClickable}:focus) {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

interface Props extends React.ComponentProps<typeof Clickable> {}

const interactableHTMLTags = new Set(['BUTTON', 'A', 'INPUT', 'TEXTAREA', 'SELECT', 'DETAILS', 'SUMMARY', 'IFRAME', 'EMBED', 'AUDIO', 'VIDEO'])

function didClickInteractableElement(clickTarget: HTMLElement, root: HTMLElement | null) {
  let current: HTMLElement | null = clickTarget
  while (current && current !== root) {
    if (interactableHTMLTags.has(current.tagName)) { return true }
    current = current.parentElement
  }
  return false
}

/**
 * A Clickable that can have other button elements nested inside it safely.
 * Also applies a default focus indicator.
 */
const NestableClickable = React.forwardRef<HTMLDivElement | HTMLAnchorElement, Props>((props, externalRef) => {
  const {
    children,
    className,
    onClick,
    onFocus,
    onBlur,
    disabled,
    ...rest
  } = props

  if (typeof externalRef === 'function') { throw new Error('NestableClickable not compatible with function refs') }

  const internalRef = useRef<HTMLDivElement>(null)
  // If no ref passed in, use our own
  const root = externalRef ?? internalRef

  const hiddenClickable = useRef<HTMLButtonElement>(null)

  const onRootClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!didClickInteractableElement(e.target as HTMLElement, root.current)) {
      hiddenClickable.current?.click()
    }
  }, [root])

  const onHiddenClickableClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClick?.(e)
  }, [onClick])

  if ((rest.to || rest.href) && !disabled) {
    // The NestableClickable behaviour doesn't make sense for links, so fall back to ClickableLink
    return <ClickableLink {...props} ref={externalRef as React.Ref<HTMLAnchorElement>}/>
  }

  return <Root ref={root} className={cn(className, { disabled })} {...rest} onClick={onRootClick}>
    <HiddenClickable
      onClick={onHiddenClickableClick}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      ref={hiddenClickable}
    />
    {children}
  </Root>
})

NestableClickable.displayName = 'NestableClickable'

export default NestableClickable
