import Caption from 'components/Luxkit/Typography/Caption'
import LuxPlusLogoWrapper from 'luxPlus/components/LuxPlusLogoWrapper'
import React, { ComponentProps } from 'react'
import BasePriceRowPrice, { SIZES_CAPTION_VARIANTS } from '../BasePriceRowPrice'
import clsx from 'clsx'

type InheritedBasePriceRowPriceProps = Pick<ComponentProps<typeof BasePriceRowPrice>, 'className' | 'data-testid' | 'discountPercentage' | 'discountType' | 'saleUnit' | 'signDisplay' | 'size' | 'priceFormat'>

interface Props extends InheritedBasePriceRowPriceProps {
  /**
   * Main price
   */
  memberPrice: number
  /**
   * Prepend the price with the loyalty logo
   */
  logoPlacement?: 'leading' | 'trailing'
}

function PriceRowLoyaltyPrice(props: Props) {
  const {
    memberPrice,
    logoPlacement,
    saleUnit,
    size,
    className,
    ...rest
  } = props

  return <BasePriceRowPrice
    {...rest}
    className={clsx(className, PriceRowLoyaltyPrice.name)}
    size={size}
    price={memberPrice}
    priceColour="loyalty"
    saleUnitColour="loyalty"
    priceLeading={logoPlacement === 'leading' && <><LuxPlusLogoWrapper height={8} />&nbsp;</>}
    saleUnit={saleUnit}
    priceTrailing={logoPlacement === 'trailing' && <Caption
      as="span"
      variant={SIZES_CAPTION_VARIANTS[size]}
      colour="loyalty"
      weight="normal"
      format="lowercase"
      wrap="no-wrap"
    >
      {' '}with&nbsp;<LuxPlusLogoWrapper height={8} />
    </Caption>}
    wrap={logoPlacement === 'trailing' ? 'word-wrap' : undefined}
  />
}

export default PriceRowLoyaltyPrice
