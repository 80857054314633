import { hasIncompleteTourItem, hasInsuranceItem, isExperiencesSupportedToAddToCart, isFlightsPendingToAddToCart, isLuxPlusAvailableToAddToCart } from 'checkout/selectors/navigationSelectors'
import { CheckoutPageId } from 'checkout/constants/pages'
import { pushWithRegion, replaceWithRegion } from 'actions/NavigationActions'
import { last } from 'lib/array/arrayUtils'
import { getInflowFlightSearchParams } from 'checkout/selectors/flightSearchSelectors'
import { removeItem } from 'actions/CheckoutActions'
import config from 'constants/config'
import { getFlightItems } from 'checkout/selectors/view/flights'
import { showCheckoutCartModeModal } from 'actions/UiActions'
import { AppDispatch } from '../../client/store'
import { getCheckoutPathWithCartId } from 'lib/url/pathsUtils'
import { navigateToNextStepTourCheckout } from 'components/App/Header/HeaderCheckout/StepsHeaderCheckout/steps/tours'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'

export function skipFlights() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const flightItems = getFlightItems(state)
    flightItems.forEach(item => {
      dispatch(removeItem(item.itemId))
    })
    dispatch(navigateToNextPage())
  }
}

export function navigateToNextPage(replace?: boolean) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    // let's work out what product flow on the checkout we are in
    const isTourV2 = getTourV2Items(state).length > 0
    if (isTourV2) {
      // Must be a tour flow, use the tour step logic
      dispatch(navigateToNextStepTourCheckout())
      return
    }
    // Must be a hotel flow, use the hotel step logic
    // Todo: Add support for other product flows
    const page = getNextCheckoutPage(state)
    const search = getSearchParams(state, page)
    const cartId = state.checkout.cart.cartId

    const url = getCheckoutPathWithCartId(page, cartId)

    if (replace) {
      dispatch(replaceWithRegion(url, search))
    } else {
      dispatch(pushWithRegion(url, search))
    }
  }
}

interface NavigateToCheckoutOptions {
  replace?: boolean,
  skipSpoofedModal?: boolean
}

export function navigateToCheckout({
  replace,
  skipSpoofedModal,
}: NavigateToCheckoutOptions = {}) {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()
    if (
      config.UNIVERSAL_CHECKOUT_SPOOFED_MULTI_CART_ITEMS_ENABLED &&
      state.checkout.cart.isMultiItemMode &&
      !skipSpoofedModal
    ) {
      dispatch(showCheckoutCartModeModal())
    } else {
      dispatch(navigateToNextPage(replace))
    }
  }
}

export const restartCheckout = navigateToNextPage

export function getCurrentCheckoutPage(state: App.State) {
  return last(state.router.location.pathname.split('/'))
}

function getNextCheckoutPage(state: App.State): CheckoutPageId {
  const curPage = getCurrentCheckoutPage(state)
  if (
    curPage !== CheckoutPageId.LuxPlus &&
    curPage !== CheckoutPageId.Experiences &&
    curPage !== CheckoutPageId.ReturningFlights &&
    curPage !== CheckoutPageId.CruiseV2 &&
    isLuxPlusAvailableToAddToCart(state)
  ) {
    return CheckoutPageId.LuxPlus
  }

  if (curPage !== CheckoutPageId.DepartingFlights && isFlightsPendingToAddToCart(state)) {
    return CheckoutPageId.DepartingFlights
  }

  if (curPage !== CheckoutPageId.TourV1Capacity && hasIncompleteTourItem(state)) {
    return CheckoutPageId.TourV1Capacity
  }

  if (config.EXPERIENCES_ENABLED && curPage !== CheckoutPageId.Experiences && isExperiencesSupportedToAddToCart(state)) {
    return CheckoutPageId.Experiences
  }

  if (
    (curPage === CheckoutPageId.ChangeDates ||
      curPage === CheckoutPageId.ChangePackage) &&
    hasInsuranceItem(state)
  ) {
    return CheckoutPageId.InsuranceSelectDates
  }

  return CheckoutPageId.Purchase
}

function getSearchParams(state: App.State, page: CheckoutPageId): string | undefined {
  switch (page) {
    case CheckoutPageId.DepartingFlights:
      return getInflowFlightSearchParams(state)
  }
}
