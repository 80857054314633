import { LOCATION_CHANGE } from 'connected-react-router'

import {
  INTERACTION_STUDIO_EVENT_ACTION,
} from 'constants/analytics'

import {
  OFFER_PRODUCT_DETAIL_VIEW,
  API_CALL_SUCCESS,
  EXPERIENCE_VIEWED,
} from 'actions/actionConstants'
import { AnyAction } from 'redux'
import * as InteractionStudioService from 'api/interactionStudio'
import { USER_LOGIN } from 'actions/apiActionConstants'
import { getSFMCCookies, baseData } from './interactionStudioData'

interface Evt {
  data: any
  path?: string
  tid?: string
}

interface Mapping {
  on: string
  evt: (state: App.State, action: AnyAction) => Evt
}

function memoryStore() {
  let tid: string | undefined
  return {
    get: (argTid: string): string => tid || argTid,
    set: (newTid: string) => tid = newTid,
  }
}

function sessionStore() {
  return {
    get: (argTid: string): string => (window?.sessionStorage.getItem('tid') || argTid),
    set: (newTid: string) => (window?.sessionStorage.setItem('tid', newTid)),
  }
}

const store = window?.sessionStorage ? sessionStore() : memoryStore()

const apiSuccessEvents = {
  [USER_LOGIN]: state => ({
    path: INTERACTION_STUDIO_EVENT_ACTION.Login,
    data: baseData(state),
  }),
}

const eventArray: Array<Mapping> = [
  {
    on: OFFER_PRODUCT_DETAIL_VIEW,
    evt: (state, action) => ({
      path: INTERACTION_STUDIO_EVENT_ACTION.ViewOffer,
      data: {
        ...baseData(state),
        ...getSFMCCookies(),
        offer_id: action.data.id,
        recommendationTrackingCode: action.data.recommendationTrackingCode,
      },
    }),
  },
  {
    on: EXPERIENCE_VIEWED,
    evt: (state, action) => {
      return {
        path: INTERACTION_STUDIO_EVENT_ACTION.ViewExperienceOffer,
        data: {
          ...baseData(state),
          ...getSFMCCookies(),
          offer_id: action.id,
        },
      }
    },
  },
  {
    on: API_CALL_SUCCESS,
    evt: (state, action) => apiSuccessEvents[action.api]?.(state, action),
  },
  {
    on: LOCATION_CHANGE,
    evt: state => {
      const { pathname } = state.router.location

      if (pathname.match(/^\/[a-z]{2}$/)) {
        return {
          path: INTERACTION_STUDIO_EVENT_ACTION.ViewOfferList,
          data: baseData(state),
        }
      }

      if (pathname.match(/^\/[a-z]{2}\/hotels$/)) {
        return {
          path: INTERACTION_STUDIO_EVENT_ACTION.ViewHotelOfferList,
          data: baseData(state),
        }
      }

      if (pathname.match(/^\/[a-z]{2}\/tours$/)) {
        return {
          path: INTERACTION_STUDIO_EVENT_ACTION.ViewTourOfferList,
          data: baseData(state),
        }
      }

      return {
        path: null,
        data: {},
      }
    },
  },
]

async function touch(data, path, tid) {
  const { result } = await InteractionStudioService.touch(data, path, store.get(tid))
  store.set(result.tid)
}

export function target(): (events: Array<Evt>) => void {
  return async(events) => {
    for (const evt of events) {
      if (!evt?.path) {
        return
      }

      try {
        await touch(evt.data, evt.path, evt.tid)
      } catch (e) {
        // noop
      }
    }
  }
}

export function events() {
  return eventArray.reduce((accumulator, x) => {
    accumulator[x.on] = (_action: any, prevState: App.State, nextState: App.State) => {
      try {
        return {
          tid: store.get(nextState.interactionStudio.tid),
          ...x.evt(nextState, _action),
        }
      } catch (e) {
        return {
          tid: store.get(nextState.interactionStudio.tid),
        }
      }
    }
    return accumulator
  }, {})
}
