import createSelector from 'lib/web/createSelector'
import { isAbandonedCart, isHotLead, isRecentlyViewed, isPriceMissing } from 'lib/offer/highIntentOffers'
import { isHotelType, isTourV2OfferType } from 'lib/offer/offerTypes'
import { isLereEnabled } from 'lib/lere/isLereEnabled'

export const getAllAbandonedCarts = createSelector(
  (state: App.State) => state.recommendations.highIntent.offers,
  (highIntentOffers) => highIntentOffers.filter(isAbandonedCart),
)

export const getHighIntentOffers = createSelector(
  (state: App.State) => state.recommendations.highIntent.offers,
  (offers) => {
    if (!isLereEnabled()) { return [] }
    // remove unsupported HL (we only support hotel HL atm
    return offers.filter(offer => isAbandonedCart(offer) || isRecentlyViewed(offer) && !isPriceMissing(offer) || isHotLead(offer) && isHotelType(offer.offerType))
  },
)

export const getHighIntentOfferIds = createSelector(
  getHighIntentOffers,
  (highIntentOffers: Array<App.HighIntentOffer>) => highIntentOffers.map(offer => offer.offerId),
)

// used for high intent carousel
export const getHighIntentTourOffers = createSelector(
  getHighIntentOffers,
  (offers: Array<App.HighIntentOffer>) => offers
    .filter(offer => isTourV2OfferType(offer.offerType)),
)

// used for tour carousel on the homepage
export const getHighIntentTours = createSelector(
  (state: App.State) => state.recommendations.highIntent.offers,
  (offers: Array<App.HighIntentOffer>): Array<App.HighIntentOffer> => offers.filter(offer => isTourV2OfferType(offer.offerType)),
)

export const getHighIntentHotelOffers = createSelector(
  getHighIntentOffers,
  (offers:Array<App.HighIntentOffer>) => offers.filter(offer => isHotelType(offer.offerType)),
)

export const getHighIntentHotelOfferIds = createSelector(
  getHighIntentHotelOffers,
  (offers:Array<App.HighIntentOffer>) => offers.map(offer => offer.offerId),
)

export const getRecentlyViewedOffers = createSelector(
  getHighIntentHotelOffers,
  (offers: Array<App.HighIntentOffer>) => offers.filter(isRecentlyViewed),
)
