import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import {
  OFFER_TYPE_TOUR,
} from 'constants/offer'
import PriceRowFlightPackage from 'components/Luxkit/PricePoints/PriceRowFlightPackage'
import GeoContext from 'contexts/geoContext'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'

interface Props {
  offer: App.Offer | App.OfferSummary;
  flightOrigin?: string;
}

function FrontPageTileFlightPricing(props: Props) {
  const {
    offer,
    flightOrigin,
  } = props

  const { currentRegionHasFlights } = useContext(GeoContext)

  const {
    flightCacheDisabled,
    lowestPricePackage,
    type,
    offerFlightsEnabled,
  } = offer

  const {
    totalWithFlights,
  } = useOfferPackagePrice(lowestPricePackage!, offer, { flightPrice: offer.flightPrices[flightOrigin || ''] })

  if (!currentRegionHasFlights || !offerFlightsEnabled) {
    return null
  }

  const flightCacheOn = !flightCacheDisabled && offer.flightPrices[flightOrigin || '']
  if (flightCacheOn && type !== OFFER_TYPE_TOUR) {
    return <PriceRowFlightPackage
      product="hotel"
      price={totalWithFlights}
    />
  }

  const isFlightsEnabledWithTours = type === OFFER_TYPE_TOUR && offerFlightsEnabled

  return <PriceRowFlightPackage price={undefined} product={isFlightsEnabledWithTours ? 'tour' : 'hotel'} />
}

function mapStateToProps(state: App.State) {
  return {
    flightOrigin: getDefaultAirportCode(state),
  }
}

export default connect(mapStateToProps)(FrontPageTileFlightPricing)
