import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFileMinusAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M8 8a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H8Zm5 12H6a1.0002 1.0002 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v5a1.0001 1.0001 0 0 0 2 0V8.94a1.307 1.307 0 0 0-.06-.27v-.09a1.0702 1.0702 0 0 0-.19-.28l-6-6a1.071 1.071 0 0 0-.28-.19.29.29 0 0 0-.1 0 1.099 1.099 0 0 0-.31-.11H6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h7a1.0001 1.0001 0 0 0 0-2Zm0-14.59L15.59 8H14a1 1 0 0 1-1-1V5.41ZM20 18h-4a1.0001 1.0001 0 0 0 0 2h4a1.0001 1.0001 0 0 0 0-2Zm-7-2H8a1.0002 1.0002 0 0 0-.7071 1.7071C7.4804 17.8946 7.7348 18 8 18h5a1.0001 1.0001 0 0 0 0-2Zm1-4H8a1.0002 1.0002 0 0 0-.7071 1.7071C7.4804 13.8946 7.7348 14 8 14h6a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineFileMinusAltIcon
