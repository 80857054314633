import { isActiveLuxPlusMember, isActiveSubscribedLuxPlusMember, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import {
  API_CALL, CLEAR_LOYALTY_MEMBER_SUBSCRIPTION,
} from './actionConstants'
import {
  FETCH_LOYALTY_PRODUCT_SUBSCRIPTION,
  FETCH_LOYALTY_MEMBER_SUBSCRIPTION,
  UPDATE_LOYALTY_MEMBER_SUBSCRIPTION,
  CREATE_LOYALTY_MEMBER_FREE_PREVIEW,
  FETCH_LOYALTY_MEMBER_FREE_PREVIEW_ELIGIBILITY,
  FETCH_LOYALTY_PREFERRED_PAYMENT_METHOD,
} from './apiActionConstants'
import {
  getMemberSubscription,
  getSubscriptionOffer,
  getMemberSubscriptionPoll,
  updateMemberSubscriptionRenewal,
  createMemberFreePreviewPeriod,
  getMemberFreePreviewEligibility,
  MemberSubscriptionUpdateData,
} from 'api/luxPlus'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { getStripeOffSessionCardByRegionAndPurposeId } from 'api/payment'
import { appViewMessageHandler } from 'lib/window/webViewUtils'

export function fetchProductSubscription() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (
      !isLuxPlusEnabled(state) ||
      state.loyalty.products.subscription.offers ||
      state.loyalty.products.subscription.fetching ||
      state.loyalty.products.subscription.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LOYALTY_PRODUCT_SUBSCRIPTION,
      request: () => getSubscriptionOffer(state.geo.currentRegionCode),
    })
  }
}

interface FetchMemberSubscriptionParams {
  refetch?: boolean
  poll?: boolean
  validate?: (result: App.MemberSubscriptionItem) => boolean
}

const checkValidMemberSubscriptionDispatch = (state: App.State, memberId?: string): boolean => {
  if (!memberId || state.loyalty.member.subscription.fetching) {
    return false
  }

  return true
}

export function fetchMemberSubscription(params: FetchMemberSubscriptionParams = {}) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const memberId = state.auth.account.memberId
    const accessToken = state.auth.accessToken

    const isValidDispatch = checkValidMemberSubscriptionDispatch(state, memberId)
    if (!isValidDispatch) return

    /*
      We want to refetch when a new membership has been bought. We want to prevent fetching
      if loyalty returns and error or if the user account does not have a membership.
     */
    const shouldFetch = !state.loyalty.member.subscription.error && state.auth.account.subscriberTier && !state.loyalty.member.subscription.item && isLuxPlusEnabled(state)
    if (params.refetch || shouldFetch) {
      dispatch({
        type: API_CALL,
        api: FETCH_LOYALTY_MEMBER_SUBSCRIPTION,
        request: () => {
          if (params.poll) {
            return getMemberSubscriptionPoll(memberId!, accessToken, params?.validate)
          } else {
            return getMemberSubscription(memberId!, accessToken)
          }
        },
      })
    }
  }
}

export function updateMemberSubscription(data: MemberSubscriptionUpdateData) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const memberId = state.auth.account.memberId

    const isValidDispatch = checkValidMemberSubscriptionDispatch(state, memberId)
    if (!isValidDispatch) return

    dispatch({
      type: API_CALL,
      api: UPDATE_LOYALTY_MEMBER_SUBSCRIPTION,
      request: () => updateMemberSubscriptionRenewal(memberId!, data),
    })
  }
}

export function clearMemberSubscription() {
  return {
    type: CLEAR_LOYALTY_MEMBER_SUBSCRIPTION,
  }
}

export function fetchMemberFreePreviewEligibility() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const memberId = state.auth.account.memberId
    const freePreview = state.loyalty.products.freePreview

    if (
      !memberId ||
      !isLuxPlusEnabled(state) ||
      freePreview.fetching ||
      freePreview.error ||
      freePreview.offer
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LOYALTY_MEMBER_FREE_PREVIEW_ELIGIBILITY,
      request: () => getMemberFreePreviewEligibility(memberId),
    })
  }
}

export function createFreePreviewMember() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const memberId = state.auth.account.memberId
    const accessToken = state.auth.accessToken
    const subscription = state.loyalty.member.subscription
    const firstName = state.auth.account.givenName
    const headlessMode = state.config.headlessMode
    let joined = false

    if (
      !memberId ||
      !isLuxPlusEnabled(state) ||
      subscription.fetching ||
      subscription.error ||
      isActiveLuxPlusMember(state)
    ) return

    dispatch({
      type: API_CALL,
      api: CREATE_LOYALTY_MEMBER_FREE_PREVIEW,
      request: () => createMemberFreePreviewPeriod({
        customerId: memberId,
        region: state.geo.currentRegionCode,
      }, accessToken)
        .then((result) => {
          joined = true
          showSnackbar(
            `${firstName ? `${firstName}, your` : 'Your'} ${LUXURY_PLUS.FREE_PREVIEW_DURATION}-day free preview for ${LUXURY_PLUS.PROGRAM_NAME} has started. Enjoy!`,
            'success',
          )
          return result
        })
        .catch((err) => {
          showSnackbar(
            'Please try again later or contact us for assistance.',
            'critical',
            { heading: 'Oops! Something went wrong' },
          )

          return err
        })
        .finally(() => {
          if (headlessMode) {
            appViewMessageHandler('luxPlusFreePreview', { joined })
          }
        }),
    })
  }
}

export function fetchLuxPlusPreferredPaymentMethod() {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusMemberSubscriptionId = state.loyalty.member.subscription.item?.id
    const activeSubscribedLuxPlusMember = isActiveSubscribedLuxPlusMember(state)

    if (!luxPlusMemberSubscriptionId || !activeSubscribedLuxPlusMember) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LOYALTY_PREFERRED_PAYMENT_METHOD,
      request: () => getStripeOffSessionCardByRegionAndPurposeId(state.geo.currentRegionCode, luxPlusMemberSubscriptionId),
    })
  }
}
