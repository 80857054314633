import { addRegionToPath } from 'lib/url/searchUrlUtils'
import { sha256 } from 'lib/utils/createHashUtils'
import { isEmpty, max, sum, take } from 'lib/array/arrayUtils'
import { RATINGS } from 'constants/analytics'
import { UAEvent } from 'api/googleTagManager'
import { BuyerType } from 'reducers/analytics'
import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_FLIGHT,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V1_ANALYTICS,
  OFFER_TYPE_TOUR_V2_ANALYTICS, OFFER_TYPE_BED_BANK_ANALYTICS,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_VILLA,
} from 'constants/offer'
import { AnyAction } from 'redux'
import { findCheapestOfTourV2Offer } from 'lib/tours/tourUtils'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import { pushResetEcommerce } from '../enhanced-ecommerce'
import { isBedbank, isBundleOffer, isLEHotel, isTourV1Offer, isTourV2Offer } from 'lib/offer/offerTypes'
import { getOfferName, getOfferPropertyName } from 'lib/offer/offerUtils'
import { PROMO_PAYMENT_TYPE } from 'constants/payment'
import { adjustForDecimal } from 'lib/maths/mathUtils'
import { getUserOrderCount } from 'api/order'
import { getMemberSubscription } from 'api/luxPlus'
import { generateLuxPlusSubscriptionItems } from 'lib/checkout/luxPlusSubscription/cart'

export * from './tripPlanner'

interface TransactionProduct {
  name: string;
  id: string;
  price: string | number;
  category: string;
  quantity: number;
  productRating: number;
  [key: string]: any;
}

type OfferTypes = 'cruise' | 'experience' | 'hotel' | 'tour' | 'flight' | 'travelinsurance' | 'subscription'

interface OfferReturnTypes<T> {
  event: string,
  ecommerce: T
}

type ViewItemItemsType = {
  affiliation: string;
  item_id: string;
  item_name: string;
  item_brand?: string;
  item_category: string;
  item_category2: string;
  item_category3?: string;
  item_category4?: string;
  index: number;
  location_id?: string;
  discount?: number;
  price: number;
  quantity: number;
}

type ViewItemReturnType = {
  currency: string,
  value: number,
  items: Array<ViewItemItemsType>,
}

// #region Base building blocks

export const buildProductCategory = (offer: App.OfferBase): string => `${offer.parentType}/${offer.analyticsType}`

function propertyLocation(offer: App.Offer | App.OfferSummary, offerPackage: App.Package, parentType) {
  const geo = (parentType === OFFER_TYPE_TOUR ? offerPackage.tour?.geoData : offer.property?.geoData) ?? ({} as { [key: string]: undefined })
  return {
    continent: geo.continentCode,
    countryCode: geo.countryCode,
    country: geo.country,
    administrativeAreaLevel1: geo.administrativeAreaLevel1,
    administrativeAreaLevel2: geo.administrativeAreaLevel2,
    administrativeAreaLevel3: geo.administrativeAreaLevel3,
  }
}

async function userSessionDetails(details: App.State, isSignUp = false) {
  const { auth, geo, utm } = details

  // TODO: this is a bit of a hack since we have lots of restriction here
  // 1.svc-auth depends on other services -> we can't call svc-orders there
  // 2.We call this function in a middleware, we also dispatch get user order from another middleware (and the dispatch comes after this function) -> we have incorrect user status
  let userPurchaseCount
  let membershipStatus
  if (!isSignUp) {
    userPurchaseCount = await getUserOrderCount(details.auth.account.memberId)
    if (auth.account.subscriberTier) {
      membershipStatus = await getMemberSubscription(details.auth.account.memberId!)
    }
  }
  return {
    userDetails: {
      userID: auth.account.memberId,
      userCountry: auth.account.countryCode,
      userEmail: sha256(auth.account.email),
      userEmailRaw: auth.account.email,
      personContactID: auth.account.personContactId,
      buyerType: userPurchaseCount && userPurchaseCount?.total_purchase_count > 0 ? BuyerType.existing : BuyerType.new,
      utmSource: auth.account.utmSource,
      utmMedium: auth.account.utmMedium,
      utmCampaign: auth.account.utmCampaign,
      utmContent: auth.account.utmContent,
      utmTerm: auth.account.utmTerm,
      utmAdgroup: auth.account.utmAdgroup,
      isMember: !!(membershipStatus && membershipStatus.status === 'ACTIVE' && membershipStatus.region === geo.currentRegionCode),
    },
    sessionDetails: {
      loggedIn: 'loggedIN',
      selectedLocation: geo.currentRegionCode,
      utm,
    },
  }
}

// #endregion

// #region Enhanced e-commerce builders

type integer = number;
export interface SnowplowImpressionData extends Record<string, unknown> {
  id: string;
  name: string;
  productType?: string;
  position?: integer;
  currency: string;
  price?: number;
  listId: string;
  listName: string;
}

export interface SnowplowClickData extends Record<string, unknown> {
  id: string;
  name: string;
  productType: string;
  position: integer;
  currency: string;
  price: number;
  listId: string;
  listName: string;
}
export interface GAImpressionFieldObject {
  id: string;
  name: string;
  list: string;
  category?: string;
  variant?: string;
  position?: integer;
  price?: number;
  quantity: number;
}

export interface GAProductFieldObject {
  id: string;
  name: string;
  category?: string;
  price?: number;
  memberPrice?: number;
  position?: integer;
  quantity?: integer;
}

function getPrice(offer: App.AnyOffer) {
  switch (offer.type) {
    case 'partner_tour':
    case 'direct_tour':
    case 'connection_tour':
      const { cheapestPurchasableOption } = findCheapestOfTourV2Offer(offer, undefined)
      return cheapestPurchasableOption?.price
    case OFFER_TYPE_BED_BANK:
      return (offer as App.BedbankOfferWithRate).rate?.totals.inclusive
    case OFFER_TYPE_TOUR:
    case OFFER_TYPE_ALWAYS_ON:
    case OFFER_TYPE_LAST_MINUTE:
    case OFFER_TYPE_HOTEL:
    case OFFER_TYPE_VILLA:
      return offer.lowestPricePackage?.price
    case OFFER_TYPE_BUNDLE_AND_SAVE:
      return offer.lowestPriceBundlePackage?.price
    default:
      return undefined
  }
}

export function productImpressionGenericObject(
  {
    id,
    name,
    category,
    price,
    listName,
    position,
  }: {
    id: string;
    name: string;
    category?: string;
    price?: number;
    listName?: string;
    position?: number;
  },
): GAImpressionFieldObject {
  return {
    id,
    name,
    category,
    ...(price !== undefined && { price }),
    quantity: 1,
    position,
    list: listName,
  }
}

export function productImpressionObject(
  offer: App.AnyOffer,
  listName: string,
  position: number,
): GAImpressionFieldObject {
  return productImpressionGenericObject({
    id: offer.id,
    name: getOfferName(offer),
    category: offer.analyticsType,
    price: getPrice(offer),
    position,
    listName,
  })
}

export function experienceImpressionObject(
  experience: App.ExperienceOffer,
  listName: string,
  position: number,
): GAImpressionFieldObject {
  return productImpressionGenericObject({
    id: experience.id,
    name: experience.name,
    category: 'experiences_offer',
    price: experience.price,
    listName,
    position,
  })
}

function getOfferPrice(offer: App.AnyOffer): number | undefined {
  if (offer.type === 'bedbank_hotel') {
    if ('rate' in offer) {
      return offer.rate?.totals.inclusive
    }
    return undefined
  } else if (offer.parentType === 'tour_v2') {
    const cheapestOption = findCheapestTourV2PurchasableOption(offer)
    return cheapestOption?.price
  } else if (offer.type === 'cruise') {
    return offer.lowestDeparturePrice.adultFare
  } else if (isBundleOffer(offer)) {
    return offer.lowestPriceBundlePackage?.price
  } else {
    return offer.lowestPricePackage?.price
  }
}

export function productClickGenericObject(
  { id, name, category, price, position }:
    { id: string; name: string; category?: string; price?: number; position?: number },
): GAProductFieldObject {
  return {
    id,
    name,
    category,
    price,
    quantity: 1,
    position,
  }
}

export function productClickObject(offer: App.AnyOffer, position: number): GAProductFieldObject {
  return productClickGenericObject(
    {
      id: offer.id,
      name: getOfferName(offer),
      category: offer.analyticsType,
      price: getOfferPrice(offer),
      position,
    },
  )
}

function offerInfo(offer: App.Offer | App.OfferSummary, position?: number, list?: string) {
  const { id, parentType, lowestPricePackage: pkg, offerFlightsEnabled } = offer

  const location = propertyLocation(offer, pkg, parentType)
  return {
    _clear: true,
    actionField: list ? { list } : {},
    products: [{
      name: getOfferPropertyName(offer),
      id,
      price: pkg?.price,
      basePrice: pkg?.trackingPrice,
      category: buildProductCategory(offer),
      subCategory: offer.analyticsType === OFFER_TYPE_TOUR ? 'cruiseFlash' : offer.analyticsType,
      ...location,
      flightsEnabled: offerFlightsEnabled ? 'Yes' : 'No',
      productRating: offer.analyticsType === OFFER_TYPE_TOUR ? RATINGS.cruiseFlash : RATINGS[offer.analyticsType],
      position,
    }],
  }
}

const bedbankOfferInfo = (offer: App.BedbankOffer) => ({
  _clear: true,
  products: [
    {
      name: offer.name,
      id: offer.id,
      category: buildProductCategory(offer),
      subCategory: offer.analyticsType,
      variant: offer.analyticsType,
      productRating: RATINGS[offer.analyticsType],
    },
  ],
})

const experienceOfferInfo = (offer: App.ExperienceOffer) => ({
  _clear: true,
  products: [
    {
      name: offer.name,
      id: offer.id,
      category: 'experience',
      subCategory: offer.id.startsWith('rez') ? 'Rezdy' : 'LED',
      productRating: offer.id.startsWith('rez') ? RATINGS.experienceRezdy : RATINGS.experienceLED,
    },
  ],
})

const toursV2OfferInfo = (offer: Tours.TourV2Offer) => ({
  _clear: true,
  products: [
    {
      name: offer.name,
      id: offer.id,
      category: buildProductCategory(offer),
      subCategory: offer.productType === 'connection_tour' ? 'tourTTC' : 'tourLE',
      productRating: offer.productType === 'connection_tour' ? RATINGS.tourTTC : RATINGS.tourLE,
    },
  ],
})

// #endregion

export function subscriptionComplete(email: string) {
  return {
    hitType: 'subscription-complete',
    userDetails: {
      userID: '',
      userEmail: sha256(email),
      subscriberID: null,
    },
  }
}

export function offerClicked(offer: App.Offer | App.OfferSummary, currency: string, position?: number) {
  return {
    hitType: 'product-click',
    ecommerce: {
      currencyCode: currency,
      click: offerInfo(offer, position),
    },
  }
}

export function mapOfferItems(
  offer: any,
  offerType: OfferTypes,
  quantity?: number,
  flightObjects?: { origin: App.Airport, destination: App.Airport },
): Array<ViewItemItemsType> {
  // affiliation is the same as item_category but with the first char caps
  const affiliation = offerType?.[0]?.toUpperCase() + offerType?.slice(1)
  switch (offerType) {
    case 'hotel':
      const accommodationPrice = (offer?.lowestPricePackage?.price + offer?.lowestPricePackage?.propertyFees) || 0

      return [{
        affiliation,
        item_id: offer?.id,
        item_name: offer?.name,
        item_category: offerType,
        item_category2: offer?.type,
        index: 0,
        location_id: offer?.property?.geoData?.placeId,
        discount: accommodationPrice / offer?.lowestPricePackage?.value || 1,
        price: accommodationPrice,
        quantity: quantity ?? 1,
      }]
    case 'experience':
      return [{
        affiliation,
        item_id: offer?.id,
        item_name: offer?.name,
        item_brand: offer?.vendor?.name,
        item_category: offerType,
        item_category2: offer?.productType,
        index: 0,
        discount: offer?.discount ?? 0,
        price: offer?.price ?? 0,
        quantity: quantity ?? 1,
      }]
    case 'cruise':
      return [{
        affiliation: 'Cruise',
        item_id: offer?.id,
        item_name: offer?.name,
        item_brand: 'cruiseLine' in offer ? offer?.cruiseLine?.name : null,
        item_category: offerType,
        item_category2: offerType,
        index: 0,
        price: offer?.lowestDeparturePrice?.total ?? 0,
        quantity: quantity ?? 1,
      }]
    case 'tour':
      const tourPrice = (offer?.lowestPricePackage?.price + offer?.lowestPricePackage?.propertyFees) || 0

      return [{
        affiliation,
        item_id: offer?.id,
        item_name: offer?.name,
        item_brand: offer?.brand?.name,
        item_category: offerType,
        item_category2: offer.parentType,
        index: 0,
        discount: tourPrice / offer?.lowestPricePackage?.value || 1,
        price: tourPrice,
        quantity: quantity ?? 1,
      }]
    case 'flight':
      let itemName = ''
      let carrierName = ''

      if (flightObjects && !isEmpty(flightObjects)) {
        itemName = `${offer?.validatingCarrierName}: ${flightObjects?.origin?.name}(${flightObjects?.origin?.code}) - ${flightObjects?.destination?.name}(${flightObjects?.destination?.code})`
        carrierName = offer?.validatingCarrierName
      } else {
        itemName = `${offer?.carrierName}: ${offer?.departingAirportName}(${offer?.departingAirport}) - ${offer?.arrivalAirportName}(${offer?.arrivalAirport})`
        carrierName = offer?.carrierName
      }

      return [{
        affiliation,
        item_id: offer?.id,
        item_name: itemName,
        item_brand: carrierName,
        item_category: offerType,
        item_category2: offer?.fareClass,
        item_category3: offer?.provider,
        item_category4: carrierName,
        index: 0,
        price: offer?.cost ?? 0,
        quantity: quantity ?? 1,
      }]
    case 'travelinsurance':
      return [{
        affiliation: 'Travel Insurance',
        item_id: offer?.id,
        item_name: offer?.name,
        item_category: offerType,
        item_category2: offerType,
        index: 0,
        price: offer?.total ?? 0,
        quantity: quantity ?? 1,
      }]
    case 'subscription':
      const items = generateLuxPlusSubscriptionItems(offer)
      const results:Array<ViewItemItemsType> = []
      items.forEach((item, index) => {
        let itemId

        if (item.itemType === 'luxury-plus-subscription') itemId = item.offerId
        if (item.itemType === 'subscription-join') itemId = item.subscriptionOfferId

        const mappedItem = {
          item_id: itemId,
          affiliation: item.itemType === 'luxury-plus-subscription' ? 'recurring_fee' : 'joining_fee',
          index,
          item_category: 'membership',
          item_category2: 'luxury_plus',
          item_list_id: 'lux_plus',
          item_list_name: 'lux_plus',
          item_name: 'LuxPlus',
          price: item.amount,
          quantity: 1,
        }
        if (!('waived' in item) || !item.waived) {
          results.push(mappedItem)
        }
      })

      return results
    default:
      return []
  }
}

export function cruiseOfferClicked(cruiseOffer: App.CruiseOffer | App.Offer, currency: string | null = null): OfferReturnTypes<ViewItemReturnType> {
  // we also handle legacy cruise items here
  return {
    event: 'view_item',
    ecommerce: {
      currency: 'lowestDeparturePrice' in cruiseOffer ? cruiseOffer?.lowestDeparturePrice?.currencyCode : currency,
      value: 'lowestDeparturePrice' in cruiseOffer ? cruiseOffer?.lowestDeparturePrice?.total : cruiseOffer?.lowestPricePackage?.value,
      items: mapOfferItems(cruiseOffer, 'cruise'),
    },
  }
}

export function experienceOfferClicked(experienceOffer: App.ExperienceOffer, currency: string): OfferReturnTypes<ViewItemReturnType> {
  return {
    event: 'view_item',
    ecommerce: {
      currency,
      value: experienceOffer?.price,
      items: mapOfferItems(experienceOffer, 'experience'),
    },
  }
}

export function accommodationOfferClicked(offer: App.AnyOffer, currency: string): OfferReturnTypes<ViewItemReturnType> {
  return {
    event: 'view_item',
    ecommerce: {
      currency,
      value: null, // TODO: there are multiple price points, not sure which to include
      items: mapOfferItems(offer, 'hotel'),
    },
  }
}

export function flightOfferClicked(flightOffer: App.Flight, currency: string, id: string, cost: number, provider: string): OfferReturnTypes<ViewItemReturnType> {
  return {
    event: 'view_item',
    ecommerce: {
      currency,
      value: cost, // TODO: there are multiple price points, not sure which to include
      items: mapOfferItems({ ...flightOffer, id, cost, provider }, 'flight'),
    },
  }
}

export function tourOfferClicked(tourOffer: Tours.TourV2Offer, currency: string): OfferReturnTypes<ViewItemReturnType> {
  return {
    event: 'view_item',
    ecommerce: {
      currency,
      value: tourOffer?.purchasableOptions[0]?.valuedAt,
      items: mapOfferItems(tourOffer, 'tour'),
    },
  }
}

export function addToCartGTM(
  offer: any,
  currency: string,
  value: number,
  quantity?: number,
  offerType?: OfferTypes,
  flightObjects?: { origin: App.Airport, destination: App.Airport },
) {
  let mappedOfferItems = mapOfferItems(offer, offerType, quantity, flightObjects)
  mappedOfferItems = mappedOfferItems.map(item => {
    const price = item.item_category === 'membership' ? item.price : value
    return { ...item, price }
  })

  if (!mappedOfferItems) return

  return {
    event: 'add_to_cart',
    ecommerce: {
      currency,
      value,
      items: mappedOfferItems,
    },
  }
}

export function productClick(action: AnyAction & { data: App.Offer, tracking?: any }, prevState: App.State, nextState: App.State) {
  const { geo } = nextState

  return {
    hitType: 'product-click',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      click: offerInfo(action.data, action.tracking?.position, action.tracking?.listName),
    },
  }
}

export const userLogout = () => ({
  hitType: 'user-logout',
  userDetails: {
    userID: '',
    userEmail: '',
    userEmailRaw: '',
    subscriberID: '',
    personContactID: '',
  },
  sessionDetails: {
    loggedIn: 'loggedOUT',
  },
  loginSource: '',
})

export const signupAttempt = action => ({
  hitType: 'signup-attempt',
  signupSource: action.source,
})

export const signupFail = (action, prevState) => ({
  hitType: 'signup-fail',
  signupSource: prevState.auth.source,
})

export async function signupSuccess(action, prevState, nextState) {
  const details = await userSessionDetails(nextState, true)
  return {
    hitType: 'signup-complete',
    signupSource: prevState.auth.source,
    userDetails: details.userDetails,
    sessionDetails: details.sessionDetails,
  }
}

export const productDetailView = (action, prevState: App.State, nextState: App.State) => {
  const { geo } = nextState
  return {
    hitType: 'product-detail-view',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      detail: offerInfo(action.data),
    },
  }
}

export const bedbankProductDetailView = (action, prevState: App.State, nextState: App.State) => {
  const { geo } = nextState
  return {
    hitType: 'product-detail-view',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      detail: bedbankOfferInfo(action.data),
    },
  }
}

export const experienceProductDetailView = (action, prevState: App.State, nextState: App.State) => {
  const { geo } = nextState
  return {
    hitType: 'product-detail-view',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      detail: experienceOfferInfo(action.experience),
    },
  }
}

export const toursV2ProductDetailView = (action, prevState: App.State, nextState: App.State) => {
  const { geo } = nextState
  return {
    hitType: 'product-detail-view',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      detail: toursV2OfferInfo(action.data),
    },
  }
}

export const toursV2VariationView = (action, prevState: App.State, nextState: App.State) => {
  const { geo } = nextState
  return {
    hitType: 'product-variation-view',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      detail: toursV2OfferInfo(action.data),
    },
  }
}

export const loginAttempt = action => {
  if (!action.source) {
    return null
  }
  return {
    hitType: 'login-attempt',
    loginSource: action.source,
  }
}

export const loginFail = (action, prevState: App.State) => {
  if (!prevState.auth.source) {
    return null
  }
  return {
    hitType: 'login-fail',
    loginSource: prevState.auth.source,
  }
}

export const loginSuccess = async(action, prevState: App.State, nextState: App.State) => {
  if (!prevState.auth.source) {
    return null
  }

  const details = await userSessionDetails(nextState)

  return {
    hitType: 'login-complete',
    loginSource: prevState.auth.source,
    userDetails: details.userDetails,
    sessionDetails: details.sessionDetails,
  }
}

function mapGiftCardToProduct(
  price: number,
  currencyCode: string,
  orderItem?: App.OrderGiftCardItem,
): TransactionProduct {
  return {
    ...(orderItem ?? {}),
    name: 'Gift Card',
    id: `gift-card-${currencyCode}-${price}`,
    price,
    category: 'gift-card',
    quantity: 1,
    productRating: 0,
  }
}

export function giftCardDetailView(geo: App.GeoState) {
  return {
    hitType: 'product-detail-view',
    ecommerce: {
      currencyCode: geo.currentCurrency,
      detail: {
        _clear: true,
        actionField: {
          action: 'detail',
        },
        products: [mapGiftCardToProduct(50, geo.currentCurrency)],
      },
    },
  }
}

export const pageView = (action, prevState: App.State, nextState: App.State) => {
  const { pathname } = action.payload.location
  const { geo } = nextState
  const giftCardsPath = addRegionToPath('/gift-cards', geo.currentRegionCode)
  let detailView
  if (pathname === giftCardsPath) {
    detailView = giftCardDetailView(geo)
  }
  const pageView = {
    hitType: 'pageview',
    page: pathname,
    previousPage: nextState.routeHistory.prevPath,
  }
  return [pageView, detailView]
}

export const offerShareButtonClick = action => ({
  hitType: 'offer-share-click',
  shareDetails: {
    id: action.data.pageId,
    type: action.data.pageType,
    method: '',
  },
})

export const offerShareComplete = action => ({
  hitType: 'offer-share-complete',
  shareDetails: {
    id: action.data.pageId,
    type: action.data.pageType,
    method: action.data.shareMethod,
  },
})

export const filterView = action => {
  let view
  const path = action.path.split('?')[0]
  const filters = action.path.split('?')[1]
  if (path === '/') {
    view = 'list'
  }
  if (path === '/map-view') {
    view = 'map'
  }
  return {
    hitType: 'filter-view',
    filterDetails: {
      filters,
      view,
    },
  }
}

export function filterViewDealsClick(offerIds: Array<string>) {
  return {
    hitType: 'filter-view-deals-click',
    filterResults: take(offerIds, 4),
  }
}

export function checkoutInactivityModal() {
  return {
    event: 'inactivity-modal',
  }
}

export function searchNavCTAHover() {
  return {
    event: 'search-nav-cta-hover',
    search: {
      navCTAHovered: true,
    },
  }
}

export function searchNavCTAClick() {
  return {
    event: 'search-nav-cta-click',
    search: {
      navCTAClicked: true,
    },
  }
}

export function searchOfferListFilters(listFilters: App.OfferListFilters) {
  return {
    event: 'search-offer-list-filters',
    search: {
      filters: {
        listFilters,
      },
    },
  }
}

export function plmSearchOfferListFilters(listFilters: App.OfferListFilters) {
  return {
    event: 'search-plm-offer-list-filters',
    search: {
      filters: {
        listFilters,
      },
    },
  }
}

export function searchFormSubmit() {
  return {
    event: 'search-form-submit',
    search: {
      submitted: true,
    },
  }
}

export function searchDestinationFormSubmit() {
  return {
    event: 'search-destination-form-submit',
    search: {
      submitted: true,
    },
  }
}

export function flexibleSearchFormSubmit() {
  return {
    event: 'search-form-submit-flexible',
    search: {
      submitted: true,
    },
  }
}

export function searchFormDisplay() {
  return {
    event: 'search-form-display',
    search: {
      displayed: true,
    },
  }
}

export function searchResultPolicyClick() {
  return {
    event: 'search-result-policy-click',
    search: {
      resultPage: {
        policyClicked: true,
      },
    },
  }
}

export function searchResultDealClick() {
  return {
    event: 'search-result-deal-click',
    search: {
      resultPage: {
        dealClicked: true,
      },
    },
  }
}

export function searchSwitchViewClick() {
  return {
    event: 'search-switch-view-click',
  }
}

export function searchShowOnMapClick() {
  return {
    event: 'search-show-on-map-click',
  }
}

export function trendingDestinationPurchase() {
  return {
    event: 'trending-destination-purchase',
  }
}

export function trendingDestinationClick() {
  return {
    event: 'trending-destination-click',
  }
}

export function searchResultCuratedBedbankDealClick() {
  return {
    event: 'search-result-curated-bedbank-deal-click',
  }
}

export function searchResultNonCuratedBedbankDealClick() {
  return {
    event: 'search-result-non-curated-bedbank-deal-click',
  }
}

export function searchSessionDealClick(sessionId: string, offerId: string) {
  return {
    event: 'search-session-deal-click',
    search: {
      resultPage: {
        sessionId,
        clickedDealId: offerId,
      },
    },
  }
}

export function searchResultOfferGalleryMove(offerId: string, totalCount: number) {
  return {
    event: 'search-result-offer-gallery-move',
    search: {
      resultPage: {
        gallary: {
          offerId,
          totalCount,
        },
      },
    },
  }
}

export function searchResultImpressionCount(total: number, seen: number) {
  return {
    event: 'search-result-impression-count',
    search: {
      resultPage: {
        impression: {
          total,
          seen,
        },
      },
    },
  }
}

export function callUsButtonClicked() {
  return {
    event: 'call-centre-call-flights',
  }
}

export function paymentPopupStart(paymentType: string): UAEvent {
  return {
    category: 'payment-popup',
    action: 'start',
    label: paymentType,
  }
}

export function paymentPopupCreated(paymentType: string): UAEvent {
  return {
    category: 'payment-popup',
    action: 'created',
    label: paymentType,
  }
}

export function paymentPopupFailed(paymentType: string): UAEvent {
  return {
    category: 'payment-popup',
    action: 'failed',
    label: paymentType,
  }
}

export function insurancePanelImpression() {
  return {
    event: 'insurance-panel-impression',
  }
}

export function insuranceLandingPageViewCoverOptionsClick() {
  return {
    event: 'insurance-landing-page-view-cover-options-click',
  }
}

export function insuranceLandingPageGetQuoteClick() {
  return {
    event: 'insurance-landing-page-get-quote-click',
  }
}

export function insurancePolicyWordingClick() {
  return {
    event: 'insurance-policy-wording-click',
  }
}

export function insuranceAddMoreDaysClick() {
  return {
    event: 'insurance-add-more-days-click',
  }
}

export function insuranceViewAllDetailsClick() {
  return {
    event: 'insurance-view-all-details-click',
  }
}

export function referralBoxInteraction(label: string = 'confirmation-page', actionType: string = 'interaction'): UAEvent {
  return {
    category: 'referral',
    action: `referral-box-${actionType}`,
    label,
  }
}

export function selectInsuranceSeniorCount(count: number): UAEvent {
  return {
    category: 'insurance',
    action: 'seniors-count-select',
    value: count,
  }
}

export function reserveNowButtonTestClick(): UAEvent {
  return {
    category: 'straight-to-checkout-reserve-now',
    action: 'click',
  }
}

export function viewOfferTestClick(): UAEvent {
  return {
    category: 'straight-to-checkout-view-offer',
    action: 'click',
  }
}

type EcommerceType = {
  affiliation: string;
  index: number;
  item_id: string;
  item_brand?: string;
  item_list_id: string;
  item_list_name: string;
  item_name?: string;
  item_category: string;
  item_category2: string;
  item_category3?: string;
  item_category4?: string;
  price: number;
  quantity: number;
  product_rating?: number;
}

type ViewItemListType = {
  event: 'view_item_list';
  ecommerce: {
    item_list_id: string;
    item_list_name: string;
    items: Array<EcommerceType>;
  }
}

type PurchaseType = {
  event: 'purchase';
  ecommerce: {
    transaction_id: string;
    value: number;
    currency: string;
    coupon?: string;
    payment_type: string;
    taxes_and_fees?: number,
    product_rating: number;
    items: Array<EcommerceType>;
  } & OrderEventPromoFields
}

export type BeginCheckoutType = {
  event: 'begin_checkout';
  ecommerce: {
    currency: string;
    value: number;
    items: Array<EcommerceType>;
  };
}

function createEcommerceViewItemsList(offers: Array<App.AnyOffer>): Array<EcommerceType> {
  return offers.map((offer, index) => {
    let price = 0
    let discount = 0
    let location = ''
    let item_category = ''
    let item_category2 = ''
    let item_brand = ''
    let affiliation = ''

    if (isLEHotel(offer)) {
      affiliation = 'Hotel'
      discount = price / (offer.lowestPricePackage?.value ?? 1)
      item_category = 'hotel'
      item_category2 = offer.productType
      location = offer.property.geoData.placeId ?? ''
      price = (offer.lowestPricePackage?.price ?? 0) + (offer.lowestPricePackage?.propertyFees ?? 0)
    } else if (isBedbank(offer)) {
      affiliation = 'Hotel'
      discount = price / 1
      item_category = 'hotel'
      item_category2 = offer.productType
    } if (isTourV2Offer(offer)) {
      affiliation = 'Tour'
      item_brand = offer.brand.name
      item_category = 'tour'
      item_category2 = offer.parentType
    } else if (isTourV1Offer(offer)) {
      affiliation = 'Tour'
      item_category = 'tour'
      item_category2 = offer.parentType
    }

    return {
      affiliation,
      discount: Math.round(discount * 100) / 100,
      index,
      item_brand,
      item_id: offer.id,
      item_list_id: item_category,
      item_list_name: item_category,
      item_name: offer.name,
      location_id: location,
      price,
      quantity: 1,
      item_category,
      item_category2,
    }
  })
}

function filterAndCreateEcommerceViewItemsList(offers: Record<string, App.AnyOffer>, offerIds: Array<string>): Array<EcommerceType> | null {
  const filteredOffers = offerIds.map(offerId => offers[offerId]).filter(Boolean)

  if (filteredOffers.length == 0) {
    return null
  }

  return createEcommerceViewItemsList(filteredOffers)
}

export function viewItemListOffers(offers: Record<string, App.AnyOffer>, offerIds: Array<string>): ViewItemListType | null {
  const viewItemList = filterAndCreateEcommerceViewItemsList(offers, offerIds)

  if (!viewItemList) {
    return null
  }
  pushResetEcommerce()

  return {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: viewItemList[0].item_category,
      item_list_name: viewItemList[0].item_category,
      items: viewItemList,
    },
  }
}

const createCruisesEcommerceViewItemsList = (cruises: Array<App.CruiseOffer>): Array<EcommerceType> => {
  if (cruises && cruises.length == 0) {
    return null
  }

  return cruises.map((cruise, index) => {
    const {
      id,
      name,
      cruiseLine: {
        name: cruiseBrand,
      },
      lowestDeparturePrice,
    } = cruise

    const price = lowestDeparturePrice?.total

    return {
      item_id: id,
      item_name: name,
      affiliation: 'Cruise',
      index,
      item_brand: cruiseBrand,
      item_category: 'cruise',
      item_category2: 'cruise',
      item_list_id: 'cruise',
      item_list_name: 'cruise',
      price,
      quantity: 1,
    }
  })
}

export function viewItemListCruises(cruises: Array<App.CruiseOffer>): ViewItemListType {
  const viewItemList = createCruisesEcommerceViewItemsList(cruises)

  if (!viewItemList) {
    return null
  }

  return {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: 'cruise',
      item_list_name: 'cruise',
      items: viewItemList,
    },
  }
}

const createFlightEcommerceViewItemsList = ({ flights, originCode, originName, destinationCode, destinationName }: {
  flights: Array<App.Journey>,
  originCode: string,
  originName: string,
  destinationCode: string,
  destinationName: string
}): Array<EcommerceType> => {
  if (flights && flights.length == 0) {
    return []
  }

  return flights.map((flight, index) => {
    const {
      id,
      cost,
      validatingCarrierName,
      fareClass,
      provider,
    } = flight

    return {
      item_id: id,
      item_name: `${validatingCarrierName}: ${originName}(${originCode}) - ${destinationName}(${destinationCode})`,
      affiliation: 'Flight',
      index,
      item_brand: validatingCarrierName,
      item_category: 'flight',
      item_category2: fareClass,
      item_category3: provider,
      item_category4: validatingCarrierName,
      item_list_id: 'flight',
      item_list_name: 'flight',
      price: cost,
      quantity: 1,
    }
  })
}

const createExperiencesEcommerceViewItemsList = (experiences: Array<{
  experience: App.ExperienceOffer,
  count: number,
}>): Array<EcommerceType> => {
  return experiences.map((experienceItem, index) => {
    const { experience: { name: title, id, discount, price, productType: offerType }, count } = experienceItem

    return {
      affiliation: 'Experience',
      discount,
      index,
      item_id: id,
      item_brand: experienceItem.experience?.vendor?.name,
      item_list_id: 'experience',
      item_list_name: 'experience',
      item_name: title,
      item_category: 'experience',
      item_category2: offerType,
      price: price * count,
      quantity: count,
    }
  })
}

export function viewItemListExperiences(experiences: Array<App.ExperienceOffer>): ViewItemListType {
  const filteredExperiences = experiences.filter(Boolean)

  if (filteredExperiences && filteredExperiences.length == 0) {
    return null
  }

  const experiencesWithCount = filteredExperiences.map(experienceItem => {
    return ({
      experience: experienceItem,
      count: 1,
    })
  })

  const viewItemList = createExperiencesEcommerceViewItemsList(experiencesWithCount)

  if (!viewItemList) {
    return null
  }

  return {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: 'experience',
      item_list_name: 'experience',
      items: viewItemList,
    },
  }
}

export function viewItemListFlights(
  flights: Array<{
    journey: App.Journey;
    show: boolean;
  }>,
  originAirport: App.Airport,
  destinationAirport: App.Airport,
): ViewItemListType {
  const { code: originCode, name: originName } = originAirport
  const { code: destinationCode, name: destinationName } = destinationAirport

  const mappedFlights = flights.map(flight => flight.journey)
  const viewItemList = createFlightEcommerceViewItemsList({ flights: mappedFlights, originCode, originName, destinationCode, destinationName })

  if (!viewItemList) {
    return null
  }

  return {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: 'flight',
      item_list_name: 'flight',
      items: viewItemList,
    },
  }
}

function createEcommerceViewItemsListV2(items) {
  return items?.reduce((acc, cur, index) => {
    if (cur?.itemType === OFFER_TYPE_HOTEL) {
      const analyticsObject = {
        affiliation: 'Hotel',
        item_id: cur?.itemId,
        item_category: 'hotel',
        item_category2: cur?.itemType,
        item_list_id: 'hotel',
        item_list_name: 'hotel',
        quantity: 1,
        index,
      }
      acc.push(analyticsObject)
    }
    if (cur?.itemType === OFFER_TYPE_BED_BANK_ANALYTICS) {
      const analyticsObject = {
        affiliation: 'Hotel',
        item_id: cur?.itemId,
        item_category: 'hotel',
        item_category2: cur?.itemType,
        item_list_id: 'hotel',
        item_list_name: 'hotel',
        quantity: 1,
        index,
      }
      acc.push(analyticsObject)
    }
    if ([OFFER_TYPE_TOUR_V1_ANALYTICS, OFFER_TYPE_TOUR_V2_ANALYTICS].includes(cur?.itemType)) {
      const analyticsObject = {
        affiliation: 'Tour',
        item_id: cur?.itemId,
        item_category: 'tour',
        item_category2: cur?.itemType,
        item_list_id: 'tour',
        item_list_name: 'tour',
        quantity: 1,
        index,
      }
      acc.push(analyticsObject)
    }
    if (cur?.itemType === OFFER_TYPE_EXPERIENCE) {
      const analyticsObject = {
        affiliation: 'Experience',
        item_id: cur?.itemId,
        item_category: 'experience',
        item_category2: cur?.offerType,
        quantity: sum(cur.tickets ?? [], (ticket: any) => ticket?.count ?? 0),
        index,
      }
      acc.push(analyticsObject)
    }
    if (cur?.itemType === OFFER_TYPE_FLIGHT) {
      const analyticsObject = {
        affiliation: 'Flight',
        item_id: cur?.itemId,
        item_category: 'flight',
        item_category2: cur?.journeyType,
        item_category3: cur?.fareType,
        item_list_id: 'flight',
        item_list_name: 'flight',
        price: cur?.totalFare,
        quantity: 1,
        index,
      }
      acc.push(analyticsObject)
    }
    if (cur?.itemType === OFFER_TYPE_CRUISE) {
      const analyticsObject = {
        affiliation: 'Cruise',
        item_id: cur?.itemId,
        item_category: 'cruise',
        item_category2: cur?.itemType,
        item_brand: cur?.ship?.name,
        item_list_id: 'cruise',
        item_list_name: 'cruise',
        quantity: 1,
        index,
      }
      acc.push(analyticsObject)
    }
    return acc
  }, [])
}

export function beginCheckoutEventV2(cart: App.CheckoutCartState, value: number): BeginCheckoutType {
  return {
    event: 'begin_checkout',
    ecommerce: {
      currency: cart?.currencyCode,
      value,
      items: createEcommerceViewItemsListV2(cart.items),
    },
  }
}

interface OrderEventPromoFields {
  coupon?: string
  discount?: number
}
const buildOrderPromoFields = (promoCode?: string, order?: App.Order): OrderEventPromoFields => {
  const coupon = promoCode ?? ''
  if (!order) {
    return {
      coupon,
    }
  }

  const promoPayments = order.payments.filter((p) => p.type == PROMO_PAYMENT_TYPE)
  if (promoPayments.length > 0) {
    return {
      coupon: promoPayments[0].code_name,
      discount: promoPayments[0].amount,
    }
  }

  return {
    coupon,
  }
}

const createOrderExperienceItems = (experienceItems: Array<App.OrderExperienceItem>): Array<EcommerceType> => {
  return experienceItems.map((experience, index) => {
    const { id, total, ticket: { name } } = experience

    return {
      affiliation: 'Experience',
      index,
      item_id: id,
      item_brand: name,
      item_list_id: 'experience',
      item_list_name: 'experience',
      item_name: name,
      item_category: 'experience',
      item_category2: experience.experienceId.startsWith('rez') ? 'Rezdy' : 'LED',
      price: total,
      quantity: 1,
      product_rating: experience.experienceId.startsWith('rez') ? RATINGS.experienceRezdy : RATINGS.experienceLED,
    }
  },
  )
}

const createOrderAccommodationOrTourItems = (items: Array<App.OrderItem>): Array<EcommerceType> => {
  return items.map((item, index) => {
    const { total, offerId, offer: { name, parentType, type } } = item
    let item_category = ''
    let affiliation = ''
    let product_rating = 0
    let offerType: string = type

    if (parentType === 'hotel') {
      affiliation = 'Hotel'
      item_category = 'hotel'
      product_rating = RATINGS[item.offer.type]
    }

    if (parentType === 'tour') {
      affiliation = 'Tour'
      item_category = 'tour'
      product_rating = RATINGS.cruiseFlash
      offerType = 'cruiseFlash'
    }
    return {
      item_id: offerId,
      item_name: name,
      affiliation,
      index,
      item_category: parentType,
      item_category2: offerType,
      item_list_id: item_category,
      item_list_name: offerType,
      price: total,
      quantity: 1,
      product_rating,
    }
  })
}

const createOrderCruisesItems = (cruiseItems: Array<App.CruiseOrderItem>): Array<EcommerceType> => {
  return cruiseItems.map((cruise, index) => {
    const {
      total,
      bookingId,
    } = cruise

    return {
      item_id: bookingId,
      affiliation: 'Cruise',
      index,
      item_category: 'cruise',
      item_category2: 'cruise',
      item_list_id: 'cruise',
      item_list_name: 'cruise',
      price: total,
      quantity: 1,
      product_rating: RATINGS.cruiseBank,
      item_name: cruise.offerName,
    }
  })
}

const createOrderToursItems = (tourItems: Array<Tours.TourV2OrderItem>): Array<EcommerceType> => {
  return tourItems.map((tour, index) => {
    const {
      total,
      bookingId,
    } = tour

    return {
      item_id: bookingId,
      affiliation: 'Tour',
      index,
      item_category: 'tour',
      item_category2: tour.productType,
      item_list_id: 'tour',
      item_list_name: 'tour',
      item_name: tour.tour.title,
      price: total,
      quantity: 1,
      product_rating: RATINGS[tour.offer.type] || 0,
    }
  })
}

const createServiceFeeItems = (serviceFee: App.ServiceFee): Array<EcommerceType> => {
  return [{
    item_id: 'Service Fee',
    affiliation: 'Service Fee',
    index: 0,
    item_category: 'service_fee',
    item_category2: 'service_fee', // within website we have order state, this currently does not have category/subcategory breakdown
    item_list_id: 'service_fee',
    item_list_name: 'service_fee',
    item_name: 'service_fee',
    price: serviceFee.total,
    quantity: 1,
    product_rating: 0,
  }]
}

const createOrderSubscriptionItems = (subscriptionItems: Array<App.OrderItemLuxPlusSubscription>): Array<EcommerceType> => {
  return subscriptionItems.map((item, index) => ({
    item_id: item.id,
    affiliation: item.subType,
    index,
    item_category: 'membership',
    item_category2: 'luxury_plus',
    item_list_id: 'lux_plus',
    item_list_name: 'lux_plus',
    item_name: 'LuxPlus',
    price: item.total,
    quantity: 1,
  }))
}

const createOrderFlightItems = (flight: App.Journey, itemId: string): Array<Partial<EcommerceType>> | [] => {
  const flightInfo = flight?.departing?.flights[0]

  if (!isEmpty(flightInfo)) {
    const { arrivalAirportName, arrivalAirport, departingAirportName, departingAirport, carrierName } = flightInfo

    return [{
      affiliation: 'Flight',
      index: 0,
      item_id: itemId,
      item_brand: carrierName,
      item_list_id: 'flight',
      item_list_name: 'flight',
      item_name: `${flight.validatingCarrierName}: ${departingAirportName}(${departingAirport}) - ${arrivalAirportName}(${arrivalAirport})`,
      item_category: 'flight',
      item_category2: flight.fareClass,
      item_category3: flight.provider,
      item_category4: flight.validatingCarrierName,
      price: flight.cost,
      quantity: 1,
      product_rating: RATINGS.flight,
    }]
  }

  return []
}

const createOrderBedbankItems = (bedbankItems: Array<App.OrderBedbankItem>): Array<EcommerceType> => {
  return bedbankItems.map((bedbankItem, index) => {
    const { total, offer: { name, id } } = bedbankItem

    return {
      affiliation: 'Hotel',
      index,
      item_id: id,
      item_list_id: 'hotel',
      item_list_name: 'hotel',
      item_name: name,
      item_category: 'hotel',
      item_category2: 'bedbank_hotel',
      price: total,
      quantity: 1,
      product_rating: RATINGS.bedbank,
    }
  })
}

const createOrderInsuranceItems = (insuranceItems: Array<App.OrderInsuranceItem>): Array<EcommerceType> => {
  return insuranceItems.map((insuranceItem, index) => {
    const { total, provider, id, productName } = insuranceItem

    return {
      affiliation: 'Travel Insurance',
      index,
      item_id: id,
      item_brand: provider,
      item_list_id: 'insuranceItems',
      item_list_name: 'insuranceItems',
      item_name: productName,
      item_category: 'insuranceItems',
      item_category2: 'insuranceItems',
      price: total,
      quantity: 1,
      product_rating: RATINGS.travelInsurance,
    }
  })
}

const createOrderBookingProtectionItems = (bookingProtectionItems: Array<App.OrderBookingProtectionItem>): Array<EcommerceType> => {
  return bookingProtectionItems.map((bookingProtectionItem, index) => {
    const { total, numberOfTickets, id } = bookingProtectionItem

    return {
      affiliation: 'Booking Protection',
      index,
      item_id: id,
      item_list_id: 'bookingProtection',
      item_list_name: 'bookingProtection',
      item_name: 'Booking Protection',
      item_category: 'bookingProtection',
      item_category2: 'bookingProtection',
      price: total,
      quantity: numberOfTickets,
      product_rating: RATINGS.bookingProtection,
    }
  })
}

const createOrderAddonObjects = (addonItems: Array<App.OrderAddonItem>): Array<EcommerceType> => {
  return addonItems.map((addonItem, index) => {
    const { total, id, name } = addonItem

    return {
      affiliation: 'Addon',
      index,
      item_id: id,
      item_list_id: 'addon',
      item_list_name: 'addon',
      item_name: name,
      item_category: 'addon',
      item_category2: 'addon',
      price: total,
      quantity: 1,
      product_rating: RATINGS.addon,
    }
  })
}

const createOrderCarHireItems = (carHireItems: Array<App.CarHireOrderItem>): Array<EcommerceType> => {
  return carHireItems.map((carHireItem, index) => {
    const { id, reservation, total } = carHireItem

    return {
      affiliation: 'Car Hire',
      index,
      item_id: id,
      item_list_id: id,
      item_list_name: 'car-hire',
      item_name: `${reservation?.vehicle.model}-${reservation?.pickUp.locationName}-${reservation?.dropOff.locationName}`,
      item_category: 'car-hire',
      item_category2: 'car-hire',
      price: Number.parseFloat(total),
      quantity: 1,
      product_rating: RATINGS.carHire,
    }
  })
}

export function refundEvent(order: App.Order, refundItemIds: Array<string>, method: App.RefundMethod) {
  const purchaseEventOrder = purchaseEvent(order)
  const ecommerce = {
    ...purchaseEventOrder.ecommerce,
    items: purchaseEventOrder.ecommerce.items.filter(item => refundItemIds.includes(item.item_id)).map(item => ({ ...item, price: method === 'credit' ? 0 : item.price })),
  }
  return {
    event: 'refund',
    ecommerce,
  }
}

export function getOrderItems(order: App.Order, flight?: App.Journey): Array<EcommerceType> {
  const {
    items,
    flightItems,
    cruiseItems,
    bedbankItems,
    insuranceItems,
    tourItems,
    experienceItems,
    serviceFee,
    bookingProtectionItems,
    carHireItems,
    addonItems,
    subscriptionItems,
  } = order

  let itemsObjects: Array<EcommerceType> = []
  let flightObjects = []
  let experienceObjects: Array<EcommerceType> = []
  let cruiseObjects: Array<EcommerceType> = []
  let bedbankObjects: Array<EcommerceType> = []
  let insuranceObjects: Array<EcommerceType> = []
  let tourObjects: Array<EcommerceType> = []
  let serviceFeeObjects: Array<EcommerceType> = []
  let bookingProtectionObjects: Array<EcommerceType> = []
  let carHireObjects: Array<EcommerceType> = []
  let addonObjects: Array<EcommerceType> = []
  let subscriptionObjects: Array<EcommerceType> = []

  if (!isEmpty(experienceItems)) {
    experienceObjects = createOrderExperienceItems(experienceItems)
  }

  if (!isEmpty(cruiseItems)) {
    cruiseObjects = createOrderCruisesItems(cruiseItems)
  }

  if (!isEmpty(tourItems)) {
    tourObjects = createOrderToursItems(tourItems)
  }

  if (!isEmpty(bedbankItems)) {
    bedbankObjects = createOrderBedbankItems(bedbankItems)
  }

  if (!isEmpty(items)) {
    itemsObjects = createOrderAccommodationOrTourItems(items)
  }

  if (!isEmpty(insuranceItems)) {
    insuranceObjects = createOrderInsuranceItems(insuranceItems)
  }

  if (!isEmpty(flightItems)) {
    const { itemId } = flightItems[0]
    flightObjects = createOrderFlightItems(flight, itemId)
  }

  if (!isEmpty(bookingProtectionItems)) {
    bookingProtectionObjects = createOrderBookingProtectionItems(bookingProtectionItems)
  }

  if (!isEmpty(carHireItems)) {
    carHireObjects = createOrderCarHireItems(carHireItems)
  }

  if (!isEmpty(addonItems)) {
    addonObjects = createOrderAddonObjects(addonItems)
  }

  if (serviceFee?.total) {
    serviceFeeObjects = createServiceFeeItems(serviceFee)
  }

  if (!isEmpty(subscriptionItems)) {
    subscriptionObjects = createOrderSubscriptionItems(subscriptionItems)
  }

  const orderItems = [...experienceObjects, ...itemsObjects, ...flightObjects, ...cruiseObjects, ...bedbankObjects, ...insuranceObjects, ...tourObjects, ...serviceFeeObjects, ...bookingProtectionObjects, ...carHireObjects, ...addonObjects, ...subscriptionObjects]

  return orderItems
}

/* the product_rating field is used to measure the margin that we make
   * for every product that we sell, the orderProductRating is the margin
   * that we make on the order total
   */
export function getOrderProductRating(orderItems: Array<EcommerceType>, total): number {
  const totalProductRating = orderItems.reduce(
    (rating, item) => rating + (item.price / total) * item.product_rating,
    0,
  )

  return adjustForDecimal(totalProductRating) || 0
}

export function purchaseEvent(order: App.Order, promoCode?: string, flight?: App.Journey): PurchaseType {
  pushResetEcommerce()
  const {
    id,
    total,
    currencyCode,
    payments,
  } = order

  const orderItems = getOrderItems(order, flight)
  const orderProductRating = getOrderProductRating(orderItems, total)
  const paymentType = payments.length > 0 ? max(payments, payment => payment.amount).type : 'other'

  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: id,
      value: total,
      currency: currencyCode,
      items: orderItems,
      payment_type: paymentType,
      product_rating: orderProductRating,
      ...buildOrderPromoFields(promoCode, order),
    },
  }
}

/**
 * This event use tour v2 item when it is added to cart.
 */
export function mapTourItemToProduct(
  tour: Tours.TourV2Offer,
  quantity: number,
  price: number,
): TransactionProduct {
  return {
    quantity,
    name: tour.name,
    id: tour.id,
    price: `${price}`,
    category: 'tour_v2',
    variant: 'instant_booking',
    productRating: RATINGS.tour,
  }
}
export function addTourToCart(
  tourOffer: Tours.TourV2Offer,
  currencyCode: string,
  price: number,
  quantity: number,
) {
  return {
    event: 'add-to-cart',
    hitType: 'add-to-cart',
    ecommerce: {
      currencyCode,
      add: {
        _clear: true,
        products: [mapTourItemToProduct(tourOffer, quantity, price)],
      },
    },
  }
}

export function cruiseOfferTileClick(offerId: string): UAEvent {
  return {
    category: 'cruises',
    action: 'offer-tile-click',
    label: offerId,
  }
}

export function flightsLandingPageView(): UAEvent {
  return {
    category: 'flights',
    action: 'view-landing-page',
  }
}

export function flightsDepartureLocationClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-departure-location',
  }
}

export function flightsDepartureLocationSelect(airportCode: string): UAEvent {
  return {
    category: 'flights',
    action: 'select-departure-location',
    label: airportCode,
  }
}

export function flightsArrivalLocationClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-arrival-location',
  }
}

export function flightsArrivalLocationSelect(airportCode: string): UAEvent {
  return {
    category: 'flights',
    action: 'select-arrival-location',
    label: airportCode,
  }
}

export function flightsDepartureDateClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-departure-date',
  }
}

export function flightsDepartureDateSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-departure-date',
  }
}

export function flightsArrivalDateClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-arrival-date',
  }
}

export function flightsArrivalDateSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-arrival-date',
  }
}

export function flightsNumberOfTravellersClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-number-of-travellers',
  }
}

export function flightsAddAdultTravellersClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-add-adult-traveller',
  }
}

export function flightsRemoveAdultTravellersClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-remove-adult-traveller',
  }
}

export function flightsAddChildTravellersClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-add-child-traveller',
  }
}

export function flightsRemoveChildTravellersClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-remove-child-traveller',
  }
}

export function flightsChildAgeSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-child-age-traveller',
  }
}

export function flightsApplyNumberOfTravellersClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-apply-number-of-traveller',
  }
}

export function flightsFareTypesClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-fare-types',
  }
}

export function flightsReturnFareTypeSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-return-fare-type',
  }
}

export function flightsOneWayFareTypeSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-one-way-fare-type',
  }
}

export function flightsFareClassesClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-fare-classes-click',
  }
}

export function flightsEconomyFareClassSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-economy-fare-class',
  }
}

export function flightsPremiumFareClassSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-premium-fare-class',
  }
}

export function flightsBusinessFareClassSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-business-fare-class',
  }
}

export function flightsFirstFareClassSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-first-fare-class',
  }
}

export function flightsFlightSearchClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-flight-search',
  }
}

export function flightsDepartingSearchResultsPageView(): UAEvent {
  return {
    category: 'flights',
    action: 'view-departing-search-results-page',
  }
}

export function flightsReturningSearchResultsPageView(): UAEvent {
  return {
    category: 'flights',
    action: 'view-returning-search-results-page',
  }
}

export function flightSpecificAirlinebyIconSelect(carrier: string): UAEvent {
  return {
    category: 'flights',
    action: 'select-specific-airline-by-icon',
    label: carrier,
  }
}

export function flightsSortByCheapestClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-cheapest',
  }
}

export function flightsSortByBestClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-best',
  }
}

export function flightsSortByFastestClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-fastest',
  }
}

export function flightsSortByEarliestTakeOffClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-earliest-take-off',
  }
}

export function flightsSortByLatestTakeOffClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-latest-take-off',
  }
}

export function flightsSortByEarliestLandingClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-earliest-landing',
  }
}

export function flightsSortByOtherOptionsClick(): UAEvent {
  return {
    category: 'flights',
    action: 'click-sort-by-other-options',
  }
}

export function flightsSortByAllStopsSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-all-stops',
  }
}

export function flightsSortByOneStopSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-one-stop',
  }
}

export function flightsSortByTwoStopsSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-two-stops',
  }
}

export function flightsSortbyAllStopoverSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-all-stopover',
  }
}

export function flightsSortByLessThanFourHoursStopoverSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-less-than-four-hours-stopover',
  }
}

export function flightsSortByFourToEightHoursStopoverSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-less-than-four-hours-stopover',
  }
}

export function flightsSortByTwelvePlusHoursStopoverSelect(): UAEvent {
  return {
    category: 'flights',
    action: 'select-sort-by-twelve-plus-hours-stopover',
  }
}

export function flightSpecificAirlineSelect(carrier: string): UAEvent {
  return {
    category: 'flights',
    action: 'select-specific-airline-select',
    label: carrier,
  }
}
