/** This is a generic search tile which are used on condensed state of
 *  Flash, Last Minute and Tactical Always On as the designs are same */

import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

import { mediaQueryUp } from 'components/utils/breakpoint'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import shouldHideAoLabels from 'lib/offer/hideAoLabel'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import OfferBadge from 'components/Common/OfferBadge'
import SoldOutMessage from '../Support/SoldOutMessage'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { encodeOfferIds } from 'lib/search/searchUtils'
import { queryKeySelectedOfferIds } from 'constants/url'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { connect } from 'react-redux'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import { ImageParams } from 'components/Common/ResponsiveImage/ResponsiveImage'
import Breakpoint from 'components/Common/Breakpoint'
import { Main } from './SearchTileStyles'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import AspectRatio from 'components/utils/AspectRatio'
import CapacityExceededMessage from 'components/OfferList/OfferListTiles/Support/CapacityExceededMessage'
import { OfferUnavailableReason } from 'lib/search/constants'

const Content = styled.div`
  padding: ${rem(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueryUp.desktop} {
    flex-direction: row;
    max-height: ${rem(262)};
    padding: ${rem(24)} ${rem(24)} ${rem(16)}
  }
`

const LeftContent = styled.div`
  align-self: flex-start;
  flex: 1;
`

const RightContent = styled.div`
  align-self: flex-end;
  margin-top: ${rem(8)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaQueryUp.tablet} {
    margin-left: ${rem(8)};
  }
`

const StyledBodyTextBlock = styled(BodyTextBlock)`
  margin: ${rem(16)} 0;

  ${mediaQueryUp.tablet} {
    margin: ${rem(4)} 0;
  }
`

const OfferOverlayContainer = styled.div`
  position: absolute;
  top: ${rem(54)};
  left: ${rem(20)};
  display: flex;
  align-items: flex-start;

  &.hideTypeLabel {
    top: ${rem(20)};
  }

  & > * + * {
    margin-top: ${rem(12)};
  }
`

const StyledOfferBadge = styled(OfferBadge)`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
`

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

interface Props {
  offer: App.Offer | App.OfferSummary;
  unavailableReason?: string;
  productClick?: (offer: App.Offer | App.OfferSummary) => void;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  windowSearch: string;
  onImageChange?: (idx: number, image?: App.Image) => void
  onImageLoad?: () => void;
}

function SearchOfferTileCondensed(props: Props) {
  const {
    offer,
    filters,
    unavailableReason = OfferUnavailableReason.DATE_UNAVAILABLE,
    windowSearch,
    eagerLoadFirstImage,
    onImageChange,
    onImageLoad,
  } = props

  const {
    property,
    name,
    type,
    images,
  } = offer

  const location = [offer.locationHeading, offer.locationSubheading].filter(t => t).join(', ')
  const hideAoLabel = shouldHideAoLabels(offer)

  const mapSearchUrl = `/search/map?${setSearchParamValue(windowSearch, queryKeySelectedOfferIds, encodeOfferIds([offer]))}`

  return (
    <Main>
      <AspectRatio ratio="16:9" desktopRatio="auto">
        <ImageCarousel
          images={images}
          imageParams={imageParams}
          eagerLoadFirstImage={eagerLoadFirstImage}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
      </AspectRatio>
      {type === OFFER_TYPE_ALWAYS_ON && (
        <OfferOverlayContainer
          className={cn({ 'hide-type-label': hideAoLabel })}
        >
          <OfferPartnershipLabel offer={offer} />
        </OfferOverlayContainer>
      )}
      {offer.badge && (
        <StyledOfferBadge
          badge={offer.badge}
          dimensions={{ width: 86, height: 86 }}
        />
      )}
      <Content>
        <LeftContent>
          <VerticalSpacer gap={4}>
            {property && showUserReviewsRating(property.rating, property.reviewsTotal) && (
              <NumberRating
                variant="medium"
                rating={property.rating}
                total={property.reviewsTotal}
                ratingSubtitle={property.reviewsSource === 'google' ? 'From online reviews' : ''}
              />
            )}
            <StyledBodyTextBlock variant="medium">
              <OfferTileLocationSection location={location} mapSearchUrl={mapSearchUrl} />
            </StyledBodyTextBlock>
            <OfferLabels offer={offer} />
            {property?.name && <>
              <Heading variant="heading5" as="h4">{property.name}</Heading>
              <Breakpoint not="tablet">
                <BodyText variant="large" as="h4">{name}</BodyText>
              </Breakpoint>
            </>}
            {!property?.name && <BodyText variant="large" as="h5">{name}</BodyText>}
          </VerticalSpacer>
        </LeftContent>
        <RightContent>
          { unavailableReason === OfferUnavailableReason.CAPACITY_EXCEEDED && <CapacityExceededMessage />}
          { unavailableReason === OfferUnavailableReason.DATE_UNAVAILABLE && <SoldOutMessage offer={offer} filters={filters} />}
        </RightContent>
      </Content>
    </Main>
  )
}

SearchOfferTileCondensed.defaultProps = {
  filters: {},
}

const mapStateToProps = (state: App.State) => ({
  windowSearch: state.router.location.search,
})

export default connect(mapStateToProps)(SearchOfferTileCondensed)
