import qs from 'qs'
import request from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubCheckEmailStatusMapper } from './mappers/agentHubMap'

type GetAgentStatusResponse = definitions['userStatusResponse']

async function requestGetAgentStatusByEmail(agentEmail: string): Promise<App.AgentHubEmailCheckStatus> {
  const query = qs.stringify({
    email: agentEmail,
  })
  const response = await request.get<App.ApiResponse<GetAgentStatusResponse>>(`/api/agent/user/status?${query}`)
  return agentHubCheckEmailStatusMapper(response.result)
}

export default requestGetAgentStatusByEmail
