import createSelector from 'lib/web/createSelector'
import { isCruiseItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import { isSpoofed } from 'selectors/featuresSelectors'

const DISABLE_KEY = 'disable_service_fee'

export const getDefaultDepositAmountPercentage = createSelector(
  isSpoofed,
  (state: App.State) => state.checkout.csDepositPercentageOverride,
  (state: App.State) => state.config.depositConfigs,
  (isSpoofed, csDepositPercentageOverride, depositConfigs): number => {
    if (!depositConfigs) return 0
    return csDepositPercentageOverride && isSpoofed ? depositConfigs.CS_DEPOSIT_PERCENTAGE_OVERRIDE : depositConfigs.DEPOSIT_AMOUNT_PERCENTAGE
  })

export const getDepositServiceFee = createSelector(
  isSpoofed,
  (state: App.State) => state.config.depositConfigs,
  (isSpoofed, depositConfigs): number => {
    if (!depositConfigs) return 0
    return isSpoofed ? depositConfigs.CS_SERVICE_FEE_PERCENTAGE_OVERRIDE : depositConfigs.SERVICE_FEE_PERCENTAGE
  })

export const getDepositServiceFeeConfig = createSelector(
  getDepositServiceFee,
  (state: App.State) => state.config.originalCanonicalURL,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.offer.tourV2Offers,
  (state: App.State) => state.config.depositConfigs,
  (depositServiceFee, url = '', items, tourV2Offers, depositConfigs): App.Checkout.ServiceFeeSettings => {
    const queryString = url.slice(url.indexOf('?') + 1)
    const params = new URLSearchParams(queryString)
    const isEnabled = params.get(DISABLE_KEY) !== 'true'

    const tourItem = items.find(isTourV2Item)
    if (tourItem !== undefined && tourV2Offers?.[tourItem.offerId]?.productType === 'connection_tour') {
      return {
        isEnabled: false,
        percentage: 0,
      }
    }

    if (items.find(isCruiseItem)) {
      const cruiseServiceFeePercentage = depositConfigs?.CRUISE_SERVICE_FEE_PERCENTAGE ?? 0

      return {
        isEnabled: isEnabled && (cruiseServiceFeePercentage !== 0),
        percentage: cruiseServiceFeePercentage,
      }
    }

    return {
      isEnabled: isEnabled && (Number(depositServiceFee) !== 0),
      percentage: depositServiceFee,
    }
  },
)
