import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getCheckoutFlightItemFinalFare } from 'actions/CheckoutFlightActions'
import { hasFlightSessionExpired } from 'components/Flights/FlightsSessionTimer/FlightsSessionTimer'
import { getFlightItems } from 'checkout/selectors/view/flights'
import { fetchFlightJourneyV2 } from 'actions/FlightActions'
import { setOptimizelyExperiment } from 'actions/OptimizelyActions'
import { OptimizelyExperiments } from 'constants/optimizely'

export function useCheckoutFlightJourney() {
  const dispatch = useAppDispatch()
  const flightItems = useAppSelector(getFlightItems)

  useEffect(() => {
    if (!hasFlightSessionExpired()) {
      flightItems
        .forEach(item => {
          if (item.searchId) {
            if (item.departing?.journeyId) {
              dispatch(fetchFlightJourneyV2(item.searchId, item.departing.journeyId, item.departing.fareFamily?.id, item.returning?.journeyId, 0))
            }

            if (item.returning?.journeyId) {
              dispatch(fetchFlightJourneyV2(item.searchId, item.returning.journeyId, item.returning.fareFamily?.id, item.departing?.journeyId, 1))
            }
          }
        })
    }
  }, [flightItems, dispatch])
}

function useCheckoutFlightFinalFares() {
  const dispatch = useAppDispatch()

  const flightItems = useAppSelector(getFlightItems)
  const journeys = useAppSelector(state => state.flights.journeysById)

  useEffect(() => {
    dispatch(setOptimizelyExperiment(OptimizelyExperiments.paymentsFlightsMerchantFees))
    const items = flightItems
      .filter(item => {
        // first we make sure the item has all the data we need to fetch the final fares
        const hasItemData = !item.hasFinalFare &&
          !!item.departing?.journeyId &&
          (item.fareType === 'oneWay' || !!item.returning?.journeyId) &&
          !item.fetchingFare &&
          !item.fareFetchError

        return hasItemData
      })

    items.forEach(item => {
      dispatch(getCheckoutFlightItemFinalFare(item))
    })
  }, [dispatch, flightItems, journeys])
}

export default function useCheckoutFlightData() {
  useCheckoutFlightJourney()
  useCheckoutFlightFinalFares()
}
