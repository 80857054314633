import { useAppSelector } from 'hooks/reduxHooks'
import { isActiveSubscribedLuxPlusMember, isEligibleLuxPlusFreePreview, isFreePreviewLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import useQueryParams from 'hooks/useQueryParams'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

/**
 * Determines whether to show the UI to sign up for LuxPlus+ free preview
 */
function useLuxPlusFreePreviewUtils(): {
  showFreePreviewSignup: boolean;
  showFreePreviewCarouselSortOrder: boolean;
  shouldAutoEnrolFreePreview: boolean;
  } {
  const freePreviewMember = useAppSelector(isFreePreviewLuxPlusMember)
  const freePreviewEligible = useAppSelector(isEligibleLuxPlusFreePreview)
  const activeSubscribedMember = useAppSelector(isActiveSubscribedLuxPlusMember)
  const queryParams = useQueryParams()
  const isFreePreviewParams = queryParams.get(LUXURY_PLUS.FREE_PREVIEW_QUERY_PARAM) === 'true'
  const notFreePreviewMemberNotActiveSubscribedMember = !freePreviewMember && !activeSubscribedMember
  const showFreePreviewCarouselSortOrder = (isFreePreviewParams || freePreviewEligible || freePreviewMember) && !activeSubscribedMember
  const showFreePreviewSignup = (isFreePreviewParams || freePreviewEligible) && notFreePreviewMemberNotActiveSubscribedMember
  const shouldAutoEnrolFreePreview = queryParams.get(LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM) === 'true' && notFreePreviewMemberNotActiveSubscribedMember

  return {
    showFreePreviewSignup,
    showFreePreviewCarouselSortOrder,
    shouldAutoEnrolFreePreview,
  }
}

export default useLuxPlusFreePreviewUtils
