export const HOTEL_DESCRIBE_OPTIONS = [
  {
    label: 'Relaxing',
    value: 'relaxing',
  },
  {
    label: 'Comfortable',
    value: 'comfortable',
  },
  {
    label: 'Clean',
    value: 'clean',
  },
  {
    label: 'Value for money',
    value: 'value_for_money',
  },
  {
    label: 'Good facilities',
    value: 'good_facilities',
  },
  {
    label: 'Kid friendly',
    value: 'kid_friendly',
  },
]

export const EXPERIENCE_DESCRIBE_OPTIONS = [
  {
    label: 'Relaxing',
    value: 'relaxing',
  },
  {
    label: 'Comfortable',
    value: 'comfortable',
  },
  {
    label: 'Clean',
    value: 'clean',
  },
  {
    label: 'Variety of meals',
    value: 'variety_of_meals',
  },
  {
    label: 'Good value',
    value: 'good_value',
  },
  {
    label: 'On-time',
    value: 'on_time',
  },
]

export const TOUR_DESCRIBE_OPTIONS = [
  {
    label: 'Culturally immersive',
    value: 'culturally_immersive',
  },
  {
    label: 'Comfortable & convenient',
    value: 'comfortable_convenient',
  },
  {
    label: 'Engaging itinerary',
    value: 'engaging_itinerary',
  },
  {
    label: 'Expert guides',
    value: 'expert_guides',
  },
  {
    label: 'Flexible options',
    value: 'flexible_options',
  },
  {
    label: 'Hidden gems',
    value: 'hidden_gems',
  },
  {
    label: 'Iconic landmarks',
    value: 'iconic_landmarks',
  },
  {
    label: 'Good value',
    value: 'good_value',
  },
  {
    label: 'Luxurious',
    value: 'luxurious',
  },
  {
    label: 'Memorable',
    value: 'memorable',
  },
  {
    label: 'Scenic beauty',
    value: 'scenic',
  },
  {
    label: 'Safe & secure',
    value: 'safe_secure',
  },
]

export const COMPANIONSHIP_OPTIONS = [
  {
    label: 'Just myself',
    value: 'alone',
  },
  {
    label: 'Partner',
    value: 'partner',
  },
  {
    label: 'Family',
    value: 'family',
  },
  {
    label: 'Friends',
    value: 'friends',
  },
]

export const COMPANIONSHIP_ENUM = {
  alone: 'Just myself',
  partner: 'Partner',
  family: 'Family',
  friends: 'Friends',
}

export const DEFINITION_ENUM = {
  relaxing: 'Relaxing',
  comfortable: 'Comfortable',
  clean: 'Clean',
  value_for_money: 'Value for money',
  good_facilities: 'Good facilities',
  kid_friendly: 'Kid friendly',
  variety_of_meals: 'Variety of meals',
  good_value: 'Good value',
  on_time: 'On-time',
  expert_guides: 'Expert guides',
  scenic: 'Scenic beauty',
  immersive: 'Immersive',
  luxurious: 'Luxurious',
  variety_of_activities: 'Variety of activities',
  culturally_immersive: 'Culturally immersive',
  comfortable_convenient: 'Comfortable & convenient',
  engaging_itinerary: 'Engaging itinerary',
  flexible_options: 'Flexible options',
  hidden_gems: 'Hidden gems',
  iconic_landmarks: 'Iconic landmarks',
  memorable: 'Memorable',
  safe_secure: 'Safe & secure',
}

const SORT_OPTION_NEWEST = {
  label: 'Most recent',
  value: 'newest',
}

const SORT_OPTION_OLDEST = {
  label: 'Oldest',
  value: 'oldest',
}

const SORT_OPTION_BEST_RATING = {
  label: 'Highest rating',
  value: 'best_rating',
}

const SORT_OPTION_WORST_RATING = {
  label: 'Lowest rating',
  value: 'worst_rating',
}

export const SORT_OPTION_MOST_RELEVANT = {
  label: 'Most relevant',
  value: 'most_relevant',
}

export type ReviewListSortOption = typeof SORT_OPTION_OLDEST
  | typeof SORT_OPTION_NEWEST
  | typeof SORT_OPTION_BEST_RATING
  | typeof SORT_OPTION_WORST_RATING
  | typeof SORT_OPTION_MOST_RELEVANT

export const userReviewsSortOptions: Array<ReviewListSortOption> = [
  SORT_OPTION_MOST_RELEVANT,
  SORT_OPTION_NEWEST,
  SORT_OPTION_OLDEST,
  SORT_OPTION_BEST_RATING,
  SORT_OPTION_WORST_RATING,
]

export const MIN_RATING_DISPLAY = 4 // (base 5) To be a 8.0 on CP display

export const REVIEWS_FORM_TERMS_AND_CONDITIONS_LABEL = 'By submitting this review I agree that Luxury Escapes may publish details of my review including without limitation my first name, my country of location, my review, along with any other reviews, and details of my stay, including room type and date on its website and other media channels.'
