import React, { useMemo } from 'react'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'

function isInclusionV2(inclusion: App.InclusionV2 | App.Inclusion): inclusion is App.InclusionV2 {
  return typeof inclusion.category === 'object'
}

interface Props {
  inclusion: App.InclusionV2 | App.Inclusion;
  isHighlighted?: boolean;
  size?: 'small' | 'medium' | 'large';
}

function InclusionItem(props: Props) {
  const {
    inclusion,
    isHighlighted,
    size = 'medium',
  } = props

  const { description, Icon } = useMemo(() => {
    if (isInclusionV2(inclusion)) {
      return {
        description: inclusion.name,
        Icon: getInclusionIcon(inclusion.category?.icon ?? ''),
      }
    } else { // is App.Inclusion
      return {
        description: inclusion.text,
        Icon: getInclusionIcon(inclusion.icon),
      }
    }
  }, [inclusion])

  return <BodyTextBlock
    variant={size}
    weight={isHighlighted ? 'bold' : 'normal'}
    startIcon={<Icon colour={isHighlighted ? 'secondary-normal' : undefined} />}
  >
    {description}
  </BodyTextBlock>
}

export default InclusionItem
