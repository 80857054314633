import { pluralizeToString } from 'lib/string/pluralize'
import offerTypeConfig from '../config/offer'
import { components } from '@luxuryescapes/contract-public-offer'
import { isOfferDomestic } from 'lib/offer/isDomestic'

import imageMap from './imageMap'

import { checkGatedOffer, getPackageUniqueKey } from 'lib/offer/offerUtils'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON, MAX_STAY } from 'constants/offer'

import { getLowestPricePackage } from 'lib/offer/getLowestPricePackage'
import { paths } from '@luxuryescapes/contract-search/server'
import { isDateBetweenOrEqual } from 'lib/datetime/dateUtils'
import config from 'constants/config'

import { groupBy } from 'lib/array/arrayUtils'

type ContractSchema = components['schemas']
type RoomRate = ContractSchema['leHotelRoomRate']
type RatePlan = ContractSchema['ratePlan']
type LeHotelPackage = ContractSchema['leHotelPackage']
type LeHotelOption = ContractSchema['leHotelOption']
type LeHotelOffer = ContractSchema['leHotelOffer']

const trimToActiveSchedules = (schedules: Array<App.ReservationSchedule>): Array<App.ReservationSchedule> => {
  return (schedules || []).filter((schedule) => isDateBetweenOrEqual(schedule.activePeriod.from, schedule.activePeriod.to))
}

interface RoomInfo {
  partnership: {
    [key: string]: App.PackagePartnership;
  }
  roomType: {
    [key: string]: App.PackageRoomType;
  }
  roomRate: {
    [key: string]: App.PackageRoomRate;
  }
}

type GeoData = ContractSchema['geoData']
export function geoDataMap(geoData: GeoData): App.OfferPropertyGeoData {
  return {
    continentCode: geoData.continentCode,
    country: geoData.country,
    countryCode: geoData.countryCode,
    locality: geoData.locality,
    route: geoData.route,
    streetNumber: geoData.streetNumber,
    administrativeAreaLevel1: geoData.administrativeAreaLevel1,
    administrativeAreaLevel2: geoData.administrativeAreaLevel2,
    administrativeAreaLevel3: geoData.administrativeAreaLevel3,
    administrativeAreaLevel4: geoData.administrativeAreaLevel4,
    administrativeAreaLevel5: geoData.administrativeAreaLevel5,
    placeId: geoData.placeId,
  }
}

type Property = ContractSchema['property']
export function propertyMap(property: Property): App.OfferProperty {
  return {
    id: property.id,
    name: property.name,
    address: property.address,
    latitude: property.location.latitude,
    longitude: property.location.longitude,
    timezone: property.timezone,
    timezoneOffset: property.timezoneOffset,
    logoImageId: property.logo.id,
    reviews: property.reviews ?? [],
    childrenPolicy: property.childrenPolicy,
    infantPolicy: 'Under 2 years old',
    geoData: geoDataMap(property.geoData),
    maxChildAge: (property.ageCategories.find(cat => cat.name === 'Adult')?.minimumAge ?? 18) - 1,
    maxInfantAge: (property.ageCategories.find(cat => cat.name === 'Child')?.minimumAge ?? 3) - 1,
    taxesAndFeesContent: property.taxAndFeesContent,
    taxesPayableAtProperty: property.taxesPayableAtProperty,
    useDynamicTaxesFees: property.useDynamicTaxesFees,
    useDynamicCancellationPolicies: property.useDynamicCancellationPolicies,
    useDynamicOccupancy: property.useDynamicOccupancy,
    currentRegionMarketedLos: property.currentRegionMarketedLos,
    isUltraLux: property.subCategory === 'Ultra Lux',
    reviewsTotal: property.reviewsTotal ?? 0,
    rating: property.rating ?? 0,
    reviewsSource: property.reviewsSource ?? '',
    category: property.category ?? '',
    hideChildPrices: property.hideChildPrices,
  }
}

type UrgencyTag = ContractSchema['urgencyTag']
export function mapOfferUrgencyTags(urgencyTags: Array<UrgencyTag>): Array<App.UrgencyTag> {
  return urgencyTags.map(urgencyTag => ({ type: urgencyTag.type, message: urgencyTag.message ?? undefined }))
}

type Schedule = ContractSchema['availabilitySchedule']
export function mapOfferSchedule(schedule: Schedule): App.OfferSchedule {
  return {
    start: schedule.start,
    end: schedule.end,
  }
}

type ReserveForZero = LeHotelOffer['reserveForZero']
export function mapReserveForZero(reserveForZero: ReserveForZero): { minDays: number } {
  return {
    minDays: reserveForZero!.min_days,
  }
}

type Amenity = ContractSchema['amenity']
type AmenityGroup = ContractSchema['amenityGroup']
function roomAmenityMap(
  amenity: Amenity,
  group: AmenityGroup,
  index: number,
): App.PackageRoomAmenity {
  return {
    // not getting ID from server anymore, index is unique enough
    id: index,
    name: amenity.name,
    group: group.name,
  }
}

type RoomType = ContractSchema['roomType']
export function mapRoomType(
  serverRoom: RoomType,
): App.PackageRoomType {
  return {
    id: serverRoom.id,
    name: serverRoom.name,
    images: serverRoom.images.map(imageMap),
    description: serverRoom.description,
    amenities: serverRoom.amenityGroups.map(group => group.values.map((amenity, index) => roomAmenityMap(amenity, group, index))).flat(),
    additionalGuestAmountDescription: serverRoom.additionalGuestAmountDescription,
    sizeSqm: serverRoom.sizeSqm ?? undefined,
    tags: (serverRoom.tags || []).map((tag) => ({ ...tag, message: tag.message ?? undefined })),
    numberOfBathrooms: serverRoom.numberOfBathrooms ?? undefined,
    numberOfBedrooms: serverRoom.numberOfBedrooms ?? undefined,
    group: serverRoom.group ?? undefined,
  }
}

export function mapRoomRate(
  serverRoomRate: RoomRate,
  serverRatePlan: RatePlan,
): App.PackageRoomRate {
  return {
    id: serverRoomRate.id,
    ratePlanId: serverRatePlan.id,
    cancellationPolicy: {
      type: serverRatePlan.cancellationPolicy.type,
      policyDetail: serverRatePlan.cancellationPolicy.description,
    },
    extraGuestSurcharges: serverRoomRate.extraGuestSurcharges,
    group: serverRatePlan.group ?? undefined,
    isPackaged: serverRatePlan.isPackaged,
    packagedRatePlanId: serverRatePlan.packagedRateId,
    capacities: serverRoomRate.capacities,
    includedGuests: serverRoomRate.includedGuests,
    inclusionsHideValue: serverRoomRate.inclusionsHideValue,
    isBaseRate: !!serverRatePlan.isBaseRate,
    isReservableForZero: serverRoomRate?.isReservableForZero ?? false,
    schedules: serverRatePlan.schedules,
    hasTactical: serverRoomRate.hasTactical,
    cancellationPolicies: serverRoomRate.cancellationPolicies,
  }
}

export function getRoomRateInclusions(
  serverRoomRate: RoomRate,
  duration: number,
  inclusionType: 'common' | 'bonus' | 'luxPlus',
): Array<App.PackageInclusion> {
  if (!serverRoomRate.inclusions.length) return []

  return serverRoomRate.inclusions.filter((inclusion) => {
    const hasValidDuration = (inclusion.minDuration > 0 && duration >= inclusion.minDuration) && (inclusion.maxDuration > 0 && duration <= inclusion.maxDuration)
    const isCommon = inclusionType === 'common' && !inclusion.isBonus && !inclusion.luxPlusTier
    const isBonus = inclusionType === 'bonus' && inclusion.isBonus && !inclusion.luxPlusTier
    const isLuxPlus = inclusionType === 'luxPlus' && !!inclusion.luxPlusTier

    return hasValidDuration && (isCommon || isBonus || isLuxPlus)
  }).map((incl) => {
    const trimmedInclusion = incl.description?.replace(/^([ -]+)/, '') ?? ''

    return {
      isBonus: incl.isBonus,
      maxDuration: incl.maxDuration,
      minDuration: incl.minDuration,
      schedules: incl.schedules,
      // the server types lies, sometimes it's null - we don't want null
      categoryIcon: incl.categoryIcon ?? undefined,
      description: '- ' + trimmedInclusion,
      displayContext: 'Rate',
      hasTactical: trimToActiveSchedules(incl.schedules).some(schedule => schedule.type === 'tactical'),
      luxPlusTier: incl.luxPlusTier as App.MembershipSubscriptionTier,
      type: incl.type ?? undefined,
    }
  })
}

// We don't actually get a 'long' version of the sale unit
// from the server, so add to this list as we find other
// types that that need extending
export const saleUnitLongReplacements = {
  'apt.': 'apartment',
  apt: 'apartment',
}

export function getHotelBonusInclusions(
  serverPkg: LeHotelPackage,
  serverRoomRate: RoomRate,
  ratePlan: RatePlan,
  duration: number,
): Array<App.PackageInclusion> {
  const roomBonusInclusions = getRoomRateInclusions(serverRoomRate, duration, 'bonus')

  const packageBonusInclusionsForDuration = serverPkg.inclusions.bonus.find(inclusion => inclusion.fromNights <= duration && inclusion.toNights >= duration)
  if (packageBonusInclusionsForDuration?.content) {
    return [...roomBonusInclusions, {
      description: packageBonusInclusionsForDuration.content,
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: true,
      displayContext: 'Package',
      schedules: [],
      hasTactical: false,
    }] }

  const ratePlanBonusInclusionsForDuration = ratePlan.inclusions.bonus.find(inclusion => inclusion.fromNights <= duration && inclusion.toNights >= duration)
  if (ratePlanBonusInclusionsForDuration?.content) {
    return [...roomBonusInclusions, {
      description: ratePlanBonusInclusionsForDuration.content,
      descriptionV2List: ratePlanBonusInclusionsForDuration.contentV2,
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: true,
      displayContext: 'RatePlan',
      schedules: [],
      hasTactical: false,
    }] }

  return roomBonusInclusions
}

const getAlwaysOnLoyaltyInclusions = (
  serverRoomRate: RoomRate,
  duration: number,
): App.LoyaltyMemberInclusionTypes | undefined => {
  const allLoyaltyInclusions = getRoomRateInclusions(serverRoomRate, duration, 'luxPlus')

  if (!allLoyaltyInclusions.length) return undefined

  const groupedInclusions = groupBy(allLoyaltyInclusions, inclusion => inclusion.luxPlusTier)
  return Object.fromEntries(groupedInclusions) as App.LoyaltyMemberInclusionTypes
}

type PackagePartnership = ContractSchema['pkgPartnership']
export function packagePartnershipMap(serverPartnerships?: Array<PackagePartnership>): App.PackagePartnership | undefined {
  const firstPartnership = serverPartnerships?.[0]

  if (firstPartnership) {
    return {
      bonusPoints: firstPartnership.bonusPoints ?? 0,
      bonusDescription: firstPartnership.bonusDescription || '',
      localRewardConversionRate: firstPartnership.localRewardConversionRate ?? 1,
    }
  }
}

export function getHotelInclusions(
  serverPkg: LeHotelPackage,
  serverRoomRate: RoomRate,
  serverRatePlan: RatePlan,
  duration: number,
): Array<App.PackageInclusion> {
  const roomInclusions = getRoomRateInclusions(serverRoomRate, duration, 'common')

  if (serverPkg.inclusions.highlights) {
    return [...roomInclusions, {
      description: serverPkg.inclusions.highlights,
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: false,
      displayContext: 'Package',
      schedules: [],
      hasTactical: false,
    }]
  }

  if (serverPkg.inclusions.description) {
    return [...roomInclusions, {
      description: serverPkg.inclusions.description,
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: false,
      displayContext: 'Package',
      schedules: [],
      hasTactical: false,
    }]
  }

  if (serverRatePlan.inclusions.description) {
    return [...roomInclusions, {
      description: serverRatePlan.inclusions.description,
      descriptionV2List: serverRatePlan.inclusions.descriptionV2,
      minDuration: 1,
      maxDuration: MAX_STAY,
      isBonus: false,
      displayContext: 'RatePlan',
      schedules: [],
      hasTactical: false,
    }]
  }

  return roomInclusions
}

export function mapHotelPackageOption({
  serverOption,
  serverOffer,
  roomInfo,
}: {
  serverOption: LeHotelOption,
  serverOffer: LeHotelOffer,
  roomInfo: RoomInfo,
}): App.HotelPackage {
  const serverPkg = serverOffer.packages[serverOption.fkPackageId]
  const ratePlan = serverOffer.ratePlans[serverOption.fkRatePlanId]
  const roomRate = serverOffer.roomRates[serverOption.fkRoomRateId]

  const inclusions = getHotelInclusions(serverPkg, roomRate, ratePlan, serverOption.duration)
  const bonusInclusions = getHotelBonusInclusions(serverPkg, roomRate, ratePlan, serverOption.duration)
  const loyaltyInclusionTypes = getAlwaysOnLoyaltyInclusions(roomRate, serverOption.duration)
  const hasTactical = serverOption.hasTactical ?? roomInfo.roomRate[serverOption.fkRoomRateId].hasTactical

  const inclusionsAmount = serverOption?.inclusionsAmount // amount without LuxPlus+ inclusions
  const memberInclusionsAmount = serverOption.luxPlusBaseInclusionsAmount // amount of only LuxPlus+ inclusions
  const totalInclusionsAmount = (inclusionsAmount ?? 0) + (memberInclusionsAmount ?? 0)

  return {
    id: serverPkg.id ?? serverPkg.lePackageId,
    lePackageId: serverPkg.lePackageId,
    offerId: serverPkg.fkOfferId,
    description: serverPkg.inclusions.description || ratePlan.inclusions.description,
    sortOrder: serverPkg.sortOrder,
    roomOccupancy: serverPkg.includedGuestsLabel,
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    duration: serverOption.duration,
    price: serverOption.price ?? 0,
    memberPrice: serverOption.luxPlusPrice ?? 0,
    value: serverOption.value ?? 0,
    memberValue: serverOption.luxPlusBaseValue ?? 0,
    taxesAndFees: serverOption.totals?.taxesAndFees ?? 0,
    propertyFees: serverOption.totals?.propertyFees ?? 0,
    surcharge: serverOption?.surcharge ?? 0,
    trackingPrice: serverOption.trackingPrice,
    name: serverOption.name ?? serverPkg.name,
    partnership: roomInfo.partnership[serverOption.fkPackageId],
    roomPolicyDescription: serverPkg.copy.roomPolicyDescription,
    bundleDiscountPercent: ratePlan.bundleDiscount,
    discountPercent: ratePlan.discount,
    shouldInstantPurchase: ratePlan.shouldInstantPurchase,
    inclusions,
    inclusionsV2: serverPkg?.inclusionsV2 as Array<App.PackageInclusionV2>,
    inclusionsAmount,
    memberInclusionsAmount,
    totalInclusionsAmount,
    isBaseRate: serverOption.isBaseRate,
    bonusInclusions,
    loyaltyInclusionTypes,
    durationLabel: pluralizeToString('Night', serverOption.duration),
    uniqueKey: getPackageUniqueKey(serverPkg.id ?? serverPkg.lePackageId, serverOption.duration, serverOption.fkRoomRateId),
    roomType: roomInfo.roomType[serverOption.fkRoomTypeId],
    roomRate: roomInfo.roomRate[serverOption.fkRoomRateId],
    allowBuyNowBookLater: serverPkg.allowBuyNowBookLater,
    allowDatesRequest: serverPkg.allowDatesRequest,
    hasTactical,
    availableRooms: serverOption.availableRooms,
    ignoreDynamicCancellationPolicy: ratePlan.ignoreDynamicCancellationPolicy,
  }
}

export function hotelOfferMap(serverOffer: LeHotelOffer, regionCode: string): App.HotelOffer {
  const offerType = serverOffer.type
  const typeConfig = offerTypeConfig[offerType as any]!
  const offerTypeHotel = offerType === OFFER_TYPE_HOTEL
  const hasBNBLPackages = offerTypeHotel && Object.values(serverOffer.packages).some((p) => (p.allowBuyNowBookLater))

  const offer: App.HotelOffer = {
    type: typeConfig.type,
    productType: typeConfig.productType,
    walledGarden: typeConfig.walledGarden,
    hasBuyNowBookLater: typeConfig.hasBuyNowBookLater && !serverOffer.bundledWithFlightsOnly,
    hasBuyNowBookLaterPackages: hasBNBLPackages,
    parentType: typeConfig.parentType,
    typeLabel: typeConfig.typeLabel,
    overlayImageLabel: typeConfig.overlayImageLabel,
    analyticsType: typeConfig.analyticsType,
    id: serverOffer.id,
    name: serverOffer.name,
    experiencesInFlow: serverOffer.experiencesInFlow,
    experiencesCurated: serverOffer.experiencesCurated,
    showOnlyExperiencesCurated: serverOffer.showOnlyExperiencesCurated,
    location: serverOffer.location.description,
    locations: serverOffer.tags.location ?? [],
    // @ts-ignore: field might be undefined
    description: serverOffer.copy.description,
    highlights: serverOffer.copy.highlights ?? '',
    holidayTypes: serverOffer.tags.holidayTypes ?? [],
    whatWeLike: serverOffer.copy.whatWeLike,
    facilities: serverOffer.copy.facilities,
    finePrint: serverOffer.copy.finePrint,
    gettingThere: serverOffer.copy.gettingThere,
    insuranceCountries: serverOffer.insurance.countries,
    isSoldOut: serverOffer.isSoldOut ?? false,
    // @ts-ignore: field might be undefined
    bookByDate: serverOffer.schedules?.bookBy?.end,
    // @ts-ignore: field might be undefined
    travelToDate: serverOffer.schedules?.travelBy?.end,
    slug: serverOffer.slug,
    canEarnPartnershipPoints: serverOffer.partnerships.length > 0,
    durationLabel: serverOffer.durationLabel ?? '',
    tileDurationLabel: serverOffer.durationLabel ?? '',
    images: serverOffer.images.map(imageMap),
    image: imageMap(serverOffer.images[0]),
    visibilitySchedules: {
      // @ts-ignore: schedules field might be undefined
      world: serverOffer.schedules.listVisibility ? mapOfferSchedule(serverOffer.schedules.listVisibility) : undefined,
      // @ts-ignore: schedules field might be undefined
      [regionCode]: serverOffer.schedules.listVisibility ? mapOfferSchedule(serverOffer.schedules.listVisibility) : undefined,
    },
    // @ts-ignore: schedules field might be undefined
    onlinePurchaseSchedule: serverOffer.schedules.onlinePurchase ? mapOfferSchedule(serverOffer.schedules.onlinePurchase) : undefined,
    // @ts-ignore: schedules field might be undefined
    availabilitySchedule: serverOffer.schedules.availability ? mapOfferSchedule(serverOffer.schedules.availability) : undefined,
    ...(serverOffer.schedules?.luxPlus ? {
      luxPlusSchedules: {
        world: mapOfferSchedule(serverOffer.schedules.luxPlus),
        [regionCode]: mapOfferSchedule(serverOffer.schedules.luxPlus),
      },
    } : {}),
    // @ts-ignore: field might be undefined
    panelImageId: serverOffer.panelImage?.id,
    vimeoVideoId: serverOffer.video?.id,
    locationHeading: serverOffer.location.heading,
    locationSubheading: serverOffer.location.subheading,
    noIndex: serverOffer.noIndex,
    saleUnit: serverOffer.saleUnit,
    saleUnitLong: saleUnitLongReplacements[serverOffer.saleUnit] ?? serverOffer.saleUnit,
    flightPrices: serverOffer.flights?.[0]?.prices ?? {},
    flightDestinationPort: serverOffer.flights?.[0]?.destinationCode,
    flightsMaxArrivalTime: serverOffer.flights?.[0]?.latestDestinationArrivalTime,
    flightsMinReturningDepartureTime: serverOffer.flights?.[0]?.earliestDestinationDepartureTime,
    flightCacheDisabled: serverOffer.flights?.[0]?.cacheDisabled,
    flightsWarningHeadline: serverOffer.flights?.[0]?.warning?.heading,
    flightsWarningPopupBody: serverOffer.flights?.[0]?.warning?.description,
    urgencyTags: mapOfferUrgencyTags(serverOffer.tags.urgency),
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    // @ts-ignore: maybe field should be optional?
    exclusiveExtras: undefined,
    exclusiveExtrasLarge: serverOffer.inclusions.description,
    inclusions: serverOffer.inclusions.description,
    inclusionsHeading: serverOffer.inclusions.heading,
    // @ts-ignore: field might be undefined
    additionalDescription: serverOffer.copy.additionalDescription,
    tileInclusions: serverOffer.inclusions.tileInclusions,
    tileInclusionsHeading: serverOffer.inclusions.tileHeading,
    // @ts-ignore: field might be undefined
    daysBeforeCheckInChangesDisallowed: serverOffer.daysBeforeCheckInChangesDisallowed,
    flightsWarningEnabled: !!serverOffer.flights?.[0]?.warning,
    offerFlightsEnabled: config.FLIGHT_ENABLED && (serverOffer.flights?.length ?? 0) > 0,
    // @ts-ignore: maybe field should be optional?
    pageViews: undefined,
    // @ts-ignore: maybe field should be optional?
    totalPurchases: undefined,
    badge: config.BRAND === 'luxuryescapes' ? serverOffer.badge : undefined,
    bundledWithFlightsOnly: serverOffer.bundledWithFlightsOnly,
    whitelistedCarrierCodes: serverOffer.whitelistedCarrierCodes ?? [],
    disableDeposit: !config.DEPOSITS_ENABLED || serverOffer.disableDeposit,
    depositThresholds: serverOffer.depositThresholds,
    packages: [],
    defaultOptions: [],
    isPartnerProperty: serverOffer.isPartnerProperty,
    numberOfDateChangesAllowed: serverOffer.numberOfDateChanges ?? 'Unlimited',
    packageUpgradesAllowed: offerTypeHotel && serverOffer.packageUpgradesAllowed,
    disableBestPriceGuarantee: serverOffer.disableBestPriceGuarantee,
    minDuration: Math.min(...serverOffer.options.map(o => o.duration)),
    ...(serverOffer.reserveForZero && { reserveForZero: mapReserveForZero(serverOffer.reserveForZero) }),
    ...((offerTypeHotel || serverOffer.type === OFFER_TYPE_ALWAYS_ON) && serverOffer.forceBundleId && { forceBundleId: serverOffer.forceBundleId }),
    vendorName: serverOffer.vendorName,
    property: propertyMap(serverOffer.property),
    hasHiddenCancellationPolicy: Object.values(serverOffer.ratePlans).some(r => r.cancellationPolicy?.type === 'hidden-cancellation-policy'),
    isDiscountPillHidden: serverOffer.isDiscountPillHidden ?? false,
    luxPlus: {
      hasMemberInclusions: serverOffer.luxPlus.hasMemberInclusions,
      access: serverOffer.luxPlus.access,
    },
    isAgentHubExclusive: Boolean(serverOffer.isAgentHubExclusive),
  }

  const roomInfo = serverOffer.options.reduce<RoomInfo>((acc, serverOption) => {
    if (!acc.partnership[serverOption.fkPackageId]) {
      // @ts-ignore: function return value might be undefined
      acc.partnership[serverOption.fkPackageId] = packagePartnershipMap(serverOffer.packages[serverOption.fkPackageId].partnerships)
    }
    if (!acc.roomType[serverOption.fkRoomTypeId]) {
      acc.roomType[serverOption.fkRoomTypeId] = mapRoomType(serverOffer.roomTypes[serverOption.fkRoomTypeId])
    }
    if (!acc.roomRate[serverOption.fkRoomRateId]) {
      acc.roomRate[serverOption.fkRoomRateId] = mapRoomRate(serverOffer.roomRates[serverOption.fkRoomRateId], serverOffer.ratePlans[serverOption.fkRatePlanId])
    }
    return acc
  }, { roomType: {}, roomRate: {}, partnership: {} })

  offer.packages = serverOffer.options.map(option => mapHotelPackageOption({
    serverOption: option,
    serverOffer,
    roomInfo,
  }))
  offer.hasTactical = offer.packages.some(p => p.hasTactical && p.price)
  // Generating all unique options keys
  offer.defaultOptions = serverOffer.lowestOptions.map((o) => getPackageUniqueKey(o.fkPackageId, o.duration, o.fkRoomRateId))
  if (offer.hasTactical && !offer.packages.some((o) => o.hasTactical && offer.defaultOptions.includes(o.uniqueKey))) {
    const defaultTacticalOptions = offer.packages.filter((o) => o.hasTactical && o.price).map(o => o.uniqueKey)
    offer.defaultOptions = defaultTacticalOptions.length > 0 ? defaultTacticalOptions : offer.defaultOptions
  }

  offer.isDomestic = isOfferDomestic(offer, regionCode)
  offer.lowestPricePackage = getLowestPricePackage(offer, offer.packages) as App.HotelPackage

  if (!offer.lowestPricePackage) {
    throw new Error('Invalid offer error, missing lowest price package that should always exist.')
  }

  // duration label of 1 to x nights is not very useful for users, just show a from price
  offer.tileDurationLabel = offer.packages.some(p => p.duration === 1) ? '' : offer.durationLabel

  if (offer.property?.isUltraLux) {
    offer.productType = 'ultralux_hotel'
    offer.walledGarden = false
  }
  offer.walledGarden = checkGatedOffer(offer) || offer.walledGarden

  return offer
}

type GetHotelListResultItem = Exclude<paths['/api/search/hotel/v1/list']['get']['responses']['200']['content']['application/json']['result'][0], string>
function mapSearchResultSuggestedTravelDateToOfferListMetaDataTravelDates(
  suggestedTravelDate: GetHotelListResultItem['suggestedTravelDates'],
  type: GetHotelListResultItem['type'],
): App.OfferListMetaData['suggestedTravelDates'] {
  if (suggestedTravelDate) {
    return {
      checkIn: suggestedTravelDate.checkIn,
      checkOut: suggestedTravelDate.checkOut,
      price: suggestedTravelDate.price,
      memberPrice: type === OFFER_TYPE_HOTEL ? suggestedTravelDate.luxPlusPrice : 0,
      currency: suggestedTravelDate.currency,
      packageId: suggestedTravelDate.packageId,
      value: suggestedTravelDate.value,
      roomRateId: suggestedTravelDate.roomRateId,
    }
  }
}

export function mapSearchResultToOfferListMetaData(serverResult: GetHotelListResultItem): App.OfferListMetaData {
  return {
    kind: serverResult.kind,
    offerId: serverResult.id,
    propertyId: serverResult.propertyId,
    bundledOfferId: serverResult.bundledOfferId,
    type: serverResult.type,
    distance: serverResult.distance,
    available: serverResult.available,
    unavailableReason: serverResult.unavailableReason,
    packages: serverResult.packages,
    suggestedTravelDates: serverResult.suggestedTravelDates ? mapSearchResultSuggestedTravelDateToOfferListMetaDataTravelDates(serverResult.suggestedTravelDates, serverResult.type) : undefined,
    hasPromotions: serverResult.hasPromotions,
    location: serverResult.location,
    hiddenByDefault: serverResult.hiddenByDefault,
  }
}

export const transformFilterObject = (input: Record<string, { count: number }>): Record<string, number> => {
  return Object.keys(input).reduce((acc, key) => {
    acc[key] = input[key].count
    return acc
  }, {})
}
