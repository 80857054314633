import React, { useCallback, useContext, useMemo } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'
import Pane from 'components/Common/Pane'
import { take } from 'lib/array/arrayUtils'
import Image from 'components/Common/Image'

import TextButton from 'components/Luxkit/Button/TextButton'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import AspectRatio from 'components/utils/AspectRatio'
import { connect } from 'react-redux'
import { ImageParams } from 'components/Common/ResponsiveImage'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import moment from 'moment'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import Tooltip from 'components/Luxkit/Tooltip'
import Pill from 'components/Luxkit/Pill'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import FormatCurrency from 'components/Common/FormatCurrency'
import { getCruiseOfferPriceVariation } from 'lib/cruises/cruiseUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import config from 'constants/config'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Main = styled(Pane)`
  display: grid;
  position: relative;
  z-index: 0;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
  }
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)};
  }
`

const DetailSegment = styled.div`
  position: relative;
  padding: ${rem(20)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  ${mediaQueryUp.desktop} {
    flex: 1;
    text-align: center;
    padding: ${rem(16)};
  }
`

const TopDetailSegment = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${rem(12)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(8)};
    }
  }
`

const OfferTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-row-gap: ${rem(4)};
  grid-column-gap: ${rem(16)};
  grid-auto-flow: column;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: ${rem(8)};
    justify-items: center;
  }
`

const OfferTitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LogoWrapper = styled.div`
  grid-column-start: 2;
  grid-row: span 2;
  align-self: center;
  height: ${rem(60)};
  display: flex;
  align-items: center;

  ${mediaQueryUp.desktop} {
    grid-row: auto;
    grid-column-start: auto;
    height: ${rem(100)};
  }
`

const Logo = styled(Image)`
  width: ${rem(90)};

  ${mediaQueryUp.desktop} {
    width: ${rem(150)};
  }
`

const OfferContent = styled.div`
  > * + * {
    margin-top: ${rem(8)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(12)};
    }
  }
`

const BottomDetailSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr ${rem(120)};
  margin-top: ${rem(12)};

  > * + * {
    margin-top: ${rem(16)};
  }

  ${mediaQueryUp.desktop} {
    grid-template-columns: 1fr;
  }
`

const MainImageSegment = styled.div`
  display: grid;
  gap: ${rem(4)};
  position: relative;

  ${mediaQueryUp.desktop} {
    &.additional {
      grid-template-rows: 1fr ${rem(120)};
    }
  }
`

const AdditionalImages = styled.div`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
    gap: ${rem(4)};
  }
`

const OfferOverlayContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(12)};
  left: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const DetailsCaption = styled(BodyText)`
  display: flex;

  ${mediaQueryUp.desktop} {
    justify-content: center;
  }

  > * + *::before {
    color: ${props => props.theme.palette.neutral.default.one};
    content: "·";
    margin: 0 ${rem(4)};
    speak-as: bullets;
    speak: none;
  }
`

const ViewOfferButton = styled(TextButton)`
  align-self: flex-end;
`

const StyledPricingGroup = styled(Group)`
  ${mediaQueryUp.desktop} {
    align-items: center;
  }
`

interface MappedProps {
  isVelocityEnabled: boolean;
}

interface Props {
  cruise: App.CruiseOffer;
  eagerLoadFirstImage?: boolean;
}

const imageParams: ImageParams = {
  aspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
}

function CruiseFrontPageTile(props: Props & MappedProps) {
  const { cruise, isVelocityEnabled, eagerLoadFirstImage } = props
  const { name, departurePort, returnPort, itinerary, mainDepartureDetails } = cruise
  const hasTheSameStartAndEndPort = departurePort === returnPort
  const returnPortCity = useMemo(() => returnPort?.split(',')?.[0], [returnPort])
  const departurePortCity = useMemo(() => departurePort?.split(',')?.[0], [departurePort])
  const startAndEndLocation = hasTheSameStartAndEndPort ?
    `Starts and ends in ${returnPortCity}` :
    `Starts in ${departurePortCity}, Ends in ${returnPortCity}`

  const cities = useMemo(() => itinerary?.map((event) => {
    const { port } = event
    if (!port) return
    return port.city || port.name.split(',')[0]
  }).filter((city) => city && city.length > 1), [itinerary])

  const countries = useMemo(() => itinerary?.map((itineraryItem) => {
    const { port } = itineraryItem
    if (!port) return
    return port.countryCode
  }).filter(country => country?.length), [itinerary])

  const placesCount = [...new Set(cities)].length
  const countriesCount = [...new Set(countries)].length

  const [earliestDepartureDate, ...laterDates] = useMemo(() => {
    const departureDates = (cruise.departureDates || [])
      .map((date) => moment(date))
      .sort((a, b) => a.unix() - b.unix())
      .map((date) => date.format('DD MMM YYYY'))

    if (mainDepartureDetails.departureDate) {
      const mainDepartureDate = moment(mainDepartureDetails.departureDate).format('DD MMM YYYY')
      return [
        ...new Set([mainDepartureDate, ...departureDates]),
      ]
    }

    return departureDates
  }, [cruise.departureDates, mainDepartureDetails.departureDate])

  const { lowestOverallPriceDetails } = mainDepartureDetails
  const { promotion, discountPills } = lowestOverallPriceDetails
  const promotionDetails = promotion ?? cruise.fallbackPromotion

  const {
    primaryPrice,
    secondaryPrice,
  } = getCruiseOfferPriceVariation(cruise, lowestOverallPriceDetails)

  const showAdditionalImages = cruise.images.length > 3

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    onEvent(OfferListEvents.offerReady)
  }, [onEvent])

  return (
    <ProductPaletteProvider palette="default">
      <Main type="clean">
        <MainImageSegment className={cn({ additional: showAdditionalImages })}>
          <AspectRatio ratio="16:9" fillHeight>
            <ImageCarousel
              images={cruise.images}
              imageParams={imageParams}
              eagerLoadFirstImage={eagerLoadFirstImage}
              onImageLoad={handleImageLoaded}
            />
          </AspectRatio>
          {showAdditionalImages && <AdditionalImages>
            {take(cruise.images, 3, 1).map((image) => (
              <Image
                key={image.id}
                id={image.id}
                alt={name}
                height={120}
                width={245}
                loading="lazy"
                fit="center"
              />
            ))}
          </AdditionalImages>}
          <OfferOverlayContainer>
            {isVelocityEnabled && <PartnershipLabel canEarn={false} canUse />}
          </OfferOverlayContainer>
        </MainImageSegment>
        <ProductPaletteProvider palette="default">
          <DetailContainer>
            <TripGuard>
              <CSSBreakpoint min="desktop">
                <Group direction="horizontal-reverse" horizontalAlign="space-between">
                  <BookmarkButton offer={cruise} />
                </Group>
              </CSSBreakpoint>
            </TripGuard>
            <DetailSegment>
              <TripGuard>
                <CSSBreakpoint max="tablet">
                  <OffsetBookmarkContainer>
                    <BookmarkButton offer={cruise} />
                  </OffsetBookmarkContainer>
                </CSSBreakpoint>
              </TripGuard>
              <TopDetailSegment>
                <OfferTitle>
                  <LogoWrapper>
                    {cruise.cruiseLine.imageId && <Logo
                      id={cruise.cruiseLine.imageId}
                      width="300"
                      alt={cruise.cruiseLine.name}
                    />}
                  </LogoWrapper>
                  <OfferTitleText>
                    <Subtitle variant="subtitle3">{startAndEndLocation}</Subtitle>
                    <Heading variant="heading5">{cruise.name}</Heading>
                  </OfferTitleText>
                </OfferTitle>
                <OfferContent>
                  <VerticalSpacer gap={0}>
                    { cruise.cruiseLine.name &&
                      <BodyText variant="medium">
                        Cruise line: <b>{cruise.cruiseLine.name}</b>
                      </BodyText>
                    }
                    { cruise.ship.name &&
                      <BodyText variant="medium">
                        Cruise ship: <b>{cruise.ship.name}</b>
                      </BodyText>
                    }
                    <DetailsCaption variant="medium" weight="bold">
                      <div>{pluralizeToString('night', cruise.duration)}</div>
                      {!!placesCount && <div>{pluralizeToString('port', placesCount)}</div>}
                      {!!countriesCount && <div>{pluralizeToString('country', countriesCount)}</div>}
                    </DetailsCaption>
                  </VerticalSpacer>
                  <BodyText variant="medium" weight="bold">
                    Departing on {earliestDepartureDate}
                    {laterDates.length > 0 && <CSSBreakpoint min="tablet">
                      <Tooltip placement="top" description={laterDates.join(', ')}>
                        or later
                      </Tooltip>
                    </CSSBreakpoint>}
                  </BodyText>
                </OfferContent>
              </TopDetailSegment>
              <BottomDetailSegment>
                <StyledPricingGroup direction="vertical" gap={8}>
                  {config.DEPOSITS_TAGS_ENABLED && !cruise.disableDeposits && (
                    <div>
                      <BodyText variant="small">
                        {promotionDetails?.deposit && <Pill kind="tertiary" variant="primary" startIcon={<LineClockDollarIcon />}>
                          Secure with {promotionDetails.deposit.formattedValue} deposit
                        </Pill>}
                        {!promotionDetails?.deposit && <Pill kind="tertiary" variant="primary" startIcon={<LineClockDollarIcon />}>
                          Deposits available
                        </Pill>}
                      </BodyText>
                    </div>
                  )}
                  <Group direction="vertical">
                    {lowestOverallPriceDetails.cabinCategory && <PriceRowPriceCaption>
                      {lowestOverallPriceDetails.cabinCategory} from
                    </PriceRowPriceCaption>}
                    <PriceRowPrice
                      data-testid="search-price"
                      size="L"
                      price={primaryPrice.total}
                      saleUnit={primaryPrice.type}
                    />
                    {!secondaryPrice && <Caption variant="medium" colour="neutral-three">Twin Share</Caption>}
                    {secondaryPrice && <PriceRowCaption>
                      <FormatCurrency value={secondaryPrice.total} />/{secondaryPrice.type} · Twin Share
                    </PriceRowCaption>}
                    {discountPills?.price && discountPills?.discountPercentage && <PriceRowValueDiscountWithCaption
                      size="M"
                      data-testid="value-box"
                      originalValue={discountPills.price}
                      discountPercentage={discountPills.discountPercentage}
                    />}
                  </Group>
                </StyledPricingGroup>
                <ViewOfferButton kind="primary" fit="flex">View offer</ViewOfferButton>
              </BottomDetailSegment>
            </DetailSegment>
          </DetailContainer>
        </ProductPaletteProvider>
      </Main>
    </ProductPaletteProvider>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  isVelocityEnabled: state.geo.partnership?.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE,
})

export default connect(mapStateToProps)(CruiseFrontPageTile)
