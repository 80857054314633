export function capitalise(str = '') {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}

export function capitaliseAll(str = ''): string {
  const wordList = str.split(' ')
  if (wordList.length > 1) {
    const capitalisedWordList = wordList.map((word, index) => {
      if (index === 0 || (word.length > 2 && !capitaliseIgnoreSet.has(word))) {
        return capitalise(word)
      }
      return word
    })

    return capitalisedWordList.join(' ')
  }

  return str
}

export function contains(str: string, search: string) {
  return str.toLowerCase().includes(search.toLowerCase())
}

function isUpperCase(str: string) {
  const upperStr = str.toUpperCase()
  return str === upperStr
}

/**
 * Convert list of tokens to AP Title Case
 */
const capitaliseIgnoreSet = new Set(['a', 'an', 'and', 'at', 'but', 'by', 'for', 'in', 'nor', 'of', 'on', 'or', 'so', 'the', 'to', 'up', 'yet'])
function toTitle(tokens: Array<string>) {
  return tokens.map((token, idx) => {
    return (idx === 0 || !capitaliseIgnoreSet.has(token)) ? capitalise(token) : token
  }).join(' ')
}

/**
 * Convert snake case string to AP Title Case
 *
 * @remarks
 * Does not support non-alpha chars
 */
export function snakeToTitle(str: string) {
  const tokens = str.split('_')
  return toTitle(tokens)
}

/**
  * Convert camel case string to AP Title Case
  *
  * @remarks
  * Does not support non-alpha chars
  */
export function camelToTitle(str: string) {
  const tokens: Array<string> = []
  let anchor = 0

  for (let i = 0; i < str.length; i++) {
    if (!isUpperCase(str.charAt(i))) { continue }
    let token = str.slice(anchor, i).toLowerCase()
    if (i === 0 || capitaliseIgnoreSet.has(token)) { token = capitalise(token) }
    tokens.push(token)
    anchor = i
  }

  // loop doesn't handle adding the last token - add it explicitly
  tokens.push(str.slice(anchor, str.length))
  return toTitle(tokens)
}

const KILO = 1024

const PREFIXED_BYTES = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
] as const

export function formatBytes(
  bytes: number,
  decimalPoints = 2,
): `${number} ${typeof PREFIXED_BYTES[number]}` {
  if (bytes == 0) return '0 Bytes'

  const i = Math.floor(Math.log(bytes) / Math.log(KILO))

  return `${parseFloat((bytes / Math.pow(KILO, i)).toFixed(decimalPoints))} ${
    PREFIXED_BYTES[i]
  }`
}

/**
 * Formats a list of strings into a comma separated list with a different
 * separator at the end of the list for human readability
 * @param array An array of strings to join
 * @param finalComma The string to use in place of a comma for the final join
 * @returns A string
 */
export function arrayToStringListFormat(array: Array<string>, finalComma = 'or') {
  if (array.length === 1) {
    return array[0]
  }

  return array.reduce((previous, current, index) => {
    if (index === 0) {
      return current
    }
    if (index === (array.length - 1)) {
      return previous + ` ${finalComma} ${current}`
    }
    return previous + `, ${current}`
  }, '')
}

export function areStringsEqual(str1: string, str2: string): boolean {
  if (!str1 || !str2) return false
  return str1.toLowerCase().trim() === str2.toLowerCase().trim()
}

export function isString(val: unknown): val is string {
  return typeof val === 'string'
}
