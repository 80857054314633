import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineHeartPlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.8001 5h.1c1.7.1 2.8 1.2 3.1 2.3.7 2.6-.5 3.8-1.2 4.5l-.3.3c-1.4 1.5-4.9 5.2-6.5 6.9-1.6-1.7-5.1-5.4-6.5-6.9l-.3-.3c-.7-.7-2-1.8-1.2-4.5.3-1.1 1.4-2.2 3.1-2.3h.1c1.4 0 2.1.6 3.2 1.9l.8.9c.4.5 1.1.5 1.5 0l.8-.9c1.1-1.3 1.9-1.9 3.3-1.9Zm0-2c-2.4 0-3.7 1.4-4.8 2.6-1.1-1.3-2.4-2.6-4.8-2.6H7c-2.6.1-4.4 1.8-5 3.8-1.1 4.2 1.5 6.1 2 6.7 1.8 1.9 7.2 7.7 7.2 7.7.1.1.2.2.3.2.1.1.3.1.4.1.1 0 .3 0 .4-.1.1-.1.2-.1.3-.2 0 0 5.4-5.8 7.2-7.7.6-.6 3.2-2.4 2-6.7-.5-1.9-2.4-3.7-5-3.8Z"/>
    <path fill="currentColor" d="M13 11h1a1.0001 1.0001 0 0 1 0 2h-1v1a1.0001 1.0001 0 0 1-2 0v-1h-1a1.0002 1.0002 0 0 1-.7071-1.7071A1.0002 1.0002 0 0 1 10 11h1v-1a1.0002 1.0002 0 0 1 1.7071-.7071c.1875.1875.2929.4419.2929.7071v1Z"/>
  </SvgIcon>
}

export default LineHeartPlusIcon
