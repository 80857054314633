// This receives the state and uses a selector to determine tourV2 vertical naming
import config from 'constants/config'

const tourV2VerticalNameSelector = (state: App.State) => {
  const { geo } = state

  if (config.CRUISES_REGIONS?.split(',').includes(geo.currentRegionCode)) {
    return 'Tours'
  }

  return 'Tours & Cruises'
}

export default tourV2VerticalNameSelector
