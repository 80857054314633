import requestPostResendActivationEmail from 'agentHub/api/requestPostResendActivationEmail'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { useAppSelector } from 'hooks/reduxHooks'
import noop from 'lib/function/noop'
import React, { useCallback } from 'react'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

interface Props {
  requestedEmail: string
  onModeChange?: (mode: App.UiAccountModalMode) => void;
}

function AgentHubResendActivationEmail(props: Props) {
  const {
    requestedEmail,
    onModeChange = noop,
  } = props
  const modalContext = useModalElementContext()
  const agentStatus = useAppSelector(state => state.agentHub.agentStatusByEmail[requestedEmail])

  const onBack = useCallback(() => {
    onModeChange('login')
  }, [onModeChange])

  const handleResendEmail = useCallback(async() => {
    if (agentStatus) {
      await requestPostResendActivationEmail(agentStatus.agentId)
      modalContext.resolve()
      showSnackbar('Activation email sent.', 'success')
    }
  }, [agentStatus, modalContext])

  return <>
    <ModalHeader title="Finish setting up your account" onBackButtonClick={onBack}/>
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={20}>
          <BodyText variant="large">
            Your account setup is not yet complete. To continue with your account set-up, we have sent an activation
            email to <b>{requestedEmail}</b>.
            <br /><br />
            Please check your inbox and follow the instructions provided in the email to continue.
          </BodyText>
          <BodyText variant="large">
            Don't see the email?{' '}
            <TextLink size="large" weight="regular" onClick={handleResendEmail}>Resend activation email</TextLink>
          </BodyText>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </>
}
export default AgentHubResendActivationEmail
