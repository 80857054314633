import React, { useCallback, useContext, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { createFreePreviewMember } from 'actions/LoyaltyActions'
import AccountAccessModal, { AccountAccessModalResult } from 'components/Account/AccountAccess/AccountAccessModal'
import { isLoggedIn as isLoggedInSelector } from 'selectors/accountSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import ModalContext from 'contexts/ModalContext'
import { INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import { isSameDay, dateDifference, useIntlDateFormatter } from 'lib/datetime/dateUtils'

function useJoinLuxPlusFreePreview(): {
  onPendingJoinFreePreview: () => void;
  hasInviteExpiryDate: boolean;
  formattedExpiryDate: string;
  invitePillExpiryCopy: string;
  } {
  const dispatch = useAppDispatch()
  const { resolve } = useModalElementContext()
  const showModal = useContext(ModalContext)
  const isLoggedIn = useAppSelector(isLoggedInSelector)

  // Expiry DATE UI
  const freePreview = useAppSelector(state => state.loyalty.products.freePreview)
  const inviteExpiryDate = freePreview?.offer?.expiryDate
  const hasInviteExpiryDate = !!inviteExpiryDate
  const intlDateFormatter = useIntlDateFormatter()
  const formattedExpiryDate = inviteExpiryDate ? intlDateFormatter(inviteExpiryDate, INTL_DMY_CASUAL_SHORT_FORMAT) : ''

  const onJoinFreePreview = useCallback(async() => {
    dispatch(createFreePreviewMember())
  }, [dispatch])

  // TODO engx task - refactor usePendingLoginHandler to use ModalContext
  const onPendingJoinFreePreview = useCallback(async() => {
    let loginResult: AccountAccessModalResult | undefined
    if (!isLoggedIn) {
      loginResult = await showModal<AccountAccessModalResult>(<AccountAccessModal />)
    }
    if (loginResult?.loggedIn || isLoggedIn) {
      resolve()
      return onJoinFreePreview()
    }
  }, [isLoggedIn, onJoinFreePreview, resolve, showModal])

  const invitePillExpiryCopy = useMemo(() => {
    const inviteExpiresToday = isSameDay(new Date(), new Date(hasInviteExpiryDate ? inviteExpiryDate : ''))
    const inviteExpiryDayCount = dateDifference(new Date(hasInviteExpiryDate ? inviteExpiryDate : '')).days

    if (!hasInviteExpiryDate) {
      return 'Limited-time offer'
    }

    if (inviteExpiresToday) {
      return 'Invite expires today'
    }

    if (inviteExpiryDayCount <= 1) {
      return 'Invite expires in 1 day'
    }

    return `Invite expires in ${inviteExpiryDayCount} days`
  }, [hasInviteExpiryDate, inviteExpiryDate])

  return {
    onPendingJoinFreePreview,
    hasInviteExpiryDate,
    formattedExpiryDate,
    invitePillExpiryCopy,
  }
}

export default useJoinLuxPlusFreePreview
