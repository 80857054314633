import { baseThemeShadows } from './shadows'
import luxuryEscapesTheme, { luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'

export const leAgenthubDefaultPalette: App.Palette = {
  ...luxuryEscapesDefaultPalette,
}

export const leAgenthubInversePalette: App.Palette = {
  ...luxuryEscapesInversePalette,
}

export const leAgentHubShadows: App.ThemeShadows = { ...baseThemeShadows }

const leAgenthubTheme: App.Theme = {
  ...luxuryEscapesTheme,
}

export default leAgenthubTheme
