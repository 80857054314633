import React, { useCallback, useState } from 'react'
import AccountAccessForgetPassword from './AccountAccessForgetPassword'
import AccountAccessJoin from './AccountAccessJoin'
import AccountAccessLoginStart from './AccountAccessLoginStart'
import AccountAccessLoginPassword from './AccountAccessLoginPassword'
import AccountAccessPartnershipLinkAccount from './AccountAccessPartnershipLinkAccount'
import AccountAccessResetPassword from './AccountAccessResetPassword'
import config from 'constants/config'
import BusinessTravellerLoginForm from 'businessTraveller/components/business-account/BusinessTravellerLoginForm'
import BusinessTravellerPasswordLoginForm from 'businessTraveller/components/business-account/BusinessTravellerPasswordLoginForm'
import AgentHubLoginForm from 'agentHub/components/AgentHubAccountAccess/AgentHubLoginForm'
import AgentHubPasswordLoginForm from 'agentHub/components/AgentHubAccountAccess/AgentHubPasswordLoginForm'
import AgentHubResendActivationEmail from 'agentHub/components/AgentHubAccountAccess/AgentHubResendActivationEmail'
import AccountAccessLoginOTP from './AccountAccessLoginOTP'
import AccountAccessAddPhone from './AccountAccessAddPhone'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import AccountAccessJoinExistingAccount from './AccountAccessJoinExistingAccount'
import { clearAuthError } from 'actions/AuthActions'

interface Props {
  mode?: App.UiAccountModalMode;
  dismissable?: boolean;
  defaultEmail?: string;
  onModeChange?: (nextMode: App.UiAccountModalMode) => void;
  resetPasswordToken?: string;
  hotelOfferDetails?: App.AccountModalOfferDetails;
  callbackPath?: string;
}

function AccountAccessModalContent(props: Props) {
  const {
    mode,
    defaultEmail,
    onModeChange,
    resetPasswordToken,
    hotelOfferDetails,
    callbackPath,
  } = props

  const [userId, setUserId] = useState<string>(defaultEmail ?? '')
  const isBusinessMode = config.businessTraveller.currentAccountMode === 'business'
  const isAgentHubMode = config.agentHub.isEnabled
  const user = useAppSelector(state => state.auth.users[userId])
  const dispatch = useAppDispatch()

  const onChangeMode = useCallback((nextMode: App.UiAccountModalMode, nextUserId?: string) => {
    // errors are relevant only for the life of that 'mode', so reset them as we change
    dispatch(clearAuthError())
    onModeChange?.(nextMode)

    if (nextUserId !== undefined) {
      setUserId(nextUserId)
    }
  }, [dispatch, onModeChange])

  switch (mode) {
    case 'login':
    case 'tripPlannerLogin':
      if (isAgentHubMode) {
        return <AgentHubLoginForm defaultUser={user} onSubmit={setUserId} onModeChange={onChangeMode} />
      } else if (isBusinessMode) {
        return <BusinessTravellerLoginForm onSubmit={setUserId} onModeChange={onChangeMode} />
      } else {
        return <AccountAccessLoginStart
          mode={mode}
          onModeChange={onChangeMode}
          hotelOfferDetails={hotelOfferDetails}
        />
      }
    case 'loginPassword':
      if (user) {
        if (isAgentHubMode) {
          return <AgentHubPasswordLoginForm user={user} onModeChange={onChangeMode} />
        } else if (isBusinessMode) {
          return <BusinessTravellerPasswordLoginForm user={user} onModeChange={onChangeMode} />
        } else {
          return <AccountAccessLoginPassword
            user={user}
            onModeChange={onChangeMode}
          />
        }
      }
      return null
    case 'loginPasswordOTP':
      if (user) {
        return <AccountAccessLoginPassword
          otpEnabled
          user={user}
          onModeChange={onChangeMode}
        />
      }
      return null
    case 'loginOTP':
      if (user) {
        return <AccountAccessLoginOTP user={user} onModeChange={onChangeMode} />
      }
      return null
    case 'promptAddPhone':
      return <AccountAccessAddPhone />
    case 'join':
      if (user) {
        return <AccountAccessJoin user={user} onModeChange={onChangeMode} />
      }
      return null
    case 'joinExistingAccount':
      if (user) {
        return <AccountAccessJoinExistingAccount user={user} onModeChange={onChangeMode} />
      }
      return null
    case 'forgotPassword':
      if (user) {
        return <AccountAccessForgetPassword user={user} onModeChange={onChangeMode} />
      }
      return null
    case 'resetPassword':
      return <AccountAccessResetPassword token={resetPasswordToken!} callbackPath={callbackPath}/>
    case 'linkPartnership':
      return <AccountAccessPartnershipLinkAccount />
    case 'agentResendActivation':
      if (isAgentHubMode && user?.email) {
        return <AgentHubResendActivationEmail requestedEmail={user.email} onModeChange={onChangeMode} />
      }
      return null
    default:
      return null
  }
}

export default AccountAccessModalContent
