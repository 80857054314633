import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import FormatCurrency from 'components/Common/FormatCurrency'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryDown, mediaQueryUp } from 'components/utils/breakpoint'
import TextButton from 'components/Luxkit/Button/TextButton'
import { setAppBannerCookie } from 'cookies/appBannerCookie'
import TextLink from 'components/Luxkit/TextLink'
import { copyValueToClipboard } from 'lib/web/clipboardUtils'
import { useResponsive } from 'hooks/useResponsive'
import getDatasetFromEvent from 'lib/analytics/getDatasetFromEvent'
import Image from 'components/Common/Image'
import noop from 'lib/function/noop'
import config from 'constants/config'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'

interface Props {
  currentRegionCode: string;
  onClose?: () => void;
  hasCpcUtmMedium: Boolean;
  hasGoogleClickIdentifier: Boolean;
}

const Inner = styled(LayoutContainer)`
  display: grid;
  gap: ${rem(16)};
  grid-template-columns: 1fr;
  padding: ${rem(24)} ${rem(20)};

  ${mediaQueryUp.tablet} {
    display: flex;
    align-items: flex-end;
    gap: ${rem(32)};
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  flex-direction: row-reverse;
  justify-content: flex-end;

  > * {
    flex-grow: 1;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  gap: ${rem(20)};

  ${mediaQueryDown.mobile} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  & :first-child {
    flex-shrink: 0;
  }
`

let circuitBreak = 0
function ContinueToAppDrawer({ onClose = noop, hasCpcUtmMedium, hasGoogleClickIdentifier, currentRegionCode }: Props) {
  const [url, setUrl] = useState<string>('')
  const [copy, setCopy] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOpen(true)
        return window.removeEventListener('scroll', handleScroll)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [match] = useResponsive() // This useResponsive is ok to use because result will be used inside an useEffect and never in the SSR

  const uptoTablet = useMemo(() => match.mobile || match.tablet, [match])

  const promoAppBannerFeatureFlag = !!useOptimizelyExperiment(OptimizelyFeatureFlags.promoAppBanner)
  const promoAppBannerAuABTest = !!useOptimizelyExperiment(OptimizelyExperiments.croAppPromoAuEnabled)

  const regionPromotionData = config.appBanner?.promotionData[currentRegionCode] || {}
  const showSpecialCodeForAdClicks = !!regionPromotionData.specialCodeForAdClicks && (hasCpcUtmMedium || hasGoogleClickIdentifier)
  const code = showSpecialCodeForAdClicks ? regionPromotionData.specialCodeForAdClicks : regionPromotionData.code
  const { discount, minSpend } = regionPromotionData

  const promoAppDrawer = useMemo(() => {
    return isEnabledForFeature(config.PROMO_BANNER_REGIONS, currentRegionCode) && (discount && code && minSpend) && (promoAppBannerFeatureFlag || promoAppBannerAuABTest)
  }, [currentRegionCode, discount, code, minSpend, promoAppBannerFeatureFlag, promoAppBannerAuABTest])

  useEffect(() => {
    // This is here because the AF_SMART_SCRIPT does not have a callback
    // So we need to try multiple times (5) to check if the script has loaded
    // Before we show the app banner
    const interval = setInterval(() => {
      // We only want to show this on upto tablet size
      if (!uptoTablet) {
        // So if screen size is larger we clear the interval
        !!interval && clearInterval(interval)
      }
      else if (!window.AF_SMART_SCRIPT_RESULT) {
        // We try every 5 seconds 5 times
        circuitBreak++
        // If past 5 times there is no script loaded we clear the interval
        if (circuitBreak > 4) clearInterval(interval)
      }
      else {
        // If the script is loaded and the screen size is upto tablet we show the banner by setting it all up
        // And then we clear the interval
        if (!promoAppDrawer) {
          const oneLinkURL = 'https://luxuryescapes.onelink.me/3WHB'
          const mediaSource = { keys: ['utm_source'], defaultValue: 'af_banner' }
          const campaign = { keys: ['utm_campaign'], defaultValue: 'af_banner_campaign' }
          const googleClickIdKey = 'af_sub1'
          const ad = { defaultValue: 'nopromo' }
          const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' }
          const custom_ss_gtm_ui = { paramKey: 'af_ss_gtm_ui', defaultValue: 'true' }

          window.AF_SMART_SCRIPT_RESULT = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL,
            afParameters: {
              mediaSource,
              campaign,
              googleClickIdKey,
              ad,
              afCustom: [
                custom_ss_ui,
                custom_ss_gtm_ui,
              ],
            },
          })
        }

        setUrl(window.AF_SMART_SCRIPT_RESULT.clickURL)
        setTimeout(() => {
          window.AF_SMART_SCRIPT.fireImpressionsLink()
        }, 1000)
        clearInterval(interval)
      }
    }, 5000)
    return () => {
      // Clear interval before the component unmounts to prevent any memory leaks
      !!interval && clearInterval(interval)
    }
  }, [promoAppDrawer, uptoTablet])

  const closeAndSetCookie = useCallback(() => {
    setOpen(false)
    onClose()
    setAppBannerCookie()
  }, [onClose])

  const copyToClipboard = useCallback((event) => {
    const code = getDatasetFromEvent(event, 'code')
    setCopy(true)
    copyValueToClipboard(code)
    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }, [])

  return (
    <ModalBase isOpen={!!(open && url)} onClose={closeAndSetCookie}>
      <Inner size="sm">
        <TextWrapper>
          <HeadingWrapper>
            <Image id={ config.appBanner?.icon } height="40" width="40" />
            <Heading variant="heading5" as="h1">
              {!promoAppDrawer && "The world's best holidays, in the palm of your hand."}
              {promoAppDrawer && discount && code && <> Get <FormatCurrency value={discount}/> off on your first app booking. Use code: <TextLink data-action="copy" data-label="promo" data-code={code} onClick={copyToClipboard}>{code}</TextLink>{copy && <BodyText as="span" variant="small" colour="neutral-two">&nbsp;Copied</BodyText>}</>}
            </Heading>
          </HeadingWrapper>
          <BodyText variant="large" colour="neutral-two">
            Download the Luxury Escapes app to get access to exclusive offers and personalised recommendations.
          </BodyText>
          <BodyText variant="small" colour="neutral-three">
            {promoAppDrawer && minSpend && <> Min. spend <FormatCurrency value={minSpend}/>. Valid on all Limited Time LUX Exclusive, LUX Premium Collection and Ultra LUX offers. T&Cs apply.</>}
          </BodyText>
        </TextWrapper>
        <ButtonsWrapper>
          <TextButton data-action="switch" data-label="button" href={url} onClick={closeAndSetCookie} kind="primary" size="large">Switch to the app</TextButton>
          <TextButton data-action="exit" data-label="dismiss" onClick={closeAndSetCookie} kind="secondary" size="large">Not now</TextButton>
        </ButtonsWrapper>
      </Inner>
    </ModalBase>
  )
}

const mapStateToProps = (state: App.State) => ({
  currentRegionCode: state.geo.currentRegionCode,
  hasCpcUtmMedium: state.utm.medium === 'cpc',
  hasGoogleClickIdentifier: !!state.router.location.query.gclid,
})

export default connect(mapStateToProps)(ContinueToAppDrawer)
