import React, { MutableRefObject, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import LineArrowsSwapHorizontalIcon from 'components/Luxkit/Icons/line/LineArrowsSwapHorizontalIcon'
import FlightSearchAirportSelect from './FlightAirportSelect/FlightSearchAirportSelect'
import IconButton from 'components/Luxkit/Button/IconButton'
import cn from 'clsx'

const InputContainer = styled(SearchFormFieldGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  .arrival-select {
    &--read-only{
      padding-left: 33px;
    }
    
    .display-field,  input {
      padding-left: 33px;
    }
  }
`

const ExchangeButtonContainer = styled.div`
  position: absolute;
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  background-color: #FFFFFF;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`

interface Props {
  className?: string
  readOnly?: boolean;
  id: string;
  onArrivalChange?: (airport: App.AirportLocation) => void;
  onDepartureChange?: (airport: App.AirportLocation) => void;
  arrivalFieldRef?: MutableRefObject<HTMLInputElement | null> | ((node: HTMLInputElement) => void);
  departingFieldRef?: MutableRefObject<HTMLInputElement | null> | ((node: HTMLInputElement) => void);
  departingRecentAirports?: Array<App.AirportLocation>;
}

function FlightSearchAirportInputs(props: Props) {
  const { className, readOnly, id, onArrivalChange, onDepartureChange, departingFieldRef, arrivalFieldRef, departingRecentAirports } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const currentFlight = useMemo(() => {
    return state.flights.find((flight) => flight.id === id)
  }, [state.flights, id])

  const {
    departureAirport,
    arrivalAirport,
  } = currentFlight!

  const onSwapAirports = useCallback(() => {
    const departure = departureAirport
    dispatch({ type: FlightSearchWidgetActions.SET_DEPARTURE_AIRPORT, id, airport: arrivalAirport })
    dispatch({ type: FlightSearchWidgetActions.SET_ARRIVAL_AIRPORT, id, airport: departure })
  }, [departureAirport, dispatch, arrivalAirport, id])

  const onDepartureSelect = useCallback((airport: App.AirportLocation) => {
    dispatch({ type: FlightSearchWidgetActions.SET_DEPARTURE_AIRPORT, id, airport })
    onDepartureChange?.(airport)
  }, [dispatch, id, onDepartureChange])

  const onArrivalSelect = useCallback((airport: App.AirportLocation) => {
    dispatch({ type: FlightSearchWidgetActions.SET_ARRIVAL_AIRPORT, id, airport })
    onArrivalChange?.(airport)
  }, [dispatch, id, onArrivalChange])

  return (
    <InputContainer className={className}>
      <FlightSearchAirportSelect
        label="From"
        required
        direction="departure"
        onSelectAirport={onDepartureSelect}
        airport={departureAirport}
        alternateAirport={arrivalAirport}
        readOnly={readOnly}
        ref={departingFieldRef}
        fallbackAirports={departingRecentAirports}
        fallbackHeading={departingRecentAirports ? 'Recent search' : undefined}
      />

      <FlightSearchAirportSelect
        label="To"
        required
        direction="arrival"
        onSelectAirport={onArrivalSelect}
        airport={arrivalAirport}
        alternateAirport={departureAirport}
        readOnly={readOnly}
        ref={arrivalFieldRef}
        className={cn('arrival-select', {
          'arrival-select--read-only': readOnly,
        })}
      />

      <ExchangeButtonContainer className="swap">
        <IconButton variant="default" kind="tertiary" size="small" shape="circle" onClick={onSwapAirports} disabled={readOnly}>
          <LineArrowsSwapHorizontalIcon size="S" />
        </IconButton>
      </ExchangeButtonContainer>
    </InputContainer>
  )
}

export default FlightSearchAirportInputs
