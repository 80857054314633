import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAppleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20.5044 15.7436a3.3862 3.3862 0 0 1-2.0547-3.127 3.4978 3.4978 0 0 1 1.6875-2.9462 1.0004 1.0004 0 0 0 .4552-1.0449.999.999 0 0 0-.1554-.3701 5.574 5.574 0 0 0-4.0161-2.3858 6.422 6.422 0 0 0 .9648-3.9766.984.984 0 0 0-1.0332-.8916 6.6906 6.6906 0 0 0-4.4433 2.2608 6.8408 6.8408 0 0 0-1.4795 2.8994 5.2637 5.2637 0 0 0-1.6202-.2773A5.9252 5.9252 0 0 0 3.8188 8.892c-1.8877 3.2774-.7329 8.1885 1.312 11.1446.8203 1.1845 2.0513 2.9629 3.9082 2.9629.0366 0 .0738 0 .1109-.002a4.7414 4.7414 0 0 0 1.7925-.4639 3.2731 3.2731 0 0 1 1.4453-.3535 3.0435 3.0435 0 0 1 1.3574.3379 4.7535 4.7535 0 0 0 1.9619.456c1.9131-.0361 3.043-1.6865 3.8682-2.8926a12.0403 12.0403 0 0 0 1.5127-3.1084 1.003 1.003 0 0 0-.5835-1.2294ZM13.4233 4.5679a4.9413 4.9413 0 0 1 1.9195-1.3223 4.8557 4.8557 0 0 1-1.0367 2.1514 4.3907 4.3907 0 0 1-1.2641 1.04l-.0029.001c-.0284.0118-.0567.0225-.085.0342-.1866.0733-.417.164-.5948.2227a4.8255 4.8255 0 0 1 1.064-2.127Zm4.5015 14.3847c-.8037 1.1739-1.436 2.0069-2.2544 2.0215a2.3658 2.3658 0 0 1-1.1314-.292 4.9694 4.9694 0 0 0-2.1513-.502 5.214 5.214 0 0 0-2.2285.5127 2.8846 2.8846 0 0 1-1.086.3066h-.0298c-.813 0-1.6875-1.2626-2.268-2.1015-1.4527-2.0996-2.7925-6.2832-1.2232-9.0078a3.917 3.917 0 0 1 3.2906-2.0058l.0336-.001a4.7585 4.7585 0 0 1 1.6412.4443c.1313.0518.2607.1035.3891.1514a1.17 1.17 0 0 0 .1006.037 3.316 3.316 0 0 0 1.1172.2374 4.4365 4.4365 0 0 0 1.564-.4209 4.956 4.956 0 0 1 2.2226-.497 3.7869 3.7869 0 0 1 2.2505.8535 5.3101 5.3101 0 0 0-1.7119 3.9453v.001a5.301 5.301 0 0 0 2.4512 4.5029 10.1981 10.1981 0 0 1-.9761 1.8144Z"/>
  </SvgIcon>
}

export default LineAppleIcon
