import { excludeNullOrUndefined } from 'checkout/utils'
import { isCruiseItem, isTourV1Item } from 'lib/checkout/checkoutUtils'
import createSelector from 'lib/web/createSelector'
import { pluralizeToString } from 'lib/string/pluralize'
import { CABIN_CATEGORIES } from 'constants/cruise'
import { CheckoutPageId } from 'checkout/constants/pages'

import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import { getDepositServiceFeeConfig } from 'checkout/selectors/featureConfig/deposit'
import {
  getSelectedCruiseItem,
  getBookingCruiseOffer,
  getBookingRateListData,
  getBookingCabinListData,
  getBookingCabinSelectionData,
  getBookingCabinPricingData,
  getBookingCustomShipCategoryGroups,
  getBookingCabinsWithPrice,
  getBookingCabinCategories,
  getCurrentCheckoutPage,
} from 'checkout/selectors/cruiseSelectors'

import { DepositType } from 'constants/checkout'
import { buildCruiseItemView } from 'checkout/lib/utils/cruises/view'
import { getCruiseCountriesByItinerary, getCruisePortsByItinerary, getCruiseDurationByItinerary } from 'lib/cruises/cruiseUtils'
import { sum, EmptyArray, sortBy, groupBy } from 'lib/array/arrayUtils'
import {
  getMultiCabinError,
  getRateListByCruiseItem,
  getRateDetailsByCruiseItem,
  getCabinSelectionByCruiseItem,
  getCabinPricingByCruiseItem,
  buildCabinCategoriesGroup,
} from 'checkout/lib/utils/cruises/booking'

// Booking V2 - Step Views Selectors
export const getBookingCabinTypeStepView = createSelector(
  getBookingRateListData,
  getBookingCruiseOffer,
  (rateListData, offer): Cruises.BookingCabinTypeStepView => {
    const cabinRates = rateListData.rates
    const cabinCategories: Array<App.CruiseShipCabinCategory> = offer.ship.cabinCategories ?? EmptyArray
    const offerEvergreenInclusions = offer.evergreenInclusions
    const ratesByType = groupBy(cabinRates, rate => rate.cabinType)

    const cabinTypes = CABIN_CATEGORIES
      .map((cabinType) => {
        const categoryRates = ratesByType.get(cabinType.category) ?? []
        const [lowestRate] = sortBy(categoryRates, rate => rate.priceDetails.price, 'asc')
        const shipCategory = cabinCategories.find(category => category.cabinType === cabinType.category)
        const evergreenInclusions = offerEvergreenInclusions.filter((inclusion) => inclusion.cabinTypes.includes(cabinType.category))

        return {
          name: cabinType.category,
          imageId: shipCategory?.imageId || cabinType.defaultImageId,
          lowestRatePrice: lowestRate?.priceDetails,
          evergreenInclusions,
        }
      })

    // We need to consider the errors of all cabins
    const errorData = getMultiCabinError([rateListData])

    return {
      cabinTypes,
      isLoading: rateListData.loading,
      isError: errorData.isError,
      errorMessage: errorData.errorMessage,
      errorToRender: errorData.errorToRender,
    }
  },
)

export const getBookingCabinCategoryStepView = createSelector(
  getBookingCabinCategories,
  getBookingRateListData,
  getBookingCabinListData,
  getBookingCustomShipCategoryGroups,
  (allCategories, rateListData, cabinListData, customCabinCategoriesGroups): Cruises.BookingCabinCategoryStepView => {
    // If we have custom categories groups coming from Admin Portal, use them
    let customCategoryGroups: Array<Cruises.BookingCabinCategoryGroupView> = []
    if (customCabinCategoriesGroups.length) {
      customCategoryGroups = customCabinCategoriesGroups.map((group) => {
        const categoryCodes = group.cabinCategories.map(category => category.code)
        const cabinCategories = allCategories.filter(category => categoryCodes.includes(category.code))

        if (!cabinCategories.length) return null
        return buildCabinCategoriesGroup(group.name, cabinCategories)
      }).filter(Boolean) as Array<Cruises.BookingCabinCategoryGroupView>
    }

    // If we don't have custom categories groups, we use the default ones
    const customCategoryCodes = customCabinCategoriesGroups.map(group => group.cabinCategories.map(category => category.code)).flat()
    const categoriesWithoutCustomGroup = allCategories.filter(category => !customCategoryCodes.includes(category.code))

    const groupedCategories: Map<string, Array<Cruises.BookingCabinCategory>> = groupBy(
      categoriesWithoutCustomGroup,
      category => category.name.toLowerCase(),
    )
    const defaultCategoryGroups = Array.from(groupedCategories).map(([, cabinCategories]) => {
      const name = cabinCategories[0].name
      return buildCabinCategoriesGroup(name, cabinCategories)
    })

    const categoryGroups = sortBy([
      ...customCategoryGroups,
      ...defaultCategoryGroups,
    ], (group) => group.lowestRatePrice.price, 'asc')

    // We need to consider the errors of all cabins
    const errorData = getMultiCabinError([rateListData, cabinListData])

    return {
      categoryGroups,
      isLoading: rateListData.loading,
      isError: errorData.isError,
      errorMessage: errorData.errorMessage,
      errorToRender: errorData.errorToRender,
    }
  },
)

export const getBookingCabinLocationStepView = createSelector(
  getBookingCabinsWithPrice,
  getBookingCruiseOffer,
  getBookingCabinCategories,
  getBookingRateListData,
  getBookingCabinListData,
  (cabinsWithPrice, offer, categories, rateListData, cabinListData): Cruises.BookingCabinLocationStepView => {
    const normalCabins = cabinsWithPrice
      .filter(cabin => !cabin.isGuaranteed)

    const cabinsByCategory = Array.from(groupBy(normalCabins, cabin => cabin.lowestCategoryCode))
      .map(([code, cabins]) => {
        const category = categories.find(category => category.code === code)

        if (category) {
          const lowestRatePrice = sortBy(cabins, cabin => cabin.lowestPrice?.price, 'asc')[0]?.lowestPrice
          const { name, color, images, description, evergreenInclusions } = category
          const cabinsCount = cabins.length

          return {
            name,
            code,
            color,
            cabins,
            description,
            images,
            cabinsCount,
            evergreenInclusions,
            lowestRatePrice,
          }
        }

        return null
      }).filter(Boolean) as Array<Cruises.BookingCabinsByCategoryView>

    const sortedCabinsByCategory = sortBy(cabinsByCategory, category => category.lowestRatePrice?.price, 'asc')
    const lowestCabinPrice = sortBy(normalCabins, cabin => cabin.lowestPrice?.price, 'asc')[0]?.lowestPrice

    const guaranteedCabin = cabinsWithPrice.find(cabin => cabin.isGuaranteed)
    let guaranteedCabinImageId: string = ''

    if (guaranteedCabin) {
      const category = categories.find(category => category.code === guaranteedCabin.lowestCategoryCode)
      guaranteedCabinImageId = category?.images[0]?.id ?? ''
    }

    // We need to consider the errors of all cabins
    const errorData = getMultiCabinError([rateListData, cabinListData])

    return {
      shipId: offer.ship.id,
      cabins: normalCabins,
      cabinsByCategory: sortedCabinsByCategory,
      lowestCabinPrice,
      guaranteedCabin,
      guaranteedCabinImageId,
      isLoading: rateListData.loading || cabinListData.loading,
      isError: errorData.isError,
      errorMessage: errorData.errorMessage,
      errorToRender: errorData.errorToRender,
    }
  },
)

export const getBookingCabinPackageStepView = createSelector(
  getBookingRateListData,
  getBookingCabinListData,
  getSelectedCruiseItem,
  (rateListData, cabinListData, cruiseItem): Cruises.BookingCabinPackageStepView => {
    const { rates } = rateListData
    const { cabinByNumber } = cabinListData

    const cabin = cruiseItem.cabinNumber ? cabinByNumber[cruiseItem.cabinNumber] : null
    const cabinComponentIds = cabin?.componentIds ?? []

    const cabinRates = rates.filter((cabinRate) => (
      cruiseItem.cabinCode === cabinRate.pricedCategoryCode &&
      cabinRate.cabinType === cruiseItem.cabinType &&
      cabinComponentIds.includes(cabinRate.componentId)
    ))

    // We need to consider the errors of all cabins
    const errorData = getMultiCabinError([rateListData, cabinListData])

    return {
      packages: sortBy(cabinRates, (rate) => rate.priceDetails.price, 'asc'),
      isLoading: rateListData.loading || cabinListData.loading,
      isError: errorData.isError,
      errorMessage: errorData.errorMessage,
      errorToRender: errorData.errorToRender,
    }
  },
)

// Other Selectors (move to cruiseSelectors)
export const getCruiseDepositDetailsFromCabinHold = createSelector(
  (state: App.State) => getCruiseItems(state),
  (state: App.State) => state.cruise.multiBooking.cabinSelection,
  (cruiseItems, allCabinSelectionData): Array<Cruises.CruiseDepositDetails> => {
    return cruiseItems.map((item) => {
      const cabinSelectionData = getCabinSelectionByCruiseItem(item, allCabinSelectionData)
      const { cabinSelection } = cabinSelectionData

      if (cabinSelection) {
        const deposit = cabinSelection.pricing.paymentSchedule?.firstPayment

        if (!deposit) return null

        return {
          amount: deposit.amount,
          dueDate: deposit.dueDate,
          currencyCode: deposit.currencyCode,
        }
      }

      return null
    }).filter(Boolean) as Array<Cruises.CruiseDepositDetails>
  },
)

// Legacy Selectors
export const getCruiseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.CruiseItem> => items.filter(isCruiseItem),
)

export const getCruiseOnboardCreditDetails = createSelector(
  getBookingCabinSelectionData,
  getBookingCabinPricingData,
  (cabinSelectionData, cabinPricingData): Cruises.CruiseOnboardCreditDetails | null => {
    const { cabinSelection } = cabinSelectionData
    const { cabinPricing } = cabinPricingData

    if (cabinPricing) {
      const onboardCredits = cabinPricing.pricing.onboardCredit

      if (onboardCredits?.amount && onboardCredits?.currency) {
        return {
          amount: onboardCredits.amount,
          currencyCode: onboardCredits.currency,
        }
      }

      return null
    }

    if (cabinSelection) {
      const onboardCredits = cabinSelection.pricing.onboardCredit

      if (!onboardCredits?.amount || !onboardCredits?.currency) return null

      return {
        amount: onboardCredits.amount,
        currencyCode: onboardCredits.currency,
      }
    }

    return null
  },
)

export const getCheckoutCruiseOfferView = createSelector(
  (state: App.State) => getCruiseItems(state),
  getBookingCruiseOffer,
  (state: App.State) => state.cruise.cruiseDeparture,
  (state: App.State) => state.cruise.deckSelections,
  getDepositServiceFeeConfig,
  (state: App.State) => state.cruise.multiBooking.cabinPricing,
  (state: App.State) => state.cruise.multiBooking.cabinSelection,
  (state: App.State) => state.cruise.multiBooking.rateList,
  (state: App.State) => state.cruise.multiBooking.rateDetails,
  getCurrentCheckoutPage,
  (
    items,
    offer,
    departures,
    deckSelections,
    serviceFee,
    allCabinPricingData,
    allCabinSelectionData,
    allRateListData,
    allRateDetailsData,
    currentCheckoutPage,
  ): App.WithDataStatus<Array<App.Checkout.CruiseAccommodationOfferView>> => {
    if (items.length === 0) { return { hasRequiredData: true, data: [] } }

    const item = items[0]
    const dayDuration = getCruiseDurationByItinerary(offer?.itinerary)
    const countriesCount = getCruiseCountriesByItinerary(offer?.itinerary).length
    const portsCount = getCruisePortsByItinerary(offer?.itinerary).length
    const isPurchasePage = currentCheckoutPage === CheckoutPageId.Purchase

    const offerView: App.Checkout.CruiseAccommodationOfferView = {
      offer,
      offerId: item.offerId,
      mainLabel: offer?.name,
      duration: offer?.duration,
      startDate: departures?.[item.departureId]?.departureDate,
      endDate: departures?.[item.departureId]?.returnDate,
      image: offer?.images?.[0],
      location: getLocationString(offer),
      occupancy: items.map(item => item.occupancy).filter(excludeNullOrUndefined),
      offerType: offer?.type,
      reservationType: 'instant_booking',
      saleUnit: 'cabin',
      durationLabel: pluralizeToString('night', dayDuration - 1),
      offerLoaded: !!offer,
      itemViews: [],
      propertyTimezone: 'GMT',
      operatorName: offer ? `Operated by: ${offer.cruiseLine.name}` : '',
      shipName: offer?.ship ? `Cruise ship: ${offer.ship.name}` : '',
      countries: countriesCount,
      ports: portsCount,
      serviceFee,
      depositType: DepositType.FLAT,
      designation: 'Cruise',
      ship: offer?.ship,
      deckSelections: deckSelections ?? {},
      urgencyLabels: [],
      bedGroups: [],
      confidenceLabels: [],
      currentCheckoutPage,
    }

    const itemViewsWithStatus = items.map(item => {
      const rateListData = getRateListByCruiseItem(item, allRateListData)
      const cabinSelectionData = getCabinSelectionByCruiseItem(item, allCabinSelectionData)
      const cabinPricingData = getCabinPricingByCruiseItem(item, allCabinPricingData)
      const rateDetailsData = getRateDetailsByCruiseItem(item, allRateDetailsData)

      const itemViewWithStatus = buildCruiseItemView(
        item,
        offer,
        cabinSelectionData,
        cabinPricingData,
        rateListData,
        rateDetailsData,
        isPurchasePage,
      )

      return itemViewWithStatus
    })

    const hasRequiredData = itemViewsWithStatus.every(({ hasRequiredData }) => hasRequiredData)
    const itemViews = itemViewsWithStatus.map(({ data }) => data)

    // Calculate the total deposit amount
    const depositAmount = sum(itemViewsWithStatus, ({ data }) => data.depositAmount)

    return {
      hasRequiredData,
      data: [{
        ...offerView,
        itemViews,
        ...(depositAmount && { depositAmount }),
      }],
    }
  },
)

export const getCheckoutCruiseOffer = createSelector(
  getCheckoutCruiseOfferView,
  (offerViewWithStatus): App.CruiseOffer | undefined => offerViewWithStatus.data[0]?.offer,
)

export const getCruisesV1 = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.offer.offers,
  (items, leOffers) => {
    return items.filter(isTourV1Item).filter(item => {
      const offer = leOffers[item.offerId]
      return !!offer && !!offer.holidayTypes?.includes('Cruises')
    })
  },
)

export const isCruiseCheckout = createSelector(
  getCruiseItems,
  getCruisesV1,
  (state: App.State) => state.checkout.cart.existingOrder?.cruiseItems,
  (cruiseItems, cruiseOfTourV1Items, existingCruiseItems) => {
    return !!cruiseItems.length || !!cruiseOfTourV1Items.length || !!existingCruiseItems?.length
  },
)
