import { fetchBestPriceForOffer } from 'actions/OfferActions'
import Pane from 'components/Common/Pane'
import { useAppDispatch } from 'hooks/reduxHooks'
import { buildSearchParamsKey } from 'lib/search/searchUtils'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import SearchOfferTileCondensed from '../SearchOfferTileCondensed'
import SearchOfferTileLoadingSkeleton from '../SearchOfferTileLoadingSkeleton'
import SearchVillaTileExpanded from './SearchVillaTileExpanded'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface Props {
  offer: App.VillaOffer
  filters: App.OfferListFilters | undefined
  bestPrices?: {
    [key: string]: App.OfferMaybeAvailableRate;
  };
  pricesErrors?: { [key: string]: any };
  offerUrl: string
  onImageChange?: (idx: number, _?: App.Image) => void,
}

function SearchVillaTile(props: Props) {
  const { offer, filters, bestPrices, pricesErrors, offerUrl, onImageChange } = props
  const dispatch = useAppDispatch()

  const metaData = useOfferMetaData(offer.id, filters)
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))
    if (checkIn && checkOut && !occupanciesInvalid) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: filters?.rooms,
      }))
    }
  }, [dispatch, filters, offer, bestPrices, checkIn, checkOut])

  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])
  const hasDates = !!checkIn && !!checkOut
  const bestPrice = bestPrices ? bestPrices[searchKey] : undefined
  const bestPriceError = pricesErrors ? pricesErrors[searchKey] : undefined
  const available = bestPrice?.available
  const rate = available ? bestPrice.rate : undefined
  const fetchingPrice = hasDates && !bestPrice && !bestPriceError
  const lowestPrice = offer.lowestPricePackage?.price
  const soldOut = hasDates ? !!bestPrice && !available : !lowestPrice

  const bestPricePackage = useMemo(() => {
    if (!hasDates || bestPriceError) {
      return offer.lowestPricePackage
    }
    if (available && 'packages' in offer) {
      return offer.packages.find(pkg => pkg.uniqueKey === rate?.packageUniqueKey)
    }
  }, [offer, available, rate, hasDates, bestPriceError])

  useOfferSoldOutPushDown(offer.id, filters, !fetchingPrice && soldOut)

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingPrice))) {
      onEvent(OfferListEvents.offerReady, {
        available: !soldOut && !!rate,
      })
    }
  }, [imageLoaded, onEvent, hasDates, fetchingPrice, rate, soldOut])

  return (<Pane type="clean">
    {fetchingPrice && <SearchOfferTileLoadingSkeleton /> }
    {soldOut && <SearchOfferTileCondensed offer={offer} filters={filters} onImageChange={onImageChange} onImageLoad={handleImageLoaded}/>}
    {!fetchingPrice && !soldOut &&
      <SearchVillaTileExpanded
        offer={offer}
        bestPriceForDates={rate}
        filters={filters}
        offerUrl={offerUrl}
        bestPricePackage={bestPricePackage}
        onImageChange={onImageChange}
        onImageLoad={handleImageLoaded}
      />
    }
  </Pane>)
}

const mapStateToProps = (state: App.State, ownProps: Partial<Props> & {offer: App.Offer}) => ({
  bestPrices: state.offer.offerBestPrices[ownProps.offer.id],
  pricesErrors: state.offer.offerPricesErrors[ownProps.offer.id],
})

export default connect(mapStateToProps)(SearchVillaTile)
