export const isActiveMemberWithSubscriptionTier = (result: App.MemberSubscriptionItem) => result.tier !== 'free_preview' && result.status !== 'CONFIRMED_AWAITING_PAYMENT'

/**
 * Used for LuxPlus+ bonus inclusions to display "neater" rounded value
 */
export function roundLuxPlusInclusionsValue(num: number): number {
  // If the number is less than 50, return it as is
  if (num < 50) {
    return num
  }

  // Calculate the closest multiple of 50 less than or equal to the number
  const roundedTo50 = Math.floor(num / 50) * 50
  // Calculate the closest multiple of 100 less than or equal to the number
  const roundedTo100 = Math.floor(num / 100) * 100

  // Determine whether to round down to the closest 50 or 100
  if (num - roundedTo50 < 50) {
    // If the difference between the number and the closest 50 is less than 50, return the closest 50
    return roundedTo50
  } else {
    // Otherwise, return the closest 100
    return roundedTo100
  }
}
