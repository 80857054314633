import { fetchExperienceCategories } from 'actions/ExperienceActions'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import { SearchMenuStates } from 'components/Search/type'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import ExperienceSearchCategoryField from './ExperienceSearchCategoryField'
import ExperienceSearchCategoryResults from './ExperienceSearchCategoryResults'

function ExperienceSearchCategorySelect() {
  const dispatch = useAppDispatch()
  const dropdownTriggerRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    dispatch(fetchExperienceCategories())
    // eslint-disable-next-line
  }, [])

  const { activeMenu } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const showDropdown = activeMenu === SearchMenuStates.ExperienceCategories

  const toggleDropdown = useCallback(() => {
    const nextMenu = showDropdown ? SearchMenuStates.Closed : SearchMenuStates.ExperienceCategories
    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: nextMenu })
  }, [searchDispatch, showDropdown])

  return (<div>
    <ExperienceSearchCategoryField ref={dropdownTriggerRef} onClick={toggleDropdown} />
    <DropdownList
      size="fill-anchor"
      anchorRef={dropdownTriggerRef}
      triggerRef={dropdownTriggerRef}
      open={showDropdown}
      disableFlip
      onClose={toggleDropdown}
    >
      {showDropdown && <ExperienceSearchCategoryResults />}
    </DropdownList>
  </div>

  )
}

export default ExperienceSearchCategorySelect
