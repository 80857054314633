import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getOfferById } from 'api/offer'
import { findCheapestOfTourV2Offer } from 'lib/tours/tourUtils'
import getOrderDataMemberPricingMetadata from './orderMemberPricingMetadata'

async function getTourOrderItems(state: App.State, order: App.Order) {
  const promises = order.tourItems.map(async orderItem => {
    let fullOffer = state.offer.offers[orderItem.tourId]
    if (!fullOffer) {
      fullOffer = await getOfferById(orderItem.tourId, { region: state?.geo?.currentRegionCode, currentCurrency: state?.geo?.currentCurrency }) as App.Offer
    }
    const { categoryId, productId } = getCategoryAndProductId(fullOffer?.parentType, fullOffer)
    const parentType = fullOffer.parentType as App.OfferParentType
    const isTourV2 = parentType === 'tour_v2'
    const fullOfferAsTourV2 = fullOffer as unknown as Tours.TourV2Offer
    const geo = fullOffer?.property?.geoData
    const luxPlusMetaData = getOrderDataMemberPricingMetadata(orderItem)

    let offerLeadPrice: number | undefined = undefined

    if (isTourV2) {
      offerLeadPrice = findCheapestOfTourV2Offer(fullOfferAsTourV2)?.cheapestPurchasableOption?.fullPrice
    }

    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem.tourId,
        categoryId,
        productId,
        duration: orderItem.tour.duration,
        travelStart: orderItem.tour.startDate,
        travelEnd: orderItem.tour.endDate,
        price: orderItem.total,
        offerLeadPrice,
        offerLeadDuration: orderItem?.tour?.duration ? String(orderItem?.tour?.duration) : undefined,
        offerLeadUnit: fullOffer?.saleUnit,
        currency: order.currencyCode,
        destinationCity: geo?.locality,
        destinationCountry: geo?.country,
        destinationRegion: geo?.administrativeAreaLevel1,
        itemId: orderItem.itemId,
        bookingNumber: order.number,
        orderId: order.id,
        metadata: luxPlusMetaData ? JSON.stringify(luxPlusMetaData) : undefined,
      },
    )
  })
  return await Promise.all(promises)
}

export default getTourOrderItems
