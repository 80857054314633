import React, { memo, useContext, useCallback, useRef } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { pushWithCruiseFilters } from 'actions/CruiseActions'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Pane from 'components/Common/Pane'
import TextButton from 'components/Luxkit/Button/TextButton'
import CruiseSearchInputs from './Inputs/CruiseSearchInputs'
import useQueryParams from 'hooks/useQueryParams'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import { SEARCH_VERTICALS } from 'constants/search'
import * as Analytics from 'analytics/analytics'
import { getCruiseSearchFiltersBar } from 'lib/cruises/cruiseUtils'

const CruiseSearchFiltersBarGrid = styled.div`
  display: grid;
  gap: ${rem(12)};
  grid-template-columns: 1fr;

  ${mediaQueryUp.tablet} {
    grid-template: "destination-input departure-input cruise-line-input date-input" auto / 1fr 1fr 1fr 1fr ${rem(112)};
  }
`

const SearchButton = styled(TextButton)`
  height: 100%;
`

interface Props {
  forceSearchPage?: boolean;
}

function CruiseSearchFiltersBar({ forceSearchPage }: Props) {
  const dispatch = useAppDispatch()
  const globalSearch = useContext(GlobalSearchStateContext)
  const pathname = useAppSelector(state => state.router.location.pathname)
  const regionCode = useAppSelector(state => state.geo.currentRegionCode)
  const queryParams = useQueryParams()
  const searchBarRef = useRef<HTMLDivElement>(null)
  const {
    searchItems: globalSearchItems,
    secondarySearchItems: globalSecondarySearchItems,
    flexibleMonths: globalFlexibleMonths,
    durationMin: globalDurationMin,
    durationMax: globalDurationMax,
    durationRange: globalDurationRange,
    eventAnalytics: globalEventAnalytics,
    checkinDate: globalCheckinDate,
    checkoutDate: globalCheckoutDate,
    cruiseLines: globalCruiseLines,
  } = globalSearch

  // update url when clicking search button
  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    Analytics.trackEvent(searchEventWithContext(
      globalEventAnalytics.contextLocation ?? 'common-search',
      globalEventAnalytics.searchItemCategory,
      mapGlobalSearchContextToSnowplowSearchEvent({
        searchItems: globalSearchItems,
        secondarySearchItems: globalSecondarySearchItems,
        searchVerticals: new Set([SEARCH_VERTICALS.CRUISES]),
        flexibleMonths: globalFlexibleMonths,
        durationMin: globalDurationMin,
        durationMax: globalDurationMax,
        durationRange: globalDurationRange,
        checkinDate: globalCheckinDate,
        checkoutDate: globalCheckoutDate,
        cruiseLines: globalCruiseLines,
      }),
    ))

    dispatch(pushWithCruiseFilters('/search/cruises', globalSearch, queryParams))
  }, [globalEventAnalytics, dispatch, globalSearch, queryParams, globalSearchItems, globalSecondarySearchItems, globalFlexibleMonths, globalDurationMin, globalDurationMax, globalDurationRange, globalCheckinDate, globalCheckoutDate, globalCruiseLines])

  const onChange = useCallback((values : App.CruiseGlobalFilters) => {
    const { pageName, filters } = getCruiseSearchFiltersBar(values, globalSearch, pathname, regionCode, forceSearchPage)
    dispatch(pushWithCruiseFilters(pageName, filters, queryParams))
  }, [globalSearch, dispatch, pathname, regionCode, queryParams, forceSearchPage])

  return <Pane ref={searchBarRef}>
    <CruiseSearchFiltersBarGrid
      as="form"
      onSubmit={onSubmit}
    >
      <CruiseSearchInputs
        onChange={onChange}
        dropdownAnchorRef={searchBarRef}
      />

      <SearchButton
        kind="primary"
        type="submit"
        size="large"
      >
        Search
      </SearchButton>
    </CruiseSearchFiltersBarGrid>
  </Pane>
}

export default memo(CruiseSearchFiltersBar)
