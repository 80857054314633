import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFileCheckAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11.5 20h-6a1.0002 1.0002 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v5a1.0001 1.0001 0 0 0 2 0V8.94a1.307 1.307 0 0 0-.06-.27v-.09a1.0702 1.0702 0 0 0-.19-.28l-6-6a1.071 1.071 0 0 0-.28-.19.29.29 0 0 0-.1 0 1.099 1.099 0 0 0-.31-.11H5.5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a1.0001 1.0001 0 0 0 0-2Zm1-14.59L15.09 8H13.5a1 1 0 0 1-1-1V5.41ZM7.5 14h6a1.0001 1.0001 0 0 0 0-2h-6a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929Zm4 2h-4a1.0002 1.0002 0 0 0-.7071 1.7071c.1875.1875.4419.2929.7071.2929h4a1.0001 1.0001 0 0 0 0-2Zm-4-6h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2Zm13.71 6.29a.9997.9997 0 0 0-1.42 0l-3.29 3.3-1.29-1.3a1.005 1.005 0 0 0-.71-.2941 1.0047 1.0047 0 0 0-.71.2941 1.0044 1.0044 0 0 0-.2177 1.0942c.0505.1219.1245.2326.2177.3258l2 2a.9997.9997 0 0 0 1.42 0l4-4a.9997.9997 0 0 0 0-1.42Z"/>
  </SvgIcon>
}

export default LineFileCheckAltIcon
