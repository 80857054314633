import PriceStackLoyaltyProminentPrice from 'components/Luxkit/PricePoints/Loyalty/PriceStackLoyaltyProminentPrice'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import React, { useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { isActiveLuxPlusMember, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import PriceStackPriceWithLoyalty from 'components/Luxkit/PricePoints/PriceStackPriceWithLoyalty'
import BasePriceRowPrice from 'components/Luxkit/PricePoints/BasePriceRowPrice'
import PriceRowLoyaltyPriceBox from 'components/Luxkit/PricePoints/Loyalty/PriceRowLoyaltyPriceBox'
import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'

type PriceProps = React.ComponentProps<typeof BasePriceRowPrice>
interface Props {
  size: 'S' | 'M' |'L';
  price: number;
  saleUnit: string;
  memberPrice?: number;
  testid?: string;
  signDisplay?: PriceProps['signDisplay'];
  horizontalAlign?: 'start' | 'center' | 'end';
  desktopHorizontalAlign?: 'start' | 'center' | 'end';
  orientation?: 'horizontal' | 'vertical';
  loyaltyConfig?: App.OfferLuxPlusConfig;
  isTour?: boolean;
  mode?: App.LuxPlusPriceStackMode;
}

function LuxPlusPriceStack(props: Props) {
  const {
    memberPrice = 0,
    price,
    saleUnit,
    size,
    testid,
    signDisplay,
    horizontalAlign = 'start',
    desktopHorizontalAlign,
    orientation = 'vertical',
    loyaltyConfig,
    isTour = false,
    mode,
  } = props

  const isMember = useAppSelector(isActiveLuxPlusMember)
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const luxPlusTourEnabled = useAppSelector(isLuxPlusToursEnabled)
  const displayedMode = useMemo(() => {
    if (!luxPlusEnabled) {
      return 'regular-only'
    } else if (mode) {
      return mode
    } else if (!!isTour && !luxPlusTourEnabled) {
      // This case will be ignored for all non-tours
      // For tours, this case will return below when toggle is off
      // if toggle is on, this case is ignored and regular logic (in the following cases) is used to display
      // the component appropriately
      return 'regular-only'
    } else if (!isMember && memberPrice > 0) {
      return 'regular-prominent'
    } else if (loyaltyConfig?.access === 'memberOnly') {
      return 'member-only'
    } else if (isMember && memberPrice > 0) {
      return 'member-prominent'
    } else {
      return 'regular-only'
    }
  }, [isMember, isTour, loyaltyConfig?.access, luxPlusEnabled, luxPlusTourEnabled, memberPrice, mode])
  switch (displayedMode) {
    case 'member-only':
      return <PriceRowLoyaltyPriceBox
        data-testid={testid}
        memberPrice={memberPrice || price}
        saleUnit={saleUnit}
        size={size}
        horizontalAlign={horizontalAlign}
      />
    case 'regular-only':
      return <PriceRowPrice
        data-testid={testid}
        price={price}
        saleUnit={saleUnit}
        size={size}
        signDisplay={signDisplay}
      />
    case 'member-prominent':
      return <PriceStackLoyaltyProminentPrice
        data-testid={testid}
        memberPrice={memberPrice}
        saleUnit={saleUnit}
        nonMemberPrice={price}
        size={size}
        memberPriceSignDisplay={signDisplay}
        nonMemberPriceSignDisplay={signDisplay}
        orientation={orientation}
        horizontalAlign={horizontalAlign}
        desktopHorizontalAlign={desktopHorizontalAlign}
      />
    case 'regular-prominent':
      return <PriceStackPriceWithLoyalty
        data-testid={testid}
        memberPrice={memberPrice}
        saleUnit={saleUnit}
        nonMemberPrice={price}
        size={size}
        signDisplay={signDisplay}
        horizontalAlign={horizontalAlign}
        desktopHorizontalAlign={desktopHorizontalAlign}
      />
    case 'member-checkout-total':
      return <PriceRowLoyaltyPriceBox
        data-testid={testid}
        memberPrice={memberPrice || price}
        saleUnit={saleUnit}
        size={size}
        horizontalAlign={horizontalAlign}
      />
  }
}

export default LuxPlusPriceStack
