import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'
import getInclusionMinStayText from 'lib/offer/getInclusionMinStayText'

interface Props {
  inclusion: App.TileInclusion | App.PackageInclusion;
}

function OfferTileInclusionsModalInclusionItem({ inclusion } : Props) {
  const inclusionLabel = 'text' in inclusion ? inclusion.text : inclusion.description
  const inclusionIconName = 'icon' in inclusion ? inclusion.icon : ''
  const InclusionIcon = getInclusionIcon(inclusionIconName ?? '')
  const inclusionMinStayText = getInclusionMinStayText(inclusion)

  return (
    <BodyTextBlock
      key={inclusionLabel}
      startIcon={<InclusionIcon data-testid={inclusionIconName || 'default_icon'} size="XS" />}
      variant="medium"
      weight={'isHighlighted' in inclusion && inclusion.isHighlighted ? 'bold' : 'normal'}
    >
      {inclusionLabel} {inclusionMinStayText}
    </BodyTextBlock>
  )
}

export default OfferTileInclusionsModalInclusionItem
