import React, { ComponentProps, MouseEventHandler, PropsWithChildren, RefObject, forwardRef, useCallback } from 'react'
import DropdownListBody from './DropdownListBody'
import DropdownListFooter from './DropdownListFooter'
import DropdownListHeader from './DropdownListHeader'
import DropdownListBase from './DropdownListBase'

type InheritedHeaderProps = Pick<ComponentProps<typeof DropdownListHeader>, 'title' | 'subtitle'>
type InheritedFooterProps = Pick<ComponentProps<typeof DropdownListFooter>, 'primaryActionProps' | 'isPrimaryActionHidden' | 'secondaryActionProps' | 'isSecondaryActionHidden'>
type InheritedBaseProps = Pick<ComponentProps<typeof DropdownListBase>, 'anchorRef' | 'boundaryRef' | 'triggerRef' | 'size' | 'placement' | 'disableFlip' | 'open' | 'portaled' | 'onClose' | 'id' | 'data-testid'>

interface Props extends PropsWithChildren, InheritedHeaderProps, InheritedFooterProps, InheritedBaseProps {
  size: 'S' | 'M' | 'fill-anchor'
  hasDismissButton?: boolean
  /**
   * @default S
   */
  headerSize?: ComponentProps<typeof DropdownListHeader>['size']
  headerExtension?: ComponentProps<typeof DropdownListHeader>['extensionSlot']
  /**
   * @default default
   */
  footerActionsLayout?: ComponentProps<typeof DropdownListFooter>['actionsLayout']
  /**
   * Reference to the body element wrapping the main content.
   */
  bodyRef?: RefObject<HTMLDivElement>
}

const DropdownList = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    size = 'M',
    hasDismissButton,
    headerSize = 'S',
    subtitle,
    title,
    headerExtension,
    primaryActionProps,
    isPrimaryActionHidden,
    secondaryActionProps,
    isSecondaryActionHidden,
    footerActionsLayout,
    bodyRef,
    anchorRef,
    boundaryRef,
    triggerRef,
    open,
    placement,
    disableFlip,
    portaled,
    onClose,
    'data-testid': dataTestId,
    id,
  } = props

  const handleDismissClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    onClose?.(event, 'dismiss-click')
  }, [onClose])

  const hasHeader = !!(title || subtitle || headerExtension || hasDismissButton)
  const hasPrimaryAction = !isPrimaryActionHidden && !!primaryActionProps?.children
  const hasSecondaryAction = !isSecondaryActionHidden && !!secondaryActionProps?.children
  const hasFooter = hasPrimaryAction || hasSecondaryAction

  return <DropdownListBase
    ref={ref}
    anchorRef={anchorRef}
    boundaryRef={boundaryRef}
    triggerRef={triggerRef}
    open={open}
    size={size}
    placement={placement}
    disableFlip={disableFlip}
    id={id}
    data-testid={dataTestId}
    onClose={onClose}
    portaled={portaled}
  >
    <DropdownListBody ref={bodyRef}>
      {children}
    </DropdownListBody>
    {hasHeader && <DropdownListHeader
      size={headerSize}
      subtitle={subtitle}
      title={title}
      extensionSlot={headerExtension}
      onDismissClick={hasDismissButton ? handleDismissClick : undefined}
    />}
    {hasFooter && <DropdownListFooter
      primaryActionProps={primaryActionProps}
      isPrimaryActionHidden={isPrimaryActionHidden}
      secondaryActionProps={secondaryActionProps}
      isSecondaryActionHidden={isSecondaryActionHidden}
      actionsLayout={footerActionsLayout}
    />}
  </DropdownListBase>
})

DropdownList.displayName = 'DropdownList'

export default DropdownList
