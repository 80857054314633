import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import { memoWithNestedProps } from 'lib/memoize/memoWithNestedProps'
import { rem } from 'polished'
import React, { ComponentProps, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'
import BaseDropdownFooter from '../BaseDropdownFooter'

const Container = styled(BaseDropdownFooter)`
  padding: ${rem(16)} ${rem(24)} ${rem(24)};
`

const StartSlot = styled.div`
  flex-grow: 1;

  &:empty {
    display: none;
  }
`

interface PrimaryActionProps extends Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'> {}
interface SecondaryActionProps extends Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'> {
  kind: 'secondary' | 'tertiary'
}

interface Props {
  primaryActionProps?: PrimaryActionProps
  isPrimaryActionHidden?: boolean
  secondaryActionProps?: SecondaryActionProps
  isSecondaryActionHidden?: boolean
  startSlot?: ReactNode
  actionsSlot?: ReactNode
}

const DropdownSheetFooter = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    primaryActionProps,
    isPrimaryActionHidden,
    secondaryActionProps,
    isSecondaryActionHidden,
    startSlot,
    actionsSlot,
  } = props

  const hasPrimaryAction = !isPrimaryActionHidden && !!primaryActionProps?.children
  const hasSecondaryAction = !isSecondaryActionHidden && !!secondaryActionProps?.children

  return <Container ref={ref}>
    <Group direction="horizontal" horizontalAlign="end" verticalAlign="center" gap={16} wrap="wrap">
      {!!startSlot && <StartSlot>{startSlot}</StartSlot>}
      <Group
        direction="horizontal"
        horizontalAlign="end"
        verticalAlign="end"
        gap={8}
        wrap="wrap"
        noDisplayWhenEmpty
      >
        {hasSecondaryAction && <TextButton
          {...secondaryActionProps}
          variant="default"
          size="medium"
        />}
        {hasPrimaryAction && <TextButton
          {...primaryActionProps}
          kind="primary"
          variant="default"
          size="medium"
        />}
        {actionsSlot}
      </Group>
    </Group>
  </Container>
})

export default memoWithNestedProps(DropdownSheetFooter, ['primaryActionProps', 'secondaryActionProps'])
