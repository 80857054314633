import React from 'react'
import FormatCurrency from 'components/Common/FormatCurrency'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import PromotionInclusionItem from 'components/Cruises/PromotionInclusions/PromotionInclusionItem'
import styled from 'styled-components'
import { rem } from 'polished'

const PromotionListWrapper = styled.div`
  padding: ${rem(4)} 0;
`

interface Props {
  promotionDetails?: App.CruisePromotionDetails | null
  evergreenInclusions?: Array<App.EvergreenInclusion>
  tileInclusions?: Array<App.TileInclusion>
}

function PromotionInclusionList({ promotionDetails, evergreenInclusions, tileInclusions } : Props) {
  const hasEvergreenInclusions = !!evergreenInclusions?.length

  return <PromotionListWrapper>
    <Group direction="vertical" gap={8}>
      {(promotionDetails?.sellingPoints || hasEvergreenInclusions) && <BodyText weight="semi-bold" variant="medium">
        Inclusions (select packages only)
      </BodyText>}

      <Group direction="vertical" gap={4}>
        {hasEvergreenInclusions && evergreenInclusions.map(({ inclusion }) => {
          return <PromotionInclusionItem
            key={inclusion}
            inclusion={inclusion}
            highlight
            weight={evergreenInclusions.length > 1 ? 'bold' : 'normal'}
            type="access"
          />
        })}

        {promotionDetails?.sellingPoints && !promotionDetails.onBoardCredit && (
          <PromotionInclusionItem
            inclusion={promotionDetails.sellingPoints[0]}
            highlight
            weight="bold"
            type="promotion"
          />
        )}

        {promotionDetails?.onBoardCredit && (
          <PromotionInclusionItem
            highlight={!hasEvergreenInclusions}
            weight={!hasEvergreenInclusions ? 'bold' : 'normal'}
            type="promotion"
          >
            Up to <FormatCurrency currency={promotionDetails.onBoardCredit.currencyCode} value={promotionDetails.onBoardCredit.amount} /> onboard credit available
          </PromotionInclusionItem>
        )}

        {promotionDetails?.sellingPoints && <>
          {promotionDetails.sellingPoints.map((point, index) => {
            const isHighlighted = index === 0 && !promotionDetails.onBoardCredit
            if (!isHighlighted) {
              return <PromotionInclusionItem key={point} inclusion={point} />
            }
          })}
        </>}

        {!!tileInclusions?.length && !hasEvergreenInclusions && tileInclusions.map((inclusion) => <PromotionInclusionItem
          key={inclusion.text}
          inclusion={inclusion.text}
        />)}
      </Group>
    </Group>
  </PromotionListWrapper>
}

export default PromotionInclusionList
