import { getCruiseDeposits } from 'checkout/lib/utils/cruises/booking'

/**
 * Compute cabin pricing data
 *
 * @remarks
 * Pricing data comes from 2 sources:
 *  - Cabin Pricing
 *  - Cabin Selection
 *
 *  Cabin Pricing takes priority
 */
export function computeCabinPricing(
  cabinSelectionData: Cruises.BookingCabinSelectionData,
  cabinPricingData: Cruises.BookingCabinPricingData,
): App.Checkout.ItemTotals {
  const { cabinSelection } = cabinSelectionData
  const { cabinPricing } = cabinPricingData

  if (cabinPricing) {
    const pricing = cabinPricing.pricing

    return {
      taxesAndFees: pricing.tax?.amount ?? 0,
      price: pricing.total.amount,
      memberPrice: 0,
    }
  }
  else if (cabinSelection) {
    const { pricing } = cabinSelection

    return {
      taxesAndFees: pricing.tax?.amount ?? 0,
      price: pricing.total.amount,
      memberPrice: 0,
    }
  }
  else {
    return { taxesAndFees: 0, price: 0, memberPrice: 0 }
  }
}

export function computeCabinDepositAmount(cabinPricingData: Cruises.BookingCabinPricingData) {
  const depositData = getCruiseDeposits(cabinPricingData)
  return depositData?.schedule?.firstPayment?.amount || 0
}
