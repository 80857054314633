import OfferListEventsContext, {
  OfferListEvents,
  OfferListEventsProvider,
} from 'components/OfferList/OfferListEventsContext'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import OfferCardMapper from './OfferCardMapper'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import useOffer from 'hooks/Offers/useOffer'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import CarouselCardLoadingSkeleton from 'components/OfferList/OfferCards/CarouselCardLoadingSkeleton'
import CarouselLockedCardLoadingSkeleton from './CarouselLockedCardLoadingSkeleton'
import ErrorBoundary from 'components/Common/ErrorBoundary'
import { useInView } from 'react-intersection-observer'
import { fadeIn } from 'components/utils/animations'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'
import useToggle from 'hooks/useToggle'
import {
  calculateHasBeenInView,
  calculateTrackingInView,
  handleSkipInView,
} from 'components/utils/OfferInView'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'

const Card = styled.div`
  display: grid;
  width: 75vw;
  height: 100%;
  flex-shrink: 0;

  // Sold out villas return null so need to be hidden
  &:empty {
    display: none;
  }

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  ${mediaQueryUp.tablet} {
    width: ${rem(320)};
  }

  ${mediaQueryUp.desktop} {
    &.size-medium {
      width: ${rem(512)};
    }
  }
`

const DefaultLoader = styled(CarouselCardLoadingSkeleton)`
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }
`

const LockedLoader = styled(CarouselLockedCardLoadingSkeleton)`
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }
`

const OfferContent = styled(OfferCardMapper)`
  animation: ${fadeIn} 0.2s;
`

interface Props {
  id: string;
  idx?: number;
  className?: string;
  lazyLoad?: boolean;
  additionalOfferUrlQuery?: string;
  /**
   * Controls the type of skeleton loader to be displayed
   *
   * @default default
   */
  tileStyle?: 'default' | 'locked'
  size?: 'small' | 'medium';
}

function OfferCard(props: Props) {
  const {
    id,
    idx,
    lazyLoad,
    additionalOfferUrlQuery,
    tileStyle = 'default',
    size = 'small',
  } = props
  const onEvent = useContext(OfferListEventsContext)
  const [skipInView, setSkipInView] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const [tileInViewRef, inView, entry] = useInView({
    rootMargin: '100px',
    threshold: [0, OFFER_TRACKING_IN_VIEW_THRESHOLD],
    skip: skipInView,
  })

  useEffect(() => {
    handleSkipInView(entry, skipInView, setSkipInView)
  }, [entry, skipInView, setSkipInView, inView])

  const hasBeenInView = useMemo(() => calculateHasBeenInView(entry), [entry])

  const trackingInView = useMemo(() => calculateTrackingInView(entry), [entry])

  const canFetchOffer = !lazyLoad || hasBeenInView

  const [offer, , offerError] = useOffer(id, {
    requireSummaryOnly: true,
    disabled: !canFetchOffer,
  })

  const [loader,,, hideLoader] = useToggle(!offer)

  useEffect(() => {
    if (offer && trackingInView && imageLoaded) {
      onEvent(OfferListEvents.impression, {
        offer,
        position: idx,
        key: EventDataKey.ProductImpression,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingInView, offer, imageLoaded])

  const onListEvent = useCallback((action: OfferListEvents, ...rest) => {
    if (action === OfferListEvents.offerReady) {
      setImageLoaded(true)
    }
    // Now that we've handled our case, propagate the event up to other listeners
    onEvent(action, ...rest)
  }, [onEvent])

  if (offerError) {
    return null
  }

  const pauseLoader = !hasBeenInView || !!offer

  return (
    <ErrorBoundary fallback={null}>
      <LoaderPlayStateContext.Provider value={pauseLoader ? 'paused' : 'running'}>
        <Card ref={tileInViewRef} className={`size-${size}`}>
          {loader && <>
            {tileStyle === 'default' && <DefaultLoader
              onTransitionEnd={hideLoader}
              className={!offer ? 'visible' : undefined}
              size={size}
            />}
            {tileStyle === 'locked' && <LockedLoader
              onTransitionEnd={hideLoader}
              className={!offer ? 'visible' : undefined}
            />}
          </>}
          {!!offer &&
            <OfferListEventsProvider onListEvent={onListEvent}>
              <OfferContent
                offer={offer}
                size={size}
                idx={idx}
                additionalOfferUrlParams={additionalOfferUrlQuery}
              />
            </OfferListEventsProvider>
          }
        </Card>
      </LoaderPlayStateContext.Provider>
    </ErrorBoundary>
  )
}

export default React.memo(OfferCard)
