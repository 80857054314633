import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import { SearchMenuStates } from 'components/Search/type'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import React, { ChangeEventHandler, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import BusinessTravellerSelectContent from './BusinessTravellerSelectContent'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'

interface MappedStateProps {
  travellerEmployees: Array<BusinessTraveller.EmployeeFromMeEndpoint>
}

interface Props {
  className?: string;
}

function BusinessTravellerSelectCarHireDesktop(props: Props & MappedStateProps) {
  const {
    className,
    travellerEmployees,
  } = props
  const { activeMenu } = useContext(GlobalSearchStateContext)
  const dispatch = useContext(GlobalSearchDispatchContext)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const setActiveMenu = useCallback((menu: SearchMenuStates) => {
    dispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu })
  }, [dispatch])

  const handleTravellerSelect = useCallback(() => {
    setActiveMenu(SearchMenuStates.Closed)
  }, [setActiveMenu])

  const handleSelectTravellerClose = useCallback(() => {
    setActiveMenu(SearchMenuStates.Closed)
  }, [setActiveMenu])

  const handleSelectTravellerClick = useCallback(() => {
    const menuToSet = activeMenu !== SearchMenuStates.CarHireBusinessTravellerSelect ?
      SearchMenuStates.CarHireBusinessTravellerSelect :
      SearchMenuStates.Closed
    setActiveMenu(menuToSet)
  }, [activeMenu, setActiveMenu])

  const fieldValue = useMemo(() => {
    if (travellerEmployees?.length) {
      const firstTraveller = travellerEmployees[0]
      return `${firstTraveller.firstName} ${firstTraveller.lastName}`
    }

    return undefined
  }, [travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  return (
    <SearchFormFieldGroup className={className}>
      <SearchFormField
        label="Travellers"
        required
        requiredErrorMessage="Please select traveller"
        value={fieldValue}
        displayValue={fieldValue}
        placeholder="Select Traveller"
        onClick={handleSelectTravellerClick}
        ref={localInputRef}
      />
      <DropdownList
        size="M"
        open={activeMenu === SearchMenuStates.CarHireBusinessTravellerSelect}
        triggerRef={localInputRef}
        anchorRef={localInputRef}
        disableFlip
        onClose={handleSelectTravellerClose}
        headerExtension={<TextInputOnly
          value={searchTerm}
          type="text"
          placeholder="Search traveller name"
          onChange={handleSearchTermChange}
          endIcon={<LineSearchIcon />}
          noValidationSpacing
        />}
      >
        <BusinessTravellerSelectContent
          searchTerm={searchTerm}
          onTravellerSelect={handleTravellerSelect}
        />
      </DropdownList>
    </SearchFormFieldGroup>
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectCarHireDesktop)
