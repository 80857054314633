import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import TourDiscountLabel from 'components/Tours/TourDiscountLabel'
import { getUrgencyTagVariant } from 'lib/tours/tourUtils'
import React, { ReactNode, useMemo } from 'react'
import TourV2LuxPlusUrgencyTag from './TourV2LuxPlusUrgencyTag'

const KNOWN_TAGS_POSITIONS = new Map<string, { position: 'start' | 'end', tagLabel: ReactNode }>([
  ['agenthub_exclusive', { position: 'start', tagLabel: <PromotionLabel key="agentHubExclusive" variant="engagement">Agent exclusive</PromotionLabel> }],
  ['bestseller', { position: 'start', tagLabel: <PromotionLabel key="bestseller" variant="default" icon={<LineAwardIcon />} data-testid="UrgencyLabel">Bestseller</PromotionLabel> }],
  ['sale', { position: 'end', tagLabel: <TourDiscountLabel key="sale" /> }],
  ['departuresale', { position: 'end', tagLabel: <TourDiscountLabel key="departuresale" saleOnDepartures /> }],
])

interface Props {
  className?: string;
  tags: Array<string>;
  cheapestPurchasableOption?: Tours.TourV2OfferPurchasableOption;
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
}

function TourV2UrgencyTags(props: Props) {
  const {
    className,
    tags,
    cheapestPurchasableOption,
    offer,
  } = props

  const tagLabels = useMemo(() => {
    const startTagLabels: Array<ReactNode> = []
    const middleTagLabels: Array<ReactNode> = []
    const endTagLabels: Array<ReactNode> = []

    for (const tag of tags) {
      if (tag === 'luxplus') {
        startTagLabels.push(<TourV2LuxPlusUrgencyTag key="luxplus" offer={offer} cheapestPurchasableOption={cheapestPurchasableOption} />)
        continue
      }

      const knownTag = KNOWN_TAGS_POSITIONS.get(tag.toLocaleLowerCase())
      switch (knownTag?.position) {
        case 'start':
          startTagLabels.push(knownTag.tagLabel)
          break
        case 'end':
          endTagLabels.push(knownTag.tagLabel)
          break
        default:
          middleTagLabels.push(<UrgencyLabel key={tag} variant={getUrgencyTagVariant(tag)}>{tag}</UrgencyLabel>)
      }
    }

    return [...startTagLabels, ...middleTagLabels, ...endTagLabels]
  }, [cheapestPurchasableOption, offer, tags])

  if (!tagLabels.length) {
    return null
  }

  return <LabelGroup className={className}>
    {tagLabels}
  </LabelGroup>
}

export default TourV2UrgencyTags
