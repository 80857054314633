import moment from 'moment'

import { DEFAULT_TOUR_V2_OFFER_MAX_CHILD_AGE } from 'constants/tours'
import { maxFlightChildAge } from 'constants/flight'
import { OFFER_TYPE_BED_BANK, OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2, GATED_OFFERS } from 'constants/offer'
import config from 'constants/config'

import { groupBy, last, take, min } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import memoize from 'lib/memoize/memoize'

import { isBundleOffer, isCruiseOffer, isLEHotel, isLEOffer, isTourV1Offer, isTourV2OfferSummary } from 'lib/offer/offerTypes'
import calculateDistance from 'lib/geo/distanceBetweenPoints'

export const getDurationName = memoize((offer: App.Offer) => offer.durationLabel.match(/Night|Day/)?.[0] ?? '')

/**
 * Calculates the maximum age a child can be for the given bedbank offer
 * @param offer The offer
 * @returns The maximum age for the child
 */
export function getBedbankChildMaxAge(offer?: App.BedbankOffer) {
  if (!offer) {
    return 17
  }

  const allAdultCats = offer.packages.flatMap(p =>
    (p.capacities?.ageCategories ?? []).filter(category => category.name === 'Adult' && !!category.minimumAge),
  )
  const allMinAges = allAdultCats.map(cat => cat.minimumAge)

  // Maximum child age is the highest mininmum age of an adult - 1
  // default child age in system is 17
  return (Math.max(...allMinAges, 0) || 18) - 1
}

/**
 * Calculates the maximum age a child can be for the given bedbank offer
 * @param offer The offer
 * @returns The maximum age for the child
 */
export function getBedbankAndFlightsChildMaxAge(offer?: App.BedbankOffer) {
  let childrenAgeThreshold = getBedbankChildMaxAge(offer)
  if (offer?.flights?.flightsEnabled) {
    childrenAgeThreshold = Math.max(childrenAgeThreshold, maxFlightChildAge)
  }

  return childrenAgeThreshold
}

/**
 * Calculates the maximum age a child can be for the given ttc tourv2 offer
 * @param offer The offer
 * @returns The maximum age for the child
 */
export function getTourV2OfferMaxChildAge(offer?: Tours.TourV2Offer) {
  if (!offer) return DEFAULT_TOUR_V2_OFFER_MAX_CHILD_AGE

  const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer)
  return cheapestPurchasableOption ? offer.variations[cheapestPurchasableOption.fkVariationId].maxChildPriceAge : DEFAULT_TOUR_V2_OFFER_MAX_CHILD_AGE
}

/**
 * Calculates the maximum age a child can be for the given ttc tourv2 offer
 * @param offer The offer
 * @returns The maximum age for the child
 */
export function getTourV2OfferMinAge(offer?: Tours.TourV2Offer) {
  if (!offer) return DEFAULT_TOUR_V2_OFFER_MAX_CHILD_AGE

  const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer)
  const minAge = cheapestPurchasableOption ? offer.variations[cheapestPurchasableOption.fkVariationId].minChildPriceAge : undefined
  return minAge ? minAge - 1 : -1
}

/**
 * Returns a set of all unique capacities combinations for a bedbank offer
 * @param offer The bedbank offer
 * @returns All unique capacities
 */
export function getCapacitiesForBedbankOffer(offer: App.BedbankOffer): Array<App.PackageRoomCapacity> {
  const allCapacities = new Map<string, App.PackageRoomCapacity>(
    (offer.packages.map(p => p.capacities.combinations).flat())
      .map(capacity => [
        `${capacity.adults}-${capacity.children}-${capacity.infants}`,
        capacity,
      ]))

  if (allCapacities.size == 0) {
    allCapacities.set('14-13-13', { adults: 14, children: 13, infants: 13 })
  }

  return Array.from(allCapacities.values())
}

/**
 * Retrieves a unique key for a single package option
 * Is build from a combination of package id, duration and room rate id
 * @param packageId The unique id of the package
 * @param duration The duration this option is for
 * @param roomRateId The room rate for this option
 * @returns The unique key
 */
export function getPackageUniqueKey(
  packageId: string,
  duration: number,
  roomRateId: string,
) {
  return `P:${packageId}D:${duration}R:${roomRateId}` as const
}

const seaWorldOffers = new Set(['0062y00000GvFpyAAF', '0062y000009CpurAAC'])
/**
 * Returns the maximum age a child can be for an offer assuming that
 * the offer will have flights available.
 * @param offer The offer to get the max age for
 * @returns The maximum age
 */
export function getOfferAndFlightsMaxChildAge(offer: App.Offer) {
  let maxAge = offer.property?.maxChildAge
  if (offer.offerFlightsEnabled) {
    // sea world considers children 3 and over as adults, but this conflicts with flights
    // But product want to show childrens age here, so use dodgy work around
    if (seaWorldOffers.has(offer.id)) return maxAge
    // flights must have a minimum child age of 11 or else we risk accidently
    // booking adult flights for children as we have no reverse map of adults -> children
    maxAge = maxAge ? Math.max(maxAge, maxFlightChildAge) : maxFlightChildAge
  }

  return maxAge
}

/**
 * Returns the maximum age a child can be for an offer assuming that
 * the offer will have flights available.
 * @param offer The offer to get the max age for
 * @returns The maximum age
 */
export function getBundleAndSaveMaxChildAge(offer: App.BundleOffer) {
  return min(Object.values(offer.bundledOffers), o => o.property?.maxChildAge)?.property?.maxChildAge
}

export function getOfferMaxInfantAge(offer: App.Offer | App.BedbankOffer | Tours.TourV2Offer): number {
  return isLEOffer(offer) ? (offer.property?.maxInfantAge ?? -1) : -1
}

export function getBundleAndSaveMaxInfantAge(offer: App.BundleOffer) {
  return min(Object.values(offer.bundledOffers), o => o.property?.maxInfantAge)?.property?.maxInfantAge ?? -1
}

export function getDurationLabel(durations: Array<number>) {
  if (durations.length > 4) {
    return `${Math.min(...durations)} to ${Math.max(...durations)} nights`
  }

  if (durations.length === 1) {
    return pluralizeToString('night', durations[0])
  }

  if (durations.length > 1) {
    return `${take(durations, durations.length - 1).join(', ')} or ${last(durations)} nights`
  }

  return ''
}

export function getVisibilityScheduleForRegion(offer: App.Offer | App.OfferSummary, region: string) {
  const scheduleMap = offer.visibilitySchedules
  return (region in scheduleMap) ? scheduleMap[region] : scheduleMap.world
}

const basicUuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export function detectOfferTypeFromId(offerId: string = ''): App.OfferType {
  if (basicUuidRegex.test(offerId)) {
    // bedbank offer use a uuid for their ID
    return 'bedbank_hotel'
  } else if (offerId.startsWith('tour')) {
    // tour v2s prefix their idea with 'tour'
    return 'direct_tour'
  } else if (offerId.startsWith('cruise')) {
    return 'cruise'
  } else {
    // we have no other information to detect any more detailed type, fallback to hotel
    return 'hotel'
  }
}

export function isIdHotelType(offerId: string) {
  const offerType = detectOfferTypeFromId(offerId)
  return offerType === 'hotel' || offerType === 'bedbank_hotel'
}

export function calcDistanceBetweenOffers(offer1: App.Offer | App.BedbankOffer, offer2: App.Offer | App.BedbankOffer) {
  if (offer1.property && offer2.property) {
    const point1 = [offer1.property.latitude, offer1.property.longitude]
    const point2 = [offer2.property.latitude, offer2.property.longitude]
    return calculateDistance(point1[0], point1[1], point2[0], point2[1])
  }
  return null
}

export function hasOfferExpired(offer: App.Offer | App.OfferSummary): boolean {
  if (!offer) return false
  return !scheduleIsCurrent(offer.onlinePurchaseSchedule)
}

export function hasPackageWithMatchingDuration(offer: App.Offer, checkIn: moment.Moment, checkOut: moment.Moment): boolean {
  if (!offer || !checkIn || !checkOut) return false
  const duration = checkOut.diff(checkIn, 'days')
  const durationPackage = offer.packages.find((pkg) => (
    pkg.duration === duration
  ))
  return durationPackage !== undefined
}

export function getOfferPropertyName(offer: App.Offer | App.OfferSummary) {
  if (offer.parentType === OFFER_TYPE_TOUR) {
    return offer.lowestPricePackage?.tour?.name
  }
  return offer?.property?.name
}

export function getOfferName(offer: App.AnyOffer) {
  if (offer.type === OFFER_TYPE_BED_BANK || offer.parentType === OFFER_TYPE_TOUR_V2 || offer.type === OFFER_TYPE_CRUISE) {
    return offer.name
  }
  return getOfferPropertyName(offer)
}

export function checkGatedOffer(offer: App.Offer | App.OfferSummary) {
  // bandaid - subject to change, may change to be based on specific schedule type that is "active"
  return GATED_OFFERS.includes(offer.id)
}

export function isLPCTacticalEnabled() {
  return config.LPC_TACTICAL
}

export function getOfferId(offer: App.AnyOffer): string {
  if (isTourV2OfferSummary(offer)) {
    return offer.summaryId
  } else if (isCruiseOffer(offer)) {
    return `cruise:${offer.id}`
  } else {
    return offer.id
  }
}

export function getOfferIds(offers: Array<App.AnyOffer>): Array<string> {
  return offers.map(getOfferId)
}

export function isUltraLuxTour(offer: Tours.TourV2Offer | Tours.TourV2OfferSummary): boolean {
  return offer.productType === 'ultra_lux_tour'
}

export function isUltraLuxOffer(offer: App.AnyOffer): boolean {
  if (offer.productType === 'ultra_lux_tour') return true
  if (offer.productType === 'ultralux_hotel') return true
  if (isLEHotel(offer) || isBundleOffer(offer) || isTourV1Offer(offer)) {
    return !!offer.holidayTypes?.includes('Ultra Lux')
  }
  return false
}

export function isSalesforceTourV2(offer: Tours.TourV2Offer): boolean | undefined {
  if (!offer) { return }
  return offer.productType === 'direct_tour' || offer.productType === 'partner_tour' || offer.productType === 'ultra_lux_tour'
}

export function groupByOfferId(packages: Array<App.Package>): { [offerId: string]: Array<App.Package> } {
  return Object.fromEntries(groupBy(packages, (p) => p.offerId).entries())
}

export function isBundleAndSaveV2Enabled() {
  return config.BUNDLE_AND_SAVE_V2_ENABLED
}
