import React from 'react'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

interface Props {
  isFlash?: boolean;
  roomOccupancy?: string;
  taxDetails?: {
    taxesAndFeesAmount: number;
    taxesPayableAtProperty: boolean;
  },
  discountPills?: {
    totalPrice: number;
    percentage: number;
  }
  priceDetails: {
    saleUnit: string;
    totalPrice: number;
  };
  className?: string;
  offerId: string;
  variant?: 'default' | 'condensed';
}

function CruisePriceDetails(props: Props) {
  const { variant, priceDetails, discountPills, isFlash, taxDetails, roomOccupancy, offerId } = props

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <VerticalSpacer gap={8}>
        <div>
          <PriceRowPriceCaption>From</PriceRowPriceCaption>
          <PriceRowPrice
            size="L"
            price={priceDetails.totalPrice}
            saleUnit={priceDetails.saleUnit}
          />
          {!isFlash && <PriceRowTaxesAndFees />}
          {!!discountPills?.totalPrice && !!discountPills.percentage && <PriceRowValueDiscountWithCaption
            size="M"
            originalValue={discountPills.totalPrice}
            discountPercentage={discountPills.percentage}
          />}
          {!isFlash && <PriceRowCaption>Inside cabin</PriceRowCaption>}
          {isFlash && !!roomOccupancy && <PriceRowCaption>{roomOccupancy}</PriceRowCaption>}
          {isFlash && !!taxDetails && variant === 'default' && <PriceRowTaxesAndFees />}
        </div>
        <PriceRowAgentHubCommission
          size="L"
          productType={isFlash ? 'cruise-flash' : 'cruise'}
          offerId={offerId}
        />
      </VerticalSpacer>
      {variant === 'default' && <CSSBreakpoint min="desktop">
        <TextButton kind="primary" nonInteractable>
          View
        </TextButton>
      </CSSBreakpoint>}
      {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
        View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
      </TextButton> }
    </Group>
  )
}

export default CruisePriceDetails
