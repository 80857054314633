import React, { useState, useEffect } from 'react'
import SessionTimeoutModal from './SessionTimeoutModal'
import * as isomorphicSessionStorage from 'lib/storage/isomorphicSessionStorage'
import moment from 'moment'

export function resetCruiseTimer() {
  const time = getCruiseVendorBookingTimer()
  // the vendor sends the length of time as seconds thats why we have this "time/60"
  const expirationTimestamp = moment().add(time, 'minutes').toDate().getTime()

  isomorphicSessionStorage.set('cruise-timer-expiration-timestamp', expirationTimestamp)
}

export function expireCruiseTimer() {
  isomorphicSessionStorage.set('cruise-timer-expiration-timestamp', 0)
}

export function setCruiseVendorBookingTimer(time:number) {
  isomorphicSessionStorage.set('cruise-vendor-booking-timer', time)
}

export function getCruiseVendorBookingTimer() {
  return Number(isomorphicSessionStorage.get('cruise-vendor-booking-timer')) / 60
}

export function getCruiseTimerExpirationTimestamp() {
  return Number(isomorphicSessionStorage.get('cruise-timer-expiration-timestamp'))
}

interface Props {
  backBtnTitle?: string;
  onReload?: () => void;
  onGoBack?: () => void;
  startCruiseTimer: boolean
}

function hasCruiseSessionExpired() {
  return !!getCruiseTimerExpirationTimestamp() && moment(getCruiseTimerExpirationTimestamp()).isBefore()
}

function CruiseSessionTimer(props: Props) {
  const [expired, setExpired] = useState<boolean>()

  function checkIfCruiseSessionHasExpired() {
    setExpired(hasCruiseSessionExpired())
  }

  useEffect(() => {
    if (props.startCruiseTimer) {
      const interval = setInterval(checkIfCruiseSessionHasExpired, 1000)
      return () => clearInterval(interval)
    }
    // This will update the session storage every second
  }, [props.startCruiseTimer])

  return (
    <SessionTimeoutModal
      {...props}
      isOpen={expired}
    />
  )
}

export default CruiseSessionTimer
