import { CreateCruiseRequestBody } from '../common'

import { OFFER_TYPE_CRUISE } from 'constants/offer'
import { Place } from 'tripPlanner/api/types'
import { GeoPlace } from 'tripPlanner/types/common'
import { SavedCruiseData } from 'tripPlanner/types/tripItem'

interface Params {
  offer: App.CruiseOffer
  currentRegionCode: string
  currentCurrency: string
  templateId?: string
  templateItemId?: string
}

const convertPortToGeoPlace = (port: App.CruisePort): GeoPlace => ({
  name: port.name,
  type: 'GEO',
  point: {
    lat: Number(port.latitude),
    lng: Number(port.longitude),
  },
})

export const getStartPlace = (
  departurePortName: string,
  ports: Array<App.CruisePort>,
): Place => {
  if (ports.length > 0) {
    const port = ports[0]
    return convertPortToGeoPlace(port)
  } else {
    return {
      name: departurePortName,
      type: 'CUSTOM',
    }
  }
}

export const getEndPlace = (
  returnPortName: string,
  ports: Array<App.CruisePort>,
): Place => {
  if (ports.length > 0) {
    const port = ports[ports.length - 1]
    return convertPortToGeoPlace(port)
  } else {
    return {
      name: returnPortName,
      type: 'CUSTOM',
    }
  }
}

export const getPorts = (offer: App.CruiseOffer): Array<App.CruisePort> => {
  return (offer.itinerary ?? [])
    .map((day) => day?.port)
    .filter((port): port is App.CruisePort => !!port)
}

export function createCruiseItemPayload({
  offer,
  currentRegionCode,
  currentCurrency,
  templateId,
  templateItemId,
}: Params): CreateCruiseRequestBody {
  const ports = getPorts(offer)

  const savedItemData: SavedCruiseData = {
    offerId: offer.id,
    offerType: OFFER_TYPE_CRUISE,
    regionCode: currentRegionCode,
    currencyCode: currentCurrency,
    price: offer.mainDepartureDetails.lowestOverallPriceDetails.total,
    cabinCategory:
      offer.mainDepartureDetails.lowestOverallPriceDetails.cabinCategory,
    imageId: offer.images?.[0]?.id,
  }

  const payload: CreateCruiseRequestBody = {
    type: 'CRUISE',
    isBooked: false,
    name: offer.name,
    sourceType: 'LE_Cruise',
    code: offer.id,
    startPlace: getStartPlace(offer.departurePort, ports),
    endPlace: getEndPlace(offer.returnPort, ports),
    savedItemData,
    templateId,
    templateItemId,
  }

  return payload
}
