import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React, { useMemo } from 'react'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import BundleAndSaveLocation from './BundleAndSaveLocation'
import BundleAndSavePriceDetails from './BundleAndSavePriceDetails'
import OfferTileLoggedInButtons from 'components/OfferList/OfferListTiles/Support/OfferTileLoggedInButtons'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'

interface Props {
  offer: App.BundleOffer | App.BundleOfferSummary;
  onClick: () => void
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
}

function BundleAndSaveOfferCard(props: Props) {
  const {
    offer,
    onClick, className,
    offerUrl,
    size,
  } = props
  const bundledOffers = useMemo(() => Object.values(offer.bundledOffers), [offer.bundledOffers])

  const offerPropertiesTitle = useMemo(
    () => offer.location || bundledOffers.map(offer => offer.property.name).join(' + ')
    , [offer.location, bundledOffers])

  const pricing = (
    <>
      {!offer.walledGarden && offer.lowestPriceBundlePackage && <BundleAndSavePriceDetails
        offer={offer}
        pkg={offer.lowestPriceBundlePackage}
        variant="default"
      />}
      {offer.walledGarden && offer.lowestPriceBundlePackage && (
        <OfferTileLoggedInButtons
          signUpText="Sign up for free"
          discountPercentage={offer.lowestPriceBundlePackage.discountPercent ? Math.round(offer.lowestPriceBundlePackage.discountPercent) : undefined}
          align="none"
          fullWidth
        >
          <BundleAndSavePriceDetails
            variant="default"
            offer={offer}
            pkg={offer.lowestPriceBundlePackage}
          />
        </OfferTileLoggedInButtons>
      )}
    </>
  )

  const isUltraLux = isUltraLuxOffer(offer)

  return (
    <ProductPaletteProvider palette={isUltraLux ? 'ultralux' : 'default'}>
      {size === 'medium' && <CarouselCardMedium
        className={className}
        title={offer.name}
        image={offer.image}
        providerName={offerPropertiesTitle}
        location={<BundleAndSaveLocation bundledOffers={bundledOffers} />}
        priceDetails={pricing}
        to={offerUrl}
        urgencyLabels={<LabelGroup>
          {isUltraLux && <ProductTypeLabel productType={offer.productType} />}
          <OfferLabels offer={offer} />
        </LabelGroup>}
        onClick={onClick}
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        description={offer.name}
        image={offer.image}
        location={<BundleAndSaveLocation bundledOffers={bundledOffers} />}
        title={offerPropertiesTitle}
        priceDetails={pricing}
        to={offerUrl}
        productType={offer.productType}
        productTypeKind="default"
        urgencyLabels={<OfferLabels offer={offer} />}
        onClick={onClick}
      />}
    </ProductPaletteProvider>
  )
}

export default BundleAndSaveOfferCard
