import { useResponsive } from 'hooks/useResponsive'

export function isAndroid(osName: string) {
  return osName.includes('Android')
}

export function isApple(osName: string) {
  return osName === 'iOS' || osName === 'Mac OS'
}

export function isIOS(osName: string) {
  return osName === 'iOS'
}

export function isMobile(osName: string) {
  return isAndroid(osName) || isIOS(osName)
}

export function useIsMobileScreen() {
  const [match] = useResponsive()
  return !!(match.mobile)
}

export function useIsLargeDesktopScreen() {
  const [match] = useResponsive()
  return !!(match.largeDesktop)
}

export function useIsDesktopScreen() {
  const [match] = useResponsive()
  return match.desktop || match.largeDesktop
}

export function useIsTabletAndSmallerScreen() {
  const [match] = useResponsive()
  return !!(match.mobile || match.tablet)
}

/**
 * this selector will also respect in-app navigation via webviews
 */
export function isIOSAppUserAgent(userAgentBrowserName: string) {
  return userAgentBrowserName.includes('mobileapp-ios')
}

/**
 * This selector will also respect in-app navigation via webviews
 */
export function isAndroidAppUserAgent(userAgentBrowserName: string) {
  return userAgentBrowserName.includes('mobileapp-android')
}
