import React from 'react'

export enum AnalyticsPage {
  app = 'app',
  offerPage = 'offer_page',
  tripPlanner = 'trip_planner',
  homepage = 'homepage',
  homepageOld = 'homepage_old',
  checkout = 'checkout',
  checkoutOld = 'checkout_old',
  search = 'search',
  verticalPage = 'vertical_page',
  orderPage = 'order_page',
  confirmationPage = 'confirmation_page',
  giftCards = 'gift_card_page',
  account = 'account',
}

/**
 * This context is to be used to convey the current "page" (the "category" label) analytics events
 * are being run from.
 *
 * e.g. offer_page, homepage, trip_planner etc
 */
const AnalyticsPageContext = React.createContext<AnalyticsPage>(AnalyticsPage.app)

export default AnalyticsPageContext
