import React, { useCallback, useContext, useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import AspectRatio from 'components/utils/AspectRatio'
import { connect } from 'react-redux'
import { ImageParams } from 'components/Common/ResponsiveImage'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import HeroTourOfferDetails from './HeroTourOfferDetails'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import HeroOfferTileWrapper from './HeroOfferTileWrapper'
import { useAppSelector } from 'hooks/reduxHooks'
import { isMobile } from 'lib/web/deviceUtils'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import { isVelocityEarnEnabled } from 'lib/payment/paymentUtils'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface MappedProps {
  isVelocityEnabled: boolean;
}

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
}

const imageParams: ImageParams = {
  mobileAspectRatio: '3:2',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '19:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
  quality: 'good',
}

function HeroTourOfferTile(props: Props & MappedProps) {
  const {
    offer,
    eagerLoadFirstImage,
    isVelocityEnabled,
  } = props

  const { cheapestVariation } = useCheapestOfTourV2Offer(offer)

  const isMobileDevice = useAppSelector(state => isMobile(state.config.userAgent.osName))
  // Swiping through the offer tiles is tough when the image carousel can also swipe. So turn off the image carousel for mobile devices
  const images = useMemo(() => {
    if (!cheapestVariation) return []

    return isMobileDevice ? [cheapestVariation.images[0]] : cheapestVariation.images
  }, [isMobileDevice, cheapestVariation])
  const palette = isUltraLuxOffer(offer) ? 'ultralux' : 'default'

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    onEvent(OfferListEvents.offerReady)
  }, [onEvent])

  return (
    <ProductPaletteProvider palette={palette}>
      <HeroOfferTileWrapper>
        <AspectRatio ratio="3:2" tabletRatio="16:9" desktopRatio="19:9" fillHeight>
          <ImageCarousel
            images={images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            topLeft={isVelocityEnabled && <PartnershipLabel canEarn={isVelocityEarnEnabled(offer)} canUse />}
            topRight={<TripGuard>
              <CSSBreakpoint max="tablet">
                <BookmarkButton offer={offer} />
              </CSSBreakpoint>
            </TripGuard>}
            onImageLoad={handleImageLoaded}
            />
        </AspectRatio>
        <HeroTourOfferDetails offer={offer} />
      </HeroOfferTileWrapper>
    </ProductPaletteProvider>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  isVelocityEnabled: state.geo.partnership?.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE,
})

export default connect(mapStateToProps)(HeroTourOfferTile)
