import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import BedbankOfferCardPriceDetails from './BedbankOfferCardPriceDetails'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary | App.BedbankOfferWithRate
  onClick: () => void
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
}

function BedbankOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props
  const rating = offer.property.rating
  const reviewsTotal = offer.property.reviewsTotal
  const ratingSubtitle = offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined

  const showReviews = showUserReviewsRating(offer.property.rating, offer.property.reviewsTotal)
  const reviews = useMemo(() => ({
    showReviews,
    rating,
    reviewsTotal,
    ratingSubtitle,
  }), [rating, ratingSubtitle, reviewsTotal, showReviews])

  const location = [
    offer.property.address.city,
    offer.property.address.countryName,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <ProductPaletteProvider palette="default">
      {size === 'medium' && <CarouselCardMedium
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        image={offer.image}
        location={location}
        title={offer.name}
        providerName={offer.property.name}
        priceDetails={<BedbankOfferCardPriceDetails variant="condensed" offer={offer} />}
        to={offerUrl}
        reviews={reviews}
        onClick={onClick}
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        description={offer.amenities}
        descriptionLines={2}
        image={offer.image}
        location={location}
        title={offer.property.name}
        priceDetails={<BedbankOfferCardPriceDetails offer={offer} />}
        to={offerUrl}
        productType={offer.productType}
        reviews={reviews}
        onClick={onClick}
      />}
    </ProductPaletteProvider>
  )
}

export default BedbankOfferCard
