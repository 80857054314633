import React, { useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { fetchHotelTrendingDestinations } from 'actions/DestinationAlertsActions'
import { addGTMEvent } from 'api/googleTagManager'
import { searchDestinationFormSubmit, searchFormSubmit, trendingDestinationClick } from 'analytics/eventDefinitions'
import { encodeSearchParams } from 'lib/search/searchUtils'
import { getHotelTrendingListKey } from 'lib/hotels/hotelTrendingListKey'
import { HOTEL_TRENDING_DESTINATION_LIMIT } from 'constants/search'
import PopularButtonList, { Option as PopularButtonListOption } from 'components/Search/SearchForm/PopularButtonList'
import GlobalSearchQuickFiltersTitle from '../GlobalSearch/QuickFilters/GlobalSearchQuickFiltersTitle'
import GlobalSearchQuickFilterButtonsContainer from '../GlobalSearch/QuickFilters/GlobalSearchQuickFilterButtonsContainer'
import GlobalSearchQuickFiltersContainer from '../GlobalSearch/QuickFilters/GlobalSearchQuickFiltersContainer'
import { take } from 'lib/array/arrayUtils'
import * as Analytics from 'analytics/analytics'
import config from 'constants/config'
import { useAppDispatch } from 'hooks/reduxHooks'

interface MappedProps {
  hotelTrendingDestinations: App.DestinationState['hotelTrendingDestinations'];
  fetchingHotelTrendingDestinations: App.DestinationState['fetchingHotelTrendingDestinations'];
  search: string;
}

interface Props {
  onClick?: () => void;
  filters?: App.OfferListFilters;
  titleWeight?: 'bold' | 'normal'
  titleColour?: 'neutral-eight' | 'neutral-two'
  className?: string;
  noTitle?: boolean;
}

function logEvents() {
  addGTMEvent(searchFormSubmit())
  addGTMEvent(searchDestinationFormSubmit())
  addGTMEvent(trendingDestinationClick())
}

function SearchTrendingDestinations({
  fetchingHotelTrendingDestinations,
  hotelTrendingDestinations,
  search,
  onClick,
  filters,
  titleColour,
  titleWeight,
  className,
  noTitle = false,
}: Props & MappedProps) {
  const dispatch = useAppDispatch()
  const getQueryParams = useCallback((optionValue: string, optionText: string): string => {
    const searchItem: App.SearchItem = {
      searchType: 'destination',
      value: optionValue,
      format: {
        mainText: optionText,
      },
    }
    const queryParams = encodeSearchParams({ urlSearch: search, searchItem, rooms: [config.search.defaultOccupants], filters, isFlexibleWithDate: true }).toString()

    return queryParams
  }, [filters, search])

  const hotelTrendingDestinationSortBy = 'recommended'

  const hotelTrendingDestinationsKey = getHotelTrendingListKey(
    HOTEL_TRENDING_DESTINATION_LIMIT,
    hotelTrendingDestinationSortBy,
    [],
    0,
  )

  const onClickHandler = useCallback((option: PopularButtonListOption) => {
    Analytics.trackClientEvent({
      subject: option.text,
      action: 'clicked',
      category: 'old-homepage-trending-destinations',
      type: 'interaction',
    })
    onClick?.()
    logEvents()
  }, [onClick])

  const onImpressionHandler = useCallback((option: PopularButtonListOption) => {
    Analytics.trackClientEvent({
      subject: option.text,
      action: 'impression',
      category: 'old-homepage-trending-destinations',
      type: 'nonInteraction',
    })
  }, [])

  useEffect(() => {
    dispatch(fetchHotelTrendingDestinations(
      HOTEL_TRENDING_DESTINATION_LIMIT,
      hotelTrendingDestinationSortBy,
      [],
      0,
      hotelTrendingDestinationsKey,
    ))
  }, [dispatch, hotelTrendingDestinationSortBy, hotelTrendingDestinationsKey])

  const fetchingTrendingDestinations = fetchingHotelTrendingDestinations[hotelTrendingDestinationsKey] ?? false

  const trendingDestinations = useMemo(() => hotelTrendingDestinations[hotelTrendingDestinationsKey] ?? [], [hotelTrendingDestinationsKey, hotelTrendingDestinations])

  const options = useMemo(() => {
    if (trendingDestinations.length > 0) {
      return trendingDestinations.map(trendingDestination => {
        const queryParams = getQueryParams(trendingDestination.placeId, trendingDestination.primaryText)
        const url = `/search?${queryParams}`

        return {
          value: trendingDestination.placeId,
          text: trendingDestination.primaryText,
          url,
        }
      })
    }
    return []
  }, [trendingDestinations, getQueryParams])

  if (!options.length) {
    return null
  }

  return (
    <GlobalSearchQuickFiltersContainer className={className}>
      {!noTitle && <GlobalSearchQuickFiltersTitle
        colour={titleColour}
        weight={titleWeight}
      >
        Trending destinations
      </GlobalSearchQuickFiltersTitle>}
      <GlobalSearchQuickFilterButtonsContainer>
        <PopularButtonList
          onClickHandler={onClickHandler}
          onContextMenuHandler={logEvents}
          options={take(options, 11)}
          fetching={fetchingTrendingDestinations}
          onImpressionHandler={onImpressionHandler}
        />
      </GlobalSearchQuickFilterButtonsContainer>
    </GlobalSearchQuickFiltersContainer>
  )
}

const mapStateToProps = (state: App.State) => ({
  hotelTrendingDestinations: state.destination.hotelTrendingDestinations,
  fetchingHotelTrendingDestinations: state.destination.fetchingHotelTrendingDestinations,
  search: state.router.location.search,
})

export default connect(mapStateToProps)(SearchTrendingDestinations)
