import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const scooponTravelDefaultPalette: App.Palette = {
  ...whiteLabelBaseDefaultPalette,
}

export const scooponTravelShadows: App.ThemeShadows = { ...baseThemeShadows }

const scooponTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default scooponTravelTheme
