import config from 'constants/config'
import { LESCAPES_SIGNUP_DOMAIN } from 'constants/auth'
import request, { ObjectOptions } from 'api/requestUtils'
import { appViewMessageHandler } from 'lib/window/webViewUtils'
import * as Analytics from 'analytics/analytics'
import { baseContext } from 'analytics/snowplow/events'
import { reportClientError } from 'services/errorReportingService'

interface PostAuthResponse {
  access_token: string;
  id_member: string;
  is_sign_up: boolean;
}

function mapAccount(serverAccount): App.AuthAccount {
  return {
    balance: 0,
    memberId: serverAccount.memberId,
    referralProgramEnabled: serverAccount.referral_program_enabled,
    givenName: serverAccount.givenName,
    surname: serverAccount.surname,
    title: serverAccount.title,
    email: serverAccount.email,
    dob: serverAccount.dob,
    status: serverAccount.status,
    roles: serverAccount.roles,
    vendors: serverAccount.vendors,
    postcode: serverAccount.postcode,
    phonePrefix: serverAccount.phone_prefix,
    phone: serverAccount.phone,
    partnerships: serverAccount.partnerships ?? {},
    toggles: serverAccount.toggles ?? {},
    lastLogin: serverAccount.lastLogin ? {
      ipAddress: serverAccount.lastLogin.ip_address,
      loginDate: serverAccount.lastLogin.login_date,
    } : {},
    isSpoofed: !!serverAccount.isSpoofed,
    fullName: serverAccount.fullName,
    legacyId: serverAccount.legacy_id,
    legacyPlatform: serverAccount.legacy_platform,
    countryCode: serverAccount.country_code,
    signupDomain: serverAccount.signup_domain,
    personContactId: serverAccount.person_contact_id,
    tenant: serverAccount.tenant,
    numberOfPurchases: serverAccount.number_of_purchases,
    customerSupportCode: serverAccount.customer_support_code,
    recentlyUsedAirportCode: serverAccount.recently_used_airport_code,
    emailVerified: !!serverAccount.email_verified,
    shadowBanUser: serverAccount.shadow_ban_user,
    utmSource: serverAccount.utm_source,
    utmMedium: serverAccount.utm_medium,
    utmCampaign: serverAccount.utm_campaign,
    utmTerm: serverAccount.utm_term,
    utmContent: serverAccount.utm_content,
    utmAdgroup: serverAccount.utm_adgroup,
    creditsByCurrency: {},
    subscriberTier: serverAccount.subscriber_tier,
  }
}

function parseTrackAuthResponse(res: any) {
  const idMember = res?.result?.id_member
  const status = res?.status
  return { idMember, status }
}

async function authPost(
  path: string,
  data: any,
  options: ObjectOptions = {},
  trackingProvider: App.AuthSource,
) {
  const res = await request.post<App.ApiResponse<PostAuthResponse>, any>(path, data, options)

  try {
    const { idMember, status } = parseTrackAuthResponse(res)

    if (path === '/register' || res.result?.is_sign_up) {
      Analytics.trackClientEvent({
        action: `${idMember}_${status}_succeeded`,
        subject: `${trackingProvider}_registration`,
        category: 'logging',
        type: 'operational',
      })
    } else {
      Analytics.trackClientEvent({
        action: `${idMember}_${status}_succeeded`,
        subject: `${trackingProvider}_auth`,
        category: 'logging',
        type: 'operational',
      })
    }
  } catch (e) {
    reportClientError(e)
  }

  const accessToken = res.result.access_token
  const isSignUp = !!res.result.is_sign_up
  const isSignIn = !isSignUp && path !== '/register'
  const accountResponse = await me(accessToken)

  return {
    accessToken,
    account: accountResponse,
    isSignUp: path === '/register' || isSignUp,
    isSignIn,
  }
}

export function me(accessToken?: string) {
  let options: ObjectOptions = { credentials: 'include' }
  if (accessToken) {
    options = { headers: { authorization: `Bearer ${accessToken}` } }
  }

  return request.get<App.ApiResponse<App.AuthAccount>>('/me', options).then(response => mapAccount(response.result))
}

interface CheckEmailPayloadStructure {
  email?: string,
  phone_prefix?: string;
  phone?: string;
  recaptchaResponse: string,
}

interface CheckUserResult {
  isExist: boolean;
  maskedPhone?: string;
}

export async function checkUserExists(user:{ email?: string, phoneNumber?: App.PhoneNumber}, recaptchaData: string = '') {
  return request.post<App.ApiResponse<CheckUserResult>, CheckEmailPayloadStructure>(
    '/api/users/exists',
    {
      email: user.email,
      // prefer email if it's provided
      phone: user.email ? undefined : user.phoneNumber?.phone,
      phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
      recaptchaResponse: recaptchaData,
    },
  )
}

export interface AuthAccountLoginResponse {
  accessToken: string;
  account: App.AuthAccount;
  business?: BusinessTraveller.Business;
  employee?: BusinessTraveller.Employee;
}

export function login(
  user: App.User,
  password: string,
  recaptchaData: string,
): Promise<AuthAccountLoginResponse> {
  return authPost(
    '/login/recaptcha',
    {
      login: user.email,
      phone: user.email ? undefined : user.phoneNumber?.phone,
      phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
      password,
      recaptchaResponse: recaptchaData,
    },
    { credentials: 'include' },
    'email',
  )
}

export function loginFacebook(data, regionCode: string) {
  return authPost(
    '/login/facebook',
    {
      ...data,
      signup_domain: LESCAPES_SIGNUP_DOMAIN,
      country_code: regionCode,
    },
    { credentials: 'include' },
    'facebook',
  )
}

export function loginGoogle(data, regionCode: string) {
  return authPost(
    '/login/google',
    {
      ...data,
      signup_domain: LESCAPES_SIGNUP_DOMAIN,
      country_code: regionCode,
    },
    { credentials: 'include' },
    'google',
  )
}

export function loginApple(data, regionCode: string) {
  return authPost(
    '/login/apple',
    {
      ...data,
      signup_domain: LESCAPES_SIGNUP_DOMAIN,
      country_code: regionCode,
    },
    { credentials: 'include' },
    'apple',
  )
}

export function logout() {
  return request.post('/logout', {}, { credentials: 'include' })
}

export function getSingleUseToken() {
  return request.post<any, any>('/api/single-use-token', {}, { credentials: 'include' })
}

export interface AuthAccountRegistrationResponse {
  accessToken: string;
  account: App.AuthAccount;
}

export function register(data, regionCode: string, includeCredentials = true): Promise<AuthAccountRegistrationResponse> {
  const { email, password, surname, givenName, utmSource, utmMedium, utmCampaign, utmTerm, utmAdgroup, utmContent, phone, phonePrefix, postcode } = data
  const signUpDomain = config.BRAND === 'luxuryescapes' ? LESCAPES_SIGNUP_DOMAIN : null
  const payload = {
    email,
    password,
    surname,
    givenName,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_term: utmTerm,
    utm_adgroup: utmAdgroup,
    utm_content: utmContent,
    country_code: regionCode,
    ...(signUpDomain && { signup_domain: signUpDomain }),
    ...(phone && {
      phone_prefix: phonePrefix,
      phone,
    }),
    postcode,
  }
  return authPost(
    '/register',
    payload,
    includeCredentials ? { credentials: 'include' } : undefined,
    'email',
  )
}

export function sendResetPasswordEmail(email: string, callbackPath?: string) {
  return request.post('/api/reset-password-email', { email, callbackPath }, { credentials: 'include' })
}

export function resetPassword({ token, password }) {
  return request
    .put<App.ApiResponse<{ access_token: string }>, any>('/api/reset-token', { token, password }, { credentials: 'include' })
    .then(async response => ({
      accessToken: response.result.access_token,
      account: await me(response.result.access_token),
    }))
}

function mapUserDetails(serverAccount) {
  return {
    givenName: serverAccount.givenName,
    surname: serverAccount.surname,
    title: serverAccount.title,
    email: serverAccount.email,
    dob: serverAccount.dob,
    postcode: serverAccount.postcode,
    phonePrefix: serverAccount.phone_prefix,
    phone: serverAccount.phone,
    countryCode: serverAccount.country_code,
    recentlyUsedAirportCode: serverAccount.recently_used_airport_code,
  }
}

export function updateUserDetails(account: Partial<App.AuthAccount>, supportPhoneNumber: string | null = null) {
  const serverAccount = {
    givenName: account.givenName ?? undefined,
    surname: account.surname ?? undefined,
    title: account.title ?? undefined,
    dob: account.dob ?? undefined,
    country_code: account.countryCode ?? undefined,
    phone_prefix: account.phonePrefix ?? undefined,
    phone: account.phone ?? undefined,
    recently_used_airport_code: account.recentlyUsedAirportCode ?? undefined,
    postcode: account.postcode ?? undefined,
    support_phone_number: supportPhoneNumber ?? undefined,
  }

  return request.put<App.ApiResponse<any>, unknown>('/api/users/current', serverAccount, { credentials: 'include' })
    .then(response => mapUserDetails(response.result))
}

export function updatePartnershipDetails(partnershipDetails: App.PartnershipsMap) {
  return request.put('/api/partnerships/current', partnershipDetails, { credentials: 'include' })
}

export function resendEmailConfirmation() {
  return request.get('/api/resend-email-confirmation', {
    credentials: 'include',
  })
}

const DO_NATIVE_AUTHENTICATE = 'doNativeAuthenticate'
export async function webViewAccountAccess() {
  const authPromise = new Promise<string>((resolve, reject) => {
    window.userAuthenticated = (token: string) => {
      if (token) {
        resolve(token)
      } else {
        reject('Authentication cancelled')
      }
    }

    // Must remove region because mobile client already sets it
    const analyticsContext = baseContext().filter(c => {
      return !c.schema.includes('region')
    })
    appViewMessageHandler('authenticateUser', DO_NATIVE_AUTHENTICATE, analyticsContext)
  })
  try {
    const accessToken = await authPromise

    return {
      accessToken,
      account: await me(accessToken),
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    // re-throw to indicate that the login failed
    throw error
  }
}

export interface ItemDetails {
  check_in?: string; // Standard hotel reservations
  start_date?: string; // Tours v1
  type?: string;
  journey?: { id: string } // Flights
  ticket?: { date?: string } // Experiences
  departure_date?: string; // Cruises
  session_id?: string; // Bedbank
  departure_id?: string; // Tours v2
}
export interface CheckRequireVerificationRequest {
  brand: string;
  regionCode: string;
  currencyCode: string;
  payments: {
    payments: Array<{ type: string }>
  };
  items: Array<ItemDetails>;
}

export interface CheckRequireVerificationResponse {
  verificationRequired: boolean;
}

export function checkRequireVerification(checkRequireVerificationRequest: CheckRequireVerificationRequest): Promise<CheckRequireVerificationResponse> {
  // Not all order creation need otp, use this to check if otp header is required for order creation.
  const { brand, regionCode, currencyCode, payments, items } = checkRequireVerificationRequest
  return request.post('/api/orders/require_verification', {
    brand,
    region_code: regionCode,
    payments,
    items,
    currency_code: currencyCode,
  }, { credentials: 'include' })
}

export function sendVerificationCode(): Promise<void> {
  // Send OTP via email
  return request.post('/api/verify/send-auth-code?without_brand=1', {}, { credentials: 'include' })
}

export interface VerifyAuthCodeResponse {
  result: { message: string, token: string }
}

export function verifyAuthCode(code: string): Promise<string> {
  // Use to check if OTP is expired or invalid
  return request.post('/api/verify/verify-auth-code?without_brand=1', { code }, { credentials: 'include' })
    .then(res => (res as VerifyAuthCodeResponse).result.token)
}

export function sendOTPVerificationCode(user: App.User) {
  return request.post('/login/send-sms-otp', {
    email: user.email,
    phone: user.email ? undefined : user.phoneNumber?.phone,
    phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
    brand: config.BRAND,
  }, { credentials: 'include' })
}

export function validateOTPVerificationCode(user: App.User, code: string) {
  return authPost(
    '/login/login-sms-otp',
    {
      email: user.email,
      phone: user.email ? undefined : user.phoneNumber?.phone,
      phone_prefix: user.email ? undefined : user.phoneNumber?.prefix,
      code,
      brand: config.BRAND,
    },
    { credentials: 'include' },
    'otp',
  )
}

export function checkEmailChangePossibility(): Promise<{ email_changeable: boolean, step: string, new_email:string, error: string }> {
  return request.post('/api/auth/email-change/check', { brand: config.BRAND }, { credentials: 'include' })
}

export function sendEmailChangeConfirmationCode(email: string, isNewEmail: boolean, password?: string, resend?: boolean): Promise<{status: number, message: string, error: any}> {
  return request.post('/api/auth/email-change/send-email-change-confirmation-code', { email, password, resend, is_new_email: isNewEmail }, { credentials: 'include' })
}

export function validateEmailChangeConfirmationCode(code: string, email: string, isNewEmail: boolean, password?: string, recaptchaResponse?: string): Promise<{status?: number, message?: string, loginData?: any, result?: any, account?: any}> {
  return request.post('/api/auth/email-change/validate-email-change-confirmation', { code, email, password, is_new_email: isNewEmail, recaptchaResponse }, { credentials: 'include' })
}
