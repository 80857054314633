import Caption from 'components/Luxkit/Typography/Caption'
import React from 'react'

interface Props {
  departureCity?: string;
  destinationCity?: string;
}

export function HeroCruiseLocation({ destinationCity, departureCity }: Props) {
  return (
    <Caption variant="large" colour="neutral-one">
      {departureCity === destinationCity && <>Starts and ends in <b>{departureCity}</b></>}
      {departureCity !== destinationCity && <>Starts in <b>{departureCity}</b>. Ends in <b>{destinationCity}</b></>}
      <Caption variant="large" colour="neutral-three" as="span"> · Cruise</Caption>
    </Caption>
  )
}
