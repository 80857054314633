import React, { useCallback } from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import useJoinLuxPlusFreePreview from 'luxPlus/hooks/useJoinLuxPlusFreePreview'
import Pill from 'components/Luxkit/Pill'

function LuxPlusFreePreviewModal() {
  const { resolve } = useModalElementContext<boolean>()

  const closeModal = useCallback(() => resolve(true), [resolve])

  const {
    onPendingJoinFreePreview,
    hasInviteExpiryDate,
    invitePillExpiryCopy,
    formattedExpiryDate,
  } = useJoinLuxPlusFreePreview()

  const handleJoinFreePreview = useCallback(() => {
    closeModal()
    onPendingJoinFreePreview()
  }, [closeModal, onPendingJoinFreePreview])

  return <Modal
      title={`LuxPlus+ ${LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview`}
      primaryActionText="Start Free Preview now"
      onPrimaryActionClick={handleJoinFreePreview}
    >
    <VerticalSpacer gap={20}>
      <Pill
          endIcon={null}
          kind="primary"
          startIcon={null}
          variant="success"
        >
        {invitePillExpiryCopy}
      </Pill>
      <BodyText variant="medium" colour="neutral-two">
        Discover hidden offers, view member pricing and find out what life is like in our VIP Travel Club.
        {' '}This exclusive invite is {hasInviteExpiryDate ? `only valid until ${formattedExpiryDate}` : 'valid for a limited period only'}, so be sure to accept this offer before time runs out.
      </BodyText>
      <BodyText variant="small" colour="neutral-two">
        To book an offer with {LUXURY_PLUS.PROGRAM_NAME} benefits during your Free Preview you will be prompted to become a member.
        {' '}<TextLink weight="regular" target="_blank" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply</TextLink>
      </BodyText>
    </VerticalSpacer>
  </Modal>
}

export default LuxPlusFreePreviewModal
