import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import React, { ChangeEventHandler, RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import BusinessTravellerSelectContent from './BusinessTravellerSelectContent'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import TextButton from 'components/Luxkit/Button/TextButton'

interface MappedStateProps {
  travellerEmployees: Array<BusinessTraveller.EmployeeFromMeEndpoint>
}

interface Props {
  dropdownBoundaryRef?: RefObject<HTMLElement>;
  className?: string;
}

function BusinessTravellerSelectFlightsDesktop(props: Props & MappedStateProps) {
  const { className, dropdownBoundaryRef, travellerEmployees } = props

  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const { activeMenu } = state

  const setActiveMenu = useCallback((activeMenu?: StandaloneFlightMenu) => {
    dispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, activeMenu })
  }, [dispatch])

  const setOccupants = useCallback((occupants: App.Occupants) => {
    dispatch({ type: FlightSearchWidgetActions.SET_OCCUPANTS, occupants })
  }, [dispatch])

  const handleSelectTravellerClick = useCallback(() => {
    const menuToSet = activeMenu !== StandaloneFlightMenu.occupants ?
      StandaloneFlightMenu.occupants :
      undefined
    setActiveMenu(menuToSet)
  }, [activeMenu, setActiveMenu])

  const handleTravellerSelect = useCallback((selectedTravellerEmployeeIds: Array<string>) => {
    if (selectedTravellerEmployeeIds.length > 0) {
      const occupants: App.Occupants = {
        adults: selectedTravellerEmployeeIds.length ?? 1,
        children: 0,
        infants: 0,
        childrenAge: [],
      }

      setOccupants(occupants)
    }
    setActiveMenu()
  }, [setActiveMenu, setOccupants])

  const handleSelectTravellerClose = useCallback(() => {
    setActiveMenu(undefined)
  }, [setActiveMenu])

  const fieldValue = useMemo(() => {
    if (travellerEmployees?.length) {
      const firstTraveller = travellerEmployees[0]

      return `${firstTraveller.firstName} ${firstTraveller.lastName}`
    }

    return undefined
  }, [travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)
  const buttonLabel = fieldValue || 'Select Traveller'

  return (
    <>
      <SearchFormFieldGroup className={className}>
        <TextButton
        kind="tertiary"
        color="danger"
        startIcon={<LineUserIcon />}
        endIcon={<DropdownChevron open={state.activeMenu === 'occupants'} />}
        onClick={handleSelectTravellerClick}
        ref={localInputRef}
      >
          {buttonLabel}
        </TextButton>
      </SearchFormFieldGroup>

      <DropdownList
        size="M"
        open={activeMenu === StandaloneFlightMenu.occupants}
        triggerRef={localInputRef}
        anchorRef={localInputRef}
        boundaryRef={dropdownBoundaryRef}
        disableFlip
        onClose={handleSelectTravellerClose}
        headerExtension={<TextInputOnly
          value={searchTerm}
          type="text"
          placeholder="Search traveller name"
          onChange={handleSearchTermChange}
          endIcon={<LineSearchIcon />}
          noValidationSpacing
        />}
      >
        <BusinessTravellerSelectContent
          searchTerm={searchTerm}
          onTravellerSelect={handleTravellerSelect}
        />
      </DropdownList>
    </>
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectFlightsDesktop)
