import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { last } from 'lib/array/arrayUtils'

function getFlightOrderItems(state: App.State, order: App.Order) {
  const { categoryId, productId } = getCategoryAndProductId('flight')
  return order.flightItems.map(orderItem => {
    const flightDetails = state?.orders?.flightDetails[orderItem?.itemId]?.flight
    const v2Flights = flightDetails?.journeyFares?.fares?.[0]?.flightGroup?.flights ?? []

    const departingDate = v2Flights?.[0]?.departingDate
    const arrivalDate = last(v2Flights ?? [])?.arrivalDate
    const originCountry = v2Flights[0]?.departureCountry
    const destinationCountry = last(v2Flights)?.arrivalCountry
    const variantRaw = flightDetails?.journeyFares?.fares?.[0]?.fareType
    const variant = variantRaw ? variantRaw.toLowerCase() : undefined

    return comluxgroup.createItem_1_1_0({
      offerId: 'flight',
      categoryId,
      productId,
      travelStart: departingDate,
      travelEnd: arrivalDate,
      numberOfAdults: flightDetails?.travellers.filter(traveller => traveller.type === 'adult')?.length || 0,
      numberOfChildren: flightDetails?.travellers.filter(traveller => traveller.type === 'child')?.length || 0,
      numberOfInfants: flightDetails?.travellers.filter(traveller => traveller.type === 'infant')?.length || 0,
      price: orderItem.total,
      variant,
      currency: order.currencyCode,
      destinationCountry,
      originCountry,
      itemId: orderItem.itemId,
      orderId: order.id,
    })
  },
  )
}

export default getFlightOrderItems
