import React, { InputHTMLAttributes, forwardRef, useCallback, useState } from 'react'
import cn from 'clsx'
import InputWrap from './InputWrap'
import Input from './Input'
import styled from 'styled-components'
import { rem } from 'polished'
import { InputTextBase } from 'components/Luxkit/Typography/InputText'
import { DMY_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { EMAIL_PATTERN } from 'constants/regularExpressions'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from 'components/Luxkit/Typography/Typography'

const StyledInput = styled(Input)`
  ${InputTextBase}
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});
  width: 100%;
  border: none;
  background: transparent;
  transition: color 0.2s;
  padding: ${rem(12)};
  border-radius: ${props => props.theme.borderRadius.S};
  color: ${props => props.theme.palette.neutral.default.one};

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.palette.neutral.default.two};
    }
  }

  /*
    Browser things like auto fill and validation pop ups use the
    actual input element as their target. We want this to look like
    the whole element - so we need our input to take up the whole container

    When there's icon, reserve space for them and then they will be
    absolutely positioned.

    Icons for inputs are always 24px sized
  */
  &.icon-left {
    padding-left: ${rem(48)};
  }

  &.icon-right {
    padding-right: ${rem(48)};
  }
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  containerRef?: React.Ref<HTMLDivElement>;
  /**
   * Override the 'required' error message
   */
  requiredErrorMessage?: string;
  /**
   * Override any other validation error
   *
   * _For more granular error handling, use getInvalidMessage_
   */
  invalidErrorMessage?: string;
  manualError?: boolean;
  noValidationSpacing?: boolean;
  noValidationMessage?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  uppercase?: boolean;
  helpText?: React.ReactNode;
  getInvalidMessage?: (input: HTMLInputElement) => string | undefined;
  onInputValidate?: (input: HTMLInputElement) => void;
}

const TextInputOnly = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value,
    defaultValue,
    className,
    children,
    inputClassName,
    disabled,
    type = 'text',
    pattern,
    style,
    startIcon,
    endIcon,
    minLength,
    maxLength,
    min,
    max,
    noValidationSpacing,
    noValidationMessage,
    onInputValidate,
    manualError,
    containerRef,
    getInvalidMessage,
    requiredErrorMessage,
    invalidErrorMessage,
    helpText,
    ...inputProps
  } = props

  const [isDirty, setDirty] = useState<boolean>(!!(defaultValue || value))
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const handleDirtyChange = useCallback(() => {
    setDirty((currDirty) => {
      if (!currDirty) {
        return true
      } else {
        return currDirty
      }
    })
  }, [])

  const updateErrorMessage = useCallback((input: HTMLInputElement) => {
    const validity = onInputValidate ? onInputValidate(input) : input.validity
    if (validity && !validity.valid) {
      let message = getInvalidMessage?.(input)
      if (!message) {
        if (validity.valueMissing && requiredErrorMessage) {
          message = requiredErrorMessage
        } else if (invalidErrorMessage) {
          message = invalidErrorMessage
        } else if (validity.patternMismatch && type === 'email') {
          message = 'Please enter a valid email'
        } else if (validity.patternMismatch && type === 'tel') {
          message = 'Phone number can contain numbers only'
        } else if (validity.tooShort) {
          message = `Please enter at least ${minLength} characters`
        } else if (validity.tooLong) {
          message = `Please enter at most ${maxLength} characters`
        } else if (type === 'date' && validity.rangeUnderflow) {
          message = `Must be after ${moment(min).format(DMY_DATE_FORMAT)}`
        } else if (type === 'date' && validity.rangeOverflow) {
          message = `Must be before ${moment(max).format(DMY_DATE_FORMAT)}`
        } else if (type === 'date' && validity.badInput) {
          message = 'Invalid date'
        } else {
          message = input.validationMessage
        }
      }
      setErrorMessage(message)
    } else if (errorMessage) {
      setErrorMessage(undefined)
    }
  }, [errorMessage, getInvalidMessage, invalidErrorMessage, max, maxLength, min, minLength, onInputValidate, requiredErrorMessage, type])

  let displayableErrorMessage = isDirty ? errorMessage : undefined

  if (manualError) {
    displayableErrorMessage = invalidErrorMessage
  }

  const inferredPattern = pattern ?? (type === 'email' ? EMAIL_PATTERN : undefined)

  return <InputWrap
    ref={containerRef}
    className={className}
    error={displayableErrorMessage}
    disabled={disabled}
    noValidationSpacing={noValidationSpacing}
    noValidationMessage={noValidationMessage}
    startIcon={startIcon}
    endIcon={endIcon}
    helpText={helpText}
  >
    <StyledInput
      {...inputProps}
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      type={type}
      pattern={inferredPattern}
      min={min}
      minLength={minLength}
      max={max}
      maxLength={maxLength}
      disabled={disabled}
      className={cn(inputClassName,
        { 'icon-left': !!startIcon, 'icon-right': !!endIcon },
      )}
      onErrorUpdate={updateErrorMessage}
      onDirtyChange={handleDirtyChange}
    />
    {children}
  </InputWrap>
})

export default TextInputOnly
