import React, { useRef, useMemo, useCallback, useContext, MouseEventHandler } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'

import MarkdownRender from 'components/Luxkit/MarkdownRender'

import BodyText from 'components/Luxkit/Typography/BodyText'
import pluralize from 'lib/string/pluralize'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'
import { isNonEmptyArray, sortBy, sum } from 'lib/array/arrayUtils'
import OfferTileInclusionsModal from './OfferTileInclusionsModal'
import getInclusionMinStayText from 'lib/offer/getInclusionMinStayText'
import ModalContext from 'contexts/ModalContext'
import TextLink from 'components/Luxkit/TextLink'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useIsMobileScreen } from 'lib/web/deviceUtils'

const Root = styled.div`
  position: relative;
  overflow: hidden;
`

const StyledInclusionItemDiv = styled.div`
  display: flex;
`

const InclusionItemText = styled(BodyText)`
  margin-top: 0;
  padding-left: ${rem(8)};
  text-indent: ${rem(-2)};
`

const StyledIconDiv = styled.div`
  position: relative;
  top: 2px;
`

function getIconText(inclusion: App.TileInclusion | App.PackageInclusion) {
  if ('icon' in inclusion) {
    return inclusion.icon
  }
  if ('categoryIcon' in inclusion) {
    return inclusion.categoryIcon
  }
  return ''
}

function getInclusionText(inclusion: App.TileInclusion | App.PackageInclusion) {
  if ('text' in inclusion) {
    return inclusion.text
  }
  if ('description' in inclusion) {
    if (inclusion.description?.startsWith('-')) {
      return inclusion.description.slice(1).trim()
    }
    return inclusion.description
  }
}

interface Props {
  className?: string;
  content?: string;
  tileInclusionsList?: Array<App.TileInclusion> | Array<App.PackageInclusion>;
  heading: string;
  abTestOfferTile?: boolean;
  maxInclusionsCount?: number;
  maxLineCount?: number;
  offerURL?: string
  offerTitle: string
  truncateInclusions?: boolean
  filters?: App.OfferListFilters
}

const filtersRank = (inclusion: string, filters: App.OfferListFilters, children: boolean) => {
  if (children) {
    if (inclusion.includes('child')) {
      return 2
    }
    if (inclusion.includes('family')) {
      return 2
    }
    if (inclusion.includes('kid')) {
      return 2
    }
  }
  for (const filter of filters.inclusions || []) {
    const lowercaseFilter = filter.toLowerCase()
    if (inclusion.includes(lowercaseFilter)) {
      return 1
    }
    if ((inclusion.includes('massage') || inclusion.includes('spa')) && lowercaseFilter.includes('spa')) {
      return 1
    }
    if (inclusion.includes('late') && lowercaseFilter.includes('late')) {
      return 1
    }
    if (inclusion.includes('early') && lowercaseFilter.includes('early')) {
      return 1
    }
    if (inclusion.includes('drink') && lowercaseFilter.includes('drink')) {
      return 1
    }
  }
  return 0
}

const sortByFilters = (inclusions: Array<App.TileInclusion> | Array<App.PackageInclusion>, filters?: App.OfferListFilters) => {
  if (!filters || inclusions.length === 0) return inclusions
  const children = !!filters.rooms && sum(filters.rooms, (room) => room.children ?? 0) > 0

  if ('text' in inclusions[0]) {
    return sortBy(inclusions as Array<App.TileInclusion>, (inclusion) => {
      const text = inclusion.text.toLowerCase()
      return filtersRank(text, filters, children)
    })
  }
  return sortBy(inclusions as Array<App.PackageInclusion>, (inclusion) => {
    const text = inclusion.description?.toLowerCase() ?? ''
    return filtersRank(text, filters, children)
  })
}

// Since we don't know the total lines until we render the inclusions, we estimate the number of lines by multiples of lettersPerLine
function limitNumberOfInclusionsByLineCount(inclusions: Array<App.TileInclusion | App.PackageInclusion>, maxLineCount: number, lettersPerLine = 55) {
  let totalLines = 0
  const filteredInclusions: Array<App.TileInclusion | App.PackageInclusion> = []

  for (const inclusion of inclusions) {
    const inclusionText = getInclusionText(inclusion)
    if (!inclusionText) continue
    const lines = Math.ceil(inclusionText.length / lettersPerLine)

    if (totalLines + lines <= maxLineCount) {
      filteredInclusions.push(inclusion)
      totalLines += lines
    } else {
      // Break the loop if adding the current inclusion exceeds the limit
      break
    }
  }

  // We want to leave space for the "More inclusions" text if it's going to be necessary
  if (filteredInclusions.length < inclusions.length && totalLines === maxLineCount) {
    return filteredInclusions.slice(0, -1)
  }

  return filteredInclusions
}

const defaultInclusionList: Array<App.TileInclusion> = []

function OfferTileInclusions({
  className,
  content,
  tileInclusionsList = defaultInclusionList,
  heading = 'Your handpicked inclusions',
  abTestOfferTile,
  maxInclusionsCount = 5,
  offerURL,
  offerTitle,
  maxLineCount,
  filters,
  truncateInclusions = false,
}: Props) {
  const showReducedInclusions = abTestOfferTile || !!maxInclusionsCount || !!maxLineCount

  const isMobile = useIsMobileScreen()
  const sortedInclusions = useMemo(() => sortByFilters(tileInclusionsList, filters), [tileInclusionsList, filters])

  const inclusionsEl = useRef(null)
  const shownInclusions = (maxLineCount ?
    limitNumberOfInclusionsByLineCount(sortedInclusions, maxLineCount, isMobile ? 40 : 55) :
    sortedInclusions.slice(0, maxInclusionsCount))
  const numInclusionsLeft = sortedInclusions.length - shownInclusions.length

  const inclusionsFromContent = useMemo(() => {
    if (!content || !maxInclusionsCount) return null
    const inclusionsFromContent = content.split('\n').map(item => item.trim()).filter(item => item !== '')
    const visibleItems = inclusionsFromContent.slice(0, maxInclusionsCount)
    const remainingItemsCount = Math.max(inclusionsFromContent.length - maxInclusionsCount, 0)

    return (
      <div>
        {visibleItems.map((item, index) => (
          <MarkdownRender key={index} content={item} fontSize="medium" type="compact" />
        ))}
        {remainingItemsCount > 0 && <BodyText variant="medium" weight="bold" colour="primary" underline>
          {`+ ${remainingItemsCount} more ${pluralize({ singular: 'inclusion', count: remainingItemsCount }).text}`}
        </BodyText>
        }
      </div>
    )
  }, [content, maxInclusionsCount])

  const modalElement = useModalElementContext()
  const showModal = useContext(ModalContext)
  const showMoreInclusionsModal = useCallback<MouseEventHandler>(async(e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!sortedInclusions) return
    const isViewOfferDismissal = await showModal<boolean>(
      <OfferTileInclusionsModal
        offerURL={offerURL}
        title={offerTitle}
        inclusionList={sortedInclusions}
      />,
    )
    if (isViewOfferDismissal) {
      modalElement?.resolve()
    }
  }, [sortedInclusions, showModal, offerURL, offerTitle, modalElement])

  return (
    <Root className={cn(className, { 'landing-page-test': abTestOfferTile })}>
      <BodyText variant="medium" weight="bold">{heading}</BodyText>
      <div ref={inclusionsEl}>
        {content && !sortedInclusions.length && maxInclusionsCount && inclusionsFromContent}
        {content && !sortedInclusions.length && !maxInclusionsCount && <MarkdownRender fontSize="medium" type="compact" content={content} />}
        {isNonEmptyArray(sortedInclusions) &&
         (showReducedInclusions ? shownInclusions : sortedInclusions).map((inclusion) => {
           const inclusionText = getInclusionText(inclusion)
           const InclusionIcon = getInclusionIcon(getIconText(inclusion))
           const inclusionMinStayText = getInclusionMinStayText(inclusion)
           const isHighlighted = 'isHighlighted' in inclusion ? inclusion.isHighlighted : false
           return (
             <StyledInclusionItemDiv key={inclusionText}>
               <StyledIconDiv>
                 <InclusionIcon data-testid={`${getIconText(inclusion)}` || 'default_icon'} size="XS" />
               </StyledIconDiv>
               <InclusionItemText
                  variant="medium"
                  weight={isHighlighted ? 'bold' : 'normal'}
                  wrap={truncateInclusions ? 'truncate' : undefined}
                >
                 {inclusionText} {inclusionMinStayText}
               </InclusionItemText>
             </StyledInclusionItemDiv>
           )
         })}
      </div>
      {isNonEmptyArray(sortedInclusions) && showReducedInclusions && numInclusionsLeft > 0 &&
        <TextLink onClick={showMoreInclusionsModal} weight="bold" size="medium">
          {`+ ${numInclusionsLeft} more ${pluralize({ singular: 'inclusion', count: numInclusionsLeft }).text}`}
        </TextLink>
      }
    </Root>
  )
}

export default OfferTileInclusions
