import { removeItem } from 'actions/CheckoutActions'
import { navigateToCheckout } from 'checkout/actions/navigateActions'
import IconButton from 'components/Luxkit/Button/IconButton'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineShoppingCartIcon from 'components/Luxkit/Icons/line/LineShoppingCartIcon'
import LineTrashIcon from 'components/Luxkit/Icons/line/LineTrashIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import { useAppDispatch } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import React, { useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import CartModeModal from 'components/Common/Modals/AddToCartModal/AddToCartModal'
import { checkoutCartItemViews } from 'selectors/checkoutCartSelectors'
import { useCheckoutFlightJourney } from 'checkout/hooks/useCheckoutFlightData'
import { pluralizeToString } from 'lib/string/pluralize'

interface MappedProps {
  itemViews: Array<App.Checkout.CartItemView>
}

function MyCartMenu(props: MappedProps) {
  const { itemViews } = props
  const { value: menuOpen, toggle: onToggle, off: onClose } = useToggle()
  const triggerRef = useRef(null)
  const dispatch = useAppDispatch()

  useCheckoutFlightJourney()

  const onRemove = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dispatch(removeItem(e.currentTarget.id))
  }, [dispatch])

  const onNavigateClick = useCallback(() => {
    dispatch(navigateToCheckout({ skipSpoofedModal: true }))
  }, [dispatch])

  return <>
    <TextButton
      kind="primary"
      startIcon={<LineShoppingCartIcon/>}
      endIcon={<DropdownChevron open={menuOpen}/>}
      ref={triggerRef}
      onClick={onToggle}
    >
      My Cart
      {itemViews.length > 0 && <span>&nbsp;({itemViews.length})</span>}
    </TextButton>
    <DropdownList
      size="M"
      open={menuOpen}
      onClose={onClose}
      triggerRef={triggerRef}
      anchorRef={triggerRef}
      title="Cart"
      subtitle={pluralizeToString('cart item', itemViews.length)}
      footerActionsLayout="stacked"
      isPrimaryActionHidden={!itemViews.length}
      primaryActionProps={{
        children: 'Go to checkout',
        onClick: onNavigateClick,
      }}
    >
      {itemViews.map(item => (
        <ListItem
          nonInteractable
          size="medium"
          key={item.id}
          title={item.label}
          subtitle={item.subtitle}
          children={<IconButton id={item.id} kind="tertiary" onClick={onRemove}>
            <LineTrashIcon/>
          </IconButton>}
        />
      ))}
    </DropdownList>
    <CartModeModal/>
  </>
}

function mapStateToProps(state: App.State) {
  return {
    itemViews: checkoutCartItemViews(state),
  }
}

export default connect(mapStateToProps)(MyCartMenu)
