import React, { useCallback, useContext, useMemo } from 'react'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import PromotionInclusionList from 'components/Cruises/PromotionInclusions/PromotionInclusionList'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import CruiseModalPrices from './CruiseModalPrices/CruiseModalPrices'
import CruiseModalItineraryDetails from 'components/Cruises/CruiseTiles/Common/CruiseModalItineraryDetails'

interface Props {
  shipDetailsLabel?: string;
  itinerary?: Array<App.CruiseItineraryItem>;
  itineraryDetailsLabel: string;
  itineraryFlash?: string;
  offerDescription?: string;
  promotionDetails: App.CruisePromotionDetails | null;
  evergreenInclusions?: Array<App.EvergreenInclusion>;
  tileInclusions?: Array<App.TileInclusion>;
  earliestDepartureDate: string;
  laterDates: Array<string>;
  offerTitle: string;
  isFlashOffer: boolean;
  offer?: App.CruiseOffer | App.Offer | App.OfferSummary;
  departures?: Record<string, Array<App.CruiseDepartureSummary>>;
  departureDates: Array<string>;
  flashOffer?: App.CruiseOffer | App.Offer | App.OfferSummary;
}

function CruiseTileDetails({
  shipDetailsLabel,
  itineraryDetailsLabel,
  itineraryFlash,
  offerDescription,
  promotionDetails,
  evergreenInclusions,
  tileInclusions,
  earliestDepartureDate,
  laterDates,
  offerTitle,
  isFlashOffer,
  offer,
  itinerary,
  departures,
}: Props) {
  const showModal = useContext(ModalContext)
  const globalFilters = useContext(GlobalSearchStateContext)

  const hasInclusions = useMemo(() => (
    promotionDetails?.sellingPoints.length || evergreenInclusions?.length || tileInclusions?.length
  ), [promotionDetails, evergreenInclusions, tileInclusions])

  const showDescription = useMemo(() => {
    return (hasInclusions || 0) <= 1
  }, [hasInclusions])

  const openPricesModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isFlashOffer) {
      await showModal<boolean>(<CruiseModalPrices
        offerTitle={offerTitle}
        offer={offer}
        departures={departures}
        startDateFilter={globalFilters.checkinDate}
        endDateFilter={globalFilters.checkoutDate}
        monthsFilter={globalFilters.flexibleMonths}
      />)
    }
  }, [
    departures,
    isFlashOffer,
    offer,
    offerTitle,
    showModal,
    globalFilters.checkinDate,
    globalFilters.checkoutDate,
    globalFilters.flexibleMonths,
  ])

  const openItineraryModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (isFlashOffer) {
      await showModal<boolean>(<CruiseModalItineraryDetails isFlashOffer itineraryFlash={itineraryFlash} offerTitle={offerTitle} offer={offer}/>)
    } else if (itinerary) {
      await showModal<boolean>(<CruiseModalItineraryDetails itinerary={itinerary} offerTitle={offerTitle} offer={offer}/>)
    }
  }, [isFlashOffer, itinerary, showModal, itineraryFlash, offerTitle, offer])

  return <Group gap={12} direction="vertical">
    <Group gap={4} direction="vertical">
      {shipDetailsLabel && <BodyText variant="medium" colour="neutral-two">{shipDetailsLabel}</BodyText>}

      <Group gap={8} direction="horizontal" verticalAlign="center" wrap="wrap">
        <BodyText variant="medium" colour="neutral-two">
          {itineraryDetailsLabel}
        </BodyText>

        {(itinerary || isFlashOffer) && (
          <TextLink weight="regular" size="caption" onClick={openItineraryModal} data-testid="cruise-tile-details-itinerary-modal">
            View itinerary
          </TextLink>
        )}
      </Group>
    </Group>

    <Group gap={4} direction="vertical">
      {offerDescription && showDescription &&
        <MarkdownRender
          type="compact"
          fontSize="medium"
          fontWeight="normal"
          content={offerDescription}
        />
      }
      {!!hasInclusions && <PromotionInclusionList
        promotionDetails={promotionDetails}
        evergreenInclusions={evergreenInclusions}
        tileInclusions={tileInclusions}
      />}
    </Group>

    <BodyText variant="medium" colour="neutral-two" weight="bold">
      {earliestDepartureDate && `Departing on ${earliestDepartureDate}`}{' '}
      {isFlashOffer && !!laterDates.length && <TextLink>
        and more
      </TextLink>}
      {!isFlashOffer && !!laterDates.length && <TextLink onClick={openPricesModal} data-testid="cruise-tile-details-prices-modal">
        and more
      </TextLink>}
    </BodyText>
  </Group>
}

export default CruiseTileDetails
