import switchFunc from 'lib/function/switchFunc'

import LineAppleIcon from 'components/Luxkit/Icons/line/LineAppleIcon'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import LineBanIcon from 'components/Luxkit/Icons/line/LineBanIcon'
import LineBedDoubleIcon from 'components/Luxkit/Icons/line/LineBedDoubleIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineBusIcon from 'components/Luxkit/Icons/line/LineBusIcon'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import LineChairWithAirplaneIcon from 'components/Luxkit/Icons/line/LineChairWithAirplaneIcon'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import LineChildWithPlusIcon from 'components/Luxkit/Icons/line/LineChildWithPlusIcon'
import LineClochIcon from 'components/Luxkit/Icons/line/LineClochIcon'
import LineClockWithTickIcon from 'components/Luxkit/Icons/line/LineClockWithTickIcon'
import LineCoffeeIcon from 'components/Luxkit/Icons/line/LineCoffeeIcon'
import LineDelayIcon from 'components/Luxkit/Icons/line/LineDelayIcon'
import LineDiningCreditIcon from 'components/Luxkit/Icons/line/LineDiningCreditIcon'
import LineDollarSignAltIcon from 'components/Luxkit/Icons/line/LineDollarSignAltIcon'
import LineDollarSignIcon from 'components/Luxkit/Icons/line/LineDollarSignIcon'
import LineDrinksTrayIcon from 'components/Luxkit/Icons/line/LineDrinksTrayIcon'
import LineFileCheckAltIcon from 'components/Luxkit/Icons/line/LineFileCheckAltIcon'
import LineFileMinusAltIcon from 'components/Luxkit/Icons/line/LineFileMinusAltIcon'
import LineFilePlusAltIcon from 'components/Luxkit/Icons/line/LineFilePlusAltIcon'
import LineFlightCreditIcon from 'components/Luxkit/Icons/line/LineFlightCreditIcon'
import LineGlassMartiniAltIcon from 'components/Luxkit/Icons/line/LineGlassMartiniAltIcon'
import LineGlassMartiniIcon from 'components/Luxkit/Icons/line/LineGlassMartiniIcon'
import LineGolfIcon from 'components/Luxkit/Icons/line/LineGolfIcon'
import LineHappyHourIcon from 'components/Luxkit/Icons/line/LineHappyHourIcon'
import LineHeartPlusIcon from 'components/Luxkit/Icons/line/LineHeartPlusIcon'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import LineKidIcon from 'components/Luxkit/Icons/line/LineKidIcon'
import LineLocationPinAltIcon from 'components/Luxkit/Icons/line/LineLocationPinAltIcon'
import LineMountainsRoadIcon from 'components/Luxkit/Icons/line/LineMountainsRoadIcon'
import LineNoFeesIcon from 'components/Luxkit/Icons/line/LineNoFeesIcon'
import LineOneWayFlightIcon from 'components/Luxkit/Icons/line/LineOneWayFlightIcon'
import LineParkingCircleIcon from 'components/Luxkit/Icons/line/LineParkingCircleIcon'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import LineReceiptIcon from 'components/Luxkit/Icons/line/LineReceiptIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import LineReturnFlightIcon from 'components/Luxkit/Icons/line/LineReturnFlightIcon'
import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import LineShieldCheckIcon from 'components/Luxkit/Icons/line/LineShieldCheckIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LineSightseeingIcon from 'components/Luxkit/Icons/line/LineSightseeingIcon'
import LineSkiLiftIcon from 'components/Luxkit/Icons/line/LineSkiLiftIcon'
import LineSpaCreditIcon from 'components/Luxkit/Icons/line/LineSpaCreditIcon'
import LineSyringeIcon from 'components/Luxkit/Icons/line/LineSyringeIcon'
import LineTagIcon from 'components/Luxkit/Icons/line/LineTagIcon'
import LineTaxiIcon from 'components/Luxkit/Icons/line/LineTaxiIcon'
import LineTennisBallIcon from 'components/Luxkit/Icons/line/LineTennisBallIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import LineUniversityIcon from 'components/Luxkit/Icons/line/LineUniversityIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import LineUtensilsIcon from 'components/Luxkit/Icons/line/LineUtensilsIcon'
import LineWellnessIcon from 'components/Luxkit/Icons/line/LineWellnessIcon'
import LineWifiIcon from 'components/Luxkit/Icons/line/LineWifiIcon'
import LineWineBeerIcon from 'components/Luxkit/Icons/line/LineWineBeerIcon'

const TOUR_V2_FINE_PRINT_CATEGORY_ICONS: Record<Tours.V2OfferFinePrintSection['title'], (props: any) => JSX.Element> = {
  accommodation: LineBedDoubleIcon,
  booking: LineTicketIcon,
  cancellations: LineBanIcon,
  dates: LineCalendarIcon,
  exclusions: LineFileMinusAltIcon,
  food: LineRestaurantIcon,
  health: LineHeartPlusIcon,
  inclusions: LineFilePlusAltIcon,
  information: LineInfoCircleIcon,
  surcharges: LineDollarSignAltIcon,
  transport: LineBusIcon,
  travel: LinePlaneDepartureIcon,
  vaccination: LineSyringeIcon,
  destination: LineLocationPinAltIcon,
}

export const getFinePrintSectionIcon = switchFunc(TOUR_V2_FINE_PRINT_CATEGORY_ICONS, LineInfoCircleIcon)

const TOUR_V2_FAQ_ITEM_ICONS: Record<string, (props: any) => JSX.Element> = {
  'The operator': LineAwardIcon,
  'The accommodation': LineBedIcon,
  'The experience': LineFileCheckAltIcon,
  'The service': LineShieldCheckIcon,
  'The group': LineUsersAltIcon,
}

export const getFAQItemIcon = switchFunc(TOUR_V2_FAQ_ITEM_ICONS, LineInfoCircleIcon)

const INCLUSION_ICONS: Record<
string,
(props: any) => JSX.Element
> = {
  apple: LineAppleIcon,
  bed_with_upwards_arrow: LineRoomUpgradeIcon,
  bus: LineBusIcon,
  car: LineCarIcon,
  check: LineCheckIcon,
  childs_face: LineKidIcon,
  childs_face_with_plus: LineChildWithPlusIcon,
  cloche: LineClochIcon,
  clock_with_check: LineClockWithTickIcon,
  clock_with_plus: LineDelayIcon,
  coffee_cup: LineCoffeeIcon,
  crossed_utensils: LineUtensilsIcon,
  crossed_utensils_with_plus: LineDiningCreditIcon,
  dollar_sign_in_circle: LineDollarSignIcon,
  dollar_sign_in_circle_with_line_through: LineNoFeesIcon,
  drink_tray: LineDrinksTrayIcon,
  golf: LineGolfIcon,
  lotus: LineWellnessIcon,
  lotus_with_plus: LineSpaCreditIcon,
  martini_glass: LineGlassMartiniAltIcon,
  p_in_circle: LineParkingCircleIcon,
  plane_with_arrow: LineOneWayFlightIcon,
  plane_with_arrows: LineReturnFlightIcon,
  plane_with_plus: LineFlightCreditIcon,
  receipt: LineReceiptIcon,
  seat_with_plane: LineChairWithAirplaneIcon,
  ski_lift: LineSkiLiftIcon,
  tag: LineTagIcon,
  taxi: LineTaxiIcon,
  tennis_ball: LineTennisBallIcon,
  wifi_signal: LineWifiIcon,
  wine_glass: LineGlassMartiniIcon,
  wine_glass_and_beer_glass: LineWineBeerIcon,
  wine_glass_with_clock: LineHappyHourIcon,
  line_bed: LineBedIcon,
  line_room_upgrade: LineRoomUpgradeIcon,
  line_sightseeing: LineSightseeingIcon,
  line_university: LineUniversityIcon,
  line_mountains_road: LineMountainsRoadIcon,
  line_iconic_landmark: LineUniversityIcon,
  line_ship: LineShipIcon,
  line_check: LineCheckIcon,
}

export const getInclusionIcon = switchFunc(INCLUSION_ICONS, LineCheckIcon)
