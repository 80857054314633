import React, { ComponentProps } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import BaseButton from './BaseButton'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { LargeButtonTypography, MediumButtonTypography, SmallButtonTypography } from '../Typography/ButtonTypography'

const UnderlineText = styled.span`
  border-bottom: 1px solid currentColor;
`

const StyledBaseButton = styled(BaseButton)`
  &.kind-primary, &.kind-secondary {
    min-width: ${rem(80)};
  }

  > svg:first-of-type,
  > svg:last-of-type {
    flex-shrink: 0;
    height: var(--button-icon-size, auto);
    width: var(--button-icon-size, auto);
  }

  &.has-icon-start {
    > :first-child {
      margin-right: ${rem(8)};
    }
  }

  &.has-icon-end {
    > :last-child {
      margin-left: ${rem(8)};
    }
  }

  &.size-small {
    --button-size: ${rem(32)};
    --button-icon-size: ${rem(16)};
    --button-padding: ${rem(8)};

    ${SmallButtonTypography}
  }

  &.size-medium {
    --button-size: ${rem(40)};
    --button-icon-size: ${rem(20)};
    --button-padding: ${rem(12)};

    ${MediumButtonTypography}
  }

  &.size-large {
    --button-size: ${rem(48)};
    --button-icon-size: ${rem(24)};
    --button-padding: ${rem(16)};

    ${LargeButtonTypography}
  }

  &.shape-round {
    --button-border-radius: ${props => props.theme.borderRadius.round};
  }

  &.shape-square {
    --button-border-radius: ${props => props.theme.borderRadius.S};
  }

  &.fit-flex {
    display: flex;
    width: 100%;
  }

  &.fit-full-width {
    width: 100%;
  }

  &.fit-mobile-full-width {
    width: 100%;

    ${mediaQueryUp.tablet} {
      width: auto;
    }
  }

  &.fit-desktop-full-width {
    ${mediaQueryUp.tablet} {
      width: 100%;
    }
  }

  &.outdent-left {
    transform: translateX(calc(var(--button-padding) * -1));
  }

  &.outdent-right {
    transform: translateX(var(--button-padding));
  }

  @media print {
    display: none;
  }
`

export const TEXT_BUTTON_FITS = ['auto', 'full-width', 'flex', 'mobile-full-width', 'desktop-full-width'] as const
export const TEXT_BUTTON_DEFAULT_FIT = TEXT_BUTTON_FITS[0]
export const TEXT_BUTTON_SIZES = ['small', 'medium', 'large'] as const
export const TEXT_BUTTON_DEFAULT_SIZE = TEXT_BUTTON_SIZES[1]

interface Props extends ComponentProps<typeof BaseButton> {
  /**
   * Buttons *require* children, it doesn't make sense without having content in the button
   */
  children: React.ReactNode;
  /**
   * Determines how a buttons sizing behaviour will work
   * Supports specifying only for certain breakpoints, e.g. desktop/mobile
   **/
  fit?: typeof TEXT_BUTTON_FITS[number]
  /** An icon element that will go at the start (often the 'left' side) of the button */
  startIcon?: React.ReactElement
  /** An icon element that will go at the end (often the 'right' side) of the button */
  endIcon?: React.ReactElement
  /**
   * Outdent, or the inverse of indent, removes the 'padding' around the buttons in the direct chosen
   * so they can visually align with the 'edge'. Mostly used for tertiary buttons that have no border/background
   * */
  outdent?: 'left' | 'right'
  /** The general shape of the button, square will have a minor border radius, round will be fully round */
  shape?: 'round' | 'square'
  /** Determines the height/padding of the button as well as the size of icons if they are provided */
  size?: typeof TEXT_BUTTON_SIZES[number]
  /** ID applied to the button to target for testing purposes */
  'data-testid'?: string;
}

/**
 * Primary and secondary kinds will apply a minimum width.
 */
const TextButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    className,
    fit = TEXT_BUTTON_DEFAULT_FIT,
    startIcon = null,
    outdent,
    endIcon = null,
    shape = 'square',
    size = TEXT_BUTTON_DEFAULT_SIZE,
    ...baseButtonProps
  } = props

  const hasAnyIcon = !!(startIcon || endIcon)
  const addUnderline = !hasAnyIcon && baseButtonProps.kind === 'tertiary'

  return <StyledBaseButton
    {...baseButtonProps}
    ref={ref}
    className={cn(
      className,
      `size-${size}`,
      `fit-${fit}`,
      `shape-${shape}`,
      {
        'has-icon-start': !!startIcon,
        'has-icon-end': !!endIcon,
      },
      outdent ? `outdent-${outdent}` : undefined,
    )}
  >
    {startIcon}
    {addUnderline && <UnderlineText>{children}</UnderlineText>}
    {!addUnderline && children}
    {endIcon}
  </StyledBaseButton>
})

TextButton.displayName = 'TextButton'

export default TextButton
