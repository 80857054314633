import { useMemo } from 'react'
import moment from 'moment'

import { calculateNights } from 'tripPlanner/utils'
import { filterPromotionsByTravelPeriod } from 'lib/hotels/bedbankUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'

function filterRatePromotions(promotions: Array<App.BedbankPromotion>, checkinDate?: moment.Moment, checkoutDate?: moment.Moment) {
  if (!checkinDate || !checkoutDate) {
    return {}
  }

  const numberOfNights = calculateNights(checkinDate, checkoutDate)
  const checkIn = moment(checkinDate).format(ISO_DATE_FORMAT)
  const checkOut = moment(checkoutDate).format(ISO_DATE_FORMAT)
  return promotions.filter((promo) => {
    return promo.los <= numberOfNights && filterPromotionsByTravelPeriod(promo, checkIn, checkOut)
  }).reduce((acc, promo) => {
    for (const rateId of promo.restrictToRates) {
      acc[rateId] = promo
    }
    return acc
  }, {})
}

export function useBedbankRoomRatePromotions(promotions: Array<App.BedbankPromotion>, checkinDate?: moment.Moment, checkoutDate?: moment.Moment) {
  return useMemo(() => filterRatePromotions(promotions, checkinDate, checkoutDate), [promotions, checkinDate, checkoutDate])
}

export function useBedbankPromotions(promotions: Array<App.BedbankPromotion>, checkinDate?: moment.Moment, checkoutDate?: moment.Moment) {
  return useMemo(() => {
    return !(checkinDate && checkoutDate) && promotions.length > 0 ? promotions : []
  }, [promotions, checkinDate, checkoutDate])
}

export function useBedbankSearchTilePromotions(promotions: Array<App.BedbankPromotion>, rate?: App.BedbankRate, checkinDate?: string, checkoutDate?: string) {
  return useMemo<Array<App.BedbankPromotion>>(() => {
    if (rate) {
      const ratesPromo = filterRatePromotions(promotions, moment(checkinDate), moment(checkoutDate))
      return ratesPromo[rate.id] ? [ratesPromo[rate.id]] : []
    }

    return promotions
  }, [promotions, rate, checkinDate, checkoutDate])
}
