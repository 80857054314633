import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getAirportsSearch } from 'api/flights'
import { last } from 'lib/array/arrayUtils'
import { MappedPlace, mapCityLevelPlace } from 'lib/search/mappingUtils'
import { getPlaceByLatLong } from 'api/search'
import { SelfDescribingJson } from '@snowplow/browser-tracker'
import { createFlightItemContext } from 'analytics/snowplow/events'
import moment from 'moment'

async function getFlightCheckoutItem(state: App.State, item: App.Checkout.FlightItem) {
  const departingJourneyId = item.departing?.journeyId
  const returningJourneyId = item.returning?.journeyId

  const departingJourneys = Object.values(state.flights.searchV2Flights).flatMap(flight => flight?.departingFares)
  const returningJourneys = Object.values(state.flights.searchV2Flights).flatMap(flight => flight?.returningFares)
  const departingJourney = departingJourneyId ? departingJourneys.find(journey => journey?.id === departingJourneyId) : undefined
  const returningJourney = returningJourneyId ? returningJourneys.find(journey => journey?.id === returningJourneyId) : undefined

  const departingData = departingJourney ? getFlightData(departingJourney) : undefined

  const departingCost = departingJourney?.price.all.totalFare
  const returningCost = returningJourney?.price.all.totalFare
  const price = (departingCost || 0) + (returningCost || 0)

  let cities: {
    originCity: string | undefined,
    destinationCity: string | undefined
  } = { originCity: undefined, destinationCity: undefined }

  if (departingData?.departingAirportName && departingData?.arrivalAirportName) {
    cities = await getCityByAirportName(departingData?.departingAirportName, departingData?.arrivalAirportName)
  }

  const { categoryId, productId } = getCategoryAndProductId(item.itemType)

  return comluxgroup.createItem_1_1_0({
    offerId: 'flight',
    categoryId,
    productId,
    duration: getFlightDurationInDays(departingData?.departureDate, departingData?.arrivalDate),
    travelStart: departingData?.departureDate,
    travelEnd: departingData?.arrivalDate,
    numberOfAdults: item.occupants.adults,
    numberOfChildren: item.occupants.children,
    numberOfInfants: item.occupants.infants,
    childrenAges: item.occupants.childrenAge,
    currency: state.geo.currentCurrency,
    destinationCountry: departingData?.arrivalCountry,
    originCountry: departingData?.departingCountry,
    originCity: cities.originCity,
    destinationCity: cities.destinationCity,
    typeId: item.fareType.toLowerCase(),
    itemId: item.itemId,
    metadata: JSON.stringify({
      originAirportCode: departingData?.departingAirport,
      arrivalAirportCode: departingData?.arrivalAirport,
    }),
    price,
  })
}

export default getFlightCheckoutItem

function getFlightData(journey: App.JourneyV2) {
  const firstFlight = journey.flightGroup.flights[0]
  const lastFlight = last(journey.flightGroup.flights)

  const arrivalAirportName = lastFlight.arrivalAirportName
  const arrivalAirport = lastFlight.arrivalAirport
  const departingAirport = firstFlight.departureAirport
  const departingAirportName = firstFlight.departureAirportName

  return {
    arrivalAirportName,
    arrivalAirport,
    departingAirport,
    departingAirportName,
    departureDate: firstFlight.departingDate,
    arrivalDate: lastFlight.arrivalDate,
    departingCountry: firstFlight.departureCountry,
    arrivalCountry: lastFlight.arrivalCountry,
  }
}

function getFlightDurationInDays(departureDate?: string, arrivalDate?: string) {
  // default to 1 for one way
  const calculatedDuration = departureDate && arrivalDate ? moment(arrivalDate).diff(departureDate, 'days') : 1
  return calculatedDuration && calculatedDuration > 0 ? calculatedDuration : 1
}

async function getCityByAirportName(departingAirportName: string, arrivalAirportName:string) {
  const [departingAirportData, arrivalAirportData] = await Promise.all([getAirportsSearch(departingAirportName), getAirportsSearch(arrivalAirportName)])

  const departingLongitude = departingAirportData[0]?.lg
  const departingLatitude = departingAirportData[0]?.lt
  const arrivalLongitude = arrivalAirportData[0]?.lg
  const arrivalLatitude = arrivalAirportData[0]?.lt

  const getCitiesPromises: Array<Promise<MappedPlace | undefined> | undefined> = [undefined, undefined]

  if (departingLongitude && departingLatitude) {
    getCitiesPromises[0] = getPlaceByLatLong(departingLatitude, departingLongitude).then(mapCityLevelPlace)
  }

  if (arrivalLongitude && arrivalLatitude) {
    getCitiesPromises[1] = getPlaceByLatLong(arrivalLatitude, arrivalLongitude).then(mapCityLevelPlace)
  }

  const places = await Promise.all(getCitiesPromises)

  return {
    originCity: places[0]?.city,
    destinationCity: places[1]?.city,
  }
}

export async function journeyToSnowplowItemContext(journey: App.JourneyV2, currency: string, occupancy: App.Occupants): Promise<SelfDescribingJson> {
  const firstFlight = journey.flightGroup.flights[0]
  const lastFlight = last(journey.flightGroup.flights)

  let cities: {
    originCity: string | undefined,
    destinationCity: string | undefined
  } = { originCity: undefined, destinationCity: undefined }

  if (firstFlight?.departureAirportName && lastFlight?.arrivalAirportName) {
    try {
      cities = await getCityByAirportName(firstFlight?.departureAirportName, lastFlight?.arrivalAirportName)
    } catch {}
  }

  const flightItemContext = createFlightItemContext({
    offerId: 'flight',
    originCity: cities?.originCity,
    destinationCity: cities?.destinationCity,
    airline: journey.carrier,
    arrivalAirportCode: lastFlight.arrivalAirport,
    departureDate: firstFlight.departingDate,
    duration: getFlightDurationInDays(firstFlight.departingDate, lastFlight.arrivalDate),
    fareType: journey.fareType.toLowerCase(),
    numberOfAdults: occupancy.adults,
    numberOfChildren: occupancy.children,
    numberOfInfants: occupancy.infants,
    childrenAges: occupancy.childrenAge,
    originAirportCode: firstFlight.departureAirport,
    pricePerAdult: journey.price.adult.totalFare!,
    arrivalDate: lastFlight.arrivalDate,
    destinationCountry: lastFlight.arrivalCountry,
    originCountry: firstFlight.departureCountry,
    currency,
  })

  return flightItemContext
}
