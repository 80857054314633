import OfferBadge from 'components/Common/OfferBadge'
import Pane from 'components/Common/Pane'
import { SPLIT_VIEW_BREAKPOINTS } from 'components/Pages/HotelSearchPage/HotelSplitView/constants'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'

export const Main = styled(Pane)`
  display: grid;
  position: relative;
  z-index: 0;
  ${mediaQueryUp.desktop} {
    grid-template-rows: 1fr;
    grid-template-columns: 40% 1fr;
  }

  &.mapTile {
    @media screen and (min-width: ${SPLIT_VIEW_BREAKPOINTS.medium}px) {
      grid-template-rows: 1fr;
      grid-template-columns: 40% 1fr;
    }
  }

  &.slim {
    ${mediaQueryUp.desktop} {
      grid-template-columns: 26% 1fr;
    }
    ${mediaQueryUp.largeDesktop} {
      grid-template-columns: 34% 1fr;
    }
  }
`

export const MainImageSegment = styled.div`
  position: relative;
  height: 100%;
  min-height: ${rem(172)};
  ${mediaQueryUp.tablet} {
    min-height: ${rem(280)};
  }
`

export const DetailSegment = styled.div`
  position: relative;
  padding: ${rem(20)};
  width: 100%;
  height: 100%;
  &:not(.mapTile) {
    &:not(.slim) {
      ${mediaQueryUp.tablet} {
        justify-content: space-between;
        display: grid;
        grid-template-columns: 60% 1fr;
        min-height: ${rem(300)};
        column-gap: ${rem(20)};
      }

      &.flash {
        ${mediaQueryUp.tablet} {
          min-height: ${rem(360)};
        }
      }
    }

    &.slim {
      ${mediaQueryUp.desktop} {
        display: flex;
        justify-content: space-between;
        gap: ${rem(20)};
      }
    }
  }
  &.mapTile {
    @media screen and (min-width: ${SPLIT_VIEW_BREAKPOINTS.large}px) {
      justify-content: space-between;
      display: grid;
      grid-template-columns: 60% 1fr;
      min-height: ${rem(360)};
      gap: ${rem(8)};
    }
  }
`

export const StyledCSSBreakpoint = styled(CSSBreakpoint)`
  position: absolute;
  top: 0;
  left: ${rem(16)};
  right: 0;
`

export const OfferOverlayContainer = styled.div`
  position: absolute;
  top: ${rem(12)};
  left: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * + * {
    margin-top: ${rem(8)};
  }
`

export const StyledOfferBadge = styled(OfferBadge)`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
`

export const ViewBundleOfferWrapper = styled.div`
  margin-top: ${rem(16)};
  grid-column: 1 / 3;
`

export const GalleryButton = styled.div`
  pointer-events: auto;
`
