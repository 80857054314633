import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import {
  BOOK_ADDON, CREATE_DATES_REQUEST, FETCH_ORDER_DATES_REQUESTS,
  FETCH_TOURV2_SNAPSHOT_DETAILS,
  SUBMIT_ORDER_TRAVELLERS,
  FETCH_PAYMENT_METHOD_STRIPE,
  USER_LOGOUT, FETCH_MERCHANT_FEE_DETAILS,
  SAVE_DRAFT_ORDER_TRAVELLERS,
} from 'actions/apiActionConstants'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS, TOUR_TRAVELLER_DETAILS_RESET_SUBMISSION,
} from 'actions/actionConstants'

export const initialOrderState: App.OrderState = {
  isSaveAsDraftTravellerDetails: false,
  isSubmittingTravellerDetails: false,
  travellerDetailsSubmission: null,
  travellerDetailsSubmissionError: null,
  bookingAddon: false,
  bookingAddonError: '',
  datesRequests: [],
  fetchingDatesRequests: false,
  tourV2SnapshotDetails: {},
  tourV2SnapshotsLoading: {},
  orderPaymentMethod: undefined,
  fetchingOrderPaymentMethod: false,
  merchantFeeDetails: [],
}

const apiRequests = reducerSwitch<App.OrderState>({
  [SUBMIT_ORDER_TRAVELLERS]: () => ({
    isSaveAsDraftTravellerDetails: false,
    isSubmittingTravellerDetails: true,
    travellerDetailsSubmission: null,
    travellerDetailsSubmissionError: null,
  }),
  [SAVE_DRAFT_ORDER_TRAVELLERS]: () => ({
    isSaveAsDraftTravellerDetails: true,
    isSubmittingTravellerDetails: true,
    travellerDetailsSubmission: null,
    travellerDetailsSubmissionError: null,
  }),
  [BOOK_ADDON]: () => ({
    bookingAddon: true,
    bookingAddonError: undefined,
  }),
  [FETCH_ORDER_DATES_REQUESTS]: () => ({
    fetchingDatesRequests: true,
  }),
  [FETCH_TOURV2_SNAPSHOT_DETAILS]: (state, action) => ({
    tourV2SnapshotsLoading: {
      ...state.tourV2SnapshotsLoading,
      [action.reservationId]: true,
    },
  }),
  [FETCH_PAYMENT_METHOD_STRIPE]: () => ({
    fetchingOrderPaymentMethod: true,
  }),
  [FETCH_MERCHANT_FEE_DETAILS]: () => ({
    merchantFeeDetails: undefined,
  }),
})

const apiSuccesses = reducerSwitch<App.OrderState>({
  [SUBMIT_ORDER_TRAVELLERS]: (state, action) => ({
    isSaveAsDraftTravellerDetails: false,
    isSubmittingTravellerDetails: false,
    travellerDetailsSubmission: action.data.result,
    travellerDetailsSubmissionError: null,
  }),
  [SAVE_DRAFT_ORDER_TRAVELLERS]: (state, action) => ({
    isSaveAsDraftTravellerDetails: false,
    isSubmittingTravellerDetails: false,
    travellerDetailsSubmission: action.data.result,
    travellerDetailsSubmissionError: null,
  }),
  [BOOK_ADDON]: () => ({
    bookingAddon: false,
  }),
  [USER_LOGOUT]: () => initialOrderState,
  [CREATE_DATES_REQUEST]: (state: App.OrderState) => ({
    datesRequests: state.datesRequests,
  }),
  [FETCH_ORDER_DATES_REQUESTS]: (state: App.OrderState, action) => ({
    fetchingDatesRequests: false,
    datesRequests: action.data,
  }),
  [FETCH_TOURV2_SNAPSHOT_DETAILS]: (state, action) => ({
    tourV2SnapshotDetails: {
      ...state.tourV2SnapshotDetails,
      [action.reservationId]: action.data,
    },
    tourV2SnapshotsLoading: {
      ...state.tourV2SnapshotsLoading,
      [action.reservationId]: false,
    },
  }),
  [FETCH_PAYMENT_METHOD_STRIPE]: (_state: App.OrderState, action) => ({
    fetchingOrderPaymentMethod: false,
    orderPaymentMethod: action.data,
  }),
  [FETCH_MERCHANT_FEE_DETAILS]: (_state, action) => ({
    merchantFeeDetails: action.data,
  }),
})

const apiFailures = reducerSwitch<App.OrderState>({
  [SUBMIT_ORDER_TRAVELLERS]: (state, action) => ({
    isSaveAsDraftTravellerDetails: false,
    isSubmittingTravellerDetails: false,
    travellerDetailsSubmission: null,
    travellerDetailsSubmissionError: action.error,
  }),
  [SAVE_DRAFT_ORDER_TRAVELLERS]: (state, action) => ({
    isSaveAsDraftTravellerDetails: false,
    isSubmittingTravellerDetails: false,
    travellerDetailsSubmission: null,
    travellerDetailsSubmissionError: action.error,
  }),
  [BOOK_ADDON]: (state, action) => ({
    bookingAddon: false,
    bookingAddonError: action.error,
  }),
  [FETCH_ORDER_DATES_REQUESTS]: () => ({
    fetchingDatesRequests: false,
  }),
  [FETCH_PAYMENT_METHOD_STRIPE]: () => ({
    fetchingOrderPaymentMethod: false,
  }),
  [FETCH_MERCHANT_FEE_DETAILS]: () => ({
    merchantFeeDetails: undefined,
  }),
})

export default createReducer<App.OrderState>(initialOrderState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [TOUR_TRAVELLER_DETAILS_RESET_SUBMISSION]: () => ({
    travellerDetailsSubmission: null,
    travellerDetailsSubmissionError: null,
    isSubmittingTravellerDetails: undefined,
  }),
})
