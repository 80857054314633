import { arrayToObject } from 'lib/array/arrayUtils'
import config from 'constants/config'
import switchFunc from 'lib/function/switchFunc'

const dollarPrefixes = {
  AUD: 'A',
  CAD: 'CA',
  HKD: 'HK',
  MOP: 'MOP',
  NZD: 'NZ',
  SGD: 'S',
  TWD: 'NT',
  USD: 'US',
}

let regions: Array<App.RegionSummary> = []
// Potentially this module gets loaded before the config is initialised,
// so we need to be able to recompute this mapping if the config changes.
let formattingLocaleForRegionCode: { [key: string]: string } = {}

function getFormattingLocaleForRegionCode(regionCode: string) {
  if (config.regions !== regions) {
    regions = config.regions
    formattingLocaleForRegionCode = arrayToObject(config.regions, region => region.code, region => region.currencyFormattingLocale)
  }
  return formattingLocaleForRegionCode[regionCode]
}

/** Any currency-specific adjustments we want to make to the format that Intl gives us */
const formatTweak = switchFunc<(str: string) => string>({
  // JPY: Replace the "full-width yen sign" with the normal yen sign,
  // because the full-width one looks kinda crappy in our font
  JPY: str => str.replace('￥', '¥'),
  // ILS: Remove RTL/LTR marks, and put the symbol on the left
  ILS: str => {
    if (str.endsWith('₪')) {
      return '₪ ' + str.replace(/[\u200e\u200f\s₪]/g, '')
    }
    // If it wasn't in the format we expected then best to just leave it alone
    return str
  },
}, str => str)

export function formatDynamicAmount(
  amount: number,
  currencyCode: string,
  regionCode: string,
  numberOptions?: Intl.NumberFormatOptions,
): string {
  if (amount % 1) {
    return formatPreciseAmount(amount, currencyCode, regionCode, numberOptions)
  } else {
    return formatRoundedAmount(amount, currencyCode, regionCode, numberOptions)
  }
}

// Old versions of Safari don't support the "narrowSymbol" currency display
function envSupportsNarrowSymbol() {
  try {
    // eslint-disable-next-line no-new
    new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'narrowSymbol',
    })
    return true
  } catch (e) {
    return false
  }
}

const currencyDisplay = envSupportsNarrowSymbol() ? 'narrowSymbol' : 'symbol'

export function formatPreciseAmount(
  amount: number,
  currencyCode: string,
  regionCode: string,
  numberOptions?: Intl.NumberFormatOptions,
): string {
  const formatter = new Intl.NumberFormat(getFormattingLocaleForRegionCode(regionCode), {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay,
    ...numberOptions,
  })
  return formatTweak(currencyCode)(formatter.format(amount))
}

export function formatRoundedAmount(
  amount: number,
  currencyCode: string,
  regionCode: string,
  numberOptions?: Intl.NumberFormatOptions,
): string {
  const formatter = new Intl.NumberFormat(getFormattingLocaleForRegionCode(regionCode), {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...numberOptions,
  })
  return formatTweak(currencyCode)(formatter.format(amount))
}

export function addDollarType(formattedAmount: string, currencyCode: string): string {
  if (!config.multiCurrency) {
    // only brands that have multiple currencies need to add the dollar type, it's unnecessary otherwise
    return formattedAmount
  }

  if (formattedAmount.match(/^\$/)) {
    return ''.concat(dollarPrefixes[currencyCode] || '').concat(formattedAmount)
  }

  if (formattedAmount.match(/^\-\$/)) {
    return '-'.concat(dollarPrefixes[currencyCode] || '').concat(formattedAmount.slice(1))
  }

  if (formattedAmount.match(/^\+\$/)) {
    return '+'.concat(dollarPrefixes[currencyCode] || '').concat(formattedAmount.slice(1))
  }

  return formattedAmount
}

export function formatDynamicAmountWithDollarType(
  amount: number,
  currencyCode: string,
  regionCode: string,
  numberOptions?: Intl.NumberFormatOptions,
): string {
  return addDollarType(
    formatDynamicAmount(amount, currencyCode, regionCode, numberOptions),
    currencyCode,
  )
}

export function formatPreciseAmountWithDollarType(
  amount: number,
  currencyCode: string,
  regionCode: string,
  numberOptions?: Intl.NumberFormatOptions,
): string {
  return addDollarType(
    formatPreciseAmount(amount, currencyCode, regionCode, numberOptions),
    currencyCode,
  )
}

export function formatRoundedAmountWithDollarType(
  amount: number,
  currencyCode: string,
  regionCode: string,
  numberOptions?: Intl.NumberFormatOptions,
): string {
  return addDollarType(
    formatRoundedAmount(amount, currencyCode, regionCode, numberOptions),
    currencyCode,
  )
}

const oneAtStart = /^1\s*/
const oneAtEnd = /\s*1$/

export function blankAmount(currencyCode: string, regionCode: string): string {
  return formatRoundedAmount(1, currencyCode, regionCode).replace(oneAtStart, '— ').replace(oneAtEnd, ' —')
}

export function blankAmountWithDollarType(currencyCode: string, regionCode: string): string {
  return formatRoundedAmountWithDollarType(1, currencyCode, regionCode).replace(oneAtStart, '— ').replace(oneAtEnd, ' —')
}

export interface CurrencyFormatOptions extends Pick<Intl.NumberFormatOptions, 'notation' | 'maximumFractionDigits' | 'minimumFractionDigits' | 'signDisplay' | 'style'> {
  /**
   * @default dynamicDollar
   */
  format?: 'dynamicDollar' | 'dynamic' | 'preciseDollar' | 'precise' | 'blank' | 'blankDollar' | 'rounded' | 'roundedDollar'
}

export function formatCurrency(
  value: number,
  currencyCode: string,
  regionCode: string,
  options: CurrencyFormatOptions = {},
) {
  const format = options.format ?? 'dynamicDollar'
  const defaultedOptions = {
    ...options,
    signDisplay: options.signDisplay ?? 'auto',
  }

  // Show blank amount if `value` can't be parsed as a number (e.g. null or undefined)
  if (isNaN(parseFloat(value as any))) {
    return format.includes('Dollar') ?
      blankAmountWithDollarType(currencyCode, regionCode) :
      blankAmount(currencyCode, regionCode)
  }

  switch (format) {
    case 'blank':
      return blankAmount(currencyCode, regionCode)
    case 'blankDollar':
      return blankAmountWithDollarType(currencyCode, regionCode)
    case 'dynamic':
      return formatDynamicAmount(value, currencyCode, regionCode, defaultedOptions)
    case 'dynamicDollar':
      return formatDynamicAmountWithDollarType(value, currencyCode, regionCode, defaultedOptions)
    case 'preciseDollar':
      return formatPreciseAmountWithDollarType(value, currencyCode, regionCode, defaultedOptions)
    case 'precise':
      return formatPreciseAmount(value, currencyCode, regionCode, defaultedOptions)
    case 'rounded':
      return formatRoundedAmount(value, currencyCode, regionCode, defaultedOptions)
    case 'roundedDollar':
      return formatRoundedAmountWithDollarType(value, currencyCode, regionCode, defaultedOptions)
    default:
      return null
  }
}
