import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import OfferBadge from 'components/Common/OfferBadge'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import { ImageParams } from 'components/Common/ResponsiveImage'
import ProductPaletteProvider, { ProductPalette } from 'contexts/ProductPaletteContext'
import HeroHotelOfferDetails from './HeroHotelOfferDetails'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import HeroOfferTileWrapper from './HeroOfferTileWrapper'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { GalleryButton } from '../SearchOfferTiles/SearchTileStyles'
import { useAppSelector } from 'hooks/reduxHooks'
import { isMobile } from 'lib/web/deviceUtils'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const MainImageSegment = styled.div`
  aspect-ratio: 3 / 2;

  ${mediaQueryUp.tablet} {
    aspect-ratio: 16 / 9;
  }

  ${mediaQueryUp.desktop} {
    aspect-ratio: unset;
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
}

const imageParams: ImageParams = {
  mobileAspectRatio: '3:2',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '19:9',
  mobileWidth: '75vw',
  tabletWidth: '55vw',
  desktopWidth: '55vw',
  largeDesktopWidth: '764px',
  quality: 'good',
}

function HeroHotelOfferTile(props: Props) {
  const {
    offer,
    eagerLoadFirstImage,
  } = props

  const isMobileDevice = useAppSelector(state => isMobile(state.config.userAgent.osName))
  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))
  // Swiping through the offer tiles is tough when the image carousel can also swipe. So turn off the image carousel for mobile devices
  const images = useMemo(() => isMobileDevice ? [offer.images[0]] : offer.images, [isMobileDevice, offer.images])
  const paletteValue: ProductPalette = luxPlusEnabled && !!offer.luxPlus?.access ? 'loyalty' : isUltraLuxOffer(offer) ? 'ultralux' : 'default'

  const onEvent = useContext(OfferListEventsContext)

  const handleImageLoaded = useCallback(() => {
    onEvent(OfferListEvents.offerReady)
  }, [onEvent])

  return (
    <ProductPaletteProvider palette={paletteValue}>
      <HeroOfferTileWrapper>
        <MainImageSegment>
          <ImageCarousel
            topLeft={<OfferPartnershipLabel offer={offer} />}
            topRight={<Group direction="horizontal" gap={4}>
              <TripGuard>
                <CSSBreakpoint max="tablet">
                  <GalleryButton>
                    <BookmarkButton offer={offer} />
                  </GalleryButton>
                </CSSBreakpoint>
              </TripGuard>
              {offer.badge && <OfferBadge badge={offer.badge} />}
            </Group>}
            images={images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            onImageLoad={handleImageLoaded}
          />
        </MainImageSegment>
        <HeroHotelOfferDetails offer={offer} />
      </HeroOfferTileWrapper>
    </ProductPaletteProvider>
  )
}

export default React.memo(HeroHotelOfferTile)
