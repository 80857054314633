import { FlightDropdownOption } from 'components/Flights/types'
import React from 'react'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import Group from 'components/utils/Group'

interface Props {
  options: Array<FlightDropdownOption>;
  selectedOption?: FlightDropdownOption;
  className?: string;
  'data-testid'?: string;
  onOptionChange: (option: FlightDropdownOption) => void;
}

function FareRadio(props: Props) {
  const { options, selectedOption, onOptionChange } = props

  return (
    <Group direction="horizontal" verticalAlign="center" gap={16}>
      {options.map((option) => (
        <RadioInput
          key={option.value}
          checked={selectedOption?.value === option.value}
          onClick={() => onOptionChange(option)}
          name="fareType"
          value={option.value}
          size="medium"
        >
          {option.label}
        </RadioInput>
      ))}
    </Group>
  )
}

export default FareRadio
