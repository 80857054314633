import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { fetchProductSubscription } from 'actions/LoyaltyActions'
import FormatCurrency from 'components/Common/FormatCurrency'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { useAppDispatch } from 'hooks/reduxHooks'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import IconLoadingBox from 'components/Luxkit/Icons/IconLoadingBox'
import LuxPlusBenefitsListLoadingSkeleton from '../LuxPlusBenefitsListLoadingSkeleton'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import LuxPlusSubscriptionCancellationModal from '../LuxPlusSubscriptionCancellationModal'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import offerPageURL from 'lib/offer/offerPageURL'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import LuxPlusBenefitsList from '../LuxPlusBenefitsList'
import { formatCurrency } from 'lib/format/formatCurrencyIntl'
import GeoContext from 'contexts/geoContext'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineUsdCircleIcon from 'components/Luxkit/Icons/line/LineUsdCircleIcon'
import LuxPlusSubscriptionTsAndCs from '../LuxPlusSubscriptionTsAndCs'
import LineLockIcon from 'components/Luxkit/Icons/line/LineLockIcon'
import { connect } from 'react-redux'
import { isLEOffer, isTourV2Offer } from 'lib/offer/offerTypes'
import { INCLUSIONS_TITLE_LUXPLUS } from 'constants/inclusions'
import { capitalise } from 'lib/string/stringUtils'
import { roundLuxPlusInclusionsValue } from 'luxPlus/lib/utils'

type InheritedModalProps = Pick<React.ComponentProps<typeof Modal>,
  'onPrimaryActionClick' |
  'primaryActionText' |
  'isSecondaryActionHidden' |
  'isPrimaryActionHidden'
>

interface MappedProps {
  subscription: App.ProductSubscription;
}

interface Props extends MappedProps, InheritedModalProps {
  title?: string;
  subTitle?: React.ReactNode;
  offer?: App.Offer | App.OfferSummary | Tours.TourV2Offer | Tours.TourV2OfferSummary;
  saveAmount?: number;
  type?: App.LuxPlusLabelModalType;
  hideSaveAmountPlus?: boolean;
  providerPage?: App.LuxPlusLabelPage;
  memberInclusionsAmount?: number;
}

interface Copy {
  title: string;
  subTitle: React.ReactNode;
  firstBenefit?: string;
  saveAmountBenefit?: string;
  memberInclusionsAmountBenefit?: string;
}

function LuxPlusBenefitsModal(props: Props) {
  const {
    offer,
    saveAmount,
    type,
    subscription,
    hideSaveAmountPlus,
    providerPage,
    memberInclusionsAmount = 0,
  } = props
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const { resolve } = useModalElementContext()
  const isLuxPlusLandingPage = providerPage === 'landing-page'
  const isOfferPage = providerPage === 'offer-page'

  const offerUrl = offerPageURL(offer)
  const geo = useContext(GeoContext)
  const formattedSaveAmount = formatCurrency(saveAmount ?? 0, geo.currentCurrency, geo.currentRegionCode, { format: 'rounded' })
  const roundedMemberInclusionsAmount = roundLuxPlusInclusionsValue(memberInclusionsAmount)
  const formattedMemberInclusionsAmount = formatCurrency(roundedMemberInclusionsAmount, geo.currentCurrency, geo.currentRegionCode, { format: 'rounded' })
  const hasMemberInclusions = !!offer?.luxPlus?.hasMemberInclusions
  const saveAmountSuffix = hideSaveAmountPlus ? '' : '+'

  const onShowCancellationPolicy = useCallback(() => {
    showModal(<LuxPlusSubscriptionCancellationModal offer={subscription.offers?.[0]} />)
  }, [showModal, subscription.offers])

  useEffect(() => {
    dispatch(fetchProductSubscription())
  }, [dispatch])

  const copy = useMemo<Copy>(() => {
    switch (type) {
      case 'early-access-offer':
        return {
          title: `Join ${LUXURY_PLUS.PROGRAM_NAME} to get early access to this offer and more!`,
          subTitle: <>Become a member of our VIP travel club for just <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />/year to unlock instant benefits when you book {isLEOffer(offer) ? <b>{offer?.property?.name}</b> : 'this hotel'} today. Cancel anytime!</>,
          firstBenefit: 'Secure your room and dates before the general public',
          saveAmountBenefit: `Save ${formattedSaveAmount}${saveAmountSuffix} on this booking with member pricing`,
        }
      case 'member-price-offer':
        return {
          title: `Join ${LUXURY_PLUS.PROGRAM_NAME} to save ${formattedSaveAmount}${saveAmountSuffix} on this offer and more!`,
          subTitle: <>Become a member of our VIP travel club for just <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />/year to unlock instant benefits when you book {isLEOffer(offer) ? <b>{offer?.property?.name}</b> : 'this hotel'} today. Cancel anytime!</>,
          saveAmountBenefit: `Save ${formattedSaveAmount}${saveAmountSuffix} on this booking`,
        }
      case 'bonus-inclusions':
        return {
          title: `Join ${LUXURY_PLUS.PROGRAM_NAME} to enjoy ${INCLUSIONS_TITLE_LUXPLUS} for this offer and more!`,
          subTitle: <>Become a member of our VIP travel club for <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" /> per year to unlock {INCLUSIONS_TITLE_LUXPLUS} when you book {isLEOffer(offer) ? <b>{offer?.property?.name}</b> : 'this hotel'}.</>,
          memberInclusionsAmountBenefit: hasMemberInclusions && memberInclusionsAmount > 0 ? `${formattedMemberInclusionsAmount}+ of ${INCLUSIONS_TITLE_LUXPLUS}, revealed at checkout` : `${capitalise(INCLUSIONS_TITLE_LUXPLUS)} revealed at checkout`,
        }
      case 'member-price-offer-tour':
        return {
          title: `Join ${LUXURY_PLUS.PROGRAM_NAME} to save ${formattedSaveAmount}${saveAmountSuffix}/person on this offer and more!`,
          subTitle: <>Become a member of our VIP travel club for just <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />/year to unlock instant benefits when you book {isTourV2Offer(offer) ? <b>{offer?.name}</b> : 'this tour'} today. Cancel anytime!</>,
          saveAmountBenefit: `Save ${formattedSaveAmount}${saveAmountSuffix}/person on this booking`,
        }
      case 'options-available-tour':
        return {
          title: `Join ${LUXURY_PLUS.PROGRAM_NAME} to save on select tour options on this offer and more!`,
          subTitle: <>Become a member of our VIP travel club for just <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />/year to unlock instant benefits when you book {isTourV2Offer(offer) ? <b>{offer?.name}</b> : 'this tour'} today. Cancel anytime!</>,
        }
      default:
        return {
          title: props.title ?? '',
          subTitle: props.subTitle ?? '',
          saveAmountBenefit: formattedSaveAmount ? `Save ${formattedSaveAmount}${saveAmountSuffix} on this booking with member pricing` : '',
        }
    }
  }, [formattedMemberInclusionsAmount, formattedSaveAmount, hasMemberInclusions, memberInclusionsAmount, offer, props?.subTitle, props?.title, saveAmountSuffix, subscription.offers, type])

  return <Modal
      primaryActionText={props.primaryActionText ?? 'View offer'}
      isPrimaryActionHidden={props.isPrimaryActionHidden || !offerUrl || isOfferPage}
      primaryActionTo={props.onPrimaryActionClick ? undefined : offerUrl}
      isSecondaryActionHidden={props.isSecondaryActionHidden ?? isLuxPlusLandingPage}
      secondaryActionText={`Learn more about ${LUXURY_PLUS.PROGRAM_NAME}`}
      secondaryActionTo={`/${LUXURY_PLUS_PATHS.LANDING_PAGE}`}
      title={copy.title}
      onPrimaryActionClick={props.onPrimaryActionClick}
    >
    {subscription.error && <BodyText variant="medium">There was an error loading the prices details. Please try again later.</BodyText>}
    {subscription.fetching &&
      <Group direction="vertical" gap={24}>
        <Group direction="vertical" gap={12}>
          <BodyText variant="medium">
            <TextLoadingBox width="100%" />
            <TextLoadingBox width="40ch" />
          </BodyText>
          <Group direction="vertical" gap={8}>
            <BodyTextBlock variant="medium" startIcon={<IconLoadingBox colour="highlight-secondary" />}>
              <TextLoadingBox colour="highlight-secondary" width="16ch" />
            </BodyTextBlock>
            <BodyTextBlock variant="medium" startIcon={<IconLoadingBox colour="highlight-secondary" />}>
              <TextLoadingBox colour="highlight-secondary" width="24ch" />
            </BodyTextBlock>
          </Group>
          <BodyText variant="medium">
            <TextLoadingBox width="40ch" />
          </BodyText>
          <LuxPlusBenefitsListLoadingSkeleton benefitsCounts={6} />
          <BodyTextBlock variant="medium" endIcon={<IconLoadingBox />}>
            <TextLoadingBox colour="highlight-secondary" width="16ch" />
          </BodyTextBlock>
        </Group>
        <BodyText variant="small">
          <TextLoadingBox width="100%" />
          <TextLoadingBox width="24ch" />
        </BodyText>
      </Group>}
    {subscription.offers &&
      <Group direction="vertical" gap={24}>
        <Group direction="vertical" gap={12}>
          {copy.subTitle && <BodyText variant="medium">
            {copy.subTitle}
          </BodyText>}
          <Group direction="vertical" gap={8}>
            {copy.firstBenefit && <BodyTextBlock variant="medium" colour="highlight-secondary" weight="bold" startIcon={<LineLockIcon />}>
              {copy.firstBenefit}
            </BodyTextBlock>}
            {!!saveAmount && copy.saveAmountBenefit && <BodyTextBlock variant="medium" colour="highlight-secondary" weight="bold" startIcon={<LineUsdCircleIcon />}>
              {copy.saveAmountBenefit}
            </BodyTextBlock>}
            {hasMemberInclusions && copy.memberInclusionsAmountBenefit && <BodyTextBlock variant="medium" colour="highlight-secondary" weight="bold" startIcon={<LineUsdCircleIcon />}>
              {copy.memberInclusionsAmountBenefit}
            </BodyTextBlock>}
            <BodyTextBlock variant="medium" colour="highlight-secondary" weight="bold" startIcon={<LineStarIcon />}>
              Special offer: No one-off joining fee if you book today (save <FormatCurrency format="rounded" value={subscription.offers[0].joinFee} />)
            </BodyTextBlock>
          </Group>
          <BodyText variant="medium" weight="bold">
            You’ll also enjoy these unlimited benefits all year round:
          </BodyText>
          <LuxPlusBenefitsList kind="long" benefits={subscription.offers[0].benefits} />
          <TextLink weight="bold" variant="highlight" size="medium" endIcon={<SolidInfoCircleIcon colour="neutral-one" />} onClick={onShowCancellationPolicy}>
            Cancel renewal anytime
          </TextLink>
        </Group>
        <LuxPlusSubscriptionTsAndCs onTsAndCsLinkClick={resolve} type={offer?.luxPlus.access === 'memberOnly' ? undefined : 'withPrice'} />
      </Group>}
  </Modal>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  subscription: state.loyalty.products.subscription,
})

export default connect(mapStateToProps)(LuxPlusBenefitsModal)
