import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'
import { useScreenSizeUp } from 'hooks/useScreenSize'
import { take } from 'lib/array/arrayUtils'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import CarouselCardSmallLoadingSkeleton from './OfferCards/CarouselCardLoadingSkeleton'
import CarouselLockedCardLoadingSkeleton from './OfferCards/CarouselLockedCardLoadingSkeleton'
import OfferCard from './OfferCards/OfferCard'
import OfferListOfferCard from './OfferCards/OfferListOfferCard'
import OfferListLoadingOfferCard from './OfferListTiles/OfferListLoadingOfferCard'

interface Props extends PropsWithChildren {
  inView: boolean
  offerList: App.OfferList
  /**
   * The style of card that'll be displayed
   * @default card
   */
  tileStyle?: 'card' | 'card-medium' | 'locked-card' | 'hero' | 'search' | 'frontpage'
  /**
   * Pass additional query params to the offer URL generated for each card
   * NOTE: This only works for tileStyle="card" at the moment
   */
  additionalOfferUrlQuery?: string;
  /**
   * Number of cards to be shown at max
   * @default 20
   */
  limit?: number
  /**
   * @default limit
   */
  tabletLimit?: number | 'default'
}

const DEFAULT_LIMIT = 20

function OfferListCarouselTrack(props: Props) {
  const {
    children,
    inView,
    offerList,
    tileStyle = 'card',
    additionalOfferUrlQuery,
    limit = DEFAULT_LIMIT,
    tabletLimit = limit,
  } = props

  const [listLimit, setListLimit] = useState<number>(limit)

  const isTabletScreenSizeAndHigher = useScreenSizeUp('tablet')
  useEffect(() => {
    if (isTabletScreenSizeAndHigher && tabletLimit) {
      setListLimit(tabletLimit === 'default' ? DEFAULT_LIMIT : tabletLimit)
    } else {
      setListLimit(limit)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletScreenSizeAndHigher])

  const offerIds = useMemo(() => take(offerList.offerIds, listLimit), [listLimit, offerList.offerIds])

  const isCardStyle = tileStyle === 'card' || tileStyle === 'locked-card' || tileStyle === 'card-medium'

  return <LoaderPlayStateContext.Provider value={inView ? 'running' : 'paused'}>
    {isCardStyle && <>
      {offerList.fetching && <>
        {(tileStyle === 'card' || tileStyle === 'card-medium') && <>
          <CarouselCardSmallLoadingSkeleton size={tileStyle === 'card' ? 'small' : 'medium'} />
          <CarouselCardSmallLoadingSkeleton size={tileStyle === 'card' ? 'small' : 'medium'} />
          <CarouselCardSmallLoadingSkeleton size={tileStyle === 'card' ? 'small' : 'medium'} />
          <CarouselCardSmallLoadingSkeleton size={tileStyle === 'card' ? 'small' : 'medium'} />
        </>}
        {tileStyle === 'locked-card' && <>
          <CarouselLockedCardLoadingSkeleton />
          <CarouselLockedCardLoadingSkeleton />
          <CarouselLockedCardLoadingSkeleton />
          <CarouselLockedCardLoadingSkeleton />
        </>}
      </>}
      {offerIds.map((id, index) => (
        <OfferCard
          tileStyle={tileStyle === 'locked-card' ? 'locked' : 'default'}
          key={id}
          id={id}
          idx={index}
          // eager load the first two, this 'preps' the carousel for mobile view
          lazyLoad={index > 1}
          size={tileStyle === 'card-medium' ? 'medium' : 'small'}
          additionalOfferUrlQuery={additionalOfferUrlQuery}
        />
      ))}
    </>}
    {!isCardStyle && <>
      {offerList.fetching && <>
        <OfferListLoadingOfferCard tileStyle={tileStyle} />
        <OfferListLoadingOfferCard tileStyle={tileStyle} />
      </>}
      {offerIds.map((id, index) => <OfferListOfferCard
        key={id}
        offerId={id}
        tileStyle={tileStyle}
        position={index}
      />)}
    </>}
    {children}
  </LoaderPlayStateContext.Provider>
}

export default OfferListCarouselTrack
