import cn from 'clsx'
import React, {
  useCallback,
  useContext, useEffect, useMemo,
  useRef,
  useState,
} from 'react'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import LocationFloatingPanel from '../Panels/LocationFloatingPanel'
import SearchFormField from '../../SearchFormField/SearchFormField'
import {
  CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL,
  CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_SEARCH_TYPES, CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
} from 'constants/carHire'
import useStepsController from 'components/SearchV2/Hooks/useStepsController'
import useDateSearchProps from 'components/SearchV2/Hooks/useDateSearchProps'
import DateFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/DateFloatingPanel'
import SearchFormFieldGroup from '../../SearchFormField/SearchFormFieldGroup'

import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import useToggle from 'hooks/useToggle'
import CarHireTimeSelect from 'components/CarHire/CarHireSearchBar/CarHireTimeSelect'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { EmptyArray } from 'lib/array/arrayUtils'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { addMonths, shortTimeHourFormatAmPm } from 'lib/datetime/dateUtils'
import SlideDown from 'components/Common/SlideDown/SlideDown'
import Caption from 'components/Luxkit/Typography/Caption'
import CarHireSearchDriversAgeCategorySelect from 'components/CarHire/CarHireSearchBar/CarHireSearchDriversAgeCategorySelect'
import styled from 'styled-components'
import { rem } from 'polished'
import CarHireDriversAgeInput from 'components/CarHire/CarHireSearchBar/CarHireDriversAgeInput'
import config from 'constants/config'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import BusinessTravellerSelectCarHireMobile from 'businessTraveller/components/select-traveller/BusinessTravellerSelectCarHireMobile'
import FlightSearchTravellerSelectField from 'components/Flights/FlightsSearch/SearchWidget/FlightTravellerSelect/FlightSearchTravellerSelectField'

interface Props {
  onSearch: () => void;
  formId?: string;
}

// Margin doesn't animate on slide down so we're left with
// double gap when this is closed. So fake it with a padding top instead
// this padding will get animated correctly
const DropOffLocationSlide = styled(SlideDown)`
  margin: 0;

  >:first-child {
    padding-top: ${rem(12)};
  }
`

const DriversAgeContainer = styled.div`
  opacity: 1;
  transition: opacity 0.2s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`

const DriversAgeInput = styled(CarHireDriversAgeInput)`
  width: ${rem(118)};
`

enum SearchSteps {
  LOCATION = 'location',
  SECONDARY_LOCATION = 'secondaryLocation',
  DATE = 'date',
  PICKUP_TIME = 'pickUpTime',
  RETURN_TIME = 'returnTime',
}

const activeDateToggleButton = {
  id: DATE_SEARCH_OPTION_IDS.SPECIFIC,
  label: 'Specific dates',
}

function getLocationTitle(activeStep: SearchSteps | undefined, differentLocation?: boolean) {
  if (!differentLocation) {
    return CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL
  }
  if (activeStep === SearchSteps.LOCATION) {
    return CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL
  }
  return CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL
}
const maxDate = addMonths(new Date(), 18)

function CarHireTabContent(props: Props) {
  const { onSearch, formId } = props

  const {
    checkinDate: pickUpDate,
    checkoutDate: returnDate,
    searchItem,
    secondarySearchItem,
    pickUpTime,
    dropOffTime,
    driversAgeCategory,
  } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const [showPickUpTimeSelect, toggleShowPickUpTimeSelect] = useToggle(false)
  const [showReturnTimeSelect, toggleShowReturnTimeSelect] = useToggle(false)
  const [differentLocations, setDifferentLocations] = useState<boolean>(secondarySearchItem && searchItem?.format.mainText !== secondarySearchItem?.format.mainText)

  useEffect(() => {
    if (secondarySearchItem && searchItem?.format.mainText !== secondarySearchItem?.format.mainText) {
      setDifferentLocations(true)
    }
  }, [searchItem, secondarySearchItem])

  const setPickUpTimeDispatch = useCallback((time: string) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_PICKUP_TIME, pickupTime: time })
    toggleShowPickUpTimeSelect()
  }, [searchDispatch, toggleShowPickUpTimeSelect])

  const setReturnTimeDispatch = useCallback((time: string) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_RETURN_TIME, dropOffTime: time })
    toggleShowReturnTimeSelect()
  }, [searchDispatch, toggleShowReturnTimeSelect])

  const stepsValidation = useMemo(() => ({
    [SearchSteps.LOCATION]: searchItem,
    ...differentLocations && { [SearchSteps.SECONDARY_LOCATION]: differentLocations && secondarySearchItem },
    [SearchSteps.DATE]: pickUpDate && returnDate,
  }),
  [searchItem, differentLocations, secondarySearchItem, pickUpDate, returnDate])

  const [activeStep, setActiveStep] = useState<SearchSteps>()
  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = () => {
    if (formRef.current?.reportValidity()) {
      onSearch()
    }
  }

  const onDifferentLocationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.currentTarget.checked
    if (!nextValue) {
      searchDispatch({ type: GlobalSearchStateActions.UNSET_SECONDARY_SEARCH_ITEM })
    }
    setDifferentLocations(nextValue)
  }, [searchDispatch])

  const {
    goToNextStep,
    goToPreviousStep,
    closeActiveStep,
    isSearchEnabled,
  } = useStepsController({ activeStep, setActiveStep, stepsValidation, onSearch: onSubmit })

  const dateSearchProps = useDateSearchProps({ onAnytimeDatesSelected: goToNextStep })

  const openPickUpSearchStep = useCallback(() => setActiveStep(SearchSteps.LOCATION), [setActiveStep])
  const openDropOffSearchStep = useCallback(() => setActiveStep(SearchSteps.SECONDARY_LOCATION), [setActiveStep])
  const openDateSearchStep = useCallback(() => setActiveStep(SearchSteps.DATE), [setActiveStep])

  const pickUpTimeFieldRef = useRef<HTMLButtonElement>(null)
  const returnTimeFieldRef = useRef<HTMLButtonElement>(null)

  const onSelectDriversAge = useCallback((nextCategory: App.CarHireDriverAgeCategory) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DRIVERS_AGE_CATEGORY, driversAgeCategory: nextCategory })
  }, [searchDispatch])

  const needsActualAge = driversAgeCategory === 1 || driversAgeCategory === 3

  return (
    <div>
      <VerticalSpacer as="form" id={formId} ref={formRef} gap={16} onSubmit={onSubmit}>
        <VerticalSpacer gap={12}>
          <SearchFormField
            label={differentLocations ? CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL : CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL}
            placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
            value={searchItem?.format.mainText}
            onClick={openPickUpSearchStep}
            required
          />
          <DropOffLocationSlide show={differentLocations} animateOpacity>
            <SearchFormField
              label={CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL}
              placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
              value={secondarySearchItem?.format.mainText}
              onClick={openDropOffSearchStep}
              required={differentLocations}
            />
          </DropOffLocationSlide>
          <CheckboxInput
            defaultChecked={differentLocations}
            onChange={onDifferentLocationChange}
          >
            Drop-off at a different location
          </CheckboxInput>
        </VerticalSpacer>

        <VerticalSpacer gap={12}>
          <SearchFormFieldGroup>
            <SearchFormField
              label="Pick-up date"
              value={pickUpDate ? pickUpDate.format(DMY_CASUAL_FORMAT) : undefined}
              placeholder="Start date"
              onClick={openDateSearchStep}
              required
            />
            <div style={{ position: 'relative' }}>
              <SearchFormField
                ref={pickUpTimeFieldRef}
                label="Time"
                placeholder="Start time"
                value={pickUpTime}
                displayValue={pickUpTime ? shortTimeHourFormatAmPm(new Date('1970-01-01T' + pickUpTime + 'Z'), true) : undefined}
                onClick={toggleShowPickUpTimeSelect}
                required
              />
              <CarHireTimeSelect
                open={showPickUpTimeSelect}
                toggleDropDown={toggleShowPickUpTimeSelect}
                dropdownTriggerRef={pickUpTimeFieldRef}
                onChange={setPickUpTimeDispatch}
                selectedTime={pickUpTime}
              />
            </div>
          </SearchFormFieldGroup>

          <SearchFormFieldGroup>
            <SearchFormField
              label="Drop-off date"
              value={returnDate ? returnDate.format(DMY_CASUAL_FORMAT) : undefined}
              placeholder="End date"
              onClick={openDateSearchStep}
              required
            />
            <div style={{ position: 'relative' }}>
              <SearchFormField
                ref={returnTimeFieldRef}
                label="Time"
                placeholder="End time"
                value={dropOffTime}
                displayValue={dropOffTime ? shortTimeHourFormatAmPm(new Date('1970-01-01T' + dropOffTime + 'Z'), true) : undefined}
                onClick={toggleShowReturnTimeSelect}
                required
              />
              <CarHireTimeSelect
                open={showReturnTimeSelect}
                toggleDropDown={toggleShowReturnTimeSelect}
                dropdownTriggerRef={returnTimeFieldRef}
                onChange={setReturnTimeDispatch}
                selectedTime={dropOffTime}
              />
            </div>
          </SearchFormFieldGroup>
        </VerticalSpacer>
        {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerAccountGuard
            accountMode="business"
            employeeRole="BUSINESS_ADMIN"
            loading={<BusinessTravellerSelectLoadingSkeleton />}
            fallback={<FlightSearchTravellerSelectField disabled />}
          >
          <BusinessTravellerSelectCarHireMobile />
        </BusinessTravellerAccountGuard>}
        <CarHireSearchDriversAgeCategorySelect
          value={driversAgeCategory}
          onChange={onSelectDriversAge}
        />
        <DriversAgeContainer className={cn({ hidden: !needsActualAge })}>
          <VerticalSpacer as="label" gap={8}>
            <Caption variant="large">
              How old is the driver?
            </Caption>
            <DriversAgeInput />
          </VerticalSpacer>
        </DriversAgeContainer>
      </VerticalSpacer>

      {/* overlays */}
      <LocationFloatingPanel
        isOpen={activeStep === SearchSteps.LOCATION || activeStep === SearchSteps.SECONDARY_LOCATION}
        onClose={closeActiveStep}
        onGoBack={goToPreviousStep}
        onConfirm={goToNextStep}
        isSecondaryLocation={activeStep === SearchSteps.SECONDARY_LOCATION}
        searchTypes={CAR_HIRE_SEARCH_TYPES}
        typeaheadVertical="car_hire"
        placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
        title={getLocationTitle(activeStep, differentLocations)}
      />

      <DateFloatingPanel
        {...dateSearchProps}
        isOpen={activeStep === SearchSteps.DATE}
        maxDate={maxDate}
        onClose={closeActiveStep}
        onConfirm={isSearchEnabled ? onSearch : closeActiveStep}
        onGoBack={goToPreviousStep}
        confirmText={isSearchEnabled ? 'Search' : 'Apply'}
        dateToggleButtons={EmptyArray}
        activeDateToggleButton={activeDateToggleButton}
        title="When do you want to hire?"
        label="day"
        includeLastDayOnCount
      />
    </div>
  )
}

export default CarHireTabContent
