import request from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-svc-payment'
import { merchantFeeConfigMap, merchantFeeDetailsMap, payToSupportedBanksMap, savedStripeCardsMap } from './mappers/paymentMap'

export function createRazorpayOrder(data) {
  return request.post<any, any>('/api/razorpay/create_order', data, { credentials: 'include' })
}

export function getDepositDetails(orderId): Promise<App.Deposit | undefined> {
  return request.get(`/api/payments/deposit-details/${orderId}`, { credentials: 'include' })
    .then((data: any | null) => {
      return data ? {
        ...data,
        paid_amount: parseFloat(data.paid_amount),
        balance_amount: parseFloat(data.balance_amount),
        total_amount: parseFloat(data.total_amount),
      } : undefined
    })
}

export function createPaymentPlanForDepositBalance(data) {
  return request.post<any, any>('/api/payment_plan/deposit-balance', data, { credentials: 'include' })
}

export function createDepositPaymentPlanForOrderId(data) {
  return request.post<any, any>('/api/payment_plan/balance-deposit', data, { credentials: 'include' })
}

export function getInstalmentDetails(orderId: string): Promise<App.InstalmentDetails> {
  return request.get(`/api/payments/instalment-details/${orderId}`, { credentials: 'include' }) as any
}

export function createInstalmentPaymentPlanForOrderId(data) {
  return request.post<any, any>('/api/payment_plan/balance-instalment', data, { credentials: 'include' })
}

export function getReserveForZeroDetails(orderId: string): Promise<App.ReserveForZeroDetails> {
  return request.get(`/api/payments/deferred-payment-details/${orderId}`, { credentials: 'include' }) as any
}

export function createDeferredPaymentPlanForOrderId(data) {
  return request.post<any, any>('/api/payment_plan/deferred-payment', data, { credentials: 'include' })
}

export function getPaymentPlanGroup(payment_plan_group_id: string): Promise<App.ReserveForZeroDetails> {
  return request.get(`/api/payment_plan/${payment_plan_group_id}`, { credentials: 'include' }) as any
}

export type StripeSavedCardsV2Response = paths['/api/payments/v2/stripe/payment-methods/saved-cards']['get']['responses']['200']['content']['application/json']

export async function getSavedStripeCards(currency: string) {
  return request.get<StripeSavedCardsV2Response>(`/api/payments/v2/stripe/payment-methods/saved-cards?currency=${currency}`, { credentials: 'include' })
    .then(response => savedStripeCardsMap(response.result))
}

interface GoCardlessBillingData {
  email: string;
  givenName: string;
  lastName: string;
  amount: number;
  currency: string;
  description?: string;
  orderId: string;
  transactionKey?: string;
}

interface GoCardlessBillingPostData {
  amount: number;
  currency: string;
  description?: string;
  customer: {
    email: string;
    givenName: string;
    familyName: string;
  };
  bankDetails?: any;
  redirectUri?: string;
  exitUri?: string;
  metadata: {
    orderId: string;
    transactionKey?: string;
  }
}

export function createGoCardlessBillingRequest(data: GoCardlessBillingData): Promise<string> {
  const postData: GoCardlessBillingPostData = {
    amount: data.amount,
    currency: data.currency,
    metadata: {
      orderId: data.orderId,
      transactionKey: data.transactionKey,
    },
    description: data.description,
    customer: {
      email: data.email,
      familyName: data.lastName,
      givenName: data.givenName,
    },
  }
  return request.post<App.ApiResponse<{ flowId: string }>, GoCardlessBillingPostData>(
    '/api/payments/v2/gocardless/billing-requests',
    postData,
    { credentials: 'include' },
  ).then(response => {
    return response.result.flowId
  })
}

export type MerchantFeeConfigResponse = paths['/api/payments/v2/merchant-fees/config']['get']['responses']['200']['content']['application/json']

export async function getMerchantFeeConfig(): Promise<Array<App.MerchantFeeConfig>> {
  return request.get<MerchantFeeConfigResponse>('/api/payments/v2/merchant-fees/config', { credentials: 'include' })
    .then(response => merchantFeeConfigMap(response.result))
}

export type MerchantFeeDetailsResponse = paths['/api/payments/v2/merchant-fees/orders/{orderId}/details']['get']['responses']['200']['content']['application/json']

export async function getMerchantFeeDetails(orderId: string): Promise<Array<App.MerchantFeeDetails>> {
  return request.get<MerchantFeeDetailsResponse>(`/api/payments/v2/merchant-fees/orders/${orderId}/details`, { credentials: 'include' })
    .then(response => merchantFeeDetailsMap(response.result))
}

export type PaymentEventLogRequest = paths['/api/payments/v2/frontend-event-logger/events']['post']['parameters']['body']['payload']

export async function postPaymentEventLog(data: PaymentEventLogRequest): Promise<void> {
  return request.post<void, PaymentEventLogRequest>('/api/payments/v2/frontend-event-logger/events', data, { credentials: 'include' })
}

export type PayToSupportedBanksResponse = paths['/api/payments/v2/stripe/payto/supported-banks']['get']['responses']['200']['content']['application/json']

export async function getPayToSupportedBanks(): Promise<Array<App.PayToSupportedBank>> {
  return request.get<PayToSupportedBanksResponse>('/api/payments/v2/stripe/payto/supported-banks', { credentials: 'include' })
    .then(response => payToSupportedBanksMap(response.result))
}

export function deleteSavedCard(customerId, cardId) {
  return request.post(`/api/stripe/${customerId}/delete-card`, {
    card_id: cardId,
  }, {
    credentials: 'include',
  })
}

export function deleteSavedStripeCardV2(cardId: string, currency: string, region: string) {
  return request.delete(`/api/payments/v2/stripe/payment-methods/saved-cards/${cardId}?currency=${currency}&region=${region}`, {
    credentials: 'include',
  })
}

export function deleteSavedStripeCard(customerId, cardId, currency) {
  return request.post(`/api/stripe/${customerId}/delete-stripe-card`, {
    card_id: cardId,
    currency,
  }, {
    credentials: 'include',
  })
}

export type SherlogRequest = paths['/api/payments/v2/sherlog/log-requests']['post']['parameters']['body']['payload']

export async function postSherLogRequest(data: SherlogRequest): Promise<void> {
  return request.post<void, SherlogRequest>('/api/payments/v2/sherlog/log-requests', data, { credentials: 'include' })
}

export type DepositConfigResponse = paths['/api/payments/v2/payment-config/deposit']['get']['responses']['200']['content']['application/json']

export async function getDepositConfig(): Promise<App.DepositConfig> {
  return request
    .get<DepositConfigResponse>('/api/payments/v2/payment-config/deposit', {
      credentials: 'include',
    })
    .then((response) => {
      return response.result
    })
}

type StripeOffSessionCardByRegionAndPurposeIdResponse = paths['/api/payments/v2/stripe/off-session-cards/region/{region}/purpose/{purposeId}']['get']['responses']['200']['content']['application/json']

export async function getStripeOffSessionCardByRegionAndPurposeId(region: string, purposeId: string): Promise<{ paymentMethod: string }> {
  return request.get<StripeOffSessionCardByRegionAndPurposeIdResponse>(`/api/payments/v2/stripe/off-session-cards/region/${region}/purpose/${purposeId}`, { credentials: 'include' })
    .then((response) => {
      return response.result
    })
}
