/**
 * DO NOT IMPORT COLOURS FROM THIS FILE DIRECTLY.
 * These primitive colours are set up to be used as theme.palette configuration only.
 */
export const BLUE: App.PrimitiveColourRange = {
  50: '#F2F7FF',
  100: '#DBE9FF',
  200: '#BBD7FF',
  300: '#8BC0FF',
  400: '#5DA4FA',
  500: '#3688F0',
  600: '#376DD3',
  700: '#304DAB',
  800: '#1F3579',
  900: '#0F1C47',
}

export const PURPLE: App.PrimitiveColourRange = {
  50: '#E7E7F9',
  100: '#CFCEF3',
  200: '#B7B6ED',
  300: '#9E9DE6',
  400: '#8685E0',
  500: '#6B6ADA',
  600: '#5C5AB6',
  700: '#494891',
  800: '#37366D',
  900: '#16162C',
}

export const GOLD: App.PrimitiveColourRange = {
  50: '#EBE9E3',
  100: '#D7D3C7',
  200: '#C4BDAB',
  300: '#B0A78F',
  400: '#9B9171',
  500: '#867C63',
  600: '#6F6852',
  700: '#595342',
  800: '#433E31',
  900: '#2D2921',
}

export const BRONZE: App.PrimitiveColourRange = {
  50: '#EDE7DE',
  100: '#DACFBE',
  200: '#C8B79D',
  300: '#B59F7D',
  400: '#A3875C',
  500: '#8C744F',
  600: '#746042',
  700: '#5D4D35',
  800: '#463A27',
  900: '#2F271A',
}

export const RED: App.PrimitiveColourRange = {
  50: '#F9DCE0',
  100: '#F3BAC0',
  200: '#ED97A1',
  300: '#E67482',
  400: '#E05262',
  500: '#DA3146',
  600: '#B62738',
  700: '#911F2D',
  800: '#6D1822',
  900: '#491016',
}

export const CRIMSON: App.PrimitiveColourRange = {
  50: '#FCE2E6',
  100: '#FAC4CE',
  200: '#F7A7B5',
  300: '#F4899C',
  400: '#F26C84',
  500: '#EF4E6B',
  600: '#C74159',
  700: '#9F3447',
  800: '#782736',
  900: '#501A24',
}

export const ORANGE: App.PrimitiveColourRange = {
  50: '#FBEFE2',
  100: '#F6DFC4',
  200: '#F2CFA7',
  300: '#EDBE89',
  400: '#E9AE6C',
  500: '#E49F4E',
  600: '#BE8441',
  700: '#986934',
  800: '#724F27',
  900: '#4C351A',
}

export const LIME_GREEN: App.PrimitiveColourRange = {
  50: '#BFE9C8',
  100: '#9FDEAD',
  200: '#80D292',
  300: '#60C777',
  400: '#40BC5B',
  500: '#20B140',
  600: '#1B9335',
  700: '#15762B',
  800: '#105920',
  900: '#0B3B15',
}

export const TEAL: App.PrimitiveColourRange = {
  50: '#D5FDF4',
  100: '#AAFAE9',
  200: '#80F8DE',
  300: '#55F5D2',
  400: '#2BF3C7',
  500: '#00F1BD',
  600: '#00C89D',
  700: '#00A07D',
  800: '#00785E',
  900: '#00503F',
}

export const BLACK: App.PrimitiveColourRange = {
  50: '#FFFFFF',
  100: '#E3E3E3',
  200: '#C6C6C6',
  300: '#AAAAAA',
  400: '#8E8E8E',
  500: '#717171',
  600: '#555555',
  700: '#393939',
  800: '#1C1C1C',
  900: '#000000',
}

export const NAVY: App.PrimitiveColourRange = {
  50: '#FFFFFF',
  100: '#F7F7F8',
  200: '#EFEFF0',
  300: '#DFDFE1',
  400: '#AFB0B5',
  500: '#7E8188',
  600: '#4A4E58',
  700: '#363A45',
  800: '#24272E',
  900: '#121317',
}
