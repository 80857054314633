import { get, remove, set } from 'lib/storage/versionedLocalStorageUtils'

const REDUX_CRUISE_CABIN_OPTIONS_STATE_KEY = 'cruiseCabinOptionsState'
const REDUX_CRUISE_CABIN_OPTIONS_STATE_VERSION = 1

export function getCruiseCabinOptionsState(): App.CruiseCabinPreferences {
  return get(REDUX_CRUISE_CABIN_OPTIONS_STATE_KEY, REDUX_CRUISE_CABIN_OPTIONS_STATE_VERSION)
}

export function saveCruiseCabinOptionsState(state: App.CruiseCabinPreferences) {
  set(REDUX_CRUISE_CABIN_OPTIONS_STATE_KEY, REDUX_CRUISE_CABIN_OPTIONS_STATE_VERSION, state)
}

export function clearCruiseCabinOptionsState() {
  remove(REDUX_CRUISE_CABIN_OPTIONS_STATE_KEY)
}
