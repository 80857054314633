import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import moment from 'moment'
import { CarHireSearchSortTypes } from './CarHireSearchPage/CarHireSearchPageSort'
import { sortBy } from 'lib/array/arrayUtils'
import { getAge } from 'lib/datetime/dateUtils'

export const CAR_HIRE_REQUEST_EXPIRATION_MS = 15 * 60 * 1000 // 15 minutes

export function getCarHireListKey(filters: App.CarHireOfferListFilters) {
  const tokens = [
    filters.driversAgeCategory,
    filters.dropOffDate,
    filters.dropOffTime,
    filters.pickUpDate,
    filters.pickUpTime,
    filters.pickUpLocationId,
    filters.dropOffLocationId,
    filters.driversAge,
  ]

  return tokens.join('-')
}

function mapParamsToSearchItem(id?: string | null, name?: string | null): App.SearchItem | undefined {
  if (id && name) {
    if (id.length === 3) {
      return {
        searchType: 'airport',
        airportCode: id,
        format: {
          mainText: name,
        },
      } as App.SearchAirport
    }

    return {
      searchType: 'destination',
      value: id,
      format: {
        mainText: name,
      },
      ...(id.includes('le') && {
        destinationType: 'city',
      }),
    }
  }
}

export function getCarHireSearchStateFromParams(queryParams: URLSearchParams): Partial<GlobalSearchState> {
  const ageCategory = queryParams.get('ageCategory')
  const pickUpItem = mapParamsToSearchItem(queryParams.get('pickUpId'), queryParams.get('pickUpName'))
  const dropOffItem = mapParamsToSearchItem(queryParams.get('dropOffId'), queryParams.get('dropOffName'))
  const pickUpDate = queryParams.get('pickUpDate')
  const dropoffDate = queryParams.get('dropOffDate')
  const age = queryParams.get('age')
  const isSameLocation = pickUpItem?.format.mainText === dropOffItem?.format.mainText

  return {
    searchItem: pickUpItem,
    secondarySearchItem: isSameLocation ? undefined : dropOffItem,
    checkinDate: pickUpDate ? moment(pickUpDate) : undefined,
    checkoutDate: dropoffDate ? moment(dropoffDate) : undefined,
    pickUpTime: queryParams.get('pickUpTime') ?? undefined,
    dropOffTime: queryParams.get('dropOffTime') ?? undefined,
    driversAgeCategory: ageCategory ? parseInt(ageCategory) as App.CarHireDriverAgeCategory : undefined,
    driversAge: age ? parseInt(age) : undefined,
  }
}

export function sortCarHireOffers(offers: Array<App.CarHireOffer>, sort: CarHireSearchSortTypes) {
  switch (sort) {
    case 'price.asc':
    case 'price.desc':
      return sortBy(offers, res => res.price, sort === 'price.asc' ? 'asc' : 'desc')
    default:
      return offers
  }
}

/**
 * Works out and returns the drivers age category based on the age provider
 * @param age The age to get the category for
 * @returns The category for the given age
 */
export function getCarHireAgeCategory(age: number): App.CarHireDriverAgeCategory {
  if (age <= 29) {
    return 1
  } else if (age >= 70) {
    return 3
  }
  return 2
}

export function isCarHireItemDobValid(dob: string, item: App.Checkout.CarHireItem): boolean {
  const age = getAge(dob, new Date(item.pickUpDate))
  const ageCategory = getCarHireAgeCategory(age)

  if (item.ageCategory === 2) {
    return ageCategory === item.ageCategory
  }

  return age === item.age
}

export function getCarHireLocationId(searchItem: App.SearchItem): string {
  if (searchItem.searchType === 'destination') {
    return searchItem.locationId || searchItem.value
  }
  return searchItem.value
}

export function getCarHireSearchType(searchItem: App.SearchItem): App.CarHireSearchTypes {
  if (searchItem.searchType === 'destination' && searchItem.destinationType !== 'city') {
    return 'destination'
  }
  return 'geo'
}

export function findCarHireListKey(records: Record<string, App.CarHireList>, key: string) {
  if (!key)
  { return null }

  const prefix = `${key.split('-').slice(0, -1).join('-')}-`

  for (const _key in records) {
    if (_key.startsWith(prefix))
    { return records[_key] }
  }

  return null
}
