import { tint, shade, transparentize } from 'polished'
import base from './base'

export const whiteLabelBaseDefaultPalette: App.Palette = {
  brand: {
    primary: {
      normal: '#147FD3',
      darkest: shade(0.24, '#147FD3'),
      dark: shade(0.16, '#147FD3'),
      light: tint(0.76, '#147FD3'),
      lightest: tint(0.86, '#147FD3'),
      hover: shade(0.16, '#147FD3'),
      active: shade(0.24, '#147FD3'),
    },
  },
  neutral: {
    default: {
      eight: '#FFFFFF',
      seven: tint(0.96, '#404040'),
      six: tint(0.92, '#404040'),
      six25: transparentize(0.25, tint(0.92, '#404040')),
      five: tint(0.84, '#404040'),
      five40: transparentize(0.4, tint(0.84, '#404040')),
      four: tint(0.52, '#404040'),
      three: tint(0.32, '#404040'),
      two: tint(0.1, '#404040'),
      one: '#404040',
      one20: transparentize(0.2, '#404040'),
      clear: transparentize(0.84, '#FFFFFF'),
    },
    control: {
      black100: '#000000',
      black25: transparentize(0.25, '#000000'),
      white16: transparentize(0.16, '#FFFFFF'),
      white24: transparentize(0.24, '#FFFFFF'),
    },
  },
  product: {
    exclusive: {
      foreground: '#A3875C',
      background: '#A3875C',
      contrast: '#FFFFFF',
    },
    lastMinute: {
      foreground: '#DA3146',
      background: '#DA3146',
      contrast: '#FFFFFF',
    },
    partner: {
      foreground: '#7E8188',
      background: '#7E8188',
      contrast: '#FFFFFF',
    },
    premium: {
      foreground: '#4A4E58',
      background: '#4A4E58',
      contrast: '#FFFFFF',
    },
    ultralux: {
      foreground: '#A9A187',
      background: '#A9A187',
      contrast: '#FFFFFF',
      active: shade(0.24, '#A9A187'),
      hover: shade(0.16, '#A9A187'),
    },
    loyalty: {
      foreground: '#314EAC',
      altForeground: '#314EAC',
      background: '#314EAC',
      contrast: '#FFFFFF',
      active: shade(0.24, '#314EAC'),
      altActive: shade(0.24, '#314EAC'),
      hover: shade(0.16, '#314EAC'),
      altHover: shade(0.16, '#314EAC'),
    },
  },
  messaging: {
    success: {
      normalForeground: '#28A429',
      normalBackground: '#28A429',
      normalContrast: '#FFFFFF',
      lightForeground: tint(0.9, '#28A429'),
      lightBackground: tint(0.9, '#28A429'),
      darkForeground: shade(0.16, '#28A429'),
      darkBackground: shade(0.16, '#28A429'),
      lightContrast: '#404040',
      darkContrast: '#FFFFFF',
    },
    warning: {
      normalForeground: '#FF8500',
      normalBackground: '#FF8500',
      lightForeground: tint(0.9, '#FF8500'),
      lightBackground: tint(0.9, '#FF8500'),
      darkForeground: shade(0.16, '#FF8500'),
      darkBackground: shade(0.16, '#FF8500'),
      normalContrast: '#404040',
      lightContrast: '#404040',
      darkContrast: '#FFFFFF',
    },
    urgency: {
      normalForeground: '#E70000',
      normalBackground: '#E70000',
      lightForeground: tint(0.9, '#E70000'),
      lightBackground: tint(0.9, '#E70000'),
      darkForeground: shade(0.16, '#E70000'),
      darkBackground: shade(0.16, '#E70000'),
      normalContrast: '#FFFFFF',
      lightContrast: '#404040',
      darkContrast: '#FFFFFF',
    },
    critical: {
      normalForeground: '#E70000',
      normalBackground: '#E70000',
      lightForeground: tint(0.9, '#E70000'),
      lightBackground: tint(0.9, '#E70000'),
      darkForeground: shade(0.16, '#E70000'),
      darkBackground: shade(0.16, '#E70000'),
      normalContrast: '#FFFFFF',
      lightContrast: '#404040',
      darkContrast: '#FFFFFF',
    },
    favourite: {
      normalForeground: '#EF4E6B',
      normalBackground: '#EF4E6B',
      lightForeground: tint(0.9, '#EF4E6B'),
      lightBackground: tint(0.9, '#EF4E6B'),
      darkForeground: shade(0.16, '#EF4E6B'),
      darkBackground: shade(0.16, '#EF4E6B'),
      normalContrast: '#FFFFFF',
      lightContrast: '#404040',
      darkContrast: '#FFFFFF',
    },
  },
  highlight: {
    primary: {
      normalForeground: '#00F1BD',
      normalBackground: '#00F1BD',
      normalActive: shade(0.24, '#00F1BD'),
      normalHover: shade(0.16, '#00F1BD'),
      lightForeground: '#E6FEF9',
      lightBackground: '#E6FEF9',
      normalContrast: '#404040',
      lightContrast: '#404040',
    },
    secondary: {
      normalForeground: '#01B18B',
      normalBackground: '#01B18B',
      lightForeground: '#E6F7F3',
      lightBackground: '#E6F7F3',
      normalActive: shade(0.24, '#01B18B'),
      normalContrast: '#FFFFFF',
      lightContrast: '#404040',
    },
    tertiary: {
      normalForeground: '#6B6ADA',
      normalBackground: '#6B6ADA',
      lightForeground: '#F0F0Fb',
      lightBackground: '#F0F0Fb',
      normalContrast: '#FFFFFF',
      lightContrast: '#6B6ADA',
    },
    loyalty: {
      normalForeground: '#47A9EB',
    },
  },
}

const whiteLabelBaseTheme: App.Theme = {
  ...base,
  typography: {
    heading: {
      one: {
        letterSpacing: '-0.5px',
        weight: 'bold',
      },
      oneAlt: {
        weight: 'bold',
      },
      two: {
        letterSpacing: '-0.5px',
        weight: 'bold',
      },
      twoAlt: {
        weight: 'bold',
      },
      three: {
        letterSpacing: '-0.5px',
        weight: 'bold',
      },
      threeAlt: {
        letterSpacing: '-0.5px',
        weight: 'bold',
      },
      four: {
        letterSpacing: '-0.5px',
        weight: 'bold',
      },
      five: {
        weight: 'bold',
      },
      six: {
        weight: 'bold',
      },
    },
  },
  borderRadius: {
    XS: '2px',
    S: '4px',
    M: '8px',
    L: '12px',
    round: '9999px',
  },
  font: {
    primary: {
      provider: 'google',
      name: 'IBM Plex Sans',
      family: '"IBM Plex Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
      weightString: '400,500,600',
      weight: {
        regular: '400',
        semiBold: '500',
        bold: '600',
        superBold: '600',
      },
    },
    secondary: {
      provider: 'google',
      name: 'Montserrat',
      family: 'Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif',
      weightString: '400,600',
      weight: {
        bold: '600',
        semiBold: '600',
        superBold: '600',
        regular: '400',
      },
    },
    secondaryAlternate: {
      provider: 'google',
      name: 'Montserrat',
      family: 'Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif',
      weightString: '400,600',
      weight: {
        bold: '600',
        semiBold: '600',
        superBold: '600',
        regular: '400',
      },
    },
  },
}

export default whiteLabelBaseTheme
