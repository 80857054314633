import NumberRating from 'components/Common/NumberRating'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import React from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import useBedbankRates from 'hooks/useBedbankRates'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import { useBedbankSearchTilePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import { isBedbankFlightBookingEnabled } from 'selectors/flightsSelectors'
import { useAppSelector } from 'hooks/reduxHooks'
import OfferTileLoggedInButtons from '../../Support/OfferTileLoggedInButtons'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import HeroBedbankPriceDetails from './HeroBedbankPriceDetails'
import useFlightPrice from 'hooks/useFlightPrice'
import BodyText from 'components/Luxkit/Typography/BodyText'
import PriceRowFlightPackage from 'components/Luxkit/PricePoints/PriceRowFlightPackage'
import WalledContent from 'components/Common/WalledContent'
import { useOfferRatePrice } from 'hooks/Offers/useOfferPrice'

const DetailContainer = styled(Group)`
  height: 100%;
  padding-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)} ${rem(32)} ${rem(32)} ${rem(32)};
  }
`

const HeaderActions = styled.div`
  display: grid;
  grid-template: "review save";
`

const SaveButtonWrapper = styled.div`
  grid-area: save;
  justify-self: end;
`

const ReviewWrapper = styled.div`
  grid-area: review;
`

const MinHeightGroup = styled(Group)`
  min-height: ${rem(300)};
  flex-grow: 1;
`

interface Props {
  offer: App.BedbankOfferSummary | App.BedbankOffer
}

function HeroBedbankOfferDetails(props: Props) {
  const { offer } = props
  const [{ hotelOnlyRates }] = useBedbankRates(offer.id)
  const rate = hotelOnlyRates[0]
  const bedbankFlightEnabled = useAppSelector(isBedbankFlightBookingEnabled)
  const flightsEnabled = bedbankFlightEnabled && offer.flights?.airportCode && offer.flights?.flightsEnabled

  const [flightPrice] = useFlightPrice(offer.flights?.airportCode, undefined, undefined, undefined, null, rate?.nights ?? offer?.sell?.los)

  const promotions = useBedbankSearchTilePromotions(offer.promotions, rate)
  const promotion = promotions.length > 0 ? promotions[0] : null

  const {
    property,
  } = offer

  const addressArray: Array<string> = []
  const { city, countryName } = property.address
  if (city) addressArray.push(city)
  if (countryName) addressArray.push(countryName)
  const location = property.locationTitleOverride ? property.locationTitleOverride : addressArray.join(', ')
  const showReviews = showUserReviewsRating(offer.property?.rating, offer.property?.reviewsTotal)

  const {
    totalPrice,
    totalWithFlights,
  } = useOfferRatePrice(offer, rate, { flightPrice }) || {}

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    align="none"
    fullWidth
  />

  return (
    <DetailContainer direction="vertical" gap={16} >
      <CSSBreakpoint min="desktop">
        <HeaderActions>
          {showReviews && (
            <ReviewWrapper>
              <NumberRating
                  variant="medium"
                  rating={offer.property.rating ?? 0}
                  total={offer.property.reviewsTotal}
                  ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined }
                />
            </ReviewWrapper>
          )}
          <TripGuard>
            <SaveButtonWrapper>
              <BookmarkButton offer={offer} />
            </SaveButtonWrapper>
          </TripGuard>
        </HeaderActions>
      </CSSBreakpoint>
      <MinHeightGroup direction="vertical" gap={16} tabletGap={24} verticalAlign="space-between">
        <Group direction="vertical" gap={4} horizontalAlign="start">
          <Caption variant="large" colour="neutral-one">
            {location}<Caption variant="large" colour="neutral-three" as="span"> · Hotel</Caption>
          </Caption>
          <Heading variant="heading6" lineClamp={3}>{property?.name ?? offer.name}</Heading>
          {showReviews && offer.property?.rating && <CSSBreakpoint max="tablet">
            <NumberRating
              inlineLabel
              variant="xs"
              rating={offer.property.rating}
              total={offer.property.reviewsTotal}
              ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
            />
          </CSSBreakpoint>}
          {promotion && <div>
            <ProductTypeLabel kind="plain" productType="limited_time_special" />
          </div>}
          <BodyText lineClamp={3} variant="medium" colour="neutral-one">
            {offer.tagline || offer.amenities}
          </BodyText>
        </Group>
        <WalledContent enforced={offer.walledGarden} hidePricing={offer.hidePricing} wall={wall}>
          <Group direction="vertical" gap={8}>
            <Group direction="horizontal" gap={8} horizontalAlign="space-between" verticalAlign="end">
              <HeroBedbankPriceDetails
                    rate={rate}
                    offer={offer}
                  />
              <CSSBreakpoint min="desktop">
                <TextButton kind="primary">
                  View offer
                </TextButton>
              </CSSBreakpoint>
            </Group>
            {flightsEnabled && !!totalPrice && <PriceRowFlightPackage
              product="hotel"
              price={totalWithFlights}
            />}
          </Group>
        </WalledContent>
      </MinHeightGroup>
    </DetailContainer>
  )
}

export default HeroBedbankOfferDetails
