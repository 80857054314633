export enum OptimizelyExperiments {
  searchPriceMapMarkers = 'search_price_map_markers',
  searchTypeaheadOpenSearch = 'search_typeahead_opensearch_v2',
  SearchFijiExpansion = 'search_fiji_expansion',
  paymentsFlightsMerchantFees = 'payments_flight_merchant_fees',
  tripExplore = 'trip_explore',
  paymentsBookingProtectionInternational = 'payments_booking_protection',
  searchMapSplitView = 'search_map_split_view',
  searchBroadSearchLocation = 'search_broad_search_location',
  searchFlightDeal = 'search_flight_deal',
  cruiseSearchNewAlgorithm = 'cruise_search_new_algorithm',
  searchMobileSlimUI = 'search_mobile_slim_ui',
  cruiseNewBookingFlow = 'cruise_new_cruisebank_booking_flow_may_2024',
  flightPreFillRecentSearch = 'flights_pre_fill_recent_search',
  isSupportAssistantEnabled = 'opex_support_assistant_customer_pool',
  searchFamilySegmentation = 'search_family_segmentation',
  paymentsHotelsDepositsAu = 'payments_hotels_deposits_au',
  paymentsHotelsDepositsNonAu = 'payments_hotels_deposits_non_au',
  lpcChangeDatesEnabled = 'opex_lpc_change_dates',
  nonFlashNextAlgo = 'search_non_flash_next_algo',
  pricePerNight = 'cro_price_per_night',
  lereGraphRecommendation = 'lere_graph_recommendation',
  lereSearchModel = 'lere_search_model',
  bedbankChangeDatesEnabled = 'opex_bedbank_change_dates',
  searchClientCheckAvailability = 'search_client_check_availability',
  searchClusterMapPins = 'search_cluster_map_pins',
  newMobileHotelOfferPage = 'cro_app_style_hotel_offer_page_booking_flow',
  croAppPromoAuEnabled = 'cro_app_promo_au',
  supportAssistantWidgetEnabled = 'opex_lesa_widget',
  expediaStyleLoginPrompt = 'expedia_style_login_prompt',
  bookingProtectionImprovedUiTest = 'cro_booking_protection_improved_ui_test',
  luxPlusCheckoutSavings = 'lux_plus_checkout_savings', // This is for high savings
  luxPlusCheckoutLowSavings = 'lux_plus_checkout_low_savings',
  emailChangeEnabled = 'opex_email_change',
  romanticLandingPage = 'romantic_properties_v1_thematic_landing_page_a_b_test',
  searchRecentSearchCarousel = 'search_recent_search_carousel',
  croTest = 'cro_3585_control_test',
  xCoverChangeDatesEnabled = 'opex_change_dates_with_xcover_insurance',
  redDotOnSignUpEnabled = 'cro_sign_up_dot',
  ghaLandingPageEnabled = 'gha_landing_page',
  signUpModalTitleEnabled = 'cro_sign_up_modal_without_save_up_to_70',
}

export enum OptimizelyFeatureFlags {
  luxPlusFeatureToggle = 'luxplus_feature_toggle',
  luxPlusToursToggle = 'luxplus_tours_toggle',
  isGoCardlessEnabled = 'payments_go_cardless',
  allowPhoneNumberLogin = 'allow_phone_number_login',
  promoAppBanner = 'app_banner_targeted_delivery',
  CSAgentsAutoQuote = 'cro_cs_agents_auto_quote',
  tourOptionalExtras = 'tours_optional_extras',
  tourReviewsEnabled = 'cro_tour_reviews',
  travelProtection = 'cro_travel_protection',
  cancellationOutsidePolicyEnabled = 'opex_cancellation_outside_refund_policy',
  tourOptionalExtraPostPurchase = 'tour_optional_extra_post_purchase',
  isBonusCreditEnabled = 'cro_cancellation_bonus_credit',
}
