import { capitalise } from 'lib/string/stringUtils'
import moment from 'moment'
import qs from 'qs'
import { without } from 'lib/array/arrayUtils'
import config from 'constants/config'
import { ExperienceSortTypes } from 'actions/ExperienceActions'
import { AnythingCategory } from 'constants/experience'
import * as ArrayUtils from 'lib/array/arrayUtils'

export function getExperienceDurationString(experience: App.ExperienceOffer) {
  const from = experience.duration.from
  const to = experience.duration.to
  if (from && to && from !== to) {
    return `From ${moment.duration(from).humanize()} to ${moment.duration(to).humanize()}`
  } else if (from && from === to) {
    return capitalise(moment.duration(to).humanize())
  } else if (!from && to) {
    return `Up to ${moment.duration(to).humanize()}`
  } else if (from && !to) {
    return `From ${moment.duration(from).humanize()}`
  }
  return 'Flexible'
}

type TotalSlots = {
  totalUsedSeatsOnServer: number;
  totalUsedSeatsOnCart: number;
  totalSeatsRemaining: number;
  isWithinSessionLimit: boolean;
}
/**
 * Gets the total number of seats already used in the cart, on the server and how many are still available in the current purchase
 * This helps to calculate the limits as there are tickets that use more than one seat. For example family or couple type ticket
 */
export function getTotalSeats(slot: App.ExperienceAvailabilityTimeSlot, ticketCounts: Record<string, number>): TotalSlots {
  const totalUsedSeatsOnServer = ArrayUtils.sum(slot.tickets.map(ticket => ticket.customerTicketPurchasedAmount), value => value ?? 0)
  const totalUsedSeatsOnCart = ArrayUtils.sum(Object.entries(ticketCounts), ([key, value]) => {
    const ticket = slot.tickets.find(ticket => ticket.id === key)
    return value * (ticket?.participantsPerUnit ?? 1)
  })

  let totalSeatsRemaining = slot.purchaseLimit.max - totalUsedSeatsOnCart

  if (slot.purchaseLimit.maxReason === 'LIMIT-PER-CUSTOMER') totalSeatsRemaining -= totalUsedSeatsOnServer

  return {
    totalUsedSeatsOnServer,
    totalUsedSeatsOnCart,
    totalSeatsRemaining: Math.max(0, totalSeatsRemaining),
    isWithinSessionLimit: totalSeatsRemaining >= 0,
  }
}

interface ExperienceListKeyParams {
  latitude?: number;
  longitude?: number;
  currencyCode?: string;
  categoryCodes?: Array<number | undefined>;
  placeId?: string;
  placeIdToIgnore?: string;
  sortBy?: ExperienceSortTypes;
  priceLte?: number;
  priceGte?: number;
  campaigns?: Array<string>;
  distance?: string;
  from?: string;
  to?: string;
  offerId?: string;
  postPurchaseOnly?: boolean;
  showUnlisted?: boolean;
}

export function getExperienceListKey(
  filters: ExperienceListKeyParams = {},
) {
  const tokens = [
    filters.latitude,
    filters.longitude,
    filters.currencyCode,
    without(filters.categoryCodes ?? [], AnythingCategory.id)?.join(','),
    filters.placeId,
    filters.sortBy,
    filters.priceLte,
    filters.priceGte,
    filters.campaigns?.join(','),
    filters.distance,
    filters.from,
    filters.to,
    filters.postPurchaseOnly,
    filters.showUnlisted,
    filters.offerId,
  ]

  return tokens.join('-')
}

interface DatesKeyParams {
  pickupPointId?: string,
  redemptionLocationId?: string,
  tickets?: Array<string>,
}

export function getExperienceDatesKey(experienceId: string, params?: DatesKeyParams) {
  return `${experienceId}-${params?.pickupPointId ?? params?.redemptionLocationId}-${params?.tickets?.join(',')}}`
}

interface TimesKeyParams {
  currency: string,
  pickupPointId?: string,
  redemptionLocationId?: string,
  isBuyNowBookLater?: boolean;
  isGift?: boolean;
  tickets?: Array<string>
}

export function getExperienceTimesKey(experienceId: string, date?: string, params?: TimesKeyParams) {
  return `${experienceId}-${date}-${params?.currency}-${params?.pickupPointId ?? params?.redemptionLocationId}-${params?.isBuyNowBookLater ?? false}-${params?.isGift ?? false}`
}

export function getExperienceTicketPurchaseKey(
  ticket: App.ExperienceItemTicket,
  count: number,
) {
  return `${ticket.id}-${count}`
}

export function mapExperienceToBookingView(
  exp: App.ExperienceOffer,
  cartExperienceItem?: App.Checkout.ExperienceItem,
  property?: App.OfferProperty | App.BedbankProperty,
): App.ExperienceBookingView {
  const leExclusive = exp.leExclusive && config.BRAND === 'luxuryescapes'

  return {
    id: exp.id,
    primaryCategory: exp.primaryCategory?.name,
    categories: exp.categories,
    location: exp.location.name,
    image: exp.images[0],
    price: exp.price,
    grossPrice: exp.grossPrice,
    title: exp.name,
    originalExperience: exp,
    description: exp.copy.dealDescription,
    ticketOptions: [],
    added: !!cartExperienceItem?.tickets.some((t: App.Checkout.ExperienceItemTicket) => t.count > 0),
    leExclusive,
    type: exp.productType,
    discount: exp.discount,
    freeCancellation: exp.cancellationPolicies.length > 0,
    interestingLocations: [
      ...(property ? [{
        id: property.id,
        latitude: property.latitude,
        longitude: property.longitude,
        type: 'property' as const,
        name: `Property: ${property.name}`,
      }] : []),
    ],
    ticketUnitLabel: exp.ticketUnitLabel,
  }
}

export interface QueryStringSearchParams {
  destinationId?: string,
  destinationName?: string,
  experienceId?: string,
  experienceName?: string,
  [key: string]: any;
}

export function replaceExperienceQueryString(params: QueryStringSearchParams) {
  const { destinationId, destinationName, ...rest } = params

  const query = qs.stringify({
    destinationId,
    destinationName,
    ...rest,
  }, { arrayFormat: 'comma' })

  return decodeURI(query).replace(/\s/g, '+')
}

export function isStandaloneExperience(item: App.OrderExperienceItem, order: App.Order) {
  if (!item || !order?.experienceItems) return false
  const otherExperiences = order.experienceItems.filter(expItem => expItem.experienceId !== item.experienceId)
  const totalItems = order.items.length + order.bedbankItems.length + order.insuranceItems.length + order.flightItems.length + order.tourItems.length + otherExperiences.length

  return totalItems === 0
}

/**
 * When a user is spoofed, an order can be created with multiple experience offers.
 * This function checks if the order has multiple and only experience offers.
 */
export function isOnlyExperiencesOrder(order: App.Order) {
  if (!order?.experienceItems) return false
  const experienceIds = order.experienceItems.map(expItem => expItem.experienceId)
  const otherItems = order.items.length + order.bedbankItems.length + order.insuranceItems.length + order.flightItems.length + order.tourItems.length
  return otherItems === 0 && ArrayUtils.unique(experienceIds).length > 1
}

export function isExperienceTicket(ticket: App.ExperienceItemTicket | App.PackageAddonItem): ticket is App.ExperienceItemTicket {
  return !!ticket.id
}

export function isPackageAddonItem(ticket: App.ExperienceItemTicket | App.PackageAddonItem): ticket is App.PackageAddonItem {
  return !ticket.id
}
