import { getTourV2SavingsWithLuxPlus } from 'lib/tours/tourUtils'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import React, { useMemo } from 'react'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary
  cheapestPurchasableOption?: Tours.TourV2OfferPurchasableOption
}

function TourV2LuxPlusUrgencyTag(props: Props) {
  const { offer, cheapestPurchasableOption } = props

  const cheapestPurchasableOptionSaving = cheapestPurchasableOption ? getTourV2SavingsWithLuxPlus(cheapestPurchasableOption) : undefined

  const nonCheapestPurchasableOptionSaving = useMemo(() => {
    if (!cheapestPurchasableOptionSaving) { // the cheapest purchasable option doesn't offer LuxPlus benefits
      const purchasableOption = offer?.purchasableOptions.find((purchasableOption) => getTourV2SavingsWithLuxPlus(purchasableOption))
      if (purchasableOption) {
        return getTourV2SavingsWithLuxPlus(purchasableOption)
      }
    }
  }, [cheapestPurchasableOptionSaving, offer?.purchasableOptions])

  const savingsWithLuxPlus = cheapestPurchasableOptionSaving || nonCheapestPurchasableOptionSaving

  if (!savingsWithLuxPlus) {
    return <LuxPlusLabelWithModal
      hideSaveAmountPlus
      offer={offer}
      type="options-available-tour"
    />
  }

  return <LuxPlusLabelWithModal
    hideSaveAmountPlus
    offer={offer}
    saveAmount={savingsWithLuxPlus}
    type="member-price-offer-tour"
  />
}

export default TourV2LuxPlusUrgencyTag
