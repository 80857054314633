import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import moment from 'moment'

import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import config from 'constants/config'
import shouldHideAoLabel from 'lib/offer/hideAoLabel'
import { ImageParams } from 'components/Common/ResponsiveImage'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import { connect } from 'react-redux'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import ViewBundleOffer from './ViewBundleOffer'
import SearchOfferTileInclusionsUpsellBanner from './TileSections/SearchOfferTileInclusionsUpsellBanner'
import SearchOfferTileInclusionsLPCUpsellBanner from './TileSections/SearchOfferTileInclusionsLPCUpsellBanner'
import SearchTilePropertyDetails from './TileSections/SearchTilePropertyDetails'
import SearchTilePriceDetails from './TileSections/SearchTilePriceDetails'
import {
  Main,
  MainImageSegment,
  OfferOverlayContainer,
  StyledOfferBadge,
  DetailSegment,
  StyledCSSBreakpoint,
} from './SearchTileStyles'
import { useTacticalUpsellBanner } from 'hooks/useTacticalUpsellBanner'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import { Location } from 'history'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const UpsellWrapper = styled.div`
  grid-column: 1 / 3;
  margin-top: ${rem(8)};
  align-self: flex-end;
`

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

interface MappedStateProps {
  location: Location;
}

interface Props {
  offer: App.Offer | App.OfferSummary;
  passedWalledGarden: boolean;
  productClick?: (offer: App.Offer | App.OfferSummary) => void;
  offerMetaData?: App.OfferListMetaData;
  filters?: App.OfferListFilters;
  bestPrice: App.OfferAvailableRate;
  soldOut: boolean;
  bestPricePackage?: App.Package;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  onImageChange?: (idx: number, image?: App.Image) => void;
  onImageLoad?: () => void;
  showInclusionUpsell: boolean;
}

function SearchOfferTileExpanded(props: Props & MappedStateProps) {
  const {
    offer,
    passedWalledGarden,
    filters,
    bestPrice,
    bestPricePackage,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    soldOut,
    location,
    onImageChange,
    onImageLoad,
    showInclusionUpsell,
  } = props

  const shouldShowLPCTacticalUpsell = useTacticalUpsellBanner(offer, bestPricePackage, filters)
  const isMapPage = location.pathname.includes('/map')

  const isMobile = useIsMobileScreen()

  const { type, isPartnerProperty } = offer

  const hideAoLabel = shouldHideAoLabel(offer)

  // This needs a memo because new moment objects aren't stable refs.
  const checkInDate = useMemo(() => {
    return filters?.checkIn ? moment(filters.checkIn) : undefined
  }, [filters?.checkIn])

  const locationName = [offer.locationHeading, offer.locationSubheading]
    .filter((t) => t)
    .join(', ')
  const cancellationPolicyType =
    bestPricePackage?.roomRate?.cancellationPolicy?.type

  return (
    <Main className={cn({ 'is-partner-property': isPartnerProperty, mapTile: isMapPage })}>
      <MainImageSegment>
        <ImageCarousel
          images={offer.images}
          imageParams={imageParams}
          eagerLoadFirstImage={eagerLoadFirstImage}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
        {type === OFFER_TYPE_ALWAYS_ON && (
          <OfferOverlayContainer
            className={cn({ 'hide-type-label': hideAoLabel })}
          >
            <OfferPartnershipLabel offer={offer} />
          </OfferOverlayContainer>
        )}
        {offer.badge && (
          <StyledOfferBadge
            badge={offer.badge}
            dimensions={{ width: 86, height: 86 }}
          />
        )}
        <CSSBreakpoint only="mobile">
          <FloatingBookmarkContainer>
            <BookmarkButton offer={offer} />
          </FloatingBookmarkContainer>
        </CSSBreakpoint>
      </MainImageSegment>
      <div>
        <DetailSegment className={cn({ mapTile: isMapPage })}>
          <TripGuard>
            <StyledCSSBreakpoint min="tablet">
              <FloatingBookmarkContainer>
                <BookmarkButton offer={offer} />
              </FloatingBookmarkContainer>
            </StyledCSSBreakpoint>
          </TripGuard>
          <SearchTilePropertyDetails
            location={locationName}
            cancellationPolicyType={cancellationPolicyType}
            offer={offer}
            checkInDate={checkInDate}
            filters={filters}
            offerUrl={offerUrl}
            bestPricePackage={bestPricePackage}
            maxInclusions={isMobile ? 3 : 5}
            maxInclusionLines={isMobile ? 5 : 8}
            showInclusionUpsell={showInclusionUpsell}
          />
          <SearchTilePriceDetails
            offer={offer}
            soldOut={soldOut}
            offerUrl={offerUrl}
            bestPriceForDates={bestPrice}
            filters={filters}
            checkInDate={checkInDate}
            bestPricePackage={bestPricePackage}
            passedWalledGarden={passedWalledGarden}
          />
          {config.ENABLE_BUNDLE_AND_SAVE && offerMetaData?.bundleOfferId && (
            <UpsellWrapper>
              <ViewBundleOffer
                offer={offer}
                filters={filters}
                offerMetaData={offerMetaData}
                offerLinkIncludesFilters={offerLinkIncludesFilters}
              />
            </UpsellWrapper>
          )}
          {!offerMetaData?.bundleOfferId && showInclusionUpsell && !shouldShowLPCTacticalUpsell && (
            <UpsellWrapper>
              <SearchOfferTileInclusionsUpsellBanner
                offer={offer}
                filters={filters}
                newTile
              />
            </UpsellWrapper>
          )}
          {!offerMetaData?.bundleOfferId && shouldShowLPCTacticalUpsell && (
            <UpsellWrapper>
              <SearchOfferTileInclusionsLPCUpsellBanner
                offer={offer}
                bestPricePackage={bestPricePackage}
                filters={filters}
                newTile
              />
            </UpsellWrapper>
          )}
        </DetailSegment>
      </div>
    </Main>
  )
}

SearchOfferTileExpanded.defaultProps = {
  filters: {},
}

const mapStateToProps = (state: App.State): MappedStateProps => ({
  location: state.router.location,
})

export default connect(mapStateToProps)(SearchOfferTileExpanded)
