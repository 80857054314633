import { fetchFlights } from 'actions/FlightActions'
import CurrencyContext from 'contexts/currencyContext'
import { getFlightSearchKey } from 'lib/flights/flightUtils'
import { useContext, useEffect, useMemo } from 'react'
import { EmptyObject } from 'lib/object/objectUtils'
import { arrayToMap, nonNullable } from 'lib/array/arrayUtils'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import { FlightViewTypes } from 'constants/flight'
import { FlightSearchItinerary } from 'api/flights'

export interface FlightSearchParams {
  itinerary: Array<FlightSearchItinerary>;
  occupancies: Array<App.Occupants>;
  fareClass: string;
  maxArrivalTime?: string;
  minReturningDepartureTime?: string;
  carriers?: Array<string>;
  carrier?: string;
  fareType?: string;
  viewType?: string;
  flightsCredit?: boolean;
  forceBundleId?: string;
  source?: string;
  filterAirlines?: Array<string>;
  key?: string | number ;
}

function useSearchFlights(
  params: FlightSearchParams,
  options: {
    disabled?: boolean,
    departingFlight?: App.JourneyV2,
    segment?: 'departing' | 'returning',
    departingFareFamily?: App.FlightFareFamily,
    offerId?: string
    offerTilePrice?: number
    offerCalendarPrice?: number
    sendCredentials?: boolean
  } = EmptyObject,
): [Array<App.JourneyV2>, boolean, any, string | undefined, string | undefined, string] {
  const currency = useContext(CurrencyContext)
  const { disabled, departingFlight, segment, departingFareFamily, offerTilePrice, offerId, offerCalendarPrice, sendCredentials } = options

  const paramsWithCurrency = {
    ...params,
    currency,
  }
  const key = getFlightSearchKey(paramsWithCurrency)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!disabled) {
      dispatch(fetchFlights(paramsWithCurrency, { offerTilePrice, offerId, offerCalendarPrice, sendCredentials }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, disabled])

  const flightsV2 = useAppSelector(state => state.flights.searchV2Flights[key])

  const v2Journeys = useMemo((): Array<App.JourneyV2> => {
    if (flightsV2 && segment) {
      if (segment === 'departing') {
        // departing flights are set at first array of return results
        return flightsV2.departingFares
      } else {
        const returnFares = flightsV2.returningFares
        if (departingFlight?.fareType === FlightViewTypes.RETURN) {
          let currentDepartingFlight = departingFlight

          // if a departing fare family is selected, we need to find the corresponding departing flight to get the correct returning flights
          if (departingFareFamily) {
            currentDepartingFlight = flightsV2.departingFares.filter((fare) => {
              return fare.carrier === departingFlight.carrier && fare.fareType === departingFlight.fareType &&
                fare.flightGroup.flights.length === departingFlight.flightGroup.flights.length &&
                fare.flightGroup.flights.every((flight, index) => {
                  return flight.departingTime === departingFlight.flightGroup.flights[index].departingTime &&
                    flight.arrivalTime === departingFlight.flightGroup.flights[index].arrivalTime
                }) && fare.bookingInfo.bookingClass === departingFareFamily.bookingClass
            })[0] || departingFlight
          }

          // We have a departing flight that expects a return leg, find all return flights that pair with this one
          const returnFairsById = arrayToMap(returnFares, fare => fare.bookingInfo.boookingKey)
          return nonNullable(currentDepartingFlight.pairedFareId.map(id => returnFairsById.get(id)))
        } else {
          // Doesn't have a paired departing flight, so we must be looking for one ways only
          return returnFares.filter(fare => fare.fareType !== FlightViewTypes.RETURN)
        }
      }
    }
    return flightsV2?.departingFares.concat(flightsV2.returningFares) ?? []
  }, [departingFlight, flightsV2, segment, departingFareFamily])

  return [
    v2Journeys,
    Boolean(flightsV2?.fetching),
    flightsV2?.error,
    flightsV2?.searchId,
    flightsV2?.viewType,
    key,
  ]
}

export default useSearchFlights
