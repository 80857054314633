import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/BookmarkButton'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import Pane from 'components/Common/Pane'
import ResponsiveImage, { ImageParams } from 'components/Common/ResponsiveImage'
import TextButton from 'components/Luxkit/Button/TextButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import isTourV2DepositAllowed from 'lib/tours/isTourV2DepositAllowed'
import GeoContext from 'contexts/geoContext'
import SecureWithDepositTag from 'components/Common/SecureWithDeposit/SecureWithDepositTag'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductPaletteProvider, { ProductPalette } from 'contexts/ProductPaletteContext'
import Group from 'components/utils/Group'
import { connect } from 'react-redux'
import { isUltraLuxTour } from 'lib/offer/offerUtils'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import TourV2ItemInclusions from 'components/OfferPage/TourV2/TourV2ItemInclusions'
import useTourV2Details from 'hooks/TourV2/useTourV2Details'
import TextLink from 'components/Luxkit/TextLink'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import useTourTags from 'hooks/TourV2/useTourTags'
import PriceRowSale from 'components/Luxkit/PricePoints/PriceRowSale'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { isVelocityEarnEnabled } from 'lib/payment/paymentUtils'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const DetailsCaptionContainer = styled(BodyText)`
  align-items: flex-start;
  display: flex;

  > * + *::before {
    color: ${props => props.theme.palette.neutral.default.one};
    content: '·';
    margin: 0 ${rem(4)};
    speak-as: bullets;
    speak: none;
  }
`

const PricingAndCtaArea = styled.div`
  align-items: flex-end;
  display: grid;
  gap: ${rem(4)};
  grid-template:
    'pricing cta'
    / 2.5fr 1fr;

  > .pricing-area {
    grid-area: pricing;
  }

  > .cta-area {
    grid-area: cta;
  }

  > .logo-area {
    grid-area: logo;
  }

  ${mediaQueryUp.desktop} {
    height: 100%;
    align-items: center;
    gap: unset;
    grid-template:
      'logo' auto
      '.' ${rem(24)}
      'pricing' min-content
      '.' ${rem(16)}
      'cta' min-content
      / 1fr;
  }
`

const PriceBodyContainer = styled(Group)`
  padding: ${rem(16)} ${rem(20)} ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding: ${rem(20)} ${rem(32)} ${rem(20)} ${rem(0)};
    > :last-child {
      padding-top: ${rem(48)};
    }
  }
`

const BookmarkButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: ${rem(12)};
`

const TileBody = styled(Group)`
  padding: ${rem(20)};

  ${mediaQueryUp.desktop} {
    padding: ${rem(20)} ${rem(32)} ${rem(20)} ${rem(32)};
  }
`

const TileMediaContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

const Tile = styled(Pane)`
  display: grid;
  grid-template-areas:
    'media'
    'body'
    'price';

  ${mediaQueryUp.desktop} {
    grid-template:
      'media body price'
      / 1fr 1.25fr 0.75fr;
  }

  > .media-area {
    grid-area: media;
    min-height: ${rem(293)};
    max-height: ${rem(465)};
  }

  > .body-area {
    grid-area: body;

    ${mediaQueryUp.desktop} {
      min-height: ${rem(253)};
    }
  }
  > .price-area {
    grid-area: price;
    ${mediaQueryUp.desktop} {
      min-height: ${rem(253)};
    }
  }
`

const OperatorLogoImage = styled(ResponsiveImage)`
  width: 100%;
  height: 100%;

  ${mediaQueryUp.desktop} {
    padding: 0 ${rem(16)};
  }
`

const DetailGridContainer = styled.div`
  display: grid;
  grid-template:
    'tour-detail logo'
    / 1fr auto;

  ${mediaQueryUp.tablet} {
    grid-template:
      'tour-detail'/ 1fr;
  }

  > .tour-detail-area {
    grid-area: tour-detail;
  }

  > .logo-area {
    grid-area: logo;
  }
`

const MEDIA_IMAGE_PARAMS: ImageParams = {
  quality: 'eco',
  mobileAspectRatio: '16:9',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '16:11',
  largeDesktopAspectRatio: '16:11',
  mobileWidth: '100vw',
  tabletWidth: '100vw',
  desktopWidth: '780px',
  largeDesktopWidth: '780px',
}
interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary,
  onImageChange?: (idx: number, image?: App.Image) => void,
}

interface MappedProps {
  isVelocityEnabled: boolean
}

function TourV2SearchTile(props: Props & MappedProps) {
  const {
    offer,
    onImageChange,
    isVelocityEnabled,
  } = props

  const { currentCurrency } = useContext(GeoContext)

  const {
    cheapestPurchasableOption,
    cheapestVariation,
    cheapestDeparture,
  } = useCheapestOfTourV2Offer(offer)

  const {
    hasDiscount,
    pricePerPerson,
    fullPricePerPerson,
    roomTypeName,
    valuedAt,
    memberPrice,
  } = useTourV2PurchasableOptionDetails(cheapestPurchasableOption) || {}
  const {
    startLocation,
    endLocation,
    hasTheSameStartAndEndLocation,
    placesCount,
    countriesCount,
    durationInDaysCount,
    inclusionItems,
  } = useTourV2VariationDetails(cheapestVariation)

  const tileImages = useMemo(() => {
    if (cheapestVariation?.routeMapImage) {
      return [...cheapestVariation.images, cheapestVariation.routeMapImage]
    }

    return cheapestVariation?.images || []
  }, [cheapestVariation])

  const { hasMoreVariations, hasAlternativeRoute, upgradesAvailable } = useTourV2Details(offer)

  const { isDepositAllowed } = useMemo(() => ({
    isDepositAllowed: !!cheapestDeparture?.startDate &&
    !!pricePerPerson &&
    isTourV2DepositAllowed(cheapestDeparture.startDate, pricePerPerson, currentCurrency, offer.depositType || 'noDeposit', offer.depositThresholds?.numberOfDays),
  }), [cheapestDeparture, currentCurrency, offer, pricePerPerson])

  const isUltraLux = isUltraLuxTour(offer)
  let paletteValue: ProductPalette
  if (isUltraLux) {
    paletteValue = 'ultralux'
  } else {
    paletteValue = 'default'
  }

  const tags = useTourTags(offer)
  const tourV2StartLocation = useMemo(
    () => {
      const variations = Object.values(offer.variations)
      if (variations.length === 0) return ['']

      const variation = variations[0]

      return [variation.startLocation || '']
    },
    [offer],
  )

  const onEvent = useContext(OfferListEventsContext)
  const isTourReviewsVisible = offer.reviewsRating > 0 && offer.reviewsTotal > 0

  const handleImageLoaded = useCallback(() => {
    onEvent(OfferListEvents.offerReady)
  }, [onEvent])

  return <ProductPaletteProvider palette={isUltraLuxTour(offer) ? 'ultralux' : 'default'}>
    <Tile type="clean" axis="vertical">
      <TileMediaContainer className="media-area">
        <ImageCarousel
          images={tileImages}
          imageParams={MEDIA_IMAGE_PARAMS}
          eagerLoadFirstImage
          topLeft={isVelocityEnabled && <PartnershipLabel canEarn={isVelocityEarnEnabled(offer)} canUse />}
          topRight={<CSSBreakpoint max="tablet">
            <TripGuard>
              <BookmarkButtonContainer>
                <BookmarkButton offer={offer} label="Save" />
              </BookmarkButtonContainer>
            </TripGuard>
          </CSSBreakpoint>}
          onImageChange={onImageChange}
          onImageLoad={handleImageLoaded}
        />
      </TileMediaContainer>

      <Group direction="vertical" className="body-area">
        <ProductPaletteProvider palette={paletteValue}>
          <TileBody direction="vertical" horizontalAlign="space-between" verticalAlign="start" gap={8}>
            <Group direction="vertical" gap={8} fullWidth>
              {isTourReviewsVisible && (
                <CSSBreakpoint min="desktop">
                  <NumberRating
                    inlineLabel
                    variant="small"
                    rating={offer.reviewsRating}
                    total={offer.reviewsTotal}
                  />
                </CSSBreakpoint>
              )}
              <TourV2UrgencyTags tags={tags} cheapestPurchasableOption={cheapestPurchasableOption} offer={offer}/>
              <ProductTypeLabel productType={offer.productType} kind="plain"/>
              <Group direction="vertical" gap={8} desktopVerticalAlign="start" desktopGap={4}>
                <DetailGridContainer>
                  <Group className="tour-detail-area" direction="vertical" gap={2} desktopGap={8}>
                    <Caption variant="large">
                      {hasTheSameStartAndEndLocation &&
                        <span>Starts and ends in <b>{endLocation}</b></span>}
                      {!hasTheSameStartAndEndLocation &&
                        <span>Starts in <b>{startLocation}</b>, Ends in <b>{endLocation}</b></span>}
                    </Caption>
                    <Heading variant="heading5" lineClamp={3} className="heading-area">{offer.name}</Heading>
                    <BodyText variant="medium" lineClamp={1}>Operated by: <b>{offer.operatedBy}</b></BodyText>
                    {isTourReviewsVisible && (
                      <CSSBreakpoint max="tablet">
                        <NumberRating
                          variant="small"
                          rating={offer.reviewsRating}
                          total={offer.reviewsTotal}
                        />
                      </CSSBreakpoint>
                    )}
                    <DetailsCaptionContainer variant="medium">
                      <div>{durationInDaysCount}</div>
                      {placesCount && <div>{placesCount}</div>}
                      {countriesCount && <div>{countriesCount}</div>}
                    </DetailsCaptionContainer>
                  </Group>
                  <CSSBreakpoint max="mobile">
                    {
                      offer.brand.logoImage?.id &&
                        <OperatorLogoImage
                          className="logo-area"
                          id={offer.brand.logoImage?.id}
                          mobileWidth="60px"
                          quality="eco"
                          alt={offer.brand.logoImage?.title}
                          role="presentation"
                        />
                  }
                  </CSSBreakpoint>
                </DetailGridContainer>
                {hasAlternativeRoute && <TextLink size="medium">
                  Alternative route available
                </TextLink>}
                {!hasAlternativeRoute && hasMoreVariations && <TextLink size="medium">
                  More options available
                </TextLink>}
                {!hasMoreVariations && upgradesAvailable && <TextLink size="medium">
                  Upgrades available
                </TextLink>}
                {inclusionItems.length > 0 && (
                  <Group direction="vertical" gap={4}>
                    <BodyText weight="bold" variant="medium">
                      Your handpicked inclusions:
                    </BodyText>
                    <TourV2ItemInclusions inclusions={inclusionItems.slice(0, 3)} truncateLength gap={4} />
                    {inclusionItems.length > 3 && <BodyText variant="medium" colour="primary" weight="bold" style={{ textDecoration: 'underline' }}>
                      +{inclusionItems.length - 3} more inclusions
                    </BodyText>}
                  </Group>
                )}
              </Group>
            </Group>
          </TileBody>
        </ProductPaletteProvider>
      </Group>
      <PriceBodyContainer direction="vertical" verticalAlign="stretch" className="price-area">
        <CSSBreakpoint min="desktop">
          <TripGuard>
            <BookmarkButtonContainer>
              <BookmarkButton offer={offer} label="Save"/>
            </BookmarkButtonContainer>
          </TripGuard>
        </CSSBreakpoint>
        <PricingAndCtaArea>
          <CSSBreakpoint min="desktop">
            {
              offer.brand.logoImage?.id &&
                <OperatorLogoImage
                  className="logo-area"
                  id={offer.brand.logoImage?.id}
                  tabletWidth="150px"
                  quality="eco"
                  alt={offer.brand.logoImage?.title}
                  role="presentation"
                />
            }
          </CSSBreakpoint>
          <Group direction="vertical" gap={4} className="pricing-area">
            <LabelGroup>
              {isDepositAllowed && <SecureWithDepositTag />}
            </LabelGroup>
            <div>
              <PriceRowPriceCaption>{durationInDaysCount} from</PriceRowPriceCaption>
              <LuxPlusPriceStack
                size="L"
                price={Number(pricePerPerson)}
                saleUnit="person"
                memberPrice={Number(memberPrice)}
                isTour
              />
              {hasDiscount && <PriceRowSale value={fullPricePerPerson!} />}
              <PriceRowCaption>{roomTypeName}</PriceRowCaption>
              {!!valuedAt && <PriceRowValueDiscountWithCaption
                size="M"
                originalValue={valuedAt.price}
                discountPercentage={valuedAt.savingPercentage}
              />}
              <PriceRowTaxesAndFees />
              <PriceRowAgentHubCommission
                size="L"
                productType={offer.productType}
                offerId={offer.id}
                offerLocations={tourV2StartLocation}
              />
            </div>
          </Group>
          <Group direction="vertical" className="cta-area">
            <CSSBreakpoint min="desktop">
              <TextButton kind="primary" style={{ width: '100%' }}>View offer</TextButton>
            </CSSBreakpoint>
            <CSSBreakpoint max="tablet">
              <TextButton kind="primary" style={{ width: '100%' }}>View</TextButton>
            </CSSBreakpoint>
          </Group>
        </PricingAndCtaArea>
      </PriceBodyContainer>
    </Tile>
  </ProductPaletteProvider>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  isVelocityEnabled: state.geo.partnership?.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE,
})

export default connect(mapStateToProps)(TourV2SearchTile)
