import { getBundlePackagesWithPrice } from 'lib/bundleAndSave/getBundlePackagesWithPrice'

export function getBundlePackagesFilteredByRate(offer: App.BundleOffer, rate: App.OfferAvailableRate) {
  const packageAvailable = offer.packages.find(pkg => pkg.uniqueKey === rate.packageUniqueKey)
  const restPackages = offer.packages.filter(pkg => pkg.offerId !== packageAvailable?.offerId)

  return getBundlePackagesWithPrice({
    ...offer,
    packages: !packageAvailable ? offer.packages : [...restPackages, {
      ...packageAvailable,
      price: rate.hotelPrice,
      surcharge: rate.surcharge,
      value: rate.hotelValue,
      taxesAndFees: rate.taxesAndFees,
      propertyFees: rate.propertyFees,
    }],
  })
}
