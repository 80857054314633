import { AppApiAction } from './ActionTypes'
import {
  API_CALL,
  CLEAR_FLIGHT_RESULT,
  FETCH_FLIGHT_DEAL,
  FETCH_FLIGHT_DEALS,
  FETCH_POPULAR_AIRPORTS,
  SET_FLIGHTS_CREDIT_DATA,
  UPDATE_FLIGHT_FILTERS,
  UPDATE_FLIGHTS_SORT,
} from './actionConstants'
import {
  getFlightBaggage,
  getFlightDealsByRegion,
  getFlightPrice,
  getJourneyV2,
  getPopularAirportsByRegion,
  searchFlights, getFlightCachePrices, getFlightFareFamilies,
  getFlightDeal,
  FlightSearchItinerary,
} from 'api/flights'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import { getFlightCalendarKey, resetFilter, getFlightPriceKey, getFlightSearchKey } from 'lib/flights/flightUtils'
import {
  FETCH_FLIGHT_BAGGAGE,
  FETCH_FLIGHT_JOURNEY,
  FETCH_FLIGHT_PRICE,
  FETCH_FLIGHT_CALENDAR, FETCH_SEARCH_FLIGHTS, FETCH_FLIGHT_FARE_FAMILIES,
} from './apiActionConstants'
import { sum } from 'lib/array/arrayUtils'
import { FlightSortOptions, ENABLE_TRAVEL_FUSION_BAGGAGE, TRAVEL_FUSION } from 'constants/flight'
import { FlightsCreditDetails } from 'components/Flights/types'
import { resetFlightsTimer } from 'components/Flights/FlightsSessionTimer/FlightsSessionTimer'
import getObjectKey from 'lib/object/getObjectKey'
import { setOptimizelyExperiment } from './OptimizelyActions'
import { OptimizelyExperiments } from 'constants/optimizely'
import { EmptyObject } from 'lib/object/objectUtils'

interface GetFlightsParams {
  itinerary: Array<FlightSearchItinerary>;
  currency: string;
  occupancies: Array<App.Occupants>;
  fareClass: string;
  maxArrivalTime?: string;
  minReturningDepartureTime?: string;
  carriers?: Array<string>;
  fareType?: string;
  source?: string;
  searchIndex?: number;
  viewType?: string;
  forceBundleId?: string;
}

function addTravelFusionBaggageV2(
  journey: App.JourneyV2,
  searchId: string,
  journeyId,
  region: string,
  pairedJourneyId?: string,
  legNumber?: number,
) {
  const isDeparting = legNumber === 0
  const baggageParams = {
    journeyId: searchId,
    fareId: isDeparting ? journeyId : pairedJourneyId,
    pairedId: isDeparting ? pairedJourneyId : journeyId,
    provider: TRAVEL_FUSION,
    viewType: 'TwoOneWays',
    region,
  }
  return getFlightBaggage(baggageParams).then(baggage => {
    journey.flightGroup.flights[0].checkedBaggageOptions = baggage.baggage[isDeparting ? 0 : 1] as Array<App.FlightV2BaggageOption>
    journey.flightGroup.flights[0].carryOnBaggageOptions = baggage.carryOnBaggage[isDeparting ? 0 : 1] as Array<App.FlightV2BaggageOption>
    return journey
  }).catch(e => {
    console.error('Error fetching TravelFusion baggage', e)
    return journey
  })
}

export function fetchFlightJourneyV2(
  searchId: string,
  journeyId: string,
  fareFamilyId?: string,
  pairedJourneyId?: string,
  legNumber?: number,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const journeyKey = getObjectKey({
      journeyId,
      searchId,
      fareFamilyId,
    })

    if (state.flights.journeysById[journeyKey] || state.flights.journeyFetching[journeyKey]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_JOURNEY,
      key: journeyKey,
      request: () => getJourneyV2(searchId, journeyId, state.auth.accessToken, fareFamilyId).then(journey => {
        // Only get baggage for TravelFusion v2 return flights.
        if (ENABLE_TRAVEL_FUSION_BAGGAGE && journey.provider === TRAVEL_FUSION && pairedJourneyId && typeof legNumber === 'number') {
          return addTravelFusionBaggageV2(journey, searchId, journeyId, state.geo.currentRegionCode, pairedJourneyId, legNumber)
        }
        return journey
      }),
    })
  }
}

export function fetchFlights(
  params: GetFlightsParams,
  options: {
    offerId?: string
    offerTilePrice?: number
    offerCalendarPrice?: number,
    sendCredentials?: boolean
  } = EmptyObject,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = getFlightSearchKey(params)

    if (state.flights.searchFlights[key] || state.flights.searchV2Flights[key]) {
      // already fetched or is fetching, don't fetch again
      return
    }

    resetFlightsTimer()

    // TODO: Look into adding an expiry time for the results
    // so they don't stick around forever and go out of date

    dispatch({
      type: API_CALL,
      api: FETCH_SEARCH_FLIGHTS,
      request: () => searchFlights(
        {
          ...params,
          region: state.geo.currentRegionCode,
        },
        options,
      ),
      key,
    })
  }
}

export function triggerFlightsMerchantFeeTest() {
  return (dispatch) => {
    dispatch(setOptimizelyExperiment(OptimizelyExperiments.paymentsFlightsMerchantFees))
  }
}

export function getBaggage(
  journeyId: string,
  provider: string,
  flightSearchKey?: string,
) {
  return (dispatch, getState) => {
    const { geo } = getState() as App.State
    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_BAGGAGE,
      request: () => getFlightBaggage({
        journeyId,
        provider,
        region: geo.currentRegionCode,
      }).then(data => ({
        journeyId,
        flightSearchKey,
        newBaggage: [{
          baggage: data.baggage[0],
          carryOnBaggage: data.carryOnBaggage[0],
          baggageNote: data.baggageNote,
          isBagsSameForRoundTrip: data.isBagsSameForRoundTrip,
        },
        {
          baggage: data.baggage[1],
          carryOnBaggage: data.carryOnBaggage[1],
          baggageNote: data.baggageNote,
          isBagsSameForRoundTrip: data.isBagsSameForRoundTrip,
        }],
      })),
    })
  }
}

export function updateFlightFilters<T extends App.AnyJourney = App.Journey>(filters: Array<App.FlightFilter<T>>) {
  return {
    type: UPDATE_FLIGHT_FILTERS,
    data: filters,
  }
}

export function updateFlightFilter(filter: App.FlightFilter) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const newFilters = [
      ...state.flights.filters.filter(f => f.id !== filter.id),
      filter,
    ]

    dispatch({
      type: UPDATE_FLIGHT_FILTERS,
      data: newFilters,
    })
  }
}

export function updateFlightsSort(sortValue: FlightSortOptions) {
  return {
    type: UPDATE_FLIGHTS_SORT,
    data: sortValue,
  }
}

export function clearFlightsResult() {
  return {
    type: CLEAR_FLIGHT_RESULT,
  }
}

export function resetFlightsFilters() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const newFilters = state.flights.filters.map(f => resetFilter(f))

    dispatch({
      type: UPDATE_FLIGHT_FILTERS,
      data: newFilters,
    })
  }
}

export function fetchFlightPrice(
  destinationAirportCode: string,
  rooms: Array<App.Occupants>,
  startDate: string,
  endDate: string,
  flightOrigin?: string,
  nights?: number,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const finalOrigin = flightOrigin || getDefaultAirportCode(state)

    const requestProps = {
      startDate,
      endDate,
      numberOfAdults: sum(rooms, (room) => room.adults),
      numberOfChildren: sum(rooms, (room) => room.children ?? 0),
      numberOfInfants: sum(rooms, (room) => room.infants ?? 0),
      origin: finalOrigin,
      destination: destinationAirportCode,
      nights,
    }

    const currency = state.geo.currentCurrency
    const region = state.geo.currentRegionCode
    const key = getFlightPriceKey(requestProps)

    if (
      // can be 0, which is still falsey - but we don't want to refetch it
      typeof state.flights.flightPrices[key] !== 'undefined' ||
      state.flights.flightPricesLoading[key]
    ) {
      // fetching or already fetched, skip
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_PRICE,
      request: () => getFlightPrice({ ...requestProps, currency, region }),
      key,
    })
  }
}

export function fetchFlightPrices(
  destinationAirportsCodes: Array<string>,
  rooms: Array<App.Occupants>,
  startDate: string,
  endDate: string,
  flightOrigin?: string,
  nights?: number,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const finalOrigin = flightOrigin || getDefaultAirportCode(state)

    const requestProps = {
      startDate,
      endDate,
      numberOfAdults: sum(rooms, (room) => room.adults),
      numberOfChildren: sum(rooms, (room) => room.children ?? 0),
      numberOfInfants: sum(rooms, (room) => room.infants ?? 0),
      origin: finalOrigin,
      nights,
    }

    const currency = state.geo.currentCurrency
    const region = state.geo.currentRegionCode
    // dedupe airports first
    const missingDestination = Array.from(new Set(destinationAirportsCodes.filter(Boolean)))
      // now get our keys
      .map(destination => ({ key: getFlightPriceKey({ ...requestProps, destination }), airportCode: destination }))
      // finally find those that aren't already fetched/fetching
      .filter(destination =>
        // can be 0, which is still falsey - but we don't want to refetch it
        typeof state.flights.flightPrices[destination.key] === 'undefined' &&
        !state.flights.flightPricesLoading[destination.key],
      )

    missingDestination.forEach(destination => {
      dispatch({
        type: API_CALL,
        api: FETCH_FLIGHT_PRICE,
        request: () => getFlightPrice({ ...requestProps, destination: destination.airportCode, currency, region, nights }),
        key: destination.key,
      })
    })
  }
}

export function fetchPopularAirports() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.flights.popularAirports.origins.length || state.flights.popularAirports.fetching) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_POPULAR_AIRPORTS,
      request: () => getPopularAirportsByRegion(state.geo.currentRegionCode),
    })
  }
}

export function setFlightCreditData(creditDetails: FlightsCreditDetails) {
  return {
    type: SET_FLIGHTS_CREDIT_DATA,
    data: creditDetails,
  }
}

export function fetchFlightDeals(dealCategory: string = 'all') {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const finalCategory = dealCategory === 'all' ? undefined : dealCategory

    const existingList = state.flights.flightDealLists[dealCategory]
    if (existingList) {
      // if a list exists, it must've been initialised and is currently fetching/has fetched or is errored
      // we don't need to do it again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_DEALS,
      request: () => getFlightDealsByRegion(state.geo.currentRegionCode, finalCategory),
      // only thing we key on at the moment is deal category
      key: dealCategory,
    })
  }
}

export function fetchFlightDeal(id: string, token?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.flights.flightDeals[id]) {
      // already done (or doing), don't do it again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_DEAL,
      request: () => getFlightDeal(id, token),
      id,
    })
  }
}

interface FlightCalendarProps {
  startDate: string;
  endDate: string;
  origin: string;
  destination: string;
  nights?: number;
}

export function fetchFlightCalendar(props: FlightCalendarProps): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()
    const region = state.geo.currentRegionCode
    const currency = state.geo.currentCurrency

    const filterKey = getFlightCalendarKey({ ...props, region, currency })
    if (state.flights.flightCalendar[filterKey] || state.flights.flightCalendarLoading[filterKey]) {
      // already have fetched the calendar for the current filters
      return
    }

    const requestData = {
      startDate: props.startDate,
      endDate: props.endDate,
      origin: props.origin,
      destination: props.destination,
      nights: props.nights,
      region,
      currency,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_CALENDAR,
      request: () => getFlightCachePrices(requestData),
      key: filterKey,
    })
  }
}
interface FetchFlightUpsellFaresParams {
  searchId: string;
  sessionId: string;
  origin: string;
  destination: string;
  occupancies: App.Occupants;
  journeyId: string;
  departingJourneyId?: string;
  departingFareFamilyId?: string;
  isStandalone?: boolean;
}

export function fetchFlightFareFamilies(params: FetchFlightUpsellFaresParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const requestProps = {
      ...params,
      departingFareId: params.departingJourneyId,
      departingUpsellOptionId: params.departingFareFamilyId,
      journeyId: params.journeyId,
      region: state.geo.currentRegionCode,
    }

    const key = getObjectKey({
      searchId: params.searchId,
      origin: params.origin,
      destination: params.destination,
      fareId: params.journeyId,
      departingFareId: params.departingJourneyId,
      departingUpsellOptionId: params.departingFareFamilyId,
    })

    const stateFareFamilies = state.flights.fareFamiliesByFlight[key]

    // already fetching or have already fetched without error
    if (stateFareFamilies && !stateFareFamilies.error) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_FLIGHT_FARE_FAMILIES,
      request: () => getFlightFareFamilies(requestProps).then(fareFamilies => {
        if (fareFamilies.length === 0) {
          throw new Error('No fare families found for flight')
        }
        return fareFamilies
      }),
      key,
    })
  }
}
