import React from 'react'
import { getInclusionIcon } from 'lib/tours/getTourV2Icons'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import Group from 'components/utils/Group'

interface Props {
  showHighlight?: boolean
  inclusions: Array<{
    categoryIcon: string
    inclusionBody: string
    isHighlight: boolean
  }>,
  truncateLength? : boolean
  gap?: 0 | 2 | 4 | 8 | 12
}

function TourV2ItemInclusions(props: Props) {
  const { inclusions, showHighlight, truncateLength, gap = 8 } = props

  return <Group direction="vertical" gap={gap}>
    {inclusions.length > 0 && inclusions.map((item) => {
      const InclusionIcon = item.categoryIcon ? getInclusionIcon(item.categoryIcon.split(',')[0]) : LineCheckIcon
      const isHighlighted = showHighlight && item.isHighlight
      return (
        <BodyTextBlock key={item.inclusionBody} weight={isHighlighted ? 'bold' : 'normal'} variant="medium" startIcon={<InclusionIcon/>} lineClamp={truncateLength ? 1 : 0}>
          {item.inclusionBody}
        </BodyTextBlock>
      ) },
    )}
  </Group>
}

export default TourV2ItemInclusions
