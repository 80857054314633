import { fetchOfferListFilters } from 'actions/OfferActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { EmptyObject } from 'lib/object/objectUtils'
import getOfferListKey from 'lib/offer/offerListKey'
import { useEffect } from 'react'

const emptyFilters: App.OfferListFilterOptions = {
  error: undefined,
  fetching: true,
  filterOrder: {
    amenities: {},
    holidayTypes: {},
    locations: {},
  },
  filters: {
    amenities: {},
    bedrooms: {},
    campaigns: {},
    holidayTypes: {},
    locations: {},
    offerTypes: {},
    propertyTypes: {},
    luxPlusFeatures: {},
    total: 0,
  },
  orderedFilters: {
    amenities: [],
    holidayTypes: [],
    locations: [],
  },
}

interface Options {
  disabled?: boolean;

  /**
   * Whether to fetch the offer list or just return existing offer list
   */
  noFetch?: boolean;
}

function useOfferListFilters(filters: App.OfferListFilters, options: Options = EmptyObject): App.OfferListFilterOptions {
  const key = getOfferListKey(filters)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!options.disabled && !options.noFetch) {
      dispatch(fetchOfferListFilters(filters))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, options])

  const listFilters = useAppSelector(state => state.offer.offerListFilterOptions[key]) ?? emptyFilters

  return listFilters
}

export default useOfferListFilters
