export enum CheckoutPageId {
  DepartingFlights = 'departing',
  ReturningFlights = 'returning',
  Experiences = 'experiences',
  Purchase = 'purchase',
  Dates = 'dates',
  CruiseV2 = 'cruises',
  CruiseCabinLocation = 'cabin-location',
  LuxPlus = 'lux-plus',
  TourV1Capacity = 'tour-capacity',
  TourV1Departure = 'tour-departure',
  TourV2Upgrade = 'tours',
  TourV2OptionalExperience = 'tours-optional-extras',
  ChangeDates = 'change-dates',
  ChangePackage = 'change-package',
  InsuranceSelectDates = 'insurance-select-dates',
}
