import { createListenerMiddleware } from '@reduxjs/toolkit'
import { getOrderId, orderIdStateIsLoaded } from '../analytics/snowplow/helpers'
import { waitForValue } from 'lib/promise/waitForValue'
import { isCheckoutReady } from 'checkout/selectors/view/generic'
// Create instance
export const analyticsListenerMiddleware = createListenerMiddleware()

const waitForCheckoutReady = waitForValue<boolean>()
export const checkoutStateIsReady = () => waitForCheckoutReady.awaitForValue()
analyticsListenerMiddleware.startListening({
  // Run on any change as checkout can be affected by many items in state
  predicate: (_, currentState: App.State, previousState: App.State) => {
    const readyNow = isCheckoutReady(currentState)
    const readyBefore = isCheckoutReady(previousState)
    return readyNow !== readyBefore
  },
  effect: async(_, listenerApi) => {
    const state = listenerApi.getState() as App.State
    if (isCheckoutReady(state)) {
      waitForCheckoutReady.resolveWithValue(true)
    } else {
      waitForCheckoutReady.resetAwaitedValue()
    }
  },
})

const waitForOrderReady = waitForValue<boolean>()
export const orderStateIsReady = () => waitForOrderReady.awaitForValue()
analyticsListenerMiddleware.startListening({
  // Run on any change only
  predicate: (_, currentState: App.State, previousState: App.State) => {
    const orderIdNow = getOrderId(currentState)
    const orderIdBefore = getOrderId(previousState)
    const orderNow = orderIdStateIsLoaded(currentState, orderIdNow)
    const orderBefore = orderIdStateIsLoaded(previousState, orderIdBefore)
    return orderNow !== orderBefore || orderIdNow !== orderIdBefore
  },
  effect: async(_, listenerApi) => {
    const state = listenerApi.getState() as App.State
    const orderId = getOrderId(state)
    if (orderId && orderIdStateIsLoaded(state, orderId)) {
      waitForOrderReady.resolveWithValue(true)
    } else {
      waitForOrderReady.resetAwaitedValue()
    }
  },
})
