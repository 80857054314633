import moment from 'moment/moment'

export function inclusionTravelPeriodFilter(
  inclusions: Array<App.PackageInclusion>,
  checkIn?: moment.Moment,
  checkOut?: moment.Moment,
): Array<App.PackageInclusion> {
  let inclusionsResult: Array<App.PackageInclusion>
  const rateInclusions = inclusions.filter(inclusion => {
    if (inclusion.displayContext !== 'Rate') {
      return false
    }

    if (inclusion.schedules.length) {
      if (!(checkIn && checkOut) && !inclusion.hasTactical) {
        return false
      } else if (checkIn && checkOut) {
        return inclusion.schedules.some(schedule => {
          return checkIn.isBetween(moment(schedule.travelPeriod.from), moment(schedule.travelPeriod.to), 'days', '[]') &&
            checkOut.isBetween(moment(schedule.travelPeriod.from), moment(schedule.travelPeriod.to), 'days', '[]')
        })
      }
    }

    return true
  })

  const otherInclusions = inclusions.filter(inclusion => {
    return inclusion.displayContext !== 'Rate'
  })

  if (rateInclusions.length > 0) {
    inclusionsResult = rateInclusions
  } else {
    inclusionsResult = otherInclusions
  }

  return inclusionsResult
}
