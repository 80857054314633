import React, { useMemo } from 'react'

import BodyText from 'components/Luxkit/Typography/BodyText'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { formatShortDateRange } from 'tripPlanner/utils'
import moment from 'moment'
import Typography from 'components/Luxkit/Typography/Typography'

interface Props {
  flightDeal: App.FlightDeal;
}

function FlightDealDuration(props: Props) {
  const { flightDeal } = props

  const offerDurationLabel = useMemo(() => {
    const formattedPeriods = flightDeal.travelPeriods.map(period => formatShortDateRange({
      startDate: moment(period.departureDate),
      endDate: moment(period.arrivalDate),
    }))
    return formattedPeriods.join('  ·  ')
  }, [flightDeal])

  return (<BodyTextBlock
      startIcon={<SolidFireIcon colour="urgency" />}
      variant="small"
      colour="neutral-two"
    >
    <BodyText variant="small" colour="urgency" as="span" weight="bold">Flight deal:</BodyText>{' '}
    Travel between{' '}
    <Typography as="b" wrap="pre-wrap">{offerDurationLabel}.</Typography>
  </BodyTextBlock>
  )
}

export default FlightDealDuration
