import { BookingProtectionActions } from 'actions/BookingProtectionActions'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import {
  FETCH_BOOKING_PROTECTION_QUOTE,
} from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

const initialState: App.BookingProtectionState = {
  fetchingQuotes: {},
  quotes: {},
}

const apiRequests = reducerSwitch<App.BookingProtectionState>({
  [FETCH_BOOKING_PROTECTION_QUOTE]: (state, action) => ({
    fetchingQuotes: {
      ...state.fetchingQuotes,
      [action.key]: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.BookingProtectionState>({
  [FETCH_BOOKING_PROTECTION_QUOTE]: (state, action) => ({
    fetchingQuotes: {
      ...state.fetchingQuotes,
      [action.key]: false,
    },
    quotes: {
      ...state.quotes,
      [action.key]: action.data,
    },
  }),
})

const apiFailures = reducerSwitch<App.BookingProtectionState>({
  [FETCH_BOOKING_PROTECTION_QUOTE]: (state, action) => ({
    fetchingQuotes: {
      ...state.fetchingQuotes,
      [action.key]: false,
    },
  }),
})

const bookingProtectionReducer = createReducer<App.BookingProtectionState, BookingProtectionActions>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})

export default bookingProtectionReducer
