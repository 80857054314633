import React, { ComponentProps, ReactElement, ReactNode, forwardRef, memo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import TextButton from '../Button/TextButton'
import BodyTextBlock from '../TextBlocks/BodyTextBlock'
import { shallowEqual } from 'react-redux'

const BannerSheet = styled.div`
  &.kind-informational-primary {
    background-color: ${props => props.theme.palette.neutral.default.seven};
    color: ${props => props.theme.palette.neutral.default.one};
  }
  &.kind-informational-secondary {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    color: ${props => props.theme.palette.neutral.default.one};
  }
  &.kind-promotional-primary {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
    color: ${props => props.theme.palette.highlight.primary.lightContrast};
  }
  &.kind-promotional-primary-accent {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
    color: ${props => props.theme.palette.highlight.primary.normalContrast};
  }
  &.kind-promotional-secondary {
    background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
    color: ${props => props.theme.palette.highlight.tertiary.lightContrast};
  }
  &.kind-success {
    background-color: ${props => props.theme.palette.messaging.success.lightBackground};
    color: ${props => props.theme.palette.messaging.success.lightContrast};
  }
  &.kind-warning {
    background-color: ${props => props.theme.palette.messaging.warning.lightBackground};
    color: ${props => props.theme.palette.messaging.warning.lightContrast};
  }
  &.kind-critical {
    background-color: ${props => props.theme.palette.messaging.critical.lightBackground};
    color: ${props => props.theme.palette.messaging.critical.lightContrast};
  }
`

const BannerContainer = styled.div`
  display: grid;

  &.align-start {
    grid-template:
      "content ." auto
      "actions actions" auto / auto 1fr;
  }
  &.align-center {
    grid-template:
      ". content ." auto
      "actions actions actions" auto / 1fr auto 1fr;
  }

  &.density-comfortable {
    padding: ${rem(16)};
  }
  &.density-compact {
    padding: ${rem(12)} ${rem(16)};
  }

  ${mediaQueryUp.tablet} {
    align-items: center;

    &.align-on-tablet-start {
      grid-template: "content . actions" auto / auto 1fr auto;
    }
    &.align-on-tablet-center {
      grid-template: ". content actions ." auto / 1fr auto auto 1fr;
    }
  }
`

const BannerContent = styled(BodyTextBlock)`
  grid-area: content;
`

const BannerTrailingContent = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-left: ${rem(4)};
  margin-inline-start: ${rem(4)};

  ${mediaQueryUp.tablet} {
    margin-left: ${rem(8)};
    margin-inline-start: ${rem(8)};
  }
`

const BannerActionsGroup = styled(Group)`
  grid-area: actions;
  margin: ${rem(8)} 0 0 0;

  ${mediaQueryUp.tablet} {
    margin: 0 0 0 ${rem(12)};
  }
`

type Props = {
  /** sets the colour of the banner */
  kind:
    'informational-primary' |
    'informational-secondary' |
    'promotional-primary' |
    'promotional-primary-accent' |
    'promotional-secondary' |
    'success' |
    'warning' |
    'critical'
  /**
   * sets the horizontal alignment of the content
   * @default 'start'
   * */
  align?: 'start' | 'center'
  /**
   * sets the horizontal alignment of the content on tablet breakpoint and higher
   * @default falls back to `align`
   * */
  tabletAlign?: 'start' | 'center'
  /** sets the padding of the banner container */
  density?: 'comfortable' | 'compact'
  /** sets the icon at the start of the description */
  icon?: ReactElement
  /**
   * sets the textual content of the banner
   * can also be a complex node, but will still be affected by the typography styles
   *  */
  description: string | ReactElement
  /** sets the textual content that sits above the description as a heading */
  heading?: string | ReactElement
  className?: string
  id?: string
} & (
  {
    /**
     * sets the props passed into the primary action
     *
     * cannot define a primary action and trailing content at the same time
     *  */
    primaryActionButtonProps?: Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'>
    /**
     * sets the props passed into the secondary action
     *
     * cannot define a secondary action and trailing content at the same time
     *  */
    secondaryActionButtonProps?: Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'>
    trailingContent?: undefined
  } | {
    /**
     * sets the node trailing the description
     *
     * cannot define trailing content and actions at the same time
     *  */
    trailingContent?: ReactNode
    primaryActionButtonProps?: undefined
    secondaryActionButtonProps?: undefined
  }
)

const MessageBanner = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    kind,
    align = 'start',
    tabletAlign,
    density = 'comfortable',
    heading,
    description,
    icon,
    trailingContent,
    className,
    id,
    primaryActionButtonProps,
    secondaryActionButtonProps,
  } = props

  return <BannerSheet
    ref={ref}
    id={id}
    className={cn(
      className,
      `kind-${kind}`,
    )}
  >
    <BannerContainer
      className={cn(
      `align-${align}`,
      `align-on-tablet-${tabletAlign ?? align}`,
      `density-${density}`,
      )}
    >
      <BannerContent
        startIcon={icon}
        variant="medium"
        align={align}
        tabletAlign={tabletAlign}
      >
        <Group direction="vertical" gap={4}>
          {!!heading && <b>{heading}</b>}
          <div>
            {description}
            {!!trailingContent && <BannerTrailingContent>{trailingContent}</BannerTrailingContent>}
          </div>
        </Group>
      </BannerContent>

      {(primaryActionButtonProps || secondaryActionButtonProps) && <BannerActionsGroup
        direction="horizontal"
        horizontalAlign="end"
        gap={8}
      >
        {secondaryActionButtonProps && <TextButton {...secondaryActionButtonProps} kind="tertiary" />}
        {primaryActionButtonProps && <TextButton {...primaryActionButtonProps} kind="secondary" />}
      </BannerActionsGroup>}
    </BannerContainer>
  </BannerSheet>
})

export default memo(MessageBanner, (prevProps, nextProps) => {
  const {
    primaryActionButtonProps: prevPrimaryActionButtonProps,
    secondaryActionButtonProps: prevSecondaryActionButtonProps,
    ...prevRest
  } = prevProps
  const {
    primaryActionButtonProps: nextPrimaryActionButtonProps,
    secondaryActionButtonProps: nextSecondaryActionButtonProps,
    ...nextRest
  } = nextProps

  return shallowEqual(prevRest, nextRest) &&
    shallowEqual(prevPrimaryActionButtonProps, nextPrimaryActionButtonProps) &&
    shallowEqual(prevSecondaryActionButtonProps, nextSecondaryActionButtonProps)
})
