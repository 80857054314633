import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import React, { useMemo } from 'react'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import VillaCardInclusions from './VillaCardInclusions'
import LEOfferPriceDetails from './LEOfferPriceDetails'
import VillaLocationCaption from './VillaLocation'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary;
  onClick: () => void
  offerUrl: string;
  className?: string;
  size: 'small' | 'medium';
}

function VillaOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props

  const reviews = useMemo(() => ({
    showReviews: !!(offer.property.rating && showUserReviewsRating(offer.property.rating, offer.property.reviewsTotal)),
    rating: offer.property.rating,
    reviewsTotal: offer.property.reviewsTotal,
    ratingSubtitle: offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined,
  }), [offer.property])

  if (offer.isSoldOut) return null

  if (size === 'medium') {
    return <CarouselCardMedium
      className={className}
      bookmarkButton={<BookmarkButton offer={offer} />}
      image={offer.image}
      location={<VillaLocationCaption offer={offer} />}
      title={offer.name}
      providerName={offer.property.name}
      priceDetails={<LEOfferPriceDetails variant="condensed" offer={offer} />}
      to={offerUrl}
      reviews={reviews}
      description={<VillaCardInclusions offer={offer} offerUrl={offerUrl} />}
      urgencyLabels={<LabelGroup>
        <OfferLabels offer={offer} />
        {offer.badge && <OfferBadgeTag badge={offer.badge} />}
      </LabelGroup>}
      onClick={onClick}
    />
  } else if (size === 'small') {
    return <CarouselCardSmall
      className={className}
      bookmarkButton={<BookmarkButton offer={offer} />}
      description={<VillaCardInclusions offer={offer} offerUrl={offerUrl} />}
      image={offer.image}
      location={<VillaLocationCaption offer={offer} />}
      title={offer.property.name}
      priceDetails={<LEOfferPriceDetails offer={offer} />}
      to={offerUrl}
      productType={offer.productType}
      reviews={reviews}
      urgencyLabels={<LabelGroup>
        <OfferLabels offer={offer} />
        {offer.badge && <OfferBadgeTag badge={offer.badge} />}
      </LabelGroup>}
      onClick={onClick}
    />
  }
  return null
}

export default VillaOfferCard
