import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import React from 'react'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'

interface Props {
  loyaltyInclusions: Array<App.PackageInclusion> | Array<App.OrderLoyaltyInclusion>,
}

function LuxPlusInclusionsList(props: Props) {
  const {
    loyaltyInclusions,
  } = props

  return (
    <VerticalSpacer gap={4} data-testid="luxury-plus-inclusions">
      {loyaltyInclusions.map((inclusion, idx) => {
        const isLoyaltyMemberInclusion = 'categoryIcon' in inclusion
        const InclusionIcon = getInclusionIcon(isLoyaltyMemberInclusion ? inclusion.categoryIcon : '')
        const { description } = inclusion
        const inclusionDescription = description?.startsWith('-') ? description.slice(1).trim() : description

        return (
          <BodyTextBlock
            key={idx}
            variant="medium"
            weight="bold"
            startIcon={<InclusionIcon colour="loyalty" size="S" />}
          >
            {inclusionDescription}
          </BodyTextBlock>
        )
      })}
    </VerticalSpacer>
  )
}

export default LuxPlusInclusionsList
