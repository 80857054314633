import TextButton from 'components/Luxkit/Button/TextButton'
import { rem } from 'polished'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getSherlogFields } from 'selectors/sherlogSelectors'
import LineCopyIcon from 'components/Luxkit/Icons/line/LineCopyIcon'
import { postSherLogRequest } from 'api/payment'
import LineEnvelopeSendIcon from 'components/Luxkit/Icons/line/LineEnvelopeSendIcon'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import LineFileDownloadIcon from '../../../../Luxkit/Icons/line/LineFileDownloadIcon'
import Tooltip from '../../../../Luxkit/Tooltip'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledButton = styled(TextButton)`
  margin-right: ${rem(8)};

  &:last-child {
    margin-left:  ${rem(0)};
  }
`

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

interface MappedProps {
  sherlogFields: App.SherlogFields;
  sherLogData: App.SherLogState;
}

function SherlogActionButtons({ sherLogData, sherlogFields }: MappedProps) {
  const [isSendingApi, setIsSendingApi] = useState(false)
  const [copySuccess, markCopySuccess] = useState(false)
  const [downloadSuccess, markDownloadSuccess] = useState(false)

  const onCopy = () => {
    let formattedOutput = ''
    for (const field of Object.keys(sherlogFields)) {
      formattedOutput += titleCase(field) + ' : ' + sherlogFields[field] + '\n'
    }
    navigator.clipboard.writeText(formattedOutput)
    markCopySuccess(true)
    setTimeout(() => { markCopySuccess(false) }, 2000)
  }

  const onDownload = () => {
    const payload = {
      traceId: sherlogFields.trace_id,
      sherlogFields,
      logData: sherLogData.logData,
    }

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(payload, null, 2))
    hiddenElement.target = '_blank'

    hiddenElement.download = `sherlog_app_state_trace_id_${sherlogFields.trace_id}_timestamp_${Date.now()}.json`
    hiddenElement.click()
    markDownloadSuccess(true)
    setTimeout(() => { markDownloadSuccess(false) }, 2000)
  }

  const onSendLogs = async() => {
    try {
      setIsSendingApi(true)
      await postSherLogRequest({
        traceId: sherlogFields.trace_id,
        logData: sherLogData.logData,
      })
      setIsSendingApi(false)
    } catch (err) {
      setIsSendingApi(false)
    }
  }

  return (
    <ButtonsContainer>
      <Tooltip description="Copy the logs to clipboard" placement="top">
        <StyledButton
          kind="primary"
          startIcon={copySuccess ? <LineCheckIcon/> : <LineCopyIcon/>}
          onClick={onCopy}
        >
          Copy
        </StyledButton>
      </Tooltip>
      <Tooltip description="Send logs to LE internal logging platform" placement="top">
        <StyledButton
          kind="primary"
          startIcon={<LineEnvelopeSendIcon/>}
          disabled={isSendingApi}
          onClick={onSendLogs}
        >
          Send
        </StyledButton>
      </Tooltip>
      <Tooltip description="Download the logs to your computer" placement="top">
        <StyledButton
          kind="primary"
          startIcon={downloadSuccess ? <LineCheckIcon/> : <LineFileDownloadIcon/>}
          onClick={onDownload}
        >
          Download
        </StyledButton>
      </Tooltip>
    </ButtonsContainer>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  sherlogFields: getSherlogFields(state),
  sherLogData: state.sherLog,
})

export default connect(mapStateToProps)(SherlogActionButtons)
