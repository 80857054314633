import createSelector from 'lib/web/createSelector'
import { eligibleLuxPlusCheckoutNotGift } from 'checkout/selectors/view/luxPlusSubscription'
import { prioritisedSubscriptionItem } from 'selectors/loyaltySelectors'
import { checkoutAccommodationOfferView, getAccommodationItems } from 'checkout/selectors/view/accommodation'
import { max, nonNullable, sum, uniqueBy } from 'lib/array/arrayUtils'
import { isLEOffer } from 'lib/offer/offerTypes'
import loyaltyInclusionFilter from 'lib/offer/loyaltyInclusionFilter'
import { getFlightTotals } from 'checkout/selectors/payment/flights'
import { getHotelOfferUrl } from 'lib/offer/offerPageURL'
import { shouldShowLuxPlusUpsellForTour } from 'checkout/selectors/tourV2Selectors'
import { isSpoofed } from 'selectors/featuresSelectors'
import { excludeNullOrUndefined } from 'checkout/utils'
import { isActiveSubscribedLuxPlusMember } from './featureToggle'

function mapSubscriptionBenefitType(config: App.OfferLuxPlusConfig): App.OrderSubscriptionBenefit['type'] | undefined {
  if (config?.access === 'earlyAccess') return 'early_access'
  if (config?.access === 'memberOnly') return 'exclusive_offers'
}

// We currently only use this to record a redeemed benefit for early access or member only offers
const getOrderSubscriptionBenefits = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.offers,
  (items, offers): Array<App.OrderSubscriptionBenefit> => {
    return items.map((item) => {
      const offer = offers?.[item.offerId]
      const type = offer?.luxPlus ? mapSubscriptionBenefitType(offer.luxPlus) : undefined

      if (!type) return undefined

      return {
        type,
        itemTransactionKey: item.transactionKey,
        /*
          Order maps the itemType 'hotel' to 'accommodation'
          but this is not set up for the subscriber benefits so
          hard-coding this for now
        */
        itemType: 'accommodation',
        amount: 1,
        unit: 'units' as const,
      }
    }).filter(excludeNullOrUndefined)
  },
)

export const getOrderSubscriber = createSelector(
  eligibleLuxPlusCheckoutNotGift,
  prioritisedSubscriptionItem,
  getOrderSubscriptionBenefits,
  (eligibleLuxPlusCheckoutNotGift, subscriptionItem, benefits): App.OrderSubscriber | undefined => {
    if (eligibleLuxPlusCheckoutNotGift) {
      return {
        benefits,
        tier: subscriptionItem?.tier ?? 'base',
      }
    }
    return undefined
  },
)

export const getCheckoutBenefitsView = createSelector(
  checkoutAccommodationOfferView,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.loyalty.products.subscription,
  getFlightTotals,
  shouldShowLuxPlusUpsellForTour,
  isSpoofed,
  (accommodationOfferView, regionCode, productSubscription, flightTotal, showLuxPlusUpsellForTour, isSpoofed): App.WithDataStatus<App.Checkout.LuxPlusCheckoutBenefitsView | undefined> => {
    const hotelViews = accommodationOfferView.data.flatMap<App.Checkout.LEAccommodationItemView>(view => {
      return view.itemViews.filter(itemView => itemView.kind === 'le')
    })
    const toursV2Views = accommodationOfferView.data.flatMap<App.Checkout.TourV2AccommodationItemView>(view => {
      return view.itemViews.filter(itemView => itemView.kind === 'tourV2')
    })

    const isBundle = accommodationOfferView.data.some(view => view.isBundled)
    const flightsPrice = flightTotal.data.price
    const isTourV2 = toursV2Views.length > 0
    const isHotel = hotelViews.length > 0

    let totalPrice = 0
    let totalMemberPrice = 0
    let hasSavingsGreaterThanSubscriptionFee = false
    let totalSavings = 0

    if (isHotel) {
      totalPrice = sum(hotelViews, view => view.price + (view.surcharge ?? 0) + (view.otherFees?.propertyFees ?? 0))
      totalMemberPrice = sum(hotelViews, view => view.memberPrice > 0 ? view.memberPrice + (view.surcharge ?? 0) + (view.otherFees?.propertyFees ?? 0) : 0)
    }

    if (isTourV2) {
      totalPrice = sum(toursV2Views, view => view.price * (view.occupancy?.adults ?? 1))
      totalMemberPrice = sum(toursV2Views, view => Number(view.memberPrice) * (view.occupancy?.adults ?? 1))
    }

    if (isBundle) {
      totalPrice = totalPrice + flightsPrice
      totalMemberPrice = totalMemberPrice > 0 ? totalMemberPrice + flightsPrice : 0
    }

    totalSavings = totalMemberPrice > 0 ? totalPrice - totalMemberPrice : 0

    const schedules = nonNullable(accommodationOfferView.data.map(view => {
      if (isLEOffer(view.offer)) {
        return view.offer.visibilitySchedules[regionCode] ?? view.offer.visibilitySchedules.world
      }
      return undefined
    }))

    let priceStackMode: App.Checkout.LuxPlusCheckoutBenefitsView['priceStackMode'] = 'regular-only'

    const hasEarlyAccess = accommodationOfferView.data.some(view => view.offer?.luxPlus?.access === 'earlyAccess')
    const isMemberOnly = accommodationOfferView.data.some(view => view.offer?.luxPlus?.access === 'memberOnly')
    const membershipRequired = hasEarlyAccess || isMemberOnly
    const offersWithJoinFeeWaived = productSubscription?.offers?.filter(offer => offer.joinFeeWaived)
    const defaultOffer = offersWithJoinFeeWaived?.[0]

    if ((totalMemberPrice > 0) && ((totalPrice - totalMemberPrice) > (defaultOffer?.price ?? 0))) {
      hasSavingsGreaterThanSubscriptionFee = true
    }

    if (isMemberOnly || totalMemberPrice > 0) priceStackMode = 'member-checkout-total'

    const generalAccessDate = max(schedules, schedule => new Date(schedule.start))?.start

    let hasLuxPlusInclusions = false
    const subscriptionViews = offersWithJoinFeeWaived?.map<App.Checkout.LuxPlusCheckoutBenefitsSubscriptionView>(offer => {
      const inclusions = hotelViews.flatMap(view => {
        const loyaltyInclusions = view.pkg?.loyaltyInclusionTypes?.[offer.tier] ?? []
        return loyaltyInclusionFilter(loyaltyInclusions, [...view.inclusionData.bonusInclusions, ...view.inclusionData.inclusions])
      })
      if (inclusions.length > 0) {
        hasLuxPlusInclusions = true
      }
      return {
        inclusions: uniqueBy(inclusions, incl => incl.description),
        offer,
      }
    }) ?? []

    const hasRequiredData = !!productSubscription.offers?.length && !productSubscription.fetching && accommodationOfferView.hasRequiredData

    if (!!productSubscription.error ||
      (!membershipRequired &&
      !subscriptionViews.some(view => view?.inclusions.length > 0) &&
      totalMemberPrice === 0 &&
      !showLuxPlusUpsellForTour)
    ) {
      return {
        hasRequiredData: hasRequiredData || !!productSubscription.error,
        data: undefined,
      }
    }

    const firstAccommodationOffer = accommodationOfferView.data[0].offer as App.Offer | Tours.TourV2Offer | undefined

    return {
      data: {
        totalPrice,
        totalMemberPrice,
        hasEarlyAccess,
        isMemberOnly,
        membershipRequired: membershipRequired && !isSpoofed,
        durationLabel: accommodationOfferView.data.map(view => view.durationLabel).join(', '),
        offerTitle: accommodationOfferView.data.map(view => view.subLabel ?? view.mainLabel).join(', '),
        subscriptionViews,
        generalAccessDate: hasEarlyAccess ? generalAccessDate : undefined,
        isBundledWithFlights: isBundle,
        isTourWithMemberPrice: isTourV2 && totalMemberPrice > 0,
        returnToAccommodationOfferPageUrl: firstAccommodationOffer ? getHotelOfferUrl(firstAccommodationOffer?.id, firstAccommodationOffer.type) : undefined,
        priceStackMode,
        hasSavingsGreaterThanSubscriptionFee,
        firstAccommodationOffer,
        hasLuxPlusInclusions,
        totalSavings,
      },
      hasRequiredData,
    }
  },
)

export const getIsLuxPlusMemberRedeemingBenefitSchemaRequest = createSelector(
  isActiveSubscribedLuxPlusMember,
  getCheckoutBenefitsView,
  (state: App.State) => state.checkout.cart.isGift,
  (isActiveSubscribedLuxPlusMember, checkoutBenefitsView, isGift): boolean => {
    const hasBenefitsWithEditablePrimaryTravellerDetails = checkoutBenefitsView.data?.isBundledWithFlights || checkoutBenefitsView.data?.isTourWithMemberPrice
    const hasBenefitsWithLockedPrimaryTravellerDetails = (checkoutBenefitsView.data?.isMemberOnly ||
      checkoutBenefitsView.data?.hasEarlyAccess ||
      checkoutBenefitsView.data?.hasLuxPlusInclusions ||
      (checkoutBenefitsView.data?.totalMemberPrice ?? 0) > 0)

    return isActiveSubscribedLuxPlusMember &&
    !isGift &&
    !hasBenefitsWithEditablePrimaryTravellerDetails &&
    hasBenefitsWithLockedPrimaryTravellerDetails
  })
