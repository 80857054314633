import { arrayToObject } from 'lib/array/arrayUtils'
import { rem } from 'polished'
import { css } from 'styled-components'

// Base 8 with some half sizes at the lower end
export const spacingOptions = [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56] as const
export type SpacingOption = typeof spacingOptions[number]

// Only needed because arrayToObject gets angry if we try to pass it a const array
const optionsArray = [...spacingOptions]

/** @deprecated Should probably be using a VerticalSpacer or Group */
export const verticalChildSpacing = arrayToObject(optionsArray, o => o, (gap: SpacingOption) => css`
  & > * + * {
    margin-top: ${rem(gap)};
  }
`) as Record<SpacingOption, ReturnType<typeof css>>

/** @deprecated Should probably be using a Group */
export const horizontalChildSpacing = arrayToObject(optionsArray, o => o, (gap: SpacingOption) => css`
  & > * + * {
    margin-left: ${rem(gap)};
  }
`) as Record<SpacingOption, ReturnType<typeof css>>
