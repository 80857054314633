import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import HighlightBorder from 'components/Common/HighlightBorder'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BinocularsIcon from 'components/Luxkit/Icons/custom/BinocularsIcon'
import cn from 'clsx'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'

const StyledHighlightBorder = styled(HighlightBorder)`
  box-shadow: inset 0 0 0 2px;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: unset;
`

const HighlightMessageBox = styled.div`
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  padding: ${rem(12)};
  display: flex;
  align-items: center;

  &.tertiary {
    background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
    color: ${props => props.theme.palette.neutral.default.two};
  }
  > * + * {
    margin-left: ${rem(12)};
  }
`

interface Props {
  message: string;
  hideIcon?: boolean
  info?: boolean
}

function HighlightMessage({ message, hideIcon, info }: Props) {
  return <>
    <StyledHighlightBorder className={cn({ 'highlight-tertiary': info })} visible/>
    <HighlightMessageBox className={cn({ tertiary: info })}>
      {info && <SolidInfoCircleIcon />}
      {!hideIcon && !info && <BinocularsIcon/>}
      <BodyText variant="medium" weight="bold">{message}</BodyText>
    </HighlightMessageBox>
  </>
}

export default HighlightMessage
