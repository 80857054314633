import { Models } from '@luxuryescapes/lib-flights-types'
import { FlightSelectParams, selectflights } from 'api/flights'
import { mapAddedBaggageToPaxMap } from 'checkout/lib/utils/flights/extras'
import { getOtherFees } from 'checkout/lib/utils/flights/fees'
import { buildBaggageMapFromFares, applyTravelFusionComplementaryReducer } from 'checkout/lib/utils/flights/finalFares'
import { isOneWayFlight } from 'lib/flights/flightUtils'
import { isEmptyObject } from 'lib/object/objectUtils'
import { AppDispatch } from '../store'
import { API_CALL, CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE, CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE } from './actionConstants'
import { FETCH_FINAL_FLIGHT_FARES } from './apiActionConstants'
import { OptimizelyExperiments } from 'constants/optimizely'

type Actions = Utils.FullActionMap<{
  [CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE]: { itemId: string, journeyType: 'returning' | 'departing', paxBaggageMap: App.Checkout.PaxBaggageMap }
  [CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE]: { itemId: string, newTotalFare: number, newDepartingTotalFare?: number, newReturningTotalFare?: number },
}>

export function updateFlightPaxBaggage(
  itemId: string,
  journeyType: 'departing' | 'returning',
  paxBaggageMap: App.Checkout.PaxBaggageMap,
): Actions['CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE'] {
  return {
    type: CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE,
    itemId,
    journeyType,
    paxBaggageMap,
  }
}

function applyComplementaryBaggageSelections(
  dispatch: AppDispatch,
  selectedFares: Array<{ flightGroups: Models.Search.FlightGroup }>,
  targetItem: App.Checkout.FlightItem,
) {
  const passengers = targetItem.passengers
  const flightGroupFares = selectedFares?.map(fare => fare.flightGroups[0]) || []
  const oneWay = isOneWayFlight(targetItem.fareType)

  const isComplementaryBaggageApplicable = (oneWay && flightGroupFares.length === 1) || (!oneWay && flightGroupFares.length === 2)

  if (isComplementaryBaggageApplicable) {
    const departingComplementaryQuantityMap = buildBaggageMapFromFares(flightGroupFares[0])

    if (!isEmptyObject(departingComplementaryQuantityMap)) {
      const departingMap = passengers.reduce<App.Checkout.PaxBaggageMap>(
        applyTravelFusionComplementaryReducer(departingComplementaryQuantityMap), {})
      dispatch(updateFlightPaxBaggage(targetItem.itemId, 'departing', departingMap))
    }

    if (!oneWay) {
      const returningComplementaryQuantityMap = buildBaggageMapFromFares(flightGroupFares[1])
      if (!isEmptyObject(returningComplementaryQuantityMap)) {
        const returningMap = passengers.reduce<App.Checkout.PaxBaggageMap>(
          applyTravelFusionComplementaryReducer(returningComplementaryQuantityMap), {})
        dispatch(updateFlightPaxBaggage(targetItem.itemId, 'returning', returningMap))
      }
    }
  }
}

export function getCheckoutFlightItemFinalFare(item: App.Checkout.FlightItem) {
  return function(dispatch, getState) {
    const state = getState() as App.State
    if (item.searchId && item.departing && (item.fareType !== 'return' || item.returning)) {
      const selectedFareIds =
        item.journeyType === 'journeyV2' ?
            [
              item.departing.journeyId,
              ...(item.returning ? [item.returning.journeyId] : []),
            ] :
          undefined

      const params: FlightSelectParams = {
        journeyId: item.searchId,
        departingJourneyKey: item.departing.journeyKey,
        departingFareFamilyId: item.departing.fareFamily?.id,
        returningJourneyKey: item.fareType == 'return' ? item.returning?.journeyKey : undefined,
        returningFareFamilyId: item.returning?.fareFamily?.id,
        departingAirport: item.originAirportCode,
        arrivalAirport: item.destinationAirportCode,
        providerSearchId: item.departing.providerSearchId,
        region: state.geo.currentRegionCode,
        isStandalone: !item.bundledItemIds?.length,
        provider: item.departing.provider,
        isFlightsCredit: item.isFlightCredit,
        viewType: item.viewType,
        selectedFareIds,
        isFlightMerchantFeeExperimentOn: state.optimizely.optimizelyExperiments[OptimizelyExperiments.paymentsFlightsMerchantFees]?.enabled,
        forceBundleId: item.forceBundleId,
      }

      dispatch({
        itemId: item.itemId,
        type: API_CALL,
        api: FETCH_FINAL_FLIGHT_FARES,
        request: () => selectflights(params).then(data => {
          const { CommonPriceDetails, selectedFares } = data.result
          const totalFare = CommonPriceDetails.find(item => item.passengerTypeQuantity.code == 'ALL')?.totalFareOriginalCurrency
          const otherFees = getOtherFees(CommonPriceDetails, item)

          if (totalFare) {
            applyComplementaryBaggageSelections(dispatch, selectedFares, item)
          }

          const [departingCost, returningCost] =
            selectedFares?.map(fare => Number(fare.price.all?.totalFareOriginalCurrency)) ?? [undefined, undefined]

          return {
            totalFare,
            otherFees,
            departingCost,
            returningCost,
            journeysById: state.flights.journeysById,
          }
        }),
      })
    }
  }
}

export function updateFlightTotalFare(itemId: string, newTotalFare: number, newDepartingTotalFare?: number, newReturningTotalFare?: number): Actions['CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE'] {
  return {
    type: CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE,
    itemId,
    newTotalFare,
    newDepartingTotalFare,
    newReturningTotalFare,
  }
}

export function updateCheckoutFlightCheckedBaggage(
  itemId: string,
  journeyType: 'departing' | 'returning',
  baggage: Array<Array<App.AddedBaggage>>,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const flightItem = state.checkout.cart.items.find(item => item.itemId === itemId)
    if (flightItem?.itemType === 'flight') {
      const paxBaggageMap = mapAddedBaggageToPaxMap(flightItem.passengers, baggage)

      dispatch(updateFlightPaxBaggage(itemId, journeyType, paxBaggageMap))
    }
  }
}
