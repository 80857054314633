import { RefundRequestActions } from 'actions/RefundRequestActions'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  UPDATE_CURRENT_REFUND_REQUEST,
} from 'actions/actionConstants'
import { FETCH_REFUND_REQUEST } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialRefundRequestState: App.RefundRequestState = {
  fetchingRefundRequest: false,
  refundRequestError: undefined,
  refundRequests: {},
}

const apiRequests = reducerSwitch<App.RefundRequestState>({
  [FETCH_REFUND_REQUEST]: (state) => ({
    ...state,
    fetchingRefundRequest: true,
    refundRequestError: undefined,
  }),
  [UPDATE_CURRENT_REFUND_REQUEST]: (state) => ({
    ...state,
    fetchingRefundRequest: true,
    refundRequestError: undefined,
  }),
})

const apiSuccesses = reducerSwitch<App.RefundRequestState>({
  [FETCH_REFUND_REQUEST]: (state, action) => {
    return {
      ...state,
      fetchingRefundRequest: false,
      refundRequestError: undefined,
      refundRequests: {
        ...state.refundRequests,
        [action.key]: action.data,
      },
    }
  },
})

const apiFailures = reducerSwitch<App.RefundRequestState>({
  [FETCH_REFUND_REQUEST]: (state, action) => ({
    ...state,
    fetchingRefundRequest: false,
    refundRequestError: action.error.message ?? action.error,
  }),
})

const refundRequestReducer = createReducer<
  App.RefundRequestState,
  RefundRequestActions
>(initialRefundRequestState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) =>
    apiSuccesses(action.api)(state, action),
  [UPDATE_CURRENT_REFUND_REQUEST]: (state, action) => {
    return {
      ...state,
      fetchingRefundRequest: false,
      refundRequestError: undefined,
      refundRequests: {
        ...state.refundRequests,
        [action.key]: action.refundRequest,
      },
    }
  },
})

export default refundRequestReducer
