import OfferLabels from 'components/Common/Labels/OfferLabels'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { encodeOfferIds } from 'lib/search/searchUtils'
import SearchTileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileCancellationPolicy'
import Group from 'components/utils/Group'
import { queryKeySelectedOfferIds } from 'constants/url'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import GeoContext from 'contexts/geoContext'
import getUrgencyLabels from 'lib/offer/getUrgencyLabels'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import React, { useCallback, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import OfferTileLocationSection from '../TileSections/OfferTileLocationSection'
import OfferTilePropertyHeading from '../TileSections/OfferTilePropertyHeading'
import SearchRentalAmenities from './SearchVillaAmenities'
import { take } from 'lib/array/arrayUtils'
import { getCapacityTitle } from 'lib/homesAndVillas/getCapacityTitle'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import VillasPageAmenitiesModalContent from 'components/OfferPage/Villas/VillasPageAmenitiesModalContent'
import Modal from 'components/Luxkit/Modal/Modal'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import NumberRating from 'components/Common/NumberRating'
import { getPropertyCategory } from 'lib/homesAndVillas/getPropertyCategory'
import SearchTileStaticCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileStaticCancellationPolicy'
import {
  getOrderedAmenitiesFromPackage,
} from 'lib/homesAndVillas/getAmenityPriority'
import { getBestVillasDiscounts } from 'components/OfferPage/Villas/utils/calculateDiscount'
import { VILLAS_DISCOUNT_LABEL_COUNT } from 'constants/villas'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'

interface MappedStateProps {
  windowSearch: string;
}

interface Props {
  location: string;
  cancellationPolicyType: string;
  offer: App.VillaOffer;
  filters: App.OfferListFilters | undefined;
  offerUrl: string;
}

function SearchVillaPropertyDetails(props: Props & MappedStateProps) {
  const {
    location,
    cancellationPolicyType,
    offer,
    windowSearch,
    filters,
    offerUrl,
  } = props

  const {
    property,
    name,
    id,
  } = offer

  const { currentRegionCode } = useContext(GeoContext)

  const { searchTargetLabel, offerDistanceFromSearchTarget, searchType } =
    useContext(GlobalSearchStateContext)
  const searchTargetDistance = offerDistanceFromSearchTarget?.[id]
  const showSearchDistance =
    searchType === 'landmark' &&
    !!searchTargetLabel &&
    typeof searchTargetDistance === 'number'

  const urgencyLabels = useMemo(() => getUrgencyLabels(offer, currentRegionCode), [offer, currentRegionCode])

  const { amenities, orderedAmenities } = useMemo(() => getOrderedAmenitiesFromPackage(offer.packages[0]), [offer])

  const showModal = useContext(ModalContext)
  const openAmenitiesModal = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    showModal(
      <Modal title="Amenities" primaryActionTo={offerUrl} primaryActionText="View offer">
        <VillasPageAmenitiesModalContent amenities={amenities} />
      </Modal>,
    )
  }, [showModal, amenities, offerUrl])

  const mapSearchUrl = useMemo(() => `/search/map?${setSearchParamValue(
    windowSearch,
    queryKeySelectedOfferIds,
    encodeOfferIds([offer]),
  )}`, [windowSearch, offer])

  const capacityTitle = useMemo(() => getCapacityTitle(offer), [offer])
  const propertyCategory = useMemo(() => getPropertyCategory(property.category), [property])

  const bestVillasDiscounts = getBestVillasDiscounts(offer.discounts, VILLAS_DISCOUNT_LABEL_COUNT)

  return (
    <Group direction="vertical" gap={8}>
      <Group direction="vertical" fullWidth gap={4}>
        {property && showUserReviewsRating(property.rating, property.reviewsTotal) &&
          <NumberRating
          variant="medium"
          rating={property.rating}
          total={property.reviewsTotal}
          ratingSubtitle={property.reviewsSource === 'google' ? 'From online reviews' : undefined}
        />
        }
        <OfferLabels offer={offer} urgencyLabels={urgencyLabels}/>
        <OfferTileLocationSection
          location={location}
          shouldShowSearchDistance={showSearchDistance}
          mapSearchUrl={mapSearchUrl}
          searchTargetDistance={searchTargetDistance}
          searchTargetLabel={searchTargetLabel}
        />
        <OfferTilePropertyHeading
            name={property?.name ?? name}
            descriptiveName={propertyCategory}
        />

        {bestVillasDiscounts.length > 0 && <ProductTypeLabel productType="limited_time_special" kind="plain" />}
        <BodyText variant="medium">{capacityTitle}</BodyText>
      </Group>
      {!filters?.checkIn && <SearchTileStaticCancellationPolicy cancellationPolicies={offer.packages[0]?.roomRate.cancellationPolicies} offerType={offer.type}/> }
      <SearchTileCancellationPolicy
        cancellationPolicyType={cancellationPolicyType}
        checkInDate={filters?.checkIn}
        timezoneOffset={offer?.property?.timezoneOffset}
        offerType={offer.type}
      />
      {amenities.length > 0 &&
        <Group direction="vertical" gap={16}>
          <SearchRentalAmenities amenities={take(orderedAmenities, 4)}/>
          <TextLink weight="bold" underline size="medium" onClick={openAmenitiesModal}>
            View all {amenities.length} amenities
          </TextLink>
        </Group>
      }
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>(
  (state): MappedStateProps => ({
    windowSearch: state.router.location.search,
  }),
)(SearchVillaPropertyDetails)
