import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'

interface Props {
  amenity: App.PackageRoomAmenity;
}

function VillasPageAmenity(props: Props) {
  const { amenity } = props

  const icon = amenity.name.toLowerCase().split(' ').join('_')
  const AmenityIcon = getInclusionIcon(icon)

  return (<BodyTextBlock
    key={amenity.name}
    variant="medium"
    format="titlecase"
    startIcon={<AmenityIcon data-testid={icon} size="S" />}
  >
    {amenity.name}
  </BodyTextBlock>)
}

export default React.memo(VillasPageAmenity)
