import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext } from 'react'
import LuxPlusLabel from './LuxPlusLabel'
import { isActiveLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import LuxPlusBenefitsModal from './modals/LuxPlusBenefitsModal'
import LuxPlusLabelContext from 'contexts/LuxPlusLabelContext'

interface MappedProps {
  isActiveLuxPlusMember: boolean
}
interface Props extends MappedProps {
  offer?: App.Offer | App.OfferSummary | Tours.TourV2Offer | Tours.TourV2OfferSummary;
  saveAmount?: number;
  type: App.LuxPlusLabelModalType;
  hideSaveAmountPlus?: boolean;
  memberInclusionsAmount?: number;
}

function LuxPlusLabelWithModal({ offer, isActiveLuxPlusMember, saveAmount, memberInclusionsAmount, type, hideSaveAmountPlus = false }: Props) {
  const showModal = useContext(ModalContext)
  const providerPage = useContext(LuxPlusLabelContext)

  const toggleModal = useCallback(() => {
    showModal(<LuxPlusBenefitsModal
      type={type}
      saveAmount={saveAmount}
      memberInclusionsAmount={memberInclusionsAmount}
      offer={offer}
      hideSaveAmountPlus={hideSaveAmountPlus}
      providerPage={providerPage}
    />)
  }, [hideSaveAmountPlus, memberInclusionsAmount, offer, providerPage, saveAmount, showModal, type])

  return (
    <LuxPlusLabel
      saveAmount={saveAmount}
      memberInclusionsAmount={memberInclusionsAmount}
      type={type}
      onClick={isActiveLuxPlusMember ? undefined : toggleModal}
      hideSaveAmountPlus={hideSaveAmountPlus}
    />
  )
}

const mapStateToProps = (state: App.State):MappedProps => ({
  isActiveLuxPlusMember: isActiveLuxPlusMember(state),
})

export default connect(mapStateToProps)(LuxPlusLabelWithModal)
