import useScrollEndsObserver from 'hooks/useScrollEndsObserver'
import React, { PropsWithChildren, forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { DropdownBehaviourDispatchContext } from './DropdownBehaviourContexts'
import { DropdownBehaviourDispatchActions } from './DropdownBehaviourState'

const DropdownBodyContainer = styled.div`
  grid-area: dropdown-body;
  overflow-y: auto;
  position: relative;
`

interface Props extends PropsWithChildren {
  className?: string
}

const BaseDropdownBody = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className,
  } = props
  const dispatch = useContext(DropdownBehaviourDispatchContext)
  const bodyRef = useRef<HTMLDivElement>(null)

  const {
    hasReachedVerticalEnd,
    hasReachedVerticalStart,
  } = useScrollEndsObserver(bodyRef, children)

  useImperativeHandle(ref, () => bodyRef.current!)

  useEffect(() => {
    dispatch({
      type: DropdownBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_END,
      hasReached: hasReachedVerticalEnd,
    })
  }, [dispatch, hasReachedVerticalEnd])

  useEffect(() => {
    dispatch({
      type: DropdownBehaviourDispatchActions.TOGGLE_BODY_HAS_REACHED_START,
      hasReached: hasReachedVerticalStart,
    })
  }, [dispatch, hasReachedVerticalStart])

  return <DropdownBodyContainer ref={bodyRef} className={className}>
    {children}
  </DropdownBodyContainer>
})

export default BaseDropdownBody
