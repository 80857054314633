import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId, getExperienceProductIdFromProvider } from 'analytics/snowplow/helpers/itemCategorisation'
import { getExperienceLocations } from './itemContextHelpers'

function getExperienceOrderItems(state: App.State, order: App.Order) {
  const orderItems = [...order.experienceItems, ...order.transferItems]
  return orderItems.map(orderItem => {
    const fullOffer = state.experience.experiences[orderItem?.experienceId]
    const { categoryId, productId } = getCategoryAndProductId(fullOffer.parentType, fullOffer)
    const finalProductId = getExperienceProductIdFromProvider(fullOffer.provider) || productId
    const locations = getExperienceLocations(fullOffer.location.name)
    const destinationCity = locations.destinationCity || undefined
    const originCity = locations.originCity || undefined
    const destinationCountry = locations.destinationCountry || undefined
    const originCountry = locations.originCountry || undefined
    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem.experienceId,
        categoryId,
        productId: finalProductId,
        duration: 1,
        travelStart: orderItem.date,
        travelEnd: orderItem.date,
        price: orderItem.total,
        offerLeadPrice: fullOffer.price,
        offerLeadDuration: '1',
        offerLeadUnit: fullOffer.ticketUnitLabel,
        currency: order.currencyCode,
        destinationCity,
        destinationCountry,
        originCity,
        originCountry,
        itemId: orderItem.id,
        bookingNumber: order.number,
        orderId: order.id,
      },
    )
  },
  )
}

export default getExperienceOrderItems
