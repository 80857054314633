import React, { FormEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SocialButtons from './AccountAccessSocialButtons'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { checkUserExists, getUserKeyFromPhone } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import BodyText from 'components/Luxkit/Typography/BodyText'
import AccountAccessLegalText from './AccountAccessLegalText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import LineGiftIcon from 'components/Luxkit/Icons/line/LineGiftIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import TrustIndicatorsMapper from './AccountAccessTrustIndicatorsMapper'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import LineUniversityIcon from 'components/Luxkit/Icons/line/LineUniversityIcon'
import noop from 'lib/function/noop'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { rem } from 'polished'
import styled from 'styled-components'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import * as Analytics from 'analytics/analytics'
import AccountAccessOfferPreview from './AccountAccessOfferPreview'
import { isCheckout } from 'selectors/checkoutSelectors'
import AccountAccessUserInput from './AccountAccessUserInput'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import useLuxPlusFreePreviewUtils from 'luxPlus/hooks/useLuxPlusFreePreviewUtils'
import { isLoggedIn } from 'selectors/accountSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { AccountAccessModalResult } from './AccountAccessModal'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'

interface LoginForm {
  email?: string;
  phoneNumber?: App.PhoneNumber;
}

const Or = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(12)};

  &::after,
  &::before {
    content: '';
    flex: 1;
    border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
    display: block;
  }
`

type LoginUIContent = Array<{
  Icon: JSX.Element;
  text: string;
}>;

type LoginUIData = {
  title: string;
  content: LoginUIContent;
};

const getLoginUIData = (isBookingFlow: boolean, signUpModalTitleEnabled: boolean, autoEnrollFreePreview: boolean, mode?: App.UiAccountModalMode): LoginUIData => {
  const modalTitleExperimentVariant = signUpModalTitleEnabled ? 'Exclusive prices and more with every booking' : 'Huge savings and more with every booking'

  if (mode === 'tripPlannerLogin') {
    return {
      title: 'Sign up to start planning your next escape',
      content: [
        { Icon: <LineHeartIcon />, text: 'Save your favourite offers' },
        { Icon: <LineCalendarIcon />, text: 'Easily plan your perfect trip' },
        { Icon: <LineUniversityIcon />, text: 'Explore must-see attractions' },
      ],
    }
  } else if (autoEnrollFreePreview) {
    return {
      title: `Sign in to access your exclusive ${LUXURY_PLUS.PROGRAM_NAME} free preview`,
      content: [],
    }
  } else {
    return {
      title: isBookingFlow ? 'Log in or sign up to book this exclusive member offer' : modalTitleExperimentVariant,
      content: [
        { Icon: <LineGiftIcon />, text: 'Unlock thousands of exclusive offers' },
        { Icon: <LineStarIcon />, text: 'Over a million trips taken every year' },
        { Icon: <LinePhoneIcon />, text: 'Our local team are available 24 hours, 7 days' },
      ],
    }
  }
}
interface Props {
  error?: any;
  isStoreMode: boolean;
  mode?: App.UiAccountModalMode
  onModeChange?: (mode: App.UiAccountModalMode, userId?: string) => void;
  hotelOfferDetails?: App.AccountModalOfferDetails;
}

function AccountAccessLoginStart(props: Props) {
  const {
    error,
    isStoreMode,
    mode,
    onModeChange = noop,
    hotelOfferDetails,
  } = props

  const formRef = useRef<HTMLFormElement>(null)
  const [userId, setUserId] = useState<string>('')
  const processing = useAppSelector(state => state.auth.processing)
  const user = useAppSelector(state => state.auth.users[userId])
  const isBooking = useAppSelector(isCheckout)
  const { shouldAutoEnrolFreePreview: autoEnrollFreePreview } = useLuxPlusFreePreviewUtils()
  const loggedIn = useAppSelector(isLoggedIn)
  const modalContext = useModalElementContext<AccountAccessModalResult>()

  useEffect(() => {
    if (!processing && user) {
      if (user.exists) {
        onModeChange(user.maskedPhone ? 'loginPasswordOTP' : 'loginPassword', user.id)
      } else {
        onModeChange('join', user.id)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, processing])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'sign_up_appear',
      action: 'impression',
      category: 'logging',
      type: 'operational',
    })
  }, [])

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  const dispatch = useAppDispatch()

  const reRef = useRef<ReCAPTCHA>(null)

  const onCheckEmail = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const loginData = formToObject<LoginForm>(event.currentTarget)
    Analytics.trackClientEvent({
      subject: 'sign_up_appear_continue',
      action: 'interaction',
      category: loginData.phoneNumber ? 'phone' : 'email',
      type: 'operational',
    })
    const userId = loginData.phoneNumber ? getUserKeyFromPhone(loginData.phoneNumber) : loginData.email
    setUserId(userId ?? '')
    dispatch(checkUserExists(loginData, reRef.current ?? undefined))
  }, [dispatch])

  const socialMediaLoginsEnabled = config.FACEBOOK_APP_ID || config.GOOGLE_APP_ID || config.APPLE_APP_ID
  const showSocialMediaButtons = !isStoreMode && socialMediaLoginsEnabled

  const isBookingFlow = isBooking || !!hotelOfferDetails

  const signUpModalTitleEnabled = useOptimizelyExperiment(OptimizelyExperiments.signUpModalTitleEnabled, !isBookingFlow)

  const { title, content } = useMemo(() => getLoginUIData(isBookingFlow, signUpModalTitleEnabled, autoEnrollFreePreview, mode), [autoEnrollFreePreview, mode, isBookingFlow, signUpModalTitleEnabled])

  const showTrustIndicators = config.LOGIN_TRUST_INDICATORS && !isBookingFlow && !autoEnrollFreePreview

  return (<>
    <ModalHeader title={title} />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={24}>
          <VerticalSpacer ref={formRef} gap={24} as="form" onSubmit={onCheckEmail} name="loginForm">
            {error?.message && <MessageBanner kind="critical" description={error.message} />}
            {isBookingFlow && <AccountAccessOfferPreview hotelOfferDetails={hotelOfferDetails} />}
            {!isBookingFlow && (
              <Group direction="vertical" gap={20}>
                <Group direction="vertical" gap={4}>
                  {content.map(({ Icon, text }) => <BodyTextBlock
                    key={text}
                    variant="medium"
                    startIcon={Icon}
                  >
                    {text}
                  </BodyTextBlock>)}
                </Group>
              </Group>
            )}
            {showTrustIndicators && <Group direction="horizontal" horizontalAlign="center">
              <TrustIndicatorsMapper />
            </Group>}
            <Group direction="vertical" gap={32} tabletGap={24}>
              <AccountAccessUserInput />
              <TextButton
                kind="primary"
                size="large"
                type="submit"
              >
                Continue
              </TextButton>
            </Group>
            {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={reRef} />}
          </VerticalSpacer>
          {showSocialMediaButtons && <>
            <Or>
              <BodyText colour="neutral-two" variant="medium">Other ways to log in</BodyText>
            </Or>
            <SocialButtons mode="login" renderId="accountAccessModal" inline/>
          </>}
          <AccountAccessLegalText />
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </>)
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
    isStoreMode: state.config.storeMode,
  }
}

export default connect(mapStateToProps)(AccountAccessLoginStart)
