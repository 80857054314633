import { useMemo } from 'react'

import { OfferTypeForTripItemTypes, useEditableTrips } from './api'

import { MAX_TRIP_NAME_CHARS } from 'tripPlanner/config'
import { extractDestinationFromOffer } from 'tripPlanner/utils/destination'
import { getUniqueTripName } from 'tripPlanner/utils/tripName'

const useNewTripNameFromOffer = (offer?: OfferTypeForTripItemTypes) => {
  const { data: trips } = useEditableTrips()

  return useMemo(() => {
    if (!trips || !offer) {
      return undefined
    }

    const destination = extractDestinationFromOffer(offer)

    if (destination) {
      const newTripName = getUniqueTripName(
        `${destination} Trip`,
        trips,
        MAX_TRIP_NAME_CHARS,
      )

      if (newTripName.length <= MAX_TRIP_NAME_CHARS) {
        return newTripName

        // If the trip name we've generated is too long, they'll have to choose one themselves
      }
    }
  }, [trips, offer])
}

export default useNewTripNameFromOffer
