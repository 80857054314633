import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import Caption from './Typography/Caption'
import { SVG_ICON_SIZE_CSS_VAR } from './SvgIcon'

export const PILL_HEIGHT = rem(24)

const PillElement = styled.span`
  height: ${PILL_HEIGHT};
  padding: 0 ${rem(8)};
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  border-radius: ${props => props.theme.borderRadius.round};

  > * + * {
    margin-left: ${rem(4)};
  }

  svg {
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(16)};
  }

  &.kind-primary {
    &.variant-success {
      background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
      color: ${props => props.theme.palette.highlight.primary.normalContrast};
    }

    &.variant-loyalty {
      background-color: ${props => props.theme.palette.product.loyalty.background};
      color: ${props => props.theme.palette.product.loyalty.contrast};
    }
  }

  &.kind-tertiary {
    &.variant-primary {
      background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      color: ${props => props.theme.palette.highlight.primary.lightContrast};
    }
  }
`

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  kind: 'primary' | 'tertiary';
  variant: 'success' | 'primary' | 'loyalty';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

function Pill(props: Props) {
  const {
    kind,
    variant,
    className,
    startIcon,
    endIcon,
    children,
    ...rest
  } = props

  return <PillElement
    {...rest}
    className={cn(
      className,
      `kind-${kind}`,
      `variant-${variant}`,
    )}
  >
    {startIcon}
    <Caption variant="medium" weight="bold">{children}</Caption>
    {endIcon}
  </PillElement>
}

export default Pill
