import React, { useCallback, useContext } from 'react'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import ExperiencePageTrendingDestinations from 'components/Experiences/ExperiencesPage/ExperiencesPageTrendingDestinations'
import styled from 'styled-components'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import LocationFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/LocationFloatingPanel'
import { rem } from 'polished'
import ExperienceCategoryPanel from '../Panels/ExperienceCategoryPanel'
import { SearchMenuStates } from 'components/Search/type'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import ExperienceSearchCategoryField from 'components/Experiences/ExperiencesSearch/ExperienceSearchBar/ExperienceSearchCategoryField'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { EXPERIENCE_SEARCH_TYPES } from 'constants/experience'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TrendingDestinations = styled(ExperiencePageTrendingDestinations)`
  margin-top: ${rem(24)};
`

interface Props {
  closeSearch: () => void;
  onSearch: () => void;
}

export default function ExperiencesTabContent(props: Props) {
  const { closeSearch, onSearch } = props

  const {
    searchItem,
    activeMenu,
  } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const toggleLocationModalVisibility = useCallback(() => {
    const nextMenu = activeMenu === SearchMenuStates.Locations ? SearchMenuStates.Closed : SearchMenuStates.Locations
    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: nextMenu })
  }, [activeMenu, searchDispatch])

  const toggleCategorySearchVisibility = useCallback(() => {
    const nextMenu = activeMenu === SearchMenuStates.ExperienceCategories ? SearchMenuStates.Closed : SearchMenuStates.ExperienceCategories
    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: nextMenu })
  }, [activeMenu, searchDispatch])

  const onTabSearch = useCallback(() => {
    toggleLocationModalVisibility()
    onSearch()
  }, [toggleLocationModalVisibility, onSearch])

  return (
    <ContentWrapper>
      <LocationFloatingPanel
        isOpen={activeMenu === SearchMenuStates.Locations}
        onClose={toggleLocationModalVisibility}
        onGoBack={toggleLocationModalVisibility}
        onConfirm={onTabSearch}
        searchTypes={EXPERIENCE_SEARCH_TYPES}
      />
      <ExperienceCategoryPanel
        open={activeMenu === SearchMenuStates.ExperienceCategories}
        onClose={toggleCategorySearchVisibility}
      />
      <VerticalSpacer gap={12}>
        <SearchFormField
          label="Where"
          placeholder="Search destination"
          value={searchItem?.format.mainText}
          onClick={toggleLocationModalVisibility}
        />
        <ExperienceSearchCategoryField onClick={toggleCategorySearchVisibility} />
      </VerticalSpacer>
      <TrendingDestinations colour="neutral-two" weight="normal" onClick={closeSearch} />
    </ContentWrapper>
  )
}
