import React, { FormEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { formToObject } from 'lib/forms/formToObject'
import TextButton from 'components/Luxkit/Button/TextButton'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import noop from 'lib/function/noop'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TextInput from 'components/Common/Form/Input/TextInput'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { sendOTPVerificationCode, validateOTPVerificationCode } from 'actions/AuthActions'
import { isLoggedIn } from 'selectors/accountSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { AccountAccessModalResult } from './AccountAccessModal'
import * as Analytics from 'analytics/analytics'
import HelpPane from 'components/Common/HelpPane'
import ContactContext from 'contexts/contactContext'
import TextLink from 'components/Luxkit/TextLink'
import useInterval from 'hooks/useInterval'

interface LoginForm {
  email: string;
  code: string;
}

interface Props {
  user: App.User;
  error?: any;
  onModeChange?: (mode: App.UiAccountModalMode) => void;
}

function AccountAccessLoginOTP(props: Props) {
  const {
    user,
    onModeChange = noop,
  } = props

  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const reRef = useRef<ReCAPTCHA>(null)
  const loggedIn = useAppSelector(isLoggedIn)
  const dispatch = useAppDispatch()
  const error = useAppSelector(state => state.auth.error)
  const [countDown, setCountDown] = useState<number>(30)
  const [submitEnabled, setSubmitEnabled] = useState(true)

  useInterval(
    () => { setCountDown((curr) => Math.max(curr - 1, 0)) },
    1000,
    { disabled: countDown <= 0 },
  )

  const currentContact = useContext(ContactContext)
  const contactNumber = currentContact.defaultContact

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    } else if (error) {
      setSubmitEnabled(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, error])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'sign_in_otp',
      action: 'impression',
      category: 'logging',
      type: 'operational',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResendCode = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'sign_in_otp_resend',
      action: 'interaction',
      category: 'logging',
      type: 'operational',
    })
    setCountDown(30)
    dispatch(sendOTPVerificationCode(user))
  }, [dispatch, user])

  const onValidateCode = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    setSubmitEnabled(false)
    const loginData = formToObject<LoginForm>(event.currentTarget)
    dispatch(validateOTPVerificationCode(user, loginData.code))
  }, [dispatch, user])

  const onBack = useCallback(() => onModeChange('loginPasswordOTP'), [onModeChange])

  return (<>
    <ModalHeader
      title="Verification code"
      onBackButtonClick={onBack}
    />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={24} as="form" onSubmit={onValidateCode} name="loginForm">
          {error?.status === 500 && <MessageBanner kind="critical" description="Something went wrong. Please try logging in with your password instead." />}
          {error?.message && error.status !== 500 && <MessageBanner kind="critical" description={error.message} />}
          <BodyText variant="medium">
            We’ve sent a 6-digit code to <strong>{user.maskedPhone}</strong>.{' '}
            The code is valid for 10 minutes and after that you will need to request a new one.
          </BodyText>
          <TextInput
            type="text"
            required
            id="code"
            name="code"
            label="6-digit code"
            placeholder="Enter 6-digit code"
            requiredErrorMessage="Code is required"
            minLength={6}
            maxLength={6}
            pattern="^\d*$"
            invalidErrorMessage="Your code must be 6 digits long"
            inputMode="numeric"
          />
          {(!error || (!!error && error.status !== 500)) && (
            <Group direction="vertical" gap={16} horizontalAlign="center">
              <TextButton
                kind="primary"
                size="large"
                type="submit"
                fit="flex"
                disabled={!submitEnabled}
              >
                Submit
              </TextButton>
              <Group direction="horizontal" gap={8}>
                <BodyText variant="medium">Didn't receive the SMS?</BodyText>
                <TextLink size="medium" onClick={onResendCode} disabled={countDown > 0}>
                  {countDown > 0 ? `Resend code in ${countDown}s` : 'Resend code'}
                </TextLink>
              </Group>
            </Group>
          )}
          {error?.status === 500 && (
            <HelpPane contactNumber={contactNumber} />
          )}
          {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={reRef} />}
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </>
  )
}

export default AccountAccessLoginOTP
