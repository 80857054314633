import { CANCELLATION_POLICIES, CANCELLATION_POLICY_DAYS, FORTY_FIVE_DAYS_BEFORE_CHECK_IN, SEVEN_DAYS_AFTER_PURCHASE, TWENTY_ONE_DAYS_BEFORE_CHECK_IN } from 'constants/cancellationPolicies'
import { dateIsAfter, dateIsBefore, addDays } from 'lib/datetime/dateUtils'
import moment from 'moment'
import { Reservation } from '@luxuryescapes/contract-svc-reservation'

const ALL_FLEXIBLE_CANCELLATION_POLICIES = [
  'partner-policy-prior-to-check-in-twenty-one-days',
  'flexible-cancellation-forty-five-days',
]

export const isConnectionCancellationPolicy = (cancellationPolicy: App.OrderCancellationPolicyTypes): cancellationPolicy is App.OrderConnectionCancellationPolicy => ('policyCode' in cancellationPolicy)

export const isCancellationPolicy = (cancellationPolicy: App.OrderCancellationPolicyTypes): cancellationPolicy is App.OrderCancellationPolicy => ('type' in cancellationPolicy)

export const creditsOnlyCancellationPolicy = (cancellationPolicy: App.OrderCancellationPolicyTypes) => {
  return cancellationPolicy && isCancellationPolicy(cancellationPolicy) &&
    cancellationPolicy.type === 'credit-only-prior-to-check-in-fourteen-days'
}

export const isFlexibleCancellationPolicy = (
  cancellationPolicy: App.OrderCancellationPolicyTypes | undefined,
): cancellationPolicy is App.OrderCancellationPolicyTypes => {
  return !!cancellationPolicy && isCancellationPolicy(cancellationPolicy) &&
    ALL_FLEXIBLE_CANCELLATION_POLICIES.includes(cancellationPolicy.type)
}

export const isWithinSevenDays = (purchaseDate, checkIn, now) => {
  return dateIsAfter(addDays(new Date(purchaseDate), 7), new Date(now)) && dateIsBefore(addDays(new Date(now), 14), new Date(checkIn))
}

export const getFlexibleCancellationDays = (cancellationPolicyType: Reservation.CancellationPolicy): typeof FORTY_FIVE_DAYS_BEFORE_CHECK_IN | typeof TWENTY_ONE_DAYS_BEFORE_CHECK_IN => {
  return cancellationPolicyType === CANCELLATION_POLICIES.FLEXIBLE_CANCELLATION_FORTY_FIVE_DAYS ?
    FORTY_FIVE_DAYS_BEFORE_CHECK_IN :
    TWENTY_ONE_DAYS_BEFORE_CHECK_IN
}

const isFreeLpcCancellationPolicy = (
  cancellationPolicy: App.OfferCancellationPolicy,
  checkIn: moment.Moment | undefined,
  propertyTimezoneOffset?: number,
): 'free' | undefined => {
  const days = CANCELLATION_POLICY_DAYS[cancellationPolicy.type]
  if (!days) return
  const startBasedOnCheckIn = checkIn ? ((propertyTimezoneOffset ? checkIn.utcOffset(propertyTimezoneOffset).subtract(days, 'days') : checkIn.subtract(days, 'days'))) : undefined
  const startBasedOnToday = propertyTimezoneOffset ? moment().utcOffset(propertyTimezoneOffset).add(SEVEN_DAYS_AFTER_PURCHASE, 'days') : moment().add(SEVEN_DAYS_AFTER_PURCHASE, 'days')
  let startDate: moment.Moment | undefined
  if (cancellationPolicy.type === 'post-purchase-seven-days') {
    if (!startBasedOnCheckIn) {
      startDate = startBasedOnToday
    } else {
      startDate = startBasedOnToday.isBefore(startBasedOnCheckIn) ? startBasedOnToday : startBasedOnCheckIn
    }
  } else {
    startDate = startBasedOnCheckIn
  }
  const refundable = (!startDate || startDate?.isAfter(moment()))
  if (refundable) return 'free'
}

export const isFreeOrFlexibleLpcCancellation = (
  cancellationPolicy: App.OfferCancellationPolicy | undefined,
  checkIn: moment.Moment | undefined,
  propertyTimezoneOffset?: number,
):'flexible' | 'free' | undefined => {
  if (!cancellationPolicy?.type || cancellationPolicy.type == 'non-refundable') return
  const isFlexible = isFlexibleCancellationPolicy(cancellationPolicy)
  if (isFlexible) return 'flexible'
  return isFreeLpcCancellationPolicy(cancellationPolicy, checkIn, propertyTimezoneOffset)
}
