import React, { useCallback, useContext, useMemo } from 'react'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import LEOfferCard from './LEOfferCard'
import TourOfferCard from './TourOfferCard'
import TourV2OfferCard from './TourV2OfferCard'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import CruiseOfferCard from './CruiseOfferCard'
import BedbankOfferCard from './BedbankOfferCard'
import BundleAndSaveOfferCard from './BundleAndSaveOfferCard'
import offerPageURL from 'lib/offer/offerPageURL'
import VillaOfferCard from './VillaOfferCard'
import { isBedbank, isBundleOffer, isCruiseOffer, isLEHotel, isTourV1Offer, isTourV2Offer, isVillaOffer } from 'lib/offer/offerTypes'

interface Props {
  idx?: number;
  className?: string;
  offer: App.AnyOffer;
  additionalOfferUrlParams?: string;
  size: 'small' | 'medium';
}

function OfferCardMapper(props: Props) {
  const { offer, size, idx, className, additionalOfferUrlParams } = props
  const onEvent = useContext(OfferListEventsContext)
  const onClick = useCallback(() => {
    onEvent(OfferListEvents.productClick, { offer, position: idx, key: EventDataKey.ProductClick })
  }, [idx, offer, onEvent])

  const offerUrl = useMemo(() => {
    return offerPageURL(offer) + (additionalOfferUrlParams ? `?${additionalOfferUrlParams}` : '')
  }, [offer, additionalOfferUrlParams])

  if (isLEHotel(offer)) {
    return <LEOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isBundleOffer(offer)) {
    return <BundleAndSaveOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isBedbank(offer)) {
    return <BedbankOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isTourV1Offer(offer)) {
    return <TourOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isTourV2Offer(offer)) {
    return <TourV2OfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isCruiseOffer(offer)) {
    return <CruiseOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isVillaOffer(offer)) {
    return <VillaOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  }

  return null
}

export default OfferCardMapper
