// Based on the following docs from Google
// https://developers.google.com/tag-manager/enhanced-ecommerce
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce

import { GAImpressionFieldObject, GAProductFieldObject } from 'analytics/eventDefinitions'
import { PRODUCT_CLICK_SCHEMA, PRODUCT_IMPRESSION_SCHEMA } from 'constants/analytics'
import * as Analytics from 'analytics/analytics'
import { impressionEvent } from 'analytics/snowplow/events'

// Note some examples assume the app is server rendered.

interface EcommerceOptions {
  impressions?: Array<GAImpressionFieldObject>;
  click?: {
    actionField?: {
      list: string;
    },
    products: Array<GAProductFieldObject>;
  };
}

export const pushResetEcommerce = () => {
  if (typeof dataLayer === 'undefined') {
    return
  }
  dataLayer.push({ ecommerce: null })
}

function push(eventName: string, currency: string, data: EcommerceOptions, lereVersion?: string) {
  if (typeof dataLayer === 'undefined') {
    return
  }

  dataLayer.push({
    event: eventName,
    ecommerce: {
      currencyCode: currency,
      ...data,
    },
    ...(lereVersion ? { lereVersion } : {}),
  })
}

export function productImpression(
  currency: string,
  impressions: Array<GAImpressionFieldObject>,
  listDetails: { listId: string; listName: string; listSource?: string },
) {
  productImpressionSnowplow(currency, impressions, listDetails)
  // Google Tag Manager -> Google Analytics
  pushResetEcommerce()
  push('product-impression', currency, { impressions }, listDetails.listSource)
}

export const productImpressionSnowplow = (currency: string,
  impressions: Array<GAImpressionFieldObject>,
  listDetails: { listId: string; listName: string; listSource?: string },
) => {
  // Snowplow
  impressions.forEach(impression => {
    Analytics.trackEvent(impressionEvent(
      impression,
      listDetails.listId,
      listDetails.listName,
      currency,
      PRODUCT_IMPRESSION_SCHEMA,
      listDetails.listSource,
    ))
  })
}

export function productClickSnowplow(
  currency: string,
  product: GAProductFieldObject,
  listDetails: { listId: string; listName: string, listSource?: string },
) {
  Analytics.trackEvent(impressionEvent(
    product,
    listDetails.listId,
    listDetails.listName,
    currency,
    PRODUCT_CLICK_SCHEMA,
    listDetails.listSource,
  ))
}

export function productClick(
  currency: string,
  product: GAProductFieldObject,
  listDetails: { listId: string; listName: string; listSource?: string },
  clearEvent = true,
) {
  // Snowplow
  productClickSnowplow(currency, product, listDetails)

  // Google Tag Manager -> Google Analytics

  push('product-click', currency, {
    click: {
      ...(clearEvent ? { _clear: true } : {}),
      actionField: {
        list: listDetails.listName,
      },
      products: [product],
    },
  })
}

type ListPrefix = 'LERE' | 'OfferList' | 'TripPlanner' | 'CruisePromo' | 'CarHire'

export function getListName(prefix: ListPrefix, pageSource: string, uiTouchpiont: string) {
  return [
    prefix,
    pageSource,
    uiTouchpiont,
  ].join(' - ')
}
