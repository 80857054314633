import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/BookmarkButton'
import ExperienceBookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/ExperienceBookmarkButton'
import FlightBookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/FlightBookmarkButton'
import HotelBookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/HotelBookmarkButton'
import TourBookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/TourBookmarkButton'

export {
  FlightBookmarkButton,
  HotelBookmarkButton,
  TourBookmarkButton,
  ExperienceBookmarkButton,
}

export default BookmarkButton
