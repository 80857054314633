import React, { ComponentProps, PropsWithChildren, useRef } from 'react'
import FloatingTooltip from 'components/Luxkit/FloatingTooltip'
import TooltipTrigger from './TooltipTrigger'

interface Props extends PropsWithChildren, Omit<ComponentProps<typeof FloatingTooltip>, 'triggerRef'> {
  /**
   * Disables the tooltip behaviour
   */
  disabled?: boolean
  className?: string
}

/**
 * Display a descriptive tooltip message when the children are hovered.
 */
function Tooltip(props: Props) {
  const { children, className, disabled, ...tooltipProps } = props
  const triggerRef = useRef<HTMLSpanElement>(null)

  return <>
    <TooltipTrigger ref={triggerRef} className={className} disabled={disabled}>{children}</TooltipTrigger>
    {!disabled && <FloatingTooltip {...tooltipProps} triggerRef={triggerRef} />}
  </>
}

export default Tooltip
