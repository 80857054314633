import { getTourV2VariationDuration } from 'lib/tours/tourUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { TOUR_V2_PACKAGE_OPTION_BASE, tourOptionPackages } from 'constants/tours'
import { unique } from 'lib/array/arrayUtils'

export interface TourV2VariationDetails {
  hasExtension: boolean
  isBase: boolean
  countriesCount?: string
  description: string
  durationInDaysCount: string
  endLocation: string | null
  hasTheSameStartAndEndLocation: boolean
  placesCount?: string
  startLocation: string | null
  maxPax: number | null
  minAge: number | null
  maxAge: number | null
  inclusionItems: Tours.TourV2OfferVariation['inclusionItems']
  itineraryDestinations: Array<string>
  itineraryDestinationsDiff: Array<string>
  availablePackageUpgrades: Tours.TourV2OfferVariation['availablePackageUpgrades']
}

function extractTourV2VariationDetails(variation: Tours.TourV2OfferVariation): TourV2VariationDetails {
  const daysCount = getTourV2VariationDuration(variation)
  const hasExtension = tourOptionPackages.includes(variation.packageOptionName ?? '')
  const isBase = (hasExtension && variation.packageOptionName === TOUR_V2_PACKAGE_OPTION_BASE) || !hasExtension
  const extractedDetails: TourV2VariationDetails = {
    hasExtension,
    isBase,
    description: variation.description,
    startLocation: variation.startLocation,
    endLocation: variation.endLocation,
    hasTheSameStartAndEndLocation: variation.startLocation === variation.endLocation,
    durationInDaysCount: pluralizeToString('day', daysCount),
    maxPax: variation.maxPax ?? null,
    minAge: variation.minChildPriceAge ?? null,
    maxAge: variation.maxAge ?? null,
    inclusionItems: variation.inclusionItems,
    itineraryDestinations: variation.itineraryDestinations,
    itineraryDestinationsDiff: variation.itineraryDestinationsDiff,
    availablePackageUpgrades: variation.availablePackageUpgrades,
  }

  if (variation.countriesVisited.length) {
    extractedDetails.countriesCount = pluralizeToString('country', variation.countriesVisited.length)
  }
  if (variation.itinerary.length) {
    const uniquePlaces = getTourV2PlacesVisited(variation.itinerary)
    if (uniquePlaces.length) {
      extractedDetails.placesCount = pluralizeToString('place', uniquePlaces.length)
    }
  }

  return extractedDetails
}

export function formatTourV2VariationDetailCounts(variation: Tours.TourV2OfferVariation): {
  variationDetailsCountText: string,
} {
  const { countriesCount, durationInDaysCount, placesCount } = extractTourV2VariationDetails(variation)
  const counts = [durationInDaysCount, placesCount, countriesCount].filter(i => !!i)

  return {
    variationDetailsCountText: counts.join(' · '),
  }
}

export function getTourV2PlacesVisited(itinerary: Array<Tours.TourV2OfferItineraryItem>): Array<string> {
  return unique(itinerary
    .flatMap(item => item.locationsVisitedDetails)
    .map(location => location.name),
  )
}

export default extractTourV2VariationDetails
