import React, { FormEventHandler, useCallback, useEffect, useRef } from 'react'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { formToObject } from 'lib/forms/formToObject'
import { forgotPassword, login } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import TextLink from 'components/Luxkit/TextLink'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import BodyText from 'components/Luxkit/Typography/BodyText'
import PasswordInput from 'components/Common/Form/Input/PasswordInput'
import Group from 'components/utils/Group'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { connect } from 'react-redux'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import noop from 'lib/function/noop'
import { isLoggedIn } from 'selectors/accountSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { AccountAccessModalResult } from 'components/Account/AccountAccess/AccountAccessModal'

interface FormData {
  email: string
  password: string
}

interface MappedStateProps {
  authError?: App.ApiCallFailure
  isAuthProcessing?: boolean
}

interface Props {
  user: App.User;
  onModeChange?: (mode: App.UiAccountModalMode) => void;
}

function AgentHubPasswordLoginForm(props: Props & MappedStateProps) {
  const {
    authError,
    isAuthProcessing,
    user,
    onModeChange = noop,
  } = props

  const appDispatch = useAppDispatch()
  const modalContext = useModalElementContext<AccountAccessModalResult>()

  const loggedIn = useAppSelector(isLoggedIn)

  const formRef = useRef<HTMLFormElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(async(event) => {
    event.preventDefault()
    const formData = formToObject<FormData>(event.currentTarget)
    const recaptchaResponse = await recaptchaRef.current?.executeAsync()
    if (recaptchaResponse) {
      appDispatch(login({ email: formData.email }, formData.password, recaptchaResponse))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleForgotPassword = useCallback(() => {
    formRef.current?.reset()
    onModeChange('forgotPassword')
    appDispatch(forgotPassword(user?.email || ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    passwordInputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  const onBack = useCallback(() => {
    onModeChange('login')
  }, [onModeChange])

  return <>
    <ModalHeader
      title=" Welcome back"
      onBackButtonClick={onBack}
    />
    <ModalBody>
      <ModalContent>
        <form
          ref={formRef}
          name="agentHubPasswordLoginForm"
          data-testid="agent-hub-password-login-form"
          onSubmit={handleSubmission}
        >
          <Group direction="vertical" gap={20}>
            {!!authError?.message && <MessageBanner
              description={authError.message}
              kind="critical"
            />}

            <BodyText variant="medium">
              Please enter your password below to continue.
            </BodyText>

            <Group direction="vertical">
              <HiddenInput
                name="email"
                value={user.email}
              />
              <PasswordInput
                label="Password"
                required
                name="password"
                placeholder="Password"
                requiredErrorMessage="Password is required"
                ref={passwordInputRef}
              />

              <Group direction="vertical" gap={16}>
                <TextButton
                  kind="primary"
                  size="large"
                  type="submit"
                  disabled={isAuthProcessing}
                >
                  Log in
                </TextButton>

                <Group direction="horizontal" horizontalAlign="center">
                  <TextLink
                    size="medium"
                    onClick={handleForgotPassword}
                  >
                    Forgot your password?
                  </TextLink>
                </Group>
              </Group>
            </Group>
          </Group>
          {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={recaptchaRef} />}
        </form>
      </ModalContent>
    </ModalBody>
  </>
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    authError: state.auth.error,
    isAuthProcessing: state.auth.processing,
  }
})(AgentHubPasswordLoginForm)
