import TextLink from 'components/Luxkit/TextLink'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import SmallToggleButton from 'components/Luxkit/Button/SmallToggleButton'
import { rem } from 'polished'
import offerPageURL from 'lib/offer/offerPageURL'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import FormatCurrency from 'components/Common/FormatCurrency'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { isBedbank } from 'lib/offer/offerTypes'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Group from 'components/utils/Group'
import { pluralizeToString } from 'lib/string/pluralize'
import { EmptyArray, sum, unique } from 'lib/array/arrayUtils'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import SlideDown from '../SlideDown'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import Typography from 'components/Luxkit/Typography/Typography'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import useAgentHubCommission from 'agentHub/hooks/useAgentHubCommission'
import useToggle from 'hooks/useToggle'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'

const PriceDetailsModalExtraDesc = styled.div`
  width: 100%;
  background-color: ${props => props.theme.palette.brand.primary.lightest};
  padding: ${rem(12)};
  margin-top: ${rem(24)};
`

const PaymentSchedule = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(16)};
`

interface Props {
  offerPage?: boolean;
  offer: App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary;
  duration: number;
  taxesAndFees?: number;
  propertyFees?: number;
  hotelPrice: number;
  hotelMemberPrice?: number;
  showMemberPrice?: boolean;
  flightPrice?: number;
  otherFees?: any
  rooms?: Array<App.Occupants>;
  extraGuestMessage?: string;
  dueAtPropertyMessage?: string;
  propertiesFees?: Array<App.PropertyFeesDetails>;
  cta?: React.ReactNode;
  onCtaAfterClose?: () => void;
}

function PriceDetailsModal(props: Props) {
  const {
    offerPage,
    offer,
    duration,
    taxesAndFees = 0,
    propertyFees = 0,
    propertiesFees = EmptyArray,
    hotelPrice,
    flightPrice = 0,
    rooms,
    extraGuestMessage,
    dueAtPropertyMessage,
    cta,
    hotelMemberPrice = 0,
    showMemberPrice,
    otherFees,
  } = props
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const enabledShowMemberPrice = showMemberPrice && luxPlusEnabled

  const [expanded, expand] = useToggle(false)

  const { resolve } = useModalElementContext<'fine-print' | undefined>()

  const dueAtProperty = (otherFees?.propertyFees || 0) + (otherFees?.extraGuestsSurcharge || 0)
  const priceBeforeTax = hotelPrice - taxesAndFees - dueAtProperty + flightPrice
  const priceAfterTax = hotelPrice + flightPrice
  const memberPriceAfterTax = hotelMemberPrice + flightPrice

  const adultsNumber = useMemo(() => sum(rooms ?? [], room => room.adults) || 2, [rooms])

  const offerLocations = useMemo(() => {
    if (isBedbank(offer)) {
      const locations = [
        offer.property?.address.city,
        offer.property?.address.stateProvinceName || '',
        offer.property?.address.countryName]
        .filter(Boolean)
      return locations
    }

    const locations = unique(offer.locations.concat(offer.location))

    return locations
  }, [offer])

  const vendorName = useMemo(() => {
    if (!isBedbank(offer)) {
      return offer.vendorName
    }

    return ''
  }, [offer])

  const isCruise = ((offer as App.Offer)?.holidayTypes ?? []).map(i => i.toLowerCase()).includes('cruises')
  const { commissionAmount } = useAgentHubCommission({
    productType: isCruise ? 'cruise-flash' : offer.productType,
    offerId: offer.id,
    offerLocations,
    vendorName,
  })
  const customerView = useAppSelector(state => state.agentHub.customerView)
  const showAgentView = config.agentHub.isEnabled && !customerView && !!commissionAmount

  const total = priceAfterTax + propertyFees
  const memberTotal = memberPriceAfterTax + propertyFees
  const commissionTotal = total * (commissionAmount / 100)

  const onFinePrint = useCallback(() => resolve('fine-print'), [resolve])

  return <ModalBase size="L">
    <ModalHeader title="Price details" />
    <ModalBody>
      <ModalContent>
        <Group direction="vertical" gap={12}>
          <Group direction="horizontal" horizontalAlign="space-between">
            <BodyText variant="medium" weight="bold">
              {pluralizeToString('night', duration)}
              {' '}
              {isBedbank(offer) && offer.displayPricingAsPerPerson ? <>({pluralizeToString('person', adultsNumber)})</> : <>({pluralizeToString(offer.saleUnit, rooms?.length || 1)})</>}
            </BodyText>
            <Typography align="end">
              <BodyText variant="large">
                <FormatCurrency value={priceBeforeTax} format="roundedDollar" />
              </BodyText>
              {duration > 1 && <BodyText as="span" variant="small">
                <FormatCurrency value={(priceBeforeTax / duration)} format="roundedDollar" />{' '}
                average per night
              </BodyText>}
            </Typography>
          </Group>

          {taxesAndFees > 0 && <>
            <Divider direction="horizontal" kind="secondary" />
            <VerticalSpacer gap={4}>
              <Group direction="horizontal" horizontalAlign="space-between">
                <BodyText variant="large">Taxes and fees</BodyText>
                <BodyText variant="large">
                  <FormatCurrency value={taxesAndFees} format="roundedDollar" />
                </BodyText>
              </Group>
              <SlideDown show={expanded}>
                <BodyText variant="medium">
                  This charge includes estimated amounts the travel service provider pays for their taxes, and/or taxes that we pay, to taxing
                  authorities on your booking (including but not limited to sales, occupancy, and value-added tax). This amount may also include
                  any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the website you booked on, retain as
                  part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how
                  you booked.
                  {isBedbank(offer) && <>
                    For more details, please see the <TextLink weight="regular" size="large" href="https://developer.expediapartnersolutions.com/terms/en" target="_blank">Terms and Conditions</TextLink>.
                  </>}
                  {!isBedbank(offer) &&
                    <>
                      Please refer to the {offerPage ? <TextLink weight="regular" onClick={onFinePrint}>‘Fine Print’</TextLink> : <TextLink weight="regular" to={`${offerPageURL(offer)}#fine-print`}>‘Fine Print’ </TextLink>} for any additional costs payable directly to the property.
                    </> }
                </BodyText>
              </SlideDown>
              <SmallToggleButton onClick={expand} on={expanded} size="small">
                {expanded ? 'Read Less' : 'Learn More'}
              </SmallToggleButton>
            </VerticalSpacer>
          </>}
          {!otherFees && propertyFees > 0 && propertiesFees.length === 0 && <>
            <Divider direction="horizontal" kind="secondary" />
            <Group direction="horizontal" horizontalAlign="space-between">
              <BodyText variant="medium" weight="bold">Due at property</BodyText>
              {!!propertyFees && <BodyText variant="large">
                <FormatCurrency value={propertyFees} format="roundedDollar" />
              </BodyText>}
            </Group>
          </>}
          {!otherFees && propertiesFees.length > 0 && <>
            <Divider direction="horizontal" kind="secondary" />
            {propertiesFees.map(p => <Group
                key={p.propertyName}
                direction="horizontal"
                horizontalAlign="space-between"
              >
              <div>
                <BodyText variant="medium" weight="bold">Local Tax</BodyText>
                <Caption colour="neutral-three" variant="medium">
                  (Payable at {p.propertyName})
                </Caption>
              </div>
              <BodyText variant="large">
                <FormatCurrency value={p.propertyFees} format="roundedDollar" />
              </BodyText>
            </Group>)}
          </>}
          <Divider direction="horizontal" kind="secondary" />
          {showAgentView && <>
            <Group direction="horizontal" horizontalAlign="space-between">
              <BodyText variant="large" weight="bold">Total commission ({commissionAmount}%)</BodyText>
              <BodyText variant="large" colour="highlight-secondary">
                -<FormatCurrency value={commissionTotal} format="roundedDollar" />
              </BodyText>
            </Group>
            <Group direction="horizontal" horizontalAlign="space-between">
              <BodyText variant="large" weight="bold">Gross total</BodyText>
              <BodyText variant="large" weight="bold">
                <FormatCurrency value={total} format="roundedDollar" />
              </BodyText>
            </Group>
            <Group direction="horizontal" horizontalAlign="space-between">
              <BodyText variant="large" weight="bold">Net total</BodyText>
              <BodyText variant="large" weight="bold">
                <FormatCurrency value={total - commissionTotal} format="roundedDollar" />
              </BodyText>
            </Group>
          </>}
          {!showAgentView && <>
            <Group direction="horizontal" horizontalAlign="space-between">
              <BodyText variant="large" weight="bold">{enabledShowMemberPrice ? 'Total for non-members' : 'Total'}</BodyText>
              <BodyText variant="large" weight="bold">
                <FormatCurrency value={total} format="roundedDollar" />
              </BodyText>
            </Group>
            {enabledShowMemberPrice && <>
              <Divider direction="horizontal" kind="secondary" />
              <Group direction="horizontal" horizontalAlign="space-between">
                <BodyText variant="large" weight="bold" colour="success">{LUXURY_PLUS.PROGRAM_NAME} member discount</BodyText>
                <BodyText variant="large" weight="bold" colour="success">
                  <FormatCurrency value={memberPriceAfterTax - priceAfterTax} format="roundedDollar" />
                </BodyText>
              </Group>
              <Divider direction="horizontal" kind="secondary" />
              <Group direction="horizontal" horizontalAlign="space-between">
                <BodyText variant="large" weight="bold">Total for {LUXURY_PLUS.PROGRAM_NAME} members</BodyText>
                <BodyText variant="large" weight="bold">
                  <FormatCurrency value={memberTotal} format="roundedDollar" />
                </BodyText>
              </Group>
            </>}
          </>}
          <Divider direction="horizontal" kind="secondary" />
          {dueAtProperty > 0 &&
            <PaymentSchedule direction="vertical" gap={8}>
              <Group direction="horizontal" horizontalAlign="space-between">
                <Heading variant="heading6" colour="highlight-secondary" >Due on checkout</Heading>
                <Heading variant="heading6" colour="highlight-secondary" align="end">
                  <FormatCurrency value={total - dueAtProperty} format="preciseDollar" />
                </Heading>
              </Group>
              <Group direction="horizontal" horizontalAlign="space-between" className="dense">
                <BodyText variant="medium" weight="normal">Due at property on arrival</BodyText>
                <BodyText variant="medium" weight="normal" align="end">
                  <FormatCurrency value={dueAtProperty} format="preciseDollar" />
                </BodyText>
              </Group>
            </PaymentSchedule>
            }
          {extraGuestMessage && <PriceDetailsModalExtraDesc>
            <BodyText variant="large" weight="bold">Extra guest surcharges</BodyText>
            <MarkdownRender content={extraGuestMessage}/>
          </PriceDetailsModalExtraDesc>}
          {dueAtPropertyMessage && <PriceDetailsModalExtraDesc>
            <BodyText variant="large" weight="bold">Due at property</BodyText>
            <MarkdownRender content={dueAtPropertyMessage}/>
          </PriceDetailsModalExtraDesc>}
        </Group>
      </ModalContent>
    </ModalBody>
    {!!cta && <ModalFooter>
      <div onClick={() => resolve(undefined)}>
        {cta}
      </div>
    </ModalFooter>}
  </ModalBase>
}

export default PriceDetailsModal
