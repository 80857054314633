import React, { useMemo } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { connect } from 'react-redux'

import loadable from 'components/utils/lazyImport'
import {
  STEP_BALANCE_PAYMENT,
  STEP_CAPACITY,
  STEP_CHANGE_DATES,
  STEP_CHANGE_PACKAGE,
  STEP_DAY_CALENDAR,
  STEP_DEPARTING_FLIGHT,
  STEP_EXPERIENCES,
  STEP_INSTALMENT_BALANCE_PAYMENT,
  STEP_INSTALMENT_PAYMENT,
  STEP_MONTH_CALENDAR,
  STEP_PAYMENT,
  STEP_RESERVE_FOR_ZERO_PAYMENT,
  STEP_RETURNING_FLIGHT,
  STEP_TOUR_DATES,
} from 'constants/booking'
import { isLoggedIn } from 'selectors/accountSelectors'
import {
  BUSINESS_TRAVELLER_NO_HEADER_ROUTES,
  BUSINESS_TRAVELLER_SUPPORT_HEADER_ROUTES,
} from 'businessTraveller/routes/businessTravellerRoutes'
import config from 'constants/config'
import { useTripPermissions } from 'tripPlanner/hooks/useTripPermissions'
import { LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP } from 'agentHub/routes/AgentHubRoutes'
import { useAppSelector } from 'hooks/reduxHooks'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const HeaderMain = loadable(() => import(/* webpackChunkName: 'HeaderMain' */ 'components/App/Header/HeaderMain/HeaderMain'), <div />)
const HeaderCheckout = loadable(() => import(/* webpackChunkName: 'HeaderCheckout' */ 'components/App/Header/HeaderCheckout'), <div />)
const HeaderTripPlannerImmersive = loadable(() => import(/* webpackChunkName: 'TripPlannerImmersiveHeader' */ 'tripPlanner/components/ImmersiveHeader'), <div />)
const BusinessTravellerSupportHeader = loadable(() => import(/* webpackChunkName: 'BusinessTravellerSupportHeader' */ 'businessTraveller/components/BusinessTravellerSupportHeader'), <div />)
const BusinessTravellerGuestHeader = loadable(() => import(/* webpackChunkName: 'BusinessTravellerGuestHeader' */ 'businessTraveller/components/BusinessTravellerGuestHeader'), <div />)
const AgentHubGuestHeader = loadable(() => import(/* webpackChunkName: 'AgentHubGuestHeader' */ 'agentHub/components/AgentHubGuestHeader'), <div />)

const pageRoute = (basePath: string, path: string) => `${basePath}${path}`

const routesWithNoHeader = [
  '/gift-card/:code',
  '/trip-planner/trip',
  '/trip-planner/curated/:id',
  '/trip-planner/public-trip/:id',
  '/search/map',
  '/search/homes-and-villas/map',
  '/account/my-escapes/experiences/:experienceItemId/voucher',
  LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP,
  ...BUSINESS_TRAVELLER_NO_HEADER_ROUTES,
]

const helpWithNotificationRoute = [
  '/terms-and-conditions',
  '/account/my-escapes',
  '/account/my-escapes/order/:orderId',
]

const noNoticationBannerRoutes = []

const checkoutHeaderPaths = [
  `/booking/${STEP_DEPARTING_FLIGHT}`,
  `/booking/${STEP_RETURNING_FLIGHT}`,
  `/booking/${STEP_MONTH_CALENDAR}`,
  `/booking/${STEP_DAY_CALENDAR}`,
  `/booking/${STEP_CAPACITY}`,
  `/booking/${STEP_CHANGE_DATES}`,
  `/booking/${STEP_CHANGE_PACKAGE}`,
  `/booking/${STEP_TOUR_DATES}`,
  `/booking/${STEP_EXPERIENCES}`,
  `/booking/${STEP_PAYMENT}`,
  `/booking/${STEP_BALANCE_PAYMENT}`,
  `/booking/${STEP_INSTALMENT_PAYMENT}`,
  `/booking/${STEP_INSTALMENT_BALANCE_PAYMENT}`,
  `/booking/${STEP_RESERVE_FOR_ZERO_PAYMENT}`,
  `/bb/booking/${STEP_PAYMENT}`,
  '/checkout',
  '/checkout-restore-cart',
  '/checkout-gateway',
  '/travel-protection/quote',
  '/travel-protection/checkout',
  '/gift-cards/checkout',
  '/travel-protection/details',
  '/travel-protection/payment',
]

const stickySearchHeaderPaths = [
  '/hotels',
  '/tours',
  '/cruises',
  '/car-hire',
  '/experiences',
  '/flights',
  '/homes-and-villas',
  '/trip-planner',
  '/trip-planner/about',
  '/trip-planner/curated',
  '/',
]

const noMobileTabletHeaderPaths = [
  '/support',
]

interface Props extends RouteComponentProps<any> {
  isLoggedIn: boolean;
  tripPlannerTripId?: string;
}

function HeaderRoutes(props: Props) {
  const { match, isLoggedIn, tripPlannerTripId } = props
  const { canEdit } = useTripPermissions(tripPlannerTripId)

  const agentHubAccountSetupComplete = useAppSelector(state => state.agentHub.agentHubAccount.account?.status === 'onboarded')
  const [
    noHeaderRoutes,
    checkoutRoutes,
    businessTravellerSupportRoutes,
    helpNotificationRoutes,
    stickySearchRoutes,
    noNotificationRoutes,
    noMobileTabletRoutes,
  ] = useMemo(() => [
    routesWithNoHeader.map(path => pageRoute(match.path, path)),
    checkoutHeaderPaths.map(path => pageRoute(match.path, path)),
    BUSINESS_TRAVELLER_SUPPORT_HEADER_ROUTES.map(path => pageRoute(match.path, path)),
    helpWithNotificationRoute.map(path => pageRoute(match.path, path)),
    stickySearchHeaderPaths.map(path => pageRoute(match.path, path)),
    noNoticationBannerRoutes.map(path => pageRoute(match.path, path)),
    noMobileTabletHeaderPaths.map(path => pageRoute(match.path, path)),
  ], [match.path])

  return (
    <Switch>
      <Route path={noHeaderRoutes} />
      <Route path={checkoutRoutes} component={HeaderCheckout} />
      {config.agentHub.isEnabled && !agentHubAccountSetupComplete && <Route component={AgentHubGuestHeader} />}
      {config.businessTraveller.currentAccountMode === 'business' && <Route exact path={businessTravellerSupportRoutes} component={BusinessTravellerSupportHeader} />}
      {config.businessTraveller.currentAccountMode === 'business' && !isLoggedIn && <Route component={BusinessTravellerGuestHeader} />}
      <Route path={helpNotificationRoutes}>
        <HeaderMain notificationSlug="help-with-banner" />
      </Route>
      {tripPlannerTripId && canEdit && <Route>
        <HeaderTripPlannerImmersive tripId={tripPlannerTripId} />
      </Route>}
      <Route exact path={stickySearchRoutes}>
        <HeaderMain stickySearch />
      </Route>
      <Route exact path={noNotificationRoutes}>
        <HeaderMain notificationSlug="none" />
      </Route>
      <Route exact path={noMobileTabletRoutes}>
        <CSSBreakpoint min="desktop">
          <HeaderMain notificationSlug="none" />
        </CSSBreakpoint>
      </Route>
      <Route component={HeaderMain} />
    </Switch>
  )
}

function mapStateToProps(state: App.State) {
  return {
    isLoggedIn: isLoggedIn(state),
    tripPlannerTripId: state.tripPlanner.currentTripId,
  }
}

export default connect(mapStateToProps)(HeaderRoutes)
