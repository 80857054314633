import React from 'react'
import Caption from '../Typography/Caption'
import FormatCurrency from 'components/Common/FormatCurrency'

interface Props {
  value: number
}

function PriceRowSale(props: Props) {
  const { value } = props

  return <Caption
    as="s"
    className={PriceRowSale.name}
    variant="large"
    colour="neutral-two"
  >
    Was <FormatCurrency value={value} format="roundedDollar" />
  </Caption>
}

export default PriceRowSale
