import React, { PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

import LoadingAnimation from './LoadingAnimation'

interface Props {
  inline?: boolean;
  className?: string;
  childrenBelow?: boolean;
  floating?: boolean;
  visible?: boolean;
  opaque?: boolean;
  backdrop?: 'white' | 'black' | 'grey-light' | 'none';
  height?: number;
  /**
   * @default center
   */
  position?: 'center' | 'top';
  /**
   * Size of each loading animation ball
   *
   * @default 16
   */
  ballSize?: number;
}

const ColouredAnimation = styled(LoadingAnimation)`
  color: ${props => props.theme.palette.brand.primary.normal};
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.position-center {
    justify-content: center;
  }

  &.position-top {
    justify-content: start;
  }

  padding: ${rem(32)};
  opacity: 0;
  transition: opacity 0.2s;

  &.fullscreen {
    height: 100vh;
    padding-bottom: ${rem(130)};
  }

  &.floating {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.palette.neutral.default.one};
    pointer-events: none;
    z-index: 1;
    padding: 0;
    border: none;
    margin: 0;

    &.white-backdrop {
      background-color: ${props => props.theme.palette.neutral.control.white24};

      &.opaque {
        background-color: ${props => props.theme.palette.neutral.default.eight};
      }
    }

    &.black-backdrop {
      color: ${props => props.theme.palette.neutral.default.eight};
      background-color: ${props => props.theme.palette.neutral.control.black25};

      &.opaque {
        background-color: ${props => props.theme.palette.neutral.default.one};
      }
    }

    &.grey-light-backdrop {
      background-color: ${props => props.theme.palette.neutral.default.six25};

      &.opaque {
        background-color: ${props => props.theme.palette.neutral.default.six};
      }
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
`

function LoadingIndicator(props: PropsWithChildren<Props>) {
  const {
    inline,
    className,
    children,
    childrenBelow,
    floating,
    visible,
    backdrop,
    opaque,
    height,
    position = 'center',
    ballSize = 16,
    ...rest
  } = props

  const style = useMemo(() => (height ? { height: rem(height) } : undefined), [height])

  return (
    <LoadingContainer
      {...rest}
      className={cn(
        className,
        `${backdrop}-backdrop`,
        `position-${position}`,
        {
          fullscreen: !inline && typeof height === 'undefined',
          floating,
          visible,
          opaque,
        })}
      style={style}
    >
      {!childrenBelow && children}
      <ColouredAnimation size={ballSize} paused={!visible} />
      {childrenBelow && children}
    </LoadingContainer>
  )
}

LoadingIndicator.defaultProps = {
  visible: true,
  backdrop: 'white',
} as Partial<Props>

export default React.memo(LoadingIndicator)
