import React from 'react'
import styled from 'styled-components'
import Pane from 'components/Common/Pane'
import config from 'constants/config'
import BedbankOfferTileExpanded from './BedbankOfferTileExpanded'
import BedbankOfferTileCondensed from './BedbankOfferTileCondensed'
import { useBedbankRoomRatePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import moment from 'moment'
import useBedbankFlightPrice from 'hooks/OfferPage/useBedbankFlightPrice'
import { isBedbankFlightBookingEnabled } from 'selectors/flightsSelectors'
import { connect } from 'react-redux'
import OfferListLoadingOfferTile from 'components/OfferList/OfferListTiles/OfferListLoadingOfferTile'
import useBedbankRates from 'hooks/useBedbankRates'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { buildSuggestedDatesParamsKey } from 'lib/search/searchUtils'

const Root = styled(Pane)`
  position: relative;
`

interface MappedStateProps {
  suggestedDates: App.OfferSuggestedDates;
  isBedbankFlightBookingEnabled: boolean;
}

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerMetaData: App.OfferListMetaData;
  onImageChange?: (idx: number, image?: App.Image) => void;
}

function SearchBedbankOffer(props: Props & MappedStateProps) {
  const {
    offer,
    filters,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    onImageChange,
    isBedbankFlightBookingEnabled,
    suggestedDates,
  } = props

  const promotions = useBedbankRoomRatePromotions(offer.promotions, moment(filters?.checkIn), moment(filters?.checkOut))
  const isLTSOffer = offerMetaData?.hasPromotions || !!Object.values(promotions).length

  const flightsEnabled = isBedbankFlightBookingEnabled && !!offer.flights?.airportCode && !!offer.flights?.flightsEnabled
  const flightPrice = useBedbankFlightPrice(offer, filters?.rooms || [], flightsEnabled, filters?.checkIn, filters?.checkOut)

  const showExpandedTile = config.BEDBANK_EXPANDED_SEARCH_OFFER_TILE_ENABLED && isLTSOffer

  const checkIn = filters?.checkIn ?? suggestedDates?.checkIn
  const checkOut = filters?.checkOut ?? suggestedDates?.checkOut

  const [{
    hotelOnlyRates,
    hotelBundleRates,
  }, fetchingRates] = useBedbankRates(offer.id, filters?.rooms, checkIn, checkOut)

  return <Root type="clean">
    {fetchingRates && <OfferListLoadingOfferTile
      offerType="bedbank_hotel"
      tileStyle="search"
    />}
    {!fetchingRates && <>
      {showExpandedTile && <BedbankOfferTileExpanded
        offer={offer }
        filters={filters}
        eagerLoadFirstImage={eagerLoadFirstImage}
        offerUrl={offerUrl}
        flightPrice={flightPrice}
        flightsEnabled={flightsEnabled}
        onImageChange={onImageChange}
        hotelOnlyRates={hotelOnlyRates}
        hotelBundleRates={hotelBundleRates}
        fetchingRates={fetchingRates}
      />}
      {!showExpandedTile && <BedbankOfferTileCondensed
        offer={offer }
        filters={filters}
        eagerLoadFirstImage={eagerLoadFirstImage}
        offerUrl={offerUrl}
        flightPrice={flightPrice}
        flightsEnabled={flightsEnabled}
        onImageChange={onImageChange}
      />}
    </>
    }
  </Root>
}

const mapStateToProps = (appState: App.State, ownProps: Props) => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)

  return {
    isBedbankFlightBookingEnabled: isBedbankFlightBookingEnabled(appState),
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
  }
}

export default connect(mapStateToProps)(SearchBedbankOffer)
