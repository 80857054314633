import { authLogout } from 'actions/AuthActions'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import ListItem from 'components/Luxkit/List/ListItem'
import Caption from 'components/Luxkit/Typography/Caption'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import React, { useCallback, useContext, useRef } from 'react'
import AgentHubAccountMenuModal from './AgentHubAccountMenuModal'
import { pushWithRegion } from 'actions/NavigationActions'

function AgentHubAccountMenu() {
  const [menuOpen, toggleMenuOpen, _, closeMenu] = useToggle(false)

  const dropdownTrigger = useRef<HTMLDivElement>(null)
  const firstName = useAppSelector(state => state.auth.account.givenName)
  const lastName = useAppSelector(state => state.auth.account.surname)
  const dispatch = useAppDispatch()

  const isMobile = useIsMobileScreen()

  const showModal = useContext(ModalContext)
  const onLogout = useCallback(() => {
    dispatch(authLogout())
    dispatch(pushWithRegion('/'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMenuClick = useCallback(() => {
    if (isMobile) {
      showModal(<AgentHubAccountMenuModal />)
    } else {
      toggleMenuOpen()
    }
  }, [isMobile, showModal, toggleMenuOpen])

  return <>
    <div ref={dropdownTrigger}>
      <CSSBreakpoint min="desktop">
        <TextButton
          kind="tertiary"
          variant="dark"
          aria-label="Account"
          onClick={onMenuClick}
          startIcon={<AvatarCircle
            size="small"
            firstName={firstName}
            lastName={lastName}
            nonInteractable
          />}
        >
          <Caption variant="medium" weight="bold">
            Account
          </Caption>
        </TextButton>
      </CSSBreakpoint>
      <CSSBreakpoint max="tablet">
        <AvatarCircle
          size="small"
          firstName={firstName}
          lastName={lastName}
          onClick={onMenuClick}
        />
      </CSSBreakpoint>
    </div>
    <DropdownList
      size="S"
      open={menuOpen}
      triggerRef={dropdownTrigger}
      anchorRef={dropdownTrigger}
      onClose={closeMenu}
      placement="bottom-end"
    >
      <ListItem title="View dashboard" href={config.AGENT_PORTAL_URL} target="_blank" />
      <ListItem title="Log out" onClick={onLogout} />
    </DropdownList>
  </>
}

export default AgentHubAccountMenu
