import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'

import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'

const Message = styled(BodyText)`
  padding-bottom: ${rem(16)};

  ${mediaQueryUp.tablet} {
    padding-bottom: 0;
  }
`

interface Props {
  isOpen: boolean;
  backBtnTitle?: string;
  onGoBack?: () => void;
  onReload?: () => void;
}

function SessionTimeoutModal(props: Props) {
  const { isOpen, backBtnTitle, onGoBack } = props
  return (
    <Modal
      isOpen={isOpen}
      title="Your session has expired"
      primaryActionText={backBtnTitle}
      onPrimaryActionClick={onGoBack}
    >
      <Message variant="large">
        Cruise prices fluctuate due to demand and availability. To make sure you are getting the most up to date prices, please refresh your search.
      </Message>
    </Modal>
  )
}

export default SessionTimeoutModal
