import { createReducer } from 'lib/redux/reducerUtils'
import { FlightFilterType, FlightSegment, FlightSortOptions, FlightsFareTypes } from 'constants/flight'
import { ValueOf } from 'type-fest'
import { removeUnusedFilters } from 'lib/flights/flightFilterUtils'

export enum FlightPriceFilterType {
  ADULT = 'adult',
  TOTAL = 'total'
}

export enum FlightPriceFilterRecommendedType {
  DIRECT_FLIGHTS = 'directFlights',
  INCLUDES_CHECKED_BAG = 'includesCheckedBag'
}

export enum StandaloneFlightsActions {
  SET_ACTIVE_SEGMENT = 'SET_ACTIVE_SEGMENT',
  SET_DEPARTING_FLIGHT = 'SET_DEPARTING_FLIGHT',
  SET_RETURNING_FLIGHT = 'SET_RETURNING_FLIGHT',
  SET_DEPARTING_FLIGHT_V2 = 'SET_DEPARTING_FLIGHT_V2',
  SET_RETURNING_FLIGHT_V2 = 'SET_RETURNING_FLIGHT_V2',
  SET_DEPARTING_FARE_FAMILY = 'SET_DEPARTING_FARE_FAMILY',
  SET_RETURNING_FARE_FAMILY = 'SET_RETURNING_FARE_FAMILY',
  RESET_FLIGHTS = 'RESET_FLIGHTS',
  SET_FLIGHTS_FILTER = 'SET_FLIGHTS_FILTER',
  SET_FLIGHTS_SORT = 'SET_FLIGHTS_SORT',
  RE_INITIALISE = 'RE_INITIALISE',
  RESET_FILTERS = 'RESET_FILTERS',
  UPDATE_PASSENGER_BAGGAGE= 'UPDATE_PASSENGER_BAGGAGE',
  TOGGLE_NON_EXISTENT_FILTERS = 'TOGGLE_NON_EXISTENT_FILTERS',
  RESET_FILTER = 'RESET_FILTER',
  SET_VIEW_TYPE = 'SET_VIEW_TYPE'
}

export interface StandaloneFlightsFilters {
  stops: Array<string>;
  airlines: Array<string>;
  cabinTypes: Array<string>;
  carriers: Array<string>;
  stopDurations: Array<string>;
  durations: Array<string>;
  minPrices: Array<string>;
  maxPrices: Array<string>;
  minDepartureTime: Array<string>;
  maxDepartureTime: Array<string>;
  minArrivalTime: Array<string>;
  maxArrivalTime: Array<string>;
  priceFilterType: FlightPriceFilterType;
  recommended: Array<string>;
  bags: Array<string>;
  stopOverCities: Array<string>;
  alliances: Array<string>;
}

export interface StandaloneFlightsState extends StandaloneFlightsFilters {
  isFlightSelectError: boolean;
  activeSegment?: FlightSegment;
  departingFlight?: App.Journey;
  returningFlight?: App.Journey;
  passengers: Array<App.Passenger>;
  maxPrice?: number;
  duration?: { min: number, max: number };
  departure?: { min: number, max: number };
  arrival?: { min: number, max: number };
  sort?: FlightSortOptions;
  destinationAirport?: App.Airport;
  originAirport?: App.Airport;
  departDate?: string;
  returnDate?: string;
  departingFlightV2?: App.JourneyV2;
  returningFlightV2?: App.JourneyV2;
  departingFareFamily?: App.FlightFareFamily;
  returningFareFamily?: App.FlightFareFamily;
  viewType?: string;
  fareType: FlightsFareTypes

  // MULTI CITY, TODO: Use flightApirports in return and one-way too
  flightAirports?: Array<{ origin: App.Airport, destination: App.Airport }>;
}

export type StandaloneFlightsAction = ValueOf<Utils.FullActionMap<{
  [StandaloneFlightsActions.SET_ACTIVE_SEGMENT]: { segment: FlightSegment };
  [StandaloneFlightsActions.SET_DEPARTING_FLIGHT]: { flight: App.Journey };
  [StandaloneFlightsActions.SET_RETURNING_FLIGHT]: { flight: App.Journey };
  [StandaloneFlightsActions.SET_DEPARTING_FLIGHT_V2]: { flight: App.JourneyV2 };
  [StandaloneFlightsActions.SET_RETURNING_FLIGHT_V2]: { flight: App.JourneyV2 };
  [StandaloneFlightsActions.SET_DEPARTING_FARE_FAMILY]: { fareFamily: App.FlightFareFamily };
  [StandaloneFlightsActions.SET_RETURNING_FARE_FAMILY]: { fareFamily: App.FlightFareFamily };
  [StandaloneFlightsActions.RESET_FLIGHTS]: { };
  [StandaloneFlightsActions.SET_FLIGHTS_FILTER]: { filters: Partial<StandaloneFlightsState> };
  [StandaloneFlightsActions.SET_FLIGHTS_SORT]: { sort: FlightSortOptions };
  [StandaloneFlightsActions.RE_INITIALISE]: { initalState: Partial<StandaloneFlightsState> };
  [StandaloneFlightsActions.RESET_FILTERS]: {};
  [StandaloneFlightsActions.RESET_FILTER]: { filterType: FlightFilterType };
  [StandaloneFlightsActions.UPDATE_PASSENGER_BAGGAGE]: {
    journeyId: string,
    baggage: Array<Array<App.AddedBaggage>>,
  };
  [StandaloneFlightsActions.TOGGLE_NON_EXISTENT_FILTERS]: { filters: Partial<StandaloneFlightsFilters> };
  [StandaloneFlightsActions.SET_VIEW_TYPE]: { viewType: string }
}>>

export const initialState: StandaloneFlightsState = {
  activeSegment: FlightSegment.DEPARTING,
  passengers: [],
  isFlightSelectError: false,
  departingFlight: undefined,
  returningFlight: undefined,
  stops: [],
  airlines: [],
  cabinTypes: [],
  maxPrice: undefined,
  duration: undefined,
  departure: undefined,
  arrival: undefined,
  carriers: [],
  stopDurations: [],
  durations: [],
  minPrices: [],
  maxPrices: [],
  priceFilterType: FlightPriceFilterType.ADULT,
  sort: undefined,
  destinationAirport: undefined,
  originAirport: undefined,
  departDate: undefined,
  returnDate: undefined,
  departingFlightV2: undefined,
  returningFlightV2: undefined,
  departingFareFamily: undefined,
  returningFareFamily: undefined,
  minDepartureTime: [],
  maxDepartureTime: [],
  minArrivalTime: [],
  maxArrivalTime: [],
  recommended: [],
  viewType: undefined,
  bags: [],
  stopOverCities: [],
  alliances: [],
  fareType: FlightsFareTypes.RETURN,
}

const standaloneStateReducer = createReducer<StandaloneFlightsState, StandaloneFlightsAction>(initialState, {
  [StandaloneFlightsActions.SET_ACTIVE_SEGMENT]: (state, action) => ({
    activeSegment: action.segment,
  }),
  [StandaloneFlightsActions.SET_DEPARTING_FLIGHT]: (state, action) => ({
    departingFlight: action.flight,
  }),
  [StandaloneFlightsActions.SET_RETURNING_FLIGHT]: (state, action) => ({
    returningFlight: action.flight,
  }),
  [StandaloneFlightsActions.RESET_FLIGHTS]: () => ({
    departingFlight: undefined,
    returningFlight: undefined,
    departingFlightV2: undefined,
    returningFlightV2: undefined,
    departingFareFamily: undefined,
    returningFareFamily: undefined,
    viewType: undefined,
  }),
  [StandaloneFlightsActions.SET_FLIGHTS_FILTER]: (state, action) => ({
    ...action.filters,
  }),
  [StandaloneFlightsActions.RESET_FILTERS]: () => ({
    stops: [],
    airlines: [],
    cabinTypes: [],
    maxPrice: undefined,
    duration: undefined,
    departure: undefined,
    arrival: undefined,
    carriers: [],
    stopDurations: [],
    durations: [],
    minPrices: [],
    maxPrices: [],
    minDepartureTime: [],
    maxDepartureTime: [],
    minArrivalTime: [],
    maxArrivalTime: [],
    priceFilterType: FlightPriceFilterType.ADULT,
    bags: [],
    stopOverCities: [],
    alliances: [],
  }),
  [StandaloneFlightsActions.SET_FLIGHTS_SORT]: (state, action) => ({
    sort: action.sort,
  }),
  [StandaloneFlightsActions.RE_INITIALISE]: (state, action) => ({
    ...initialState,
    ...action.initalState,
  }),
  [StandaloneFlightsActions.UPDATE_PASSENGER_BAGGAGE]: (state, action) => ({
    passengers: state.passengers.map((passenger, index) => ({
      ...passenger,
      addedExtras: {
        ...passenger.addedExtras,
        baggage: {
          ...passenger.addedExtras.baggage,
          [action.journeyId]: action.baggage[index],
        },
      },
    })),
  }),
  [StandaloneFlightsActions.SET_DEPARTING_FLIGHT_V2]: (state, action) => ({
    departingFlightV2: action.flight,
  }),
  [StandaloneFlightsActions.SET_RETURNING_FLIGHT_V2]: (state, action) => ({
    returningFlightV2: action.flight,
  }),
  [StandaloneFlightsActions.SET_DEPARTING_FARE_FAMILY]: (state, action) => ({
    departingFareFamily: action.fareFamily,
  }),
  [StandaloneFlightsActions.SET_RETURNING_FARE_FAMILY]: (state, action) => ({
    returningFareFamily: action.fareFamily,
  }),
  [StandaloneFlightsActions.RESET_FILTER]: (_state, action) => ({
    [action.filterType]: initialState[action.filterType],
  }),
  [StandaloneFlightsActions.TOGGLE_NON_EXISTENT_FILTERS]: (state, action) => {
    const newFilters = Object.keys(action.filters).reduce((filters, filterKey) => ({
      ...filters,
      [filterKey]: removeUnusedFilters(state[filterKey], action.filters[filterKey]),
    }), {})

    return newFilters
  },
  [StandaloneFlightsActions.SET_VIEW_TYPE]: (state, action) => ({
    viewType: action.viewType,
  }),
})

export default standaloneStateReducer
