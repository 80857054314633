import { AlternativeOfferEnquiry } from 'api/recommendations'
import { sortBy } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'
import { getHighIntentOffers } from './highIntentSelectors'

export const personalisedAlternativesOfferType = ['hotel', 'last_minute_hotel', 'tactical_ao_hotel', 'bedbank_hotel']

export const getPersonalisedAlternativeOfferIds = createSelector(
  (state: App.State) => state.recommendations.personalisedAlternatives.offers,
  (offers:Array<App.RecommendationOffer>) => offers.map(offer => offer.offerId),
)

// Assuming dateString is a string in the format 'YYYY-MM-DD'
const isDateAfterToday = (dateString) => {
  try {
    const date = new Date(dateString)
    const now = new Date()

    return date > now
  } catch (error) {
    return false
  }
}

export const constructAlternativeOffersKey = (offerEnquiries: Array<AlternativeOfferEnquiry>) => {
  // maintain the same order as it is using for the key
  const sortedEnquiries = sortBy(offerEnquiries, (val) => { val.offer_id })
  // stringify the array and concat to be the key
  let key = ''
  sortedEnquiries.forEach((enquiry) => { key = `${key}-${enquiry.offer_id}-${enquiry.check_in}-${enquiry.check_out}` })
  return key
}

export const constructOfferEnquiry = (offers:Array<App.RecommendationOffer>) => offers
  .filter(offer => personalisedAlternativesOfferType.includes(offer.offerType)) // Filter out offers with categories which are not support for LERE
  .map((offer) => ({
    offer_id: offer.offerId,
    ...('checkIn' in offer && !!offer.checkIn && isDateAfterToday(offer.checkIn) && { check_in: offer.checkIn as string }), // Conditionally include check_in
    ...('checkOut' in offer && !!offer.checkOut && isDateAfterToday(offer.checkOut) && { check_out: offer.checkOut as string }), // Conditionally include check_out
  }))

export const getAlternativeOfferEnquiry = createSelector(
  getHighIntentOffers,
  (offers:Array<App.RecommendationOffer>) => constructOfferEnquiry(offers),
)

export const getAlternativeOfferKey = createSelector(
  getAlternativeOfferEnquiry,
  (offerEnquiries) => constructAlternativeOffersKey(offerEnquiries),
)
