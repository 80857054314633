import { useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchOfferList } from 'actions/OfferActions'
import arrayShuffle from 'lib/array/arrayShuffle'
import memoize from 'lib/memoize/memoize'
import getOfferListKey from 'lib/offer/offerListKey'
import { without } from 'lib/array/arrayUtils'
import { SetOptional } from 'type-fest'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { isHotelSpecificFilters } from 'api/offer'
export interface Options {
  /**
   * For when the offer list is no longer needed, but still needs to be called to avoid violating the Rules of Hooks
   * */
  disabled?: boolean;
  /**
   * Randomize the offer ids in the list
   * Will be stable for that set of offer ids
   */
  randomize?: boolean;
  /**
   * An array of offer ids to explicitly exclude from the results
   */
  excludedIds?: Array<string>;
  /**
   * Whether explicitly filter out sold-out offer ids from the list
   * This will use the meta-data to determine if they are sold out
   */
  filterSoldOut?: boolean;
  /**
   * Whether check the availability of the offer on the client side
   */
  clientCheckAvailability?: boolean;

  /**
   * Whether to fetch the offer list or just return existing offer list
   * It stops the fetches when the filters are changed unexpectedly
   * so that the issue could be exposed and fixed
   */
  noFetch?: boolean;
}

/**
 * Memoize is used here as it provides a stable set of offer ids for that exact version of the array
 * This ensures that we always return the same randomised set throughout the life of the page
 */
const getRandomizedOfferIds = memoize((offerIds: Array<string>) => arrayShuffle(offerIds))

const emptyOfferList: SetOptional<App.OfferList, 'key'> = {
  offerIds: [],
  fetching: true,
  error: null,
}

const emptyDisabledOfferList: SetOptional<App.OfferList, 'key'> = {
  offerIds: [],
  fetching: false,
  error: null,
}

function useOfferList(
  filters: App.OfferListFilters | undefined,
  options: Options = {},
): App.OfferList {
  const {
    randomize,
    excludedIds,
    filterSoldOut,
    clientCheckAvailability,
    noFetch,
  } = options

  const disabled = options.disabled || !filters
  const dispatch = useAppDispatch()

  const includesChildren = useMemo(() => !!filters?.rooms?.some(room => room.children), [filters])
  const isOnlyHotels = useMemo(() => isHotelSpecificFilters(filters), [filters])

  const familySegmentation: '0' | '33' | '66' | undefined = useOptimizelyExperiment(OptimizelyExperiments.searchFamilySegmentation, includesChildren && isOnlyHotels)

  const listKey = useMemo(() => {
    if (filters) {
      return getOfferListKey(filters)
    }
    return ''
  }, [filters])

  useEffect(() => {
    if (disabled || noFetch) {
      // Do nothing
    } else if (familySegmentation !== undefined && familySegmentation !== '0') {
      dispatch(fetchOfferList({
        ...filters,
        segments: [...(filters.segments ?? []), `occupancy:CHILDREN:0.${familySegmentation}`],
      }))
    } else {
      dispatch(fetchOfferList(filters, {
        clientCheckAvailability,
      }))
    }
    // eslint-disable-next-line
  }, [listKey, disabled])

  const offerList = useAppSelector((state) => {
    if (disabled) {
      return emptyDisabledOfferList
    }
    return state.offer.offerLists[listKey] ?? emptyOfferList
  })

  const metaData = useAppSelector(state => state.offer.searchResultMetadata.offerMetaData[listKey])

  const finalOfferList = useMemo(() => {
    let postExclusionIds = excludedIds ? without(offerList.offerIds, ...excludedIds) : offerList.offerIds

    if (filterSoldOut && metaData) {
      postExclusionIds = postExclusionIds.filter(id => {
        const offerData = metaData[id]
        // if we don't have any meta data on it, assume it's available
        return !offerData || offerData.available
      })
    }

    if (randomize) {
      return {
        ...offerList,
        key: offerList.key ?? listKey,
        offerIds: getRandomizedOfferIds(postExclusionIds),
      }
    }
    return {
      ...offerList,
      key: offerList.key ?? listKey,
      offerIds: postExclusionIds,
    }
  }, [excludedIds, offerList, filterSoldOut, metaData, randomize, listKey])

  return finalOfferList
}

export default useOfferList
