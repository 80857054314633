import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import config from 'constants/config'
import { pluralizeToString } from 'lib/string/pluralize'

const visibilityRegion = (schedules: App.OfferVisibilityScheduleMap, regionCode: string) => schedules[regionCode] || schedules.world

export default function getUrgencyLabels(
  offer: App.Offer | App.OfferSummary,
  regionCode: string,
  showLimitedAvailability?: boolean,
) {
  const visibilitySchedules = 'visibilitySchedules' in offer ?
    visibilityRegion(offer.visibilitySchedules, regionCode) :
    undefined
  const luxPlusSchedules = offer.luxPlusSchedules ?
    visibilityRegion(offer.luxPlusSchedules, regionCode) :
    undefined

  if (!visibilitySchedules && offer.type !== OFFER_TYPE_LAST_MINUTE) {
    return []
  }

  let nextUrgencyTags: Array<App.OfferUrgencyLabel>
  let messageOverride: string | undefined

  if (offer.type === OFFER_TYPE_ALWAYS_ON) {
    nextUrgencyTags = offer.urgencyTags.filter(item => item.type !== 'left')
  } else {
    nextUrgencyTags = offer.urgencyTags
  }

  if (!showLimitedAvailability) {
    nextUrgencyTags = nextUrgencyTags.filter(item => item.type !== 'limited')
  }

  return nextUrgencyTags.map<App.OfferUrgencyLabel>((urgencyTag) => {
    if (urgencyTag.type === 'lux_plus_general_release') {
      return {
        start: luxPlusSchedules?.start,
        end: visibilitySchedules?.start,
        type: urgencyTag.type,
        message: messageOverride ?? urgencyTag.message,
      }
    }

    return {
      start: visibilitySchedules?.start,
      end: visibilitySchedules?.end,
      type: urgencyTag.type,
      message: messageOverride ?? urgencyTag.message,
    }
  })
}

export function getOnlyNRoomLeftLabel(
  availableRooms: number,
  isShortFormat?: boolean,
  thresholdOverride?: number,
): string | undefined {
  const threshold = thresholdOverride ?? config.ONLY_N_ROOMS_LEFT_THRESHOLD ?? 3

  if (availableRooms > 0 && availableRooms <= threshold) {
    return isShortFormat ?
        `${pluralizeToString('room', availableRooms)} left` :
        `Only ${pluralizeToString('room', availableRooms)} left`
  }
}
