export const initialFormState = {
  editIdx: 0,
  activeHeadIdx: 0,
  travellerForms: [],
}

function isFormSatisfiedForSchema(
  form: App.Checkout.TravellerForm,
  schemaForm: App.Checkout.TravellerFormSchema,
): boolean {
  const { id, isAustraliaResident, ...rest } = form

  // Special field
  // The schema field is constructed into 2 separate fields
  if (rest.phone && rest.prefix) {
    rest.phonePrefix = `${rest.phone}${rest.prefix}`
    delete rest.phone
    delete rest.prefix
  }

  const forwardSatisfied = Object.keys(rest).every(key => !!schemaForm?.properties?.[key])
  const backwardSatisfied = schemaForm?.required?.every(key => !!form?.[key])
  return forwardSatisfied && backwardSatisfied
}

export function isValidFormState(
  schema: App.Checkout.FormSchema,
  formState: App.Checkout.FormState,
): boolean {
  if (schema) {
    const forms = formState.travellerForms
    return forms.every(form => isFormSatisfiedForSchema(form, schema.properties[form.id]))
  }
  return false
}
