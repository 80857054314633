import {
  CLEAR_FLIGHT_RESULT,
  UPDATE_FLIGHT_FILTERS,
  UPDATE_FLIGHTS_SORT,
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  FETCH_POPULAR_AIRPORTS,
  SET_FLIGHTS_CREDIT_DATA,
  FETCH_FLIGHT_CREDIT_RESERVATION,
  FETCH_FLIGHT_DEALS,
  FETCH_FLIGHT_DEAL,
} from 'actions/actionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import { arrayToObject, sortBy } from 'lib/array/arrayUtils'
import { FETCH_FLIGHT_JOURNEY, FETCH_FLIGHTS, FETCH_FLIGHT_BAGGAGE, FETCH_FLIGHT_PRICE, FETCH_FLIGHT_CALENDAR, FETCH_SEARCH_FLIGHTS, FETCH_FLIGHT_FARE_FAMILIES } from 'actions/apiActionConstants'
import { ApiAction } from 'middlewares/apiMiddleware'

const initialState: App.FlightsState = {
  // Old booking fields, do not use
  journeys: [],
  filters: [],
  flightSearchV2: {
    onewayFares: [undefined, undefined],
    searchId: undefined,
  },
  sort: 'fastest',
  isFetching: false,
  isFetched: false,
  hasError: false,
  fetchedKey: '',
  errorMessage: '',
  // Actual fields
  journeysById: {},
  isBaggageFetching: false,
  isBaggageFetchingError: false,
  flightPricesLoading: {},
  flightPrices: {},
  fareFamiliesByFlight: {},
  flightCalendarLoading: {},
  flightCalendar: {},
  searchFlights: {},
  searchV2Flights: {},
  journeyFetching: {},
  journeyErrors: {},
  popularAirports: {
    origins: [],
    destinations: [],
    fetching: false,
  },
  creditDetails: null,
  creditRebookingReservation: null,
  isCreditReservationFetching: false,
  isCreditReservationError: false,
  flightDeals: {},
  flightDealLists: {},
  notices: [],
}

const updateJourneys = (journey, action) => {
  if (journey.id === action.data.journeyId) {
    let updatedJourney = {
      ...journey,
      departing: {
        ...journey.departing,
        extras: {
          ...journey.departing.extras,
          ...action.data.newBaggage[0],
        },
      },
    }
    if (journey.returning) {
      updatedJourney = {
        ...updatedJourney,
        returning: {
          ...journey.returning,
          extras: {
            ...journey.returning.extras,
            ...action.data.newBaggage[1],
          },
        },
      }
    }
    return updatedJourney
  }
  return journey
}

const apiRequests = reducerSwitch<App.FlightsState>({
  [FETCH_FLIGHT_JOURNEY]: (state, action) => ({
    journeyFetching: { ...state.journeyFetching, [action.key]: true },
  }),
  [FETCH_FLIGHTS]: (state, action) => ({
    journeys: [],
    fetchedKey: action.key,
    isFetching: true,
    isFetched: false,
  }),
  [FETCH_SEARCH_FLIGHTS]: (state, action) => ({
    searchFlights: {
      ...state.searchFlights,
      [action.key]: {
        journeys: [],
        fetching: true,
      },
    },
    searchV2Flights: {
      ...state.searchV2Flights,
      [action.key]: {
        departingFares: [],
        returningFares: [],
        fetching: true,
      },
    },
  }),
  [FETCH_FLIGHT_CALENDAR]: (state, action) => ({
    flightCalendarLoading: {
      ...state.flightCalendarLoading,
      [action.key]: true,
    },
  }),
  [FETCH_FLIGHT_BAGGAGE]: () => ({
    isBaggageFetching: true,
    isBaggageFetchingError: false,
  }),
  [FETCH_FLIGHT_PRICE]: (state, action) => ({
    flightPricesLoading: {
      ...state.flightPricesLoading,
      [action.key]: true,
    },
  }),
  [FETCH_FLIGHT_FARE_FAMILIES]: (state, action) => ({
    fareFamiliesByFlight: {
      ...state.fareFamiliesByFlight,
      [action.key]: {
        fetching: true,
        families: [],
      },
    },
  }),
  [FETCH_FLIGHT_CREDIT_RESERVATION]: () => ({
    isCreditReservationFetching: true,
    creditRebookingReservation: null,
    isCreditReservationError: false,
  }),
  [FETCH_FLIGHT_DEALS]: (state, action) => ({
    flightDealLists: {
      ...state.flightDealLists,
      [action.key]: {
        dealIds: [],
        error: undefined,
        fetching: true,
      },
    },
  }),
  [FETCH_FLIGHT_DEAL]: (state, action) => ({
    flightDeals: {
      ...state.flightDeals,
      [action.id]: {
        error: undefined,
        fetching: true,
      },
    },
  }),
  [FETCH_POPULAR_AIRPORTS]: (state) => ({
    popularAirports: {
      ...state.popularAirports,
      fetching: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.FlightsState>({
  [FETCH_FLIGHT_JOURNEY]: (state, action) => ({
    journeysById: {
      ...state.journeysById,
      [action.key]: action.data,
    },
    journeyFetching: { ...state.journeyFetching, [action.key]: false },
  }),
  [FETCH_FLIGHTS]: (state, action) => ({
    isFetching: false,
    isFetched: true,
    journeys: 'journeys' in action.data ? action.data.journeys : [],
    ...('onewayFares' in action.data && {
      flightSearchV2: {
        onewayFares: action.data.onewayFares,
        searchId: action.data.searchId,
      },
    }),
    notices: action.data.notices,
    hasError: false,
    errorMessage: '',
  }),
  [FETCH_SEARCH_FLIGHTS]: (state, action) => {
    if (action.data.onewayFares) {
      return {
        searchFlights: {
          ...state.searchFlights,
          [action.key]: {
            ...state.searchFlights[action.key],
            fetching: false,
          },
        },
        searchV2Flights: {
          ...state.searchV2Flights,
          [action.key]: {
            departingFares: action.data.onewayFares[0],
            returningFares: action.data.onewayFares[1],
            fetching: false,
            searchId: action.data.searchId,
            sessionId: action.data.sessionId,
            viewType: action.data.viewType,
          },
        },
      }
    } else {
      return {
        searchFlights: {
          ...state.searchFlights,
          [action.key]: {
            journeys: action.data.journeys,
            viewType: action.data.viewType,
            fetching: false,
          },
        },
        searchV2Flights: {
          ...state.searchV2Flights,
          [action.key]: {
            ...state.searchV2Flights[action.key],
            fetching: false,
          },
        },
        notices: action.data.notices,
        sort: action.data.sort,
      }
    }
  },
  [FETCH_FLIGHT_BAGGAGE]: (state, action) => ({
    isBaggageFetching: false,
    journeys: action.data.flightSearchKey ? state.journeys : state.journeys.map((journey) => updateJourneys(journey, action)),
    searchFlights: action.data.flightSearchKey ? {
      [action.data.flightSearchKey]: {
        ...state.searchFlights[action.data.flightSearchKey],
        journeys: state.searchFlights[action.data.flightSearchKey]?.journeys.map((journey) => updateJourneys(journey, action)),
      },
    } : state.searchFlights,
  }),
  [FETCH_FLIGHT_PRICE]: (state, action) => ({
    flightPrices: {
      ...state.flightPrices,
      [action.key]: action.data,
    },
    flightPricesLoading: {
      ...state.flightPricesLoading,
      [action.key]: false,
    },
  }),
  [FETCH_FLIGHT_FARE_FAMILIES]: (state, action) => ({
    fareFamiliesByFlight: {
      ...state.fareFamiliesByFlight,
      [action.key]: {
        fetching: false,
        families: action.data,
      },
    },
  }),
  [FETCH_FLIGHT_CALENDAR]: (state, action) => ({
    flightCalendar: {
      ...state.flightCalendar,
      [action.key]: action.data,
    },
    flightCalendarLoading: {
      ...state.flightCalendarLoading,
      [action.key]: false,
    },
  }),
  [FETCH_POPULAR_AIRPORTS]: (state, action) => ({
    popularAirports: action.data,
  }),
  [FETCH_FLIGHT_CREDIT_RESERVATION]: (state, action) => ({
    creditRebookingReservation: action.data,
    isCreditReservationFetching: false,
    isCreditReservationError: false,
  }),
  [FETCH_FLIGHT_DEALS]: (state, action: ApiAction<Array<App.FlightDeal>>) => ({
    flightDeals: {
      ...state.flightDeals,
      ...arrayToObject(
        action.data,
        deal => deal.id,
        // if we already have it, don't overwrite - the singular endpoint returns more data at the moment
        deal => ({
          error: undefined,
          fetching: false,
          deal,
        }),
      ),
    },
    flightDealLists: {
      ...state.flightDealLists,
      [action.key]: {
        dealIds: action.data?.map(deal => deal.id) ?? [],
        error: undefined,
        fetching: false,
      },
    },
  }),
  [FETCH_FLIGHT_DEAL]: (state, action) => ({
    flightDeals: {
      ...state.flightDeals,
      [action.id]: {
        error: undefined,
        fetching: false,
        deal: action.data,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.FlightsState>({
  [FETCH_FLIGHT_JOURNEY]: (state, action) => ({
    journeyErrors: { ...state.journeyErrors, [action.key]: action.error.message },
    journeyFetching: { ...state.journeyFetching, [action.key]: false },
  }),
  [FETCH_FLIGHTS]: (state, action) => ({
    hasError: true,
    isFetching: false,
    errorMessage: action.error.message,
  }),
  [FETCH_FLIGHT_BAGGAGE]: () => ({
    isBaggageFetching: false,
    isBaggageFetchingError: true,
  }),
  [FETCH_FLIGHT_PRICE]: (state, action) => ({
    flightPricesLoading: {
      ...state.flightPricesLoading,
      [action.key]: false,
    },
  }),
  [FETCH_FLIGHT_FARE_FAMILIES]: (state, action) => ({
    fareFamiliesByFlight: {
      ...state.fareFamiliesByFlight,
      [action.key]: {
        fetching: false,
        error: action.error,
        families: [],
      },
    },
  }),
  [FETCH_FLIGHT_CALENDAR]: (state, action) => ({
    flightCalendarLoading: {
      ...state.flightCalendarLoading,
      [action.key]: false,
    },
  }),
  [FETCH_SEARCH_FLIGHTS]: (state, action) => ({
    searchFlights: {
      ...state.searchFlights,
      [action.key]: {
        ...state.searchFlights[action.key],
        fetching: false,
        error: action.error,
      },
    },
    searchV2Flights: {
      ...state.searchV2Flights,
      [action.key]: {
        ...state.searchV2Flights[action.key],
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_FLIGHT_CREDIT_RESERVATION]: () => ({
    isCreditReservationFetching: false,
    isBaggageFetchingError: true,
  }),
  [FETCH_FLIGHT_DEALS]: (state, action) => ({
    flightDealLists: {
      ...state.flightDealLists,
      [action.key]: {
        dealIds: [],
        error: action.error,
        fetching: false,
      },
    },
  }),
  [FETCH_FLIGHT_DEAL]: (state, action) => ({
    flightDeals: {
      ...state.flightDeals,
      [action.id]: {
        error: action.error,
        fetching: false,
      },
    },
  }),

})

const flightsReducer = createReducer<App.FlightsState>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_FLIGHT_RESULT]: () => ({
    journeys: [],
    filters: [],
    flightSearchV2: {
      onewayFares: [undefined, undefined],
      searchId: undefined,
    },
    sort: 'fastest',
    isFetching: false,
    isFetched: false,
    hasError: false,
    errorMessage: '',
    isBaggageFetching: false,
    isBaggageFetchingError: false,
  }),
  [UPDATE_FLIGHT_FILTERS]: (state, action) => ({
    filters: sortBy(action.data, f => f.order, 'asc'),
  }),
  [UPDATE_FLIGHTS_SORT]: (state, action) => ({
    sort: action.data,
  }),
  [SET_FLIGHTS_CREDIT_DATA]: (state, action) => ({
    creditDetails: action.data,
  }),
})

export default flightsReducer
