import React, { PropsWithChildren } from 'react'

export type ProductPalette = 'default' | 'ultralux' | 'loyalty'

export const ProductPaletteContext = React.createContext<ProductPalette>('default')

interface Props {
  palette: ProductPalette;
}

export default function ProductPaletteProvider(props: PropsWithChildren<Props>) {
  return <ProductPaletteContext.Provider value={props.palette}>
    {props.children}
  </ProductPaletteContext.Provider>
}
