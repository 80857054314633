import { AppStore } from '../store'
import config from 'constants/config'
import { initSentryClientSide, sendErrorToSentry } from '../lib/utils/sentryUtils'
import { isLoggedIn } from 'selectors/accountSelectors'

let _store: AppStore | undefined

export function registerErrorReportStore(store: AppStore) {
  _store = store
}

let Sentry
export async function reportClientError(error, extras: any = {}) {
  if (!config.SENTRY_ID) return Promise.resolve()

  if (!Sentry) {
    Sentry = await import(/* webpackChunkName: "Sentry" */ '@sentry/browser')
    initSentryClientSide(Sentry)
  }

  let account

  if (_store) {
    const state = _store.getState()
    extras.loggedIn = isLoggedIn(state)
    extras.headlessMode = state.config.headlessMode
    extras.regionCode = state.geo.currentRegionCode
    extras.query = state.router.location.search
    extras.path = state.router.location.pathname
    extras.recentHistory = state.routeHistory.recentHistory
    extras.cartItems = state.checkout.cart.items
    extras.isSpoofed = state.auth.account.isSpoofed

    account = state.auth.account
  }

  if (!config.APP_ENV || config.APP_ENV !== 'production') {
    console.warn('Client error reported:', {
      error,
      extras,
      account,
    })
  }

  return sendErrorToSentry(error, account, extras)
}
