import { rem } from 'polished'
import styled from 'styled-components'

import zIndex from 'styles/tools/z-index'

const FloatingBookmarkContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  top: ${rem(16)};
  left: ${rem(16)};
  right: ${rem(16)};
  z-index: ${zIndex.floatingBookmarkButton};
  width: fit-content;
  margin-left: auto;
`

export default FloatingBookmarkContainer
