import React, { ComponentProps, PropsWithChildren, forwardRef } from 'react'
import BaseFloatingDropdown from '../BaseFloatingDropdown'
import DropdownSheetSheet from './DropdownSheetSheet'

type InheritedSheetProps = Pick<ComponentProps<typeof DropdownSheetSheet>, 'size' | 'height'>
type InheritedFloatingDropdownProps = Pick<ComponentProps<typeof BaseFloatingDropdown>, 'placement' | 'anchorRef' | 'boundaryRef' | 'triggerRef' | 'open' | 'portaled' | 'onClose' | 'id' | 'data-testid' | 'crossAxisShift'>

interface Props extends PropsWithChildren, InheritedSheetProps, InheritedFloatingDropdownProps { }

const DropdownSheetBase = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    size,
    height,
    placement,
    open,
    anchorRef,
    boundaryRef,
    triggerRef,
    'data-testid': dataTestId,
    id,
    onClose,
    crossAxisShift,
    portaled,
  } = props

  return <BaseFloatingDropdown
    ref={ref}
    width={size === 'fill-anchor' ? 'match-anchor' : 'auto'}
    placement={placement}
    anchorRef={anchorRef}
    boundaryRef={boundaryRef}
    triggerRef={triggerRef}
    open={open}
    disableFlip
    data-testid={dataTestId}
    id={id}
    onClose={onClose}
    crossAxisShift={crossAxisShift}
    portaled={portaled}
  >
    <DropdownSheetSheet size={size} height={height}>
      {children}
    </DropdownSheetSheet>
  </BaseFloatingDropdown>
})

export default DropdownSheetBase
