import React, { useMemo } from 'react'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import getLPCRegionLOS from 'lib/offer/getLPCRegionLOS'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import { useAppSelector } from 'hooks/reduxHooks'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import getOfferDurationText from 'lib/offer/getOfferDurationText'

interface Props {
  offer: App.Offer | App.OfferSummary;
  variant: 'default' | 'condensed';
}

function LEOfferForceBundlePricing(props: Props) {
  const {
    offer,
    variant,
  } = props

  const pkg = offer.lowestPricePackage!
  const flightOrigin = useAppSelector(state => getDefaultAirportCode(state))
  const flightPrice = offer.flightPrices[flightOrigin || '']
  const {
    saleUnit,
    totalValue: baseTotalValue,
    totalWithFlights,
  } = useOfferPackagePrice(pkg, offer, { flightPrice })

  const displayTotalValue = baseTotalValue + flightPrice
  const valueOffPercent = getValueOffPercent(displayTotalValue, totalWithFlights!)

  const shouldShowRate = !offer.isDiscountPillHidden && valueOffPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE
  const shouldShowValue = pkg.value > 0 && totalWithFlights && displayTotalValue > totalWithFlights && pkg.shouldDisplayValue &&
    !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue)

  let staysFrom = offer.minDuration
  const LPCRegionLOS = getLPCRegionLOS(offer)
  if (LPCRegionLOS && offer.packages.some(p => p.duration === LPCRegionLOS)) {
    staysFrom = LPCRegionLOS
  }
  const showStaysFromLabel = staysFrom && offer.type === OFFER_TYPE_ALWAYS_ON && LPCRegionLOS && staysFrom < pkg.duration
  const durationText = useMemo(() => getOfferDurationText(offer, pkg), [offer, pkg])

  return (<>
    <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
    <PriceRowPrice
      price={totalWithFlights!}
      saleUnit={saleUnit}
      size="L"
    />
    {shouldShowValue && <PriceRowValueDiscountWithCaption
      size="L"
      originalValue={Math.ceil(displayTotalValue)}
      discountPercentage={shouldShowRate ? valueOffPercent : undefined}
    />}
    {staysFrom && showStaysFromLabel && <PriceRowCaption>
      Stays from {pluralizeToString('night', staysFrom)} available
    </PriceRowCaption>}
    {!!pkg.roomOccupancy && offer.type === 'tour' && <PriceRowCaption>
      {pkg.roomOccupancy}
    </PriceRowCaption>}
    {variant === 'default' && <PriceRowTaxesAndFees />}
  </>)
}

export default LEOfferForceBundlePricing
