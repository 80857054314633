import { isEmpty, sortBy, truthyMap } from 'lib/array/arrayUtils'
import { serialiseOccupancy } from 'lib/search/searchUtils'

const stringCompare = (a, b) => a.localeCompare(b)

/**
 * Creates a unique key based on the set of filters passed in
 * This is used to uniquely identify an offer list within the application
 * @param filters The filters object for the offer list
 * @returns A key representing the offer list for the filters provided
 */
export default function getOfferListKey(filters: App.OfferListFilters) {
  const filterEntries = Object.entries(filters)

  // Order is important for the key we need it to generate the same key for the same set of filters
  // even if those filters are defined on the object in a different order
  const sortedEntries = sortBy(filterEntries, entry => entry[0])

  const keys = truthyMap(sortedEntries, ([filterType, filterValue]) => {
    // truthy check fails on numbers and we also want actual boolean falses to
    // go through as a 'false'. That means the only things we don't want are
    // undefined or null. Or NaN, please don't send a NaN
    if (!isEmpty(filterValue)) {
      switch (filterType) {
        case 'rooms':
          return `${filterType}=${serialiseOccupancy(filterValue).join(';')}`
        default:
          if (Array.isArray(filterValue) && filterValue.length > 0) {
            return (`${filterType}=` + [...filterValue].sort(stringCompare).join(','))
          }
          if (typeof filterValue !== 'object') {
            return `${filterType}=${filterValue as string}`
          }

          return undefined
      }
    }
  })

  return keys.join(';')
}
