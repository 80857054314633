import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import styled from 'styled-components'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import TextButton from 'components/Luxkit/Button/TextButton'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import OfferCardCarouselSectionFilters from './OfferCardCarouselSectionFilters'
import useOfferListFilters from 'hooks/Offers/useOfferListFilters'
import { pluralizeToString } from 'lib/string/pluralize'
import { take, takeWhile, unique } from 'lib/array/arrayUtils'
import { getHolidayTypeIcon } from 'lib/search/searchUtils'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'
import useTourFacetLocationCategory from 'hooks/TourV2/useTourFacetLocationCategory'
import Places from 'constants/places'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { OFFER_TYPE_VILLA } from 'constants/offer'
import useOfferList from 'hooks/Offers/useOfferList'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import config from 'constants/config'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

interface Props extends React.PropsWithChildren, Pick<ComponentProps<typeof OfferListCarousel>, 'tileStyle'> {
  /**
   * The title that'll be displayed
   */
  title: React.ReactNode;
  /**
   * Sub title that'll be displayed below the title
   */
  subtitle?: React.ReactNode;
  /**
   * The set of offer list filters for this carousel
   */
  filters: App.OfferListFilters;
  /**
   * Add a line of filter chips for the type of filters given
   * Is able to auto fill holidayTypes, locations and bedrooms from filter data
   *
   * You *must* provide your own destinations through the 'additionalFilterOptions' prop
   */
  additionalFilters?: 'tourLocations' | 'holidayTypes' | 'locations' | 'bedrooms' | 'destinations' | 'amenities';
  /**
   * Provide your own set of filters for the above additional filter types
   */
  additionalFilterOptions?: Array<{ value: string, label: string}>;
  /**
   * The default filter that will be used when there is no filter set
   */
  defaultFilter?: string;
  /**
   * The link the "view all" button will take the user to
   */
  allLink?: string;
  /**
   * Exclude the following offer ids from the results
   */
  excludedIds?: Array<string>;
  id?: string;
  /**
   * Hides the carousel when thre are no results for the filters given.
   *
   * NOTE: This is not ideal UX as it can cause the page to jump around as
   * the carousel loads it's data then disappears. Prefer other patterns
   * over this one (such as fall back values or "empty states"). This should
   * be a final resort only.
   */
  hideWhenEmpty?: boolean;
  additionalOfferList?: App.OfferList;
}

const OfferCardCarouselSection = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    tileStyle = 'card',
    title,
    subtitle,
    filters,
    additionalFilters,
    allLink,
    children,
    additionalFilterOptions,
    defaultFilter,
    excludedIds,
    id,
    hideWhenEmpty,
    additionalOfferList,
  } = props

  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(defaultFilter)

  const toggleFilter = useCallback((nextFilter: string) => {
    setSelectedFilter(value => value === nextFilter ? defaultFilter : nextFilter)
  }, [defaultFilter])

  const listFilters = useOfferListFilters(filters, { disabled: additionalFilters === 'tourLocations' })
  const tourFacets = useTourFacetLocationCategory({}, { disabled: additionalFilters !== 'tourLocations' })

  const filtersOptions = useMemo((): Array<{ label: string, value: string | number, icon?: React.ReactElement }> => {
    if (additionalFilterOptions) {
      return take(additionalFilterOptions, 5)
    }

    switch (additionalFilters) {
      case 'bedrooms':
        return take((Object.keys(listFilters.filters?.bedrooms ?? {})).map((roomCount) => ({
          label: pluralizeToString('bedroom', +roomCount),
          value: +roomCount,
        })), 5)
      case 'holidayTypes':
        const holidayFilters = takeWhile(
          listFilters.orderedFilters?.holidayTypes ?? [],
          filter => filter.count >= 3,
          5,
        )

        return holidayFilters.map((filter) => ({
          label: filter.value,
          value: filter.value,
          icon: getHolidayTypeIcon(filter.value.toLowerCase()),
        }))
      case 'locations':
        const locationFilters = takeWhile(
          listFilters.orderedFilters?.locations ?? [],
          filter => filter.count >= 3,
          5,
        )
        return locationFilters.map((filter) => ({
          label: filter.value,
          value: filter.value,
        }))
      case 'destinations':
        // not automatically supported, use additionalFilterOptions to provide values
        return []
      case 'amenities':
        const amenityFilters = takeWhile(
          listFilters.orderedFilters?.amenities ?? [],
          filter => filter.count >= 3,
          5,
        )
        return amenityFilters.map((filter) => {
          const Icon = getInclusionIcon(filter.value)
          return {
            label: filter.value,
            value: filter.value,
            icon: <Icon />,
          }
        })
      case 'tourLocations':
        const tourLocationOptions = Array.from(tourFacets.locations.values()).map(value => ({
          label: value.name,
          value: value.id,
        }))

        return take([{
          label: 'Around the world',
          value: Places.Anywhere.id,
        }].concat(tourLocationOptions),
        5)
      default:
        return []
    }
  }, [additionalFilters, listFilters, additionalFilterOptions, tourFacets])

  const finalFilters = useMemo((): App.OfferListFilters => {
    switch (additionalFilters) {
      case 'bedrooms':
        // Only apply bedroomEq filters on homes and villas page. Requested by Jaime Benatar
        if (filters.offerTypes?.includes(OFFER_TYPE_VILLA)) {
          return {
            ...filters,
            bedroomsEq: selectedFilter ? +selectedFilter : undefined,
          }
        }
        return {
          ...filters,
          bedroomsGte: selectedFilter ? +selectedFilter : undefined,
        }
      case 'holidayTypes':
        return {
          ...filters,
          holidayTypes: selectedFilter ? [selectedFilter] : undefined,
        }
      case 'locations':
        return {
          ...filters,
          locations: selectedFilter ? [selectedFilter] : undefined,
        }
      case 'destinations':
      case 'tourLocations':
        return {
          ...filters,
          destinationId: selectedFilter ?? filters.destinationId,
        }
      case 'amenities':
        return {
          ...filters,
          amenities: selectedFilter ? [selectedFilter] : undefined,
        }
      default:
        return filters
    }
  }, [additionalFilters, filters, selectedFilter])

  const list = useOfferList(finalFilters)
  const isTourCarousel = additionalFilters === 'tourLocations'
  const isAnywhere = (selectedFilter ?? filters.destinationId) === ANYWHERE_PLACE_ID
  const isLuxuryescapes = config.BRAND === 'luxuryescapes'
  const canUsePersonalisedTours = isTourCarousel && isAnywhere && isLuxuryescapes && !!additionalOfferList
  const haveEnoughReco = !!additionalOfferList && !additionalOfferList?.fetching && additionalOfferList?.offerIds.length >= 3
  // re-construce the overrideOfferList for tour ab test if the user in the experiment group
  const overrideOfferList = useMemo(() => {
    if (canUsePersonalisedTours) {
      // insert the lere results in front of the default tours when having >= 3 recommendations
      let offerIds: Array<string> = []
      if (haveEnoughReco) {
        offerIds = unique([...take(additionalOfferList.offerIds, 3), ...list.offerIds])
      } else {
        offerIds = list.offerIds
      }
      return {
        fetching: additionalOfferList.fetching || list.fetching,
        error: additionalOfferList.error || list.error,
        offerIds,
        key: list.key,
        offerCount: offerIds.length,
      }
    }
  }, [additionalOfferList, canUsePersonalisedTours, haveEnoughReco, list])

  if (hideWhenEmpty && list.offerIds.length === 0 && !list.fetching) {
    return null
  }

  return (
    <SectionContainer gap={20} ref={ref} id={id}>
      <LayoutContainer>
        <VerticalSpacer gap={20}>
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="start" gap={16}>
            <VerticalSpacer gap={8}>
              <Heading variant="heading2">{title}</Heading>
              {!!subtitle && <BodyText variant="large">{subtitle}</BodyText>}
            </VerticalSpacer>
            {!!allLink && <TextButton kind="tertiary" size="medium" to={allLink}>
              View all
            </TextButton>}
          </Group>
          {!!additionalFilters && <OfferCardCarouselSectionFilters
            options={filtersOptions}
            value={selectedFilter}
            onChange={toggleFilter}
          />}
        </VerticalSpacer>
      </LayoutContainer>
      <OfferListCarousel
          tileStyle={tileStyle}
          filters={finalFilters}
          limit={8}
          tabletLimit="default"
          excludedIds={excludedIds}
          overrideOfferList={overrideOfferList}
        >
        {children}
      </OfferListCarousel>
    </SectionContainer>
  )
})

OfferCardCarouselSection.displayName = 'OfferCardCarouselSection'

export default React.memo(OfferCardCarouselSection)
