import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const dealsTravelDefaultPalette: App.Palette = {
  ...whiteLabelBaseDefaultPalette,
}

export const dealsTravelShadows: App.ThemeShadows = { ...baseThemeShadows }

const dealsTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default dealsTravelTheme
