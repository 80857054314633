import createSelector from 'lib/web/createSelector'
import {
  hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade,
  hasOptionalExtras, selectablePurchasableOptionsHasRoomTypeUpgrade,
  shouldShowLuxPlusUpsellForTour,
} from 'checkout/selectors/tourV2Selectors'
import { pushWithRegion } from 'actions/NavigationActions'
import { CheckoutPageId } from 'checkout/constants/pages'
import { getCurrentCheckoutPage } from 'checkout/actions/navigateActions'
import { getCheckoutPathWithCartId } from 'lib/url/pathsUtils'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

const tourCheckoutSteps: { [key:string]: App.Checkout.CheckoutStep } = {
  package: {
    label: 'Tour package',
    step: CheckoutPageId.TourV2Upgrade,
    isRoute: true,
    subSteps: [],
    showFooter: false,
  },
  luxPlus: {
    label: LUXURY_PLUS.PROGRAM_NAME,
    step: CheckoutPageId.LuxPlus,
    isRoute: true,
    subSteps: [],
    showFooter: false,
  },
  extras: {
    label: 'Optional experiences',
    step: CheckoutPageId.TourV2OptionalExperience,
    isRoute: true,
    subSteps: [],
    showFooter: true,
  },
  purchase: {
    label: 'Review & payment',
    step: CheckoutPageId.Purchase,
    isRoute: true,
    subSteps: [],
    showFooter: false,
  },
}

export const isStepTour = (step: string) => {
  return Object.values(tourCheckoutSteps).some(
    tourStep => tourStep.step === step && step !== tourCheckoutSteps.purchase.step,
  )
}

export const getTourCheckoutSteps = createSelector(
  hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade,
  selectablePurchasableOptionsHasRoomTypeUpgrade,
  hasOptionalExtras,
  shouldShowLuxPlusUpsellForTour,
  (state) => state.checkout.cart.postPurchase,
  (hasPackageUpgrade, hasRoomUpgrade, hasExtras, showLuxPlusUpsell, postPurchaseMode): Array<App.Checkout.CheckoutStep> => {
    if (postPurchaseMode === 'tour-optional-experiences') {
      return [tourCheckoutSteps.extras, tourCheckoutSteps.purchase]
    }
    const steps = [] as Array<App.Checkout.CheckoutStep>
    if (hasPackageUpgrade || hasRoomUpgrade) {
      if (hasRoomUpgrade) {
        tourCheckoutSteps.package.showFooter = true
      } else {
        tourCheckoutSteps.package.showFooter = false
      }
      steps.push(tourCheckoutSteps.package)
    }
    if (showLuxPlusUpsell) steps.push(tourCheckoutSteps.luxPlus)
    if (hasExtras) steps.push(tourCheckoutSteps.extras)
    steps.push(tourCheckoutSteps.purchase)
    return steps
  },
)

export const navigateToTourCheckoutStep = (selectedStep?: string) => {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const steps = getTourCheckoutSteps(state)
    const firstStep = selectedStep ?? steps?.[0]?.step

    const url = getCheckoutPathWithCartId(selectedStep ?? firstStep, state.checkout.cart.cartId)

    if (firstStep) {
      dispatch(pushWithRegion(url))
    }
  }
}

export const navigateToNextStepTourCheckout = () => {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const steps = getTourCheckoutSteps(state)
    const currentStep = getCurrentCheckoutPage(state)
    const currentIndex = steps.findIndex(step => step.step === currentStep)
    const nextStep = steps?.[currentIndex + 1]
    if (nextStep) {
      const url = getCheckoutPathWithCartId(nextStep.step, state.checkout.cart.cartId)
      dispatch(pushWithRegion(url))
    }
  }
}

export const getCurrentTourCheckoutStep = createSelector(
  getTourCheckoutSteps,
  getCurrentCheckoutPage,
  (tourSteps, currentStep) => {
    const currentIndex = tourSteps.findIndex(step => step.step === currentStep)
    return tourSteps?.[currentIndex]
  },
)

export const getNextTourCheckoutStep = createSelector(
  getTourCheckoutSteps,
  getCurrentCheckoutPage,
  (tourSteps, currentStep) => {
    const currentIndex = tourSteps.findIndex(step => step.step === currentStep)
    return tourSteps?.[currentIndex + 1]
  },
)
