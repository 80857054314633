import React, { ComponentProps } from 'react'
import BodyText from '../Typography/BodyText'
import styled from 'styled-components'
import { rem } from 'polished'
import { TextBlockIcon, TextBlockProps, TextBlockStyle } from './TextBlock'
import cn from 'clsx'

const TextBlock = styled(BodyText)`
  ${TextBlockStyle}

  &.variant-small {
    --text-block-gap: ${rem(4)};
  }

  &.variant-medium,
  &.variant-large {
    --text-block-gap: ${rem(8)};
  }
`

interface Props extends Omit<ComponentProps<typeof BodyText>, 'as' | 'forwardedAs'>, TextBlockProps {
}

function BodyTextBlock(props: Props) {
  const {
    children,
    endIcon = null,
    startIcon = null,
    fit,
    align,
    tabletAlign,
    variant,
    className,
    format,
    lineClamp,
    wrap,
    ...rest
  } = props

  return <TextBlock
    {...rest}
    variant={variant}
    className={cn(
      className,
      fit ? `fit-${fit}` : undefined,
      align ? `align-${align}` : undefined,
      tabletAlign ? `align-on-tablet-${tabletAlign}` : undefined,
    )}
  >
    {!!startIcon && <TextBlockIcon>{startIcon}</TextBlockIcon>}
    <BodyText
      variant={variant}
      format={format}
      lineClamp={lineClamp}
      wrap={wrap}
      align={align}
      tabletAlign={tabletAlign}
    >
      {children}
    </BodyText>
    {!!endIcon && <TextBlockIcon>{endIcon}</TextBlockIcon>}
  </TextBlock>
}

export default BodyTextBlock
