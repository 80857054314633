import { Moment } from 'moment'
import { rem } from 'polished'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import MobileSelectTripDestinations from './MobileSelectTripDestinations'
import MobileSelectDates from '../../Common/MobileSelectDates'
import TripDatesDropdown from '../TripDetailsFields/TripDatesDropdown'
import TripNameField from '../TripDetailsFields/TripNameField'
import TripPlaceFieldDesktop from '../TripDetailsFields/TripPlaceFieldDesktop'
import TripTravellersField, {
  addIdsToRooms,
  TravellerRoomWithId,
} from '../TripDetailsFields/TripTravellersField'

import { getPlaceById } from 'api/search'
import Breakpoint from 'components/Common/Breakpoint'
import FakeInput from 'components/Common/Form/Input/FakeInput'
import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import InputChip from 'components/Luxkit/Chips/InputChip'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import Group from 'components/utils/Group'
import { DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import OfferPageStateContext from 'contexts/OfferPage/offerPageStateContext'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import useStateWithRef from 'hooks/useStateWithRef'
import useStateWithSetters from 'hooks/useStateWithSetters'
import useToggle from 'hooks/useToggle'
import { uniqueBy, without } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { omitKeys } from 'lib/object/objectUtils'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import { UpdateTripError } from 'tripPlanner/api/types'
import { getDatesAndGuests } from 'tripPlanner/components/Bookmark/BookmarkButton/utils'
import { useTripMetadata, useUpdateTrip } from 'tripPlanner/hooks/api'
import * as Mapper from 'tripPlanner/mappers'
import { FullTrip } from 'tripPlanner/types/common'
import { countTravellers } from 'tripPlanner/utils'
import { getPlaceShortLabel } from 'tripPlanner/utils/places'

const LeftDateInput = styled(FakeInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: ${rem(160)};

  &:not(.error):not(.disabled):focus-within {
    z-index: 1;
  }
`

const RightDateInput = styled(FakeInput)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
  width: ${rem(160)};
`

interface PlaceSelection {
  id: string
  name: string
}

interface Props {
  trip: FullTrip
  onBack?: () => void
  /** This is called when the modal should be closed, regardless of whether the user saved or cancelled */
  onClose: (trip: FullTrip, saved: boolean) => void
  onSaved?: (trip: FullTrip) => void
  includeNameField: boolean
  title: string
  subtitle?: string
  saveLabel: string
  cancelLabel: string
  /** For analytics */
  onSettingInteracted?: (
    type: 'destination' | 'dates' | 'adults' | 'children',
    action: 'open' | 'set' | 'remove',
  ) => void
  fillInDetailsFromContext?: boolean
}

function EditTripDetailsGeneric(props: Props) {
  const {
    trip,
    onBack,
    onClose = noop,
    onSaved = noop,
    includeNameField,
    title,
    subtitle,
    saveLabel,
    cancelLabel,
    onSettingInteracted = noop,
    fillInDetailsFromContext = false,
  } = props

  const [mode, setMode] = useStateWithSetters([
    'main',
    'origin',
    'destinations',
    'dates',
  ])
  const { data: metadata } = useTripMetadata({ tripId: trip.id })
  const offerPageState = useContext(OfferPageStateContext)
  const globalSearchState = useContext(GlobalSearchStateContext)
  const datesAndGuests = getDatesAndGuests(offerPageState, globalSearchState)

  const {
    globalSearchDispatch: searchDispatch,
    globalSearchState: searchState,
  } = useGlobalSearchContext()

  const isMobile = useIsMobileScreen()

  const [name, setName, nameRef] = useStateWithRef(trip.name)

  const [destinationInputValue, setDestinationInputValue] = useState('')
  const [tripDestinations, setTripDestinations, tripDestinationsRef] =
    useStateWithRef<Array<PlaceSelection>>(() =>
      trip.destinationsGeo ?
        trip.destinationsGeo.map((d) => ({
          id: d.lePlaceId,
          name: getPlaceShortLabel(d),
        })) :
          [],
    )
  const [tripDates, setTripDates, tripDatesRef] = useStateWithRef<{
    startDate: Moment | undefined
    endDate: Moment | undefined
  }>({
    startDate: fillInDetailsFromContext ?
      datesAndGuests.checkInDate :
      trip.startDate,
    endDate: fillInDetailsFromContext ?
      datesAndGuests.checkOutDate :
      trip.endDate,
  })
  const [travellerRooms, setTravellerRooms, travellerRoomsRef] =
    useStateWithRef<Array<TravellerRoomWithId>>(() => {
      const roomsFromContexts = datesAndGuests.occupancies?.map((o) => {
        return { adults: o.adults, childAges: o.childrenAge ?? [] }
      })
      const rooms =
        (fillInDetailsFromContext ? roomsFromContexts : trip.travellerRooms) ??
        []
      return addIdsToRooms(rooms)
    })

  const dateDropdownTrigger = useRef<HTMLDivElement>(null)
  const {
    value: dateDropdownOpen,
    on: openDateDropdown,
    off: closeDateDropdown,
  } = useToggle()

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  const { mutate, isLoading } = useUpdateTrip({
    onSuccess: (updatedTripRaw) => {
      const updatedTrip = Mapper.fullTrip(updatedTripRaw)
      onClose(updatedTrip, true)
      onSaved(updatedTrip)
    },
    onError: (e: UpdateTripError) => {
      if (e.status === 422) {
        setErrorMsg(e.message)
      }
    },
  })

  const formIsValid = !isLoading && name.length > 0 && !destinationInputValue

  const onCloseClick = useCallback(() => {
    onClose(trip, false)
  }, [onClose, trip])

  const onUpdate = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (!formIsValid) {
        return
      }
      mutate({
        tripId: trip.id,
        name: includeNameField ? nameRef.current : undefined,
        destinationPlaceIds: tripDestinationsRef.current.map((d) => d.id),
        startDate: tripDatesRef.current.startDate ?? null,
        endDate: tripDatesRef.current.endDate ?? null,
        travellerRooms: travellerRoomsRef.current.map(omitKeys(['id'])),
      })
    },
    [
      mutate,
      trip.id,
      formIsValid,
      includeNameField,
      nameRef,
      tripDestinationsRef,
      tripDatesRef,
      travellerRoomsRef,
    ],
  )

  const selectDestinations = useCallback(
    (destinations: Array<PlaceSelection>) => {
      const prevDestinationIds = tripDestinationsRef.current.map((d) => d.id)
      const newDestinationIds = destinations.map((d) => d.id)

      const addedIds = without(newDestinationIds, ...prevDestinationIds)
      addedIds.forEach(() => {
        onSettingInteracted('destination', 'set')
      })

      const removedIds = without(prevDestinationIds, ...newDestinationIds)
      removedIds.forEach(() => {
        onSettingInteracted('destination', 'remove')
      })

      setTripDestinations(destinations)
      setMode.main()
    },
    [onSettingInteracted, setMode, setTripDestinations, tripDestinationsRef],
  )

  const selectDestination = useCallback(
    (selection: PlaceSelection) => {
      const placeId = selection.id
      if (!tripDestinations.find((p) => p.id === placeId)) {
        onSettingInteracted('destination', 'set')
        setTripDestinations((prev) => {
          return [
            ...prev,
            {
              id: placeId,
              name: selection.name,
            },
          ]
        })
        // Typeahead API doesn't include country code, so do a separate request to get that
        getPlaceById(placeId)
          .then((place) => {
            if (place.type !== 'country' && place.countryCode) {
              const name = `${place.name}, ${place.countryCode}`
              setTripDestinations((prev) =>
                prev.map((p) => (p.id === placeId ? { ...p, name } : p)),
              )
            }
          })
          .catch(() => {
            // do nothing
          })
      }
      setDestinationInputValue('')
    },
    [tripDestinations, onSettingInteracted, setTripDestinations],
  )

  const removeDestination = (placeId: string) => {
    setTripDestinations((prev) => prev.filter((p) => p.id !== placeId))
    onSettingInteracted('destination', 'remove')
  }

  const clearDestinationInput = () => {
    setDestinationInputValue('')
  }

  const updateTravellerRooms = useCallback(
    (newTravellerRooms: Array<TravellerRoomWithId>) => {
      const { adults: prevAdults, children: prevChildren } = countTravellers(
        travellerRoomsRef.current,
      )
      const { adults: newAdults, children: newChildren } =
        countTravellers(newTravellerRooms)
      if (newAdults !== prevAdults) {
        onSettingInteracted('adults', 'set')
      }
      if (newChildren !== prevChildren) {
        onSettingInteracted('children', 'set')
      }

      setTravellerRooms(newTravellerRooms)
    },
    [onSettingInteracted, setTravellerRooms, travellerRoomsRef],
  )

  const onDateInputClick = useCallback(() => {
    onSettingInteracted('dates', 'open')
    if (isMobile) {
      setMode.dates()
    } else {
      openDateDropdown()
    }
  }, [isMobile, onSettingInteracted, openDateDropdown, setMode])

  const selectDates = useCallback(
    (startDate: moment.Moment, endDate?: moment.Moment) => {
      setTripDates({ startDate, endDate: endDate ?? startDate })
      setMode.main()
      closeDateDropdown()
      onSettingInteracted('dates', 'set')
    },
    [setTripDates, setMode, closeDateDropdown, onSettingInteracted],
  )

  const clearDates = useCallback(() => {
    setTripDates({ startDate: undefined, endDate: undefined })
    setMode.main()
    closeDateDropdown()
    onSettingInteracted('dates', 'remove')
  }, [setTripDates, setMode, closeDateDropdown, onSettingInteracted])

  useEffect(() => {
    if (fillInDetailsFromContext && metadata) {
      setTripDestinations((prev) => {
        const implicitDestinations = metadata.locations.map((l) => ({
          id: l.placeGroup.lePlaceId,
          name: l.placeGroup.lePlaceName,
        }))
        return uniqueBy(prev.concat(implicitDestinations), (p) => p.id)
      })
    }
  }, [metadata, setTripDestinations, fillInDetailsFromContext])

  if (mode === 'destinations') {
    return (
      <GlobalSearchContextProvider
        state={searchState}
        dispatch={searchDispatch}
      >
        <MobileSelectTripDestinations
          initialDestinations={tripDestinations}
          title="Destinations"
          doneLabel={
            tripDestinations.length > 0 ?
              'Update destinations' :
              'Add destinations'
          }
          onChange={selectDestinations}
          onBack={setMode.main}
          onCancel={setMode.main}
        />
      </GlobalSearchContextProvider>
    )
  } else if (mode === 'dates') {
    return (
      <MobileSelectDates
        title="Dates"
        doneLabel={tripDates.startDate ? 'Update dates' : 'Add dates'}
        initialStartDate={tripDates.startDate}
        initialEndDate={tripDates.endDate}
        onChange={selectDates}
        onRemove={tripDates.startDate ? clearDates : undefined}
        onBack={setMode.main}
        onCancel={setMode.main}
      />
    )
  }

  return (
    <GlobalSearchContextProvider state={searchState} dispatch={searchDispatch}>
      <form onSubmit={onUpdate}>
        <ModalHeader
          title={title}
          subtitle={subtitle}
          onBackButtonClick={onBack}
          onCloseButtonClick={onCloseClick}
        />
        <ModalBody>
          <ModalContent>
            <VerticalSpacer gap={32}>
              {errorMsg && (
                <MessageBanner kind="critical" description={errorMsg} />
              )}
              {includeNameField && (
                <TripNameField
                  label="Trip name"
                  value={name}
                  onChange={setName}
                  disabled={isLoading}
                />
              )}
              <VerticalSpacer gap={12}>
                <Breakpoint max="mobile">
                  <FakeInput
                    label="Add trip destinations"
                    placeholder="Search by city"
                    onClick={setMode.destinations}
                    disabled={isLoading}
                  />
                </Breakpoint>
                <Breakpoint min="tablet">
                  <TripPlaceFieldDesktop
                    label="Add trip destinations"
                    inputValue={destinationInputValue}
                    onInputChange={setDestinationInputValue}
                    onSelect={selectDestination}
                    onClear={clearDestinationInput}
                    disabled={isLoading}
                    isInvalidState={!!destinationInputValue}
                  />
                </Breakpoint>
                {tripDestinations.length > 0 && (
                  <Group
                    direction="horizontal"
                    horizontalAlign="start"
                    gap={8}
                    wrap="wrap"
                  >
                    {tripDestinations.map((place) => (
                      <InputChip
                        key={place.id}
                        variant="filled"
                        onClick={() => removeDestination(place.id)}
                      >
                        {place.name}
                      </InputChip>
                    ))}
                  </Group>
                )}
              </VerticalSpacer>

              <VerticalSpacer gap={4}>
                <InputLabelWrap label="When?" />
                <Group direction="horizontal" ref={dateDropdownTrigger}>
                  <LeftDateInput
                    value={tripDates.startDate?.format(DMY_CASUAL_SHORT_FORMAT)}
                    placeholder="Start date"
                    onClick={onDateInputClick}
                    disabled={isLoading}
                    startIcon={<LineCalendarIcon />}
                    noValidationSpacing
                  />
                  <RightDateInput
                    value={tripDates.endDate?.format(DMY_CASUAL_SHORT_FORMAT)}
                    placeholder="End date"
                    onClick={onDateInputClick}
                    disabled={isLoading}
                    startIcon={<LineCalendarIcon />}
                    noValidationSpacing
                  />
                </Group>
                {dateDropdownOpen && (
                  <TripDatesDropdown
                    triggerRef={dateDropdownTrigger}
                    isOpen
                    startDate={tripDates.startDate}
                    endDate={tripDates.endDate}
                    onSelect={selectDates}
                    onClear={tripDates.startDate ? clearDates : undefined}
                    onClose={closeDateDropdown}
                  />
                )}
              </VerticalSpacer>

              <TripTravellersField
                travellerRooms={travellerRooms}
                onChange={updateTravellerRooms}
                allowEmpty
              />
            </VerticalSpacer>
          </ModalContent>
        </ModalBody>
        <ModalFooter
          primaryActionProps={{
            children: saveLabel,
            disabled: isLoading,
            type: 'submit',
          }}
          secondaryActionProps={{
            children: cancelLabel,
            disabled: isLoading,
            onClick: onCloseClick,
          }}
        />
      </form>
    </GlobalSearchContextProvider>
  )
}

export default EditTripDetailsGeneric
