import createSelector from 'lib/web/createSelector'
import { isOrganicSearch } from 'lib/search/searchUtils'
import {
  isAndroid,
  isApple,
  isIOS,
  isMobile,
} from 'lib/web/deviceUtils'

export const hasOrganicReferrer = createSelector(
  (state: App.State) => state.config.referrer,
  (referrer) => isOrganicSearch(referrer),
)

export const isAndroidSel = createSelector(
  (state: App.State) => state.config.userAgent,
  (userAgent) => isAndroid(userAgent.osName),
)

export const isAppleSel = createSelector(
  (state: App.State) => state.config.userAgent,
  (userAgent) => isApple(userAgent.osName),
)

export const isIOSSel = createSelector(
  (state: App.State) => state.config.userAgent,
  (userAgent) => isIOS(userAgent.osName),
)

export const isMobileSel = createSelector(
  (state: App.State) => state.config.userAgent,
  (userAgent) => isMobile(userAgent.osName),
)

export const isHeadlessModeSel = (state: App.State) => state.config.headlessMode

export const isMobileAppSel = createSelector(
  isMobileSel,
  isHeadlessModeSel,
  (isMobileSel, isHeadlessModeSel) => isMobileSel && isHeadlessModeSel,
)

export const useMobileNativeAppAuth = createSelector(
  (state: App.State) => state.config.headlessMode,
  (headlessMode) => {
    return headlessMode &&
    typeof window !== 'undefined' &&
    (
      window.webkit?.messageHandlers?.authenticateUser ||
      window.appInterface
    )
  },
)
