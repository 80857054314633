import { ExperienceOfferPageState } from 'contexts/Experience/ExperienceOfferPage/experienceOfferPageStateReducer'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import { arrayToMap } from 'lib/array/arrayUtils'
import {
  isTourV2Offer,
  isTourV2OfferSummary,
  isLEOffer,
  isBedbank,
} from 'lib/offer/offerTypes'
import { OfferTypeForTripItemTypes } from 'tripPlanner/hooks/api'
import {
  BasicTrip,
  BookmarkIdSet,
  TripItemAndOfferAndDatesIdSet,
} from 'tripPlanner/types/common'
import { BookmarkDatesAndOccupancy } from 'tripPlanner/types/tripItem'

const includedParams = new Set([
  'originAirportCode',
  'originAirportName',
  'destinationAirportCode',
  'destinationAirportName',
  'departDate',
  'returnDate',
  'adults',
  'children',
  'infants',
  'childrenAge',
  'fareClass',
  'fareType',
])

export type SaveItemsCallbackResult = {
  savedItemIds: Array<string>
}

export const IS_SAVED_COPY = 'Saved'
export const NOT_SAVED_COPY = 'Save'
export const NOT_SAVED_ROOM_COPY = 'Save room'

export function flightSearchQueryStringToBookmarkId(queryString: string) {
  const params = new URLSearchParams(queryString)
  for (const param of [...params.keys()]) {
    if (!includedParams.has(param)) {
      params.delete(param)
    }
  }
  return '?' + params.toString()
}

export function flightSearchQueryStringWithoutTripId(queryString: string) {
  const params = new URLSearchParams(queryString)
  params.delete('tripId')
  return '?' + params.toString()
}

export function flightSearchQueryStringWithoutAirportNames(
  queryString: string,
) {
  const params = new URLSearchParams(queryString)
  params.delete('originAirportName')
  params.delete('destinationAirportName')
  return '?' + params.toString()
}

export const getPropertyName = (offer: OfferTypeForTripItemTypes): string => {
  if ((isLEOffer(offer) || isBedbank(offer)) && offer.property) {
    return offer.property.name
  }

  return offer.name
}

export const getOfferImageId = (
  offer: Exclude<OfferTypeForTripItemTypes, App.CruiseOffer>,
  selectedVariation?: Tours.TourV2OfferVariation,
): string | undefined => {
  if (isTourV2Offer(offer) || isTourV2OfferSummary(offer)) {
    return selectedVariation?.images[0].id
  }

  return offer.image?.id ?? offer.images?.[0]?.id
}

export const getDatesAndGuests = (
  offerPageState: OfferPageState | undefined,
  globalSearchState: GlobalSearchState | undefined,
): BookmarkDatesAndOccupancy => {
  if (offerPageState) {
    const checkOutDate =
      offerPageState.checkOutDate ??
      (offerPageState.checkInDate && offerPageState.duration) ?
        offerPageState.checkInDate
          ?.clone()
          .add(offerPageState.duration, 'days') :
        undefined

    return {
      checkInDate: offerPageState.checkInDate,
      checkOutDate,
      durationInDays: offerPageState.duration,
      occupancies: offerPageState.rooms,
    }
  }
  if (globalSearchState) {
    return {
      checkInDate: globalSearchState.checkinDate,
      checkOutDate: globalSearchState.checkoutDate,
      occupancies: globalSearchState.occupancies,
    }
  }
  return {}
}

export const needHotelPrice = (
  datesAndGuests: BookmarkDatesAndOccupancy,
): boolean =>
  !!datesAndGuests.checkInDate &&
  !!datesAndGuests.checkOutDate &&
  !!datesAndGuests.occupancies &&
  datesAndGuests.occupancies.length > 0

export const getLabelText = (
  isSaved: boolean,
  isRoom: boolean | undefined,
): string => {
  if (!isSaved && isRoom) {
    return NOT_SAVED_ROOM_COPY
  }

  return isSaved ? IS_SAVED_COPY : NOT_SAVED_COPY
}

const plannedIdSetContainsOfferId = (
  offerId: string,
  roomTypeId?: string,
): ((plannedIdSet: TripItemAndOfferAndDatesIdSet) => boolean) => {
  return (plannedIdSet) =>
    plannedIdSet.offerIdAndMeta.startsWith(offerId) &&
    !!(!roomTypeId || roomTypeId === plannedIdSet.roomTypeId)
}

const bookmarkIdSetContainsOfferId =
  (offerId: string): ((bookmarkIdSet: BookmarkIdSet) => boolean) =>
    (bookmarkIdSet) =>
      bookmarkIdSet.offerId === offerId

type SavedDetail = { trip: BasicTrip; itemId: string }

export const getBookmarkDetailsForOffer = (
  trips: Array<BasicTrip>,
  offerId: string,
): SavedDetail | undefined => {
  const trip = trips.find((trip) =>
    trip.bookmarkIdSets.some(bookmarkIdSetContainsOfferId(offerId)),
  )
  if (trip) {
    const idSet = trip.bookmarkIdSets.find(
      bookmarkIdSetContainsOfferId(offerId),
    )

    if (idSet?.itemId) {
      return { trip, itemId: idSet.itemId }
    }
    return undefined
  }
}

export const getSavedDetailsForOffer = (
  trips: Array<BasicTrip>,
  offerId: string,
  roomTypeId?: string,
): Array<SavedDetail> => {
  const foundTrips = trips.flatMap((trip) => {
    const plannedIdSets = trip.plannedIdSets.filter(
      plannedIdSetContainsOfferId(offerId, roomTypeId),
    )
    return plannedIdSets.map((idSet) => ({
      trip,
      itemId: idSet.itemId,
    }))
  })

  if (foundTrips.length > 0) {
    return foundTrips
  } else if (!roomTypeId) {
    const bookmarkDetail = getBookmarkDetailsForOffer(trips, offerId)
    if (bookmarkDetail) {
      return [bookmarkDetail]
    }
  }
  return []
}

function occupanciesHaveChildrenAgeDefined(
  occupancies: Array<App.Occupants>,
): occupancies is Array<App.Occupants & { childrenAge: Array<number> }> {
  return occupancies.every((o) => o.childrenAge)
}

export function convertOccupancies(
  occupancies: Array<App.Occupants> | undefined,
):
  | Array<{
      adults: number
      childrenAge: Array<number>
    }>
  | undefined {
  if (
    occupancies &&
    occupancies.length > 0 &&
    occupanciesHaveChildrenAgeDefined(occupancies)
  ) {
    return occupancies.map((o) => ({
      adults: o.adults,
      childrenAge: o.childrenAge,
    }))
  }
  return undefined
}

export function mapExperienceTicketSelections(
  experienceOfferPageState: ExperienceOfferPageState | undefined,
  allTicketOptions: Array<App.ExperienceItemTicket>,
):
  | Array<{
      ticketId: string
      fareType: string
      count: number
      name: string
    }>
  | undefined {
  if (!experienceOfferPageState?.ticketCounts) {
    return undefined
  }

  const ticketOptionsById = arrayToMap(allTicketOptions, (option) => option.id)

  return Object.entries(experienceOfferPageState.ticketCounts)
    .filter(([ticketId, count]) => count > 0 && ticketOptionsById.has(ticketId))
    .map(([ticketId, count]) => ({
      ticketId,
      fareType: ticketOptionsById.get(ticketId)!.fareType,
      count,
      name: ticketOptionsById.get(ticketId)!.name,
    }))
}
