import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'

const BadgeSection = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;

  > * {
    margin-top: ${rem(10)};
  }
  > * + * {
    margin-left: ${rem(4)};
  }
`

interface Props {
  promotions: Array<App.BedbankPromotion>;
}

function BedbankPromotions({ promotions }: Props) {
  return <>{promotions.map(promotion => (
    <BadgeSection key={promotion.name}>
      <ProductTypeLabel productType="limited_time_special" />
      <BodyText variant="medium" weight="bold">{promotion.name}</BodyText>
    </BadgeSection>
  ))}</>
}

export default BedbankPromotions
