import { CruisesContract } from '@luxuryescapes/contract-svc-cruise'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { EmptyArray, unique } from 'lib/array/arrayUtils'
import { CRUISE_FLEXIBLE_DURATION_RANGE } from 'constants/search'

export const titleGenderMap = {
  Sr: 'M',
  Mstr: 'M',
  Mr: 'M',
  Ms: 'F',
  Mrs: 'F',
  Miss: 'F',
}

export function getCruiseDurationByItinerary(itinerary?: Array<App.CruiseItineraryItem>): number {
  const uniqueItineraryDays = new Set<number>()
  itinerary?.forEach(item => uniqueItineraryDays.add(item.startDay))

  return uniqueItineraryDays.size
}

export function getCruiseCountriesByItinerary(itinerary?: Array<App.CruiseItineraryItem>): Array<string> {
  const countries = (itinerary ?? []).map(({ port }) => port?.countryCode).filter(Boolean) as Array<string>
  return countries?.length ? unique(countries) : []
}

export function getCruisePortsByItinerary(itinerary?: Array<App.CruiseItineraryItem>): Array<string> {
  const cities = (itinerary ?? []).map(({ port }) => {
    if (!port) return

    const portName = port.name.split(',')[0]
    if (port.city?.trim() === '') return portName

    return port.city ?? portName
  }).filter(Boolean) as Array<string>

  return cities?.length ? unique(cities) : []
}

export function offerHasValidDepartures(offer: App.CruiseOffer): boolean {
  return (offer.departureDates ?? []).filter(d => moment(new Date(d)) >= moment()).length > 0
}

type PricingSchedule = Partial<CruisesContract.PricingCancellationSchedule> & Partial<CruisesContract.PricingPaymentSchedule>
export function filterDataByHighestPercentage(data: Array<PricingSchedule>, isNonRefundable: boolean) {
  const areAllDatesEqual = data.every(obj => obj.date === data[0].date)
  let filteredData = data

  if (areAllDatesEqual) {
    filteredData = data.filter(obj => obj.isPercentage)
  }

  const finalResult = {}

  filteredData.forEach(obj => {
    const date = obj.date as string
    const amount = obj.amount as number
    if (!(date in finalResult) || amount > finalResult[date].amount) {
      finalResult[date] = {
        ...obj,
        isNonRefundable: !!isNonRefundable,
      }
    }
  })

  const newArray: Array<App.PricingCancellationSchedule> = Object.values(finalResult)

  return newArray
}

export interface CruiseOfferPriceVariation {
  primaryPrice: {
    total: number;
    type: string;
  }
  secondaryPrice?: {
    total: number;
    type: string;
  }
}

export function getCruiseOfferPriceVariation(
  offer: App.CruiseOffer,
  price?: App.CruisePrice | App.LowestPriceWithCategory | App.CruisePriceDetails,
  isPricePerNight?: boolean,
): CruiseOfferPriceVariation {
  if (!price) {
    return {
      primaryPrice: {
        total: 0,
        type: 'person',
      },
    }
  }

  const pricePerPerson = {
    total: price.total,
    type: 'person',
  }

  if (offer.showPricePerNight || isPricePerNight) {
    const pricePerNight = {
      total: Math.round(price.total / offer.duration),
      type: 'night',
    }

    return {
      primaryPrice: pricePerNight,
      secondaryPrice: pricePerPerson,
    }
  }

  return {
    primaryPrice: pricePerPerson,
  }
}

export function getItineraryUniqueKey(itinerary: App.CruiseItineraryItem): string {
  const { title, startDay, startTime, endTime } = itinerary
  return `${title}-${startDay}-${startTime}-${endTime}`
}

// ----------------------  Search Utils ----------------------
function queryParamsToSearchItem(
  queryParams: URLSearchParams,
  idKey: string,
  nameKey: string,
): App.SearchItem | undefined {
  const id = queryParams.get(idKey)
  const name = queryParams.get(nameKey)
  if (id && name) {
    return {
      searchType: 'destination',
      value: id,
      format: {
        mainText: name,
      },
    }
  }
}

function queryParamsToSearchItems(
  queryParams: URLSearchParams,
  idsKey: string,
  namesKey: string,
): Array<App.SearchItem> {
  const searchPlaceIds = queryParams.getAll(idsKey)
  const searchNames = queryParams.getAll(namesKey)

  return searchPlaceIds.map((placeId, index) => {
    return {
      searchType: 'destination',
      value: placeId,
      format: {
        mainText: searchNames[index],
      },
    }
  })
}

export function getCruiseSearchStateFromParams(queryParams: URLSearchParams): Partial<GlobalSearchState> {
  const durationMin = queryParams.get('durationMin')
  const durationMax = queryParams.get('durationMax')
  const durationRange = queryParams.getAll('durationRange') as unknown as Array<CRUISE_FLEXIBLE_DURATION_RANGE>
  return {
    searchItem: queryParamsToSearchItem(queryParams, 'destinationId', 'destinationName'),
    searchItems: queryParamsToSearchItems(queryParams, 'destinationIds', 'destinationNames'),
    secondarySearchItem: queryParamsToSearchItem(queryParams, 'departureId', 'departureName'),
    secondarySearchItems: queryParamsToSearchItems(queryParams, 'departureIds', 'departureNames'),
    cruiseLines: queryParams.getAll('cruiseLines'),
    flexibleMonths: queryParams.get('departureMonths') ?? undefined,
    durationMin: durationMin ? parseInt(durationMin) : undefined,
    durationMax: durationMax ? parseInt(durationMax) : undefined,
    durationRange: durationRange ?? undefined,
    checkinDate: queryParams.get('checkinDate') ? moment(queryParams.get('checkinDate')) : undefined,
    checkoutDate: queryParams.get('checkoutDate') ? moment(queryParams.get('checkoutDate')) : undefined,
  }
}

export function getCruiseOfferFiltersFromParams(queryParams: URLSearchParams): [App.OfferListFilters, boolean] {
  const minPrice = queryParams.get('minPrice')
  const maxPrice = queryParams.get('maxPrice')
  const durationMin = queryParams.get('durationMin')
  const durationMax = queryParams.get('durationMax')
  const cruiseShips = queryParams.getAll('cruiseShips')
  const cabinTypes = queryParams.getAll('cabinTypes')
  const isLuxExclusivePromotion = Boolean(queryParams.get('isLuxExclusivePromotion'))
  const isSpecialOffers = Boolean(queryParams.get('isSpecialOffers'))
  const filtersApplied = Boolean(minPrice || maxPrice || cruiseShips.length || cabinTypes.length || isLuxExclusivePromotion || isSpecialOffers)

  return [{
    offerTypes: ['cruise'],
    destinationIds: queryParams.getAll('destinationIds'),
    destinationNames: queryParams.getAll('destinationNames'),
    destinationId: queryParams.get('destinationId') ?? undefined,
    destinationName: queryParams.get('destinationName') ?? undefined,
    departureName: queryParams.get('departureName') ?? undefined,
    departurePlaceId: queryParams.get('departureId') ?? undefined,
    departureIds: queryParams.getAll('departureIds'),
    departureNames: queryParams.getAll('departureNames'),
    durationMin: durationMin ? parseInt(durationMin) : undefined,
    durationMax: durationMax ? parseInt(durationMax) : undefined,
    durationRange: queryParams.getAll('durationRange') ?? undefined,
    departureStartDate: queryParams.get('checkinDate') ?? undefined,
    departureEndDate: queryParams.get('checkoutDate') ?? undefined,
    cruiseLines: queryParams.getAll('cruiseLines'),
    departureMonths: queryParams.get('departureMonths')?.split(','),
    rateCodes: queryParams.getAll('rateCodes'),
    cruiseShips,
    cabinTypes,
    isSpecialOffers,
    isLuxExclusivePromotion,
    minPrice: minPrice ? parseInt(minPrice) : undefined,
    maxPrice: maxPrice ? parseInt(maxPrice) : undefined,
    sortBy: queryParams.get('sortBy') as App.OfferListSortByOptions ?? undefined,
    priceBy: queryParams.get('priceBy') ?? undefined,
  }, filtersApplied]
}

export function getCruiseExtraFilters(queryParams: URLSearchParams, globalSearch?: GlobalSearchState) {
  const cruiseShips = queryParams.getAll('cruiseShips')
  const minPrice = queryParams.get('minPrice')
  const maxPrice = queryParams.get('maxPrice')
  const isSpecialOffers = queryParams.get('isSpecialOffers')
  const isLuxExclusivePromotion = queryParams.get('isLuxExclusivePromotion')
  const sortBy = queryParams.get('sortBy')

  return {
    cruiseShips,
    cabinTypes: queryParams.get('cabinTypes') ?? undefined,
    isSpecialOffers: Boolean(isSpecialOffers) || undefined,
    isLuxExclusivePromotion: Boolean(isLuxExclusivePromotion) || undefined,
    sortBy: sortBy || undefined,
    priceBy: queryParams.get('priceBy') ?? undefined,
    minPrice: minPrice ? parseInt(minPrice) : undefined,
    maxPrice: maxPrice ? parseInt(maxPrice) : undefined,
    durationMin: queryParams.get('durationMin') ?? undefined,
    durationMax: queryParams.get('durationMax') ?? undefined,
    durationRange: globalSearch?.durationRange ?? queryParams.getAll('durationRange') ?? undefined,
  }
}

export function getCruiseGlobalFilters(globalSearch: GlobalSearchState) {
  const {
    searchItem,
    searchItems,
    secondarySearchItem,
    secondarySearchItems,
    cruiseLines,
    flexibleMonths,
    durationMax,
    durationMin,
    durationRange,
    checkinDate,
    checkoutDate,
  } = globalSearch

  return {
    cruiseLines,
    durationMax: durationMax || undefined,
    durationMin: durationMin || undefined,
    durationRange: durationRange || undefined,
    destinationId: searchItem?.value,
    destinationName: searchItem?.format.mainText,
    departureId: secondarySearchItem?.value,
    departureName: secondarySearchItem?.format.mainText,
    departureMonths: flexibleMonths || undefined,
    destinationNames: searchItems?.map((item) => item.format.mainText),
    destinationIds: searchItems?.map((item) => item.value),
    departureNames: secondarySearchItems?.map((item) => item.format.mainText),
    departureIds: secondarySearchItems?.map((item) => item.value),
    ...(!!checkinDate && !!checkoutDate && {
      checkinDate: checkinDate?.format(ISO_DATE_FORMAT),
      checkoutDate: checkoutDate?.format(ISO_DATE_FORMAT),
      departureStartDate: checkinDate?.format(ISO_DATE_FORMAT),
      departureEndDate: checkoutDate?.format(ISO_DATE_FORMAT),
    }),
  }
}

export function getGlobalFacetFilters(globalSearch: GlobalSearchState) {
  const {
    searchItem,
    searchItems,
    secondarySearchItem,
    secondarySearchItems,
    cruiseLines,
    flexibleMonths,
    durationMax,
    durationMin,
    checkinDate,
    checkoutDate,
    durationRange,
  } = globalSearch

  const departureMonths = flexibleMonths?.split(',').filter(Boolean) || EmptyArray

  return {
    cruiseLines,
    durationMax: durationMax || undefined,
    durationMin: durationMin || undefined,
    destinationId: searchItem?.value,
    destinationName: searchItem?.format.mainText,
    departureId: secondarySearchItem?.value,
    departureName: secondarySearchItem?.format.mainText,
    destinationNames: searchItems.map((item) => item.format.mainText),
    destinationIds: searchItems.map((item) => item.value),
    departureNames: secondarySearchItems.map((item) => item.format.mainText),
    departureIds: secondarySearchItems.map((item) => item.value),
    ...(departureMonths.length && { departureMonths }),
    ...(!!checkinDate && !!checkoutDate && {
      checkinDate: checkinDate.format(ISO_DATE_FORMAT),
      checkoutDate: checkoutDate.format(ISO_DATE_FORMAT),
      departureStartDate: checkinDate.format(ISO_DATE_FORMAT),
      departureEndDate: checkoutDate.format(ISO_DATE_FORMAT),
    }),
    ...(durationRange?.length && { durationRange }),
  }
}

export function getCruiseFilters(globalSearch: GlobalSearchState, queryParams?: URLSearchParams) {
  const globalFilters = getCruiseGlobalFilters(globalSearch)

  return {
    ...globalFilters,
    ...(queryParams ? getCruiseExtraFilters(queryParams, globalSearch) : {}),
  }
}

export function getCruiseSearchFiltersBar(
  values: App.CruiseGlobalFilters,
  globalSearch: GlobalSearchState,
  pathname: string,
  regionCode: string,
  forceSearchPage = true,
) {
  const {
    searchItem,
    searchItems,
    secondarySearchItem,
    flexibleMonths,
    durationMax,
    durationMin,
    durationRange,
    cruiseLines,
    checkinDate,
    checkoutDate,
  } = values

  const filters: App.CruiseGlobalFilters = {
    ...globalSearch,
    ...(cruiseLines && { cruiseLines }),
  }

  if ('durationMin' in values) {
    filters.durationMin = durationMin
  }

  if ('durationMax' in values) {
    filters.durationMax = durationMax
  }

  if ('durationRange' in values) {
    filters.durationRange = durationRange
  }

  if ('flexibleMonths' in values) {
    filters.flexibleMonths = flexibleMonths
  }

  if ('searchItem' in values) {
    filters.searchItem = searchItem
  }

  if ('searchItems' in values) {
    filters.searchItems = searchItems
  }

  if ('secondarySearchItem' in values) {
    filters.secondarySearchItem = secondarySearchItem
  }

  if ('checkinDate' in values) {
    filters.checkinDate = checkinDate
  }

  if ('checkoutDate' in values) {
    filters.checkoutDate = checkoutDate
  }

  const pageName = forceSearchPage ? '/search/cruises' : pathname.replace(`/${regionCode.toLowerCase()}`, '')
  return {
    pageName,
    filters: filters as GlobalSearchState,
  }
}
