import cn from 'clsx'
import React, { PropsWithChildren, useCallback } from 'react'

import IconButton from 'components/Luxkit/Button/IconButton'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import SolidHeartIcon from 'components/Luxkit/Icons/solid/SolidHeartIcon'
import noop from 'lib/function/noop'
import { themeClassName } from 'lib/theme/themeUtils'
import { preventDefault, stopPropagation } from 'lib/web/eventHandlerUtils'

interface Props {
  className?: string
  label?: string
  onClick?: () => void
  isSaved?: boolean
  isProcessing?: boolean
  testId?: string
  endIcon?: React.ReactElement
  iconOnly?: boolean
}

function BaseBookmarkButton(props: PropsWithChildren<Props>) {
  const {
    children,
    className,
    label,
    onClick = noop,
    endIcon,
    isSaved = false,
    isProcessing = false,
    testId,
    iconOnly = false,
  } = props
  const savedIcon = isSaved ? (
    <SolidHeartIcon colour={isProcessing ? 'neutral-four' : 'favourite'} />
  ) : (
    <LineHeartIcon colour={isProcessing ? 'neutral-four' : 'favourite'} />
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      preventDefault(e)
      stopPropagation(e)

      onClick()
    },
    [onClick],
  )

  return iconOnly ? (
    <IconButton
      className={themeClassName('default')}
      shape="circle"
      kind="secondary"
      variant="dark"
      onClick={handleClick}
      disabled={isProcessing}
      aria-label={label}
      size="medium"
      data-testid={cn(testId, {
        'bookmark-button-saved': isSaved,
        'bookmark-button-unsaved': !isSaved,
      })}
    >
      {savedIcon}
    </IconButton>
  ) : (
    <AssistChip
      className={cn(className, themeClassName('default'))}
      variant="filled"
      size="small"
      onClick={handleClick}
      startIcon={savedIcon}
      aria-label={label}
      disabled={isProcessing}
      data-testid={cn(testId, {
        'bookmark-button-saved': isSaved,
        'bookmark-button-unsaved': !isSaved,
      })}
      endIcon={endIcon}
    >
      {label ?? children}
    </AssistChip>
  )
}

export default React.memo(BaseBookmarkButton)
