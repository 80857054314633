import clsx from 'clsx'
import FormatCurrency from 'components/Common/FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import React, { ComponentProps } from 'react'

type InheritedFormatCurrencyProps = Pick<ComponentProps<typeof FormatCurrency>, 'signDisplay'>

interface Props extends InheritedFormatCurrencyProps {
  nonMemberPrice: number
  size: 'M' | 'L'
  className?: string
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center' | 'end'
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

function PriceStackLoyaltyNonMemberPrice(props: Props) {
  const {
    nonMemberPrice,
    signDisplay,
    size,
    className,
    horizontalAlign = 'start',
  } = props

  const captionVariant = SIZES_CAPTION_VARIANTS[size]

  return <Group
    direction="vertical"
    horizontalAlign={horizontalAlign}
    className={clsx(PriceStackLoyaltyNonMemberPrice.name, className)}
  >
    <Caption variant={captionVariant} colour="neutral-one">
      Non-member
    </Caption>
    <Caption variant={captionVariant} weight="bold" colour="neutral-two">
      <FormatCurrency signDisplay={signDisplay} value={nonMemberPrice} format="roundedDollar" />
    </Caption>
  </Group>
}

export default PriceStackLoyaltyNonMemberPrice
