import { isFlightItem } from 'checkout/lib/utils/flights/cart'
import { getCheckoutFlightV2SearchUrl, getCheckoutFlightV1SearchUrl, getStandaloneFlightsV1SearchUrl, getStandaloneFlightsV2SearchUrl } from 'checkout/lib/utils/flights/links'
import { nonNullable } from 'lib/array/arrayUtils'
import {
  buildFlightItemBreakdownView,
  formatFareType,
  formatFlightPassengersShorthand,
  formatJourneyShorthand,
} from 'lib/checkout/flights/format'
import createSelector from 'lib/web/createSelector'

import { getJourneyV2IdKey } from 'lib/flights/flightUtils'
import { convertJourneyV2ToJourneyFlight } from 'api/lib/convertJourneyV2'
import config from 'constants/config'
import { generateCheckoutItemViewOfferCreditKey } from 'checkout/lib/utils/businessTraveller/cart'
import { FlightViewTypes } from 'constants/flight'
import { isBookingProtectionItem, isInsuranceItem, isLuxPlusSubscriptionItem } from 'lib/checkout/checkoutUtils'

export const getFlightItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.FlightItem> => items.filter(isFlightItem),

)

export const flightSearchInProgress = createSelector(
  (state: App.State) => state.flights.searchFlights,
  (state: App.State) => state.flights.searchV2Flights,
  (flightsV1, flightsV2): boolean => {
    const journeysV1Fetching = Object.values(flightsV1).flatMap(flight => flight?.fetching).filter(Boolean)
    const journeysV2Fetching = Object.values(flightsV2).flatMap(flight => flight?.fetching).filter(Boolean)
    return !!(journeysV1Fetching.length || journeysV2Fetching.length)
  },
)

export const getBundledFlightItems = createSelector(
  getFlightItems,
  (items): Array<App.Checkout.FlightItem> => items.filter(item => !!item.bundledItemIds?.length),

)

export const cartIncludesFlights = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.some(isFlightItem),
)

function mapFlightV2ToView(
  item: App.Checkout.FlightItem,
  airports: Array<App.Airport>,
  departing?: App.JourneyV2,
  returning?: App.JourneyV2,
): App.Checkout.FlightItemView {
  const departingFare = item.departing?.cost ?? 0
  const returningFare = item.returning?.cost ?? 0
  const departingSurcharge = departing?.price.all.totalFees ?? 0
  const returningSurcharge = returning?.price.all.totalFees ?? 0
  const isBundled = (item.bundledItemIds?.length ?? 0) > 0

  const totalFare = item.totalFare ?? 0
  const isReturnFares = [departing?.fareType, returning?.fareType].includes(FlightViewTypes.RETURN)
  let price = totalFare > 0 ? totalFare : departingFare + returningFare

  if (isReturnFares) {
    // For return fares, if departing and returning is defined, we must use the sum of departingFare and returning fare
    // This happens because after updating the fare with an upsell fare family, each segment total fare is updated individually
    // If the only departing is defined, we can use the totalRoundTripPrice since it represents the price considering for
    // the selected departing flight and the cheapest returning flight paired within it
    price = returning ? departingFare + returningFare : (departing?.price.all.totalRoundTripPrice ?? 0)
  } else if (departing && !returning && item.viewType !== FlightViewTypes.ONE_WAY) {
    // For V2 two one-ways, if the returning fare is not defined, we must use the departing.price.all.totalFare because
    // it has the updated departing flight price after selecting the fare family. The item.departing.cost is only updated
    // after the select endpoint call. We must also add the item.quotedFare price because the cheapest flight price
    // is already included in the total Hotel + flights package. In order to have correct increase in price based on the relative price
    // displayed in the flight tile, we must add the quotedFare to the total price
    price = departing.price.all.totalFare + (item.quotedFare ?? 0) / 2
  }

  return {
    itemId: item.itemId,
    item,
    journeyId: item.searchId,
    provider: departing?.provider,
    passengers: item.passengers,
    departing: (item.departing && departing) ? {
      journeyFlight: convertJourneyV2ToJourneyFlight(departing),
      extras: item.departing.extras,
      cost: item.departing.cost,
      fareFamily: item.departing.fareFamily,
    } : undefined,
    returning: (item.returning && returning) ? {
      journeyFlight: convertJourneyV2ToJourneyFlight(returning),
      extras: item.returning.extras,
      cost: item.returning.cost,
      fareFamily: item.returning.fareFamily,
    } : undefined,
    designation: 'Flights',
    validatingCarrier: departing?.carrier,
    validatingCarrierName: departing?.carrierName ?? '',
    validatingCarrierLogo: departing?.carrierLogo ?? '',
    returningCarrierName: returning?.carrierName ?? '',
    returningCarrierLogo: returning?.carrierLogo ?? '',
    fareType: item.fareType,
    price,
    memberPrice: 0, // No data on this
    value: price,
    surcharge: departingSurcharge + returningSurcharge,
    seatSelectionPolicy: departing?.flightGroup.flights[0].seatSummary.selectionPolicy,
    isBundled,
    taxesAndFees: 0,
    quotedFare: item.quotedFare,
    otherFees: item.otherFees,
    searchFlightsUrl: isBundled ?
      getCheckoutFlightV2SearchUrl(departing, returning) :
      getStandaloneFlightsV2SearchUrl(item, airports, departing, returning),
    viewType: item.viewType,
  }
}

function mapFlightToView(
  item: App.Checkout.FlightItem,
  journey?: App.Journey,
): App.Checkout.FlightItemView {
  const isBundled = (item.bundledItemIds?.length ?? 0) > 0

  return {
    itemId: item.itemId,
    item,
    journeyId: item.searchId,
    provider: journey?.provider,
    passengers: item.passengers,
    departing: (item.departing && journey?.departing) ? {
      journeyFlight: journey.departing,
      extras: item.departing.extras,
      cost: item.departing.cost,
      fareFamily: item.departing.fareFamily,
    } : undefined,
    returning: (item.returning && journey?.returning) ? {
      journeyFlight: journey.returning,
      extras: item.returning.extras,
      cost: item.returning.cost,
      fareFamily: item.returning.fareFamily,
    } : undefined,
    designation: 'Flights',
    validatingCarrier: journey?.validatingCarrier,
    validatingCarrierName: journey?.validatingCarrierName ?? '',
    validatingCarrierLogo: journey?.validatingCarrierLogo ?? '',
    fareType: item.fareType,
    price: (item.totalFare || journey?.cost) ?? 0,
    memberPrice: 0, // No data on this
    value: 0, // No data on this
    surcharge: journey?.fee,
    seatSelectionPolicy: journey?.seatSelectionPolicy,
    isBundled,
    taxesAndFees: 0,
    otherFees: item.otherFees,
    quotedFare: item.quotedFare,
    searchFlightsUrl: isBundled ?
      getCheckoutFlightV1SearchUrl(journey) :
      getStandaloneFlightsV1SearchUrl(item, journey),
  }
}

export const getFlightItemsView = createSelector(
  (state: App.State) => getFlightItems(state),
  (state: App.State) => state.flights.journeysById,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => state.geo.airports,
  (flightItems, journeys, offerCredits, airports): App.WithDataStatus<Array<App.Checkout.FlightItemView>> => {
    const anyItemsFetching = flightItems.some(item => item.fetchingFare)
    const flightViews = nonNullable(
      flightItems.map<{ hasRequiredData: boolean, view: App.Checkout.FlightItemView | undefined }>(item => {
        if (item.searchId) {
          if (item.journeyType === 'journeyV2') {
            const departingKey = getJourneyV2IdKey(item.departing?.journeyId!, item.searchId, item.departing?.fareFamily?.id)
            const returningKey = getJourneyV2IdKey(item.returning?.journeyId!, item.searchId, item.returning?.fareFamily?.id)

            return {
              view: mapFlightV2ToView(
                item,
                airports,
                journeys[departingKey] as App.JourneyV2,
                journeys[returningKey] as App.JourneyV2,
              ),
              hasRequiredData: !!journeys[departingKey] && (!item.returning || !!journeys[returningKey]),
            }
          } else if (item.journeyType === 'journeyV1') {
            return {
              view: mapFlightToView(item, journeys[item.searchId] as App.Journey),
              hasRequiredData: !!journeys[item.searchId],
            }
          }
        }

        return {
          view: mapFlightToView(item),
          hasRequiredData: true,
        }
      }),
    )

    const hasAllRequiredData = flightViews.every(fv => fv.hasRequiredData) && !anyItemsFetching && flightViews.length === flightItems.length

    // inject business credits into flightItemView
    if (config.businessTraveller.currentAccountMode === 'business' &&
      offerCredits
    ) {
      for (const flightView of flightViews) {
        if (flightView.view) {
          const offerCreditKey = generateCheckoutItemViewOfferCreditKey(flightView.view)
          const offerCredit = offerCredits[offerCreditKey]

          if (offerCredit?.status === 'success') {
            flightView.view.businessTravellerCredits = offerCredit.creditValue
          }
        }
      }
    }

    return {
      hasRequiredData: hasAllRequiredData,
      data: nonNullable(flightViews.map(view => view.view)),
    }
  },
)

export const getFlightBreakdownView = createSelector(
  getFlightItemsView,
  (state: App.State) => state.businessTraveller.offersCredits,
  (flightViewsWithStatus, offerCredits): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    const flightBreakdownViews = flightViewsWithStatus.data.map<App.Checkout.PriceBreakdownView>(view => {
      const supplementaryData = {
        validatingCarrierName: view.validatingCarrierName,
        validatingCarrierLogo: view.validatingCarrierLogo,
      }
      const breakdownView: App.Checkout.PriceBreakdownView = {
        title: formatFareType(view.fareType),
        additionalInfoText: [
          ...(view.departing ? [formatJourneyShorthand(view.departing.journeyFlight)] : []),
          formatFlightPassengersShorthand(view.passengers),
        ],
        price: view.isBundled ? 0 : (view.price ?? 0),
        memberPrice: view.isBundled ? 0 : (view.memberPrice ?? 0),
        items: [
          ...(view.departing ? [buildFlightItemBreakdownView(view, view.departing, supplementaryData)] : []),
          ...(view.returning ? [buildFlightItemBreakdownView(view, view.returning, supplementaryData)] : []),
        ],
      }

      if (config.businessTraveller.currentAccountMode === 'business' &&
        offerCredits
      ) {
        const offerCreditKey = generateCheckoutItemViewOfferCreditKey(view)
        const offerCredit = offerCredits[offerCreditKey]
        if (offerCredit?.status === 'success') {
          breakdownView.businessTravellerCredits = offerCredit.creditValue
        }
      }

      return breakdownView
    })

    return {
      hasRequiredData: flightViewsWithStatus.hasRequiredData,
      data: flightBreakdownViews,
    }
  },
)

export const selectEarnableFlightBusinessTravellerCreditsTotal = createSelector(
  getFlightItemsView,
  (
    flightViewsWithStatus,
  ): App.WithDataStatus<App.Checkout.ItemTotals> => {
    let price = 0
    for (const flightBreakdownView of flightViewsWithStatus.data) {
      price += flightBreakdownView.businessTravellerCredits ?? 0
    }

    return {
      hasRequiredData: flightViewsWithStatus.hasRequiredData,
      data: {
        price,
        taxesAndFees: 0,
        memberPrice: 0,
      },
    }
  },
)

export const isStandaloneFlights = createSelector(
  (state: App.State) => state.checkout.cart.items,
  getFlightItems,
  (allItems, flightItems): boolean => {
    // a standalone flight can still have insurance/booking protection and/or a luxplus subscription
    return flightItems.length > 0 && allItems.every(item => isFlightItem(item) || isInsuranceItem(item) || isBookingProtectionItem(item) || isLuxPlusSubscriptionItem(item))
  },
)
