import createSelector from 'lib/web/createSelector'
import { sum } from 'lib/array/arrayUtils'
import { isLuxPlusSubscriptionItem, isSubscriptionJoinItem } from 'lib/checkout/checkoutUtils'
import { addYears, intlFormatter } from 'lib/datetime/dateUtils'
import { INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import { isActiveSubscribedLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

export const getLuxPlusSubscriptionItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.LuxPlusSubscriptionItem> => items.filter(isLuxPlusSubscriptionItem),
)

export const isStandaloneLuxPlusSubscription = createSelector(
  (state : App.State) => state.checkout.cart.items,
  (items): boolean => items.every(item => isLuxPlusSubscriptionItem(item) || isSubscriptionJoinItem(item)),
)

export const isLuxPlusSubscriptionInCart = createSelector(
  getLuxPlusSubscriptionItems,
  (items): boolean => items.length > 0,
)

export const isMemberOrHasSubscriptionInTheCart = createSelector(
  isLuxPlusSubscriptionInCart,
  isActiveSubscribedLuxPlusMember,
  (isLuxPlusSubscriptionInCart, isActiveSubscribedLuxPlusMember): boolean => !!(isLuxPlusSubscriptionInCart || isActiveSubscribedLuxPlusMember),
)

export const eligibleLuxPlusCheckoutNotGift = createSelector(
  isMemberOrHasSubscriptionInTheCart,
  (state: App.State) => state.checkout.cart.isGift,
  (isMemberOrHasSubscriptionInTheCart, isGift): boolean => isMemberOrHasSubscriptionInTheCart && !isGift,
)

export const checkoutWithMemberPrice = createSelector(
  eligibleLuxPlusCheckoutNotGift,
  isStandaloneLuxPlusSubscription,
  (eligibleLuxPlusCheckoutNotGift, isStandaloneLuxPlusSubscription): boolean => {
    return eligibleLuxPlusCheckoutNotGift && !isStandaloneLuxPlusSubscription
  },
)

export const getLuxPlusSubscriptionItemView = createSelector(
  getLuxPlusSubscriptionItems,
  (luxPlusItems): App.WithDataStatus<Array<App.Checkout.LuxPlusSubscriptionItemView>> => {
    return {
      hasRequiredData: true,
      data: luxPlusItems.map(item => {
        return {
          itemId: item.itemId,
          price: item.amount,
          taxesAndFees: 0,
          memberPrice: 0,
        }
      }),
    }
  },
)

export const getLuxPlusSubscriptionsTotalPrice = createSelector(
  getLuxPlusSubscriptionItems,
  (items): number => sum(items, item => item.amount || 0),
)

export const getSubscriptionJoinItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items) => items.filter(isSubscriptionJoinItem),
)

export const getSubscriptionJoinItemView = createSelector(
  getSubscriptionJoinItems,
  (items): App.WithDataStatus<Array<App.Checkout.SubscriptionJoinItemView>> => {
    return {
      hasRequiredData: true,
      data: items.map(item => {
        return {
          itemId: item.itemId,
          price: item.waived ? 0 : item.amount,
          value: item.amount,
          taxesAndFees: 0,
          memberPrice: 0,
        }
      }),
    }
  },
)

export const getLuxPlusBreakdownView = createSelector(
  getLuxPlusSubscriptionItemView,
  getLuxPlusSubscriptionsTotalPrice,
  getSubscriptionJoinItems,
  (subscriptionViews, totalPrice, joinViews): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (subscriptionViews.data.length === 0) {
      return { hasRequiredData: subscriptionViews.hasRequiredData, data: [] }
    }

    const breakdownViews: Array<App.Checkout.PriceBreakdownView> = subscriptionViews.data.map(view => {
      const joinFeeItem = joinViews.find(join => join.subscriptionItemId === view.itemId)
      const breakdown: App.Checkout.PriceBreakdownView = {
        title: `${LUXURY_PLUS.PROGRAM_NAME} Membership`,
        price: totalPrice,
        memberPrice: 0,
        additionalInfoText: [],
        alwaysShowItemPrice: true,
        itemsAlwaysVisible: true,
        items: [{
          itemType: 'lux-plus-subscription',
          title: '',
          itemId: view.itemId,
          additionalInfoText: [
            'Yearly membership',
            `Renews ${intlFormatter(addYears(new Date(), 1), INTL_DMY_CASUAL_SHORT_FORMAT)}.`,
          ],
          additionalElements: joinFeeItem?.amount ? [{
            id: 'policy',
            type: 'cancellation-policy',
            left: {
              type: 'text',
              value: 'Cancel renewal anytime',
            },
            right: {
              type: 'price',
              value: 0,
            },
          }] : [],
        }],
      }

      if (joinFeeItem) {
        if (!joinFeeItem.waived) {
          breakdown.items[0].additionalElements.push({
            id: 'joinFee',
            type: 'price-line',
            left: {
              type: 'text',
              value: 'One-off joining fee',
            },
            right: {
              type: 'price',
              value: joinFeeItem.amount,
            },
          })
        }
        if (joinFeeItem.waived && joinFeeItem.amount) {
          breakdown.items[0].additionalElements.push({
            id: 'joinFeeWaiver',
            variant: 'loyalty',
            type: 'price-line',
            left: {
              type: 'text',
              value: 'Special offer: No joining fee',
            },
            right: {
              type: 'price',
              value: joinFeeItem.amount,
              strikethrough: true,
            },
          })
        }
      }

      return breakdown
    })

    return {
      hasRequiredData: true,
      data: breakdownViews,
    }
  },
)
