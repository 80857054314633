import { productImpression, productClick } from 'analytics/enhanced-ecommerce'
import { productClickObject, productImpressionObject } from 'analytics/eventDefinitions'
import { trackOfferListClick } from 'api/interactionStudio'
import GeoContext from 'contexts/geoContext'
import useStableObjectReference from 'hooks/useStableObjectReference'
import noop from 'lib/function/noop'
import React, { PropsWithChildren, useCallback, useContext } from 'react'
import { RequireAtLeastOne } from 'type-fest'
import * as Analytics from 'analytics/analytics'
import { productClickEventWithContext, impressionEventWithContext } from 'analytics/snowplow/events'

export enum OfferListEvents {
  productClick,
  impression,
  listLoad,
  clientAction,
  hover,
  offerReady
}

export interface OfferImpressionData {
  offer: App.Offer
  index: number
}

export interface OfferClickData {
  offer: App.Offer
  index: number
}

export interface OfferHoverData {
  offer: App.Offer
  index: number
}

export interface OfferListLoadData {
  key: string,
  list: {
    error: string,
    fetching: boolean,
    offerIds: Array<string>
  },
}

type OfferListAction = (action: OfferListEvents, data?: any, additionalData?: any) => void
const OfferListEventsContext = React.createContext<OfferListAction>(noop)

type Props = RequireAtLeastOne<{
  /**
   * Event that will be fired whenever an action occurs in the offer list
   */
  onListEvent?: OfferListAction;
  /**
   * Use this to augment any data to the `onListEvent` function
   */
  additionalData?: any;
  /**
   * Pass a tracking config object to enable default tracking.
   * This currently will fire analytics for:
   * - Product clicks
   * - Impressions
   *
   * Use this to automatically track the 'common' metrics
   * For any extra metrics, please hook into the `onListEvent` prop
   */
  tracking?: App.OfferListTrackingConfig;
}, 'onListEvent' | 'tracking'>

export function OfferListEventsProvider(props: PropsWithChildren<Props>) {
  const { onListEvent = noop, additionalData, tracking } = props
  const { currentCurrency } = useContext(GeoContext)
  const stableAdditionalData = useStableObjectReference(additionalData)

  const value = useCallback((action: OfferListEvents, data) => {
    onListEvent(action, data, stableAdditionalData)

    if (tracking) {
      if (action === OfferListEvents.productClick) {
        const { offer, position } = data
        Analytics.trackClientEvent({
          subject: 'click-any-offer',
          action: 'clicked',
          category: 'logging',
          type: 'operational',
          optimizelyEventId: '28377200726',
          optimizelyEventKey: 'click-any-offer',
        })

        // If the individual offer has different sources, will map the source here
        let finalTracking = tracking
        if (tracking.offerSourceMapping) {
          const offerSource = tracking.offerSourceMapping[offer.id]
          finalTracking = { ...tracking, listSource: offerSource ?? tracking.listSource }
        }
        productClick(
          currentCurrency,
          productClickObject(offer, position + 1),
          finalTracking,
        )

        if (finalTracking.ias) {
          trackOfferListClick({
            index: position,
            list: finalTracking.ias.list,
            offerId: offer.id,
            listId: finalTracking.ias.listId,
          })
        }
        if (tracking?.snowplow?.enabled) {
          Analytics.trackEvent(productClickEventWithContext(position + 1, finalTracking.listName, tracking.listId, offer))
        }
      } else if (action === OfferListEvents.impression) {
        const { offer, position } = data
        // If the individual offer has different sources, will map the source here
        let finalTracking = tracking
        if (tracking.offerSourceMapping) {
          const offerSource = tracking.offerSourceMapping[offer.id]
          finalTracking = { ...tracking, listSource: offerSource ?? tracking.listSource }
        }
        if (tracking?.snowplow?.enabled) {
          Analytics.trackEvent(impressionEventWithContext(position + 1, finalTracking.listName, tracking.listId, offer))
        }
        productImpression(
          currentCurrency,
          [productImpressionObject(offer, finalTracking.listName, position + 1)],
          finalTracking,
        )
      }
    }
  }, [currentCurrency, onListEvent, stableAdditionalData, tracking])

  return <OfferListEventsContext.Provider value={value}>
    {props.children}
  </OfferListEventsContext.Provider>
}

export default OfferListEventsContext
