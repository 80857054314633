import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import React, { useCallback, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { RouteChildrenProps, RouteProps, useHistory } from 'react-router'
import { isLoggedIn } from 'selectors/accountSelectors'
import ModalContext from 'contexts/ModalContext'
import AccountAccessModal, { AccountAccessModalResult } from 'components/Account/AccountAccess/AccountAccessModal'
import { pushWithRegion } from 'actions/NavigationActions'
import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import { isCheckout } from 'selectors/checkoutSelectors'
import { getCheckoutDepth } from 'checkout/hooks/useCheckoutRouteTracker'

interface Props extends RouteChildrenProps, Pick<RouteProps, 'children' | 'render' | 'component'> {
  isLoggedIn: boolean;
  loggingIn: boolean;
  regionCode: string;
  showPopup?: boolean;
}

function AuthRouteComponent(props: Props) {
  const {
    isLoggedIn,
    loggingIn,
    regionCode,
    component: Component,
    showPopup,
    children,
    render,
    ...rest
  } = props
  const { match } = props

  const dispatch = useAppDispatch()
  const path = match?.path
  const isCheckoutPage = useAppSelector(isCheckout)
  const history = useHistory()
  const showModal = useContext(ModalContext)

  /**
   * CheckoutDepth is 0 if we're on the first checkout step.
   * Thus, we sum 1 to get the previous page, which is the offer page.
   */
  const goBackToOfferPage = useCallback(() => {
    const checkoutDepth = getCheckoutDepth()
    history.go(-(checkoutDepth + 1))
  }, [history])

  useEffect(() => {
    if (showPopup && !isLoggedIn) {
      const useTripPlannerVersiom = !!path?.startsWith('/:regionCode/trip-planner/trip/')
      showModal<AccountAccessModalResult>(<AccountAccessModal
        initialMode={useTripPlannerVersiom ? 'tripPlannerLogin' : 'login'}
      />).then(result => {
        if (result?.loggedIn) {
          // success, let them through
        } else {
          // no success, they closed it or something else
          const isRestoreCart = path?.includes('/checkout-restore-cart')
          if (isCheckoutPage && !isRestoreCart) {
            goBackToOfferPage()
          } else {
            dispatch(pushWithRegion('/'))
          }
        }
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, showPopup, path])

  if (!isLoggedIn && showPopup) {
    // We're waiting for the response on the auth modal now
    return <LoadingIndicator />
  }

  if (!isLoggedIn) {
    // Don't have access to this route and we don't want to show a login pop up
    // send them directly back to the home page
    return <RedirectWithStatus code={302} to={`/${regionCode.toLowerCase()}`}/>
  }

  if (Component) {
    return <Component {...rest} />
  }

  if (render) {
    return <>{render(rest)}</>
  }

  return <>{children}</>
}

function mapStateToProps(state: App.State) {
  return {
    isLoggedIn: isLoggedIn(state),
    loggingIn: state.auth.processing,
    regionCode: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(AuthRouteComponent)
