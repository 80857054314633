import React, { useCallback } from 'react'

import RadioInput from 'components/Luxkit/Radio/RadioInput'
import { FlightDropdownOption as SelectOption } from 'components/Flights/types'
import { rem } from 'polished'
import styled from 'styled-components'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'

// TODO: Replace with design system Tag
const InfoTag = styled(Caption)`
  padding: ${rem(2)} ${rem(8)};
  background-color: ${props => props.theme.palette.neutral.default.six};
  color: ${props => props.theme.palette.neutral.default.one};
  border: 1px solid ${props => props.theme.palette.neutral.default.eight};
  border-radius: ${props => props.theme.borderRadius.S};
`

interface Props {
  defaultChecked?: boolean;
  checked?: boolean;
  option: SelectOption;
  name: string;
  onChange: (option: SelectOption) => void;
  disabled?: boolean;
}

function FlightDropdownOption(props: Props) {
  const {
    defaultChecked,
    checked,
    option,
    onChange,
    name,
  } = props

  const onDropdownChange = useCallback(() => {
    onChange(option)
  }, [onChange, option])

  return (
    <RadioInput
      defaultChecked={defaultChecked}
      checked={checked}
      onChange={onDropdownChange}
      name={name}
      value={option.value}
      data-testid={option.value}
      disabled={option.disabled}
    >
      <Group direction="horizontal" horizontalAlign="space-between">
        <span>{option.label}</span>
        {option.comingSoon && <InfoTag variant="medium" weight="bold" wrap="no-wrap">Coming soon!</InfoTag>}
      </Group>
    </RadioInput>
  )
}

FlightDropdownOption.displayName = 'FlightDropdownOption'

export default FlightDropdownOption
