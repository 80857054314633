import { useState, useCallback } from 'react'
import { breakpointSizes } from 'components/utils/breakpoint'
import { average } from 'lib/array/arrayUtils'
import { getMetresDistanceFromLatLong } from 'lib/flights/getDistanceFromLatLong'
import { getWidth } from 'lib/window/getViewport'
import * as isomorphicSessionStorage from 'lib/storage/isomorphicSessionStorage'
import { MAP_TILE_PADDING } from 'constants/search'
import { Position } from 'components/Search/type'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'

type OfferTypes = App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary | App.OfferListMetaData
type FullOfferTypes = App.Offer | App.BedbankOffer
type OfferWithLocation = FullOfferTypes | App.OfferListMetaData

const MAP_TILE_MAX_WIDTH = 400
const MAP_TILE_BEDBANK_HEIGHT = 330

interface Geolocation {
  longitude: number
  latitude: number
}

function hasLongitudeAndLatitude(location: { longitude?: number, latitude?: number }): boolean {
  return location.longitude !== undefined && location.latitude !== undefined
}

function getGeolocation(offer: OfferWithLocation): Geolocation
function getGeolocation(offer: OfferTypes): Geolocation | undefined
function getGeolocation(offer: OfferTypes): Geolocation | undefined {
  return isOfferListMeta(offer) ? offer.location : offer.property
}

export function isLoadedOffer(offer: OfferTypes): offer is FullOfferTypes {
  return 'id' in offer
}

export function isOfferListMeta(offer: OfferTypes): offer is App.OfferListMetaData {
  return 'offerId' in offer
}

function hasValidLocation(offer: OfferTypes): offer is OfferWithLocation {
  const propertyOrTour = getGeolocation(offer)
  return !!propertyOrTour && hasLongitudeAndLatitude(propertyOrTour)
}

export function getMarkersByOffers(offers: Array<OfferTypes>, includeOneOfferPerMarker?: boolean): Array<App.SearchMapMarker> {
  const offerGroups = groupOffersAtSameCoordinates(offers.filter(hasValidLocation))
  if (includeOneOfferPerMarker) {
    return offerGroups.map(offers => getMarkerByOfferGroup([offers[0]]))
  }
  return offerGroups.map(offers => getMarkerByOfferGroup(offers))
}

function getMarkerByOfferGroup(offers: Array<OfferWithLocation>): App.SearchMapMarker {
  const latitude = average(offers, offer => getGeolocation(offer).latitude)
  const longitude = average(offers, offer => getGeolocation(offer).longitude)

  return {
    id: offers.map(offer => getOfferId(offer)).join(','),
    latitude: parseFloat((latitude).toFixed(6)),
    longitude: parseFloat((longitude).toFixed(6)),
    offers,
  }
}

export function getOfferId(offer: OfferTypes): string {
  if (isOfferListMeta(offer)) {
    return offer.offerId
  } else {
    return offer.id
  }
}

/*
  Groups offers that either have the same property, or are in the same building, or otherwise are so
  close together that it's not feasible to show separate markers for them.
  To make this algorithm more efficient, first the offers are sorted by their latitude.
  That means for each offer, instead of comparing it against every other offer (which would have
  O(n^2) time complexity), we can stop comparing once we reach an offer that's too far away in
  latitude - each subsequent offer will also be too far away, so we can break that loop and move on.
*/
export function groupOffersAtSameCoordinates(offers: Array<OfferWithLocation>): Array<Array<OfferWithLocation>> {
  const metresThreshold = 10
  const groupedOfferSet = new Set<OfferWithLocation>()
  const groups: Array<Array<OfferWithLocation>> = []

  for (let i = 0; i < offers.length; i++) {
    const referenceOffer = offers[i]

    if (groupedOfferSet.has(referenceOffer)) {
      continue
    }

    const { latitude: lat1, longitude: lon1 } = getGeolocation(referenceOffer)

    const group = [referenceOffer]
    groupedOfferSet.add(referenceOffer)

    // Find offers that are within the threshold distance from `referenceOffer`
    for (let j = i + 1; j < offers.length; j++) {
      const thisOffer = offers[j]
      const { latitude: lat2, longitude: lon2 } = getGeolocation(thisOffer)

      if (groupedOfferSet.has(thisOffer)) {
        continue // Skip offers already in a group
      }

      if (getMetresDistanceFromLatLong(lat1, lon1, lat2, lon2) < metresThreshold) {
        group.push(thisOffer)
        groupedOfferSet.add(thisOffer)
      }
    }

    groups.push(group)
  }

  return groups
}

/*
  The fudged versions of the padding are smaller by one pixel. This is because, when trying to
  fit the map to bounds that should exactly match the viewport with the correct padding, they
  end up not quite fitting (presumably due to rounding errors or something), and the map ends
  up zoomed out by one level to make them fit. Decreasing the padding by a pixel fixes this.
*/
const mobilePadding: google.maps.Padding = { top: 70, left: 20, bottom: 48, right: 20 }
const desktopPadding: google.maps.Padding = { top: 67, left: 60, bottom: 40, right: 60 }
const mobilePaddingFudged: google.maps.Padding = { top: 69, left: 19, bottom: 47, right: 19 }
const desktopPaddingFudged: google.maps.Padding = { top: 66, left: 59, bottom: 39, right: 59 }

export function getPadding(fudged: boolean = false) {
  if (getWidth() >= breakpointSizes.tablet.min) {
    return fudged ? desktopPaddingFudged : desktopPadding
  } else {
    return fudged ? mobilePaddingFudged : mobilePadding
  }
}

/**
 * Gives us the map bounds within the padding, so we can search for offers without them appearing too
 * close to the edge of the map.
 */
export function subtractPaddingFromBounds(bounds: google.maps.LatLngBounds, boundingRect: DOMRect) {
  const padding = getPadding()

  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()
  const longitudeSpan = ne.lng() - sw.lng()
  const latitudeSpan = ne.lat() - sw.lat()

  const longitudeDegreesPerPixel = longitudeSpan / boundingRect.width
  const latitudeDegreesPerPixel = latitudeSpan / boundingRect.height

  const paddingTop = latitudeDegreesPerPixel * (padding?.top ?? 0)
  const paddingBottom = latitudeDegreesPerPixel * (padding?.bottom ?? 0)
  const paddingLeft = longitudeDegreesPerPixel * (padding?.left ?? 0)
  const paddingRight = longitudeDegreesPerPixel * (padding?.right ?? 0)

  return new google.maps.LatLngBounds(
    new google.maps.LatLng({ lat: sw.lat() + paddingBottom, lng: sw.lng() + paddingLeft }),
    new google.maps.LatLng({ lat: ne.lat() - paddingTop, lng: ne.lng() - paddingRight }),
  )
}

// over 480 - the desktop version of the fitted map looks good, less than 480 - a lot of grey space on top and under the map
const MAX_MOBILE_WIDTH = 480

export function fitMarkersOnMap(map: google.maps.Map | null, markers: Array<App.SearchMapMarker | App.SearchExperienceMapMarker>, centerMarker?: App.SearchMapMarker | App.SearchExperienceMapMarker) {
  if (!map || markers.length === 0) {
    return
  }

  if (markers.length === 1) {
    map.setCenter(new google.maps.LatLng(markers[0].latitude, markers[0].longitude))
    map.setZoom(15)
    return
  }

  const bounds = new google.maps.LatLngBounds()

  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()

  if (!ne || !sw) {
    return
  }

  markers.forEach(marker => {
    bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude))
  })

  const longitudeSpan = ne.lng() - sw.lng()

  const viewportWidth = getWidth()

  if (viewportWidth <= MAX_MOBILE_WIDTH && longitudeSpan > 100) {
    if (centerMarker) {
      map.setCenter(new google.maps.LatLng(centerMarker.latitude, centerMarker.longitude))
      const height = map.getDiv().offsetHeight
      const listener = google.maps.event.addListener(map, 'idle', () => {
        map.panBy(0, height / 3)
        google.maps.event.removeListener(listener)
      })
    } else {
      map.setCenter(new google.maps.LatLng(markers[0]?.latitude, markers[0]?.longitude))
    }
    map.setZoom(1)
  } else {
    map.fitBounds(bounds, getPadding(false))

    if (centerMarker) {
      const listener = google.maps.event.addListener(map, 'idle', () => {
        map.setCenter(new google.maps.LatLng(centerMarker.latitude, centerMarker.longitude))
        if (viewportWidth <= MAX_MOBILE_WIDTH) {
          const height = map.getDiv().offsetHeight
          map.panBy(0, height / 3)
        }
        google.maps.event.removeListener(listener)
      })
    }
  }
}

export function centerAndZoomOnMarker(map: google.maps.Map | null, marker: App.SearchMapMarker, shouldZoom: boolean) {
  if (map) {
    map.setCenter(new google.maps.LatLng(marker.latitude, marker.longitude))
    if (shouldZoom) {
      map.setZoom(16)
    }
  }
}

export function parseBounds(bounds: string) {
  const [latLo, lngLo, latHi, lngHi] = bounds.split(',')
  return new google.maps.LatLngBounds(
    new google.maps.LatLng(+latLo, +lngLo),
    new google.maps.LatLng(+latHi, +lngHi),
  )
}

export function mapHasAnyMarkersInView(map: google.maps.Map | null, markers: Array<App.SearchMapMarker>): boolean {
  const bounds = map?.getBounds()
  if (!bounds) { return false }
  return markers.some(marker => bounds.contains(new google.maps.LatLng(marker.latitude, marker.longitude)))
}

const vmStorageKey = 'searchMapVisitedMarkers'
export function useVisitedMarkersState() {
  const [visitedMarkerIds, setVisitedMarkerIds] = useState(() => {
    const storedIdsRaw = isomorphicSessionStorage.get(vmStorageKey)
    return new Set<string>(storedIdsRaw ? JSON.parse(storedIdsRaw) : [])
  })

  const addVisitedMarkerId = useCallback((markerId: string) => {
    setVisitedMarkerIds(ids => {
      if (ids.has(markerId)) { return ids }
      const newIds = new Set(ids)
      newIds.add(markerId)
      isomorphicSessionStorage.set(vmStorageKey, JSON.stringify(Array.from(newIds)))
      return newIds
    })
  }, [])

  return [visitedMarkerIds, addVisitedMarkerId] as const
}

export function isMarkerWithinBounds(marker: App.SearchMapMarker, bounds: google.maps.LatLngBounds): boolean {
  const markerBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(marker.latitude, marker.longitude),
    new google.maps.LatLng(marker.latitude, marker.longitude),
  )
  return bounds.intersects(markerBounds)
}

export function getCenterOfCluster(markers: Array<App.SearchMapMarker>): { latitude: number, longitude: number } {
  const latitudes = markers.map(marker => marker.latitude)
  const longitudes = markers.map(marker => marker.longitude)
  return {
    latitude: average(latitudes),
    longitude: average(longitudes),
  }
}

export function getPositionAndOffsetOfTile(
  event: Event | React.MouseEvent<Element | MouseEvent>,
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  isBedbank: boolean,
): {
  position: Position | undefined;
  offset: { xOffset: number; yOffset: number } | undefined;
} {
  const maxWidth = MAP_TILE_MAX_WIDTH + 2 * MAP_TILE_PADDING
  const flashTileHeight = 500
  // tile height is estimated since we don't know the tile height until it's rendered
  const maxHeight = (isBedbank ? MAP_TILE_BEDBANK_HEIGHT : flashTileHeight) + 2 * MAP_TILE_PADDING
  const pin = event.currentTarget as HTMLDivElement
  const pinRect = pin.getBoundingClientRect()
  const target = event.target as HTMLDivElement
  const pinWidth = target.clientWidth
  const pinHeight = target.clientHeight
  const boundingRect = containerRef.current?.getBoundingClientRect()

  if (boundingRect) {
    const distanceLeft = pinRect.left - boundingRect?.left
    const distanceRight =
      boundingRect?.right - pinRect.right
    const distanceTop = pinRect.top - boundingRect?.top
    const distanceBottom =
      boundingRect?.bottom - pinRect.bottom
    // since tile would be displayed at bottom of pin, need to calculate distance from top of map to bottom of pin
    const distanceTopFromBottomOfPin = distanceTop + pinRect.height
    let position: Position | undefined
    let xOffset = 0
    let yOffset = 0
    const additionalOffsetFromBottom = 16

    // Check if there is enough space on the right
    if (distanceRight - maxWidth >= 0) {
      position = 'right'
    }
    // Check if there is enough space on the left
    else if (distanceLeft - maxWidth >= 0) {
      position = 'left'
    }
    // Check if there is enough space below
    else if (distanceBottom - maxHeight >= 0) {
      position = 'down'
    }
    // Check if there is enough space above
    else if (distanceTop - maxHeight >= 0) {
      position = 'up'
    }
    else {
      position = 'middle'
    }

    if (position === 'left' || position === 'right') {
      if (distanceTopFromBottomOfPin - maxHeight / 2 < 0) {
        // need to offset from top
        yOffset = maxHeight / 2 - distanceTopFromBottomOfPin
      } else if (distanceBottom - maxHeight / 2 < 0) {
        // need to offset from bottom
        yOffset = distanceBottom - maxHeight / 2 - additionalOffsetFromBottom
      }

      xOffset = pinWidth / 2
    }

    if (position === 'up' || position === 'down') {
      if (distanceLeft - maxWidth / 2 < 0) {
        // need to offset from left side
        xOffset = maxWidth / 2 - distanceLeft
      } else if (distanceRight - maxWidth / 2 < 0) {
        // need to offset from right side
        xOffset = distanceRight - maxWidth / 2
      }
      if (position === 'up') {
        yOffset = pinHeight
      }
    }

    if (position === 'middle') {
      if (distanceLeft - maxWidth / 2 < 0) {
        // need to offset from left side
        xOffset = maxWidth / 2 - distanceLeft
      } else if (distanceRight - maxWidth / 2 < 0) {
        // need to offset from right side
        xOffset = distanceRight - maxWidth / 2
      } else if (distanceTopFromBottomOfPin - maxHeight / 2 < 0) {
        // need to offset from top
        yOffset = maxHeight / 2 - distanceTopFromBottomOfPin
      } else if (distanceBottom - maxHeight / 2 < 0) {
        // need to offset from bottom
        yOffset = distanceBottom - maxHeight / 2 - additionalOffsetFromBottom
      }
    }
    const offset = { xOffset, yOffset }

    return {
      position,
      offset,
    }
  }

  return {
    position: undefined,
    offset: undefined,
  }
}

export function areOffersBedbank(
  offers: Array<App.Offer | App.BedbankOffer | App.OfferListMetaData>,
): offers is Array<App.BedbankOffer> {
  return offers.some(offer => offer.type === OFFER_TYPE_BED_BANK)
}
