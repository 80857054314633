import createSelector from 'lib/web/createSelector'
import { getBookingProtectionItemsView } from '../view/bookingProtection'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'

export const getBookingProtectionTotals = createSelector(
  getBookingProtectionItemsView,
  (viewWithStatus): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => itemView.price),
        memberPrice: 0,
        value: sum(definedItems, itemView => (itemView.value ?? 0)),
        surcharge: 0,
        taxesAndFees: 0,
      },
    }
  },
)
