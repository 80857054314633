import generateDepartureRoomPriceBreakdown from 'lib/tours/generateDepartureRoomPriceBreakdown'

export function getTourV2ItemViewPrice(offer: Tours.TourV2Offer | undefined, purchasableOption: Tours.TourV2OfferPurchasableOption, occupancy: App.Occupants) {
  const { totalPrice, totalMemberPrice, totalValuedAtPrice } = generateDepartureRoomPriceBreakdown(
    occupancy,
    purchasableOption,
    purchasableOption.fkRoomTypePricingId ? offer?.roomTypePricing[purchasableOption.fkRoomTypePricingId] : undefined,
    offer?.variations[purchasableOption.fkVariationId],
  )

  return { totalPrice, totalMemberPrice, totalValuedAtPrice }
}
