import request from 'api/requestUtils'

export function getTrendingDestinations(regionCode: string, imageFrom = 'typeahead') {
  return request.get<App.ApiResponse<Array<App.TrendingDestination>>>(`/api/search/place/v1/trending?region=${regionCode}&limit=10&imageFrom=${imageFrom}`)
    .then(res => res.result)
}

export function getHotelTrendingDestinations(regionCode: string, limit: number, sortBy: App.HotelTrendingSortByOptions, exclude: Array<string>, percentile: number) {
  return request.get<App.ApiResponse<Array<App.TrendingDestination>>>(`/api/search/hotel/v1/places/trending?region=${regionCode}&limit=${limit}&exclude=${exclude.join(',')}&sortBy=${sortBy}&percentile=${percentile}`)
    .then(res => res.result)
}

export function getHotelPopularHolidayTypes(regionCode: string) {
  return request.get<App.ApiResponse<Array<{ name: string }>>>(`/api/search/hotel/v1/popular/holiday-types?region=${regionCode}&limit=7`)
    .then(res => res.result)
}
