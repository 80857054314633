export const BOOKING_PROTECTION_INTERNATIONAL_EXPERIMENT_REGIONS = ['AU']

export const PARTNER_BRANDS_ENABLED_REGIONS = ['AU']

export const REQUIRE_POSTCODE_REGIONS = ['AU']

export const PARTNERSHIP_LINK_ACCOUNT_REGIONS = ['AU']

export const USER_REVIEWS_PROMO_CODE_REGIONS = ['AU']

export const ULTRA_LUX_CONTRACT_REGIONS = ['AU']

export const TIMELY_ENABLED_REGIONS = ['AU']

export const MERCHANT_FEE_ENABLED_REGIONS = ['AU']

export const USE_MID_VALUE_CONTACT_TYPE_REGIONS = ['AU']

export const PRIORITY_SUPPORT_NUMBER_REGIONS = ['AU']

export const VELOCITY_ENABLED_REGIONS = ['AU']

export const HOMEPAGE_FLIGHT_DEALS_REGIONS = ['AU']

export const LETV_SUPPORT_REGIONS = ['AU']

export const TRANSFERS_USP_REGIONS = ['AU']

export const TOUR_ONLY_REGIONS = ['AU']

export const TOUR_V2_PRIVATE_REQUEST_EXCLUDED_REGIONS = ['IN']

export const NO_24_7_SUPPORT_REGIONS = ['IN']

export const ATOL_REGIONS = ['GB']

export const PRODUCT_REVIEW_REGIONS = ['AU']

export const TWELVE_HOUR_FORMAT_REGIONS = new Set(['IN', 'PH', 'MY', 'SA', 'IE', 'CA', 'US', 'AU', 'NZ'])

export const STRIPE_POSTCODE_REGIONS = ['US']

export const PARTNERSHIP_NON_VIRGIN_REGIONS = ['AU', 'GB', 'US']

export const CRUISE_SEARCH_DEPARTURE_REGIONS: Array<App.CruiseDepartureRegion> = [
  {
    name: 'Australia & New Zealand',
    identifier: 'australia-new-zealand',
    countries: ['Australia', 'New Zealand'],
    countriesCodes: ['AU', 'NZ'],
    placeIds: 'le_9fc3d7152ba9336a670e36d0ed79bc43,le_d3d9446802a44259755d38e6d163e820',
  },
  {
    name: 'Europe',
    identifier: 'europe',
    countries: ['Europe', 'Northern Europe', 'Austria', 'Belgium', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'United Kingdom'],
    countriesCodes: ['AT', 'BE', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB'],
    placeIds: 'le_12767faf1bf8cafc7594430586c558c2',
  },
  {
    name: 'North America',
    identifier: 'north-america',
    countries: ['Hawaii', 'Alaska', 'Canada', 'United States of America', 'Mexico'],
    countriesCodes: ['US', 'CA', 'MX'],
    placeIds: 'le_cf874aad79e14b401a4c86954a596fa5',
  },
  {
    name: 'Latin America & Caribbean',
    identifier: 'latin-america-caribbean',
    countries: ['Caribbean', 'South America', 'Argentina', 'Bahamas', 'Barbados', 'Belize', 'Bermuda', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Dominican Republic', 'Ecuador', 'Guatemala', 'Honduras', 'Jamaica', 'Nicaragua', 'Panama', 'Peru', 'Puerto Rico', 'Trinidad and Tobago', 'Uruguay', 'Venezuela'],
    countriesCodes: ['AR', 'BS', 'BB', 'BZ', 'BM', 'BR', 'CL', 'CO', 'CR', 'CU', 'DO', 'EC', 'GT', 'HN', 'JM', 'NI', 'PA', 'PE', 'PR', 'TT', 'UY', 'VE'],
    placeIds: 'le_ded8e3f6fa8da10375ce4f89e1124893,le_d7bce4d8dbac13945471c343c4973ba3',
  },
  {
    name: 'Asia & the Pacific',
    identifier: 'asia-pacific',
    countries: ['Mediterranean', 'Asia', 'China', 'Hong Kong', 'India', 'Indonesia', 'Japan', 'Malaysia', 'Philippines', 'Singapore', 'South Korea', 'Taiwan', 'Thailand', 'Vietnam'],
    countriesCodes: ['CN', 'HK', 'IN', 'ID', 'JP', 'MY', 'PH', 'SG', 'KR', 'TW', 'TH', 'VN'],
    placeIds: 'le_fdbf7de11c1a054dc927adf66d9b5fce,le_a8697c7e6e7988b29a9de094b8d554cc',
  },
]

export const CAR_HIRE_ENHANCED_PROTECTION_REGIONS = ['US']

export const ASIAN_COUNTRIES = ['SG', 'HK', 'IN', 'MY', 'TH', 'ID', 'PH', 'VN', 'CN', 'JP', 'KR', 'TW', 'MO', 'AE', 'SA', 'IL', 'QA']

export const NAVBAR_SPECIFIC_ORDER_REGIONS_LIST = new Set(['AU', 'NZ', 'GB', 'US', 'SG', 'IN'])

export const REGION_DROPDOWN_START_ORDER = ['AU', 'US', 'GB', 'NZ', 'SG']

export const LAST_MINUTE_REGIONS_LIST = ['AU', 'NZ']

export const CRUISE_AVAILABLE_REGIONS = new Set(['AU', 'NZ'])

export const CONTACT_GENERAL_REGIONS_EXCLUDE_LIST = ['AU', 'US', 'GB', 'NZ']

export const US_AMOUNT_REGIONS_LIST = ['US', 'UK']

export const INSURANCE_VALID_COUNTRIES = ['NZ', 'AU', 'SG', 'MY', 'TH', 'IN', 'HK']

export const AGENT_HUB_DEFAULT_PHONE_NUMBER_PREFIX: Record<App.AgentHubAvailableRegions, string> = {
  AU: '+61',
  CA: '+1',
  CN: '+86',
  FR: '+33',
  DE: '+49',
  IN: '+91',
  ID: '+62',
  IE: '+353',
  IL: '+972',
  IT: '+39',
  JP: '+81',
  KR: '+82',
  MO: '+853',
  MY: '+60',
  NL: '+31',
  NZ: '+64',
  PH: '+63',
  QA: '+974',
  RU: '+7',
  SA: '+966',
  SG: '+65',
  ZA: '+27',
  ES: '+34',
  TW: '+886',
  TH: '+66',
  AE: '+971',
  GB: '+44',
  US: '+1',
  VN: '+84',
}

export const ISO2_START_ORDER = ['au', 'us', 'gb', 'nz', 'sg']

const ALL_DESTINATIONS: Array<App.UserPreferencesDestination> = [
  {
    name: 'Maldives',
    countryCode: 'MV',
    placeId: 'le_2723d092b63885e0d7c260cc007e8b9d',
  },
  {
    name: 'United Arab Emirates',
    countryCode: 'AE',
    placeId: 'le_a2557a7b2e94197ff767970b67041697',
  },
  {
    name: 'Vietnam',
    countryCode: 'VN',
    placeId: 'le_a597e50502f5ff68e3e25b9114205d4a',
  },
  {
    name: 'New Zealand',
    countryCode: 'NZ',
    placeId: 'le_9fc3d7152ba9336a670e36d0ed79bc43',
  },
  {
    name: 'USA',
    countryCode: 'US',
    placeId: 'le_757b505cfd34c64c85ca5b5690ee5293',
  },
  {
    name: 'Thailand',
    countryCode: 'TH',
    placeId: 'le_8f85517967795eeef66c225f7883bdcb',
  },
  {
    name: 'Malaysia',
    countryCode: 'MY',
    placeId: 'le_a3c65c2974270fd093ee8a9bf8ae7d0b',
  },
  {
    name: 'Australia',
    countryCode: 'AU',
    placeId: 'le_d3d9446802a44259755d38e6d163e820',
  },
  {
    name: 'Fiji',
    countryCode: 'FJ',
    placeId: 'le_72b32a1f754ba1c09b3695e0cb6cde7f',
  },
  {
    name: 'Singapore',
    countryCode: 'SG',
    placeId: 'le_bd4c9ab730f5513206b999ec0d90d1fb',
  },
  {
    name: 'Cook Islands',
    countryCode: 'CK',
    placeId: 'le_3416a75f4cea9109507cacd8e2f2aefc',
  },
  {
    name: 'Malta',
    countryCode: 'MT',
    placeId: 'le_698d51a19d8a121ce581499d7b701668',
  },
  {
    name: 'Bali',
    countryCode: 'ID',
    placeId: 'le_64930b7151b6bc3e289f37d5da62ac9f',
  },
  {
    name: 'Canada',
    countryCode: 'CA',
    placeId: 'le_c16a5320fa475530d9583c34fd356ef5',
  },
  {
    name: 'Italy',
    countryCode: 'IT',
    placeId: 'le_93db85ed909c13838ff95ccfa94cebd9',
  },
  {
    name: 'United States',
    countryCode: 'US',
    placeId: 'le_757b505cfd34c64c85ca5b5690ee5293',
  },
  {
    name: 'Indonesia',
    countryCode: 'ID',
    placeId: 'le_43ec517d68b6edd3015b3edc9a11367b',
  },
  {
    name: 'United Kingdom',
    countryCode: 'GB',
    placeId: 'le_cfecdb276f634854f3ef915e2e980c31',
  },
  {
    name: 'Spain',
    countryCode: 'ES',
    placeId: 'le_7e7757b1e12abcb736ab9a754ffb617a',
  },
  {
    name: 'Portugal',
    countryCode: 'PT',
    placeId: 'le_8d5e957f297893487bd98fa830fa6413',
  },
  {
    name: 'France',
    countryCode: 'FR',
    placeId: 'le_093f65e080a295f8076b1c5722a46aa2',
  },
  {
    name: 'Mexico',
    countryCode: 'MX',
    placeId: 'le_eb160de1de89d9058fcb0b968dbbbd68',
  },
  {
    name: 'Greece',
    countryCode: 'GR',
    placeId: 'le_735b90b4568125ed6c3f678819b6e058',
  },
  {
    name: 'Netherlands',
    countryCode: 'NL',
    placeId: 'le_d1f491a404d6854880943e5c3cd9ca25',
  },
  {
    name: 'Mauritius',
    countryCode: 'MU',
    placeId: 'le_2b44928ae11fb9384c4cf38708677c48',
  },
  {
    name: 'Iceland',
    countryCode: 'IS',
    placeId: 'le_d1fe173d08e959397adf34b1d77e88d7',
  },
  {
    name: 'Philippines',
    countryCode: 'PH',
    placeId: 'le_2b24d495052a8ce66358eb576b8912c8',
  },
  {
    name: 'Germany',
    countryCode: 'DE',
    placeId: 'le_ea5d2f1c4608232e07d3aa3d998e5135',
  },
  {
    name: 'Japan',
    countryCode: 'JP',
    placeId: 'le_7647966b7343c29048673252e490f736',
  },
  {
    name: 'Hong Kong',
    countryCode: 'HK',
    placeId: 'le_f5b2c7d542d5cc6eecba73128445ef53',
  },
  {
    name: 'Cambodia',
    countryCode: 'KH',
    placeId: 'le_6ea9ab1baa0efb9e19094440c317e21b',
  },
  {
    name: 'Morocco',
    countryCode: 'MA',
    placeId: 'le_a0a080f42e6f13b3a2df133f073095dd',
  },
  {
    name: 'Switzerland',
    countryCode: 'CH',
    placeId: 'le_bf8229696f7a3bb4700cfddef19fa23f',
  },
  {
    name: 'South Africa',
    countryCode: 'ZA',
    placeId: 'le_9766527f2b5d3e95d4a733fcfb77bd7e',
  },
  {
    name: 'Jamaica',
    countryCode: 'JM',
    placeId: 'le_2a38a4a9316c49e5a833517c45d31070',
  },
  {
    name: 'Oman',
    countryCode: 'OM',
    placeId: 'le_e00da03b685a0dd18fb6a08af0923de0',
  },
  {
    name: 'The Bahamas',
    countryCode: 'BS',
    placeId: 'le_d41d8cd98f00b204e9800998ecf8427e',
  },
  {
    name: 'Saint Lucia',
    countryCode: 'LC',
    placeId: 'le_d41d8cd98f00b204e9800998ecf8427e',
  },
  {
    name: 'Turkey',
    countryCode: 'TR',
    placeId: 'le_cedebb6e872f539bef8c3f919874e9d7',
  },
  {
    name: 'Sri Lanka',
    countryCode: 'LK',
    placeId: 'le_5878a7ab84fb43402106c575658472fa',
  },
  {
    name: 'Dominican Republic',
    countryCode: 'DO',
    placeId: 'le_c0c7c76d30bd3dcaefc96f40275bdc0a',
  },
  {
    name: 'Croatia',
    countryCode: 'HR',
    placeId: 'le_17e62166fc8586dfa4d1bc0e1742c08b',
  },
  {
    name: 'Montenegro',
    countryCode: 'ME',
    placeId: 'le_0cf05e5adbf5f3a64c72fb3d6a24758f',
  },
  {
    name: 'Ireland',
    countryCode: 'IE',
    placeId: 'le_68d30a9594728bc39aa24be94b319d21',
  },
  {
    name: 'Czechia',
    countryCode: 'CZ',
    placeId: 'le_d41d8cd98f00b204e9800998ecf8427e',
  },
  {
    name: 'South Korea',
    countryCode: 'KR',
    placeId: 'le_f4b9ec30ad9f68f89b29639786cb62ef',
  },
  {
    name: 'Bermuda',
    countryCode: 'BM',
    placeId: 'le_98f13708210194c475687be6106a3b84',
  },
  {
    name: 'Vanuatu',
    countryCode: 'VU',
    placeId: 'le_58a2fc6ed39fd083f55d4182bf88826d',
  },
  {
    name: 'Austria',
    countryCode: 'AT',
    placeId: 'le_6512bd43d9caa6e02c990b0a82652dca',
  },
  {
    name: 'Hungary',
    countryCode: 'HU',
    placeId: 'le_35f4a8d465e6e1edc05f3d8ab658c551',
  },
  {
    name: 'Tanzania',
    countryCode: 'TZ',
    placeId: 'le_96da2f590cd7246bbde0051047b0d6f7',
  },
  {
    name: 'India',
    countryCode: 'IN',
    placeId: 'le_f033ab37c30201f73f142449d037028d',
  },
  {
    name: 'Anguilla',
    countryCode: 'AI',
    placeId: 'le_1679091c5a880faf6fb5e6087eb1b2dc',
  },
  {
    name: 'China',
    countryCode: 'CN',
    placeId: 'le_a5bfc9e07964f8dddeb95fc584cd965d',
  },
  {
    name: 'Colombia',
    countryCode: 'CO',
    placeId: 'le_a5771bce93e200c36f7cd9dfd0e5deaa',
  },
  {
    name: 'Botswana',
    countryCode: 'BW',
    placeId: 'le_b6d767d2f8ed5d21a44b0e5886680cb9',
  },
  {
    name: 'Zimbabwe',
    countryCode: 'ZW',
    placeId: 'le_3644a684f98ea8fe223c713b77189a77',
  },
  {
    name: 'Brazil',
    countryCode: 'BR',
    placeId: 'le_37693cfc748049e45d87b8c7d8b9aacd',
  },
  {
    name: 'Poland',
    countryCode: 'PL',
    placeId: 'le_a5e00132373a7031000fd987a3c9f87b',
  },
  {
    name: 'Israel',
    countryCode: 'IL',
    placeId: 'le_3ef815416f775098fe977004015c6193',
  },
  {
    name: 'Costa Rica',
    countryCode: 'CR',
    placeId: 'le_a1d0c6e83f027327d8461063f4ac58a6',
  },
  {
    name: 'Antigua and Barbuda',
    countryCode: 'AG',
    placeId: 'le_8f14e45fceea167a5a36dedd4bea2543',
  },
  {
    name: 'Saudi Arabia',
    countryCode: 'SA',
    placeId: 'le_6c4b761a28b734fe93831e3fb400ce87',
  },
  {
    name: 'Seychelles',
    countryCode: 'SC',
    placeId: 'le_140f6969d5213fd0ece03148e62e461e',
  },
  {
    name: 'Samoa',
    countryCode: 'WS',
    placeId: 'le_2a79ea27c279e471f4d180b08d62b00a',
  },
  {
    name: 'Peru',
    countryCode: 'PE',
    placeId: 'le_0a09c8844ba8f0936c20bd791130d6b6',
  },
  {
    name: 'Laos',
    countryCode: 'LA',
    placeId: 'le_26657d5ff9020d2abefe558796b99584',
  },
  {
    name: 'Macao',
    countryCode: 'MO',
    placeId: 'le_cdfd412b1f1d8b3087ae31b4dd5c2173',
  },
  {
    name: 'Qatar',
    countryCode: 'QA',
    placeId: 'le_f2217062e9a397a1dca429e7d70bc6ca',
  },
  {
    name: 'Rwanda',
    countryCode: 'RW',
    placeId: 'le_1d7f7abc18fcb43975065399b0d1e48e',
  },
  {
    name: 'Sweden',
    countryCode: 'SE',
    placeId: 'le_f7e6c85504ce6e82442c770f7c8606f0',
  },
]

export function searchPlacesFromFixedList(searchPhrase: string): Array<App.UserPreferencesDestination> {
  const results: Array<App.UserPreferencesDestination> = []
  for (const destination of ALL_DESTINATIONS) {
    if ((destination.name.toLowerCase()).startsWith(searchPhrase.toLowerCase())) {
      results.push(destination)
    }
  }
  return results
}
