import React, { useMemo, useEffect, useCallback, useContext, useState } from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import { rem } from 'polished'
import styled from 'styled-components'
import { getPlural } from 'lib/string/pluralize'
import Pane from 'components/Common/Pane'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'

import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { buildSearchParamsKey } from 'lib/search/searchUtils'
import { fetchBestPriceForOffer } from 'actions/OfferActions'
import useImpressionHandler from 'hooks/useImpressionHandler'
import SearchOfferTileCondensed from './SearchOfferTileCondensed'
import SearchFlashOfferExpanded from './SearchFlashOfferExpanded'
import { dateDifference } from 'lib/datetime/dateUtils'
import { isSpoofed } from 'selectors/featuresSelectors'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { OfferUnavailableReason } from 'lib/search/constants'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import { logNewRelicError } from 'services/newRelic'
import useOffer from 'hooks/Offers/useOffer'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled(Pane)`
  position: relative;
`

const OfferLoadingBox = styled(LoadingBox)`
  height: ${rem(480)};
  width: 100%;
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  offerMetaData?: App.OfferListMetaData;
  bestPrices?: {
    [key: string]: App.OfferMaybeAvailableRate;
  };
  pricesErrors?: { [key: string]: any };
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  isSpoofed?: boolean;
  currentRegion?: string;
  onImageChange?: (idx: number, image?: App.Image) => void
}

function SearchFlashOffer(props: Props) {
  const {
    bestPrices,
    pricesErrors,
    isSpoofed,
    filters,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    currentRegion,
    onImageChange,
  } = props
  const [imageLoaded, setImageLoaded] = useState(false)
  const offerId = props.offer.id
  const metaData = useOfferMetaData(offerId, filters)

  const dispatch = useAppDispatch()
  const impressionRef = useImpressionHandler(offerId)

  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])

  const hasDates = !!(checkIn && checkOut && filters?.rooms)
  const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))
  const bestPrice = bestPrices[searchKey]
  const bestPriceError = pricesErrors[searchKey]
  const available = bestPrice?.available
  const bestPriceRate = available ? bestPrice.rate : undefined
  const fetchingPrice = hasDates && !bestPrice && !bestPriceError
  const soldOut = hasDates && bestPrice && !available
  const dateUnavailable = metaData?.unavailableReason === OfferUnavailableReason.DATE_UNAVAILABLE
  const capacityExceeded = metaData?.unavailableReason === OfferUnavailableReason.CAPACITY_EXCEEDED

  const [offer = props.offer, fetchingOffer] = useOffer<App.Offer>(offerId, {
    requireSummaryOnly: !hasDates,
  })

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  const [showSpoofHighlight, spoofMessage] = useMemo(() => {
    let show = false
    if (offer.visibilitySchedules && isSpoofed) {
      Object.entries(offer.visibilitySchedules).forEach(([schedule, value]) => {
        if (!scheduleIsCurrent(value)) {
          if (schedule === 'AU' && currentRegion === 'AU') {
            show = true
          } else if (schedule !== 'AU' && currentRegion !== 'AU') {
            show = true
          }
        }
      })
    }
    return [show, 'This hotel is only visible to staff and available for purchase.']
  }, [currentRegion, isSpoofed, offer.visibilitySchedules])

  const showHighlight = (filters?.propertyId && !filters?.searchNearby) || showSpoofHighlight
  let highlightMessage: string
  let tileType: 'loading' | 'expanded' | 'condensed'
  const nights = useMemo(() => {
    if (checkIn && checkOut) {
      return dateDifference(new Date(checkOut), new Date(checkIn)).days
    }
    return null
  }, [checkIn, checkOut])

  if (capacityExceeded) {
    tileType = 'condensed'
    highlightMessage = `The number of guests exceeds the ${offer.saleUnit}'s maximum capacity.`
  } else if (dateUnavailable) {
    tileType = 'condensed'
    highlightMessage = `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
  } else if (hasDates) {
    if (fetchingPrice) {
      tileType = 'loading'
    } else if (available || bestPriceError) {
      tileType = 'expanded'
      highlightMessage = 'We found a limited time deal for your hotel. Book now before it ends!'
    } else if (!offer.isSoldOut && offer.minDuration && nights && offer.minDuration > nights) {
      tileType = 'condensed'
      highlightMessage = `Stay for at least ${offer.minDuration} nights to unlock a limited time deal on this hotel.`
    } else {
      tileType = 'condensed'
      highlightMessage = `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
    }
  } else {
    tileType = 'expanded'
    highlightMessage = 'We found a limited time deal for your hotel. Choose dates and book now before it ends!'
  }
  if (showSpoofHighlight) {
    highlightMessage = spoofMessage
    tileType = 'expanded'
  }

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingPrice))) {
      onEvent(OfferListEvents.offerReady, {
        available: tileType === 'expanded',
      })
    }
  }, [fetchingPrice, hasDates, imageLoaded, onEvent, tileType])

  const bestPricePackage = useMemo(() => {
    if (!hasDates || bestPriceError) {
      return offer.lowestPricePackage
    }
    if (bestPriceRate && !fetchingOffer) {
      const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestPriceRate.packageUniqueKey)
      if (!bestPricePkg) {
        logNewRelicError('Missing best price package from offer', {
          offerId: offer.id,
          packages: offer.packages.map(pkg => pkg.uniqueKey),
          bestPriceRate,
        })
      }
      return bestPricePkg ?? offer.lowestPricePackage
    }
  }, [offer, bestPriceRate, hasDates, bestPriceError, fetchingOffer])

  useOfferSoldOutPushDown(offer.id, filters, !fetchingPrice && soldOut)

  useEffect(() => {
    if (hasDates && !occupanciesInvalid) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: filters.rooms,
      }))
    }
  }, [checkIn, checkOut, dispatch, filters, hasDates, occupanciesInvalid, offer])

  return <Root type="clean" ref={impressionRef}>
    {showHighlight && <HighlightMessage info={showSpoofHighlight} message={highlightMessage} />}
    {tileType === 'loading' && <OfferLoadingBox />}
    {tileType === 'expanded' && <SearchFlashOfferExpanded
      offer={offer}
      filters={filters}
      offerMetaData={offerMetaData}
      bestPriceForDates={bestPriceRate}
      fetchingPriceForDates={fetchingPrice}
      soldOut={soldOut}
      bestPricePackage={bestPricePackage}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
      offerLinkIncludesFilters={offerLinkIncludesFilters}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
    {tileType === 'condensed' && <SearchOfferTileCondensed
      offer={offer}
      unavailableReason={metaData?.unavailableReason}
      filters={filters}
      eagerLoadFirstImage={eagerLoadFirstImage}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
  </Root>
}

SearchFlashOffer.defaultProps = {
  filters: {},
  bestPrices: {},
  pricesErrors: {},
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  const offerId = ownProps.offer?.id ?? ''
  return {
    bestPrices: state.offer.offerBestPrices[offerId],
    pricesErrors: state.offer.offerPricesErrors[offerId],
    isSpoofed: isSpoofed(state),
    currentRegion: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(SearchFlashOffer)
