import { isOrderItem, isTourV2OrderItem } from 'lib/order/orderUtils'

/**
 *
 * Util to calculate the member pricing metadata for an order item
 *
 * Return member price if available, otherwise return non-member price as `memberPrice`
 *
 * @param orderItem Order items that support member pricing
 * @returns
 */
const getOrderDataMemberPricingMetadata = (orderItem: App.OrderItem | Tours.TourV2OrderItem) => {
  const { memberPrice } = orderItem
  let nonMemberPrice: number | undefined
  if (isTourV2OrderItem(orderItem)) {
    nonMemberPrice = orderItem.publicPrice
  } else if (isOrderItem(orderItem)) {
    nonMemberPrice = orderItem.package.price
  }
  const memberPriceOrPrice = memberPrice && memberPrice > 0 ? memberPrice : nonMemberPrice
  return memberPriceOrPrice && memberPriceOrPrice > 0 ? { memberPrice: memberPriceOrPrice, nonMemberPrice } : undefined
}

export default getOrderDataMemberPricingMetadata
