import responsiveHelper, { Matches } from 'components/Common/Breakpoint/responsiveHelper'
import { breakpointEntries } from 'components/utils/breakpoint'
import { skip, take } from 'lib/array/arrayUtils'
import { useEffect, useMemo, useState } from 'react'

function getMatchedBreakpoint(matches: Matches): App.ScreenSize {
  const [bp] = (Object.entries(matches) as Array<[App.ScreenSize, boolean]>).find(([, v]) => v) ?? []

  return bp!
}

function useScreenSize(): App.ScreenSize {
  const [match, setMatch] = useState<App.ScreenSize>(() => getMatchedBreakpoint(responsiveHelper.nextMatch))

  useEffect(() => {
    const matchSetter = (matches: Matches) => {
      setMatch(getMatchedBreakpoint(matches))
    }
    responsiveHelper.registerListener(matchSetter)

    return () => responsiveHelper.removeListener(matchSetter)
  }, [])

  return match
}

/**
 * Matches the given breakpoint against the current
 */
export function useScreenSizeOnly(breakpoint: App.ScreenSize): boolean {
  const currentMediaQuery = useScreenSize()

  return breakpoint === currentMediaQuery
}

const breakpoints = breakpointEntries.map(([bp]) => bp)

/**
 * Inclusively matches the given breakpoint and anything higher against the current
 */
export function useScreenSizeUp(breakpoint: Exclude<App.ScreenSize, 'mobile'>): boolean {
  const currentMediaQuery = useScreenSize()
  const targets = useMemo(() => {
    const fromIndex = breakpoints.indexOf(breakpoint)

    return new Set(skip(breakpoints, fromIndex))
  }, [breakpoint])

  return targets.has(currentMediaQuery)
}

/**
 * Inclusively matches between the given breakpoints against the current
 */
export function useScreenSizeBetween(
  /** must be smaller than the max */
  minBreakpoint: Exclude<App.ScreenSize, 'largeDesktop'>,
  /** must be bigger than the min */
  maxBreakpoint: Exclude<App.ScreenSize, 'mobile'>,
): boolean {
  const currentMediaQuery = useScreenSize()
  const targets = useMemo(() => {
    const fromIndex = breakpoints.indexOf(minBreakpoint)
    const toIndex = breakpoints.indexOf(maxBreakpoint)

    if (process.env.NODE_ENV !== 'production' && fromIndex > toIndex) {
      throw new Error(`useScreenSizeBetween: Min breakpoint cannot be bigger than the max! (min: ${minBreakpoint}; max: ${maxBreakpoint})`)
    }

    return new Set(take(breakpoints, toIndex + 1, fromIndex))
  }, [minBreakpoint, maxBreakpoint])

  return targets.has(currentMediaQuery)
}

export default useScreenSize
