import PriceRowFlightPackage from 'components/Luxkit/PricePoints/PriceRowFlightPackage'
import React from 'react'
import { connect } from 'react-redux'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import { useLowestFlightPrice } from 'tripPlanner/hooks/api'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  currency: string;
  region: string;
  totalPrice: number;
  hotelNights: number;
  defaultAirportCode: string;
}

export function BedbankTileFlightPrice(props: Props) {
  const {
    offer,
    filters,
    hotelNights,
    totalPrice,
    currency,
    region,
    defaultAirportCode,
  } = props

  const { data: flightPrice } = useLowestFlightPrice({
    originAirportCode: defaultAirportCode,
    destinationAirportCode: offer.flights?.airportCode || '',
    currencyCode: currency,
    regionCode: region,
    nights: hotelNights,
    numberOfAdults: 2,
    shouldQuery: true,
    checkIn: filters?.checkIn,
    checkOut: filters?.checkOut,
  })

  return <PriceRowFlightPackage
    product="hotel"
    price={flightPrice ? (flightPrice + totalPrice) : undefined}
  />
}

const mapStateToProps = (state: App.State) => ({
  currency: state.geo.currentCurrency,
  region: state.geo.currentRegionCode,
  defaultAirportCode: getDefaultAirportCode(state),
})

export default connect(mapStateToProps)(BedbankTileFlightPrice)
