import { GENESYS_SEARCH_EVENT } from 'actions/actionConstants'
import { fireGenesysCustomEvent, initialiseSearchSessionId } from 'actions/AnalyticsActions'
import { SearchResult, trackSearchSession } from 'api/interactionStudio'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import usePrevious from 'hooks/usePrevious'
import { useContext, useEffect } from 'react'
import { isLoggedIn } from 'selectors/accountSelectors'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import GeoContext from 'contexts/geoContext'

export function useGlobalSearchResultsTracking(
  globalSearchState: GlobalSearchState,
  offerListKey: string,
  trackableSearchResults: Array<SearchResult>,
  areResultsFetching: boolean,
  numberOfResultIgnoreAvailability?: number,
) {
  const {
    searchItem,
    searchVerticals,
    occupancies,
    checkinDate,
    checkoutDate,
    flexibleMonths,
    flexibleNights,
    isFlexibleDateSelected,
    searchTargetLabel,
  } = globalSearchState

  const dispatch = useAppDispatch()
  const searchSessionId = useAppSelector(state => state.analytics.search.sessions[offerListKey])

  useEffect(() => {
    if (offerListKey) {
      dispatch(initialiseSearchSessionId(offerListKey))
    }
  }, [dispatch, offerListKey])

  const userIsLoggedIn = useAppSelector(isLoggedIn)
  const { currentRegionCode: regionCode } = useContext(GeoContext)

  const wasFetching = usePrevious(areResultsFetching)
  const justFinishedFetching = !areResultsFetching && wasFetching

  useEffect(() => {
    if (justFinishedFetching && searchSessionId) {
      dispatch(fireGenesysCustomEvent({
        type: GENESYS_SEARCH_EVENT,
        data: {
          searchItem: (searchItem || {}),
          occupancies: (occupancies || [{ adults: 2 }]),
          trackableSearchResults: trackableSearchResults || [],
          checkinDate: checkinDate || 'none',
          checkoutDate: checkoutDate || 'none',
          searchTargetLabel: searchTargetLabel || 'none',
          flexibleMonths: flexibleMonths || 'none',
          flexibleNights: flexibleNights || 'none',
        },
      }))

      trackSearchSession({
        isLoggedIn: userIsLoggedIn,
        regionCode,
        listId: searchSessionId,
        listKey: offerListKey,
        searchState: {
          searchVerticals,
          searchItem,
          occupancies,
          checkinDate,
          checkoutDate,
          flexibleMonths,
          flexibleNights,
          isFlexibleDateSelected,
        },
        searchResults: trackableSearchResults,
        numberOfResultIgnoreAvailability,
      })
    }
  }, [
    offerListKey,
    checkinDate,
    checkoutDate,
    flexibleMonths,
    flexibleNights,
    justFinishedFetching,
    occupancies,
    regionCode,
    searchItem,
    searchSessionId,
    searchVerticals,
    trackableSearchResults,
    userIsLoggedIn,
    isFlexibleDateSelected,
    numberOfResultIgnoreAvailability,
    searchTargetLabel,
    dispatch,
  ])
}
