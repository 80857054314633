import React, { useContext, useMemo } from 'react'
import moment from 'moment'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import NumberRating from 'components/Common/NumberRating'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import SearchTileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileCancellationPolicy'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Group from 'components/utils/Group'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import OfferTileLocationSection from './OfferTileLocationSection'
import OfferTilePropertyHeading from './OfferTilePropertyHeading'
import TileInclusions from '../../Support/OfferTileInclusions'
import { connect } from 'react-redux'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { encodeOfferIds } from 'lib/search/searchUtils'
import { queryKeySelectedOfferIds } from 'constants/url'
import { useOfferInclusions } from 'hooks/useOfferInclusions'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { useTacticalUpsellBanner } from 'hooks/useTacticalUpsellBanner'
import LuxPlusInclusions from 'luxPlus/components/LuxPlusInclusions'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import { isActiveLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import styled from 'styled-components'
import OfferTilePricingUrgencyTags from '../../Support/OfferTilePricingUrgencyTags'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import config from 'constants/config'
import { mapInclusionsToTileInclusions } from 'lib/offer/inclusionUtils'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import useOfferList from 'hooks/Offers/useOfferList'

const MaxMobileGroup = styled(Group)`
  ${mediaQueryUp.tablet} {
    display: none;
  }
`

interface MappedStateProps {
  windowSearch: string;
  currentMemberLoyaltyTier?: App.MembershipSubscriptionTier;
  isActiveLuxPlusMember: boolean;
}

interface Props {
  location: string;
  cancellationPolicyType: string;
  offer: App.OfferSummary;
  checkInDate: moment.Moment | undefined;
  filters: App.OfferListFilters | undefined;
  offerUrl: string;
  bestPricePackage?: App.Package;
  showCompact?: boolean;
  maxInclusions?: number;
  maxInclusionLines?: number;
  bestPriceForDates?: App.OfferAvailableRate;
  showInclusionUpsell?: boolean;
}

function SearchTilePropertyDetails(props: Props & MappedStateProps) {
  const {
    location,
    cancellationPolicyType,
    offer,
    checkInDate,
    windowSearch,
    offerUrl,
    filters,
    bestPricePackage,
    showCompact = false,
    maxInclusions,
    maxInclusionLines,
    isActiveLuxPlusMember,
    bestPriceForDates,
    showInclusionUpsell = false,
  } = props

  const {
    property,
    name,
    tileInclusions,
    tileInclusionsHeading,
    tileDescription,
    type,
    productType,
    id,
  } = offer

  const { searchTargetLabel, offerDistanceFromSearchTarget, searchType } =
    useContext(GlobalSearchStateContext)

  const hotelOfferList = useOfferList(filters, { noFetch: true })
  const searchTargetDistance = offerDistanceFromSearchTarget?.[id]
  const showSearchDistance =
    searchType === 'landmark' &&
    !!searchTargetLabel &&
    typeof searchTargetDistance === 'number'

  let inclusionsHeading: string

  const offerTileInclusions = useMemo(() => {
    if (tileInclusions?.length) {
      return tileInclusions
    }
    const pkgWithInclusions = offer.packages.find((p) => p.inclusions?.length)

    return pkgWithInclusions?.inclusions?.filter((inclusion) => inclusion.displayContext === 'Rate')
  }, [offer.packages, tileInclusions])

  const isMobile = useIsMobileScreen()

  if (type === OFFER_TYPE_LAST_MINUTE) {
    inclusionsHeading = tileInclusionsHeading ?? 'Exclusive extras:'
  } else if (!tileInclusions?.length && offerTileInclusions?.length) {
    inclusionsHeading = `${config.title} inclusions: (select packages only):`
  } else {
    inclusionsHeading = tileInclusionsHeading ?? 'Handpicked inclusions:'
  }

  const shouldShowLPCTacticalUpsell = useTacticalUpsellBanner(offer, bestPricePackage, filters)
  const showLPCTacticalLabel = offer?.hasTactical && bestPricePackage?.hasTactical && !shouldShowLPCTacticalUpsell
  if (showLPCTacticalLabel) {
    inclusionsHeading = 'Your handpicked inclusions:'
  }

  const isLTS = offer.type === OFFER_TYPE_HOTEL && offer.isPartnerProperty

  const checkIn = filters?.checkIn ? moment(filters.checkIn) : undefined
  const checkOut = filters?.checkOut ? moment(filters.checkOut) : undefined
  const { inclusions: commonInclusions, bonusInclusions, loyaltyInclusions } = useOfferInclusions(bestPricePackage, { checkIn, checkOut })

  const hasLoyaltyInclusions = !!offer.luxPlus.hasMemberInclusions

  const allBonusInclusions = useMemo(() => mapInclusionsToTileInclusions([...commonInclusions, ...bonusInclusions]), [bonusInclusions, commonInclusions])
  const showBonusInclusions = showLPCTacticalLabel && allBonusInclusions.length > 0

  const shouldShowInclusions =
  type === OFFER_TYPE_HOTEL ||
    (!!offerTileInclusions &&
    offerTileInclusions?.length &&
    !showInclusionUpsell)

  const showPropertyDescription = !filters?.searchNearby && searchType === 'property' && (hotelOfferList?.offerCount ?? 0) > 1
  const hotelDescription =
    tileInclusions?.length === undefined ||
    tileInclusions?.length < 3 || showPropertyDescription ?
      name :
      undefined

  const mapSearchUrl = `/search/map?${setSearchParamValue(
    windowSearch,
    queryKeySelectedOfferIds,
    encodeOfferIds([offer]),
  )}`

  let inclusionsCount = 5

  if (maxInclusions) {
    inclusionsCount = maxInclusions
  }

  const { totalPrice, totalMemberPrice, showMemberPrice, memberInclusionsAmount } = useSearchPrices({
    offer,
    pkg: bestPricePackage,
    rate: bestPriceForDates,
    filters,
  })

  const saveAmount = totalPrice - (totalMemberPrice || 0)

  return (
    <Group direction="vertical" gap={isActiveLuxPlusMember && hasLoyaltyInclusions ? 16 : 8}>
      <Group direction="vertical" gap={4}>
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="start">
          <Group direction="vertical">
            <Group direction="vertical" fullWidth gap={4}>
              {!showCompact && property && showUserReviewsRating(property.rating, property.reviewsTotal) && (
                <CSSBreakpoint min="desktop">
                  <NumberRating
                    variant="medium"
                    rating={property.rating}
                    total={property.reviewsTotal}
                    ratingSubtitle={property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                  />
                </CSSBreakpoint>
              )}
              <Group direction="horizontal" gap={4} verticalAlign="center" wrap="wrap">
                <OfferLabels
                  offer={offer}
                  saveAmount={showMemberPrice ? saveAmount : undefined}
                  memberInclusionsAmount={memberInclusionsAmount}
                  showMemberInclusionLabels={!isActiveLuxPlusMember}
                />
                <MaxMobileGroup direction="horizontal" gap={4} verticalAlign="center">
                  <OfferTilePricingUrgencyTags offer={offer} filters={filters} />
                </MaxMobileGroup>
              </Group>
              <OfferTileLocationSection
                location={location}
                shouldShowSearchDistance={showSearchDistance}
                mapSearchUrl={mapSearchUrl}
                searchTargetDistance={searchTargetDistance}
                searchTargetLabel={searchTargetLabel}
              />
            </Group>
            <div>
              {property && (
                <OfferTilePropertyHeading
                  name={property.name}
                  showCompact={showCompact}
                />
              )}
            </div>
          </Group>
          {showCompact && property && showUserReviewsRating(property.rating, property.reviewsTotal) && (
            <NumberRating
              variant="xs"
              rating={property.rating}
              total={property.reviewsTotal}
              onlyNumber
            />
          )}
        </Group>
        <Group direction="vertical" gap={8} horizontalAlign="start">
          {showLPCTacticalLabel &&
            <ProductTypeLabel productType="limited_time_special" kind="plain" />
          }
          {(!showLPCTacticalLabel && !offer.isPartnerProperty) &&
            <ProductTypeLabel productType={productType} kind="plain" />
        }
          {isLTS && !showLPCTacticalLabel &&
            <ProductTypeLabel productType="limited_time_special" kind="plain" />
        }
        </Group>
        {!showCompact && property && showUserReviewsRating(property.rating, property.reviewsTotal) && (
          <CSSBreakpoint max="tablet">
            <NumberRating
              inlineLabel
              variant="xs"
              rating={property.rating}
              total={property.reviewsTotal}
              ratingSubtitle={property.reviewsSource === 'google' ? 'From online reviews' : undefined}
            />
          </CSSBreakpoint>
        )}
        {!!hotelDescription && !showCompact && <BodyText variant="medium">{hotelDescription}</BodyText>}
        {!showCompact && <SearchTileCancellationPolicy
          cancellationPolicyType={cancellationPolicyType}
          checkInDate={checkInDate}
          timezoneOffset={offer?.property?.timezoneOffset}
          offerType={offer.type}
        />}
      </Group>
      <Group direction="vertical" gap={isActiveLuxPlusMember && hasLoyaltyInclusions ? 16 : 8}>
        {hasLoyaltyInclusions && <LuxPlusInclusions
          loyaltyInclusions={loyaltyInclusions}
          accommodationOffer={offer}
          memberInclusionsAmount={memberInclusionsAmount}
          mode={!isActiveLuxPlusMember ? 'disabled' : undefined}
        />}
        {!showBonusInclusions && (offerTileInclusions?.length || tileDescription) &&
          shouldShowInclusions && (
            <TileInclusions
              offerTitle={offer.property?.name ? offer.property.name : offer.name}
              offerURL={offerUrl}
              content={tileDescription}
              tileInclusionsList={offerTileInclusions || []}
              heading={showCompact || isMobile ? '' : inclusionsHeading}
              maxInclusionsCount={inclusionsCount}
              maxLineCount={maxInclusionLines}
              filters={filters}
            />
        )}
        {showBonusInclusions && (
          <TileInclusions
            offerTitle={offer.property?.name ? offer.property.name : offer.name}
            offerURL={offerUrl}
            tileInclusionsList={allBonusInclusions}
            heading={showCompact || isMobile ? '' : inclusionsHeading}
            maxInclusionsCount={inclusionsCount}
            maxLineCount={maxInclusionLines}
          />
        )}
      </Group>
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>(
  (state): MappedStateProps => ({
    windowSearch: state.router.location.search,
    isActiveLuxPlusMember: isActiveLuxPlusMember(state),
  }),
)(SearchTilePropertyDetails)
