import request from 'api/requestUtils'

export interface ExpressionOfInterestData {
  agencyName: string;
  affiliation: App.AgentHubAgencyAffiliations | string;
  name: string;
  phone: string;
  email: string;
  streetAddress?: string;
  cityOrSuburb?: string;
  stateOrTerritory?: string;
  postcode?: string;
  country?: string;
  isAgencyAdmin?: boolean;
}

async function requestPostExpressionOfInterest(agencyData: ExpressionOfInterestData) {
  return request.post(
    '/api/agent/eoi',
    agencyData,
  )
}

export default requestPostExpressionOfInterest
