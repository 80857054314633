import { ADMIN_ROLE, EMPLOYEE_ROLE } from 'constants/auth'
import config from 'constants/config'
import createSelector from 'lib/web/createSelector'
import showGeoRdirect from 'lib/geo/geoRedirect'
import { SvgIconColour } from 'components/Luxkit/SvgIcon'

export const shouldShowGeoRedirect = createSelector(
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.config.headlessMode,
  (accountRegion, currentRegion, headlessMode) => {
    return !headlessMode &&
      !config.agentHub.isEnabled &&
      config.currencyRedirectEnabled &&
      accountRegion &&
      currentRegion !== accountRegion &&
      !!config.regions.find(region => region.code === accountRegion) &&
      showGeoRdirect()
  },
)

// Suspect this is faster without reselect
// We can convert this to reselect if this ever gets more complex
export const getCurrentUserId = (state: App.State) => state.auth.account.memberId

export const isLoggedIn = (state: App.State) => !!getCurrentUserId(state)

export const isRestrictedChannel = (state: App.State) =>
  state.utm.content === 'gha-lpp' ||
  (state.utm.medium === 'organic' && state.router.location.pathname.includes('/partner/')) ||
  (state.utm.source === 'facebook' && state.utm.medium === 'cpc' && state.router.location.pathname.includes('/property/'))

export const getUserPreferences = createSelector(
  (state: App.State) => state.recommendations.userPreferences.preferences,
  (state: App.State) => state.recommendations.userPreferences.state,
  (preferences, loadingState) => {
    return { preferences, loadingState }
  },
)

export const hasUserPreferences = createSelector(
  getUserPreferences,
  ({ preferences, loadingState }) => {
    if (loadingState === 'loading') {
      return true
    }
    if (!preferences) {
      return false
    }
    return Object.values(preferences).some((value) => value !== undefined && value.length > 0)
  },
)

export const hasUserPreferencesNotification = createSelector(
  hasUserPreferences,
  isLoggedIn,
  (hasPreferences, isLoggedIn) => !hasPreferences && isLoggedIn && config.USER_PREFERENCES_ENABLED,
)

export const hasPassedWalledGarden = createSelector(
  isLoggedIn,
  isRestrictedChannel,
  (state: App.State) => state.utm.bp,
  (state: App.State) => state.config.previewMode,
  (isLoggedIn, isRestrictedChannel, bypassMode, previewMode) => isLoggedIn || previewMode || (bypassMode === '2' && !isRestrictedChannel),
)

export const hasPassedHidePricing = createSelector(
  isLoggedIn,
  (state: App.State) => state.config.previewMode,
  (isLoggedIn, previewMode) => isLoggedIn || previewMode,
)

export const getRecommendationUserId = createSelector(
  getCurrentUserId,
  (state: App.State) => state.recommendations.userId,
  (currentUserId, recommendationUserId) => currentUserId || recommendationUserId,
)

export const hasIncompleteInformation = createSelector(
  isLoggedIn,
  (state: App.State) => !config.ENABLE_EMAIL_OPT_IN || !!state.auth.account.emailVerified,
  (state: App.State) => !!state.auth.account.givenName && !!state.auth.account.surname,
  (isLoggedIn, emailVerified, nameCompleted) => isLoggedIn && !(emailVerified && nameCompleted),
)

export const hasAccountNotification = createSelector(
  hasIncompleteInformation,
  hasUserPreferencesNotification,
  isLoggedIn,
  (hasIncompleteInformation, hasUserPreferencesNotification, isLoggedIn) => (hasIncompleteInformation || hasUserPreferencesNotification) && isLoggedIn,
)

export const getNotificationType = createSelector(
  hasIncompleteInformation,
  hasUserPreferencesNotification,
  (hasIncompleteInformation, hasUserPreferencesNotification) => hasIncompleteInformation ? 'warning' : hasUserPreferencesNotification ? 'highlight-primary-normal' : 'warning' as SvgIconColour,
)

export const getCustomerDetails = createSelector(
  (state: App.State) => state.auth.addressDetails,
  (state: App.State) => state.auth.account.givenName,
  (state: App.State) => state.auth.account.surname,
  (state: App.State) => state.auth.account.email,
  (state: App.State) => state.auth.account.phone,
  (state: App.State) => state.auth.account.phonePrefix,
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.auth.gender || undefined,
  (state: App.State) => state.auth.title || undefined,
  (state: App.State) => state.auth.middleName || undefined,
  (addressDetails = {}, givenName, surname, email, phone, phonePrefix, countryCode, gender, title, middleName) => {
    return {
      addressDetails,
      givenName,
      surname,
      email,
      phone,
      phonePrefix,
      countryCode,
      gender,
      title,
      middleName,
    }
  },
)

export const isLEStaff = (state: App.State) => state.auth.account.roles.includes(EMPLOYEE_ROLE)
export const isLEAdmin = (state: App.State) => state.auth.account.roles.includes(ADMIN_ROLE)
export const isLEStaffOrAdmin = (state) => isLEAdmin(state) || isLEStaff(state)

export const getFirstName = createSelector(
  isLoggedIn,
  (state: App.State) => state.auth.account.givenName,
  (isLoggedIn, givenName) => isLoggedIn ? givenName : '',
)

export const isMyProfileComplete = createSelector(
  (state: App.State) => state.auth.account.givenName,
  (state: App.State) => state.auth.account.surname,
  (state: App.State) => state.auth.account.email,
  (state: App.State) => state.auth.account.phone,
  (state: App.State) => state.auth.account.postcode,
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.auth.account.dob,
  (
    givenName,
    surname,
    email,
    phone,
    postcode,
    countryCode,
    dob,
  ) => {
    return !!(givenName && surname && email && phone && postcode && countryCode && dob)
  },
)
