import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

const Container = styled.div`
  display: grid;
  grid-template-columns: [border-start] var(--gutter) [content-start] 1fr [content-end] var(--gutter) [border-end];
  grid-template-rows: [label-start] auto [border-start] auto [label-end] var(--top-gutter) [content-start] 1fr [content-end] var(--gutter) [border-end];

  &.size-small {
    --gutter: ${rem(8)};
    --top-gutter: ${rem(4)};
  }

  &.size-medium {
    --gutter: ${rem(16)};
    --top-gutter: ${rem(8)};
  }
`

const LabelContainer = styled.div`
  grid-column: border;
  grid-row: label;

  &.align-start {
    text-align: left;
    text-align: start;
  }

  &.align-center {
    text-align: center;
  }

  &.align-end {
    text-align: right;
    text-align: end;
  }
`

const Border = styled.div`
  grid-column: border;
  grid-row: border;
  border-radius: ${props => props.theme.borderRadius.S};
  border-width: 1px;
  border-style: solid;

  &.colour-highlight-secondary {
    border-color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  } 

  &.colour-loyalty {
    border-color: ${props => props.theme.palette.product.loyalty.background};
  }

  &.shadow-medium {
    box-shadow: ${props => props.theme.shadow.bottom.medium};
  }
`

const Content = styled.div`
  grid-column: content;
  grid-row: content;
`

interface Props {
  children?: React.ReactNode;
  labels: React.ReactNode;
  size?: 'small' | 'medium';
  className?: string;
  colour?: 'highlight-secondary' | 'loyalty';
  align?: 'start' | 'center' | 'end';
  shadow?: 'medium';
  'data-testid'?: string;
}

function LabeledContainer(props: Props) {
  const { children, labels, size = 'medium', className, colour, align, shadow } = props

  return (
    <Container
      data-testid={props['data-testid']}
      className={cn(
        className,
        `size-${size}`,
      )}
    >
      <Border aria-hidden="true" className={cn(
        colour ? `colour-${colour}` : undefined,
        shadow ? `shadow-${shadow}` : undefined,
      )} />
      <LabelContainer className={cn(align ? `align-${align}` : undefined)}>
        {labels}
      </LabelContainer>
      <Content>{children}</Content>
    </Container>
  )
}

export default LabeledContainer
