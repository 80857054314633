import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { last } from 'lib/array/arrayUtils'
import moment from 'moment'

function getCruiseOrderItems(state: App.State, order: App.Order) {
  return order.cruiseItems.map(orderItem => {
    const cruiseOfferId = state?.cruise?.cruiseDeparture?.[orderItem?.departureId]?.offer?.id
    const fullOffer = state.cruise.cruiseOffers[cruiseOfferId]
    const { categoryId, productId } = getCategoryAndProductId('cruise', fullOffer)
    const offerLeadPrice = fullOffer?.lowestDeparturePrice?.total
    const departurePort = fullOffer?.departurePort
    const destinationPort = fullOffer?.returnPort
    const originCity = departurePort ? departurePort.split(', ')[0] : undefined
    const originCountry = departurePort ? last(departurePort.split(', ')) : undefined
    const destinationCity = destinationPort ? destinationPort.split(', ')[0] : undefined
    const destinationCountry = destinationPort ? last(destinationPort.split(', ')) : undefined
    const travelStart = orderItem?.departureDate
    const travelEnd = orderItem?.arrivalDate

    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem?.cruiseOfferId || orderItem.bookingId,
        categoryId,
        productId,
        duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : undefined,
        travelStart,
        travelEnd,
        price: orderItem.total,
        offerLeadPrice,
        offerLeadDuration: fullOffer?.duration ? String(fullOffer?.duration) : undefined,
        offerLeadUnit: fullOffer?.saleUnit,
        currency: order.currencyCode,
        destinationCity,
        destinationCountry,
        originCity,
        originCountry,
        itemId: orderItem.orderId,
        bookingNumber: order.number,
        orderId: order.id,
      },
    )
  },
  )
}

export default getCruiseOrderItems
