import cn from 'clsx'
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import useResizeObserver from '@react-hook/resize-observer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import LineAngleLeftIcon from 'components/Luxkit/Icons/line/LineAngleLeftIcon'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import noop from 'lib/function/noop'
import Carousel, { DEFAULT_CAROUSEL_GAP } from './Carousel'
import debounce from 'debounce-promise'
import { ScrollPosition, getElementContentWidth, getElementPadding, getHorizontalScrollPosition } from './utils'
import { isNumber } from 'lib/maths/mathUtils'
import { useIsTabletAndSmallerScreen } from 'lib/web/deviceUtils'

const CarouselArrow = styled(IconButton)`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 0;
  z-index: 1;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s;
  box-shadow: ${props => props.theme.shadow.bottom.medium};

  &.right {
    transform: translateY(-50%) translateX(50%);
    left: unset;
    right: 0;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  ${mediaQueryUp.desktop} {
    display: inline-flex;
  }
`

const CarouselContainer = styled.div`
  position: relative;

  &.arrowsOnlyOnHover:not(:hover) {
    ${CarouselArrow} {
      opacity: 0;
      pointer-events: none;
    }
  }
`

const getCarouselReferencePoint: Record<'start' | 'end', (element: HTMLElement) => number> = {
  start: (el) => el.getBoundingClientRect().left + parseFloat(window.getComputedStyle(el).paddingLeft),
  end: (el) => el.getBoundingClientRect().right - parseFloat(window.getComputedStyle(el).paddingRight),
}

const getElementReferencePoint: Record<'start' | 'end', (element: HTMLElement) => number> = {
  start: (el) => el.getBoundingClientRect().left,
  end: (el) => el.getBoundingClientRect().right,
}

function findCurrentElementIndex(elements: Array<HTMLElement>, carousel: HTMLDivElement, snap: 'start' | 'end'): number {
  const referencePoint = getCarouselReferencePoint[snap](carousel)
  const elementReference = getElementReferencePoint[snap]
  const closest = elements.reduce<{ index: number, distance: number }>((closest, element, index) => {
    const distance = Math.abs(elementReference(element) - referencePoint)
    if (distance < closest.distance) {
      return { index, distance }
    }
    return closest
  }, { index: 0, distance: Infinity })
  return closest.index
}

export type ArrowDirection = 'left' | 'right'

interface Props extends Omit<React.ComponentProps<typeof Carousel>, 'pageSize'> {
  onArrowClick?: (e: React.MouseEvent<HTMLButtonElement>, arrow: ArrowDirection) => void;
  /**
   * If `number`: Enforces the number of elements visible at once at desktop screen widths.
   * At lower screen widths it will always use the width prop or intrinsic width of the children.

   * If `'auto'`: Intrinsic element widths will be used, and clicking the forward/backward
   * buttons will figure out how far to scroll based on the size of the visible elements.
   * Currently only works if `snap` is set to `'start'`.
   */
  pageSize?: number | 'auto';
  /** If true, the arrows will only appear while hovering the mouse over the carousel */
  arrowsOnlyOnHover?: boolean;
}

const CardCarousel = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className,
    pageSize,
    onArrowClick = noop,
    onScroll = noop,
    arrowsOnlyOnHover,
    ...carouselProps
  } = props
  const carouselRef = useRef<HTMLDivElement>(null)
  // Where our carousel scroll is. Helps us determine to show arrows among other things
  const [position, setPosition] = useState<ScrollPosition>('start')
  const [showArrows, setShowArrows] = useState(false)

  const requiresArrows = !useIsTabletAndSmallerScreen()
  // @ts-ignore useImperativeHandle has issues with TS, it's guaranteed to be defined.
  useImperativeHandle(ref, () => carouselRef.current, [carouselRef])

  const onCarouselArrowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (carouselRef.current) {
      const direction = e.currentTarget.dataset.direction as ArrowDirection
      const contentWidth = getElementContentWidth(carouselRef.current)
      const gap = carouselProps.gap ?? DEFAULT_CAROUSEL_GAP
      const { snap } = carouselProps
      let nextLeft: number

      if (pageSize === 'auto' && snap === 'start') {
        const itemElements = Array.from<HTMLElement>(carouselRef.current.children as any)
        const carouselRect = carouselRef.current.getBoundingClientRect()
        const carouselPadding = getElementPadding(carouselRef.current)
        const alignReferencePoint = carouselRect.left + carouselPadding.left
        const forwardReferencePoint = carouselRect.right - carouselPadding.right
        const backwardReferencePoint = alignReferencePoint - contentWidth - gap
        const currentElementIndex = findCurrentElementIndex(itemElements, carouselRef.current, snap)

        if (direction === 'right') {
          // Find first element which is beyond the current element and has its right edge beyond the carousel area
          const nextElement = itemElements.find((el, i) => {
            return i > currentElementIndex && el.getBoundingClientRect().right > forwardReferencePoint
          })
          nextLeft = carouselRef.current.scrollLeft + (nextElement ? nextElement.getBoundingClientRect().left - alignReferencePoint : 0)
        } else {
          // Find first element which is far enough left that if we scrolled to it, we'd have a page worth of elements
          // moved into view without skipping any elements
          const nextElement = itemElements.find((el, i) => {
            return el.getBoundingClientRect().left >= backwardReferencePoint || i === currentElementIndex - 1
          })
          nextLeft = nextElement ? carouselRef.current.scrollLeft - (alignReferencePoint - nextElement.getBoundingClientRect().left) : 0
        }
      } else {
        nextLeft = carouselRef.current.scrollLeft + (direction === 'left' ? -contentWidth : contentWidth)
      }

      onArrowClick(e, direction)

      carouselRef.current.scrollTo({
        left: nextLeft,
        behavior: 'smooth',
      })
    }
  }
  const childCount = React.Children.count(children)

  const calculateShowArrows = useCallback(() => {
    if (carouselRef.current && requiresArrows) {
      // make a best/effecient attempt at trying to work out if we need to show arrows at desktop
      // even though we don't have a page size to work out if we have more items than the page
      const isDifferentSize = carouselRef.current.offsetWidth < carouselRef.current.scrollWidth
      // React.children is not always an accurate measurement of how many elements we want to show
      // e.g. it could be a single fragment (count: 1), or have many falsey values
      // to get around this, lets check how many DOM children we have
      const hasMoreChildren = !!pageSize && isNumber(pageSize) && carouselRef.current.children.length > pageSize

      if (!!pageSize && isNumber(pageSize) && !hasMoreChildren) {
        setShowArrows(false)
        return
      }

      setShowArrows(hasMoreChildren || isDifferentSize)
    }
  }, [pageSize, requiresArrows])
  const debouncedCalculateShowArrows = useMemo(() => debounce(calculateShowArrows, 100), [calculateShowArrows])

  useResizeObserver(carouselRef, debouncedCalculateShowArrows)
  useIsomorphicLayoutEffect(calculateShowArrows, [childCount, pageSize, carouselRef.current])

  const onScrollRecalc = useMemo(() => {
    // turn on and off our arrows if the user manually scrolls the carousel
    return debounce(() => {
      if (carouselRef.current) {
        setPosition(getHorizontalScrollPosition(carouselRef.current))
      }
    }, 32)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselRef.current])

  const onCarouselScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    if (requiresArrows) {
      onScrollRecalc()
    }
    onScroll(e)
  }, [requiresArrows, onScroll, onScrollRecalc])

  return <CarouselContainer className={cn(className, { arrowsOnlyOnHover })}>
    {showArrows && <CarouselArrow
      kind="secondary"
      variant="dark"
      shape="circle"
      data-direction="left"
      onClick={onCarouselArrowClick}
      className={cn({ hidden: position === 'start' })}
    >
      <LineAngleLeftIcon />
    </CarouselArrow>}
    <Carousel
      {...carouselProps}
      onScroll={onCarouselScroll}
      pageSize={isNumber(pageSize) ? pageSize : undefined}
      ref={carouselRef}
    >
      {children}
    </Carousel>
    {showArrows && <CarouselArrow
      kind="secondary"
      variant="dark"
      shape="circle"
      data-direction="right"
      className={cn('right', { hidden: position === 'end' })}
      onClick={onCarouselArrowClick}
    >
      <LineAngleRightIcon />
    </CarouselArrow>}
  </CarouselContainer>
})

CardCarousel.displayName = 'CardCarousel'

export default CardCarousel
