import config from 'constants/config'
import { EmptyArray } from '../array/arrayUtils'

export default function getPaymentMethods(currencyCode: string) {
  return config.currencyConfig[currencyCode]?.paymentMethods ?? EmptyArray
}

export function getPaymentMethodsByRegion(regionCode: string) {
  return config.paymentMethodsByRegion[regionCode.toUpperCase()]?.paymentMethods ?? EmptyArray
}
