import { excludeNullOrUndefined } from 'checkout/utils'
import createSelector from 'lib/web/createSelector'
import { checkoutAccommodationOfferView, getAccommodationBreakdownView } from './accommodation'
import { getExperienceBreakdownView, getExperienceItems, getExperienceItemsView, getTransferItemsView, getTransfersBreakdownView } from './experience'
import { cartIncludesFlights, getFlightBreakdownView, getFlightItemsView } from './flights'
import { getInsuranceBreakdownView, getInsuranceItems, getInsuranceItemsView } from './insurance'
import { getCarHireBreakdownView, getCarHireItemsView } from './carHire'
import { getBundleAndSaveBreakdownView } from './bundleAndSave'
import { getGiftCardBreakdownView } from 'checkout/selectors/view/giftCard'
import { getVillaBreakdownView, getVillaViews } from './villa'
import { getCustomOfferBreakdownView } from 'checkout/selectors/view/customOffer'
import { getLuxPlusBreakdownView, getLuxPlusSubscriptionItemView, getSubscriptionJoinItemView } from 'checkout/selectors/view/luxPlusSubscription'
import { getBookingProtectionBreakdownView, getBookingProtectionItemsView } from 'checkout/selectors/view/bookingProtection'
import { getTourExperienceV2BreakdownView, getTourV2ExperienceItemsViews } from 'checkout/selectors/view/toursv2'

export const breakdownView = createSelector(
  getAccommodationBreakdownView,
  getVillaBreakdownView,
  getFlightBreakdownView,
  getExperienceBreakdownView,
  getTransfersBreakdownView,
  getInsuranceBreakdownView,
  getCarHireBreakdownView,
  getBundleAndSaveBreakdownView,
  getGiftCardBreakdownView,
  getCustomOfferBreakdownView,
  getLuxPlusBreakdownView,
  getBookingProtectionBreakdownView,
  getTourExperienceV2BreakdownView,
  (...views): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    const allViews = views.flatMap(view => view.data).filter(excludeNullOrUndefined)
    return {
      hasRequiredData: views.every(o => o.hasRequiredData),
      data: allViews,
    }
  },
)

export type AllCheckoutItemViews = ReturnType<typeof getAllItemViews>

export type AllItemViews = {
  accommodationItemsView: App.WithDataStatus<Array<App.Checkout.AccommodationOfferView>>
  experienceItemsView: App.WithDataStatus<Array<App.Checkout.ExperienceItemView>>
  flightItemsView: App.WithDataStatus<Array<App.Checkout.FlightItemView>>
  insuranceItemsView: App.WithDataStatus<Array<App.Checkout.InsuranceItemView>>
  transferItemsView: App.WithDataStatus<Array<App.Checkout.TransferItem>>
  bookingProtectionItemsView: App.WithDataStatus<Array<App.Checkout.BookingProtectionItemView>>
  villaItemsView: App.WithDataStatus<Array<App.Checkout.VillaItemView>>
  carHireItemsView: App.WithDataStatus<Array<App.Checkout.CarHireItemView>>
  subscriptionItemView: App.WithDataStatus<Array<App.Checkout.SubscriptionJoinItemView>>
  tourV2ExperienceItemsView: App.WithDataStatus<Array<App.Checkout.TourV2ExperienceItemView>>
  luxPlusSubscriptionItemView: App.WithDataStatus<Array<App.Checkout.LuxPlusSubscriptionItemView>>
}

export const getAllItemViews = createSelector(
  checkoutAccommodationOfferView,
  getExperienceItemsView,
  getFlightItemsView,
  getInsuranceItemsView,
  getTransferItemsView,
  getBookingProtectionItemsView,
  getVillaViews,
  getCarHireItemsView,
  getSubscriptionJoinItemView,
  getTourV2ExperienceItemsViews,
  getLuxPlusSubscriptionItemView,
  (...views):App.WithDataStatus<AllItemViews> => {
    return {
      hasRequiredData: views.every(o => o.hasRequiredData),
      data: {
        accommodationItemsView: views[0],
        experienceItemsView: views[1],
        flightItemsView: views[2],
        insuranceItemsView: views[3],
        transferItemsView: views[4],
        bookingProtectionItemsView: views[5],
        villaItemsView: views[6],
        carHireItemsView: views[7],
        subscriptionItemView: views[8],
        tourV2ExperienceItemsView: views[9],
        luxPlusSubscriptionItemView: views[10],
      },
    }
  },
)

export const hasPurchasableItems = createSelector(
  getAllItemViews,
  (allItemViews) => {
    if (!allItemViews.hasRequiredData) {
      return {
        hasRequiredData: false,
        data: false,
      }
    }
    return (
      allItemViews.data.accommodationItemsView.data.length > 0 ||
      allItemViews.data.flightItemsView.data.length > 0 ||
      allItemViews.data.insuranceItemsView.data.length > 0 ||
      allItemViews.data.transferItemsView.data.length > 0 ||
      allItemViews.data.experienceItemsView.data.some(item => (
        !item.isSessionPurchaseLimitReached && item.ticketViews.some(ticket => !ticket.unavailable)
      ))
    )
  },
)

export const isSingleDesignation = createSelector(
  checkoutAccommodationOfferView,
  getExperienceItemsView,
  getFlightItemsView,
  (checkoutAccommodationOfferView, getExperienceItemsView, getFlightItemsView): boolean => {
    const allItemsViews = [...checkoutAccommodationOfferView.data, ...getExperienceItemsView.data, ...getFlightItemsView.data]
    return [...new Set(allItemsViews.map(view => view?.designation).filter(excludeNullOrUndefined))].length === 1
  },
)

export const checkoutHasNonAccommodationItems = createSelector(
  getInsuranceItems,
  cartIncludesFlights,
  getExperienceItems,
  (insuranceItems, includesFlights, experienceItems) => {
    return insuranceItems.length > 0 || includesFlights || experienceItems.length > 0
  },
)

/**
 * Tells us whether or not all the data has been loaded to represent the checkout
 * i.e. is it "ready" and/or "done"
 */
export const isCheckoutReady = createSelector(
  getAllItemViews,
  (state: App.State) => state.checkout.isCartRestored,
  (allViews, restored) => {
    return restored && allViews.hasRequiredData
  },
)
