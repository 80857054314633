/**
 * Javascript % operator is a *remainder* operator
 * This method provides a *modulo* operator where negative numbers
 * go back around to the top of the range
 * @param num1 The input number
 * @param num2 The modulo number
 */
export function mod(num1: number, num2: number): number {
  return ((num1 % num2) + num2) % num2
}

export function adjustForDecimal(num: number):number {
  return Math.round(num * 100) / 100
}

/**
 * Clamps number within the inclusive lower and upper bounds.
 * @param num The number to clamp
 * @param lower The minimum value the number can have
 * @param upper The maximum value the number can have
 * @returns The 'clamped' number
 */
export function clamp(num: number, lower: number, upper: number) {
  return Math.min(Math.max(num, lower), upper)
}

export function isNumber(value: any): value is number {
  return typeof value === 'number' && isFinite(value)
}

export function safeDivideAndCeil(value: number, divisor: number): number;
export function safeDivideAndCeil(value: number | undefined, divisor: number): number | undefined;
export function safeDivideAndCeil(value: number | undefined, divisor: number) {
  if (!value) return
  if (divisor <= 0) return value
  if (value <= 0) return value
  return Math.ceil(value / divisor)
}

export function randomIntegerBetween(
  /** inclusive */
  min: number,
  /** inclusive */
  max: number,
): number {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)

  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled)
}
