import { createReducer } from '@reduxjs/toolkit'

import { PATH_CHANGE } from 'actions/actionConstants'
import {
  setCurrentTripId,
  clearCurrentTripId,
  setCurrentSectionId,
  clearCurrentSectionId,
  resetSelectedDate,
  setSelectedDate,
  setTripItemHasJustBeenAdded,
  toggleSubFilters,
  clearSubFilters,
  setSubFilters,
  setFirstTripItemHasJustBeenAdded,
  clearFirstTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'

export const initialState: App.TripPlannerState = {
  currentTripId: undefined,
  currentSectionId: undefined,
  selectedDate: {},
  tripItemHasJustBeenAdded: false,
  selectedSubFilters: [],
  firstTripItemHasJustBeenAdded: false,
}

const tripPlannerReducer = createReducer<App.TripPlannerState>(
  initialState,
  (builder) => {
    builder.addCase(setCurrentTripId, (state, action) => ({
      ...state,
      currentTripId: action.payload,
    }))
    builder.addCase(clearCurrentTripId, (state) => ({
      ...state,
      currentTripId: undefined,
    }))
    builder.addCase(setCurrentSectionId, (state, action) => ({
      ...state,
      currentSectionId: action.payload,
    }))
    builder.addCase(clearCurrentSectionId, (state) => ({
      ...state,
      currentSectionId: undefined,
    }))
    builder.addCase(setTripItemHasJustBeenAdded, (state) => {
      state.tripItemHasJustBeenAdded = true
    })
    builder.addCase(setFirstTripItemHasJustBeenAdded, (state) => {
      state.firstTripItemHasJustBeenAdded = true
    })
    builder.addCase(clearFirstTripItemHasJustBeenAdded, (state) => {
      state.firstTripItemHasJustBeenAdded = false
    })
    builder.addCase(setSelectedDate, (state, action) => {
      state.selectedDate[action.payload.tripId] = action.payload.date
    })
    builder.addCase(resetSelectedDate, (state, action) => {
      state.selectedDate[action.payload.tripId] = undefined
    })
    builder.addCase(toggleSubFilters, (state, action) => {
      if (state.selectedSubFilters?.length > 0) {
        const includes = state.selectedSubFilters.includes(action.payload)
        state.selectedSubFilters = includes ?
          state.selectedSubFilters.filter((value) => value != action.payload) :
            [...state.selectedSubFilters, action.payload]
      } else {
        state.selectedSubFilters = [action.payload]
      }
    })
    builder.addCase(setSubFilters, (state, action) => {
      if (action.payload) {
        state.selectedSubFilters = action.payload
      }
    })
    builder.addCase(clearSubFilters, (state) => {
      state.selectedSubFilters = []
    })
    builder.addMatcher(
      (action) => action.type === PATH_CHANGE,
      (state) => {
        state.tripItemHasJustBeenAdded = false
        state.firstTripItemHasJustBeenAdded = false
      },
    )
  },
)

export default tripPlannerReducer
