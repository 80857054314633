import React, { useMemo } from 'react'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import Group from 'components/utils/Group'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from 'components/OfferList/OfferListTiles/Support/OfferTileLoggedInButtons'
import config from 'constants/config'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { useOfferRatePrice } from 'hooks/Offers/useOfferPrice'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary | App.BedbankOfferWithRate;
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center'
  loggedInButtonsAlign?: 'none' | 'center' | 'left' | 'right'
  variant?: 'default' | 'condensed';
}

function BedbankOfferPriceDetails(props: Props) {
  const {
    offer,
    loggedInButtonsAlign = 'none',
    horizontalAlign = 'start',
    variant = 'default',
  } = props
  const hotelNights = offer.sell?.los ?? 0
  const durationText = `${pluralizeToString('night', hotelNights)} from`

  const bedbankPrices = useOfferRatePrice(offer)

  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  const wall = (
    <OfferTileLoggedInButtons
      signUpText="Sign up for free"
      align={loggedInButtonsAlign}
      fullWidth={false}
      mdFullWidth={horizontalAlign === 'center'}
    />
  )
  return <Group
    desktopHorizontalAlign={horizontalAlign}
    direction="vertical"
    gap={8}
  >
    {bedbankPrices && <WalledContent enforced={offer.walledGarden} hidePricing={offer.hidePricing} wall={wall}>
      <Group desktopHorizontalAlign={horizontalAlign} direction="vertical">
        <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
        <PriceRowPrice
          size="L"
          price={bedbankPrices.totalPrice}
          saleUnit={bedbankPrices.saleUnit}
        />
        {bedbankPrices.valueOffPercent > 0 && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(bedbankPrices.totalValue)}
          discountPercentage={bedbankPrices.valueOffPercent > (config.displayDiscountThreshold.bedbank * 100) ? bedbankPrices.valueOffPercent : undefined}
        />}
        {variant === 'default' && <PriceRowTaxesAndFees />}
      </Group>
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerLocations={offerLocations}
        offerId={offer.id}
      />
    </WalledContent>}
  </Group>
}

export default BedbankOfferPriceDetails
