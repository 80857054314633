import { useAppSelector } from 'hooks/reduxHooks'
import React, { PropsWithChildren } from 'react'

const CurrencyContext = React.createContext<string>(undefined)

interface Props {
  currency?: string;
}

export function CurrencyProvider(props: PropsWithChildren<Props>) {
  const currentCurrency = useAppSelector((state) => state.geo.currentCurrency)

  return <CurrencyContext.Provider value={props.currency ?? currentCurrency}>
    {props.children}
  </CurrencyContext.Provider>
}

export default CurrencyContext
