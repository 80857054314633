import clsx from 'clsx'
import { themeClassName } from 'lib/theme/themeUtils'
import React, { PropsWithChildren, forwardRef } from 'react'
import { RequireAtLeastOne } from 'type-fest'

type Props = PropsWithChildren<{
  className?: string
} & RequireAtLeastOne<{
  paletteType?: App.PaletteType
  tabletPaletteType?: App.PaletteType
  desktopPaletteType?: App.PaletteType
}, 'paletteType' | 'tabletPaletteType' | 'desktopPaletteType'>>

const PaletteSheet = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className,
    paletteType,
    tabletPaletteType,
    desktopPaletteType,
  } = props

  return <div
    ref={ref}
    className={clsx(className, themeClassName({
      base: paletteType,
      tabletAndUp: tabletPaletteType,
      // @ts-ignore because TS and type-fest are not friends
      desktopAndUp: desktopPaletteType,
    }))}
  >
    {children}
  </div>
})

export default PaletteSheet
