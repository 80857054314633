import React from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Label from 'components/Luxkit/Label/Label'
import Caption from 'components/Luxkit/Typography/Caption'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'

interface Props {
  saleOnDepartures?: boolean;
}

export default function TourDiscountLabel(props: Props) {
  const { saleOnDepartures = false } = props
  return (
    <Label kind="plain" variant="urgency" startIcon={<SolidFireIcon />}>
      <Caption variant="medium" weight="bold">
        Limited Time Special {saleOnDepartures && <BodyText variant="small" as="span" colour="neutral-three">(select departures)</BodyText>}
      </Caption>
    </Label>
  )
}
