import { useEffect, useMemo } from 'react'
import moment from 'moment'
import { isLPCTacticalEnabled } from 'lib/offer/offerUtils'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { isLoggedIn as isLoggedInSelector } from 'selectors/accountSelectors'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { sortBy } from 'lib/array/arrayUtils'
import { buildParamsKey } from 'lib/offer/availabilityUtils'
import { fetchAvailableRatesForOffer } from 'actions/OfferActions'

function useOfferRates(
  offer: App.Offer | App.OfferSummary,
  occupants?: Array<App.Occupants>,
  checkIn?: string | moment.Moment,
  checkOut?: string | moment.Moment,
): App.OfferAvailableRates {
  const dispatch = useAppDispatch()
  const checkInStr = checkIn ? moment(checkIn).format(ISO_DATE_FORMAT) : undefined
  const checkOutStr = checkOut ? moment(checkOut).format(ISO_DATE_FORMAT) : undefined
  const filterKey = useMemo(() => buildParamsKey(
    checkInStr,
    checkOutStr,
    occupants,
  ), [occupants, checkInStr, checkOutStr])

  const rates = useAppSelector(state => state.offer.offerAvailableRates[offer.id]?.[filterKey])

  useEffect(() => {
    const occupanciesInvalid = occupants?.some(item => item.childrenAge?.some(age => age < 0))
    if (checkInStr && checkOutStr && !occupanciesInvalid) {
      dispatch(fetchAvailableRatesForOffer(
        offer,
        {
          occupants,
          checkIn: checkInStr,
          checkOut: checkOutStr,
        },
      ))
    }
  }, [offer, dispatch, occupants, checkInStr, checkOutStr])

  return rates
}

export function useLPCTacticalSearchTilePromotions(offer: App.Offer | App.OfferSummary, bestPricePackage?: App.Package, filters?: App.OfferListFilters) {
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const isAuthorised = isLoggedIn || !offer.walledGarden

  const isLPCTactical = useMemo(() => {
    return isLPCTacticalEnabled() && offer.type === OFFER_TYPE_ALWAYS_ON && (offer?.hasTactical ?? false)
  }, [offer])

  const selectedDuration = useMemo(() => {
    return filters?.checkIn && filters?.checkOut ? moment(filters.checkOut).diff(moment(filters.checkIn), 'days') : undefined
  }, [filters])

  const bestPriceByDurationTacticalPackage = useMemo(() => {
    if (!isLPCTactical || !selectedDuration) {
      return undefined
    }
    const allPackages = offer.packages ?? []
    const packagedViews = allPackages.filter(pkg => pkg.roomRate?.isPackaged)

    for (const view of packagedViews) {
      if (view.price > 0) {
        const indexPackageViewByDuration = allPackages.findIndex(x => x.roomType?.id === view.roomType?.id && x.roomRate?.packagedRatePlanId === view.roomRate?.ratePlanId)
        if (indexPackageViewByDuration >= 0) {
          allPackages[indexPackageViewByDuration] = view
        } else {
          allPackages.push(view)
        }
      }
    }

    const sortedTacticalPackages = allPackages.filter(pkg => pkg.hasTactical && pkg.price > 0).sort((a, b) => {
      return Math.abs(a.duration - selectedDuration) - Math.abs(b.duration - selectedDuration) || a.price - b.price
    })
    return sortedTacticalPackages.length > 0 ? sortedTacticalPackages[0] : undefined
  }, [isLPCTactical, offer, selectedDuration])

  let rate, checkIn, checkOut
  if (bestPriceByDurationTacticalPackage && isAuthorised) {
    const tacticalSchedules = [
      ...(bestPriceByDurationTacticalPackage?.roomRate?.schedules ?? []),
      ...(bestPriceByDurationTacticalPackage.bonusInclusions ?? []).flatMap(
        (inclusion) => inclusion.schedules ?? [],
      ),
      ...(bestPriceByDurationTacticalPackage.inclusions ?? []).flatMap(
        (inclusion) => inclusion.schedules ?? [],
      ),
    ].filter(schedule => schedule.type == 'tactical')

    const travelPeriod = tacticalSchedules[0].travelPeriod

    const from = moment(travelPeriod.from)
    const to = moment(travelPeriod.to)

    const startDate = moment(filters?.checkIn)
    const endDate = moment(filters?.checkOut)

    if (startDate.isBetween(from, to, 'days', '[]')) {
      const diff = from.diff(to, 'days')
      if (selectedDuration && diff < 30 && diff >= selectedDuration) {
        checkIn = startDate.clone().add(selectedDuration, 'days')
        checkOut = endDate.clone().add(selectedDuration, 'days')
      } else {
        checkIn = startDate.clone().add(30, 'days')
        checkOut = endDate.clone().add(30, 'days')
      }
    } else if (startDate.isBefore(from)) {
      checkIn = from
      checkOut = checkIn.clone().add(selectedDuration, 'days')
    } else if (startDate.isAfter(to)) {
      checkOut = to
      checkIn = checkOut.clone().subtract(selectedDuration, 'days')
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const freshRates = useOfferRates(offer, filters?.rooms, checkIn, checkOut)
    if (freshRates?.rates) {
      const rates = sortBy(freshRates.rates.filter(r => r.hasTactical && r.price > 0) ?? [], p => p.price, 'asc')
      if (rates.length > 0) {
        rate = rates[0]
      }
    }
  }
  return { rate, checkIn, checkOut }
}
