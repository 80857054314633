import { getPlural } from 'lib/string/pluralize'
import { capitalise } from 'lib/string/stringUtils'
import { fareTypeLabelMap, FlightsFareTypes } from 'constants/flight'
import moment from 'moment'
import { SHORT_DAY_NAME_DAY_MONTH_YEAR } from 'constants/dateFormats'

interface Supplement {
  validatingCarrierName: string;
  validatingCarrierLogo: string;
}

export function buildFlightItemBreakdownView(
  itemView: App.Checkout.FlightItemView,
  segment: App.Checkout.FlightItemViewJourney,
  supplement: Supplement,
): App.Checkout.FlightItemBreakdownView {
  return {
    title: formatJourneyShorthand(segment.journeyFlight),
    additionalInfoText: [formatJourneyDepartureDate(segment.journeyFlight)],
    itemType: 'flight',
    additionalElements: [],
    price: segment.cost || segment.journeyFlight.cost,
    journey: segment.journeyFlight,
    validatingCarrierLogo: supplement.validatingCarrierLogo,
    validatingCarrierName: supplement.validatingCarrierName,
    isBundled: itemView.isBundled,
    taxesAndFees: itemView.taxesAndFees,
    fareFamily: segment.fareFamily?.fareFamily.description,
  }
}

export function formatJourneyDepartureDate(journey: App.JourneyFlight): string {
  return moment(journey.flights[0].departingDate).format(SHORT_DAY_NAME_DAY_MONTH_YEAR)
}

export function formatFareType(type: 'return' | 'oneWay' | 'multiCity'): string {
  const titleNoun = type === FlightsFareTypes.ONE_WAY ? 'flight' : 'flights'
  return `${fareTypeLabelMap[type]} ${titleNoun}`
}

export function formatJourneyShorthand(journey: App.JourneyFlight): string {
  const journeyDepartureLocation = journey.flights[0].departingDisplayNames.primary
  const journeyArrivalLocation = journey.flights[journey.flights.length - 1].arrivalDisplayNames.primary
  return `${journeyDepartureLocation} to ${journeyArrivalLocation}`
}

export function formatFlightPassengersShorthand(passengers: Array<App.Checkout.FlightPassenger>): string {
  const paxMap = passengers.reduce<{[type: string]: number}>((acc, item) => {
    return { ...acc, [item.type]: item.type in acc ? acc[item.type] + 1 : 1 }
  }, {})
  const elements = Object.keys(paxMap).map(key => {
    const count = paxMap[key]
    return `${count} ${capitalise(getPlural(key, count))}`
  })
  return elements.join(', ')
}
