import React, { useMemo } from 'react'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from 'components/OfferList/OfferListTiles/Support/OfferTileLoggedInButtons'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import ProductPaletteProvider, { ProductPalette } from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import LEOfferForceBundlePricing from './LEOfferPricing/LEOfferForceBundlePricing'
import LEOfferPricing from './LEOfferPricing/LEOfferPricing'
import { unique } from 'lib/array/arrayUtils'

interface Props {
  offer: App.Offer | App.OfferSummary;
  variant?: 'default' | 'condensed';
}

function LEOfferPriceDetails(props: Props) {
  const {
    offer,
    variant = 'default',
  } = props

  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    align="right"
    fullWidth={false}
    discountLabelAlign="end"
  />

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName
  const isCruiseOffer = offer.holidayTypes?.includes('Cruises')

  const paletteValue: ProductPalette = luxPlusEnabled && !!offer.luxPlus?.access ? 'loyalty' : isUltraLuxOffer(offer) ? 'ultralux' : 'default'
  return (
    <WalledContent enforced={offer.walledGarden} wall={wall}>
      <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
        <VerticalSpacer gap={8}>
          <div>
            {offer.bundledWithFlightsOnly && <LEOfferForceBundlePricing variant={variant} offer={offer}/>}
            {!offer.bundledWithFlightsOnly && <LEOfferPricing variant={variant} offer={offer}/>}
          </div>
          <PriceRowAgentHubCommission
            offerId={offer.id}
            offerLocations={offerLocations}
            vendorName={vendorName}
            size="L"
            productType={isCruiseOffer ? 'cruise-flash' : offer.productType}
          />
        </VerticalSpacer>
        <ProductPaletteProvider palette={paletteValue}>
          {variant === 'default' && <CSSBreakpoint min="desktop">
            <TextButton kind="primary" nonInteractable>
              View
            </TextButton>
          </CSSBreakpoint>}
          {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
            View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
          </TextButton> }
        </ProductPaletteProvider>
      </Group>
    </WalledContent>
  )
}

export default LEOfferPriceDetails
