import React, { useCallback, useContext, useState } from 'react'
import moment from 'moment'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Hr from 'components/Common/Hr'
import HelpPane from 'components/Common/HelpPane'
import { INTL_DAY_MONTH_NAME_SHORT, INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import styled from 'styled-components'
import { rem } from 'polished'
import { SEVEN_DAYS_AFTER_PURCHASE, FOURTEEN_DAYS_BEFORE_CHECK_IN } from 'constants/cancellationPolicies'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { getFlexibleCancellationDays } from 'lib/offer/cancellationPolicyUtils'
import Modal from 'components/Luxkit/Modal/Modal'
import LineRefundIcon from 'components/Luxkit/Icons/line/LineRefundIcon'
import TextLink from 'components/Luxkit/TextLink'
import Clickable from 'components/Common/Clickable'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import ModalContext from 'contexts/ModalContext'
import BookingProtectionViaInfoModal from 'checkout/Components/CheckoutSteps/Review/BookingProtection/BookingProtectionViaInfoModal'
import { useIntlDateFormatter } from 'lib/datetime/dateUtils'

const MiddledTooltipIcon = styled(SolidInfoCircleIcon)`
  vertical-align: middle;
`

const CancellationTermsWrapper = styled.div`
  display: grid;
  grid-column-gap: ${rem(18)};
  margin-top: ${rem(40)};

  ${mediaQueryUp.tablet} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
`

const TermsGrid = styled.div`
  display: grid;
  grid-column-gap: ${rem(16)};
  grid-row-gap: ${rem(18)};
  grid-template-columns: 1fr 2fr;

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

interface Props {
  cancellationPolicy: App.OfferCancellationPolicy;
  propertyTimezoneOffset?: number;
  checkInDate?: string | moment.Moment;
  isFlashUSP?: boolean;
  isBookingProtectionEnabled?: boolean;
  cartIncludesFlights?: boolean;
}

function OfferPropertyFlexibleCancellationPolicies(props: Props) {
  const { cancellationPolicy, propertyTimezoneOffset, checkInDate, isFlashUSP, isBookingProtectionEnabled = false, cartIncludesFlights } = props
  const [modalOpen, setModalOpen] = useState(false)
  const intlDateFormatter = useIntlDateFormatter()

  const days = getFlexibleCancellationDays(cancellationPolicy.type)
  let startBasedOnToday = propertyTimezoneOffset ? moment().utcOffset(propertyTimezoneOffset).add(SEVEN_DAYS_AFTER_PURCHASE, 'days') : moment().add(SEVEN_DAYS_AFTER_PURCHASE, 'days')
  let startBasedOnCheckIn = checkInDate && (propertyTimezoneOffset ? moment(checkInDate).utcOffset(propertyTimezoneOffset).subtract(days, 'days') : moment(checkInDate).subtract(days, 'days'))

  const changeOfMindDeadline = checkInDate && propertyTimezoneOffset ? moment(checkInDate).utcOffset(propertyTimezoneOffset).subtract(FOURTEEN_DAYS_BEFORE_CHECK_IN, 'days') : moment(checkInDate).subtract(FOURTEEN_DAYS_BEFORE_CHECK_IN, 'days')
  startBasedOnToday = startBasedOnToday.isBefore(changeOfMindDeadline) ? startBasedOnToday : changeOfMindDeadline

  const cancellationAfterChangeOfMindPeriod = checkInDate && startBasedOnToday.isBefore(changeOfMindDeadline) && moment().add(SEVEN_DAYS_AFTER_PURCHASE, 'days').isBefore(moment(checkInDate).subtract(days, 'days'))
  startBasedOnCheckIn = cancellationAfterChangeOfMindPeriod ? startBasedOnCheckIn : startBasedOnToday

  const bookingProtectionLastEligibleRefundDate = checkInDate ? moment(checkInDate).add(60, 'days') : undefined

  const showModal = useContext(ModalContext)

  const onItemClick = useCallback((e) => {
    e.preventDefault()
    if (isBookingProtectionEnabled && startBasedOnCheckIn && !cartIncludesFlights) {
      showModal(
        <BookingProtectionViaInfoModal
          cancellationType="flexible"
          cancelUntilFullRefundDate={startBasedOnToday}
          cancelAfterNonRefundableDate={startBasedOnCheckIn}
          bookingProtectionLastEligibleRefundDate={bookingProtectionLastEligibleRefundDate}
          cancellationAfterChangeOfMindPeriod={cancellationAfterChangeOfMindPeriod}
        />,
      )
    } else {
      setModalOpen(true)
    }
  }, [
    isBookingProtectionEnabled,
    showModal,
    startBasedOnToday,
    startBasedOnCheckIn,
    bookingProtectionLastEligibleRefundDate,
    cancellationAfterChangeOfMindPeriod,
    cartIncludesFlights,
  ])

  const onModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  return <>
    {!isFlashUSP &&
      <Clickable onClick={onItemClick}>
        <BodyText variant="medium" weight="semi-bold" as="span" colour="highlight-secondary">Flexible cancellation</BodyText>{' '}
        {!checkInDate && <BodyText variant="medium" as="span">available (hotel only)</BodyText>}
        {checkInDate && startBasedOnCheckIn && <BodyText variant="medium" as="span">until {intlDateFormatter(startBasedOnCheckIn, INTL_DAY_MONTH_NAME_SHORT)} (hotel only)</BodyText>}
        {' '}<MiddledTooltipIcon size="XS" />
      </Clickable>
    }
    {isFlashUSP &&
      <TextLink
        size="medium"
        variant="highlight"
        underline={false}
        data-type="7day"
        onClick={onItemClick}
        startIcon={<LineRefundIcon />}
      >
        Flexible cancellation
      </TextLink>
    }
    <Modal
      onClose={onModalClose}
      isOpen={modalOpen}
      title="Cancellation policy"
    >
      <BodyText variant="medium" as="p">
        Things don’t always work out, so enjoy peace of mind knowing that the following cancellation options are available to you.{' '}
      </BodyText>
      <CancellationTermsWrapper>
        <TermsGrid>
          {!checkInDate && <BodyText variant="medium" weight="semi-bold" as="span">
            Up to 7 days after purchase
          </BodyText>}
          {checkInDate && <BodyText variant="medium" weight="semi-bold" as="span">
            Cancel until {intlDateFormatter(startBasedOnToday, INTL_DMY_CASUAL_SHORT_FORMAT)}
          </BodyText>}
          <BodyText variant="medium" as="span">
            100% refund of accommodation purchase price to your original payment method or credit {!checkInDate && <>(provided cancellation is made at least 14 days prior to check-in)</>}
          </BodyText>
        </TermsGrid>
        <Hr size="md"/>
        {cancellationAfterChangeOfMindPeriod && startBasedOnCheckIn && <>
          <TermsGrid>
            <BodyText variant="medium" weight="semi-bold" as="span">
              Cancel until {intlDateFormatter(startBasedOnCheckIn, INTL_DMY_CASUAL_SHORT_FORMAT)}
            </BodyText>
            <BodyText variant="medium" as="span">
              100% refund of accommodation purchase price to credit
            </BodyText>
          </TermsGrid>
          <Hr size="md"/>
        </>}
        {!cancellationAfterChangeOfMindPeriod && !checkInDate && <>
          <TermsGrid>
            <BodyText variant="medium" weight="semi-bold" as="span">
              Up to {days + 1} days prior to check-in
            </BodyText>
            <BodyText variant="medium" as="span">
              100% refund of accommodation purchase price to credit
            </BodyText>
          </TermsGrid>
          <Hr size="md"/>
        </>}
        <TermsGrid>
          {!checkInDate && <BodyText variant="medium" weight="semi-bold" as="span">
            From {days} days until check-in
          </BodyText>}
          {checkInDate && startBasedOnCheckIn && <BodyText variant="medium" weight="semi-bold" as="span">
            Cancel after {intlDateFormatter(startBasedOnCheckIn, INTL_DMY_CASUAL_SHORT_FORMAT)}
          </BodyText>}
          <BodyText variant="medium" as="span">
            Non-refundable
          </BodyText>
        </TermsGrid>
      </CancellationTermsWrapper>
      <HelpPane />
    </Modal>
  </>
}

export default OfferPropertyFlexibleCancellationPolicies
