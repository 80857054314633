import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import useImpressionHandler from 'hooks/useImpressionHandler'
import moment from 'moment'
import React, { useMemo } from 'react'
import SearchTilePriceDetails from '../TileSections/SearchTilePriceDetails'
import { Main, OfferOverlayContainer, StyledOfferBadge, StyledCSSBreakpoint, DetailSegment, MainImageSegment } from '../SearchTileStyles'
import SearchRentalPropertyDetails from './SearchVillaPropertyDetails'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import cn from 'clsx'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { useLocation } from 'react-router'

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

interface Props {
  offer: App.VillaOffer;
  bestPriceForDates?: App.OfferAvailableRate;
  filters?: App.OfferListFilters;
  offerUrl: string;
  bestPricePackage?: App.Package;
  onImageChange?: (idx: number, image?: App.Image) => void;
  onImageLoad?: () => void;
}

function SearchVillaTileExpanded(props: Props) {
  const {
    offer,
    bestPriceForDates,
    filters,
    offerUrl,
    bestPricePackage,
    onImageChange,
    onImageLoad,
  } = props

  const { locationHeading, locationSubheading, badge, images: offerImages } = offer
  const images = offerImages
  const location = useMemo(() => [locationHeading, locationSubheading].filter(t => t).join(', '), [locationHeading, locationSubheading])
  const impressionRef = useImpressionHandler(offer.id)
  const cancellationPolicyType = offer.lowestPricePackage?.roomRate?.cancellationPolicy.type

  const isMapPage = useLocation().pathname.includes('map')

  return (
    <Main ref={impressionRef}>
      <MainImageSegment>
        <ImageCarousel
          images={images}
          imageParams={imageParams}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
        <OfferOverlayContainer>
          <OfferPartnershipLabel offer={offer} />
        </OfferOverlayContainer>
        {badge && <StyledOfferBadge badge={badge} dimensions={{ width: 86, height: 86 }} />}
      </MainImageSegment>
      <div>
        <DetailSegment className={cn({ mapTile: isMapPage })}>
          <TripGuard>
            <StyledCSSBreakpoint min="tablet">
              <FloatingBookmarkContainer>
                <BookmarkButton offer={offer} />
              </FloatingBookmarkContainer>
            </StyledCSSBreakpoint>
            <StyledCSSBreakpoint max="mobile">
              <OffsetBookmarkContainer>
                <BookmarkButton offer={offer} />
              </OffsetBookmarkContainer>
            </StyledCSSBreakpoint>
          </TripGuard>
          <SearchRentalPropertyDetails
            offer={offer}
            location={location}
            cancellationPolicyType={cancellationPolicyType}
            filters={filters}
            offerUrl={offerUrl}
          />
          <SearchTilePriceDetails
            offer={offer}
            filters={filters}
            soldOut={false}
            offerUrl={offerUrl}
            bestPriceForDates={bestPriceForDates}
            bestPricePackage={bestPricePackage}
            checkInDate={moment(filters?.checkIn)}
            />
        </DetailSegment>
      </div>
    </Main>
  )
}

export default SearchVillaTileExpanded
