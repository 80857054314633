import React, { PropsWithChildren, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import BaseDropdownBody from '../BaseDropdownBody'
import { rem } from 'polished'
import clsx from 'clsx'
import { DropdownBehaviourStateContext } from '../DropdownBehaviourContexts'

const Container = styled(BaseDropdownBody)`
  padding: ${rem(24)};

  &.no-top-padding {
    padding-top: 0;
  }
  &.no-bottom-padding {
    padding-bottom: 0;
  }
`

interface Props extends PropsWithChildren {}

const DropdownSheetBody = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children } = props

  const dropdownContext = useContext(DropdownBehaviourStateContext)

  return <Container
    {...props}
    ref={ref}
    className={clsx({
      'no-bottom-padding': dropdownContext.hasFooter,
      'no-top-padding': dropdownContext.hasHeader,
    })}
  >
    {children}
  </Container>
})

export default DropdownSheetBody
