import React, { useCallback, useContext, useMemo } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Pane from 'components/Common/Pane'
import { sortBy, take } from 'lib/array/arrayUtils'
import Image from 'components/Common/Image'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import OfferBadge from 'components/Common/OfferBadge'

import OfferTilePricing from './Support/OfferTilePricing'
import OfferTileLoggedInButtons from './Support/OfferTileLoggedInButtons'
import TextButton from 'components/Luxkit/Button/TextButton'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import Heading from 'components/Luxkit/Typography/Heading'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import GeoContext from 'contexts/geoContext'
import AspectRatio from 'components/utils/AspectRatio'
import { ImageParams } from 'components/Common/ResponsiveImage'
// import NumberRating from 'components/Common/NumberRating/NumberRating'
import ProductPaletteProvider, { ProductPalette } from 'contexts/ProductPaletteContext'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import Group from 'components/utils/Group'
import { getCapacityTitle } from 'lib/homesAndVillas/getCapacityTitle'
import ModalContext from 'contexts/ModalContext'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TextLink from 'components/Luxkit/TextLink'
import SearchRentalAmenities from './SearchOfferTiles/SearchVillaTile/SearchVillaAmenities'
import VillasPageAmenitiesModalContent from 'components/OfferPage/Villas/VillasPageAmenitiesModalContent'
import Modal from 'components/Luxkit/Modal/Modal'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Main = styled(Pane)`
  display: grid;
  position: relative;
  z-index: 0;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
  }
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)};
  }
`

const DetailSegment = styled.div`
  position: relative;
  padding: ${rem(20)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  ${mediaQueryUp.desktop} {
    flex: 1;
    text-align: center;
    padding: ${rem(16)};
  }
`

const TopDetailSegment = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${rem(12)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(8)};
    }
  }
`

const OfferTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-row-gap: ${rem(4)};
  grid-column-gap: ${rem(16)};
  grid-auto-flow: column;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: ${rem(8)};
    justify-items: center;
  }
`

const OfferTitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const BottomSegmentDetails = styled.div``
const BottomSegmentCTA = styled.div`
  display: flex;
  flex-direction: column;
`
const BottomSegmentExtra = styled.div`
  &:empty {
    display: none;
  }
`
const BottomSegment = styled.div`
  margin-top: ${rem(12)};
`

const BottomSegmentGrid = styled.div`
  display: grid;
  grid-column-gap: ${rem(8)};
  grid-template:
    "details cta"
    "extra extra" auto / 1fr ${rem(120)};

  > ${BottomSegmentDetails} {
    grid-area: details;
  }
  > ${BottomSegmentCTA} {
    grid-area: cta;
    align-self: end;
  }
  > ${BottomSegmentExtra} {
    grid-area: extra;
    margin-top: ${rem(16)};
  }

  ${mediaQueryUp.desktop} {
    grid-template:
      "details"
      "." ${rem(16)}
      "cta"
      "extra" auto / 1fr;
  }
`

const BottomWalledGardenSection = styled.div`
  margin-top: ${rem(12)};
`

const UrgencyLabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / 1;

  ${mediaQueryUp.desktop} {
    justify-content: center;
  }

  > * + * {
    margin-left: ${rem(4)};

    ${mediaQueryUp.desktop} {
      margin-left: ${rem(12)};
    }
  }
`

const MainImageSegment = styled.div`
  display: grid;
  gap: ${rem(4)};
  position: relative;

  ${mediaQueryUp.desktop} {
    &.additional {
      grid-template-rows: 1fr ${rem(120)};
    }
  }
`

const AdditionalImages = styled.div`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
    gap: ${rem(4)};
  }
`

const OfferOverlayContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(12)};
  left: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledOfferBadge = styled(OfferBadge)`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};
`

const USDesktopRating = styled(NumberRating)`
  justify-content: center;
`

const StyledOfferTilePricing = styled(OfferTilePricing)`
  align-items: flex-start;

  ${mediaQueryUp.desktop} {
    align-items: center;
  }
`

const TileUrgencyLabels = styled(OfferLabels)`
  ${mediaQueryUp.desktop} {
    justify-content: center;
  }
`

const OfferBadgeTagWrapper = UrgencyLabelsWrapper

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
}

const imageParams: ImageParams = {
  aspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
  quality: 'good',
}

const DesktopOffer = styled.div`
  display: none;
  ${mediaQueryUp.desktop} {
    display: block;
  }
`

const MobileOffer = styled.div`
  display: block;
  ${mediaQueryUp.desktop} {
    display: none;
  }
`

function HomesAndVillasOfferTile(props: Props) {
  const {
    offer,
    eagerLoadFirstImage,
    offerUrl,
  } = props

  const {
    property,
    name,
    lowestPricePackage,
  } = offer
  const { currentRegionCode } = useContext(GeoContext)
  const showAdditionalImages = offer.images.length > 3

  const location = [offer.locationHeading, offer.locationSubheading].filter(t => t).join(', ')
  const capacityTitle = useMemo(() => getCapacityTitle(offer), [offer])

  const pricingSection = <BottomSegmentGrid>
    <BottomSegmentDetails>
      {lowestPricePackage &&
        <StyledOfferTilePricing
          offer={offer}
          pkg={lowestPricePackage}
          offerUrl={offerUrl}
        />}
    </BottomSegmentDetails>
    <BottomSegmentCTA>
      <TextButton kind="primary">
        <DesktopOffer>View Offer</DesktopOffer>
        <MobileOffer>View</MobileOffer>
      </TextButton>
    </BottomSegmentCTA>
  </BottomSegmentGrid>

  const isUltraLux = offer.property?.isUltraLux || offer.productType === 'ultra_lux_tour'

  let paletteValue: ProductPalette
  if (isUltraLux) {
    paletteValue = 'ultralux'
  } else {
    paletteValue = 'default'
  }

  const isUSRegion = currentRegionCode === 'US'
  const hasReview = !isUSRegion && showUserReviewsRating(offer.property?.rating, offer.property?.reviewsTotal)

  const showModal = useContext(ModalContext)
  // villas only have 1 'room' type
  const amenities = useMemo(() => sortBy(offer.packages[0]?.roomType?.amenities ?? [], amenity => amenity.name, 'asc'), [offer])
  const openAmenitiesModal = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    showModal(
      <Modal title="Amenities">
        <VillasPageAmenitiesModalContent amenities={amenities} />
      </Modal>,
    )
  }, [showModal, amenities])

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    onEvent(OfferListEvents.offerReady)
  }, [onEvent])

  return (
    <ProductPaletteProvider palette={isUltraLux ? 'ultralux' : 'default'}>
      <Main type="clean">
        <MainImageSegment className={cn({ additional: showAdditionalImages })}>
          <AspectRatio ratio={showAdditionalImages ? '16:7' : '16:9'}>
            <ImageCarousel
              images={offer.images}
              imageParams={imageParams}
              eagerLoadFirstImage={eagerLoadFirstImage}
              onImageLoad={handleImageLoaded}
            />
          </AspectRatio>
          {showAdditionalImages && <AdditionalImages>
            {take(offer.images, 3, 1).map((image) => (
              <Image
                key={image.id}
                id={image.id}
                alt={name}
                height={120}
                width={245}
                loading="lazy"
                fit="center"
              />
            ))}
          </AdditionalImages>}

          <OfferOverlayContainer>
            <ProductTypeLabel productType={offer.productType} />
            <OfferPartnershipLabel offer={offer} />
          </OfferOverlayContainer>

          {offer.badge && <StyledOfferBadge badge={offer.badge} />}
        </MainImageSegment>
        <ProductPaletteProvider palette={paletteValue}>
          <DetailContainer>
            <CSSBreakpoint min="desktop">
              <Group direction={!hasReview ? 'horizontal-reverse' : 'horizontal'} horizontalAlign="space-between">
                {offer.property && hasReview && (
                  <NumberRating
                    variant="medium"
                    rating={offer.property.rating}
                    total={offer.property.reviewsTotal}
                    ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                  />
                )}
                <TripGuard>
                  <BookmarkButton offer={offer} />
                </TripGuard>
              </Group>
            </CSSBreakpoint>
            <DetailSegment>
              <TripGuard>
                <CSSBreakpoint max="tablet">
                  <OffsetBookmarkContainer>
                    <BookmarkButton offer={offer} />
                  </OffsetBookmarkContainer>
                </CSSBreakpoint>
              </TripGuard>
              <TopDetailSegment>
                <OfferTitle>
                  <TileUrgencyLabels offer={offer} />
                  <OfferTitleText>
                    <Subtitle variant="subtitle3">{location}</Subtitle>
                    <Heading variant="heading5">{property.name}</Heading>
                  </OfferTitleText>
                </OfferTitle>
                {offer.property && showUserReviewsRating(offer.property.rating, offer.property.reviewsTotal) && (
                  <>
                    <CSSBreakpoint max="tablet">
                      <NumberRating
                        inlineLabel
                        variant="xs"
                        rating={offer.property.rating}
                        total={offer.property.reviewsTotal}
                        ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                      />
                    </CSSBreakpoint>
                    {isUSRegion && (
                      <CSSBreakpoint min="desktop">
                        <USDesktopRating
                          inlineLabel
                          variant="xs"
                          rating={offer.property.rating}
                          total={offer.property.reviewsTotal}
                          ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                        />
                      </CSSBreakpoint>
                    )}
                  </>
                )}
                <Group direction="vertical" desktopHorizontalAlign="center" gap={8} desktopGap={12}>
                  {capacityTitle && <BodyTextBlock variant="medium">{capacityTitle}</BodyTextBlock>}
                  <Group direction="vertical" gap={16}>
                    <SearchRentalAmenities amenities={take(amenities, 4)} />
                    <TextLink weight="bold" underline size="medium" onClick={openAmenitiesModal}>
                      View all {amenities.length} amenities
                    </TextLink>
                  </Group>
                  {!!offer.badge && <OfferBadgeTagWrapper>
                    <OfferBadgeTag badge={offer.badge} />
                  </OfferBadgeTagWrapper>}
                </Group>
              </TopDetailSegment>

              <BottomSegment>
                {!offer.walledGarden && pricingSection}
                {offer.walledGarden && <OfferTileLoggedInButtons
                  signUpText="Sign up for free"
                  discountPercentage={Math.round(lowestPricePackage?.discountPercent ?? 0)}
                  align="none"
                  fullWidth
                >
                  <BottomWalledGardenSection>
                    {pricingSection}
                  </BottomWalledGardenSection>
                </OfferTileLoggedInButtons>}
              </BottomSegment>
            </DetailSegment>
          </DetailContainer>
        </ProductPaletteProvider>
      </Main>
    </ProductPaletteProvider>
  )
}

export default HomesAndVillasOfferTile
