import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Group from 'components/utils/Group'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'
import { take } from 'lib/array/arrayUtils'
import styled from 'styled-components'
import { rem } from 'polished'

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${rem(8)};
  grid-column-gap: ${rem(16)};
  overflow: hidden;
  white-space: nowrap;
`

const StyledGroup = styled(Group)`
  margin-top: ${rem(16)};
`

function BedbankFacilities({ popularFacilities }: { popularFacilities: Array<string> }) {
  return (<>
    {popularFacilities.length > 0 && <StyledGroup direction="vertical" gap={8}>
      <BodyTextBlock variant="medium" weight="bold">
        Enjoy these top amenities:
      </BodyTextBlock>
      <AmenitiesGrid>
        {take(popularFacilities, 6).map((facility) => {
          const icon = facility.toLowerCase().split(' ').join('_')
          const FacilityIcon = getInclusionIcon(icon)
          return <BodyTextBlock
              key={facility}
              variant="medium"
              format="titlecase"
              startIcon={<FacilityIcon data-testid={icon} size="S" />}
            >
            {facility}
          </BodyTextBlock>
        })}
      </AmenitiesGrid>
      <BodyTextBlock variant="medium" >
        + more amenities
      </BodyTextBlock>
    </StyledGroup>
  }
  </>)
}

export default BedbankFacilities
