import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import LEOfferPriceDetails from './LEOfferPriceDetails'
import { isCruiseV1Offer } from 'lib/offer/offerTypes'
import CruiseFlashPriceDetails from './CruiseFlashPriceDetails'
import CruiseDescription from './CruiseDescription'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import config from 'constants/config'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'

interface Props {
  offer: App.Offer | App.OfferSummary
  onClick: () => void
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
}

function TourOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props
  const isUltraLux = isUltraLuxOffer(offer)
  const isAgentHubExclusive = config.agentHub.isEnabled && offer?.isAgentHubExclusive

  return (
    <ProductPaletteProvider palette={isUltraLux ? 'ultralux' : 'default'}>
      {size === 'medium' && <CarouselCardMedium
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        image={offer.image}
        location={getLocationString(offer)}
        title={offer.name}
        providerName={offer.vendorName}
        to={offerUrl}
        onClick={onClick}
        urgencyLabels={<LabelGroup>
          {isUltraLux && <ProductTypeLabel productType={offer.productType} />}
          {isAgentHubExclusive && <PromotionLabel key="agenthub_exclusive" variant="engagement">Agent Exclusive</PromotionLabel>}
          {offer.badge && <OfferBadgeTag badge={offer.badge} />}
        </LabelGroup>}
        priceDetails={
          isCruiseV1Offer(offer) ?
            <CruiseFlashPriceDetails variant="condensed" offer={offer} /> :
            <LEOfferPriceDetails variant="condensed" offer={offer} />
        }
        description={
          isCruiseV1Offer(offer) ?
            <CruiseDescription variant="condensed" offer={offer} /> :
            undefined
        }
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        image={offer.image}
        location={getLocationString(offer)}
        title={offer.name}
        to={offerUrl}
        onClick={onClick}
        urgencyLabels={<LabelGroup>
          {isAgentHubExclusive && <PromotionLabel key="agenthub_exclusive" variant="engagement">Agent Exclusive</PromotionLabel>}
          {offer.badge && <OfferBadgeTag badge={offer.badge} />}
        </LabelGroup>}
        description={
          isCruiseV1Offer(offer) ?
            <CruiseDescription offer={offer} /> :
            undefined
        }
        priceDetails={
          isCruiseV1Offer(offer) ?
            <CruiseFlashPriceDetails offer={offer} /> :
            <LEOfferPriceDetails offer={offer} />
        }
      />}
    </ProductPaletteProvider>
  )
}

export default TourOfferCard
