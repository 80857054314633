import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import cn from 'clsx'

import { mediaQueryUp } from 'components/utils/breakpoint'
import RoomList from 'components/OfferPage/Bedbank/BedbankRoomList'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import OfferTileLoggedInButtons from '../Support/OfferTileLoggedInButtons'
import BedbankPromotions from '../Support/BedbankPromotions'

import { getPlural } from 'lib/string/pluralize'
import useImpressionHandler from 'hooks/useImpressionHandler'

import SoldOutMessage from '../Support/SoldOutMessage'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'
import TextButton from 'components/Luxkit/Button/TextButton'
import useBedbankRates from 'hooks/useBedbankRates'
import BedbankOfferTilePriceDetails from '../Support/BedbankOfferTilePriceDetails'
import BedbankOfferTileSnippet from '../Support/BedbankOfferTileSnippet'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Heading from 'components/Luxkit/Typography/Heading'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import { useBedbankSearchTilePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import { getBedbankFacilitiesLimit } from 'lib/hotels/bedbankUtils'
import { buildSuggestedDatesParamsKey, encodeOfferIds } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { queryKeySelectedOfferIds } from 'constants/url'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import AspectRatio from 'components/utils/AspectRatio'
import Group from 'components/utils/Group'
import { Main } from './SearchTileStyles'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { sum } from 'lib/array/arrayUtils'
import BedbankFacilities from '../Support/BedbankFacilities'
import { SPLIT_VIEW_BREAKPOINTS } from 'components/Pages/HotelSearchPage/HotelSplitView/constants'
import WalledContent from 'components/Common/WalledContent'
import { SEARCH_VERTICALS } from 'constants/search'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import { Location } from 'history'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
  padding: ${rem(16)};
  flex: 1;

  &:not(.mapTile) {
    ${mediaQueryUp.tablet} {
      display: grid;
      grid-template-columns: 60% 1fr;
      justify-content: space-between;
      flex-direction: row;
        min-height: ${rem(300)};
        padding: ${rem(24)} ${rem(20)} ${rem(16)};
    }
  }
`

const LeftContent = styled.div`
  align-self: flex-start;
  flex: 1;
  width: 100%;
`

const RightContent = styled.div`
  margin-top: ${rem(16)};
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};

  &:not(.mapTile) {
    ${mediaQueryUp.tablet} {
      margin-left: ${rem(20)};
      justify-content: flex-end;
      align-items: flex-end;

      > * + * {
        margin-top: initial;
      }
    }
  }

  &.mapTile {
    margin-left: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`

const FooterContentHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;

  &.footer-content-columned {
    flex-direction: row;
    align-items: center;
    & > * > * > * {
      margin-top: 0;
    }
  }

  &:empty {
    display: none;
  }
`

const ProductTypeLabelWrapper = styled(ProductTypeLabel)`
  margin-top: ${rem(6)};
`

const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(16)};
  &.mapTile {
    @media screen and (min-width: ${SPLIT_VIEW_BREAKPOINTS.medium}px) {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      > * + * {
        width: 100%;
      }
    }
  }
`

const PromotionsBlock = styled.div`
  width: 100%;
  margin-top: 0;
`

const ViewOfferButton = styled(TextButton)`
  margin-top: ${rem(16)};
  width: 100%;
  &.mapTile {
    width: fit-content;
    padding: 0 ${rem(20)};
  }
`

function getFilterHighlightMessage(offer: App.BedbankOffer | App.BedbankOfferSummary, hasRate: boolean, hasDates: boolean, filters?: App.OfferListFilters) {
  if (filters?.propertyId && !filters?.searchNearby) {
    // This is a property search result

    if (hasDates) {
      if (!hasRate) {
        return `There are no ${getPlural(offer.saleUnit)} available for your selected dates.`
      }
      return 'We found a great price for your hotel. Book now to secure this rate!'
    }
    else {
      return 'We found the property you were interested in. Select your dates to book now.'
    }
  }
}

interface MappedStateProps {
  suggestedDates: App.OfferSuggestedDates;
  windowSearch: string;
  location: Location;
}

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  onImageChange?: (idx: number, image?: App.Image) => void,
  flightPrice: number | undefined
  flightsEnabled: boolean;
}

function BedbankOfferTileCondensed({ offer, filters, eagerLoadFirstImage, offerUrl, suggestedDates, windowSearch, location, onImageChange, flightPrice, flightsEnabled }: Props & MappedStateProps) {
  const checkIn = filters?.checkIn ?? suggestedDates?.checkIn
  const checkOut = filters?.checkOut ?? suggestedDates?.checkOut

  const { images, name, tagline, id, property } = offer
  const [{
    hotelOnlyRates,
    hotelBundleRates,
  }, fetchingRates] = useBedbankRates(id, filters?.rooms, checkIn, checkOut)
  const rate = hotelOnlyRates[0]
  const bundleRate = hotelBundleRates[0] || rate
  const hasDates = !!(checkIn && checkOut)
  const hasStandardOccupancy = useMemo(() => (sum(filters?.rooms || [], room => room.adults + (room.children ?? 0)) === 2), [filters?.rooms])
  const [imageLoaded, setImageLoaded] = useState(false)

  const addressArray: Array<string> = []
  const { city, countryName } = property.address
  if (city) addressArray.push(city)
  if (countryName) addressArray.push(countryName)

  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const isMobileScreen = useIsMobileScreen()
  const isMapPage = location.pathname.includes('/search/map')

  const locationTitle = property.locationTitleOverride ? property.locationTitleOverride : addressArray.join(', ')

  const hasInclusions = !!(rate?.refundable || rate?.facilities?.length)

  const impressionRef = useImpressionHandler(id)

  const { onBedbankSelectDatesPrompt, searchTargetLabel, offerDistanceFromSearchTarget } = useContext(GlobalSearchStateContext)

  const searchTargetDistance = offerDistanceFromSearchTarget?.[offer.id]

  const onSelectDatesPrompt = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (isMobileScreen) {
      toggleSearchVertical(SEARCH_VERTICALS.HOTELS)
    } else {
      onBedbankSelectDatesPrompt?.(offer.id, offer.type, windowSearch)
    }
  }, [isMobileScreen, offer.id, offer.type, onBedbankSelectDatesPrompt, toggleSearchVertical, windowSearch])

  const promotions = useBedbankSearchTilePromotions(offer.promotions, rate, checkIn, checkOut)
  const hasPromotions = promotions.length > 0

  const limitNumberOfFacilities = getBedbankFacilitiesLimit(!!tagline, promotions.length)

  const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut
  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch

  const highlightMessage = getFilterHighlightMessage(offer, !!rate, hasDates, filters)

  const mapSearchUrl = `/search/map?${setSearchParamValue(windowSearch, queryKeySelectedOfferIds, encodeOfferIds([offer]))}`

  const showAmenities = offer.popularFacilities.length > 0

  const imageParams: ImageParams = {
    mobileAspectRatio: '3:2',
    mobileWidth: '100vw',
  }

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    discountPercentage={Math.round(rate?.discount * 100)}
    align="left"
  />

  const isSoldOut = !rate && hasDates && !fetchingRates
  useOfferSoldOutPushDown(offer.id, filters, isSoldOut)
  const hasSellValueAndStandardOccupancy = !!offer.sell && hasStandardOccupancy

  const onEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingRates))) {
      onEvent(OfferListEvents.offerReady, {
        available: !isSoldOut,
      })
    }
  }, [fetchingRates, hasDates, imageLoaded, isSoldOut, onEvent])

  return (
    <Root ref={impressionRef}>
      {!fetchingRates && highlightMessage && <HighlightMessage message={highlightMessage} />}
      <Main className={cn({ mapTile: isMapPage })}>
        <AspectRatio fillHeight={isMapPage} ratio="16:9" desktopRatio={isMapPage ? undefined : 'auto'}>
          <ImageCarousel
            images={images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            onImageChange={onImageChange}
            onImageLoad={handleImageLoaded}
          />
          <CSSBreakpoint max="mobile">
            <OffsetBookmarkContainer>
              <BookmarkButton offer={offer} />
            </OffsetBookmarkContainer>
          </CSSBreakpoint>
        </AspectRatio>
        <Content className={cn({ 'has-dates': hasDates, 'has-sell': !hasDates && offer.sell, mapTile: isMapPage })}>
          <LeftContent>
            <VerticalSpacer gap={8}>
              <Group direction="vertical" fullWidth gap={4}>
                {showUserReviewsRating(property.rating, property.reviewsTotal) && (
                  <NumberRating
                    variant="medium"
                    rating={property.rating ?? 0}
                    total={property.reviewsTotal}
                    ratingSubtitle={property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                  />
                )}
                <OfferTileLocationSection
                  location={locationTitle}
                  mapSearchUrl={mapSearchUrl}
                  searchTargetDistance={searchTargetDistance}
                  searchTargetLabel={searchTargetLabel}
                />
                {!hasDates && (
                  <TripGuard>
                    <CSSBreakpoint min="tablet">
                      <FloatingBookmarkContainer>
                        <BookmarkButton offer={offer} />
                      </FloatingBookmarkContainer>
                    </CSSBreakpoint>
                  </TripGuard>
                )}
              </Group>
              <div>
                <Heading variant="heading5" as="h4">{name}</Heading>
                {tagline && <Subtitle variant="subtitle3">{tagline}</Subtitle>}
              </div>
            </VerticalSpacer>
            {!hasPromotions && <ProductTypeLabelWrapper productType={OFFER_TYPE_BED_BANK} kind="plain"></ProductTypeLabelWrapper>}
            {(hasDates && hasPromotions) && <PromotionsBlock><BedbankPromotions promotions={promotions} /></PromotionsBlock>}
            {!showAmenities && hasInclusions && <RoomList offer={offer} rate={rate} limitNumberOfFacilities={!showAmenities ? limitNumberOfFacilities : 0} showNonCancellation={false} />}
            {!hasDates && !showAmenities && <BedbankOfferTileSnippet offer={offer as App.BedbankOffer} />}
            {(!hasDates && offer.sell && hasPromotions) && <PromotionsBlock><BedbankPromotions promotions={promotions} /></PromotionsBlock>}
            {showAmenities && 'popularFacilities' in offer && <BedbankFacilities popularFacilities={offer.popularFacilities} />}
          </LeftContent>
          <RightContent className={cn({ mapTile: isMapPage })}>
            <FooterContentHolder>
              {!hasDates && !offer.sell && <PromotionsBlock><BedbankPromotions promotions={promotions} /></PromotionsBlock>}
              {(!hasDates && !(hasSellValueAndStandardOccupancy)) && <FooterButtons className={cn({ mapTile: isMapPage })}>
                <TextButton outdent="left" kind="tertiary">View details</TextButton>
                <TextButton kind="primary" onClick={onSelectDatesPrompt}>Select dates</TextButton>
              </FooterButtons>}
            </FooterContentHolder>
            {hasDates && (
            // Render an empty div here for space-between when trip planner is turned off
              <div>
                <TripGuard>
                  <CSSBreakpoint min="tablet">
                    <FloatingBookmarkContainer>
                      <BookmarkButton offer={offer} />
                    </FloatingBookmarkContainer>
                  </CSSBreakpoint>
                </TripGuard>
              </div>
            )}
            <WalledContent enforced={offer.walledGarden && (hasDates || hasSellValueAndStandardOccupancy)} hidePricing={offer.hidePricing} wall={wall}>
              {(rate || (isAnytimeSearch && hasStandardOccupancy && offer.sell)) && <>
                <BedbankOfferTilePriceDetails
                    rate={rate}
                    bundleRate={bundleRate}
                    rooms={filters?.rooms}
                    offer={offer}
                    filters={filters}
                    offerUrl={offerUrl}
                    promotions={promotions}
                    flightPrice={flightPrice}
                    flightsEnabled={flightsEnabled}
                  />
                <ViewOfferButton className={cn({ mapTile: isMapPage })} kind="primary">View offer</ViewOfferButton>
              </>}
              {isSoldOut && <div><SoldOutMessage offer={offer} filters={filters} /></div>}
            </WalledContent>
          </RightContent>
        </Content>
      </Main>
    </Root>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps): MappedStateProps => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)
  return {
    location: appState.router.location,
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
    windowSearch: appState.router.location.search,
  }
})(BedbankOfferTileCondensed)
