import { useAppSelector } from 'hooks/reduxHooks'
import { getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { calculateDiscount } from 'lib/payment/calculateDiscount'
import { buildSuggestedDatesParamsKey } from 'lib/search/searchUtils'
import { isActiveLuxPlusMember, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { useMemo } from 'react'
import { getSuggestedDates } from 'selectors/offerSelectors'

interface Props {
  offer: App.Offer | App.OfferSummary
  pkg?: App.Package
  rate?: App.OfferAvailableRate
  duration?: number
  filters?: App.OfferListFilters
  isPerNightPricingTestEnabled?: boolean
}

interface Return {
  totalPrice: number
  totalMemberPrice?: number
  totalValueBase: number
  discountPercent: number
  hotelPrice: number
  hotelMemberPrice: number
  showMemberPrice: boolean
  propertyFees: number
  showPerNightPricing?: boolean
  suggestedDates?: App.OfferSuggestedDates
  memberInclusionsAmount: number
}

export const useSearchPrices = ({ offer, pkg, rate, duration, filters }: Props):Return => {
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const activeLuxPlusMember = useAppSelector(isActiveLuxPlusMember)
  const isPerNightPricingTestEnabled = !!useAppSelector((state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, filters?.rooms)
  const suggestedDates = useAppSelector((state) => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))
  const isCruise = (offer.holidayTypes ?? []).map(i => i.toLowerCase()).includes('cruises')
  const showPerNightPricing = isPerNightPricingTestEnabled && !isCruise
  const isFlexibleSearch = filters?.flexibleMonths || filters?.flexibleNights
  const rateOrPkg = (rate ?? pkg)
  const baseMemberPrice = rateOrPkg?.memberPrice || 0
  const showMemberPrice = luxPlusEnabled && baseMemberPrice > 0
  const rateOrPkgMemberPrice = rate?.memberPriceWithSurcharge ?? pkg?.memberPrice
  const rooms = useMemo(() => filters?.rooms || [], [filters?.rooms])
  const priceMultiplier = rate ? 1 : (rooms.length || 1)
  const propertyFees = (rate?.propertyFees ?? pkg?.propertyFees ?? 0) * priceMultiplier
  const extraGuestSurcharges = useMemo(() => getSumExtraGuestsSurcharge(rooms, offer, pkg), [pkg, rooms, offer])
  const hotelPrice = ((rateOrPkg?.price ?? 0) + (pkg?.surcharge ?? 0)) * priceMultiplier + extraGuestSurcharges
  const hotelMemberPrice = showMemberPrice ? ((rateOrPkgMemberPrice ?? 0 + (pkg?.surcharge ?? 0)) * priceMultiplier + extraGuestSurcharges) : 0
  const memberInclusionsAmount = pkg?.memberInclusionsAmount || 0
  const totalPrice = isFlexibleSearch && suggestedDates?.price ? suggestedDates?.price : hotelPrice + propertyFees
  const pkgValueOrMemberValue = activeLuxPlusMember ? pkg?.memberValue : pkg?.value
  const totalValueBase = rate?.hotelValue ?? suggestedDates?.value ?? (pkgValueOrMemberValue ?? 0 * (rooms.length || 1) + extraGuestSurcharges)
  const discountPercent = pkg?.discountPercent || calculateDiscount(showMemberPrice && activeLuxPlusMember ? hotelMemberPrice : hotelPrice, totalValueBase)
  const totalMemberPrice = useMemo(() => {
    if (showMemberPrice) {
      let memberPrice:number
      if (isFlexibleSearch && suggestedDates?.memberPrice) {
        memberPrice = suggestedDates.memberPrice
      } else {
        memberPrice = hotelMemberPrice + propertyFees
      }
      return memberPrice && showPerNightPricing ? Math.ceil(memberPrice / (duration || 1)) : memberPrice
    }
  }, [showMemberPrice, isFlexibleSearch, suggestedDates, showPerNightPricing, duration, hotelMemberPrice, propertyFees])
  return {
    totalPrice,
    totalMemberPrice,
    totalValueBase,
    discountPercent,
    hotelPrice,
    hotelMemberPrice,
    showMemberPrice,
    propertyFees,
    showPerNightPricing,
    suggestedDates,
    memberInclusionsAmount,
  }
}
