import React, { ComponentProps } from 'react'
import BasePriceRowPrice from './BasePriceRowPrice'

type Props = Pick<ComponentProps<typeof BasePriceRowPrice>, 'data-testid' | 'discountPercentage' | 'discountType' | 'price' | 'priceFormat' | 'saleUnit' | 'signDisplay' | 'size'>

/**
 * Displays a formatted price with a trailing sale unit.
 */
function PriceRowPrice(props: Props) {
  return <BasePriceRowPrice {...props} className={PriceRowPrice.name} />
}

export default PriceRowPrice
