import React, { useMemo } from 'react'
import CruisePriceDetails from './CruisePriceDetails'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'

interface Props {
  offer: App.Offer | App.OfferSummary
  className?: string;
  variant?: 'default' | 'condensed';
}

function CruiseFlashPriceDetails({ offer, variant = 'default' }: Props) {
  const pkg = offer.lowestPricePackage!
  const propertyFees = pkg.propertyFees
  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE
  const totalPrice = pkg.price + propertyFees
  const totalValue = pkg.value + propertyFees
  const valueOffPercent = getValueOffPercent(totalValue, totalPrice)

  const priceDetails = useMemo(() => {
    return {
      totalPrice,
      saleUnit: offer.saleUnit,
    }
  }, [offer.saleUnit, totalPrice])

  const taxDetails = useMemo(() => {
    return {
      taxesAndFeesAmount: pkg.taxesAndFees,
      taxesPayableAtProperty: !!offer.property?.taxesPayableAtProperty,
    }
  }, [offer.property?.taxesPayableAtProperty, pkg.taxesAndFees])

  const discountPills = useMemo(() => {
    const shouldShowValue = pkg.value > 0 && totalValue > totalPrice && pkg.shouldDisplayValue &&
      !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue)

    if (shouldShowValue) {
      return {
        percentage: valueOffPercent,
        totalPrice: Math.ceil(totalValue),
      }
    }

    return undefined
  }, [offer.type, pkg, shouldShowDiscountPercent, totalPrice, totalValue, valueOffPercent])

  return <CruisePriceDetails
    offerId={offer.id}
    isFlash
    taxDetails={taxDetails}
    priceDetails={priceDetails}
    discountPills={discountPills}
    roomOccupancy={pkg.roomOccupancy}
    variant={variant}
  />
}

export default CruiseFlashPriceDetails
