import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import { FETCH_TOUR_OPERATORS, FETCH_TOUR_SEARCH_FACETS } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialTourState: App.TourState = {
  tourFacets: {},
  tourOperators: {},
}

const apiRequests = reducerSwitch<App.TourState>({
  [FETCH_TOUR_SEARCH_FACETS]: (state, action) => ({
    tourFacets: {
      ...state.tourFacets,
      [action.key]: {
        fetching: true,
      },
    },
  }),
  [FETCH_TOUR_OPERATORS]: (state) => ({
    tourOperators: {
      ...state.tourOperators,
      operators: {},
      fetching: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.TourState>({
  [FETCH_TOUR_SEARCH_FACETS]: (state, action) => ({
    tourFacets: {
      ...state.tourFacets,
      [action.key]: {
        ...action.data,
        destinations: action.data.locations,
        fetching: false,
      },
    },
  }),
  [FETCH_TOUR_OPERATORS]: (state, action) => ({
    tourOperators: {
      ...state.tourOperators,
      operators: { ...action.data },
      fetching: false,
    },
  }),
})

const apiFailures = reducerSwitch<App.TourState>({
  [FETCH_TOUR_SEARCH_FACETS]: (state, action) => ({
    tourFacets: {
      ...state.tourFacets,
      [action.key]: {
        error: action.error,
        fetching: false,
      },
    },
  }),
  [FETCH_TOUR_OPERATORS]: (state, action) => ({
    tourOperators: {
      ...state.tourOperators,
      operators: {},
      fetching: false,
      error: action.error,
    },
  }),
})

export default createReducer<App.TourState>(initialTourState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})
