import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineDollarSignAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14 11h-4a1.9999 1.9999 0 1 1 0-4h5a1 1 0 0 1 1 1 1.0002 1.0002 0 0 0 1.7071.7071A1.0002 1.0002 0 0 0 18 8a3 3 0 0 0-3-3h-2V3a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 3v2h-1a4 4 0 0 0 0 8h4a2 2 0 0 1 0 4H9a1.0002 1.0002 0 0 1-1-1 1 1 0 1 0-2 0 3 3 0 0 0 3 3h2v2a1.0001 1.0001 0 0 0 2 0v-2h1a3.9996 3.9996 0 0 0 2.8284-1.1716 3.9996 3.9996 0 0 0 0-5.6568A3.9996 3.9996 0 0 0 14 11Z"/>
  </SvgIcon>
}

export default LineDollarSignAltIcon
