import {
  cruiseOfferTileClick,
  offerClicked,
} from 'analytics/eventDefinitions'
import { addGTMEvent, fireInteractionEvent } from 'api/googleTagManager'
import CurrencyContext from 'contexts/currencyContext'
import useOffer from 'hooks/Offers/useOffer'
import { isCruiseOffer, isLEOffer } from 'lib/offer/offerTypes'
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import FrontPageTile from './FrontPageOfferTiles/FrontPageTile'
import SearchTile from './SearchOfferTiles/SearchTile'
import OfferListEventsContext, { OfferListEvents, OfferListEventsProvider } from '../OfferListEventsContext'
import OfferListLoadingOfferTile from './OfferListLoadingOfferTile'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import HeroOfferTile from './HeroOfferTiles/HeroOfferTile'
import ErrorBoundary from 'components/Common/ErrorBoundary'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'
import styled from 'styled-components'
import { Main } from './SearchOfferTiles/SearchTileStyles'
import config from 'constants/config'
import clsx from 'clsx'
import {
  calculateHasBeenInView,
  calculateTrackingInView,
  handleSkipInView,
} from 'components/utils/OfferInView'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'

interface Props {
  /**
   * The visual style of tile that should be rendered
   */
  tileStyle: 'search' | 'frontpage' | 'hero';
  /**
   * The offer ID this tile is for
   */
  offerId: string;
  /**
   * The filters associated with the list that's rendering this tile
   */
  filters?: App.OfferListFilters;
  /**
   * The position of this tile in the offer list.
   * Used for analytics
   */
  position: number;
  /**
   * Will eager load (not wait till in view) the first image of whatever image carousel is shown for this offer
   * Use this mostly for SSR
   */
  eagerLoadImage?: boolean;
  className?: string;
  /**
   * Dictate the behaviour of when the tile will mount/load
   *
   * offer-and-inview:
   * Will fetch the offer once in view
   * Will show the tile once both the offer AND the tile is in view
   * This is used to ensure the tile isn't mounted when out of view
   * Note: This will prevent the tile from being SSR'ed
   *
   * offer-or-inview:
   * Will fetch the offer once in view
   * Will show the tile as soon as the offer is available (from any source)
   * Used when you wish the tile to be able to SSR'ed
   *
   * none:
   * Will fetch the offer straight
   * Will show the tile as soon as the offer is ready
   *
   * @default none
   */
  lazyLoad?: 'offer-and-inview' | 'offer-or-inview' | 'none' ;
}

const tileStyleMap = {
  search: SearchTile,
  frontpage: FrontPageTile,
  hero: HeroOfferTile,
}

const StyledHoverHandler = styled.div`
  // Hover effect for LEBT split map view
  &.hoverable {
    ${Main}.mapTile {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.six};
      }
    }
  }
`

/**
 * Core offer list tile that will render the appropriate tile based on the type of item given
 */
const OfferListOfferTile = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    tileStyle,
    offerId,
    position,
    filters,
    eagerLoadImage,
    className,
    lazyLoad = 'none',
  } = props

  const currency = useContext(CurrencyContext)
  const tileRef = useRef<HTMLDivElement>(null)
  const [skipInView, setSkipInView] = useState(false)
  const [offerReady, setOfferReady] = useState(false)
  const [offerAvailability, setOfferAvailability] = useState<boolean | undefined>(undefined)
  // Used to lazy load the tile if turned on
  const [tileInViewRef, tileInView, entry] = useInView({
    rootMargin: '100px',
    threshold: [0, OFFER_TRACKING_IN_VIEW_THRESHOLD],
    skip: skipInView,
  })

  useEffect(() => {
    handleSkipInView(entry, skipInView, setSkipInView)
  }, [entry, skipInView, setSkipInView, tileInView])

  const hasBeenInView = useMemo(() => calculateHasBeenInView(entry), [entry])

  const trackingInView = useMemo(() => calculateTrackingInView(entry), [entry])

  useImperativeHandle(ref, () => tileRef.current!)

  useEffect(() => {
    tileInViewRef(tileRef.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tileRef.current])

  const Tile = tileStyleMap[tileStyle]
  const fireOfferListEvent = useContext(OfferListEventsContext)
  const [offer, , error] = useOffer(offerId, {
    requireSummaryOnly: true,
    disabled: lazyLoad !== 'none' && !hasBeenInView,
  })

  const onProductClick = useCallback(() => {
    if (isLEOffer(offer)) {
      // TODO: remove this once G4A is up?
      // we only know how to register le offer clicks at the moment
      addGTMEvent(offerClicked(offer, currency, position))
    } else if (isCruiseOffer(offer)) {
      fireInteractionEvent(cruiseOfferTileClick(offerId))
    }

    fireOfferListEvent(OfferListEvents.productClick, {
      offer,
      index: position,
      position,
      key: EventDataKey.ProductClick,
    })
  }, [offer, fireOfferListEvent, position, currency, offerId])

  const handleMouseEnter = useCallback(() => {
    fireOfferListEvent(OfferListEvents.hover, { offer, index: position, position, key: EventDataKey.OfferHover })
  }, [fireOfferListEvent, offer, position])

  const handleMouseLeave = useCallback(() => {
    fireOfferListEvent(OfferListEvents.hover, { offer: null, index: position, position, key: EventDataKey.OfferHover })
  }, [fireOfferListEvent, position])

  useEffect(() => {
    if (trackingInView && offer && offerReady) {
      fireOfferListEvent(OfferListEvents.impression, {
        offer,
        index: position,
        position,
        availability: offerAvailability,
        key: EventDataKey.ProductImpression,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingInView, offer, offerReady])

  const onListEvent = useCallback((action: OfferListEvents, data: any, ...rest) => {
    if (action === OfferListEvents.offerReady) {
      setOfferReady(true)
      setOfferAvailability(data?.available)
    }
    fireOfferListEvent(action, data, ...rest)
  }, [fireOfferListEvent])

  if (error) {
    return null
  }

  const showTile = lazyLoad === 'offer-and-inview' ? offer && hasBeenInView : offer

  const offerListTileHoverEnabled = config.businessTraveller.currentAccountMode === 'business' && config.BUSINESS_TRAVELLER_SPLIT_MAP_VIEW_UPDATES_ENABLED

  return (
    <ErrorBoundary fallback={null} reportErrors={false}>
      <LoaderPlayStateContext.Provider value={hasBeenInView ? 'running' : 'paused'} >
        <StyledHoverHandler ref={tileRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={clsx({ hoverable: offerListTileHoverEnabled })}>
          {!showTile && (
            <OfferListLoadingOfferTile
              className={className}
              offerId={offerId}
              tileStyle={tileStyle}
            />
          )}
          {showTile && (
            <OfferListEventsProvider onListEvent={onListEvent}>
              <Tile
                className={className}
                offer={offer!}
                eagerLoadFirstImage={eagerLoadImage}
                filters={filters}
                productClick={onProductClick}
              />
            </OfferListEventsProvider>
          )}
        </StyledHoverHandler>
      </LoaderPlayStateContext.Provider>
    </ErrorBoundary>
  )
})

OfferListOfferTile.displayName = 'OfferListOfferTile'

export default React.memo(OfferListOfferTile)
