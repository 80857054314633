import useAgentHubCommission from 'agentHub/hooks/useAgentHubCommission'
import PriceRowCommission from 'components/Luxkit/PricePoints/PriceRowCommission'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import React from 'react'

type PriceRowCommissionProps = React.ComponentProps<typeof PriceRowCommission>

const productTypeToProductMap = new Map<App.ProductType, PriceRowCommissionProps['productType']>(
  [
    ['limited_time_special', 'hotel'],
    ['tactical_ao_hotel', 'hotel'],
    ['last_minute_hotel', 'hotel'],
    ['hotel', 'hotel'],
    ['bedbank_hotel', 'hotel'],
    ['ultralux_hotel', 'hotel'],
    ['rental', 'hotel'],
    ['bundle_and_save', 'hotel'],
    ['tour', 'tour'],
    ['direct_tour', 'tour'],
    ['partner_tour', 'tour'],
    ['ultra_lux_tour', 'tour'],
    ['connection_tour', 'tour'],
    ['cruise-flash', 'tour'],
    ['cruise', 'cruise'],
    ['cruise-exclusive', 'cruise'],
    ['cruise-tactical', 'cruise'],
    ['experience', 'experience'],
    ['transfer', 'experience'],
    ['always_on', 'experience'],
  ],
)

interface Props {
  size: PriceRowCommissionProps['size'];
  productType: App.ProductType;
  kind?: 'default' | 'plain';
  offerId: string;
  offerLocations?: Array<string>;
  vendorName?: string;
}

function PriceRowAgentHubCommission(props: Props) {
  const {
    size,
    productType,
    kind = 'default',
    offerId,
    offerLocations,
    vendorName,
  } = props

  const customerView = useAppSelector(state => state.agentHub.customerView)
  const product = productTypeToProductMap.get(productType)

  const { commissionAmount } = useAgentHubCommission({
    productType,
    offerId,
    offerLocations,
    vendorName,
  })

  if (!config.agentHub.isEnabled || !commissionAmount || !product || customerView) {
    return null
  }

  if (kind === 'plain') {
    return <BodyTextBlock weight="bold" variant="small" colour="highlight-secondary">
      Earn {commissionAmount}% commission
    </BodyTextBlock>
  }

  return <PriceRowCommission
    size={size}
    commissionPercentage={commissionAmount}
    productType={product}
  />
}

export default PriceRowAgentHubCommission
