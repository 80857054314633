import { createAction } from '@reduxjs/toolkit'

import {
  RESET_SELECTED_DATE,
  SET_SELECTED_DATE,
  SET_CURRENT_TRIP_ID,
  CLEAR_CURRENT_TRIP_ID,
  SET_CURRENT_SECTION_ID,
  CLEAR_CURRENT_SECTION_ID,
  SET_TRIP_ITEM_HAS_JUST_BEEN_ADDED,
  TOGGLE_SUB_FILTER,
  CLEAR_SUB_FILTERS,
  SET_SUB_FILTERS,
  SET_FIRST_TRIP_ITEM_HAS_JUST_BEEN_ADDED,
  CLEAR_FIRST_TRIP_ITEM_HAS_JUST_BEEN_ADDED,
} from './actionConstants'

export const setCurrentTripId = createAction<string>(SET_CURRENT_TRIP_ID)

export const clearCurrentTripId = createAction<void>(CLEAR_CURRENT_TRIP_ID)

export const setCurrentSectionId = createAction<string>(SET_CURRENT_SECTION_ID)

export const clearCurrentSectionId = createAction<void>(
  CLEAR_CURRENT_SECTION_ID,
)

export const setSelectedDate = createAction<{
  tripId: string
  date: string
}>(SET_SELECTED_DATE)

export const resetSelectedDate = createAction<{ tripId: string }>(
  RESET_SELECTED_DATE,
)

export const setTripItemHasJustBeenAdded = createAction<void>(
  SET_TRIP_ITEM_HAS_JUST_BEEN_ADDED,
)

export const setFirstTripItemHasJustBeenAdded = createAction<void>(
  SET_FIRST_TRIP_ITEM_HAS_JUST_BEEN_ADDED,
)

export const clearFirstTripItemHasJustBeenAdded = createAction<void>(
  CLEAR_FIRST_TRIP_ITEM_HAS_JUST_BEEN_ADDED,
)

export const toggleSubFilters =
  createAction<App.TripPlannerSubFilterType>(TOGGLE_SUB_FILTER)

export const clearSubFilters = createAction<void>(CLEAR_SUB_FILTERS)

export const setSubFilters =
  createAction<Array<App.TripPlannerSubFilterType>>(SET_SUB_FILTERS)
