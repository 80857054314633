/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { AdultLabel, getMaxOccupancies } from 'lib/offer/occupancyUtils'
import OccupancyChildAgeSelect from './OccupancyChildAgeSelect'
import Counter from '../../Luxkit/Counter'
import Group from 'components/utils/Group'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import InputText from 'components/Luxkit/Typography/InputText'

const AgeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(12)} ${rem(24)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(auto-fill, ${rem(124)});
    gap: ${rem(8)};
  }
`

export type OccupancyChildVariant = 'child-age' | 'infants'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'onReset'> {
  // occupancy objects don't have an ID so we need to provide one
  itemId: number;
  occupancy: App.Occupants;
  name?: string;
  maxChildAge?: number;
  maxInfantAge?: number;
  capacities?: Array<App.PackageRoomCapacity>;
  onChange: (itemId: number, nextOccupancy: App.Occupants, eventLabel?: string) => void;
  showRemove?: boolean;
  childrenAgeLabel?: string;
  adultLabelType?: AdultLabel;
  showChildren?: boolean;
  offerType?: App.OfferType;
  childVariant?: OccupancyChildVariant;
  childrenLabel?: string;
}

function OccupancyItem(props: Props) {
  const {
    maxChildAge = 17,
    maxInfantAge = -1,
    itemId,
    occupancy,
    onChange,
    name,
    capacities,
    childrenAgeLabel = 'at time of check-in',
    adultLabelType = 'adult',
    showChildren = true,
    offerType,
    childVariant = 'child-age',
    childrenLabel,
    ...rest
  } = props

  const {
    adults,
    infants,
    children,
    childrenAge = [],
  } = occupancy

  const maxCapacities = useMemo(() => {
    return getMaxOccupancies(capacities, occupancy, { maxChildAge, maxInfantAge })
  }, [capacities, occupancy, maxChildAge, maxInfantAge])

  const onAdultChange = useCallback((val: number) => {
    const newOccupancies = { ...occupancy, adults: val }
    onChange(itemId, newOccupancies, val > occupancy.adults ? 'add-adult' : undefined)
  }, [occupancy, onChange, itemId])

  const onChildrenChange = useCallback((val: number) => {
    const infantVariant = childVariant === 'infants'

    if (infantVariant) {
      const nextOccupancy = {
        ...occupancy,
        children: val,
      }

      onChange(itemId, nextOccupancy)
      return
    }

    const nextChildrenAges = [...childrenAge]
    const isIncrementing = val > nextChildrenAges.length

    if (isIncrementing) {
      nextChildrenAges.push(-1)
    } else {
      nextChildrenAges.pop()
    }

    const children = maxInfantAge >= 0 ?
      nextChildrenAges.filter((age) => age > maxInfantAge).length :
      nextChildrenAges.length

    const newOccupancies = {
      ...occupancy,
      children,
      infants: nextChildrenAges.length - children,
      childrenAge: nextChildrenAges,
    }

    onChange(itemId, newOccupancies, isIncrementing ? 'add-kid' : undefined)
  }, [childrenAge, maxInfantAge, childVariant, occupancy, onChange, itemId])

  const onInfantChange = useCallback((val: number) => {
    const isIncrementing = val > (infants || 0)

    const newOccupancies = {
      ...occupancy,
      infants: val,
    }

    onChange(itemId, newOccupancies, isIncrementing ? 'add-kid' : undefined)
  }, [infants, itemId, occupancy, onChange])

  const onChildrenAgeChange = useCallback((childId: number, age: number) => {
    const nextChildrenAges = [...childrenAge]
    nextChildrenAges[childId] = age

    const children = maxInfantAge >= 0 ?
      nextChildrenAges.filter((age) => age > maxInfantAge).length :
      nextChildrenAges.length

    const newOccupancy = {
      ...occupancy,
      children,
      infants: nextChildrenAges.length - children,
      childrenAge: nextChildrenAges,
    }
    onChange(itemId, newOccupancy)
  }, [childrenAge, occupancy, onChange, itemId, maxInfantAge])

  const onlyInfantCanBeAdded = maxCapacities.children === 0 && (maxCapacities.infants ?? 0) > 0
  const maxChildrenAge = onlyInfantCanBeAdded ? maxInfantAge : maxChildAge
  const adultLabel = adultLabelType === 'adult' ? 'Adults' : 'Travellers'
  const showChildrenAge = childVariant === 'child-age' ? childrenAge.length > 0 : false
  const infantVariant = childVariant === 'infants'

  return (
    <VerticalSpacer {...rest} gap={24} data-testid={`occupancy-item-${itemId}`}>
      <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
        <label>
          <InputText variant="label">{adultLabel}</InputText>
          <InputText variant="sub-label" colour="neutral-three">Ages {maxChildAge + 1} and above</InputText>
        </label>

        <Counter
          data-testid={`occupancy-item-${itemId}-adults`}
          value={adults}
          onChange={onAdultChange}
          max={maxCapacities.adults}
          min={1}
          name={`${name}.adults`}
        />
      </Group>

      {showChildren && <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
        <label>
          <InputText variant="label">Children</InputText>
          <InputText variant="sub-label" colour="neutral-three">{childrenLabel || `Up to ${maxChildrenAge} years old`}</InputText>
        </label>

        <Counter
          data-testid={`occupancy-item-${itemId}-children`}
          value={infantVariant ? children : childrenAge.length}
          onChange={onChildrenChange}
          max={childVariant === 'infants' ? maxCapacities.children : (maxCapacities.children ?? 0) + (maxCapacities.infants ?? 0)}
          min={0}
          name={`${name}.children`}
        />
      </Group>}

      {infantVariant && <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
        <label>
          <InputText variant="label">Infants</InputText>
          <InputText variant="sub-label" colour="neutral-three">Aged under {maxInfantAge + 1} · On lap</InputText>
        </label>

        <Counter
          data-testid={`occupancy-item-${itemId}-infant`}
          value={infants}
          onChange={onInfantChange}
          max={maxCapacities.infants ?? 0}
          min={0}
          name={`${name}.infants`}
        />
      </Group>}

      {showChildrenAge &&
        <VerticalSpacer gap={8}>
          <label>
            <InputText variant="label">Children's ages</InputText>
            <InputText variant="sub-label" colour="neutral-three">({childrenAgeLabel})</InputText>
          </label>

          <AgeContainer>
            {childrenAge.map((child, index) => <OccupancyChildAgeSelect
              key={index}
              capacities={capacities}
              occupancy={occupancy}
              maxChildAge={maxChildAge}
              maxInfantAge={maxInfantAge}
              childId={index}
              defaultValue={child === -1 ? undefined : child}
              onChange={onChildrenAgeChange}
              data-testid={`occupancy-item-${itemId}-childrenAge-${index}`}
              name={`${name}.childrenAge[${index}]::number`}
              required
            />)}
          </AgeContainer>
        </VerticalSpacer>
      }
    </VerticalSpacer>
  )
}

export default React.memo(OccupancyItem)
