import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

function getAddonOrderItems(order: App.Order) {
  return order.addonItems.map(addonItem => (comluxgroup.createItem_1_1_0(
    {
      offerId: addonItem.offerSalesforceId,
      categoryId: 'hotel',
      productId: 'addon',
      duration: 1,
      travelStart: addonItem.channelBookingStartTime,
      travelEnd: addonItem.channelBookingEndTime,
      price: addonItem.total,
      offerLeadDuration: '1',
      currency: order.currencyCode,
      destinationCity: addonItem.locationText,
      itemId: addonItem.id,
      bookingNumber: order.number,
      orderId: order.id,
    })
  ),
  )
}

export default getAddonOrderItems
