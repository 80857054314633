import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  getOfferImageId,
  getLabelText,
  needHotelPrice,
  getSavedDetailsForOffer,
  SaveItemsCallbackResult,
  convertOccupancies,
  getDatesAndGuests,
} from './utils'
import BaseBookmarkButton from '../Common/BaseBookmarkButton'
import SaveModal, { TripPlannerSaveModalResult } from '../Common/SaveModal'

import { fetchBestPriceForOffer } from 'actions/OfferActions'
import * as Analytics from 'analytics/analytics'
import {
  saveToTripEvent,
  tripLoginModalSignUpDismiss,
  tripLoginModalSignUpView,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import AnalyticsComponentContext from 'contexts/Analytics/analyticsComponentContext'
import AnalyticsPageContext from 'contexts/Analytics/analyticsPageContext'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import ModalContext from 'contexts/ModalContext'
import OfferPageStateContext from 'contexts/OfferPage/offerPageStateContext'
import { RecommendationSaveClickTrackerContext } from 'contexts/RecommendationSaveClickTrackerContext'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import usePendingLoginHandler from 'hooks/usePendingLoginHandler'
import { EmptyArray } from 'lib/array/arrayUtils'
import { isLoggedIn } from 'selectors/accountSelectors'
import {
  clearRecentlySavedTripId,
  getRecentlySavedTripId,
  setRecentlySavedTripId,
} from 'storage/recentSavedTrip'
import { AccommodationRentalBookmarkPayload } from 'tripPlanner/api/bookmark/types'
import { useTripId } from 'tripPlanner/contexts/TripContext'
import {
  useDeleteTripItem,
  useEditableTrips,
  useTrip,
} from 'tripPlanner/hooks/api'
import { useProcessBookmarks } from 'tripPlanner/hooks/api/bookmark'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import useNewTripNameFromOffer from 'tripPlanner/hooks/useNewTripNameFromOffer'
import {
  setCurrentSectionId,
  setTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'
import {
  getImmersiveTripId,
  selectTripPlannerTemplateId,
  selectTripPlannerTemplateItemId,
} from 'tripPlanner/selectors'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'
import { itemSectionId } from 'tripPlanner/utils/itemSection'

export interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  testId?: string
  iconOnly?: boolean
}

function HomeAndVillasBookmarkButton({
  offer,
  testId,
  iconOnly = false,
}: Props) {
  const offerPageState = useContext(OfferPageStateContext)

  const globalSearchState = useContext(GlobalSearchStateContext)
  const datesAndGuests = getDatesAndGuests(offerPageState, globalSearchState)
  const [isPostLoginSaveRequired, setIsPostLoginSaveRequired] =
    useState<boolean>(false)
  const inTripId = useTripId()
  const currentTripId = useAppSelector(getImmersiveTripId) ?? inTripId
  const analyticsPage = useContext(AnalyticsPageContext)
  const analyticsComponent = useContext(AnalyticsComponentContext)

  const { data, isFetching: tripsFetching } = useEditableTrips()
  const trips = data ?? EmptyArray

  const templateId = useAppSelector(selectTripPlannerTemplateId)
  const templateItemId = useAppSelector(selectTripPlannerTemplateItemId)

  const { data: currentTrip } = useTrip({ tripId: currentTripId })
  const dispatch = useAppDispatch()

  const didAutoSelectTrip = useRef(false)

  // A promise that can be awaited to make sure we only show snackbar messages once the modal is closed
  const modalPromise = useRef<Promise<any>>(Promise.resolve())

  const details = getSavedDetailsForOffer(trips, offer.id, undefined)
  const isSaved = details.length > 0

  const recommendationSaveClickTracker = useContext(
    RecommendationSaveClickTrackerContext,
  )

  const {
    showSaveSuccessSnackbar,
    showSaveErrorSnackbar,
    showRemoveSuccessSnackbar,
    showRemoveErrorSnackbar,
  } = useBookmarkSnackbarHandlers()

  const { mutate: deleteBookmark, isLoading: isDeleting } = useDeleteTripItem({
    onSuccess: (_res, vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveSuccessSnackbar(trip.id, trip.name)
    },
    onError: (_res, _vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveErrorSnackbar(trip.name)
    },
  })

  const newTripName = useNewTripNameFromOffer(offer)

  useEffect(() => {
    if (
      needHotelPrice(datesAndGuests) &&
      datesAndGuests.checkInDate &&
      datesAndGuests.checkOutDate
    ) {
      dispatch(
        fetchBestPriceForOffer(offer, {
          checkIn: datesAndGuests.checkInDate.format(ISO_DATE_FORMAT),
          checkOut: datesAndGuests.checkOutDate.format(ISO_DATE_FORMAT),
          occupants: datesAndGuests.occupancies || [],
        }),
      )
    }
  }, [dispatch, datesAndGuests, offer])

  const { mutateAsync: processBookmarks, isLoading: isCreating } =
    useProcessBookmarks({
      onError: (e, variables) => {
        console.error(e)
        showSaveErrorSnackbar(
          trips.find((t) => t.id === variables.tripIdsAdded[0])?.name,
        )

        // Clear the recently saved trip ID in case it errored because the trip was deleted
        clearRecentlySavedTripId()
      },
    })

  const createTripItems = useCallback(
    async(tripId: string): Promise<SaveItemsCallbackResult> => {
      const payload: AccommodationRentalBookmarkPayload = {
        type: 'rental',
        code: offer.id,
        packageId: offer.lowestPricePackage?.id,
        roomTypeId: offer.lowestPricePackage?.roomType?.id,
        startDate: datesAndGuests.checkInDate?.format(ISO_DATE_FORMAT),
        endDate: datesAndGuests.checkOutDate?.format(ISO_DATE_FORMAT),
        occupancies: convertOccupancies(datesAndGuests.occupancies),
        templateId,
        templateItemId,
      }

      const bookmarkResult = await processBookmarks({
        items: [payload],
        tripIdsAdded: [tripId],
        tripIdsRemoved: [],
      })

      return {
        savedItemIds: bookmarkResult.created.map((item) => item.id),
      }
    },
    [
      datesAndGuests.checkInDate,
      datesAndGuests.checkOutDate,
      datesAndGuests.occupancies,
      offer,
      processBookmarks,
      templateId,
      templateItemId,
    ],
  )

  const createTripItemsImmediate = useCallback(
    async(trip: FullTrip | BasicTrip) => {
      try {
        const res = await createTripItems(trip.id)
        const itemId = res.savedItemIds[0]
        dispatch(setCurrentSectionId(itemSectionId(itemId)))
        dispatch(setTripItemHasJustBeenAdded())
        modalPromise.current.then(() =>
          showSaveSuccessSnackbar(
            trip.id,
            trip.name,
            itemId,
            'ACCOMMODATION',
            true,
          ),
        )
        setRecentlySavedTripId(trip.id)
      } catch (e) {
        // Do nothing - failure case is handled by the hook's onError handler
      }
    },
    [createTripItems, dispatch, showSaveSuccessSnackbar],
  )

  const analyticsLabel = useMemo(() => {
    return analyticsComponent ?
      `${analyticsPage}_${analyticsComponent}` :
      analyticsPage
  }, [analyticsPage, analyticsComponent])

  const showModal = useContext(ModalContext)
  const openSaveModal = useCallback(() => {
    return showModal<TripPlannerSaveModalResult>(
      <SaveModal
        createTripItems={createTripItems}
        itemTypeLabel="offer"
        defaultTripName={newTripName}
        offerImageId={getOfferImageId(offer)}
        isCreatingItem={isCreating}
      />,
    )
  }, [createTripItems, isCreating, newTripName, offer, showModal])

  const save = useCallback(
    async(isIntendingToRemoveBookmark: boolean) => {
      didAutoSelectTrip.current = false
      const recentlySavedTripId = getRecentlySavedTripId()
      if (isSaved) {
        if (isIntendingToRemoveBookmark) {
          Promise.all(
            details.map((detail) =>
              deleteBookmark({
                tripId: detail.trip.id,
                tripItemId: detail.itemId,
              }),
            ),
          )
        }
      } else if (currentTrip) {
        recommendationSaveClickTracker()
        fireInteractionEvent(
          saveToTripEvent('button', 'immersive', 'click', analyticsLabel),
        )
        createTripItemsImmediate(currentTrip)
      } else {
        fireInteractionEvent(
          saveToTripEvent('button', 'global', 'click', analyticsLabel),
        )
        Analytics.trackClientEvent({
          subject: 'save-to-trip',
          action: 'clicked',
          category: 'logging',
          type: 'operational',
          optimizelyEventId: '26343810012',
          optimizelyEventKey: 'click-save-to-trip',
        })
        const recentlySavedTrip = trips.find(
          (t) => t.id === recentlySavedTripId,
        )
        if (recentlySavedTrip) {
          didAutoSelectTrip.current = true
          createTripItemsImmediate(recentlySavedTrip)
        } else {
          modalPromise.current = openSaveModal()
          await modalPromise.current
          modalPromise.current = Promise.resolve()
        }
      }
    },
    [
      isSaved,
      currentTrip,
      details,
      deleteBookmark,
      recommendationSaveClickTracker,
      analyticsLabel,
      createTripItemsImmediate,
      openSaveModal,
      trips,
    ],
  )

  const loggedIn = useAppSelector(isLoggedIn)
  const trackTripLoginModalSignupView = useCallback(() => {
    fireInteractionEvent(tripLoginModalSignUpView('save'))
  }, [])
  const trackTripLoginModalSignupDismiss = useCallback(() => {
    fireInteractionEvent(tripLoginModalSignUpDismiss('save'))
  }, [])

  const onButtonClick = usePendingLoginHandler(
    () => {
      if (loggedIn) {
        save(isSaved)
      } else {
        setIsPostLoginSaveRequired(true)
      }
    },
    'tripPlannerLogin',
    trackTripLoginModalSignupView,
    trackTripLoginModalSignupDismiss,
  )

  useEffect(() => {
    if (loggedIn && isPostLoginSaveRequired && !tripsFetching) {
      save(false)
      setIsPostLoginSaveRequired(false)
    }
  }, [
    isPostLoginSaveRequired,
    loggedIn,
    save,
    setIsPostLoginSaveRequired,
    tripsFetching,
  ])

  const isProcessing = isDeleting || isCreating || tripsFetching

  // We don't support saving flight bundles from the offer page yet, so we hide
  // the save button for now
  if (offer?.bundledWithFlightsOnly) return null

  return (
    <BaseBookmarkButton
      label={getLabelText(isSaved, false)}
      isProcessing={isProcessing}
      isSaved={isSaved}
      onClick={onButtonClick}
      testId={testId}
      iconOnly={iconOnly}
    />
  )
}

export default HomeAndVillasBookmarkButton
