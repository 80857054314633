import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Pane from 'components/Common/Pane/Pane'
import TextButton from 'components/Luxkit/Button/TextButton'
import moment from 'moment'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import BedbankOfferTilePriceDetails from './BedbankOfferTilePriceDetails'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'

const Container = styled(Pane)`
  display: flex;
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  padding: ${rem(16)};
  margin-top: ${rem(16)};
  grid-column: 1 / 3;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  > * + * {
    width: fit-content;
  }
`

const UpsellCTAButton = styled(TextButton)`
  align-self: flex-end;
  margin-top: ${rem(8)};
`

const PriceDetailsContainer = styled.div`
  align-self: flex-end;
${mediaQueryUp.tablet} {
  padding-left: ${rem(20)};
}
`

interface Props {
  offer: App.BedbankOffer
  rate?: App.BedbankRate
  bundleRate?: App.BedbankRate
  offerUrl: string
  filters: App.OfferListFilters
  rooms: Array<App.Occupants>
  flightPrice: number | undefined
  flightsEnabled: boolean
}

export default function BedbankUpsellPromotion(props: Props) {
  const {
    offer,
    offerUrl,
    rate,
    bundleRate,
    filters,
    flightPrice,
    flightsEnabled,
  } = props

  const promotion = offer.promotions?.at(0)

  const checkIn = useMemo(() => filters ? moment(filters.checkIn).format('YYYY-MM-DD') : undefined, [filters])
  const checkOut = useMemo(() => checkIn && promotion ? moment(checkIn).add(promotion.los, 'days').format('YYYY-MM-DD') : undefined, [checkIn, promotion])

  const pkg = useMemo(() => {
    // sort packages by lowest sell price
    return offer.packages.sort((a, b) => {
      if (a?.sell && !b?.sell) return 1
      if (!a?.sell && b?.sell) return -1
      return a?.sell?.price - b?.sell?.price
    }).find(pkg => promotion?.id === pkg.promotionId && pkg?.sell?.price)
  }, [offer, promotion])

  const specialUrl = useMemo(() => {
    const params: any = {
      checkIn,
      checkOut,
    }
    return `${offerUrl.substring(0, offerUrl.indexOf('?'))}?${buildSearchParamsFromFilters(filters, params).toString()}`
  }, [checkIn, offerUrl, checkOut, filters])

  if (!pkg) {
    return null
  }

  return (
    <>
      <PriceDetailsContainer>
        <BedbankOfferTilePriceDetails rate={rate} bundleRate={bundleRate} rooms={props.rooms} offer={offer} filters={filters} offerUrl={offerUrl} promotions={offer.promotions} isUpsell flightPrice={flightPrice} flightsEnabled={flightsEnabled} />
      </PriceDetailsContainer>
      <Container className="bedbank-limited-time-special-offer-tag">
        <VerticalSpacer gap={8}>
          <ProductTypeLabel productType="limited_time_special" />
          <BodyText variant="medium">
            Special discount & inclusions available when you stay for longer.
          </BodyText>
        </VerticalSpacer>
        <UpsellCTAButton kind="secondary" size="small" to={specialUrl}>
          <VerticalSpacer gap={8} />
          View Special Offer
        </UpsellCTAButton>
      </Container>
    </>
  )
}
