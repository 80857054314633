import {
  PROMO_PAYMENT_TYPE,
  CREDIT_PAYMENT_TYPE,
} from 'constants/payment'
import moment from 'moment'
import { groupBy, sortBy, sum } from 'lib/array/arrayUtils'
import { isInstalmentFullyPaidOff } from 'lib/payment/payInInstalmentsUtils'
import config from 'constants/config'

export function getTotalsByItemTypes(order: App.Order) {
  return ({
    accommodationTotal: order.items.reduce((orderTotal, item) => {
      let itemTotal = item.packagePrice

      if (item.reservation?.surcharge &&
        !item.reservation.surchargePaidDirectToVendor) {
        itemTotal = itemTotal + item.reservation.surcharge
      }

      return orderTotal + itemTotal
    }, 0),
    addonsTotal: sum(order.addonItems, i => i.total),
    experiencesTotal: sum(order.experienceItems, i => i.total),
    insuranceTotal: sum(order.insuranceItems, i => i.total),
    flightsTotal: sum(order.flightItems, i => i.total),
  })
}

export function getTotalsExperiencesTickets(order: App.Order) {
  return sum(order.experienceItems, (i) => i.total)
}

export function getTotalPrice(order: App.Order) {
  return sum(getTotalsByItemTypes(order), total => total)
}

export function getPaymentSummary(payments: Array<App.OrderPayment>, hasDowngrade = false) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.type === PROMO_PAYMENT_TYPE ||
      payment.status === 'failed' ||
      (payment.amount < 0 && !hasDowngrade)) {
      return paymentsTotal
    }
    return paymentsTotal + payment.amount
  }, 0)
}

export function getCreditPaymentSummary(payments: Array<App.OrderPayment>, hasDowngrade = false) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.status === 'failed' ||
      payment.type !== CREDIT_PAYMENT_TYPE ||
      (payment.amount < 0 && !hasDowngrade)) {
      return paymentsTotal
    }
    return paymentsTotal + payment.amount
  }, 0)
}

export function isSuccessfulPromoPayment(payment: App.OrderPayment) {
  return payment.status === 'completed' && payment.type === PROMO_PAYMENT_TYPE
}

export function getPromoPaymentSummary(payments: Array<App.OrderPayment>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (isSuccessfulPromoPayment(payment)) {
      return paymentsTotal + payment.amount
    }
    return paymentsTotal
  }, 0)
}

export function getRefundSummary(payments: Array<App.OrderPayment>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.type === PROMO_PAYMENT_TYPE) {
      return paymentsTotal
    }
    if (payment.status === 'failed') {
      return paymentsTotal
    }
    if (payment.amount > 0) {
      return paymentsTotal
    }
    return paymentsTotal - payment.amount
  }, 0)
}

export function getRefundableAmount(payments: Array<App.OrderPayment>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.type === PROMO_PAYMENT_TYPE || payment.status === 'failed') {
      return paymentsTotal
    }
    // N.B. Already refunded amounts are negative payment amounts
    return paymentsTotal + payment.amount
  }, 0)
}

export function getPaymentTotalsByDate(order: App.Order, merchantFee: number = 0) {
  // deposits
  const isDepositOrder = !!order.payments[0]?.depositDetails
  const isDepositBalancePaid = !!order.payments[0]?.depositDetails?.balance_paid_date
  const isInitialDepositOrder = isDepositOrder && !isDepositBalancePaid
  const isCustomOffer = order.customOfferItems.length > 0
  const customOfferDeposit = isCustomOffer && order?.customOfferItems[0]?.custom_offer?.payment_metadata.customer_deposit_1_amount ? order?.customOfferItems[0]?.custom_offer?.payment_metadata.customer_deposit_1_amount / 100 : undefined
  let subTotalCalc = 0
  let creditTotalCalc = 0
  // instalments
  const isPendingInstalmentOrder = !!order.instalmentDetails && !isInstalmentFullyPaidOff(order.instalmentDetails)

  // reserveForZero
  const isPendingReserveForZero = order.reserveForZeroDetails && !order.reserveForZeroDetails.actual_payment_date

  const hasDowngradedItem = [...order.items, ...order.bedbankItems].some((item) => item.isDowngraded || item.downgradedFromId)
  const completedPayments = order.payments.filter(payment => payment.status === 'completed')
  const grouped = groupBy(completedPayments, payment => moment(payment.createdAt).startOf('day').valueOf())
  const paymentGroups = Array.from(grouped.entries()).map(([date, payments]) => {
    const promo = getPromoPaymentSummary(payments)
    const hasPromoPayment = payments.some(isSuccessfulPromoPayment)
    const refunds = getRefundSummary(payments)
    const paid = getPaymentSummary(payments, hasDowngradedItem)
    const credits = getCreditPaymentSummary(payments, hasDowngradedItem)
    subTotalCalc = paid + promo
    creditTotalCalc = credits
    if (isCustomOffer && customOfferDeposit) {
      subTotalCalc += customOfferDeposit
    }
    return {
      date: moment(date),
      subTotal: subTotalCalc,
      paidTotal: !hasDowngradedItem ? paid - credits : paid,
      promoTotal: config.agentHub.isEnabled ? 0 : promo,
      hasPromoPayment,
      creditTotal: creditTotalCalc,
      refundTotal: refunds,
      commissionTotal: config.agentHub.isEnabled ? promo : 0,
    }
  })

  // This is a hack to get the amounts out of the order
  // if changes to cheaper date were performed
  // When the surcharge is downgraded we make full refund on surcharges and then
  // make a new payment for the new surcharge.
  // This information is internal and should not be exposed to the user.
  let cheaperDateRefund: number, cheaperDatePayment: number, cheaperDateCredits: number
  let downgradeRefund = 0
  cheaperDateRefund = cheaperDatePayment = cheaperDateCredits = 0
  if (order.refundMeta.length > 0) {
    order.refundMeta.forEach(element => {
      if (element.reason === 'Customer changed to cheaper dates') {
        cheaperDateRefund += element.cash_amount
        cheaperDateCredits += element.cash_amount
        const paymentTransactionKey = element.comment.split(' ')[0]
        const payment = order.payments.find(payment => payment.transaction_key === paymentTransactionKey)
        if (payment) {
          cheaperDateRefund -= payment.amount
        }
      }
      if (element.reason === 'Up/Downgrade voucher') {
        downgradeRefund += element.cash_amount
      }
    })
  }
  cheaperDatePayment = cheaperDateCredits - cheaperDateRefund

  // if incomplete deposit, instalment and reserveForZero orders use order total as grand total
  const grandTotal = (isInitialDepositOrder || isPendingInstalmentOrder || isPendingReserveForZero) ? order.total - sum(paymentGroups, group => group.creditTotal) - sum(paymentGroups, group => group.promoTotal) + merchantFee : sum(paymentGroups, group => group.paidTotal) - cheaperDateRefund
  // if incomplete deposit or instalment order user order total minus credits total and promo total as sub total
  let subTotal = (isInitialDepositOrder || isPendingInstalmentOrder || isPendingReserveForZero) ? order.total : sum(paymentGroups, group => group.subTotal) - cheaperDatePayment - merchantFee

  // business traveller credits
  let totalBusinessTravellerCredits: number | undefined
  if (
    config.businessTraveller.currentAccountMode === 'business' &&
    order.businessTravellerCreditItems?.length
  ) {
    totalBusinessTravellerCredits = sum(order.businessTravellerCreditItems, (item) => item.total)
    subTotal -= totalBusinessTravellerCredits
  }

  // car hire addons
  if (order.carHireItems[0]?.reservation) {
    subTotal += sum(order.carHireItems[0].reservation?.addons || [], addons => addons.total)
  }

  const hasPromoPayment = paymentGroups.some(group => group.hasPromoPayment)
  const refundedTotal = !hasDowngradedItem ? sum(paymentGroups, group => group.refundTotal) - cheaperDatePayment : sum(paymentGroups, group => group.refundTotal) - downgradeRefund

  return {
    grandTotal,
    subTotal,
    refundedTotal,
    hasPromoPayment,
    promoTotal: sum(paymentGroups, group => group.promoTotal),
    commissionTotal: sum(paymentGroups, group => group.commissionTotal),
    creditsTotal: sum(paymentGroups, group => group.creditTotal) - cheaperDatePayment,
    paymentGroups: sortBy(paymentGroups, g => g.date.valueOf(), 'asc'),
    hasDowngradedItem,
    totalBusinessTravellerCredits,
  }
}
