export const VIRGIN_VELOCITY_PARTNERSHIP_CODE = 'velocity'
/** 0.0075 AUD per velocity point */
export const VIRGIN_VELOCITY_BURN_RATE = 0.0075
export const VIRGIN_VELOCITY_CHECKOUT_PAYMENT_METHODS = ['googlepay', 'stripe_3ds', 'applepay', 'stripe_payment_element_card']
export const VIRGIN_VELOCITY_BURN_MINIMUM = 5000

export const VIRGIN_VELOCITY_EARN_RATE = 1
export const VIRGIN_VELOCITY_START_DATE = '2023-08-22T00:00:00.000Z'

export enum VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP {
  TOUR = 'LUXTOURS',
  CRUISE = 'LUXCRUISE',
  EXPERIENCE = 'LUXEXP',
  FLIGHT = 'LUXFLTS',
  ULTRA_LUX = 'LUXULTRA',
  HOMES_VILLA = 'LUXHMVLA',
  PACKAGE = 'LUXHOLS',
  LAST_MINUTE_HOTEL = 'LUXLMESCPE',
  HOTEL = 'LUXHTLRST',
}

export enum VELOCITY_SSO_STATUS {
  LOGGING_IN = 'loggingIn',
  LOGGED_IN = 'loggedIn',
  LOGGING_OUT = 'loggingOut',
  LOGGED_OUT = 'loggedOut',
}
