import { computeAccommodationDepositAmount } from 'checkout/lib/utils/accommodation/deposit'
import { DepositType } from 'constants/checkout'

export function computeDepositAmount(
  payable: number,
  settings?: App.Checkout.DepositSettings,
): number {
  return settings ? computeAccommodationDepositAmount(payable, settings) : 0
}

export function computeCruiseDepositAmount(
  payable: number,
  settings?: App.Checkout.DepositSettings,
): number {
  if (!settings) return 0

  const { depositType, percentage, flatAmount } = settings
  return (depositType == DepositType.FLAT) ? (flatAmount ?? 0) : payable * (percentage ?? 0) / 100
}
