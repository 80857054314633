import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import useToggle from 'hooks/useToggle'
import React, { forwardRef, useRef } from 'react'
import BusinessTravellerAccountMenuContent from './BusinessTravellerAccountMenuContent'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import BusinessAccountModeIcon from './BusinessAccountModeIcon'
import Group from 'components/utils/Group'
import Caption from 'components/Luxkit/Typography/Caption'
import FormatCurrency from 'components/Common/FormatCurrency'
import { connect } from 'react-redux'

interface Props {
  className?: string
  balance?: number
}

const MENU_TITLE = 'Your account'

const BusinessTravellerAccountButtonMenu = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { off, value, toggle } = useToggle()
  const triggerRef = useRef<HTMLButtonElement>(null)
  const isMobileScreen = useIsMobileScreen()
  const { balance } = props

  return <Group direction="horizontal" ref={ref}>
    <TextButton
      kind="primary"
      variant="ghost"
      to="/account/my-escapes"
    >
      My Bookings
    </TextButton>
    <TextButton
      ref={triggerRef}
      kind="tertiary"
      variant="dark"
      startIcon={<BusinessAccountModeIcon />}
      onClick={toggle}
    >
      <div>
        Account
        {!!balance && <Caption variant="small">
          <FormatCurrency value={balance} format="dynamicDollar" />
        </Caption>}
      </div>
    </TextButton>
    <DropdownList
      size="M"
      open={value && !isMobileScreen}
      title={MENU_TITLE}
      onClose={off}
      triggerRef={triggerRef}
      anchorRef={triggerRef}
    >
      <BusinessTravellerAccountMenuContent onListItemClick={off} />
    </DropdownList>
  </Group>
})

function mapStateToProps(state: App.State) {
  return {
    balance: state.auth.account.balance,
  }
}

export default connect(mapStateToProps)(BusinessTravellerAccountButtonMenu)
