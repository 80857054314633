import { get, set } from 'lib/storage/isomorphicLocalStorage'

const ACCOUNT_ADD_MOBILE_PROMPTED = 'account.addMobilePrompted'

export const accountAddMobilePrompted = (): boolean => {
  return get(ACCOUNT_ADD_MOBILE_PROMPTED)
}

export const setAccountAddMobilePrompted = (prompted: boolean) => {
  set(ACCOUNT_ADD_MOBILE_PROMPTED, prompted)
}
