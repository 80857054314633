import React, { useMemo } from 'react'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
  className?: string;
  variant?: 'default' | 'condensed';
}

function TourV2PriceDetails(props: Props) {
  const { offer, variant = 'default' } = props

  const {
    cheapestPurchasableOption,
    cheapestVariation,
  } = useCheapestOfTourV2Offer(offer)

  const {
    durationInDaysCount,
  } = useTourV2VariationDetails(cheapestVariation)

  const {
    pricePerPerson,
    roomTypeName,
    valuedAt,
    memberPrice,
  } = useTourV2PurchasableOptionDetails(cheapestPurchasableOption) || {}

  const tourV2StartLocation = useMemo(
    () => {
      const variations = Object.values(offer.variations)
      if (variations.length === 0) return ['']

      const variation = variations[0]

      return [variation.startLocation || '']
    },
    [offer],
  )

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <VerticalSpacer gap={8}>
        <div>
          {pricePerPerson &&
            <>
              <PriceRowPriceCaption>{durationInDaysCount} from</PriceRowPriceCaption>
              <LuxPlusPriceStack
                size="L"
                price={Number(pricePerPerson)}
                saleUnit="person"
                memberPrice={Number(memberPrice)}
                isTour
              />
            </>
          }
          {valuedAt && <PriceRowValueDiscountWithCaption
            data-testid="value-box"
            size="L"
            originalValue={Math.ceil(valuedAt.price)}
            discountPercentage={valuedAt.savingPercentage}
          />}
          <PriceRowCaption>{roomTypeName}</PriceRowCaption>
          {variant === 'default' && <PriceRowTaxesAndFees />}
        </div>
        <PriceRowAgentHubCommission
          size="L"
          productType={offer.productType}
          offerId={offer.id}
          offerLocations={tourV2StartLocation}
        />
      </VerticalSpacer>
      {variant === 'default' && <CSSBreakpoint min="desktop">
        <TextButton kind="primary" nonInteractable>
          View
        </TextButton>
      </CSSBreakpoint>}
      {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
        View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
      </TextButton>}
    </Group>
  )
}

export default TourV2PriceDetails
