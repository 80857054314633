import { fetchAccumulatedRatesForBedbankOffer, getBedbankRateKey } from 'actions/BedbankOfferActions'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { useAppDispatch, useAppSelector } from './reduxHooks'

const emptyRates: Array<App.BedbankRate> = []

function useBedbankRates(
  offerId: string,
  occupants?: Array<App.Occupants>,
  checkIn?: string | moment.Moment,
  checkOut?: string | moment.Moment,
  hookEnabled: boolean = true,
): [{ hotelOnlyRates: Array<App.BedbankRate>; hotelBundleRates: Array<App.BedbankRate> }, boolean] {
  const [waitForLoading, setWaitForLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const checkInStr = checkIn ? moment(checkIn).format(ISO_DATE_FORMAT) : undefined
  const checkOutStr = checkOut ? moment(checkOut).format(ISO_DATE_FORMAT) : undefined
  const filterKey = useMemo(() => hookEnabled ? getBedbankRateKey(
    occupants,
    checkInStr,
    checkOutStr,
  ) : undefined, [occupants, checkInStr, checkOutStr, hookEnabled])

  const packages = useAppSelector(state => state.offer.bedbankOfferRates[offerId]?.[filterKey]) ?? emptyRates
  const fetching = useAppSelector(state => state.offer.offerRatesLoading[offerId] ?? waitForLoading)

  useEffect(() => {
    // We can't get rates for a bedbank property without the following properties
    if (hookEnabled && checkInStr && checkOutStr && occupants) {
      dispatch(fetchAccumulatedRatesForBedbankOffer(offerId, occupants, checkInStr, checkOutStr))
    }
    setWaitForLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, hookEnabled])

  const rates = useMemo(() => {
    const hotelOnlyRates = packages.filter(p => !p.isFlightBundle)
    const hotelBundleRates = packages.filter(p => p.isFlightBundle)

    return {
      hotelOnlyRates,
      hotelBundleRates,
    }
  }, [packages])

  return [rates, fetching]
}

export default useBedbankRates
