import { definitions } from '@luxuryescapes/contract-car-hire'
import { dateDifference } from 'lib/datetime/dateUtils'

type VehicleAvailabilityResponse = definitions['getVehicleAvailabilityResponse']

type ServerVehicle = VehicleAvailabilityResponse['vehicles'][0]
type ServerLocation = VehicleAvailabilityResponse['location']
type ServerImportantInformation = definitions['importantInformations'][0]
type ServerReservation = definitions['reservationResponse']
type ServerInsuranceQuote = definitions['getInsuranceQuoteResponse'] | null
type ServerDestinationPriceInfo = definitions['getTrendsResponse']['trends'][0]
type ServerCheapestOffer = definitions['cheapestVehicleOfferResponse']

const fuelTypeToText = new Map<string, string | undefined>([
  ['FULLFULL', 'Return with a full tank of fuel.'],
  ['EMPTYEMPTY', 'No refuelling required before return.'],
  ['FULLEMPTY', 'Pre-purchase fuel. No refuelling required before return.'],
  ['HALFEMPTY', 'No refuelling required before return.'],
  ['QUARTEREMPTY', 'No refuelling required before return.'],
  ['HALFHALF', 'Return with half a tank of fuel.'],
  ['QUARTERQUARTER', 'Return with a quarter tank of fuel.'],
  ['FREETANK', 'Free tank of fuel included'],
  ['FULLEMPTYREFUND', 'If fuel tank is returned with remaining fuel, you will be refunded for the fuel you did not use'],
  ['FULLFULLHYBRID', 'Return with a full tank of fuel'],
  ['CHAUFFULFUL', undefined],
  ['CHAUFFUELINC', 'Chauffer included. Must be returned with a full tank of fuel'],
  ['ELECTRICVEHICLE', 'Return with same charge level as pick-up.'],
])

const serverSizeToType = new Map<string, App.CarHireVehicleType>([
  ['suv', 'SUV'],
  ['medium suv', 'SUV'],
  ['large suv', 'SUV'],
  ['small suv', 'SUV'],
  ['luxury', 'Luxury'],
  ['intermediate elite', 'Luxury'],
  ['premium', 'Luxury'],
  ['minivan', 'Van'],
  ['7 seat minivan', 'Van'],
  ['9 seat minivan', 'Van'],
  ['fullsize', 'Sedan'],
  ['intermediate', 'Sedan'],
  ['standard', 'Sedan'],
  ['car', 'Compact'],
  ['economy', 'Compact'],
  ['compact', 'Compact'],
  ['mini', 'Compact'],
  ['small/medium truck', 'Truck'],
  ['monospace', 'Van'],
  ['moped', 'Scooters and Bikes'],
  ['four wheel drive', '4WD'],
  ['Ford Mustang Converitble', 'Convertable'],
  ['BMW Series 4 Convertible', 'Convertable'],
])

const serverImportantInformationToCategory = new Map<string, App.CarHireImportantInformationCategory>([
  ["Driver's age", 'Driver & Vehicle'],
  ['Driving licence', 'Driver & Vehicle'],
  ['Form(s) of ID', 'Driver & Vehicle'],
  ['Fuel Policy', 'Driver & Vehicle'],
  ['Voucher', 'Driver & Vehicle'],
  ['Mandatory Documents and Important Information', 'Driver & Vehicle'],
  ['Tax rate', 'Deposit & Payment'],
  ['Payment Details', 'Deposit & Payment'],
  ['Extras and Additional Charges', 'Deposit & Payment'],
  ['Fees', 'Deposit & Payment'],
  ['Deposit and Payment Card Information', 'Deposit & Payment'],
  ['Travel Restrictions', 'Travel Restrictions'],
  ['Cross Border Information', 'Travel Restrictions'],
  ['Insurance coverage', 'Coverage'],
  ['Breakdown assistance', 'Inclusions'],
  ['Included in the Price', 'Inclusions'],
])

function getVehicleType(serverType: string): App.CarHireVehicleType {
  const searchValue = serverType.toLowerCase()
  // first try match exact, then do our best
  const mapType = serverSizeToType.get(searchValue)
  if (mapType) {
    return mapType
  }

  if (searchValue.includes('van')) {
    return 'Van'
  } else if (searchValue.includes('suv')) {
    return 'SUV'
  } else if (searchValue.includes('elite')) {
    return 'Luxury'
  } else if (searchValue.includes('truck')) {
    return 'Truck'
  } else if (searchValue.includes('scooter') || searchValue.includes('motorbike')) {
    return 'Scooters and Bikes'
  } else if (searchValue.includes('4wd')) {
    return '4WD'
  } else if (searchValue.includes('convertable')) {
    return 'Convertable'
  }

  return 'Other'
}

function getInformationCategory(serverImportantInformationTitle: string): App.CarHireImportantInformationCategory {
  const mapType = serverImportantInformationToCategory.get(serverImportantInformationTitle)
  if (mapType) {
    return mapType
  }

  return 'Other'
}

const serverPickupTypeToPickupType = new Map<string, App.CarHirePickupType>([
  ['hotel', 'office'],
  ['shuttle off airport', 'shuttle'],
  ['shuttle on airport', 'shuttle'],
  ['terminal', 'terminal'],
])

export function carHireOfferMap(
  serverOffer: ServerVehicle,
  availabilityUuid: string,
  pickUpLocationsMap: Map<string, ServerLocation['pickUp'][0]>,
  returnLocationsMap: Map<string, ServerLocation['return'][0]>,
  pickUpDateTime:string,
  returnDateTime:string,
): App.CarHireOffer {
  const rateOption = serverOffer.rateOptions[0]

  return {
    id: serverOffer.reference.id,
    type: 'car_hire',
    parentType: 'car_hire',
    productType: 'car_hire',
    analyticsType: 'car_hire',
    name: serverOffer.vehicle.model,
    slug: serverOffer.vehicle.model,
    vendor: {
      id: serverOffer.vendor.code,
      name: serverOffer.vendor.shortName,
      image: {
        url: serverOffer.vendor.logoUrl,
        title: serverOffer.vendor.shortName,
      },
    },
    price: Math.ceil(rateOption.totalCharge.price),
    payOnArrivalItems: Array.from(new Set((serverOffer.beforePickupInclusions || []).map((item) => {
      return item.description
    }))),
    priceWithInsurance: serverOffer.vehicle.insuranceUpsell ? Math.ceil(calculatePriceWithInsurance(pickUpDateTime, returnDateTime, rateOption.totalCharge.price, pickUpLocationsMap.get(serverOffer.pickUp.code)?.details?.location?.countryCode)) : undefined,
    pricePerDay: Math.ceil(rateOption.totalCharge.price / serverOffer.duration_days),
    pricePerDayWithInsurance: serverOffer.vehicle.insuranceUpsell ? Math.ceil(calculatePriceWithInsurance(pickUpDateTime, returnDateTime, rateOption.totalCharge.price, pickUpLocationsMap.get(serverOffer.pickUp.code)?.details?.location?.countryCode) / serverOffer.duration_days) : undefined,
    guarenteedModel: serverOffer.vehicle.isGuaranteed,
    duration: serverOffer.duration_days,
    // overall review is out of 100, but we show out of 10
    reviewsBreakdown: {
      overallRating: (serverOffer.vendor.reviews?.overallReview ?? 0) / 10,
      carReview: (serverOffer.vendor.reviews?.carReview ?? 0) / 10,
      deskReview: (serverOffer.vendor.reviews?.deskReview ?? 0) / 10,
      dropOffReview: (serverOffer.vendor.reviews?.dropoffReview ?? 0) / 10,
      pickupReview: (serverOffer.vendor.reviews?.pickupReview ?? 0) / 10,
      priceReview: (serverOffer.vendor.reviews?.priceReview ?? 0) / 10,
    },
    availabilityUuid,
    dropOff: {
      countryCode: returnLocationsMap.get(serverOffer.return.code)?.details?.location.countryCode || '',
      name: returnLocationsMap.get(serverOffer.return.code)?.Name,
      id: returnLocationsMap.get(serverOffer.return.code)?.Code,
      type: serverPickupTypeToPickupType.get(serverOffer.pickUp.vwf.description.toLowerCase()),
      operatingHours: Object.entries(serverOffer.vendor.operatingTime ?? []).flatMap<App.CarHireLocationOperatingTime>(([day, times]) =>
        ({
          day,
          start: times[0]?.start,
          end: times[0]?.end,
        }),
      ),
      address: returnLocationsMap.get(serverOffer.return.code)?.details?.location.address,
      latitude: returnLocationsMap.get(serverOffer.return.code)?.details?.location?.latLon?.[0],
      longitude: returnLocationsMap.get(serverOffer.return.code)?.details?.location?.latLon?.[1],
    },
    pickUp: {
      countryCode: returnLocationsMap.get(serverOffer.return.code)?.details?.location.countryCode || '',
      name: pickUpLocationsMap.get(serverOffer.pickUp.code)?.Name,
      id: pickUpLocationsMap.get(serverOffer.pickUp.code)?.Code,
      type: serverPickupTypeToPickupType.get(serverOffer.return.vwf.description.toLowerCase()),
      // address data can be different between drop off and pick up, this doesn't handle it at the moment
      // awaiting back end to update the data
      operatingHours: Object.entries(serverOffer.vendor.operatingTime ?? []).flatMap<App.CarHireLocationOperatingTime>(([day, times]) =>
        ({
          day,
          start: times[0]?.start,
          end: times[0]?.end,
        }),
      ),
      address: pickUpLocationsMap.get(serverOffer.pickUp.code)?.details?.location?.address,
      latitude: pickUpLocationsMap.get(serverOffer.pickUp.code)?.details?.location?.latLon?.[0],
      longitude: pickUpLocationsMap.get(serverOffer.pickUp.code)?.details?.location?.latLon?.[1],
    },
    copy: {
      inclusions: serverOffer.inclusions,
      fuelPolicy: fuelTypeToText.get(serverOffer.fuelPolicy!),
      pricedCoverages: (serverOffer.pricedCoverages ?? []).map(item => ({
        type: item.type,
        ...(item.deductibleValue && {
          deductibleValue: item.deductibleValue,
        }),
        ...(item.charges && {
          charges: (item.charges ?? []).map(item => ({
            includedInRate: item.includedInRate,
            taxInclusive: item.taxInclusive,
          })),
        }),
      })),
    },
    cancellationPolicy: {
      maxHours: serverOffer.cancellationPolicy.beforeHours,
      maxDate: serverOffer.cancellationPolicy.limitDateUTC,
    },
    mileage: {
      unlimited: serverOffer.vehicle.mileage.unlimited,
      period: serverOffer.vehicle.mileage.period?.toLowerCase().replace('rentalperiod', 'rental') as any,
      unit: serverOffer.vehicle.mileage.unit?.toLowerCase() as any,
      quantity: serverOffer.vehicle.mileage.quantity,
    },
    vehicle: {
      id: serverOffer.reference.id,
      model: serverOffer.vehicle.model.replace(/ or similar/i, ''),
      type: getVehicleType(serverOffer.vehicle.size.description),
      transmission: serverOffer.vehicle.transmissionType,
      seats: serverOffer.vehicle.passengerQuantity,
      luggage: serverOffer.vehicle.baggageQuantity,
      doors: serverOffer.vehicle.doorCount,
      fuelType: serverOffer.vehicle.fuelType,
      insuranceUpsell: serverOffer.vehicle.insuranceUpsell || false,
      image: {
        url: serverOffer.vehicle.image,
        title: serverOffer.vehicle.model,
      },
      code: serverOffer.vehicle.code,
      driveType: serverOffer.vehicle.driveType,
      sizeCode: serverOffer.vehicle.size.code,
      typeCode: serverOffer.vehicle.type.code,
    },
    addons: (serverOffer.addons ?? []).map(serverAddon => ({
      description: serverAddon.description,
      total: serverAddon.price.total,
      memberTotal: 0,
      type: serverAddon.type,
      rateType: serverAddon.rateType,
    })),
    luxPlus: {},
    rateOptions: serverOffer.rateOptions.map((rateOption) => {
      const { totalCharge, ...rest } = rateOption
      return {
        ...rest,
        payNowAmount: Math.ceil(totalCharge.payNow.amount),
        payOnArrivalAmount: Math.ceil(totalCharge.payOnArrival.amount),
      }
    }),
  }
}

export function carHireCheapestOfferMap(serverOffer: ServerCheapestOffer) {
  return {
    pickUp: {
      date: serverOffer.pickUp.date,
      dateChanged: serverOffer.pickUp.dateChanged,
    },
    dropOff: {
      date: serverOffer.return.date,
      dateChanged: serverOffer.return.dateChanged,
    },
    totalCharge: {
      price: serverOffer.vehicle.totalCharge.price,
      currency: serverOffer.vehicle.totalCharge.currency,
    },
    vehicle: {
      code: serverOffer.vehicle.code,
      model: serverOffer.vehicle.model,
      image: {
        url: serverOffer.vehicle.image,
        title: serverOffer.vehicle.model,
      },
      reference: {
        id: serverOffer.vehicle.reference.id,
        idContext: serverOffer.vehicle.reference.idContext,
        type: serverOffer.vehicle.reference.type,
        dateTime: serverOffer.vehicle.reference.dateTime,
        url: serverOffer.vehicle.reference.url,
      },
    },
    duration: serverOffer.vehicle.duration_days,
    offerCount: serverOffer.offerCount,
  }
}

export function carHireOrderItemMap(orderItem: any): App.CarHireOrderItem {
  return {
    id: orderItem.id,
    fkOrderId: orderItem.fk_order_id,
    transactionKey: orderItem.transaction_key,
    status: orderItem.status,
    total: orderItem.total,
    statusReason: orderItem.status_reason,
    idReservation: orderItem.id_reservation,
    ...(orderItem.reservation && {
      reservation: {
        ...(orderItem.reservation.driver && {
          driver: {
            age: orderItem.reservation.driver.age,
            fullName: orderItem.reservation.driver.fullName,
          },
        }),
        payOnPickUpAmount: orderItem.reservation.payOnPickUpAmount,
        pickupType: orderItem.reservation.pickUp?.vwf?.description.toLowerCase(),
        pickUp: {
          code: orderItem.reservation.pickUp.code,
          date: orderItem.reservation.pickUp.date,
          locationName: orderItem.reservation.pickUp.locationName,
        },
        dropOff: {
          code: orderItem.reservation.return.code,
          date: orderItem.reservation.return.date,
          locationName: orderItem.reservation.return.locationName,
        },
        vendor: {
          ...(orderItem.reservation.vendor?.shortName && {
            image: {
              url: orderItem.reservation.vendor.logoUrl ??
                `https://ct-supplierimage.imgix.net/car/${orderItem.reservation.vendor.shortName.toLowerCase().replaceAll(' ', '_')}.pdf?output=auto&w=80&q=80&dpr=2`,
              title: orderItem.reservation.vendor.shortName,
            },
          }),
        },
        ...(orderItem.reservation.vehicle && {
          vehicle: {
            id: orderItem.reservation.vehicle.code,
            doors: orderItem.reservation.vehicle.doorCount,
            vehicleCategory: orderItem.reservation.vehicle.category,
            size: orderItem.reservation.vehicle.size,
            image: {
              url: orderItem.reservation.vehicle.image,
              title: orderItem.reservation.vehicle.model,
            },
            code: orderItem.reservation.vehicle.code,
            fuelType: orderItem.reservation.vehicle.fuelType,
            driveType: orderItem.reservation.vehicle.driveType,
            luggage: orderItem.reservation.vehicle.baggageQuantity,
            transmission: orderItem.reservation.vehicle.transmissionType,
            seats: orderItem.reservation.vehicle.passengerQuantity,
            model: orderItem.reservation.vehicle.model.replace(' or similar', ''),
            type: orderItem.reservation.vehicle.type.description,
            guarenteedModel: orderItem.reservation.vehicle.type.isGuaranteed,
          },
        }),
        ...(orderItem.reservation.arrivalDetails && {
          arrivalFlightNumber: orderItem.reservation.arrivalDetails.number.toUpperCase(),
        }),
        ...(orderItem.reservation.specialEquipments && {
          addons: (orderItem.reservation.specialEquipments ?? []).map(equipment => ({
            description: equipment.description,
            total: equipment.total,
            type: equipment.type,
            rateType: equipment.rateType,
            quantity: equipment.quantity,
          })),
        }),
      },
    }),
  }
}

export function carHireReservationMap(serverReservation: ServerReservation): App.CarHireReservation {
  return {
    id: serverReservation.id,
    orderId: serverReservation.orderId,
    currency: serverReservation.totalCharge.currency,
    voucherId: serverReservation.providerReservationCode,
    driver: {
      age: serverReservation.driver.age,
      fullName: `${serverReservation.driver.givenName} ${serverReservation.driver.surname}`,
      email: serverReservation.driver.email,
      ...(serverReservation.driver.phoneNumber && {
        phoneNumber: serverReservation.driver.phoneNumber,
      }),
    },
    orderDuration: serverReservation.durationDays,
    payNowAmount: Math.ceil(serverReservation.totalCharge.payNow.amount),
    payOnArrivalAmount: serverReservation.totalCharge.payOnArrival?.amount || 0,
    payOnArrivalItems: Array.from(new Set((serverReservation.beforePickupInclusions || []).map((item) => {
      return item.description
    }))),
    pickUp: {
      countryCode: serverReservation.pickUp.location.countryCode,
      type: serverPickupTypeToPickupType.get(serverReservation?.pickUp?.vwf?.description.toLowerCase() || ''),
      id: serverReservation.pickUp.code,
      date: serverReservation.pickUp.date,
      locationName: serverReservation.pickUp.location.locationName,
      airport: serverReservation.pickUp.location.airport,
      address: serverReservation.pickUp.location.address,
      latitude: serverReservation.pickUp.location.lat,
      longitude: serverReservation.pickUp.location.lng,
      directions: serverReservation.pickUp.directions,
      operatingHours: Object.entries(serverReservation.vendor.operatingTime ?? []).flatMap<App.CarHireLocationOperatingTime>(([day, times]) =>
        ({
          day,
          start: times[0]?.start,
          end: times[0]?.end,
        }),
      ),
    },
    dropOff: {
      countryCode: serverReservation.pickUp.location.countryCode,
      type: serverPickupTypeToPickupType.get(serverReservation?.pickUp?.vwf?.description.toLowerCase() || ''),
      id: serverReservation.return.code,
      date: serverReservation.return.date,
      locationName: serverReservation.return.location.locationName,
      airport: serverReservation.return.location.airport,
      address: serverReservation.return.location.address,
      latitude: serverReservation.return.location.lat,
      longitude: serverReservation.return.location.lng,
      operatingHours: Object.entries(serverReservation.vendor.operatingTime ?? []).flatMap<App.CarHireLocationOperatingTime>(([day, times]) =>
        ({
          day,
          start: times[0]?.start,
          end: times[0]?.end,
        }),
      ),
    },
    copy: {
      importantInformation: (serverReservation.importantInformations ?? []).map(item => ({
        category: getInformationCategory(item.title),
        title: item.title,
        paragraphs: item.paragraphs,
      })),
      ...(serverReservation.inclusions && {
        inclusions: serverReservation.inclusions,
      }),
      ...(serverReservation.fuelPolicy && {
        fuelPolicy: fuelTypeToText.get(serverReservation.fuelPolicy),
      }),
      pricedCoverages: (serverReservation.pricedCoverages ?? []).map(item => ({
        type: item.type,
        ...(item.deductibleValue && {
          deductibleValue: item.deductibleValue,
        }),
        ...(item.charges && {
          charges: (item.charges ?? []).map(item => ({
            includedInRate: item.includedInRate,
            taxInclusive: item.taxInclusive,
          })),
        }),
      })),
    },
    vendor: {
      phone: serverReservation.vendor.phone,
      phoneCode: serverReservation.vendor.phoneCode,
      name: serverReservation.vendor.name,
      image: {
        title: serverReservation.vendor.shortName,
        url: serverReservation.vendor.logoUrl ??
          `https://ctimg-svg.cartrawler.com/supplier-images/${serverReservation.vendor.shortName.toLowerCase().replaceAll(' ', '_')}.svg`,
      },
    },
    vehicle: {
      id: serverReservation.vehicle.code,
      doors: serverReservation.vehicle.doorCount,
      size: serverReservation.vehicle.size.description,
      image: {
        url: serverReservation.vehicle.image,
        title: serverReservation.vehicle.model,
      },
      code: serverReservation.vehicle.code,
      fuelType: serverReservation.vehicle.fuelType,
      driveType: serverReservation.vehicle.driveType,
      luggage: serverReservation.vehicle.baggageQuantity,
      transmission: serverReservation.vehicle.transmissionType,
      seats: serverReservation.vehicle.passengerQuantity,
      model: serverReservation.vehicle.model.replace(/ or similar/i, ''),
      type: getVehicleType(serverReservation.vehicle.size.description),
      guarenteedModel: serverReservation.vehicle.isGuaranteed,
    },
    mileage: {
      unlimited: serverReservation.vehicle.mileage.unlimited,
      period: serverReservation.vehicle.mileage.period?.toLowerCase().replace('rentalperiod', 'rental') as any,
      unit: serverReservation.vehicle.mileage.unit?.toLowerCase() as any,
      quantity: serverReservation.vehicle.mileage.quantity,
    },
    // overall review is out of 100, but we show out of 10
    rating: (serverReservation.vendor.reviews?.overallReview ?? 0) / 10,
    ...(serverReservation.arrivalDetails && {
      arrivalFlightNumber: serverReservation.arrivalDetails.number,
    }),
    ...(serverReservation.addons && {
      addons: (serverReservation.addons ?? []).map(equipment => ({
        description: equipment.description,
        total: equipment.price.total,
        type: equipment.type,
        rateType: equipment.rateType,
        quantity: equipment.quantity,
      })),
    }),
    ...(serverReservation.cancellationPolicy && {
      cancellationPolicy: {
        maxHours: serverReservation.cancellationPolicy.beforeHours,
        maxDate: serverReservation.cancellationPolicy.limitDateUTC,
      },
    }),
  }
}

export function carHireOfferImportantInformationMap(
  serverImportantInformation: ServerImportantInformation,
): App.CarHireImportantInformation {
  return {
    category: getInformationCategory(serverImportantInformation.title),
    title: serverImportantInformation.title,
    paragraphs: serverImportantInformation.paragraphs,
  }
}

function getFooterText(serverInsuranceQuote: ServerInsuranceQuote) {
  if (serverInsuranceQuote) {
    const footerText = serverInsuranceQuote.extensions.data.paragraph.find((item) => item.type === 'SUBFOOTER')
    if (footerText) {
      return footerText.text
    }
  }
  return ''
}

function getLimitedProtectionText(serverInsuranceQuote: ServerInsuranceQuote) {
  if (serverInsuranceQuote) {
    const limitedProtectionInfo =
      serverInsuranceQuote.extensions.explained.items.find((item) => item.type === 'INFO' && item.order === '2')
    if (limitedProtectionInfo) {
      return limitedProtectionInfo.text
    }
  }
  return ''
}

function getLimitedProtectionExcess(serverInsuranceQuote: ServerInsuranceQuote) {
  if (serverInsuranceQuote) {
    const limitedProtectionInfo =
    serverInsuranceQuote.extensions.explained.items.find((item) => item.type === 'INFO' && item.order === '2')

    const limitedSplittedProtectionText = limitedProtectionInfo?.text.split('AUD')
    const excessValueString = limitedSplittedProtectionText?.[1].substring(1, 10)

    if (limitedProtectionInfo && excessValueString) {
      return parseFloat(excessValueString)
    }
  }
  return 0
}

export function carHireInsuranceQuoteMap(serverInsuranceQuote: ServerInsuranceQuote)
: App.CarHIreOfferInsuranceQuoteInformation | null {
  if (!serverInsuranceQuote) {
    return null
  }
  return {
    termsAndConditionsUrl: serverInsuranceQuote.quoteDetailUrl,
    premiumPlanPrice: Number(serverInsuranceQuote.planPricePremium),
    premiumPlanCurrency: serverInsuranceQuote.planPricePremiumCurrency,
    premiumPlanName: serverInsuranceQuote.extensions.data.title.text,
    limitedProtectionFooterText: getFooterText(serverInsuranceQuote),
    planId: serverInsuranceQuote.planID,
    insuranceSupplierLogo: {
      url: serverInsuranceQuote.extensions.data.image.text,
    },
    limitedProtectionText: getLimitedProtectionText(serverInsuranceQuote),
    limitedProtectionExcess: getLimitedProtectionExcess(serverInsuranceQuote),
    planPriceCurrency: serverInsuranceQuote.planPriceCurrency,
    premiumPlanParagraphs: (serverInsuranceQuote.extensions.explained.items || []).slice(3).map((item) => ({
      // we are replacing the plan name ('Roadtrip Insurance') with 'Enhanced Protection' in all copy
      text: item.text.replace('Roadtrip Insurance', 'Enhanced Protection'),
      type: item.type,
    })),
    policyLimit: parseFloat(serverInsuranceQuote.policyLimit),
    planUrl: serverInsuranceQuote.quoteDetailUrl,
  }
}

export function carHireDestinationPriceInfoMap(serverDestinationPriceInfo: ServerDestinationPriceInfo)
: App.CarHireDestinationPriceInfo {
  return {
    locationName: serverDestinationPriceInfo.name.toLowerCase(),
    locationAveragePrice: serverDestinationPriceInfo.avgPrice,
    locationMinimumPrice: serverDestinationPriceInfo.minPrice,
    key: serverDestinationPriceInfo.key,
  }
}

const regionPricesPerDay = {
  AU: 25.23,
  US: 12.00,
  default: 28.01,
}

function calculatePriceWithInsurance(
  pickUpDateTime: string,
  returnDateTime: string,
  price: number,
  countryCode: string | undefined,
) : number {
  if (!countryCode) {
    return price
  }

  /**
   * For the insurance duration, once a rental crosses midnight it's an additional day,
   * that's why we are adding a day in the difference result.
  */
  const insuranceDuration = dateDifference(new Date(returnDateTime), new Date(pickUpDateTime)).days + 1

  const regionPrice = (regionPricesPerDay[countryCode] || regionPricesPerDay.default) * insuranceDuration
  return price + regionPrice
}
