export function checkJourneyMismatchPrice(
  journey: App.Journey,
  departingCost: number,
  returningCost: number,
): App.Journey {
  if (journey.cost !== departingCost + returningCost) {
    journey.cost = departingCost + returningCost
    journey.departing.cost = departingCost

    if (journey.returning) {
      journey.returning.cost = returningCost
    }
  }

  return journey
}

export function convertToJourney(
  journey: App.JourneyV2,
  fareType: 'return' | 'oneWay' | 'multiCity',
  searchId: string,
  // use this when you want to merge two journey v2s (2 legs) into a single journey v1
  returningJourney?: App.JourneyV2,
): App.Journey {
  return {
    id: journey.id,
    provider: journey.provider,
    validatingCarrier: journey.carrier,
    validatingCarrierName: journey.carrierName,
    validatingCarrierLogo: journey.carrierLogo,
    seatSelectionPolicy: journey.flightGroup.flights[0]?.seatSummary.selectionPolicy,
    isAllianceCarrier: false,
    apisRequired: true,
    auContactTracingRequired: true,
    cost: journey.price.all.totalFare + (returningJourney?.price.all.totalFare ?? 0),
    tax: journey.price.all.totalTax + (returningJourney?.price.all.totalTax ?? 0),
    fee: journey.price.all.totalFees + (returningJourney?.price.all.totalFees ?? 0),
    bookingFee: journey.price.all.bookingFee + (returningJourney?.price.all.bookingFee ?? 0),
    serviceFee: journey.price.all.serviceFee + (returningJourney?.price.all.serviceFee ?? 0),
    atolFee: journey.price.all.atolFee + (returningJourney?.price.all.atolFee ?? 0),
    flightsCreditFee: journey.price.all.flightsCreditFee + (returningJourney?.price.all.flightsCreditFee ?? 0),
    priceBreakdown: {
      adults: {
        cost: (journey.price.adult.totalFare ?? 0) + (returningJourney?.price.adult.totalFare ?? 0),
        tax: (journey.price.adult.totalTax ?? 0) + (returningJourney?.price.adult.totalTax ?? 0),
      },
      children: {
        cost: 0,
        tax: 0,
      },
      infants: {
        cost: 0,
        tax: 0,
      },
    },
    returning: fareType === 'return' || fareType === 'multiCity' ? convertJourneyV2ToJourneyFlight(returningJourney ?? journey) : null,
    departing: convertJourneyV2ToJourneyFlight(journey),
    fareType,
    fareClass: 'economy',
    searchId,
    itemType: 'journeyV2',
  }
}

export function convertJourneyV2ToJourneyFlight(journey: App.JourneyV2): App.JourneyFlight {
  return {
    journeyKey: journey.bookingInfo.journeyKey,
    flights: journey.flightGroup.flights.map(flight => flightMap(flight, journey.links)),
    flightTime: journey.flightGroup.totalFlightDuration,
    extras: baggageOptionsMap(journey.flightGroup.flights[0]),
    providerSearchId: journey.bookingInfo.providerSearchId,
    priceBreakdown: {
      adults: {
        cost: journey.price.adult.totalFare,
        tax: journey.price.adult.totalTax,
      },
      children: {
        cost: 0,
        tax: 0,
      },
      infants: {
        cost: 0,
        tax: 0,
      },
    },
    provider: journey.provider,
    carrierName: journey.carrierName,
    carrierLogo: journey.carrierLogo,
    cost: journey.price.all.totalFare,
    tax: journey.price.all.totalTax,
  }
}

function flightMap(flight: App.FlightV2, links: Array<Record<string, string>>): App.Flight {
  const { operatingAirline, marketingAirline } = flight
  const operatingByDifferentAirline = operatingAirline.code !== marketingAirline.code
  const operatedByText = operatingByDifferentAirline ? `Operated by ${operatingAirline.name}` : null

  return {
    _links: {
      fare_rules: {
        href: links.find(link => link.key === 'fareRules')?.value,
      },
    },
    departingAirport: flight.departureAirport,
    departingAirportName: flight.departureAirportName,
    departingDisplayNames: {
      departingCountry: flight.departureCountry,
      primary: flight.departureAirportName,
      secondary: null,
    },
    departingCountry: flight.departureCountry,
    departingDate: flight.departingDate,
    departingTime: flight.departingTime,

    arrivalAirport: flight.arrivalAirport,
    arrivalAirportName: flight.arrivalAirportName,
    arrivalDisplayNames: {
      primary: flight.arrivalAirportName,
      secondary: null,
    },
    arrivalCountry: flight.arrivalCountry,
    arrivalDate: flight.arrivalDate,
    arrivalTime: flight.arrivalTime,
    operatedByText,
    carrier: flight.marketingAirline.code,
    carrierName: flight.marketingAirline.name,
    flightNumber: flight.flightNumber,
    aircraftType: flight.aircraftType,
    layoverTime: flight.stopoverTime,
    freeCarryOnBaggageDescription: flight.includedCarryOnBaggage?.description,
    freeCheckedInBaggageDescription: flight.includedCheckedBaggage?.description,
    freeCheckedInBaggageIncluded: !!flight.includedCheckedBaggage,
    bookingClass: flight.flightBookingKey,
    fareClass: flight.fareClass,
    fareBasis: '', // flight.fare_basis,
    productClass: '', // flight.product_class,
    operatingCarrier: flight.operatingAirline.code,
  }
}

function baggageOptionsMap(flight: App.FlightV2): App.JourneyAddons {
  return {
    baggage: mapBaggageToAddon(flight.checkedBaggageOptions),
    carryOnBaggage: mapBaggageToAddon(flight.carryOnBaggageOptions),
  }
}

function mapBaggageToAddon(options: Array<App.FlightV2BaggageOption>): Array<App.JourneyAddon> {
  return options
    .filter(option => !option.includedInFare)
    .map(option => ({
      id: option.id,
      description: option.description,
      quantity: option.quantity,
      amount: option.amount,
      ...((option.passengerType == 'ADT' || option.passengerType === 'CHD') && {
        [option.passengerType === 'ADT' ? 'adult' : 'child']: {
          cost: option.amount,
          tax: 0,
          max: 1,
        },
      }),
    }))
}
