import config from 'constants/config'
import { PROVIDER_COVER_GENIUS } from 'constants/insurance'
import memoize from 'lib/memoize/memoize'
import moment from 'moment'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { getAge } from 'lib/datetime/dateUtils'

/**
 * Excludes 'senior' as there is logic in svc-insurance for this
 * and an option in checkout to specifically select it if required.
 */
const COVER_GENIUS_AGE_CATEGORIES: Array<{
  minAge: number,
  maxAge: number,
  name: 'infant' | 'child' | 'adult'
}> = [
  {
    minAge: 0,
    maxAge: 1,
    name: 'infant',
  },
  {
    minAge: 2,
    maxAge: 17,
    name: 'child',
  },
  {
    minAge: 18,
    maxAge: 65,
    name: 'adult',
  },
]

// isCoverGeniusEnables checks if the Cover Genius should be used for
// an insurance provider for the given region code.
export const isCoverGeniusEnabled = memoize((regionCode: string): boolean => {
  return isEnabledForFeature(config.COVER_GENIUS_COUNTRIES, regionCode)
})

export function isCoverGeniusEndDateValid(date: string):boolean {
  const dateIn1Year = moment().startOf('day').add(1, 'year')
  return !moment(date).isSameOrAfter(dateIn1Year)
}

export const getInsuranceProvider = (regionCode: string) => {
  if (isCoverGeniusEnabled(regionCode)) {
    return PROVIDER_COVER_GENIUS
  }

  return false
}

const matchAgeItem = (
  age: number,
  category: { minAge: number; maxAge: number; },
) => {
  return age >= category.minAge && age <= category.maxAge
}

const getAgeCategoryNameByAge = (age: number): 'infant' | 'child' | 'adult' => {
  const match = COVER_GENIUS_AGE_CATEGORIES.find(category => matchAgeItem(age, category))
  return match ? match.name : 'adult'
}
export const getCoverGeniusAgeCategoryByDateOfBirth = (dateOfBirth: string): 'infant' | 'child' | 'adult' => {
  const age = getAge(dateOfBirth)
  if (!age) return 'adult'
  return getAgeCategoryNameByAge(age)
}

export const providerIsCoverGenius = (regionCode: string) => (getInsuranceProvider(regionCode) === PROVIDER_COVER_GENIUS)
