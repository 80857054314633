import React, { useCallback, useContext, useMemo, useState } from 'react'
import TourV2TrendingDestinationsQuickFilters from 'components/Search/TourV2/TourV2TrendingDestinationsQuickFilters'
import styled from 'styled-components'
import { rem } from 'polished'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES, TOUR_V2_SEARCH_TYPES, tourDurationOptions } from 'constants/tours'
import LocationFloatingPanel from '../Panels/LocationFloatingPanel'
import SearchFormField from '../../SearchFormField/SearchFormField'
import { prettyFlexibleDates } from 'components/Search/SearchForm/SearchDateInput/FlexibleDateSearchField'
import DateFloatingPanel from 'components/SearchV2/Components/Mobile/Panels/DateFloatingPanel'
import useStepsController from 'components/SearchV2/Hooks/useStepsController'
import useDateSearchProps from 'components/SearchV2/Hooks/useDateSearchProps'
import TourGroupTypePanel from 'components/SearchV2/Components/Mobile/Panels/TourGroupTypePanel'
import { useGlobalToursSearchContext } from '../../../../../hooks/GlobalSearch/GlobalSearchVerticals/useGlobalToursSearch'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    &:not(:first-child) {
      margin-top: ${rem(8)};
    }
  }
`

const TrendingDestinations = styled.div`
  margin-top: ${rem(24)};
`

enum SearchSteps {
  LOCATION = 'location',
  DATE = 'date',
  GROUP_TYPE = 'group_type'
}

interface Props {
  closeSearch: () => void;
  onSearch: () => void;
}

export default function ToursTabContent(props: Props) {
  const { closeSearch, onSearch } = props

  const {
    searchItem,
    flexibleMonths,
    flexibleNights,
    userSelectedFlexibleMonths,
    tourGroupTypes = [],
  } = useContext(GlobalSearchStateContext)

  const [activeStep, setActiveStep] = useState<SearchSteps>()

  const stepsValidation = useMemo(() => ({
    [SearchSteps.LOCATION]: searchItem,
    [SearchSteps.DATE]: flexibleNights,
    [SearchSteps.GROUP_TYPE]: tourGroupTypes,
  }), [searchItem, flexibleNights, tourGroupTypes])

  const {
    goToNextStep,
    goToPreviousStep,
    closeActiveStep,
    isSearchEnabled,
  } = useStepsController({ activeStep, setActiveStep, stepsValidation, onSearch })

  const dateSearchProps = useDateSearchProps({ onAnytimeDatesSelected: onSearch, isSpecificSearchEnabled: false })

  const { globalToursSearchState } = useGlobalToursSearchContext()

  const flexibleSearchDateSelectedText =
    prettyFlexibleDates(userSelectedFlexibleMonths ? flexibleMonths : undefined, flexibleNights, tourDurationOptions)

  const openLocationSearchStep = useCallback(() => setActiveStep(SearchSteps.LOCATION), [setActiveStep])
  const openDateSearchStep = useCallback(() => setActiveStep(SearchSteps.DATE), [setActiveStep])

  return (
    <ContentWrapper>
      <LocationFloatingPanel
        isOpen={activeStep === SearchSteps.LOCATION}
        onClose={closeActiveStep}
        onGoBack={goToPreviousStep}
        onConfirm={goToNextStep}
        searchTypes={TOUR_V2_SEARCH_TYPES}
        typeaheadTypes={TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES}
        typeaheadVertical="tour"
        popularPlaces={globalToursSearchState.popularDestinations}
      />

      <DateFloatingPanel
        {...dateSearchProps}
        isOpen={activeStep === SearchSteps.DATE}
        onClose={closeActiveStep}
        onConfirm={goToNextStep}
        onGoBack={goToPreviousStep}
        confirmText={isSearchEnabled ? 'Search' : 'Apply'}
        durationOptionsCustom={tourDurationOptions}
        hasDates={false}
        hideDatePicker
        showFlexibleYearSelector
      />

      <TourGroupTypePanel
        isOpen={activeStep === SearchSteps.GROUP_TYPE}
        onClose={closeActiveStep}
        onGoBack={goToPreviousStep}
        onConfirm={goToNextStep}
      />

      <FieldsWrapper>
        <SearchFormField
          label="Where"
          placeholder="Search destination or place"
          value={searchItem?.format?.mainText ?? ''}
          onClick={openLocationSearchStep}
        />

        <SearchFormField
          label="When"
          onClick={openDateSearchStep}
          placeholder="Anytime"
          value={flexibleSearchDateSelectedText}
        />

      </FieldsWrapper>
      <TrendingDestinations>
        <TourV2TrendingDestinationsQuickFilters
          titleColour="neutral-two"
          titleWeight="normal"
          titleLabel="Popular searches"
          onDestinationClick={closeSearch}
        />
      </TrendingDestinations>
    </ContentWrapper>
  )
}
