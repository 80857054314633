import PartnershipIcon from 'components/Common/Vendors/Partnership/PartnershipIcon'
import Tooltip from 'components/Luxkit/Tooltip'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import GeoContext from 'contexts/geoContext'
import React, { PropsWithChildren, useContext, useMemo } from 'react'
import IconLabel from './IconLabel'

type LabelType = 'use-only' | 'earn-and-use' | 'earn-points' | 'earn-only';

const partnershipLabelMap = (partnership: App.Partnership, points?: number): { [key in LabelType]: String } => ({
  'use-only': `Use ${partnership.rewardName}`,
  'earn-and-use': `Earn & Use ${partnership.rewardName}`,
  'earn-points': `Earn ${points ? Math.floor(points).toLocaleString() : 0} ${partnership.rewardName}`,
  'earn-only': `Earn ${partnership.rewardName}`,
})

interface Props {
  canEarn?: boolean;
  canUse?: boolean;
  points?: number;
  className?: string;
}

function PartnershipLabel(props: PropsWithChildren<Props>) {
  const { canEarn = true, canUse = true, className, points } = props
  const { partnership } = useContext(GeoContext)

  const labelType = useMemo<LabelType | undefined>(() => {
    if (partnership) {
      const hasEarn = canEarn && partnership.hasEarn
      const hasUse = canUse && partnership.hasBurn
      if (points && partnership.code !== VIRGIN_VELOCITY_PARTNERSHIP_CODE) {
        return 'earn-points'
      } else if (hasEarn && hasUse) {
        return 'earn-and-use'
      } else if (hasEarn) {
        return 'earn-only'
      } else if (hasUse) {
        return 'use-only'
      }
    }
  }, [canEarn, canUse, partnership, points])

  const tooltip = useMemo<String | null>(() => {
    if (!partnership || !labelType) return null
    return partnershipLabelMap(partnership)[labelType]
  }, [labelType, partnership])

  if (!tooltip) {
    return null
  }

  return <Tooltip
      className={className}
      placement="right"
      description={tooltip}
    >
    <IconLabel
      kind="secondary"
      variant="ghost"
      icon={<PartnershipIcon />} />
  </Tooltip>
}

export default PartnershipLabel
