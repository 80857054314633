import config from 'constants/config'
import React from 'react'
import Label from './Label'
import {
  OFFER_TYPE_ADDON,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_VILLA,
} from 'constants/offer'
import {
  EXPERIENCE_PRODUCT_TYPE_ALWAYS_ON,
  EXPERIENCE_PRODUCT_TYPE_CHANNEL_MANAGER,
  EXPERIENCE_PRODUCT_TYPE_FLASH,
} from 'constants/experience'
import UltraLuxLogo from './UltraLuxLogo'
import { CONNECTION_TOUR, DIRECT_TOUR, PARTNER_TOUR, ULTRA_LUX_TOUR } from 'constants/tours'
import { LUXURY_ESCAPES } from 'constants/brands'
import { FLASH_HOTEL_PRODUCT_LABEL, LE_EXCLUSIVE, LE_FLASH_HOTEL_PRODUCT_LABEL, LE_LPP_PRODUCT_LABEL, LE_PARTNER, LE_RENTAL_PRODUCT_LABEL, LE_TAO_HOTEL_PRODUCT_LABEL, LME_HOTEL_PRODUCT_LABEL, LPP_PRODUCT_LABEL, LUX_BUNDLE_LABEL, RENTAL_PRODUCT_LABEL, TAO_HOTEL_PRODUCT_LABEL } from 'constants/productLabel'
import SolidFireIcon from '../Icons/solid/SolidFireIcon'
import SolidStarCircleIcon from '../Icons/solid/SolidStarCircleIcon'
import { isLPCTacticalEnabled } from 'lib/offer/offerUtils'

type Variant = 'limited-time-exclusive' |
  'premium' |
  'last-minute' |
  'partner' |
  'partner-property' |
  'experiences'|
  'exclusive' |
  'ultralux' |
  'bundle' |
  'limited-time-special' |
  'corporate' |
  'rental'

type Config = Omit<React.ComponentProps<typeof Label>, 'children'> & {
  children: (brand: App.BrandName) => React.ReactNode;
  'data-testid'?: string
}

/**
 * Mapping application product types to the variants
 * Variants are the names design refer to these as
 * so to keep this in sync with design - we keep the variant names
 * but hide the implementation away in this component
 */
export const offerTypeToProductMap: Record<App.ProductType, Variant | null> = {
  [OFFER_TYPE_HOTEL]: 'limited-time-exclusive',
  [OFFER_TYPE_LAST_MINUTE]: 'last-minute',
  [OFFER_TYPE_ALWAYS_ON]: 'premium',
  [OFFER_TYPE_BED_BANK]: 'partner-property',
  [ULTRA_LUX_TOUR]: 'ultralux',
  [DIRECT_TOUR]: 'exclusive',
  [PARTNER_TOUR]: 'partner',
  [EXPERIENCE_PRODUCT_TYPE_ALWAYS_ON]: 'experiences',
  [EXPERIENCE_PRODUCT_TYPE_FLASH]: 'exclusive',
  [EXPERIENCE_PRODUCT_TYPE_CHANNEL_MANAGER]: 'experiences',
  ultralux_hotel: 'ultralux',
  [CONNECTION_TOUR]: null,
  [OFFER_TYPE_TOUR]: null,
  [OFFER_TYPE_CRUISE]: null,
  [OFFER_TYPE_ADDON]: null,
  [OFFER_TYPE_BUNDLE_AND_SAVE]: 'bundle',
  [OFFER_TYPE_VILLA]: 'rental',
  limited_time_special: 'limited-time-special',
  corporate: 'corporate',
  transfer: null,
  car_hire: null,
  'cruise-flash': 'limited-time-exclusive',
  'cruise-tactical': 'premium',
  'cruise-exclusive': 'exclusive',
  experience: null,
}

const variantConfig: Record<Variant, Config> = {
  'limited-time-exclusive': {
    kind: 'primary',
    variant: 'exclusive',
    startIcon: <SolidStarCircleIcon />,
    children: brand => brand === LUXURY_ESCAPES ? LE_FLASH_HOTEL_PRODUCT_LABEL : FLASH_HOTEL_PRODUCT_LABEL,
  },
  premium: {
    kind: 'secondary',
    variant: 'dark',
    children: brand => brand === LUXURY_ESCAPES ? LE_TAO_HOTEL_PRODUCT_LABEL : TAO_HOTEL_PRODUCT_LABEL,
  },
  'last-minute': {
    kind: 'secondary',
    variant: 'urgency',
    children: () => LME_HOTEL_PRODUCT_LABEL,
  },
  'partner-property': {
    kind: 'secondary',
    variant: 'grey',
    children: brand => brand === LUXURY_ESCAPES ? LE_LPP_PRODUCT_LABEL : LPP_PRODUCT_LABEL,
  },
  partner: {
    kind: 'secondary',
    variant: 'grey',
    children: brand => brand === LUXURY_ESCAPES ? LE_PARTNER : null,
  },
  experiences: {
    kind: 'secondary',
    variant: 'dark',
    children: () => 'LUX Collection',
  },
  exclusive: {
    kind: 'primary',
    variant: 'dark',
    children: brand => brand === LUXURY_ESCAPES ? LE_EXCLUSIVE : null,
  },
  ultralux: {
    kind: 'secondary',
    variant: 'ghost',
    children: () => <UltraLuxLogo height={10} />,
    'data-testid': 'ultra-lux-label',
  },
  bundle: {
    kind: 'primary',
    variant: 'highlight',
    children: () => LUX_BUNDLE_LABEL,
  },
  'limited-time-special': {
    kind: 'primary',
    variant: isLPCTacticalEnabled() ? 'urgency' : 'highlight-secondary',
    startIcon: <SolidFireIcon />,
    children: () => 'Limited time special',
  },
  corporate: {
    kind: 'primary',
    variant: 'highlight-secondary',
    startIcon: <SolidStarCircleIcon />,
    children: () => 'Business Traveller Special',
  },
  rental: {
    kind: 'secondary',
    variant: 'dark',
    children: brand => brand === LUXURY_ESCAPES ? LE_RENTAL_PRODUCT_LABEL : RENTAL_PRODUCT_LABEL,
  },
}

interface Props {
  productType: App.ProductType;
  className?: string;
  kind?: 'default' | 'plain';
}

function ProductTypeLabel(props: Props) {
  const { productType, kind = 'default', ...rest } = props
  const variant = offerTypeToProductMap[productType]

  if (!variant) {
    return null
  }

  const labelConfig = variantConfig[variant]
  const content = labelConfig.children(config.BRAND)
  if (!content) {
    return null
  }

  return <Label
    {...rest}
    {...labelConfig}
    kind={kind === 'default' ? labelConfig.kind : 'plain'}
  >
    {content}
  </Label>
}

export default ProductTypeLabel
