import moment from 'moment'
import chunk from 'lodash/chunk'
import { sum, sortBy, without, arrayToObject, unique } from 'lib/array/arrayUtils'
import { CheckoutPageId } from 'checkout/constants/pages'
import { CRUISE_CHECKOUT_INFLOW_V2 } from 'constants/checkout'
import { CruiseAPI } from 'api/cruises'
import { mdyDateFormat } from 'lib/datetime/dateUtils'

const GUARANTEED_CODES = ['GUAR', 'GTY', 'G00000']

export function getSessionByCruiseItem(
  selectedCruiseItem: App.Checkout.CruiseItem | undefined,
  session: App.CruiseMultiBookingState['session'],
  cruiseItems?: Array<App.Checkout.CruiseItem>,
): Cruises.BookingSessionData {
  const cruiseItem = cruiseItems?.[0]
  const emptyData = {
    bookingId: undefined,
    sessionId: undefined,
    sharedSessionId: undefined,
    cabinHoldTimeout: undefined,
  }
  if (!cruiseItem && cruiseItems) return emptyData

  const selectedItemSession = session[selectedCruiseItem?.itemId || ''] ?? emptyData

  // When there is more than one cabin that has the same number of passengers,
  // we only make one request to the server to obtain rates and cabins, using the sessionId of the first cabin "sharedSessionId"
  const sharedSessionId = selectedItemSession.sessionId ?? cruiseItem?.sessionId ?? undefined

  return {
    ...selectedItemSession,
    sessionId: cruiseItem?.sessionId || sharedSessionId,
    sharedSessionId,
  }
}

export function getMultiCabinStatus(
  multiCabinDataMap: App.CruiseMultiBookingState['rateList']
    | App.CruiseMultiBookingState['cabinList']
    | App.CruiseMultiBookingState['cabinSelection']
    | App.CruiseMultiBookingState['cabinPricing'],
) {
  // Check if there is an error in the data of any cabin
  const cabinDataItems = Object.values(multiCabinDataMap).filter(Boolean)
  const cabinDataWithError = cabinDataItems.find(data => data.error)
  const multiCabinStatus = cabinDataWithError ? {
    isError: true,
    errorMessage: cabinDataWithError.errorMessage,
    errorToRender: cabinDataWithError.errorToRender,
  } : { isError: false }

  return multiCabinStatus
}

export function getRateListByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  rateList: App.CruiseMultiBookingState['rateList'],
): Cruises.CruiseBookingRateListDataWithStatus {
  const basicData = {
    rates: [],
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(rateList),
  }

  const rateListData = rateList[cruiseItem?.itemId!]
  if (!cruiseItem || !rateListData) return basicData

  return {
    ...basicData,
    ...rateListData,
    rates: sortBy(rateListData.rates, rate => rate.priceDetails.price, 'asc'),
  }
}

export function getCabinListByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinList: App.CruiseMultiBookingState['cabinList'],
): Cruises.BookingCabinListDataWithStatus {
  const basicData = {
    cabins: [],
    cabinByNumber: {},
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(cabinList),
  }
  const cabinListData = cabinList[cruiseItem?.itemId!]

  if (!cruiseItem || !cabinListData) return basicData

  return { ...basicData, ...cabinListData }
}

export function getCabinDetailsByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinDetails: App.CruiseMultiBookingState['cabinDetails'],
): Cruises.BookingCabinDetailsListData {
  const emptyData = { detailsByCabin: {}, errorByCabin: {}, loading: false }
  if (!cruiseItem) return emptyData

  return cabinDetails[cruiseItem.itemId] ?? emptyData
}

export function getRateDetailsByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  rateDetails: App.CruiseMultiBookingState['rateDetails'],
): Cruises.BookingRateDetailsData {
  const emptyData = { detailsByRate: {}, errorByRate: {}, loadingByRate: {} }
  if (!cruiseItem) return emptyData

  return rateDetails[cruiseItem.itemId] ?? emptyData
}

export function getCabinSelectionByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinSelection: App.CruiseMultiBookingState['cabinSelection'],
): Cruises.BookingCabinSelectionDataWithStatus {
  const basicData = {
    cabinSelection: undefined,
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(cabinSelection),
  }

  const cabinSelectionData = cabinSelection[cruiseItem?.itemId!]
  if (!cruiseItem || !cabinSelectionData) return basicData

  return { ...basicData, ...cabinSelectionData }
}

export function getCabinPricingByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinPricing: App.CruiseMultiBookingState['cabinPricing'],
): Cruises.BookingCabinPricingDataWithStatus {
  const basicData = {
    cabinPricing: undefined,
    hasCabinPricing: false,
    loading: false,
    error: false,
    multiCabinStatus: getMultiCabinStatus(cabinPricing),
  }

  const cabinPricingData = cabinPricing[cruiseItem?.itemId!]
  if (!cruiseItem || !cabinPricingData) return basicData

  return {
    ...basicData,
    ...cabinPricingData,
    hasCabinPricing: !!cabinPricingData.cabinPricing,
  }
}

export function getCabinReleaseByCruiseItem(
  cruiseItem: App.Checkout.CruiseItem | undefined,
  cabinRelease: App.CruiseMultiBookingState['cabinRelease'],
): Cruises.BookingCabinReleaseData {
  const emptyData = { released: false, error: false, loading: false }
  if (!cruiseItem) return emptyData

  return cabinRelease[cruiseItem.itemId] ?? emptyData
}

export function getMultiCabinError(
  bookingDataItems: Array<
    Cruises.CruiseBookingRateListDataWithStatus
    | Cruises.BookingCabinListDataWithStatus
    | Cruises.BookingCabinSelectionDataWithStatus
    | Cruises.BookingCabinPricingDataWithStatus
  >,
): { isError: boolean, errorMessage?: string, errorToRender?: string } {
  const bookingDataWithError = bookingDataItems.find(data => data.multiCabinStatus.isError)
  if (!bookingDataWithError) return { isError: false }

  return {
    isError: true,
    errorMessage: bookingDataWithError.multiCabinStatus.errorMessage,
    errorToRender: bookingDataWithError.multiCabinStatus.errorToRender,
  }
}

export function buildCabinsWithPrice(
  cruiseItem: App.Checkout.CruiseItem,
  cruiseItems: Array<App.Checkout.CruiseItem>,
  cabinLisData: Cruises.BookingCabinListData,
  cabinDetails: Cruises.BookingCabinDetailsListData,
  selectedRates: Array<Cruises.BookingCabinRate>,
  evergreenInclusions: Array<App.EvergreenInclusion>,
): Array<Cruises.BookingCabinWithPrice> {
  const { cabins, cabinByNumber } = cabinLisData
  const { detailsByCabin } = cabinDetails

  const cabinsWithPrice = cabins
    .map((cabin) => {
      const { cabinNumber, deckId, componentIds, connectingCabinNumber } = cabin
      const rates = selectedRates.filter(rate => componentIds.includes(rate.componentId))
      const categoryCodes = unique(rates.map(rate => rate.pricedCategoryCode))

      const lowestRate = rates[0]
      const lowestPrice = lowestRate?.priceDetails
      const lowestCategoryCode = lowestRate?.pricedCategoryCode
      const isGuaranteed = GUARANTEED_CODES.includes(cabinNumber) || !deckId
      const details = detailsByCabin[cabinNumber]

      const isByronBeachClub = evergreenInclusions.some((inclusion) => (
        inclusion.cabinTypes.includes(cruiseItem.cabinType!) &&
        inclusion.cabinCategories.some(category => categoryCodes.includes(category)) &&
        inclusion.inclusion === 'Access to Byron Beach Club'
      ))

      const connectingCabinAvailable = (
        !!connectingCabinNumber &&
        connectingCabinNumber !== '*' &&
        !!cabinByNumber[connectingCabinNumber]
      )

      const cabinItemIndex = cruiseItems.findIndex(item => item.cabinNumber === cabinNumber)
      const selectedByItemPosition = cabinItemIndex !== -1 && cruiseItems.length > 1 ? cabinItemIndex + 1 : undefined

      return {
        ...cabin,
        details,
        categoryCodes,
        lowestCategoryCode,
        isGuaranteed,
        selectedByItemPosition,
        isByronBeachClub,
        connectingCabinAvailable,
        lowestPrice,
      }
    })
    .filter(cabin => cabin.lowestPrice)

  return sortBy(cabinsWithPrice, cabin => cabin.lowestPrice.price, 'asc')
}

export function buildCabinCategoriesGroup(
  groupName: string,
  cabinCategories: Array<Cruises.BookingCabinCategory>,
): Cruises.BookingCabinCategoryGroupView {
  const lowestRatePrice = sortBy(cabinCategories, (category) => category.lowestRatePrice.price, 'asc')[0].lowestRatePrice
  const categories = cabinCategories.map(category => ({
    code: category.code,
    color: category.color ?? '',
  }))

  const { cabin, description, evergreenInclusions, images } = cabinCategories[0]
  const hasAccessibleCabin = cabinCategories.some(category => category.hasAccessibleCabin)
  const hasConnectingCabin = cabinCategories.some(category => category.hasConnectingCabin)
  const hasGuaranteedCabin = cabinCategories.some(category => category.hasGuaranteedCabin)

  /* multiples categories can have guaranteed cabin,
  * so we need to count them separately to avoid duplicates */
  const normalCabinsCount = sum(cabinCategories, category => category.cabinsCount)
  const cabinsCount = normalCabinsCount + (hasGuaranteedCabin ? 1 : 0)

  const name = groupName

  return {
    name,
    images,
    cabinsCount,
    description,
    categories,
    lowestRatePrice,
    evergreenInclusions,
    hasAccessibleCabin,
    hasConnectingCabin,
    cabin,
  }
}

export function getBookingCabinsByCategory(
  cabinsWithPrice: Array<Cruises.BookingCabinWithPrice>,
): Record<string, Array<Cruises.BookingCabinWithPrice>> {
  return cabinsWithPrice.reduce((acc, value) => {
    const { categoryCodes, ...cabin } = value

    const values = {}
    for (const categoryCode of categoryCodes) {
      values[categoryCode] = [...(acc[categoryCode] || []), cabin]
    }

    return { ...acc, ...values }
  }, {}) as Record<string, Array<Cruises.BookingCabinWithPrice>>
}

export function getCruiseDeposits(
  cabinPricingData: Cruises.BookingCabinPricingData,
): App.CruisePaymentScheduleDetails | undefined {
  const { cabinPricing } = cabinPricingData
  const paymentSchedule = cabinPricing?.pricing.paymentSchedule

  if (paymentSchedule?.firstPayment?.amount) {
    return {
      type: '',
      schedule: {
        firstPayment: paymentSchedule.firstPayment,
        finalPayment: paymentSchedule.finalPayment,
      },
    } as App.CruisePaymentScheduleDetails
  }
}

// Group cabins by cabin number and merge the componentIds
export function groupCabinListByNumber(
  cabins: Array<Cruises.CruiseBookingCabin>,
  newCabins: Array<Cruises.CruiseBookingCabin>,
): Record<string, Cruises.CruiseBookingCabin> {
  const cabinByNumber = arrayToObject(cabins, (cabin) => cabin.cabinNumber)

  newCabins.forEach((newCabin) => {
    const cabin = cabinByNumber[newCabin.cabinNumber]

    if (cabin) {
      cabinByNumber[newCabin.cabinNumber] = {
        ...cabin,
        componentIds: unique([...cabin.componentIds, newCabin.categoryId]),
      }
    } else {
      cabinByNumber[newCabin.cabinNumber] = {
        ...newCabin,
        componentIds: [newCabin.categoryId],
      }
    }
  })

  return cabinByNumber
}

export function checkIfNeedsToReleaseCabin(cruiseItem: App.Checkout.CruiseItem): boolean {
  return (
    !!cruiseItem.sessionId &&
    !!cruiseItem.bookingId &&
    // This prevents releasing a cabin from a sessionID from another browser tab
    cruiseItem.sessionId === sessionStorage.getItem('cruiseSessionID')
  )
}

// BOOKING DATA FETCH PARAMS
export function buildCabinListFetchParams(
  cruiseItem: App.Checkout.CruiseItem,
  sessionData: Cruises.BookingSessionData,
  rateListData: Cruises.CruiseBookingRateListData,
  cabinListData: Cruises.BookingCabinListData,
  inFlowCheckoutStep: string | undefined,
) {
  const { sessionId } = sessionData
  const { rates } = rateListData
  const { cabins, loading, error } = cabinListData
  const cabinType = cruiseItem.cabinType
  const cabinCodes = cruiseItem.cabinCodes ?? []
  const isV2 = cruiseItem.version === 'v2'

  if (isV2 && cabinType && rates.length && sessionId && !loading && !error) {
    const isCabinCategoryStep = inFlowCheckoutStep === CRUISE_CHECKOUT_INFLOW_V2.CABIN_CATEGORY
    const isPaymentStep = inFlowCheckoutStep === CheckoutPageId.Purchase

    const categoryRates = rates
      .filter((rate) => {
        /* If there are categories selected in the item and it is not in the categories step.
         * it only loads the cabins of the selected categories */
        if (!isCabinCategoryStep && !cabinCodes?.includes(rate.pricedCategoryCode)) {
          return false
        }

        /* If the user is in the payment step, only load cabins from the componentId of the selected rate,
         * This avoids loading unnecessary data when refreshing the page */
        if (isPaymentStep && rate.componentId !== cruiseItem.componentId) {
          return false
        }

        return rate.cabinType === cabinType
      })
      .map((rate) => rate.componentId)

    const categoryRatesLoaded = [...new Set(cabins.flatMap((cabin: Cruises.CruiseBookingCabin) => cabin.componentIds))]
    const categoryRatesToLoad = without(categoryRates, ...categoryRatesLoaded)

    const PARALLEL_REQUESTS = 3
    const RATES_PER_REQUEST = 5
    const cabinGroupsToLoad = chunk(categoryRatesToLoad, RATES_PER_REQUEST).slice(0, PARALLEL_REQUESTS)
    if (cabinGroupsToLoad.length) {
      return cabinGroupsToLoad
    }
  }

  return undefined
}

export function buildCabinDetailsFetchParams(
  cruiseItem: App.Checkout.CruiseItem,
  sessionData: Cruises.BookingSessionData,
  cabinDetailsData: Cruises.BookingCabinDetailsListData,
  cabinListData: Cruises.BookingCabinListData,
  inFlowCheckoutStep: string | undefined,
) {
  const { sessionId } = sessionData
  const { detailsByCabin, errorByCabin, loading } = cabinDetailsData
  const { cabins } = cabinListData
  const isV2 = cruiseItem.version === 'v2'
  const cabinCodes = cruiseItem.cabinCodes ?? []
  const stepsToLoad = [CRUISE_CHECKOUT_INFLOW_V2.CABIN_CATEGORY, CRUISE_CHECKOUT_INFLOW_V2.CABIN_SELECTION]

  if (isV2 && sessionId && cabinCodes.length && !loading && stepsToLoad.includes(inFlowCheckoutStep || '')) {
    const cabinsWithUnavailableDetails = cabins
      .filter(({ isCabinInfoAvailable }) => !isCabinInfoAvailable)
      .map(({ cabinNumber }) => cabinNumber)

    const cabinsWithError = Object.keys(errorByCabin)
    const cabinNumbers = cabins.map(({ cabinNumber }) => cabinNumber)
    const cabinDetailsLoaded = Object.keys(detailsByCabin)

    const cabinDetailsToLoad = without(
      cabinNumbers,
      ...cabinDetailsLoaded,
      ...cabinsWithUnavailableDetails,
      ...cabinsWithError,
    )

    const PARALLEL_REQUESTS = 3
    const CABINS_PER_REQUEST = 10
    const cabinDetailGroupsToLoad = chunk(cabinDetailsToLoad, CABINS_PER_REQUEST).slice(0, PARALLEL_REQUESTS)
    if (cabinDetailGroupsToLoad.length) {
      return cabinDetailGroupsToLoad
    }
  }

  return undefined
}

export function buildCabinSelectionFetchParams(
  cruiseItem: App.Checkout.CruiseItem,
  sessionData: Cruises.BookingSessionData,
  rateListData: Cruises.CruiseBookingRateListData,
  cabinSelectionData: Cruises.BookingCabinSelectionData,
  rateDetailsData: Cruises.BookingRateDetailsData,
  departures: Array<App.CruiseDepartureDetails>,
  currentStepId: string,
) {
  const { sessionId, bookingId } = sessionData
  const { rates } = rateListData
  const { cabinSelection, loading, error } = cabinSelectionData
  const { detailsByRate } = rateDetailsData
  const details = detailsByRate[cruiseItem.componentId!]
  const cabinRate = rates.find(rate => rate.componentId === cruiseItem.componentId)
  const departure = departures.find(departure => departure.id === cruiseItem.departureId)
  const isV2 = cruiseItem.version === 'v2'

  if (
    isV2 &&
    !cabinSelection &&
    !loading &&
    !error &&
    rates.length &&
    sessionId &&
    bookingId &&
    cruiseItem.cabinNumber &&
    cruiseItem.componentId &&
    currentStepId === CheckoutPageId.Purchase &&
    cabinRate &&
    departure
  ) {
    const departurePrices = departure.lowestOverallPriceDetails
    return {
      categoryId: cruiseItem.componentId,
      cabinNumber: cruiseItem.cabinNumber,
      departureId: cruiseItem.departureId,
      cabinCode: cruiseItem.cabinCode,
      sessionId,
      bookingId,
      isNccfIncluded: departurePrices?.isNccfIncluded,
      isTaxIncluded: departurePrices?.isTaxIncluded,
      rateCodeDescription: cabinRate.rateCodeDescription,
      ...(details?.rawRateDetails && { rateCodeDetails: details.rawRateDetails }),
    }
  }

  return undefined
}

export function buildBookingCabinPricingParams(
  cruiseItem: App.Checkout.CruiseItem,
  preferences: App.CruiseCabinPreferences | undefined,
  travellerForms: Array<App.Checkout.TravellerForm>,
  personTitles: Array<Cruises.BookingPersonTitles>,
): Omit<CruiseAPI.CruiseCabinPricingBody, 'region' | 'currencyCode'> {
  const bookingId = cruiseItem.bookingId as string
  const departureId = cruiseItem.departureId
  const sessionId = cruiseItem.sessionId as string

  const vendorOptions = {
    ...(preferences?.diningOption && { dining: preferences.diningOption }),
    ...(preferences?.bedConfiguration && { bedConfiguration: preferences.bedConfiguration }),
    ...(preferences?.insurance && { insurance: preferences.insurance }),
    ...(preferences?.prepaidGratuity && { prepaidGratuities: preferences.prepaidGratuity }),
    ...(preferences?.tableSize && { tableSize: preferences.tableSize }),
  } as Cruises.BookingCabinPreferences

  const passengers = buildBookingCabinPricingPassengers(travellerForms, personTitles)

  return {
    bookingId,
    departureId,
    sessionId,
    passengers,
    vendorOptions,
  }
}

function buildBookingCabinPricingPassengers(
  travellerForms: Array<App.Checkout.TravellerForm>,
  personTitles: Array<Cruises.BookingPersonTitles>,
): CruiseAPI.CruiseCabinPricingBody['passengers'] {
  const titleGenderMap = buildBookingTitleGenderMap(personTitles)

  const phoneNumber = travellerForms[0].phonePrefix ?? `${travellerForms[0].prefix}${travellerForms[0].phone}`
  const emailAddress = travellerForms[0].email

  const passengers = travellerForms.reduce((acc, travellerForm, index) => {
    const passenger = {
      type: buildBookingTravellerType(travellerForm.id),
      gender: titleGenderMap[travellerForm.title],
      firstName: travellerForm.firstName,
      middleName: travellerForm.middleName,
      lastName: travellerForm.lastName,
      dateOfBirth: mdyDateFormat(new Date(travellerForm.dateOfBirth)),
      citizenshipCountry: travellerForm.countryOfResidence,
      title: travellerForm.title,
      pastPassengerNumber: travellerForm.pastPassengerNumber,
      contactInfo: {
        emailAddress,
        phoneNumber,
        ...(travellerForm.address && {
          address: {
            address1: travellerForm.address,
            city: travellerForm.city,
            state: travellerForm.state,
            postalCode: travellerForm.postcode,
            country: travellerForm.countryOfResidence,
          },
        }),
      },
    }
    return {
      ...acc,
      [index + 1]: passenger,
    }
  }, {} as CruiseAPI.CruiseCabinPricingBody['passengers'])

  return passengers
}

function buildBookingTitleGenderMap(personTitles: Array<App.CruisePersonTitle>): Cruises.BookingTitleGender {
  const titleGenderMap = {
    Sr: 'M',
    Mstr: 'M',
    Mr: 'M',
    Ms: 'F',
    Mrs: 'F',
    Miss: 'F',
  } as Cruises.BookingTitleGender

  const personTitleGenders = (personTitles ?? []).reduce((acc, curr) => {
    acc[curr.code] = titleGenderMap[curr.code] ?? 'M'
    return acc
  }, {} as Cruises.BookingTitleGender)

  return personTitles ? personTitleGenders : titleGenderMap
}

export function buildBookingTravellerType(key: string): 'ADT' | 'CHD' {
  return key.includes('adult') ? 'ADT' : 'CHD'
}

type CabinLowestPrice = {
  price: number;
  cabinCategory: string;
}

function getLowerCabinPrice(
  currentLowestPrice: CabinLowestPrice | undefined,
  cabin: Cruises.BookingCabinWithPrice,
) {
  if (
    !currentLowestPrice ||
    cabin.lowestPrice.price < currentLowestPrice.price
  ) {
    return {
      price: cabin.lowestPrice.price,
      cabinCategory: cabin.lowestCategoryCode,
    }
  }

  return currentLowestPrice
}

export function getCabinLowestPrice(
  cabins: Array<Cruises.BookingCabinWithPrice>,
): CabinLowestPrice | undefined {
  if (!cabins.length) return

  return cabins.reduce(getLowerCabinPrice, undefined)
}

export function setDeckLowestPrice(decks: Array<App.CruiseDeck>): Array<App.CruiseDeck> {
  const lowestPriceDeck: App.CruiseDeck = decks
    .filter((deck) => !!deck.cabinLowestPrice)
    .reduce((acc, deck) => {
      if (!deck.cabinLowestPrice || !acc.cabinLowestPrice) return deck
      return deck.cabinLowestPrice < acc.cabinLowestPrice ? deck : acc
    }, decks[0])

  if (!lowestPriceDeck.cabinLowestPrice) return decks

  return decks.map((deck) => ({
    ...deck,
    isDeckWithLowestPrice: deck.deckId === lowestPriceDeck.deckId,
  }))
}

export function buildCruiseCancellationPolicies(
  cancellationPolicies: Array<App.PricingCancellationSchedule>,
  isNonRefundable: boolean = true,
): Cruises.CruiseCancellationPolicy {
  const policies = cancellationPolicies.map(policy => ({
    amount: policy.amount,
    date: new Date(policy.date),
    isPercentage: policy.isPercentage,
    isNonRefundable: 'isNonRefundable' in policy ? !!policy.isNonRefundable : isNonRefundable,
  }))

  // ordering by date (asc)
  const orderedPolicies = policies.sort((firstItem, secondItem) => {
    return moment(firstItem.date).diff(moment(secondItem.date))
  })

  if (!orderedPolicies.length) {
    return {
      isRefundable: !isNonRefundable,
      cancellationPolicies: [],
      soonerPoliceHasValidDate: false,
    }
  }

  // check if sooner date has at least 10 days from now
  const soonerPoliceHasValidDate = moment(orderedPolicies[0].date).diff(moment(), 'days') >= 10

  return {
    soonerPoliceHasValidDate,
    cancellationPolicies: orderedPolicies,
    isRefundable: !orderedPolicies.some(policy => policy.isNonRefundable),
  }
}
