import React from 'react'

function childrenAreEmpty(children: React.ReactNode): boolean {
  return Array.isArray(children) ?
    children.every(childrenAreEmpty) :
      !children
}

type Props<T extends Utils.TagOrComponent> = {
  as?: T;
  children: React.ReactNode;
} & Omit<Utils.PropsOfTagOrComponent<T>, 'as'>

type PropsWithStandardRef<T extends Utils.TagOrComponent> = Props<T> & { ref?: React.Ref<Utils.ElementTypeOfTagOrComponent<T>> }

function OnlyIfChildrenComponent<T extends Utils.TagOrComponent = 'div'>(
  props: Props<T>,
  ref: React.Ref<Utils.ElementTypeOfTagOrComponent<T>>,
) {
  const {
    as: Component = 'div',
    children,
    ...restProps
  } = props

  // The `any` here isn't ideal, but the dynamic prop typing makes things tricky
  return childrenAreEmpty(children) ? null : <Component {...restProps as any} ref={ref}>{children}</Component>
}

const OnlyIfChildren: <T extends Utils.TagOrComponent = 'div'>(props: PropsWithStandardRef<T>) => JSX.Element | null = React.forwardRef(OnlyIfChildrenComponent)

export default OnlyIfChildren
