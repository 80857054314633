import React, { useContext } from 'react'
import GeoContext from 'contexts/geoContext'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import { isVelocityEarnEnabled } from 'lib/payment/paymentUtils'

function isInvalid<T>(types: Array<T>, target: T): boolean {
  return types.length > 0 && !types.includes(target)
}

interface Props {
  className?: string;
  offer: App.Offer | App.OfferSummary;
}

function OfferPartnershipLabel(props: Props) {
  const {
    offer,
    className,
  } = props

  const { partnership } = useContext(GeoContext)

  if (!partnership ||
    (partnership.availableForOfferType && isInvalid(partnership.availableForOfferType, offer.type)) ||
    (partnership.availableForProductType && isInvalid(partnership.availableForProductType, offer.productType))
  ) {
    return null
  }

  const isVelocity = partnership?.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE
  const { canEarn, canBurn } = isVelocity ? { canEarn: isVelocityEarnEnabled(offer), canBurn: true } : { canEarn: offer.canEarnPartnershipPoints, canBurn: undefined }

  return <PartnershipLabel
    canEarn={canEarn}
    canUse={canBurn}
    className={className}
  />
}

export default React.memo(OfferPartnershipLabel)
