import React, { useCallback, useMemo } from 'react'
import { goBack } from 'connected-react-router'
import { shallowEqual } from 'react-redux'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineArrowLeftIcon from 'components/Luxkit/Icons/line/LineArrowLeftIcon'
import { setInFlowCheckoutStep } from 'actions/CruiseActions'
import { getCruiseCurrentStep, CRUISE_CHECKOUT_STEPS_V2 } from 'components/App/Header/HeaderCheckout/StepsHeaderCheckout/steps/cruises'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

interface Props {
  className?: string
}

function CruiseCheckoutBackButton({ className }: Props) {
  const dispatch = useAppDispatch()

  const {
    location,
    inFlowCheckoutStep,
  } = useAppSelector((state) => ({
    location: state.router.location,
    inFlowCheckoutStep: state.cruise.inFlowCheckoutStep,
  }), shallowEqual)

  const currentCheckoutStep = useMemo(
    () => getCruiseCurrentStep(inFlowCheckoutStep, location.pathname),
    [inFlowCheckoutStep, location.pathname],
  )

  const handleBack = useCallback(() => {
    const flatSteps = CRUISE_CHECKOUT_STEPS_V2.flatMap((step) => step.subSteps?.length ? step.subSteps : step)
    const stepIndex = flatSteps.findIndex((step) => step.step === currentCheckoutStep)
    const currentStep = flatSteps[stepIndex]
    const prevStep = flatSteps[stepIndex - 1]

    if (prevStep && !prevStep.isRoute) {
      dispatch(setInFlowCheckoutStep(prevStep.step))
    }

    if (!prevStep || prevStep.isRoute || currentStep.isRoute) {
      dispatch(goBack())
    }
  }, [currentCheckoutStep, dispatch])

  return <TextButton
    className={className}
    kind="tertiary"
    outdent="left"
    startIcon={<LineArrowLeftIcon />}
    variant="ghost"
    onClick={handleBack}
  >
    Back
  </TextButton>
}

export default CruiseCheckoutBackButton
