import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId, getCruiseTourV2OrExperienceOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { last } from 'lib/array/arrayUtils'
import moment from 'moment'

function getCruiseCheckoutItem(state: App.State, item: App.Checkout.CruiseItem) {
  const offerId = item.offerId
  const offer = getCruiseTourV2OrExperienceOffer(state, offerId) as App.CruiseOffer

  const { categoryId, productId } = getCategoryAndProductId(item.itemType, offer)

  const offerLeadPrice = offer?.lowestDeparturePrice?.total
  const departurePort = offer?.departurePort
  const destinationPort = offer?.returnPort
  const originCity = departurePort ? departurePort.split(', ')[0] : undefined
  const originCountry = departurePort ? last(departurePort.split(', ')) : undefined
  const destinationCity = destinationPort ? destinationPort.split(', ')[0] : undefined
  const destinationCountry = destinationPort ? last(destinationPort.split(', ')) : undefined
  const travelStart = item.startDate
  const travelEnd = item.endDate

  return comluxgroup.createItem_1_1_0(
    {
      categoryId,
      productId,
      offerId,
      duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : undefined,
      travelStart,
      travelEnd,
      numberOfAdults: item.occupancy.adults,
      numberOfChildren: item.occupancy.children,
      numberOfInfants: item.occupancy.infants,
      childrenAges: item.occupancy.childrenAge,
      price: item.ratePrices?.price,
      offerLeadPrice,
      offerLeadDuration: offer?.duration ? String(offer?.duration) : undefined,
      offerLeadUnit: offer?.saleUnit,
      destinationCity,
      destinationCountry,
      originCity,
      originCountry,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
    },
  )
}

export default getCruiseCheckoutItem
