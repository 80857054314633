import moment from 'moment'

import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { isStandaloneExperience, isOnlyExperiencesOrder } from 'lib/experiences/experienceUtils'

function afterToday(date: string) {
  return moment.utc(date, ISO_DATE_FORMAT).isSameOrAfter(moment.utc(), 'day')
}

function inThePast(date: string) {
  return moment.utc(date, ISO_DATE_FORMAT).isBefore(moment.utc(), 'day')
}

function isBetween(start: string, end: string) {
  return moment().isBetween(moment(start), moment(end), 'day')
}

export function isItemUpcoming(item: App.OrderItem) {
  if (item.status === 'cancelled') {
    return false
  }

  if (!item.reservationMade || !item.reservation) {
    return !(item.offer.bookByDate && inThePast(item.offer.bookByDate))
  }

  return afterToday(item.reservation.startDate)
}

export function isItemBedbankUpcoming(item: App.OrderBedbankItem) {
  if (item.status === 'cancelled') {
    return false
  }

  return afterToday(item.checkIn.format(ISO_DATE_FORMAT))
}

export function isItemTourUpcoming(item: Tours.TourV2OrderItem) {
  if (item.status === 'cancelled') {
    return false
  }

  return afterToday(moment(item.tour.startDate).format(ISO_DATE_FORMAT))
}

function isInsuranceUpcoming(item: App.OrderInsuranceItem) {
  return afterToday(item.endDate)
}

function isFlightUpcoming(item: App.OrderFlightItem) {
  return item.departureDate ? afterToday(item.departureDate) : false
}

function isItemCurrent(item: App.OrderItem) {
  if (item.status === 'cancelled') {
    return false
  }

  if (item.reservation) {
    return isBetween(item.reservation.startDate, item.reservation.endDate)
  }
  return false
}

function isItemBedbankCurrent(item: App.OrderBedbankItem) {
  if (item.status === 'cancelled') {
    return false
  }

  return isBetween(item.checkIn, item.checkOut)
}

function isInsuranceCurrent(item: App.OrderInsuranceItem) {
  if (item.status === 'cancelled') {
    return false
  }

  return isBetween(item.startDate, item.endDate)
}

function isFlightCurrent(item: App.OrderFlightItem) {
  if (item.status === 'cancelled') {
    return false
  }

  return item.departureDate && item.arrivalDate ? isBetween(item.departureDate, item.arrivalDate) : false
}

// this always returns true for open date standalone experiences but correct way to classify this is by markedAsRedeemed, but that is not available in the order object
export function isExperienceItemUpcoming(item: App.OrderExperienceItem, order: App.Order) {
  if (item.status === 'cancelled') return false

  // this can be an open date experiences as well, but we consider it as not upcoming if the expiry date in the past with status as completed
  if (isExperienceItemExpired(item)) {
    return false
  }

  // always consider standalone open date experiences as upcoming. If non-standalone return false so isUpcoming function will check the other items
  // the correct way to classify this is by markedAsRedeemed, but that is not available in the order object
  if (!item.date) return isStandaloneExperience(item, order)

  return afterToday(item.date)
}

export function isCustomOfferActive(item: App.CustomOfferItem) {
  if (item.status === 'cancelled') {
    return false
  }
  if (item.custom_offer && item.custom_offer.items.length > 0 && item.custom_offer.items[0].end_date) {
    return afterToday(item.custom_offer.items[0].end_date)
  }
  return true
}

export function isTransferItemUpcoming(item: App.OrderTransferItem) {
  return item.status !== 'cancelled' && afterToday(item.date)
}

export function isExperienceItemExpired(item: App.OrderExperienceItem) {
  return item.status === 'completed' && item.expirationDate && inThePast(item.expirationDate)
}

export function isExperienceItemCurrent(item: App.OrderExperienceItem) {
  return item.status !== 'cancelled' && item.date && moment(item.date).isSame(moment(), 'day') && !isExperienceItemExpired(item)
}

export function isTransferItemCurrent(item: App.OrderTransferItem) {
  return item.status !== 'cancelled' && item.date && moment(item.date).isSame(moment(), 'day')
}

export function isCustomOfferItemCurrent(item: App.CustomOfferItem) {
  if (item.status === 'cancelled') {
    return false
  }

  if (item.custom_offer && item.custom_offer.items.length > 0 && item.custom_offer.items[0].end_date && item.custom_offer.items[0].start_date) {
    return isBetween(moment(item.custom_offer.items[0].start_date).format(ISO_DATE_FORMAT), moment(item.custom_offer.items[0].end_date).format(ISO_DATE_FORMAT))
  }
  return true
}

export function isItemTourCurrent(item: Tours.TourV2OrderItem) {
  if (item.status === 'cancelled') {
    return false
  }

  return isBetween(moment(item.tour.startDate).format(ISO_DATE_FORMAT), moment(item.tour.endDate).format(ISO_DATE_FORMAT))
}

export function isCruiseItemUpcoming(item: App.CruiseOrderItem) {
  if (item.status === 'cancelled') return false
  return afterToday(moment(item.departureDate).format(ISO_DATE_FORMAT))
}

export function isCarHireItemUpcoming(item: App.CarHireOrderItem) {
  if (item.status === 'cancelled' || !item.reservation?.pickUp?.date) return false
  return afterToday(moment(item.reservation?.pickUp?.date).format(ISO_DATE_FORMAT))
}

export function isCruiseItemCurrent(item: App.CruiseOrderItem) {
  if (item.status === 'cancelled') return false
  return isBetween(moment(item.departureDate).format(ISO_DATE_FORMAT), moment(item.arrivalDate).format(ISO_DATE_FORMAT))
}

export function isCarHireItemCurrent(item: App.CarHireOrderItem) {
  if (item.status === 'cancelled' || !item.reservation?.pickUp?.date) return false
  return isBetween(moment(item.reservation?.pickUp?.date).format(ISO_DATE_FORMAT), moment(item.reservation?.dropOff?.date).format(ISO_DATE_FORMAT))
}

export function isOrderCurrent(order: App.Order) {
  return order.items.some(isItemCurrent) ||
    order.bedbankItems.some(isItemBedbankCurrent) ||
    order.insuranceItems.some(isInsuranceCurrent) ||
    order.flightItems.some(isFlightCurrent) ||
    order.tourItems.some(isItemTourCurrent) ||
    order.experienceItems.some(isExperienceItemCurrent) ||
    order.cruiseItems.some(isCruiseItemCurrent) ||
    order.carHireItems.some(isCarHireItemCurrent) ||
    order.transferItems.some(isTransferItemCurrent) ||
    order.customOfferItems.some(isCustomOfferItemCurrent)
}

// Reusing logic to determine if Velocity post order eligibility
export function isUpcomingForPartnerVelocityEarnEligibility(order: App.Order) {
  return order.items.some(isItemUpcoming) ||
    order.tourItems.some(isItemTourUpcoming) ||
    order.experienceItems.some(item => isExperienceItemUpcoming(item, order)) ||
    order.cruiseItems.some(isCruiseItemUpcoming)
}

// this always returns true for open date standalone experiences but correct way to classify this is by markedAsRedeemed, but that is not available in the order object
export default function isUpcoming(order: App.Order) {
  return order.items.some(isItemUpcoming) ||
    order.bedbankItems.some(isItemBedbankUpcoming) ||
    order.insuranceItems.some(isInsuranceUpcoming) ||
    order.flightItems.some(isFlightUpcoming) ||
    order.tourItems.some(isItemTourUpcoming) ||
    order.experienceItems.some(item => isExperienceItemUpcoming(item, order)) ||
    order.cruiseItems.some(isCruiseItemUpcoming) ||
    order.carHireItems.some(isCarHireItemUpcoming) ||
    order.transferItems.some(isTransferItemUpcoming) ||
    order.customOfferItems.some(isCustomOfferActive)
}

export function isOrderPast(order: App.Order) {
  return !isUpcoming(order) && !isOrderCurrent(order)
}

export function isOnlyExperiencesOrderUpcoming(order: App.Order) {
  if (isOnlyExperiencesOrder(order)) {
    return order.experienceItems.some(item => {
      if (item.status === 'cancelled') return false

      if (isExperienceItemExpired(item)) {
        return false
      }

      if (!item.date) return true

      return afterToday(item.date)
    })
  }

  return false
}
