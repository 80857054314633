import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { getItemUniqueKey, getPackageId } from '../accommodation/cart'
import config from 'constants/config'
import { generateCheckoutItemViewOfferCreditKey } from '../businessTraveller/cart'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { dateIsAfter, dateIsBefore, isBookingWithinDateRange } from 'lib/datetime/dateUtils'
import { sum } from 'lib/array/arrayUtils'

function dummyBedbankItemView(
  item: App.Checkout.BedbankHotelItem,
  kind: 'bedbank',
): App.Checkout.BedbankAccommodationItemView {
  return {
    kind,
    packageId: getPackageId(item),
    uniqueKey: getItemUniqueKey(item),
    item,
    image: {},
    mainLabel: '',
    occupancy: { adults: 0, children: 0, infants: 0 },
    removeLabel: 'Remove',
    soldOut: true,
    price: 0,
    memberPrice: 0,
    value: 0,
    surcharge: 0,
    taxesAndFees: 0,
  }
}

export function getBedbankItemView(
  item: App.Checkout.BedbankHotelItem,
  offer?: App.BedbankOffer,
  rate?: App.BedbankRate,
  offerCredits?: Record<string, BusinessTraveller.StatefulData<BusinessTraveller.OfferCredit>>,
  postPurchase?: App.PostPurchase,
  existingOrder?: App.Order,
  ratesFetched?: boolean,
): App.WithDataStatus<App.Checkout.BedbankAccommodationItemView> {
  if (!offer) {
    return {
      hasRequiredData: false,
      data: dummyBedbankItemView(item, 'bedbank'),
    }
  }

  // no rate means no availability
  if (!rate) {
    return {
      hasRequiredData: postPurchase === 'change-dates' ? !!ratesFetched : false,
      data: dummyBedbankItemView(item, 'bedbank'),
    }
  }

  const pricing = rate.occupancyPricing.find(pricing =>
    pricing.occupancy === generateOccupancyStringByRoom(item.occupancy),
  ) || rate.occupancyPricing[0]

  const pkg = offer.packages.find(pkg => pkg.roomId === item.roomId)

  const hasPromo = offer.promotions.length > 0 && item.duration >= offer.promotions[0].los

  const todayWithinOfferPeriod = hasPromo && dateIsAfter(new Date(), new Date(offer.promotions[0].periodSchedule.from)) &&
  dateIsBefore(new Date(), new Date(offer.promotions[0].periodSchedule.to))

  const includePromoInclusions = (offer.type === OFFER_TYPE_BED_BANK) ?
    hasPromo && todayWithinOfferPeriod &&
    isBookingWithinDateRange(item.checkIn, item.checkOut, offer.promotions[0].travelPeriod.from, offer.promotions[0].travelPeriod.to) :
    true

  function getInclusions() {
    if (includePromoInclusions) {
      return offer?.promotions?.find(p => pkg?.promotionId === p.id)?.rateInclusionsLong
    }

    if (rate?.facilities) {
      return rate.facilities
        .concat(rate.promotions).map(item => '- ' + item)
        .join('\n')
    }
    return ''
  }

  // 431.84 + 56.14 = 487.97999999999996
  const price = Number(((Math.round(pricing.exclusive * 100) + Math.round(pricing.taxesAndFees * 100)) / 100).toFixed(2))
  const soldOut = !price

  const itemTotals: App.Checkout.ItemTotals = {
    price,
    memberPrice: 0,
    value: pricing.inclusive,
    surcharge: 0,
    taxesAndFees: pricing.taxesAndFees ?? 0,
    otherFees: {
      propertyFees: pricing.propertyFees ?? 0,
    },
    paidPrice: 0,
    newPrice: 0,
    newSurcharge: 0,
    newExtraGuestSurcharge: 0,
  }

  if (postPurchase == 'change-dates' && 'orderItemId' in item && existingOrder) {
    const orderItem = existingOrder.bedbankItems.find(orderItem => orderItem.itemId === item.orderItemId)

    if (orderItem) {
      const activeRooms = orderItem.rooms.filter(room => room.status === 'booked')
      const roomsLength = activeRooms.length
      // subtracting old room price from new room, each room is a checkout item so we need to divide sum by room.length
      const orderTotal = Math.round(sum(activeRooms.map(room => room.price)) * 100) / roomsLength

      itemTotals.paidPrice = sum(activeRooms.map(room => room.price))
      itemTotals.newPrice = price

      const priceDiff = Number(((Math.round(price * 100) - orderTotal) / 100).toFixed(2))

      itemTotals.price = priceDiff >= 0 ? priceDiff : 0

      // clear those prices that we don't want to display
      itemTotals.value = 0
      itemTotals.taxesAndFees = 0
      itemTotals.otherFees!.propertyFees = 0
    }
  }

  const data: App.Checkout.BedbankAccommodationItemView = {
    kind: 'bedbank',
    packageId: item.roomId,
    uniqueKey: item.roomId,
    item,
    mainLabel: pkg?.name ?? '',
    image: pkg?.images[0] ?? offer.image,
    occupancy: item.occupancy,
    rate,
    removeLabel: 'Remove room',
    ...itemTotals,
    inclusions: getInclusions(),
    soldOut,
  }

  let hasRequiredData = true

  if (config.businessTraveller.currentAccountMode === 'business' && offerCredits) {
    const offerCreditKey = generateCheckoutItemViewOfferCreditKey(data)
    const offerCredit = offerCredits[offerCreditKey]
    if (offerCredit?.status === 'success') {
      data.businessTravellerCredits = offerCredit.creditValue
    } else {
      hasRequiredData = false
    }
  }

  return {
    hasRequiredData,
    data,
  }
}
