import React, { useMemo, MouseEventHandler } from 'react'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { connect } from 'react-redux'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import FormatCurrency from 'components/Common/FormatCurrency'

interface MappedProps {
  subscription: App.ProductSubscription;
}

interface Props extends MappedProps {
  onTsAndCsLinkClick?: MouseEventHandler<HTMLAnchorElement> | (() => void)
  type?: 'withPrice' | 'firstTimeDiscounted'
}

/**
 * LuxPlus subscription terms and conditions inside a `<BodyText>`.
 */
function LuxPlusSubscriptionTsAndCs(props: Props) {
  const { onTsAndCsLinkClick = noop, type, subscription } = props

  const defaultTsAndCs = useMemo(() => <>
    Your {LUXURY_PLUS.PROGRAM_NAME} annual membership renews on your sign-up anniversary date.{' '}
    You can cancel your renewal anytime.{' '}
    <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
  </>, [onTsAndCsLinkClick])

  const copy = useMemo<JSX.Element>(() => {
    switch (type) {
      case 'withPrice':
        return <>
          {subscription.error && defaultTsAndCs}
          {subscription.fetching && <LoadingIndicator />}
          {subscription.offers && <>
            Your {LUXURY_PLUS.PROGRAM_NAME} <FormatCurrency value={subscription.offers[0].price} format="rounded" /> annual membership renews on your sign-up anniversary date.{' '}
            You can cancel your renewal anytime. <FormatCurrency value={subscription.offers[0].joinFee} format="rounded" /> one-off joining fee will be waived when you purchase this offer.{' '}
            <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink>
          </>}
        </>
      case 'firstTimeDiscounted':
        return <>
          {subscription.error && defaultTsAndCs}
          {subscription.fetching && <LoadingIndicator />}
          {subscription.offers && <>*Discount applies to first year of membership. After that, {LUXURY_PLUS.PROGRAM_NAME} renews at <FormatCurrency value={subscription.offers[0].price} />{' '}
            annually on your sign-up anniversary. You can cancel your renewal anytime. The <FormatCurrency value={subscription.offers[0].joinFee} format="rounded" /> one-off joining{' '}
            fee will be waived when you book this offer. <TextLink onClick={onTsAndCsLinkClick} weight="regular" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply.</TextLink></>}
        </>
      default:
        return defaultTsAndCs
    }
  }, [defaultTsAndCs, onTsAndCsLinkClick, subscription.error, subscription.fetching, subscription.offers, type])

  return <BodyText variant="small" colour="neutral-one" data-testid="luxury-plus-subscription-ts-and-cs">
    {copy}
  </BodyText>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  subscription: state.loyalty.products.subscription,
})

export default connect(mapStateToProps)(LuxPlusSubscriptionTsAndCs)
