import React, { ComponentProps, PropsWithChildren, forwardRef } from 'react'
import BaseFloatingDropdown from '../BaseFloatingDropdown'
import DropdownListSheet from './DropdownListSheet'

type InheritedSheetProps = Pick<ComponentProps<typeof DropdownListSheet>, 'size'>
type InheritedFloatingDropdownProps = Pick<ComponentProps<typeof BaseFloatingDropdown>, 'placement' | 'disableFlip' | 'anchorRef' | 'boundaryRef' | 'triggerRef' | 'open' | 'portaled' | 'onClose' | 'id' | 'data-testid'>

interface Props extends PropsWithChildren, InheritedSheetProps, InheritedFloatingDropdownProps { }

const DropdownListBase = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    size,
    placement,
    open,
    anchorRef,
    boundaryRef,
    triggerRef,
    'data-testid': dataTestId,
    id,
    onClose,
    portaled,
  } = props

  return <BaseFloatingDropdown
    ref={ref}
    width={size === 'fill-anchor' ? 'match-anchor' : 'auto'}
    placement={placement}
    anchorRef={anchorRef}
    boundaryRef={boundaryRef}
    triggerRef={triggerRef}
    open={open}
    disableFlip
    data-testid={dataTestId}
    id={id}
    onClose={onClose}
    portaled={portaled}
    crossAxisShift
  >
    <DropdownListSheet size={size}>
      {children}
    </DropdownListSheet>
  </BaseFloatingDropdown>
})

DropdownListBase.displayName = 'DropdownListBase'

export default DropdownListBase
