import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const cudoTravelDefaultPalette: App.Palette = {
  ...whiteLabelBaseDefaultPalette,
}

export const cudoTravelShadows: App.ThemeShadows = { ...baseThemeShadows }

const cudoTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default cudoTravelTheme
