import React, { useMemo } from 'react'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import LuxPlusHiddenOfferLabel from './LuxPlusHiddenOfferLabel'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'
import { themeClassName } from 'lib/theme/themeUtils'
import useLuxPlusFreePreviewUtils from 'luxPlus/hooks/useLuxPlusFreePreviewUtils'
import useHiddenOfferClickHandler from './useHiddenOfferClickHandler'

interface Props {
  offer: App.Offer | App.OfferSummary;
}

function LuxPlusHiddenOfferDetails(props: Props) {
  const { offer } = props
  const onHandleHiddenOffer = useHiddenOfferClickHandler(offer)

  const pkg = offer.lowestPricePackage!
  const {
    valueOffPercent,
  } = useOfferPackagePrice(pkg, offer, { showMemberPrice: true })

  /**
   * If the locationHeading and locationSubheading are the same, only display the locationSubheading
   * e.g. Singapore, Singapore -> Singapore
  */
  const formattedLocationHeading = useMemo(() => {
    if (offer.locationHeading === offer.locationSubheading) return offer.locationSubheading
    return `${offer.locationHeading}, ${offer.locationSubheading}`
  }, [offer.locationHeading, offer.locationSubheading])

  const {
    showFreePreviewSignup,
  } = useLuxPlusFreePreviewUtils()

  return <Group gap={16} direction="vertical" verticalAlign="space-between">
    <VerticalSpacer gap={8}>
      <LuxPlusHiddenOfferLabel className={themeClassName('inverse')} />
      <Heading variant="heading5">
        <Heading variant="heading5" colour="highlight-secondary" as="span">
          Members save up to {valueOffPercent}%
        </Heading>
        {' '}on this hidden offer in {formattedLocationHeading}
      </Heading>
    </VerticalSpacer>

    <ProductPaletteProvider palette="loyalty">
      <TextButton
        variant="default"
        kind="secondary"
        fit="full-width"
        onClick={onHandleHiddenOffer}
      >
        {showFreePreviewSignup ? 'Unlock this offer via Free Preview' : `Join ${LUXURY_PLUS.PROGRAM_NAME} to unlock this offer`}
      </TextButton>
    </ProductPaletteProvider>
  </Group>
}

export default LuxPlusHiddenOfferDetails
