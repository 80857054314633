import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import FullWidthCarouselWithAnalytics from 'components/WithAnalytics/Carousel/FullWidthCarouselWithAnalytics'
import useOfferList from 'hooks/Offers/useOfferList'
import React, { ComponentProps, PropsWithChildren, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import OfferListCarouselTrack from './OfferListCarouselTrack'

type InheritedOfferListCarouselTrackProps = Pick<ComponentProps<typeof OfferListCarouselTrack>, 'additionalOfferUrlQuery' | 'tabletLimit' | 'limit' | 'tileStyle'>

interface Props extends PropsWithChildren, InheritedOfferListCarouselTrackProps {
  /**
   * The set of filters to fetch the offer list with
   */
  filters?: App.OfferListFilters;
  /**
   * Use this to provide your own full offer list for the carousel to display
   */
  overrideOfferList?: App.OfferList;
  className?: string;
    /**
   * Exclude the following offer ids from the results
   */
  excludedIds?: Array<string>;
  /**
   * - `block`: To be used when the carousel is contained inside another element. (ie. modal or dropdown)
   * - `page`: To be used as an immediate child of a page. (like a page section)
   *
   * @default page
   */
  size?: 'block' | 'page';
}

function OfferListCarousel(props: Props) {
  const {
    filters,
    overrideOfferList,
    limit,
    tabletLimit,
    children,
    tileStyle,
    additionalOfferUrlQuery,
    className,
    excludedIds,
    size = 'page',
  } = props
  const filterOfferList = useOfferList(filters, { disabled: !!overrideOfferList || !filters, excludedIds })
  const offerList = overrideOfferList ?? filterOfferList
  const carouselRef = useRef<HTMLDivElement>(null)

  const [inViewRef, carouselInView] = useInView({
    triggerOnce: true,
    rootMargin: '500px',
  })

  useEffect(() => {
    // We've been given a new offer list, send the carousel back to the start
    if (carouselRef.current) {
      carouselRef.current.scrollTo({ left: 0 })
    }
  }, [offerList])

  useEffect(() => {
    inViewRef(carouselRef.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselRef.current])

  const track = <OfferListCarouselTrack
    inView={carouselInView}
    offerList={offerList}
    tileStyle={tileStyle}
    additionalOfferUrlQuery={additionalOfferUrlQuery}
    limit={limit}
    tabletLimit={tabletLimit}
  >
    {children}
  </OfferListCarouselTrack>

  if (size === 'page') {
    let itemsPerArrow = 1
    if (tileStyle === 'card') {
      itemsPerArrow = 3
    } else if (tileStyle === 'card-medium') {
      itemsPerArrow = 2
    }

    return <FullWidthCarouselWithAnalytics
      itemsPerArrow={itemsPerArrow}
      snap="start"
      className={className}
      ref={carouselRef}
    >
      {track}
    </FullWidthCarouselWithAnalytics>
  }

  return <CardCarousel
    snap="start"
    className={className}
    ref={carouselRef}
  >
    {track}
  </CardCarousel>
}

export default React.memo(OfferListCarousel)
