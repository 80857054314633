import { isOneWayFlight } from 'lib/flights/flightUtils'
import moment from 'moment/moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import qs from 'qs'
import { getInflowFlightsCheckoutPath } from 'lib/url/pathsUtils'
import { last } from 'lib/array/arrayUtils'
import { FlightsFareTypes } from 'constants/flight'

export function getCheckoutFlightV2SearchUrl(
  departing?: App.JourneyV2,
  returning?: App.JourneyV2,
): string {
  if (!departing && !returning) {
    return getInflowFlightsCheckoutPath()
  }

  const queryParams = {
    departDate: departing?.flightGroup.flights[0].departingDate,
    returnDate: returning?.flightGroup.flights[0].departingDate,
    originAirportCode: departing?.flightGroup.flights[0].departureAirport,
    destinationAirportCode: returning?.flightGroup.flights[0].departureAirport,
  }
  return [getInflowFlightsCheckoutPath(), qs.stringify(queryParams)].join('?')
}

export function getCheckoutFlightV1SearchUrl(
  journey?: App.Journey,
): string {
  if (!journey) {
    return getInflowFlightsCheckoutPath()
  }

  const queryParams = {
    departDate: journey.departing.flights[0].departingDate,
    returnDate: journey.returning?.flights[0].departingDate,
    originAirportCode: journey.departing.flights[0].departingAirport,
    destinationAirportCode: journey.returning?.flights[0].departingAirport,
  }
  return [getInflowFlightsCheckoutPath(), qs.stringify(queryParams)].join('?')
}

export function getStandaloneFlightsV2SearchUrl(
  item: App.Checkout.FlightItem,
  airports: Array<App.Airport>,
  departing?: App.JourneyV2,
  returning?: App.JourneyV2,
): string {
  if (!departing && !returning && !item.originAirportCode && !item.destinationAirportCode && !item.travelStart && !item.travelEnd) {
    return '/flights'
  }

  let originAirportCode = item.originAirportCode
  let originAirportName = airports.find((airport) => airport.code === item.originAirportCode)?.name
  let destinationAirportCode = item.destinationAirportCode
  let destinationAirportName = airports.find((airport) => airport.code === item.destinationAirportCode)?.name

  // pick from the departing journey if available
  if (departing) {
    originAirportCode = departing?.flightGroup.flights[0].departureAirport
    originAirportName = departing?.flightGroup.flights[0].departureAirportName
    destinationAirportCode = last(departing?.flightGroup.flights ?? [])?.arrivalAirport
    destinationAirportName = last(departing?.flightGroup.flights ?? [])?.arrivalAirportName
  }

  const numberOfAdults = item.occupants.adults
  const numberOfChild = item.occupants.children
  const numberOfInfant = item.occupants.infants

  const queryParams = {
    originAirportCode,
    originAirportName,
    destinationAirportCode,
    destinationAirportName,
    departDate: moment(departing?.flightGroup.flights[0].departingDate || item.travelStart).format(ISO_DATE_FORMAT),
    returnDate: returning || item.travelEnd ? moment(returning?.flightGroup?.flights[0].departingDate || item.travelEnd).format(ISO_DATE_FORMAT) : null,
    adults: numberOfAdults,
    children: numberOfChild,
    infants: numberOfInfant,
    fareClass: 'economy',
    fareType: item.fareType,
  }

  if (item.fareType === FlightsFareTypes.MULTI_CITY) {
    if (!departing || !returning) {
      return '/flights'
    }

    const departingFlightGroup = departing?.flightGroup
    const returningFlightGroup = returning?.flightGroup

    const flights = [departingFlightGroup, returningFlightGroup].map(group => {
      const departingFlight = group.flights[0]
      const returningFlight = last(group.flights)

      return {
        originAirportCode: departingFlight.departureAirport,
        originAirportName: departingFlight.departureAirportName,
        destinationAirportCode: returningFlight.arrivalAirport,
        destinationAirportName: returningFlight.arrivalAirportName,
        departDate: departingFlight.departingDate,
      }
    })

    return `/flights-search-results-multi-city?${qs.stringify({ ...queryParams, flights })}`
  }

  return `/flights-search-results?${qs.stringify(queryParams)}`
}

export function getStandaloneFlightsV1SearchUrl(
  item: App.Checkout.FlightItem,
  journey?: App.Journey,
): string {
  if (!journey) {
    return '/flights'
  }

  const { departing, returning } = journey
  const originAirportCode = departing.flights[0].departingAirport
  const originAirportName = departing.flights[0].departingAirportName
  const destinationAirportCode = last(departing.flights).arrivalAirport
  const destinationAirportName = last(departing.flights).arrivalAirportName
  const numberOfAdults = item.passengers.filter((passenger) => passenger.type === 'adult').length
  const numberOfChild = item.passengers.filter((passenger) => passenger.type === 'child').length
  const numberOfInfant = item.passengers.filter((passenger) => passenger.type === 'infant').length
  const isOneWayFare = isOneWayFlight(item.fareType)
  const queryParams = {
    originAirportCode,
    originAirportName,
    destinationAirportCode,
    destinationAirportName,
    departDate: moment(departing.flights[0].departingDate).format(ISO_DATE_FORMAT),
    returnDate: !isOneWayFare ? moment(returning!.flights[0].departingDate).format(ISO_DATE_FORMAT) : null,
    adults: numberOfAdults,
    children: numberOfChild,
    infants: numberOfInfant,
    fareClass: 'economy',
    fareType: item.fareType,
  }

  return `/flights-search-results?${qs.stringify(queryParams)}`
}
