import { AppApiAction } from 'actions/ActionTypes'
import { API_CALL } from 'actions/actionConstants'
import requestGetAccountBusiness from 'businessTraveller/api/requestGetAccountBusiness'
import { BUSINESS_TRAVELLER_API_ACTIONS } from 'reducers/businessTravellerApiActionReducers'
import restoreSelectedBusinessTravellerEmployeeIds from './restoreSelectedBusinessTravellerEmployeeIds'
import config from 'constants/config'

interface Params {
  refetch?: boolean
}

export function fetchAccountBusiness(params?: Params): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()

    // this side effect ensures that if the selected business traveller is in
    // localStorage that it will be restored to redux
    if (config.businessTraveller.currentAccountMode === 'business') {
      dispatch(restoreSelectedBusinessTravellerEmployeeIds())
    }

    if (!state.businessTraveller.currentBusinessAccount || params?.refetch) {
      dispatch({
        type: API_CALL,
        api: BUSINESS_TRAVELLER_API_ACTIONS.FETCH_ACCOUNT_BUSINESS,
        request: () => requestGetAccountBusiness({ accessToken: state.auth.accessToken, includeEmployees: true }),
      })
    }
  }
}

export default fetchAccountBusiness
