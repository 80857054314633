export const PROVIDER_COVER_GENIUS = 'cover_genius'
export const PROVIDER_NIB = 'nib'

export const PRODUCT_ID_COMPREHENSIVE = 'COMPREHENSIVE_TRIP_PROTECTION'
export const PRODUCT_ID_OVERSEA_MEDICAL = 'OVERSEAS_MEDICAL_AND_EVACUATION_PROTECTION'
export const PRODUCT_ID_CANCELLATION_AND_BAGGAGE = 'CANCELLATION_AND_BAGGAGE_PROTECTION'
export const PRODUCT_ID_NO_PROTECTION = 'NO_PROTECTION'
export const PRODUCT_ID_FULL_PROTECTION_CRUISE_DOMESTIC = 'FULL_PROTECTION_CRUISE_DOMESTIC'
export const PRODUCT_ID_FULL_PROTECTION_CRUISE = 'FULL_PROTECTION_CRUISE'

export const INSURANCE_CANCELLATION_REASON_UPGRADED = 'insurance_upgraded'

export const CRUISE_INSURANCE_LIMIT_AGE = 80
