import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineLocationPinAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 10.8a1.9999 1.9999 0 1 0-1.6629-3.1111 1.9998 1.9998 0 0 0 .2487 2.5253A2 2 0 0 0 12 10.8Zm-.71 6.91a.9997.9997 0 0 0 1.42 0l4.09-4.1a6.79 6.79 0 1 0-9.6 0l4.09 4.1ZM7.23 8.34a4.81 4.81 0 0 1 2.13-3.55 4.8101 4.8101 0 0 1 5.28 0 4.8197 4.8197 0 0 1 1.8735 5.6233A4.8207 4.8207 0 0 1 15.39 12.2L12 15.59 8.61 12.2a4.7706 4.7706 0 0 1-1.38-3.86ZM19 20H5a1.0002 1.0002 0 0 0-.7071 1.7071C4.4804 21.8946 4.7348 22 5 22h14a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineLocationPinAltIcon
