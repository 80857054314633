import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSyringeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.71 2.29a1.001 1.001 0 0 0-.71-.2958 1.0003 1.0003 0 0 0-.71.2958l-2.12 2.12-.71-.7a1 1 0 0 0-1.41 0l-1.41 1.41-.71-.71a1.001 1.001 0 0 0-.71-.2958 1.0003 1.0003 0 0 0-.71.2958l-7.77 7.78-.74-.7a1.0003 1.0003 0 0 0-1.2875.1202A1 1 0 0 0 2.62 12.9l3.53 3.54-1.73 1.74-.71-.72a1.0041 1.0041 0 1 0-1.42 1.42l2.83 2.83a1.0003 1.0003 0 0 0 .71.29 1.0005 1.0005 0 0 0 .71-.29 1.0008 1.0008 0 0 0 .2958-.71 1.0003 1.0003 0 0 0-.2958-.71l-.71-.7 1.74-1.74 3.53 3.53a1.0005 1.0005 0 0 0 .71.3.9998.9998 0 0 0 .9758-1.1991 1.0006 1.0006 0 0 0-.2758-.5109l-.7-.71 7.78-7.77a.9997.9997 0 0 0 0-1.42l-.71-.71L20.29 8a.9998.9998 0 0 0 0-1.41l-.7-.71 2.12-2.12a.9999.9999 0 0 0 0-1.47ZM7.57 15l-1.42-1.39 1.41-1.42L9 13.61 7.57 15Zm2.82 2.83L9 16.44 10.39 15l1.42 1.42-1.42 1.41ZM13.22 15 9 10.78l4.24-4.24.71.7 3.53 3.54L13.22 15Zm4.24-7-1.41-1.46.71-.71.7.7.7.7-.7.77Z"/>
  </SvgIcon>
}

export default LineSyringeIcon
