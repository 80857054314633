import { OFFER_TYPE_TOUR } from 'constants/offer'
import { isCruiseOffer, isTourV2Offer } from 'lib/offer/offerTypes'
import { findCheapestOfTourV2Offer } from 'lib/tours/tourUtils'
import { OfferTypeForTripItemTypes } from 'tripPlanner/hooks/api'

export const extractDestinationFromBedbankOffer = (
  offer: App.BedbankOffer,
): string => {
  return offer.property.address.city
}

export const extractDestinationFromOffer = (
  offer: OfferTypeForTripItemTypes,
): string | undefined => {
  if (isTourV2Offer(offer)) {
    const { cheapestVariation } = findCheapestOfTourV2Offer(offer)
    if (cheapestVariation) {
      const { startLocation, endLocation } = cheapestVariation
      const locations = [startLocation, endLocation].filter(Boolean)
      if (locations.length === 1 || locations[0] === locations[1]) {
        return locations[0] ?? undefined
      } else if (locations.length === 2) {
        return `${locations[0]} to ${locations[1]}`
      }
    }
  }
  // Prefer location field for tour V1
  if (offer.type === OFFER_TYPE_TOUR && 'location' in offer) {
    return offer.location
  }
  if (isCruiseOffer(offer)) {
    return offer.departurePort
  }
  if ('locationHeading' in offer) {
    return offer.locationHeading
  }
  if ('location' in offer) {
    return offer.location
  }
  if ('property' in offer) {
    if ('geoData' in offer.property) {
      // @ts-ignore
      return offer.property.geoData.country
    }
    if ('address' in offer.property) {
      return offer.property.address.countryName
    }
  }
}

export const extractDestinationFromTourItems = (
  item: Tours.TourV2OrderItem,
): string => {
  return item.tour.startLocation
}

export const extractDestinationFromFlight = (
  journey: App.JourneyV2,
): string => {
  const lastLegIndex = journey.flightGroup.flights.length - 1
  return journey.flightGroup.flights[lastLegIndex].arrivalAirportName
}

export const extractDestinationFromOrder = (
  order: App.Order,
  offers: Array<OfferTypeForTripItemTypes>,
  flights: Array<App.JourneyV2>,
  experiences: Array<App.ExperienceOffer>,
): string | undefined => {
  if (order.bedbankItems.length > 0) {
    return extractDestinationFromBedbankOffer(order.bedbankItems[0].offer)
  }

  if (offers.length > 0) {
    const result = extractDestinationFromOffer(offers[0])

    if (result) {
      return result
    }
  }

  if (order.tourItems.length > 0) {
    return extractDestinationFromTourItems(order.tourItems[0])
  }

  if (flights.length > 0) {
    return extractDestinationFromFlight(flights[0])
  }

  if (experiences.length > 0) {
    // for experiences with multiple locations
    if (experiences[0].redemptionLocations?.length > 1) {
      return experiences[0].redemptionLocations.find(
        (location) =>
          location.id === order.experienceItems[0]?.redemptionLocation?.id,
      )?.name
    }
    return experiences[0].location.name
  }
}
