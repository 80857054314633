const prefixCategory = (category: string) => {
  if ([
    'Cabin',
    'Campsite',
    'Chalet',
    'Condominium',
    'Country House',
    'Guesthouse',
    'Apartment',
    'Private vacation home',
    'Villa',
    'Castle',
    'Country House'].includes(category)) {
    return true
  }
  return false
}

export function getPropertyCategory(category?: string) {
  if (category) {
    if (prefixCategory(category)) {
      return `Entire ${category.toLowerCase()}`
    } else {
      return category
    }
  }
  return 'Entire villa'
}
