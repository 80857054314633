import React, { PropsWithChildren, forwardRef } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import BaseDropdownSheet, { DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE, DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE, DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE, DROPDOWN_SHEET_WIDTH_CSS_VARIABLE } from '../BaseDropdownSheet'
import { mediaQueryUp } from 'components/utils/breakpoint'

const Container = styled(BaseDropdownSheet)`
  ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: 100%;
  ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(480)};

  ${mediaQueryUp.tablet} {
    ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(600)};
  }

  &.size-S {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(200)};
    ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(220)};
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};
    }
  }
  &.size-M {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(335)};
    ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(400)};
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(600)};
    }
  }
`

interface Props extends PropsWithChildren {
  /**
   * Controls the width and height of the sheet
   */
  size: 'S' | 'M' | 'fill-anchor'
}

const DropdownListSheet = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    size,
  } = props

  return <Container ref={ref} className={`size-${size}`}>
    {children}
  </Container>
})

export default DropdownListSheet
