export const CHECKOUT_ITEM_TYPE_TOUR_V2 = 'tourV2'
export const CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE = 'tourV2Experience'
export const CHECKOUT_ITEM_TYPE_CRUISE = 'cruise'
export const CHECKOUT_ITEM_TYPE_CAR_HIRE = 'car-hire'
export const CHECKOUT_ITEM_TYPE_TOUR_V1 = 'tourV1'
export const CHECKOUT_ITEM_TYPE_BEDBANK = 'bedbankHotel'
export const CHECKOUT_ITEM_TYPE_LE_HOTEL = 'hotel'
export const CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE = 'bundleAndSave'
export const CHECKOUT_ITEM_TYPE_EXPERIENCE = 'experience'
export const CHECKOUT_ITEM_TYPE_TRANSFER = 'transfer'
export const CHECKOUT_ITEM_TYPE_FLIGHT = 'flight'
export const CHECKOUT_ITEM_TYPE_INSURANCE = 'insurance'
export const CHECKOUT_ITEM_TYPE_CARHIRE = 'car-hire'
export const CHECKOUT_ITEM_TYPE_GIFT_CARD = 'gift-card'
export const CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION = 'luxury-plus-subscription'
export const CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE = 'subscription-join'
export const CHECKOUT_ITEM_TYPE_VILLA = 'villa'
export const CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION = 'booking-protection'

export const PRIMARY_TRAVELLER_FORM_ID = 'adult-1'
export const GIFT_RECIPIENT_FORM_ID = 'recipient'
export const PRIMARY_TRAVELLER_EXPERIENCE_FORM_ID = 'customer-adult-1'

export const TOUR_V2_DEPOSIT_MINIMUM_VALUE_PER_CURRENCY_CODE = {
  AUD: 0,
  // AUD: 2999,
  USD: 0,
  // USD: 1999,
  GBP: 0,
  // GBP: 2499,
  EUR: 0,
  // EUR: 1999,
  CAD: 0,
  // CAD: 2499,
  NZD: 0,
  // NZD: 2999,
  SGD: 0,
  // SGD: 1499,
}

export const CHECKOUT_SAVING_PERCENTAGE_THRESHOLD = 5

export const CHECKOUT_DUE_DATE_DAY_THRESHOLD = 60

export enum DepositType {
  PERCENTAGE = 'percentage',
  FLAT = 'flat',
}

export const CRUISE_CHECKOUT_INFLOW_V2 = {
  TRAVELERS: 'travelers',
  CABIN_TYPE: 'cabin-type',
  CABIN_CATEGORY: 'cabin-category',
  CABIN_SELECTION: 'cabin-selection',
  RATE_SELECTION: 'rate-selection',
}

export const CHECKOUT_REVIEW_CONTAINER_ID = 'checkout-review-section'
export const CHECKOUT_INSURANCE_CONTAINER_ID = 'checkout-insurance-section'
export const CHECKOUT_TRAVELLER_CONTAINER_ID = 'checkout-traveller-section'
export const CHECKOUT_ARRIVAL_DETAILS_CONTAINER_ID = 'checkout-arrival-details-section'
export const CHECKOUT_PAYMENTS_CONTAINER_ID = 'checkout-payments-section'

// Default offset of header size
export const SCROLL_TO_OFFSET = -75
// Offset for insurance
export const SCROLL_TO_OFFSET_INSURANCE = -130

export const POST_PURCHASE_TOUR_EXPERIENCE = 'tour-optional-experiences'
