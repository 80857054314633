import React, { useCallback, useContext, useMemo } from 'react'
import LuxPlusLabel from './LuxPlusLabel'
import { isActiveLuxPlusMember, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import LabeledContainer from 'components/Common/Labels/LabeledContainer'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LuxPlusInclusionsList from './LuxPlusInclusionsList'
import LuxPlusLabelWithModal from './LuxPlusLabelWithModal'
import FormatCurrency from 'components/Common/FormatCurrency'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import Clickable from 'components/Common/Clickable'
import ModalContext from 'contexts/ModalContext'
import LuxPlusBenefitsModal from './modals/LuxPlusBenefitsModal'
import { INCLUSIONS_TITLE_LUXPLUS } from 'constants/inclusions'
import Typography from 'components/Luxkit/Typography/Typography'
import LuxPlusLabelContext from 'contexts/LuxPlusLabelContext'
import { roundLuxPlusInclusionsValue } from 'luxPlus/lib/utils'

type Mode = 'disabled' | 'inclusions-viewable' | 'upsell-compact' | 'upsell-expanded'

interface Props {
  isLuxPlusEnabled: boolean;
  isActiveLuxPlusMember: boolean;
  loyaltyInclusions?: Array<App.PackageInclusion> | Array<App.OrderLoyaltyInclusion>;
  accommodationOffer?: App.Offer | App.OfferSummary;
  memberInclusionsAmount?: number;
  mode?: Mode;
}

function LuxPlusInclusions(props: Props) {
  const { isLuxPlusEnabled, isActiveLuxPlusMember, loyaltyInclusions, accommodationOffer, memberInclusionsAmount = 0, mode } = props

  const showModal = useContext(ModalContext)
  const providerPage = useContext(LuxPlusLabelContext)

  const toggleModal = useCallback(() => {
    return showModal(<LuxPlusBenefitsModal type="bonus-inclusions" memberInclusionsAmount={memberInclusionsAmount} offer={accommodationOffer} providerPage={providerPage} />)
  }, [accommodationOffer, memberInclusionsAmount, providerPage, showModal])

  const displayedMode = useMemo<Mode>(() => {
    if (!isLuxPlusEnabled) {
      return 'disabled'
    } else if (mode) {
      return mode
    } else if (isActiveLuxPlusMember && loyaltyInclusions?.length) {
      return 'inclusions-viewable'
    } else {
      return 'upsell-compact'
    }
  }, [isActiveLuxPlusMember, isLuxPlusEnabled, loyaltyInclusions?.length, mode])

  switch (displayedMode) {
    case 'disabled':
      return null
    case 'inclusions-viewable':
      return <LabeledContainer
      data-testid="luxury-plus-inclusions-member"
      size="medium"
      colour="loyalty"
      labels={
        <>
          <CSSBreakpoint not="tablet"><LuxPlusLabel type="bonus-inclusions" /></CSSBreakpoint>
          <CSSBreakpoint only="tablet"><LuxPlusLabel type="only-logo" /></CSSBreakpoint>
        </>
      }
    >
        <LuxPlusInclusionsList loyaltyInclusions={loyaltyInclusions!} />
      </LabeledContainer>
    case 'upsell-compact':
      return <div data-testid="luxury-plus-inclusions-non-member">
        <LuxPlusLabelWithModal type="bonus-inclusions" offer={accommodationOffer} memberInclusionsAmount={memberInclusionsAmount} />
      </div>
    case 'upsell-expanded':
      return <LabeledContainer
      data-testid="lux-plus-inclusions-upsell-expanded"
      size="medium"
      colour="loyalty"
      labels={<LuxPlusLabel type="only-logo" />}
      >
        <BodyTextBlock
            variant="medium"
            weight="bold"
            endIcon={<Clickable onClick={toggleModal} data-testid="lux-plus-inclusions-upsell-expanded-clickable">
              <LineInfoCircleIcon size="M" />
            </Clickable>}
          >
          {memberInclusionsAmount > 0 && <Typography format="propercase">{INCLUSIONS_TITLE_LUXPLUS} worth <FormatCurrency format="dynamic" value={roundLuxPlusInclusionsValue(memberInclusionsAmount)} />+ for members</Typography>}
          {memberInclusionsAmount === 0 && <Typography format="propercase">{INCLUSIONS_TITLE_LUXPLUS} revealed at checkout</Typography>}
        </BodyTextBlock>
      </LabeledContainer>
  }
}

function mapStateToProps(state: App.State) {
  return {
    isActiveLuxPlusMember: isActiveLuxPlusMember(state),
    isLuxPlusEnabled: isLuxPlusEnabled(state),
  }
}

export default connect(mapStateToProps)(LuxPlusInclusions)
