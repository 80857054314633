import clsx from 'clsx'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import SearchFormTextField from 'components/SearchV2/Components/SearchFormField/SearchFormTextField'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import RecentSearchSuggestions from '../SearchForm/SearchLocationInput/RecentSearchSuggestions'
import SearchMenuListItem from '../SearchForm/SearchMenu/SearchMenuListItem'
import SearchPlaceSelectList from './SearchPlaceSelectList'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import { rem } from 'polished'
import { take } from '../../../lib/array/arrayUtils'

const PlaceControl = styled.div`
  width: 100%;

  &:not(.full-width) {
    position: relative;
  }
`

const SearchMenuListItemContainer = styled.div`
  width: ${rem(325)};
  display: flex;
  column-gap: ${rem(40)};
  flex-wrap: wrap;

  &.horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
`

const StyledRecentSearchSuggestions = styled(RecentSearchSuggestions)`
  padding: 0 ${rem(4)};
`

interface Props {
  /**
   * @default fill-anchor
   */
  size?: ComponentProps<typeof DropdownList>['size']
  className?: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: App.SearchItem;
  required?: boolean;
  label: string;
  placeholder: string;
  localInputRef: React.RefObject<HTMLInputElement>;
  searchPhrase: string;
  onSearchPhraseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpen: (e: any) => void;
  onClearClick: () => void;
  finalOpen: boolean;
  closeDropdown: () => void;
  showPlaceholder: boolean;
  hasRecentSearchItems: boolean;
  recentSearches: Array<App.FullRecentSearch>;
  selectedDropdown: number | undefined;
  onRecentSearchDelete: (item: App.FullRecentSearch) => void;
  onRecentSearchClick: (item: App.FullRecentSearch) => void;
  placeholderTitle?: string;
  placeholdersSearches: Array<App.SearchItem>;
  onLocationSelect: (item?: App.SearchItem) => void;
  handleResultsChange: (results: Array<App.SearchItem>, searchPhrase: string) => void;
  placeTypes?: Array<App.SearchPlaceType>;
  searchTypes?: Array<App.SearchType>;
  searchVertical?: App.SearchPlaceVertical;
  overrideSearchItems?: Array<App.SearchItem>;
  controlsContainerRef?: React.RefObject<HTMLDivElement>;
  showHorizontalLayout?: boolean;
}

function SearchPlaceSelectDropdown(props: Props) {
  const {
    size = 'fill-anchor',
    className,
    onKeyDown,
    value,
    required,
    label,
    placeholder,
    localInputRef,
    searchPhrase,
    onSearchPhraseChange,
    handleOpen,
    onClearClick,
    finalOpen,
    closeDropdown,
    showPlaceholder,
    hasRecentSearchItems,
    recentSearches,
    selectedDropdown,
    onRecentSearchDelete,
    onRecentSearchClick,
    placeholderTitle,
    placeholdersSearches,
    onLocationSelect,
    handleResultsChange,
    placeTypes,
    searchTypes,
    searchVertical,
    overrideSearchItems,
    controlsContainerRef,
    showHorizontalLayout = false,
  } = props

  return (
    <PlaceControl className={clsx({ className, 'full-width': showHorizontalLayout })} onKeyDown={onKeyDown}>
      <HiddenInput
        required={required}
        value={value?.format.mainText}
      />
      <SearchFormTextField
        label={label}
        placeholder={placeholder}
        ref={localInputRef}
        value={searchPhrase}
        displayValue={value?.format.mainText ?? ''}
        onChange={onSearchPhraseChange}
        onFocus={handleOpen}
        onClear={onClearClick}
      />

      {showHorizontalLayout && controlsContainerRef && <DropdownSheet
        size="fill-anchor"
        anchorRef={controlsContainerRef}
        triggerRef={localInputRef}
        open={finalOpen}
        data-testid="place-select"
        onClose={closeDropdown}
      >
        {showPlaceholder && <VerticalSpacer gap={12}>
          {hasRecentSearchItems && <>
            <RecentSearchSuggestions
              recentSearches={recentSearches}
              onDelete={onRecentSearchDelete}
              selectedDropdown={selectedDropdown}
              onRecentSearchClick={onRecentSearchClick}
            />
            <Divider kind="primary" />
          </>}
          <div>
            {placeholderTitle && <ListSubheader>{placeholderTitle}</ListSubheader>}
            <SearchMenuListItemContainer className="horizontal">
              {take(placeholdersSearches, 6).map((item, index) => <SearchMenuListItem
              key={item.value}
              searchItem={item}
              onClick={onLocationSelect}
              selected={index + recentSearches.length === selectedDropdown}
              imageId={item.imageId}
              searchVertical={searchVertical}
              />)}
            </SearchMenuListItemContainer>
          </div>
        </VerticalSpacer>}
        {!showPlaceholder &&
          <SearchPlaceSelectList
            searchPhrase={searchPhrase}
            onResultsChange={handleResultsChange}
            onChange={onLocationSelect}
            placeTypes={placeTypes}
            searchTypes={searchTypes}
            vertical={searchVertical}
            selectedDropdown={selectedDropdown}
            overrideSearchItems={overrideSearchItems}
            showHorizontalLayout={showHorizontalLayout}
          />}
      </DropdownSheet>}
      {!showHorizontalLayout && <DropdownList
        data-testid="place-select"
        size={size}
        anchorRef={localInputRef}
        triggerRef={localInputRef}
        disableFlip
        open={finalOpen}
        onClose={closeDropdown}
      >
        {showPlaceholder && <VerticalSpacer gap={12}>
          {hasRecentSearchItems && <>
            <StyledRecentSearchSuggestions
              recentSearches={recentSearches}
              onDelete={onRecentSearchDelete}
              selectedDropdown={selectedDropdown}
              onRecentSearchClick={onRecentSearchClick}
            />
            <Divider kind="primary" />
          </>}
          <div>
            {placeholderTitle && <ListSubheader>{placeholderTitle}</ListSubheader>}
            {placeholdersSearches.map((item, index) => <SearchMenuListItem
            key={item.value}
            searchItem={item}
            onClick={onLocationSelect}
            selected={index + recentSearches.length === selectedDropdown}
            searchVertical={searchVertical}
          />)}
          </div>
        </VerticalSpacer>}
        {!showPlaceholder &&
          <SearchPlaceSelectList
            searchPhrase={searchPhrase}
            onResultsChange={handleResultsChange}
            onChange={onLocationSelect}
            placeTypes={placeTypes}
            searchTypes={searchTypes}
            vertical={searchVertical}
            selectedDropdown={selectedDropdown}
            overrideSearchItems={overrideSearchItems}
            showHorizontalLayout={showHorizontalLayout}
          />}
      </DropdownList>}
    </PlaceControl>
  )
}

export default SearchPlaceSelectDropdown
