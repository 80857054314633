import { useContext, useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { unique } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { getCruiseOfferPriceVariation, CruiseOfferPriceVariation, getCruiseCountriesByItinerary, getCruisePortsByItinerary } from 'lib/cruises/cruiseUtils'
import { getHighlightedDepartureDate } from 'lib/cruises/cruiseOfferUtils'
import { isVelocityEnabled as getVelocityEnabled } from 'selectors/featuresSelectors'
import moment from 'moment'
import { ProductPalette } from 'contexts/ProductPaletteContext'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import CurrencyContext from 'contexts/currencyContext'

interface Props {
  offer: App.CruiseOffer | App.Offer | App.OfferSummary;
  cabinTypes?: string;
}

function useCruiseOfferView({ offer, cabinTypes } : Props) {
  const globalFilters = useContext(GlobalSearchStateContext)
  const cruisePriceByNight = useCruisePriceByNight()
  const isBankOffer = offer.type === 'cruise'

  const offerImages = offer.images

  const currencyCode = useContext(CurrencyContext)
  const isVelocityEnabled = useAppSelector(getVelocityEnabled)

  const offerTitle = offer.name

  const lowestPrice = useMemo(() => {
    if (isBankOffer) {
      let lowestOffer = offer.mainDepartureDetails.lowestOverallPriceDetails
      if (cabinTypes) {
        lowestOffer = offer.mainDepartureDetails.lowestPriceDetailsByCategory[cabinTypes]
      }
      return getCruiseOfferPriceVariation(offer, lowestOffer, cruisePriceByNight)
    }

    const saleUnit = cruisePriceByNight ? 'night' : offer.saleUnit.toLowerCase() === 'cruise' ? 'person' : offer.saleUnit
    const lowestPrice = offer.lowestPricePackage?.price! || 0
    const total = cruisePriceByNight ? Math.round(lowestPrice / offer.minDuration! || 1) : lowestPrice
    return {
      primaryPrice: {
        total,
        type: saleUnit,
        currencyCode,
      },
    } as CruiseOfferPriceVariation
  }, [isBankOffer, offer, currencyCode, cabinTypes, cruisePriceByNight])

  const departureDates = useMemo(() => {
    if (isBankOffer) {
      const { mainDepartureDetails } = offer
      const allDepartureDates = (offer.departureDates ?? [])
        .map((date) => moment(date))
        .sort((a, b) => a.unix() - b.unix())
        .map((date) => date.format('DD MMM YYYY'))

      if (mainDepartureDetails.departureDate) {
        const mainDepartureDate = moment(mainDepartureDetails.departureDate).format('DD MMM YYYY')
        return [
          ...new Set([mainDepartureDate, ...allDepartureDates]),
        ]
      }

      return allDepartureDates
    }
    const departureDates: Array<string> = []

    if (offer.travelFromDate) {
      departureDates.push(offer.travelFromDate)
    }

    if (offer.travelFromDate && (offer.travelToDate !== offer.travelFromDate)) {
      departureDates.push(offer.travelToDate)
    }

    return departureDates
      .filter(Boolean)
      .map((date) => moment(date).format('DD MMM YYYY'))
  }, [offer, isBankOffer])

  const [earliestDepartureDate, ...laterDates] = departureDates

  const offerDescription = useMemo(() => {
    if (isBankOffer) {
      const shipInfoDescription = offer.ship.shipInfo?.find(info => info.dataTypeName === 'Summary')?.description
      const cruiseDescription = offer.description || shipInfoDescription || offer.destinationDescription
      const parsedDescription = cruiseDescription
        ?.replace(/(<([^>]+)>)/gi, '')
        ?.split(' ')
        ?.slice(0, 35)
        ?.join(' ') + '...'

      return parsedDescription
    }
  }, [offer, isBankOffer])

  const isUltraLux = isBankOffer ? false : offer.property?.isUltraLux || offer.productType === 'ultra_lux_tour'
  const palette:ProductPalette = isUltraLux ? 'ultralux' : 'default'

  if (isBankOffer) {
    const departureCity = offer.departurePort.split(',')[0]
    const destinationCity = offer.returnPort.split(',')[0]
    const cities = getCruisePortsByItinerary(offer.itinerary)
    const countries = getCruiseCountriesByItinerary(offer.itinerary)
    const promotion = offer.mainDepartureDetails.lowestOverallPriceDetails.promotion ?? offer.fallbackPromotion ?? null
    const detailItems = [offer.cruiseLine.name, offer.ship.name].filter(Boolean)
    const itineraryDetailItems: Array<string> = []
    const duration = offer.duration

    if (duration) itineraryDetailItems.push(pluralizeToString('night', duration))
    if (cities.length) itineraryDetailItems.push(pluralizeToString('port', cities.length))
    if (countries.length) itineraryDetailItems.push(pluralizeToString('country', countries.length))

    const { highlightedDepartureId, highlightedDepartureDate, otherDepartureDates } = getHighlightedDepartureDate(
      offer.mainDepartureDetails.departureDate,
      offer.departuresSummary,
      globalFilters.checkinDate,
      globalFilters.checkoutDate,
      globalFilters.flexibleMonths,
    )

    return {
      offerTitle,
      offerDescription,
      departureCity,
      destinationCity,
      cities,
      countries,
      duration,
      offerImages,
      isUltraLux,
      lowestPrice,
      isVelocityEnabled,
      palette,
      highlightedDepartureId,
      earliestDepartureDate: highlightedDepartureDate,
      laterDates: otherDepartureDates,
      departureDates,
      shipName: offer.ship.name,
      cruiseLineName: offer.cruiseLine.name,
      cruiseLineImage: offer.cruiseLine.imageId,
      isSpecialOffer: !!offer.evergreenInclusions.length || !!promotion,
      shipDetailsLabel: detailItems.join(' · '),
      itineraryDetailsLabel: itineraryDetailItems.join(' · '),
      productType: offer.productType,
      promotionDetails: promotion,
      evergreenInclusions: offer.evergreenInclusions,
      disableDeposits: offer.disableDeposits,
      isFlashOffer: false,
      cabinCategory: cabinTypes || offer.mainDepartureDetails.lowestOverallPriceDetails.cabinCategory,
      discountPills: offer.mainDepartureDetails.lowestOverallPriceDetails.discountPills,
      itinerary: offer.itinerary,
    }
  }

  const shipName = offer.vendorVehicle
  const cruiseLineName = offer.vendorName
  const cruiseLineImage = offer.property?.logoImageId || offer.lowestPricePackage?.tour?.logoImageId || ''
  const duration = offer.minDuration
  const departureCity = offer.startLocation?.split(',')?.[0]
  const destinationCity = offer.endLocation?.split(',')?.[0]
  const locations = offer.locationsVisited ?? []
  const countries = unique(locations.map(({ country }) => country)).filter(Boolean)
  const cities = unique(locations.map(({ cityOrPlaceName }) => cityOrPlaceName).filter(Boolean))

  const detailItems: Array<string> = []

  if (duration) {
    // FOR FLASH CRUISES WE NEED TO
    // SUBTRACT 1 NIGHT FROM THE DURATION.
    // BECAUSE DURATION COMES IN DAYS RATHER THATN NIGHTS.
    // E.G. 7 DAYS CRUISE IS 6 NIGHTS
    const nights = duration - 1
    detailItems.push(pluralizeToString('night', nights))
  }

  if (cities.length) {
    detailItems.push(pluralizeToString('port', cities.length))
  }

  if (countries.length) {
    detailItems.push(pluralizeToString('country', countries.length))
  }

  const itineraryDetailsLabel = detailItems.join(' · ')
  const shipDetailsItems = [cruiseLineName, shipName].filter(Boolean)
  const shipDetailsLabel = shipDetailsItems.join(' · ')

  const itineraryFlash = offer.lowestPricePackage?.tour?.itinerary || ''
  const flashPromotion = {
    isNew: false,
    isEndingIn: offer.bookByDate ? moment(offer.bookByDate).diff(new Date(), 'days') : 0,
    leExclusive: false,
    sellingPoints: [],
    deposit: null,
    onBoardCredit: null,
  } as App.CruisePromotionDetails

  return {
    offerTitle,
    shipName,
    cruiseLineName,
    cruiseLineImage,
    departureCity,
    destinationCity,
    duration,
    offerImages,
    isUltraLux,
    lowestPrice,
    shipDetailsLabel,
    itineraryDetailsLabel,
    isVelocityEnabled,
    earliestDepartureDate,
    laterDates,
    palette,
    cities,
    countries,
    itineraryFlash,
    departureDates,
    isSpecialOffer: true,
    productType: 'cruise-flash',
    offerDescription: offer.preHeader,
    disableDeposits: true,
    isFlashOffer: true,
    tileInclusions: offer.tileInclusions,
    cabinCategory: '',
    flashOffer: offer,
    discountPills: null,
    promotionDetails: flashPromotion,
    highlightedDepartureId: null,
  }
}

export default useCruiseOfferView
