import React, { PropsWithChildren, forwardRef } from 'react'
import cn from 'clsx'
import styled from 'styled-components'

/**
 * NOT TO BE USED SEPARATELY
 * @see Luxkit/Tooltip
 */
export const TooltipTriggerElement = styled.span`
  &:not(.disabled) {
    cursor: pointer;
  }
`

interface Props {
  className?: string
  disabled?: boolean
}

/**
 * NOT TO BE USED SEPARATELY
 * @see Luxkit/Tooltip
 */
const TooltipTrigger = forwardRef<HTMLSpanElement, PropsWithChildren<Props>>((props, ref) => {
  const { children, className, disabled } = props

  return <TooltipTriggerElement
    ref={ref}
    className={cn(className, { disabled })}
  >
    {children}
  </TooltipTriggerElement>
})

export default TooltipTrigger
