export const ONE_DAY_BEFORE_CHECK_IN = 1
export const TWO_DAYS_BEFORE_CHECK_IN = 2
export const THREE_DAYS_BEFORE_CHECK_IN = 3
export const SEVEN_DAYS_BEFORE_CHECK_IN = 7
export const SEVEN_DAYS_AFTER_PURCHASE = 7
export const FOURTEEN_DAYS_BEFORE_CHECK_IN = 14
export const TWENTY_ONE_DAYS_BEFORE_CHECK_IN = 21
export const TWENTY_EIGHT_DAYS_BEFORE_CHECK_IN = 28
export const THIRTY_ONE_DAYS_BEFORE_CHECK_IN = 31
export const FORTY_FIVE_DAYS_BEFORE_CHECK_IN = 45
export const SIXTY_DAYS_BEFORE_CHECK_IN = 60
export const CREDIT_ONLY_PRIOR_TO_CHECK_IN_FOURTEEN_DAYS = 14

export const CANCELLATION_POLICIES = {
  NON_REFUNDABLE: 'non-refundable',
  PRIOR_TO_CHECK_IN_ONE_DAY: 'prior-to-check-in-one-day',
  PRIOR_TO_CHECK_IN_TWO_DAYS: 'prior-to-check-in-two-days',
  PRIOR_TO_CHECK_IN_THREE_DAYS: 'prior-to-check-in-three-days',
  PRIOR_TO_CHECK_IN_SEVEN_DAYS: 'prior-to-check-in-seven-days',
  PRIOR_TO_CHECK_IN_FOURTEEN_DAYS: 'prior-to-check-in-fourteen-days',
  PRIOR_TO_CHECK_IN_TWENTY_ONE_DAYS: 'prior-to-check-in-twenty-one-days',
  PRIOR_TO_CHECK_IN_TWENTY_EIGHT_DAYS: 'prior-to-check-in-twenty-eight-days',
  PRIOR_TO_CHECK_IN_THIRTY_ONE_DAYS: 'prior-to-check-in-thirty-one-days',
  PRIOR_TO_CHECK_IN_FORTY_FIVE_DAYS: 'prior-to-check-in-forty-five-days',
  PRIOR_TO_CHECK_IN_SIXTY_DAYS: 'prior-to-check-in-sixty-days',
  POST_PURCHASE_SEVEN_DAYS: 'post-purchase-seven-days',
  CREDIT_ONLY_PRIOR_TO_CHECK_IN_FOURTEEN_DAYS: 'credit-only-prior-to-check-in-fourteen-days',
  PARTNER_POLICY_PRIOR_TO_CHECK_IN_TWENTY_ONE_DAYS: 'partner-policy-prior-to-check-in-twenty-one-days',
  FLEXIBLE_CANCELLATION_FORTY_FIVE_DAYS: 'flexible-cancellation-forty-five-days',
}

export const PRIOR_TO_CHECK_IN_CANCELLATION_POLICIES = [
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_ONE_DAY,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_TWO_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_THREE_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_SEVEN_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_FOURTEEN_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_TWENTY_ONE_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_TWENTY_EIGHT_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_THIRTY_ONE_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_FORTY_FIVE_DAYS,
  CANCELLATION_POLICIES.PRIOR_TO_CHECK_IN_SIXTY_DAYS,
  CANCELLATION_POLICIES.PARTNER_POLICY_PRIOR_TO_CHECK_IN_TWENTY_ONE_DAYS,
  CANCELLATION_POLICIES.FLEXIBLE_CANCELLATION_FORTY_FIVE_DAYS,
]

export const CANCELLATION_POLICY_DAYS = {
  'non-refundable': 0,
  'prior-to-check-in-one-day': 1,
  'prior-to-check-in-two-days': 2,
  'prior-to-check-in-three-days': 3,
  'prior-to-check-in-seven-days': 7,
  'prior-to-check-in-fourteen-days': 14,
  'prior-to-check-in-twenty-one-days': 21,
  'prior-to-check-in-twenty-eight-days': 28,
  'prior-to-check-in-thirty-one-days': 31,
  'prior-to-check-in-forty-five-days': 45,
  'prior-to-check-in-sixty-days': 60,
  'post-purchase-seven-days': 7,
  'credit-only-prior-to-check-in-fourteen-days': 14,
  'partner-policy-prior-to-check-in-twenty-one-days': 21,
  'flexible-cancellation-forty-five-days': 45,
}
