import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import HideVisually from 'components/Common/HideVisually'
import config from 'constants/config'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import LogoIcon from './LogoIcon'
import { ResponsiveLogoProps } from './logos/ResponsiveLuxuryEscapesLogo'

const LogoLink = styled(ClickableLink)`
  display: flex;
  overflow: hidden;
  height: ${() => rem(config.logo.iconHeight)};

  > * + * {
    margin-left: ${rem(8)};
  }
`
interface Props extends ResponsiveLogoProps {
  className?: string;
}

function Logo(props: Props) {
  const {
    className,
    mobileVariant,
    tabletVariant,
  } = props

  const logoConfig = config.logo

  return (
    <LogoLink
      className={className}
      to={config.header.logo.to}
    >
      <LogoIcon
        mobileVariant={mobileVariant}
        tabletVariant={tabletVariant}
      />
      <HideVisually>{logoConfig.alt}</HideVisually>
    </LogoLink>
  )
}

export default React.memo(Logo)
