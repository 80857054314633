import { baseThemeShadows } from './shadows'
import luxuryEscapesTheme, { luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'

export const leBusinessTravellerDefaultPalette: App.Palette = {
  ...luxuryEscapesDefaultPalette,
}

export const leBusinessTravellerInversePalette: App.Palette = {
  ...luxuryEscapesInversePalette,
}

export const leBusinessTravellerShadows: App.ThemeShadows = { ...baseThemeShadows }

const leBusinessTravellerTheme: App.Theme = {
  ...luxuryEscapesTheme,
}

export default leBusinessTravellerTheme
