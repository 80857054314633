import React, { useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { isLoggedIn as isLoggedInSelector } from 'selectors/accountSelectors'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { useAppSelector } from 'hooks/reduxHooks'
import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import MasterModalContext from 'contexts/MasterModalContext'
import ModalContext from 'contexts/ModalContext'
import useToggle from 'hooks/useToggle'
import { matchPath } from 'react-router'
import { useIsMobileScreen } from 'lib/web/deviceUtils'

const ShowLoginExcludedRoutes = [
  '/:regionCode/offer',
]

export const MODAL_OPENED_TIMEOUT_IN_SECONDS = 10
export const LOGIN_MODAL_DELAY_IN_SECONDS = 30
const LOGIN_MODAL_EXPIRATION_TIME_IN_MINUTES = 60
const LOCAL_STORAGE_TIMER_NAME = 'loginModalExpirationTime'

function useShowLogin() {
  const [scrolled, setScrolled] = useState(false)
  const [timerExpired, setTimerExpired] = useState<boolean>()
  const [modalRecentlyOpened, setModalRecentlyOpened] = useState<boolean>(false)
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const showModal = useContext(ModalContext)
  const modalState = useContext(MasterModalContext)
  const [timeoutState, setTimeoutState] = useState<number | undefined>()

  const isMobile = useIsMobileScreen()
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isMobileAndBlacklistedRoute = useMemo(() => {
    return isMobile && matchPath(currentPath, { path: ShowLoginExcludedRoutes, exact: false })
  }, [currentPath, isMobile])

  useEffect(() => {
    if (isLoggedIn || scrolled) {
      return
    }

    const handleScroll = () => {
      setScrolled(true)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLoggedIn, scrolled, isMobileAndBlacklistedRoute])

  useEffect(() => {
    if (isMobileAndBlacklistedRoute && timeoutState) {
      clearTimeout(timeoutState)
    } else {
      setScrolled(false)
    }
  }, [isMobileAndBlacklistedRoute, timeoutState])

  const { value: isDelayed, off: finishDelay } = useToggle(true)
  useEffect(() => {
    if (!isLoggedIn && scrolled) {
      setTimeoutState(window.setTimeout(finishDelay, LOGIN_MODAL_DELAY_IN_SECONDS * 1000))
    }
    return () => {
      clearTimeout(timeoutState)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishDelay, isLoggedIn, scrolled])

  useEffect(() => {
    let openOtherModalTimeout

    if (modalState.open) {
      setModalRecentlyOpened(true)
    }

    if (!modalState.open && modalRecentlyOpened) {
      openOtherModalTimeout = setTimeout(() => {
        setModalRecentlyOpened(false)
      }, MODAL_OPENED_TIMEOUT_IN_SECONDS * 1000)
    }

    return () => {
      clearTimeout(openOtherModalTimeout)
    }
  }, [modalState.open, modalRecentlyOpened])

  // To prevent the login modal popping up straight away for users who are sent to a new tab, we set a time in their local storage and only setTimerExpired if it's been more than an hour
  useEffect(() => {
    const timerExpiration = getLocalStorage(LOCAL_STORAGE_TIMER_NAME) ?? undefined
    const now = new Date().getTime()

    if (now >= timerExpiration) {
      setTimerExpired(true)
    } else {
      const timeoutId = setTimeout(() => {
        setTimerExpired(true)
      }, timerExpiration - now)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [])

  useEffect(() => {
    if (!isLoggedIn && !isDelayed && timerExpired && !modalRecentlyOpened && !modalState.open) {
      showModal(<AccountAccessModal />)
      setLocalStorage(LOCAL_STORAGE_TIMER_NAME, moment().add(LOGIN_MODAL_EXPIRATION_TIME_IN_MINUTES, 'minutes').toDate().getTime().toString())
      setTimerExpired(false)
    }
  }, [modalRecentlyOpened, isDelayed, isLoggedIn, showModal, timerExpired, modalState])
}

export default useShowLogin
