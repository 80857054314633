import { sum } from 'lib/array/arrayUtils'
import { countOccupantsForProperty } from 'lib/offer/occupancyUtils'

export function isReservationEmpty(reservation: App.Occupants) {
  return reservation.adults === 0 &&
  !reservation.childrenAge?.length &&
  reservation.children === 0 &&
  reservation.infants === 0
}

export function extractOccupantsFromReservation(reservation?: App.Occupants) {
  if (!reservation || isReservationEmpty(reservation)) {
    return []
  }
  return [{
    adults: reservation.adults,
    children: reservation.children,
    infants: reservation.infants,
    childrenAge: reservation.childrenAge,
  }]
}

export function isReservationAvailableForRoom(
  occupancy: App.Occupants,
  capacities: Array<App.PackageRoomCapacity>,
  offer: App.Offer | App.OfferSummary | App.BedbankOffer | App.BundledOffer,
) {
  let property
  if (offer.type !== 'bedbank_hotel') {
    property = offer.property
    if (offer.property?.useDynamicOccupancy && !capacities.length) {
      return true
    }
  }

  const transformedOccupants = countOccupantsForProperty([occupancy], property)

  return capacities.some(cap =>
    transformedOccupants.adults <= cap.adults &&
    (!transformedOccupants?.children || transformedOccupants.children <= cap.children) &&
    (!transformedOccupants?.infants || transformedOccupants.infants <= cap.infants),
  )
}

export function hasIncludedGuestsExceeded(
  occupancy: App.Occupants,
  pkg: App.Package,
  offer: App.Offer | App.OfferSummary | App.BundledOffer,
) {
  if (pkg.roomRate?.includedGuests.length) {
    const transformedOccupants = countOccupantsForProperty([occupancy], offer.property)
    return !pkg.roomRate.includedGuests.some(included =>
      included.adults >= transformedOccupants.adults &&
      included.children >= (transformedOccupants?.children ?? 0) &&
      included.infants >= (transformedOccupants?.infants ?? 0),
    )
  }

  return false
}

export function includedGuestsExceededItems(
  guests: Array<App.Occupants>,
  pkg: App.Package,
  offer: App.Offer | App.OfferSummary | App.BundledOffer,
) {
  return guests.some(guests => guests.adults >= 1 &&
    hasIncludedGuestsExceeded(guests, pkg, offer))
}

export function getExtraGuests(
  occupancy: App.Occupants,
  pkg: App.Package,
  offer: App.Offer | App.OfferSummary | App.BundledOffer,
) {
  if (hasIncludedGuestsExceeded(occupancy, pkg, offer)) {
    const transformedOccupants = countOccupantsForProperty([occupancy], offer.property)
    return pkg.roomRate?.includedGuests.map(included => ({
      adults: Math.max(transformedOccupants.adults - included.adults, 0),
      children: Math.max((transformedOccupants.children ?? 0) - included.children, 0),
      infants: Math.max((transformedOccupants.infants ?? 0) - included.infants, 0),
    }))
  }

  return []
}

export const getRoomExtraGuestSurcharge = (
  pkg: App.Package,
  occupancy: App.Occupants,
  offer: App.Offer | App.OfferSummary | App.BundledOffer,
) => {
  const extraGuests = getExtraGuests(occupancy, pkg, offer)

  if (pkg.roomRate?.extraGuestSurcharges.length && extraGuests && extraGuests.length > 0) {
    const amounts = extraGuests.reduce((acc, extraGuest) => {
      const amount = pkg.roomRate?.extraGuestSurcharges.reduce((acc, surcharge) => {
        const adults = surcharge.adultAmount * extraGuest.adults
        const children = surcharge.childAmount * extraGuest.children
        const infants = surcharge.infantAmount * extraGuest.infants
        return acc + adults + children + infants
      }, 0)

      if (amount && amount > 0) {
        acc.push(amount)
      }

      return acc
    }, [] as Array<number>)

    return amounts.length > 0 ? Math.min(...amounts) * pkg.duration : 0
  }

  return 0
}

export const getSumExtraGuestsSurcharge = (
  occupancies: Array<App.Occupants>,
  offer: App.Offer | App.OfferSummary | App.BundledOffer,
  pkg?: App.Package,
) => {
  if (pkg?.roomRate?.extraGuestSurcharges.length && occupancies?.length > 0) {
    return sum(occupancies, occupancy => getRoomExtraGuestSurcharge(pkg, occupancy, offer))
  }

  return 0
}
