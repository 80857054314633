import {
  API_CALL_SUCCESS,
  CLEAR_AUTH_ERROR,
  SET_CUSTOMER_VALUE,
  SET_RECENTLY_USED_AIRPORT_CODE,
  SYNC_USER_DETAILS,
} from 'actions/actionConstants'

import { GSI_SCRIPT_LOADED, API_CALL, SAVE_CUSTOMER_SIGNATURE, DELETE_CUSTOMER_SIGNATURE } from './actionConstants'
import {
  FETCH_AVAILABLE_CREDIT,
  FETCH_PAYMENT_CARDS,
  USER_LOGIN,
  USER_REGISTRATION,
  UPDATE_USER_DETAILS,
  USER_LOGOUT,
  UPDATE_ACCOUNT_PARTNERSHIP,
  USER_FORGOT_PASSWORD,
  LINK_PARTNERSHIP_ACCOUNT,
  FETCH_STRIPE_PAYMENT_CARDS,
  CHECK_LEGACY_ORDERS_FOR_CURRENT_USER,
  CHECK_USER_EXISTS,
  USER_RESET_PASSWORD,
  FETCH_CUSTOMER_DETAILS,
  DELETE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS, SEND_VERIFICATION_CODE,
} from './apiActionConstants'
import { getAvailableCredit, getSavedCardsFromStripe, getLedLegacyOrders, getSavedCardsOnStripe } from 'api/order'
import { getSavedStripeCards } from 'api/payment'
import * as authService from 'api/auth'
import { sendResetPasswordEmail, updatePartnershipDetails, resetPassword } from 'api/auth'
import { setTimeUserLastSeenSnackbar } from 'storage/creditsSnackbar'
import { getCreditSnackbar, shouldShowCreditSnackbar } from 'lib/customer/creditSnackbarUtils'
import config from 'constants/config'
import { replaceWithRegion } from './NavigationActions'
import { deleteCustomerDetails, getCustomerDetails, updateCustomerDetails } from 'api/traveller'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import ReCAPTCHA from 'react-google-recaptcha'
import { isStripePaymentElementCardAvailable, isStripe3dsV2Available } from 'checkout/selectors/payment/paymentType'
import { replace } from 'connected-react-router'

const HIGH_VALUE_OFFER_AMOUNT = 4999
const MID_VALUE_OFFER_AMOUNT = 2999

export function getUserKeyFromPhone(phoneNumber: App.PhoneNumber): string {
  return `+${phoneNumber.prefix}${phoneNumber.phone}`
}

export function checkUserExists(
  user: { email?: string, phoneNumber?: App.PhoneNumber},
  recaptchaApi?: ReCAPTCHA | null,
) {
  return (dispatch, getState) => {
    const key = user.email ?? (user.phoneNumber ? getUserKeyFromPhone(user.phoneNumber) : undefined)
    if (!key) {
      return
    }
    const state = getState() as App.State
    // no need to check again, we've already done so
    if (state.auth.users[key]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: CHECK_USER_EXISTS,
      request: async() => {
        let recaptchaData
        if (recaptchaApi) {
          recaptchaData = await recaptchaApi.executeAsync()
        }
        return authService.checkUserExists(user, recaptchaData)
      },
      email: user.email,
      phoneNumber: user.phoneNumber,
      key,
    })
  }
}

export function loginNativeApp() {
  return {
    type: API_CALL,
    api: USER_LOGIN,
    request: () => authService.webViewAccountAccess(),
  }
}

export function login(
  user: App.User,
  password: string,
  recaptchaData: string,
) {
  return {
    type: API_CALL,
    api: USER_LOGIN,
    request: () => authService.login(user, password, recaptchaData),
    source: user.email ? 'email' : 'phone',
  }
}

export function loginGoogle(data) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.loginGoogle(data, state.geo.currentRegionCode),
      source: 'google',
    })
  }
}

export function loginFacebook(data) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.loginFacebook(data, state.geo.currentRegionCode),
      source: 'facebook',
    })
  }
}

export function loginApple(data) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.loginApple(data, state.geo.currentRegionCode),
      source: 'apple',
    })
  }
}

const registerMethods = {
  phone: authService.register,
  email: authService.register,
  google: authService.loginGoogle,
  facebook: authService.loginFacebook,
  apple: authService.loginApple,
}

export function register(userData: Partial<App.AuthAccount>, source: App.SignUpAuthSource) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const registerData = {
      ...userData,
      utmAdgroup: state.utm.adgroup,
      utmCampaign: state.utm.campaign,
      utmContent: state.utm.content,
      utmMedium: state.utm.medium,
      utmSource: state.utm.source,
      utmTerm: state.utm.term,
    }

    dispatch({
      type: API_CALL,
      api: USER_REGISTRATION,
      source,
      request: () => {
        return registerMethods[source](registerData, state.geo.currentRegionCode).then(res => {
          if (res.accessToken && res.account.givenName) {
            // WL travel brands do not need Email Verification
            const verificationEmailMessage = config.BRAND === 'luxuryescapes' ?
              'Check your email inbox to verify your account' :
              ''
            showSnackbar(`${verificationEmailMessage}`, 'success', { heading: `Welcome to ${config.title}` })
          }
          return res
        })
      },
    })
  }
}

export function resetUserPassword(token: string, password: string, callbackPath?: string) {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: USER_RESET_PASSWORD,
      request: () => resetPassword({ token, password }).then(data => {
        showSnackbar('Your password has been successfully reset', 'success')
        dispatch(callbackPath ? replace(callbackPath) : replaceWithRegion('/'))

        dispatch({
          type: API_CALL_SUCCESS,
          api: USER_LOGIN,
          data,
        })

        return data
      }),
    })
  }
}

export function forgotPassword(email: string, callbackPath?: string) {
  return {
    type: API_CALL,
    api: USER_FORGOT_PASSWORD,
    request: () => sendResetPasswordEmail(email, callbackPath),
  }
}

export function linkPartnershipAccount(partnershipPrefix: string, details: any) {
  return {
    type: API_CALL,
    api: LINK_PARTNERSHIP_ACCOUNT,
    request: () => updatePartnershipDetails({ [partnershipPrefix]: details }),
    prefix: partnershipPrefix,
    details,
  }
}

export function authLogout() {
  return {
    type: API_CALL,
    api: USER_LOGOUT,
    request: () => authService.logout(),
  }
}

export function fetchAvailableCreditAndOpenSnackbar(currencyCode?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const memberId = state.auth.account.memberId
    if (!memberId) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_AVAILABLE_CREDIT,
      request: () => getAvailableCredit(
        memberId,
        currencyCode ?? state.geo.currentCurrency,
      ).then(resp => {
        if (!resp.balance) return resp
        const {
          geo: { currentCurrency, currentRegionCode },
          router: { location: { pathname } },
          config: { headlessMode },
        } = state

        if (shouldShowCreditSnackbar(resp.balance, currentCurrency, pathname, memberId, headlessMode)) {
          const creditSnackbar = getCreditSnackbar(resp.balance, currentCurrency, currentRegionCode)
          showSnackbar(creditSnackbar.message, 'success', { heading: creditSnackbar.heading })
          setTimeUserLastSeenSnackbar(memberId)
        }
        return resp
      }),
      currencyCode: currencyCode ?? state.geo.currentCurrency,
    })
  }
}

export function fetchAvailableCredit(currencyCode?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const memberId = state.auth.account.memberId
    if (!memberId) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_AVAILABLE_CREDIT,
      request: () => getAvailableCredit(
        memberId,
        currencyCode ?? state.geo.currentCurrency,
      ),
      currencyCode: currencyCode ?? state.geo.currentCurrency,
    })
  }
}

export function openCreditSnackbar() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const {
      geo: { currentCurrency, currentRegionCode },
      router: { location: { pathname } },
      auth: { account: { balance, memberId } },
      config: { headlessMode },
    } = state

    if (memberId && shouldShowCreditSnackbar(balance, currentCurrency, pathname, memberId, headlessMode)) {
      const creditSnackbar = getCreditSnackbar(balance, currentCurrency, currentRegionCode)
      showSnackbar(creditSnackbar.message, 'success', { heading: creditSnackbar.heading })
      setTimeUserLastSeenSnackbar(memberId)
    }
  }
}

export function setRecentlyUsedAirportCode(airportCode: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.auth.account.memberId) {
      dispatch({
        type: API_CALL,
        api: SET_RECENTLY_USED_AIRPORT_CODE,
        request: () => authService.updateUserDetails({ recentlyUsedAirportCode: airportCode }),
        airportCode,
      })
    }
  }
}

export function fetchPaymentCards() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (!isStripePaymentElementCardAvailable(state)) {
      dispatch({
        type: API_CALL,
        api: FETCH_PAYMENT_CARDS,
        request: () =>
          getSavedCardsFromStripe(state.auth.account.memberId),
      })
    }
  }
}

export function fetchStripePaymentCards() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    dispatch({
      type: API_CALL,
      api: FETCH_STRIPE_PAYMENT_CARDS,
      request: () => (isStripePaymentElementCardAvailable(state) || isStripe3dsV2Available(state)) ?
        getSavedStripeCards(state.geo.currentCurrency) :
        getSavedCardsOnStripe(state.auth.account.memberId),
    })
  }
}

/**
 * Saves the details given for the current user in the API and updates state
 * with the newest details
 * @param userDetails The user details to save to the API
 * @returns
 */
export function updateUserDetails(userDetails: Partial<App.AuthAccount>, supportPhoneNumber: string | null = null) {
  return {
    type: API_CALL,
    api: UPDATE_USER_DETAILS,
    request: () => authService.updateUserDetails(userDetails, supportPhoneNumber),
  }
}

/**
 * Manually sync a set of user details into redux
 * Used for when you absolutely have to call the update api method yourself
 * @param userDetails The user details to sync to redux
 */
export function syncUserDetails(userDetails: Partial<App.AuthAccount>) {
  return {
    type: SYNC_USER_DETAILS,
    userDetails,
  }
}

export function updateAccountPartnership(partnershipPrefix: string, details: App.PartnershipsMap) {
  return {
    type: API_CALL,
    api: UPDATE_ACCOUNT_PARTNERSHIP,
    request: () => authService.updatePartnershipDetails(details).then(() => details),
    partnershipPrefix,
  }
}

export function hasLegacyOrdersForCurrentUser() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { memberId } = state.auth.account
    if (memberId && !state.auth.ledLegacyOrders.hasFetched) {
      dispatch({
        type: API_CALL,
        api: CHECK_LEGACY_ORDERS_FOR_CURRENT_USER,
        request: () => getLedLegacyOrders(memberId),
      })
    }
  }
}

export function fetchTravellerDetails() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { memberId } = state.auth.account
    if (!memberId) { return }
    dispatch({
      type: API_CALL,
      api: FETCH_CUSTOMER_DETAILS,
      request: () => getCustomerDetails(memberId),
    })
  }
}

export function deleteTravellerDetails() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { memberId } = state.auth.account
    if (!memberId) { return }
    dispatch({
      type: API_CALL,
      api: DELETE_CUSTOMER_DETAILS,
      request: () => deleteCustomerDetails(memberId),
    })
  }
}

export function updateTravellerDetails(travellerDetails: App.CustomerDetails) {
  return {
    type: API_CALL,
    api: UPDATE_CUSTOMER_DETAILS,
    request: () => updateCustomerDetails({
      ...travellerDetails,
    }),
  }
}

export function saveCustomerSignature(signature: string) {
  return {
    type: SAVE_CUSTOMER_SIGNATURE,
    signature,
  }
}

export function deleteCustomerSignature() {
  return {
    type: DELETE_CUSTOMER_SIGNATURE,
  }
}

export function GSILoadComplete() {
  return {
    type: GSI_SCRIPT_LOADED,
  }
}

export function sendOTPVerificationCode(user: App.User) {
  return {
    type: API_CALL,
    api: SEND_VERIFICATION_CODE,
    request: () => authService.sendOTPVerificationCode(user),
  }
}

export function validateOTPVerificationCode(user: App.User, code: string) {
  return {
    type: API_CALL,
    api: USER_LOGIN,
    request: () => authService.validateOTPVerificationCode(user, code),
    source: 'otp',
  }
}

export function setCustomerValue(price: number) {
  const value = price >= HIGH_VALUE_OFFER_AMOUNT ? 'highValue' : price >= MID_VALUE_OFFER_AMOUNT ? 'midValue' : undefined
  return {
    type: SET_CUSTOMER_VALUE,
    value,
  }
}

/**
 * Call to clear any existing auth errors
 */
export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  }
}
