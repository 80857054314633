import { ArrowDirection } from 'components/Luxkit/Carousel/CardCarousel'
import FullWidthCarousel from 'components/Luxkit/Carousel/FullWidthCarousel'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import React, { useCallback, useContext } from 'react'

interface Props extends React.ComponentProps<typeof FullWidthCarousel>{}

/**
  * This component is simply a wrapper around `Luxkit/Carousel/FullWidthCarousel`
  */
const FullWidthCarouselWithAnalytics = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const onEvent = useContext(OfferListEventsContext)
  const onArrowClick = useCallback((_e: React.MouseEvent<HTMLButtonElement>, arrow: ArrowDirection) => {
    const params = {
      action: 'pressed',
      subject: arrow === 'right' ? 'carousel next button' : 'carousel prev button',
      key: EventDataKey.ClientAction,
    }
    onEvent(OfferListEvents.clientAction, params)
  }, [onEvent])

  return <FullWidthCarousel
    {...props}
    ref={ref}
    onArrowClick={onArrowClick}
  />
})

FullWidthCarouselWithAnalytics.displayName = 'FullWidthCarouselWithAnalytics'

export default FullWidthCarouselWithAnalytics
