import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import cn from 'clsx'

import { breakpointSizes, mediaQueryUp, mediaQueryDown, mediaQueryOnly } from 'components/utils/breakpoint'

type Size = keyof typeof breakpointSizes
const sizes = Object.keys(breakpointSizes) as Array<Size>
const precompiledCss = sizes.map(size => css`
&.hidedown-${size} {
  ${mediaQueryDown[size]} { display: none; }
}

&.hideup-${size} {
  ${mediaQueryUp[size]} { display: none; }
}

&.hideonly-${size} {
  ${mediaQueryOnly[size]} { display: none; }
}
`)

const UpDownBreakpoint = styled.div`
  ${precompiledCss}
`

interface Props extends React.HTMLAttributes<HTMLElement> {
  min?: Size;
  max?: Size;
  only?: Size;
  not?: Size;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  className?: string;
}

const CSSBreakpoint = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const { min, max, only, not, className, children, ...rest } = props

  let hideDownSize: Size | undefined
  let hideUpSize: Size | undefined

  const onlyOrMin = only ?? min
  const onlyOrMax = only ?? max

  if (onlyOrMin) {
    const minIndex = sizes.indexOf(onlyOrMin)
    hideDownSize = sizes[minIndex - 1]
  }
  if (onlyOrMax) {
    const maxIndex = sizes.indexOf(onlyOrMax)
    hideUpSize = sizes[maxIndex + 1]
  }

  const classes = cn(className, {
    [`hidedown-${hideDownSize}`]: hideDownSize,
    [`hideup-${hideUpSize}`]: hideUpSize,
    [`hideonly-${not}`]: not,
  })

  return <UpDownBreakpoint {...rest} className={classes} ref={ref}>
    {children}
  </UpDownBreakpoint>
})

CSSBreakpoint.displayName = 'CSSBreakpoint'

export default CSSBreakpoint
