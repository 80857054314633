import optimizely, { Client, OptimizelyUserContext, setLogger, enums } from '@optimizely/optimizely-sdk'

import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { isLEStaffOrAdmin, isLoggedIn } from 'selectors/accountSelectors'
import { isMobileSel } from 'selectors/configSelectors'
import { reportClientError } from 'services/errorReportingService'
import config from 'constants/config'

let client: Client | null
export let userContext: OptimizelyUserContext
let optimizelyResolver

/**
 * The following code is used to wait for Optimizely to be initialized and ready
 *
 * The optimizelyResolver variable is used to make the resolver of the subsequent
 * promise accessible to any other function in this file
 * The optimizelyIsReady variable is used to wait on another promise, by making
 * its resolver public to the local file, it can be resolved as soon as something else
 * calls the optimizelyResolver (with true or false in our case)
**/

export const optimizelyIsReady = new Promise<boolean>((resolve) => {
  optimizelyResolver = resolve
})

function getClient(datafile: string) {
  if (!client) {
    client = optimizely.createInstance({
      datafile,
    })

    setLogger({
      log: (level, message) => {
        if (level !== enums.LOG_LEVEL.ERROR) return

        if (message.includes('is not in datafile')) return
        if (message.includes('Datafile is invalid')) return

        reportClientError(new Error(message))
      },
    })
  }

  return client
}

/**
 * Init function
*/
export function init(store) {
  if (!config.OPTIMIZELY_SDK_KEY) {
    optimizelyResolver(false)
    return
  }

  let datafile
  if (typeof window.__OPTIMIZELY_DATAFILE__ !== 'undefined') {
    datafile = JSON.parse(window.__OPTIMIZELY_DATAFILE__)
  }

  let optimizelyClient
  if (datafile) {
    optimizelyClient = getClient(datafile)
  }

  const state = store.getState()

  const userId = getDomainUserId()
  const inStoreMode = state.config.storeMode
  const isSpoofed = state.auth.account.isSpoofed
  const regionCode = state.geo.currentRegionCode
  const utmSource = state.utm.source || ''
  const utmMedium = state.utm.medium || ''
  const utmCampaign = state.utm.campaign || ''
  const utmTerm = state.utm.term || ''
  const utmContent = state.utm.content || ''

  const optimizelyEnabled = userId && !inStoreMode

  if (!optimizelyEnabled || !datafile) {
    optimizelyResolver(false)
    return
  }

  const attributes = {
    userId,
    isSpoofed,
    leEmailDomain: isLEStaffOrAdmin(state),
    regionCode,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    deviceName: isMobileSel(state) ? 'mobile' : 'desktop',
    isLoggedIn: isLoggedIn(state),
  }

  return optimizelyClient.onReady().then((result) => {
    if (result.success) {
      userContext = optimizelyClient.createUserContext(userId, attributes)!
      optimizelyResolver(true)
    } else {
      optimizelyResolver(false)
    }
  })
}
