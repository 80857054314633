export default function getInclusionMinStayText(inclusion: App.TileInclusion | App.PackageInclusion) {
  const minDuration = inclusion?.minDuration
  const maxDuration = inclusion?.maxDuration

  if (minDuration && minDuration !== 0 && !(minDuration === maxDuration)) {
    return `(${inclusion.minDuration}+ nights)`
  }

  return ''
}
