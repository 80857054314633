import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import TileInclusions from '../Support/OfferTileInclusions'
import SoldOutMessage from '../Support/SoldOutMessage'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import { nonNullable } from 'lib/array/arrayUtils'
import TextButton from 'components/Luxkit/Button/TextButton'
import BundleOfferTilePricing from '../Support/BundleOfferTilePricing'
import BundleOfferLocation from '../BundleOfferLocation'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import Group from 'components/utils/Group'
import { Main, MainImageSegment, OfferOverlayContainer, StyledOfferBadge, DetailSegment } from './SearchTileStyles'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import cn from 'clsx'
import { useLocation } from 'react-router'
import WalledContent from 'components/Common/WalledContent'

const PriceDetails = styled(VerticalSpacer)`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: ${rem(16)};
  position: relative;
  width: auto;
  min-height: ${rem(84)};

  ${mediaQueryUp.desktop} {
    margin-left: ${rem(20)};
  }

  &.landing-page-test {
    margin-top: 0;
  }
`

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  flex-direction: column;
  min-width: ${rem(180)};
  align-items: flex-start;
`

const AlignedLoader = styled(LoadingIndicator)`
  align-items: flex-start;
`

interface Props {
  offer: App.BundleOffer;
  productClick?: (offer: App.BundleOffer) => void;
  filters?: App.OfferListFilters;
  soldOut: boolean;
  bestPricePackage: App.BundlePackageWithPrice;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  onImageChange?: (idx: number, image?: App.Image) => void
  onImageLoad?: () => void
}

function SearchBundleOfferTileExpanded(props: Props) {
  const {
    offer,
    filters,
    soldOut,
    bestPricePackage,
    eagerLoadFirstImage,
    offerUrl,
    onImageChange,
    onImageLoad,
  } = props

  const {
    name,
    tileDescription,
    tileInclusions,
    tileInclusionsHeading,
  } = offer

  const inclusions = tileDescription ?? offer.inclusions
  const inclusionsList = tileInclusions
  const inclusionsHeading = tileInclusionsHeading || 'Your handpicked inclusions:'

  const { searchTargetLabel, offerDistanceFromSearchTarget } = useContext(GlobalSearchStateContext)
  const searchTargetDistance = offerDistanceFromSearchTarget?.[offer.id]
  const isMapPage = useLocation().pathname.includes('map')

  // Eg. `Melbourne`, `Victoria` & `Kuta`, `Bali`
  const locationCaption = useMemo(() =>
    Object.values(offer.bundledOffers ?? {}).map(offer =>
      nonNullable([
        offer.locationHeading,
        offer.locationSubheading,
      ]).join(', '))
      .join(' & ')
  , [offer.bundledOffers])

  return (
    <Main className={cn({ mapTile: isMapPage })}>
      <MainImageSegment>
        <ImageCarousel
          images={offer.images}
          eagerLoadFirstImage={eagerLoadFirstImage}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
        <OfferOverlayContainer>
        </OfferOverlayContainer>
        {offer.badge && <StyledOfferBadge badge={offer.badge} dimensions={{ width: 70, height: 70 }} />}
      </MainImageSegment>
      <div>
        <DetailSegment className={cn({ mapTile: isMapPage })}>
          <Group direction="vertical" gap={8}>
            <Group direction="vertical" gap={4}>
              <OfferTileLocationSection
              location={locationCaption}
              mapSearchUrl=""
              searchTargetDistance={searchTargetDistance}
              searchTargetLabel={searchTargetLabel}
            />
              <div>
                <ProductTypeLabel productType={offer.productType} />
              </div>
              <BundleOfferLocation offer={offer} />
              <Subtitle variant="subtitle3">{name}</Subtitle>
            </Group>
            {(inclusions || (inclusionsList && inclusionsList?.length > 0)) &&
              <TileInclusions
                offerTitle={offer.property?.name ? offer.property.name : offer.name}
                offerURL={offerUrl}
                content={inclusions}
                tileInclusionsList={inclusionsList}
                heading={inclusionsHeading}
                filters={filters}
              />
            }
          </Group>
          <PriceDetails gap={8}>
            <PriceWrapper>
              <Group
                fullWidth
                direction="vertical"
                verticalAlign="start"
                horizontalAlign="start"
                gap={16}
              >
                {bestPricePackage && <>
                  <BundleOfferTilePricing
                      offer={offer}
                      pkg={bestPricePackage}
                      offerUrl={offerUrl}
                      rooms={filters?.rooms}
                      align="left"
                    />
                  <TextButton fit="full-width" kind="primary">
                    View offer
                  </TextButton>
                </>}
              </Group>
            </PriceWrapper>
            <WalledContent enforced={!soldOut} wall={null}>
              <SoldOutMessage offer={offer} filters={filters} />
            </WalledContent>
            <WalledContent enforced={!!bestPricePackage} wall={null}>
              <AlignedLoader floating opaque />
            </WalledContent>
          </PriceDetails>
        </DetailSegment>
      </div>
    </Main>
  )
}

SearchBundleOfferTileExpanded.defaultProps = {
  filters: {},
}

export default SearchBundleOfferTileExpanded
