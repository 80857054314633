import {
  UPDATE_APPLE_PAY_AVAILABLE,
  UPDATE_GOOGLE_PAY_AVAILABLE,
  API_CALL,
} from './actionConstants'
import { AppDispatch } from '../store'
import { FETCH_MERCHANT_FEE_CONFIG, FETCH_PAYTO_SUPPORTED_BANKS, FETCH_DEPOSIT_CONFIG } from './apiActionConstants'
import { getMerchantFeeConfig, getPayToSupportedBanks, getDepositConfig } from 'api/payment'

export function updateCanPayWithApplePay(flag: boolean) {
  return {
    type: UPDATE_APPLE_PAY_AVAILABLE,
    value: flag,
  }
}

export function updateCanPayWithGooglePay(flag: boolean) {
  return {
    type: UPDATE_GOOGLE_PAY_AVAILABLE,
    value: flag,
  }
}

export function fetchMerchantFeeConfig() {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const { config } = getState()
    if (config.merchantFeeConfigs.length !== 0) return

    dispatch({
      type: API_CALL,
      api: FETCH_MERCHANT_FEE_CONFIG,
      request: () => getMerchantFeeConfig(),
    })
  }
}

export function fetchPayToSupportedBanks() {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const { config } = getState()
    if (config.payToSupportedBanks.length !== 0) return

    dispatch({
      type: API_CALL,
      api: FETCH_PAYTO_SUPPORTED_BANKS,
      request: () => getPayToSupportedBanks(),
    })
  }
}

export function fetchDepositConfig() {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const { config } = getState()
    if (config.depositConfigs) return

    dispatch({
      type: API_CALL,
      api: FETCH_DEPOSIT_CONFIG,
      request: () => getDepositConfig(),
    })
  }
}
