import { arrayToObject, partitionBy, sum } from 'lib/array/arrayUtils'
import { isOneWayFlight } from 'lib/flights/flightUtils'

/**
 * For some providers (travelfusion), `svc-flights` cannot return us the baggage data.
 * Instead, it is initialised with a "dummy" addon.
 */

export function isDummyBaggage(addon: App.JourneyAddon): boolean {
  return addon.id === '0kg'
}

export function containsDummyBaggageOptions(journey: App.Journey): boolean {
  const departureBaggageOptions = journey.departing?.extras?.baggage ?? []
  const returningBaggageOptions = journey.returning?.extras?.baggage ?? []

  const baggageList = [
    ...departureBaggageOptions,
    ...(isOneWayFlight(journey?.fareType ?? '') ? [] : returningBaggageOptions),
  ]

  return baggageList.some(isDummyBaggage)
}

export function hasBaggageData(journeyFlight: App.JourneyFlight) {
  return journeyFlight?.extras?.baggage
}

/**
 * Extracts baggage options from a journeyFlight
 *
 * @remarks
 * $0 cost options are considered as complementary baggage options
 *
 * @returns An array [selectableOptions, complementaryOptions]
 */
export function getBaggageOptions(journeyFlight: App.JourneyFlight): [Array<App.JourneyAddon>, Array<App.JourneyAddon>] {
  const list = journeyFlight?.extras?.baggage
  if (!list) { return [[], []] }
  return partitionBy(list, item => item.amount !== 0)
}

/**
 * Checks if a journeyFlight has complementary baggage
 * @remarks
 * Explicit field checks for Amadeus providers
 */
export function hasComplementaryBaggage(journeyFlight: App.JourneyFlight): boolean {
  return journeyFlight.flights[0].freeCheckedInBaggageIncluded
}

export function computeBaggageCost(
  addonsMap: { [key: string]: App.JourneyAddon },
  quantityMap: App.Checkout.BaggageQuantityMap,
): number {
  return Object.entries(quantityMap).reduce((acc, [baggageId, quantity]) => {
    const journeyAddon = addonsMap[baggageId]
    if (!journeyAddon) { return acc }

    return acc + journeyAddon.amount * quantity
  }, 0)
}

export function computeBaggageCostForPaxList(
  paxList: Array<App.Checkout.FlightPassenger>,
  journeyAddons: Array<App.JourneyAddon>,
  selectedBaggageMap: App.Checkout.PaxBaggageMap,
): number {
  const addonsMap = arrayToObject(journeyAddons, element => element.id, element => element)
  return Object.entries(selectedBaggageMap).reduce((acc, [paxId, baggageMap]) => {
    const pax = paxList.find(pax => pax.id === paxId)
    return pax ? acc + computeBaggageCost(addonsMap, baggageMap) : acc
  }, 0)
}

export function calculateTotalJourneyBaggageCost(itemView: App.Checkout.FlightItemView) {
  let result = 0

  if (itemView.departing && hasBaggageData(itemView.departing.journeyFlight)) {
    const { journeyFlight: flight, extras: selectedExtras } = itemView.departing
    if (flight.extras?.baggage) {
      result += computeBaggageCostForPaxList(itemView.passengers, flight.extras.baggage, selectedExtras.baggage)
    }
  }

  if (itemView.fareType === 'return' && itemView.returning && hasBaggageData(itemView.returning.journeyFlight)) {
    const { journeyFlight: flight, extras: selectedExtras } = itemView.returning
    if (flight.extras?.baggage) {
      result += computeBaggageCostForPaxList(itemView.passengers, flight.extras.baggage, selectedExtras.baggage)
    }
  }

  return result
}

export interface BaggageSelectionForm {
  checked: { [baggageId: string]: number | string };
  carryOn: { [baggageId: string]: number | string }; // Not used
}

/**
 * Transform baggage form objects into a quantity map
 */
export function transformToBaggageQuantityMap(form: BaggageSelectionForm): App.Checkout.PaxBaggageMap {
  return Object.entries(form).reduce((acc, [paxId, selections]) => {
    const formValue = selections.checked
    // Jetstar forms - 'formValue' is already in the appropriate structure
    if (typeof formValue === 'object') {
      const quantityMap = Object.fromEntries(Object.entries<number>(formValue))
      return { ...acc, [paxId]: quantityMap }
    }
    // Travelfusion forms - 'formValue' is a string
    if (formValue !== 'none') { return { ...acc, [paxId]: { [formValue]: 1 } } }
    return acc
  }, {})
}

export function combineBaggageQuantityMap(
  mapA: App.Checkout.BaggageQuantityMap,
  mapB: App.Checkout.BaggageQuantityMap,
): App.Checkout.BaggageQuantityMap {
  const intersectedKeys = Object.keys(mapA).filter(key => key in mapB)
  const intersectedQuantityMap = intersectedKeys.reduce((acc, key) => ({ ...acc, [key]: mapA[key] + mapB[key] }), {})
  return { ...mapA, ...mapB, ...intersectedQuantityMap }
}

export function aggregateBaggageSelectionCount(map: App.Checkout.PaxBaggageMap): App.Checkout.BaggageQuantityMap {
  return Object.values(map).reduce((acc, quantityMap) => combineBaggageQuantityMap(acc, quantityMap), {})
}

export function mapAddedBaggageToPaxMap(
  passengers: Array<App.Checkout.FlightPassenger>,
  baggage: Array<Array<App.AddedBaggage>>,
): App.Checkout.PaxBaggageMap {
  return passengers.reduce<App.Checkout.PaxBaggageMap>((acc, passenger, idx) => ({
    ...acc,
    [passenger.id]: baggage[idx].reduce(
      (acc, baggage) => ({
        ...acc,
        ...(baggage.count ? { [baggage.id]: baggage.count } : { [baggage.id]: 0 }),
      }),
      {},
    ),
  }), {})
}

export function getBaggageForFlight(
  flightId: string,
  passengers: Array<App.Passenger>,
): App.Checkout.FlightItemPaxBaggageMap {
  return {
    baggage: arrayToObject(passengers,
      passenger => passenger.id,
      passenger => arrayToObject(
        passenger.addedExtras?.baggage[flightId] ?? [],
        bag => bag.id,
        bag => bag.count,
      ),
    ),
    carryOnBaggage: arrayToObject(passengers,
      passenger => passenger.id,
      passenger => arrayToObject(
        passenger.addedExtras?.carryOnBaggage[flightId] ?? [],
        bag => bag.id,
        bag => bag.count,
      ),
    ),
  }
}

export function getCheckoutBaggageCount(baggageMap?: App.Checkout.PaxBaggageMap): number {
  if (!baggageMap) {
    return 0
  }

  const allBaggageCounts = Object.values(baggageMap).flatMap(baggage => Object.values(baggage))
  return sum(allBaggageCounts)
}
