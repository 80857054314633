import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_GIFT_CARD,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_VILLA,
} from 'constants/checkout'
import { RESERVATION_TYPE_BOOK_LATER, RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { PRODUCT_ID_NO_PROTECTION } from 'constants/insurance'
import { hasRoomTypePackageUpgrade } from 'lib/tours/tourUtils'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

const accommodationItemTypes = new Set([
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_CRUISE,
])

export function isInsuranceItem(item: App.Checkout.AnyItem): item is App.Checkout.InsuranceItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_INSURANCE
}

export function isInsuranceProtectionItem(item: App.Checkout.AnyItem): item is App.Checkout.InsuranceItem {
  return isInsuranceItem(item) && item.productId !== PRODUCT_ID_NO_PROTECTION
}

export function isNoProtectionInsuranceItem(item: App.Checkout.AnyItem): item is App.Checkout.InsuranceItem {
  return isInsuranceItem(item) && item.productId === PRODUCT_ID_NO_PROTECTION
}

export function isAccommodationItem(item: App.Checkout.AnyItem): item is App.Checkout.AccommodationItem {
  return accommodationItemTypes.has(item.itemType)
}

export function isBundleAndSaveItem(item: App.Checkout.AnyItem): item is App.Checkout.BundleAndSaveItem {
  return item.itemType === 'bundleAndSave'
}

export function isLEHotelItem(item: App.Checkout.AnyItem): item is App.Checkout.LEHotelItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL
}

export function isInstantBookingLEHotelItem(item: App.Checkout.AnyItem): item is App.Checkout.InstantBookingLEHotelItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL && item.reservationType === RESERVATION_TYPE_INSTANT_BOOKING &&
    !!item.checkIn && !!item.checkOut
}

export function isInstantLEHotelPostPurchaseItem(item: App.Checkout.AnyItem): item is App.Checkout.InstantBookingLEHotelPostPurchaseItem {
  return isInstantBookingLEHotelItem(item) && !!item.orderId && !!item.orderItemId
}

export function isBNBLLEHotelItem(item: App.Checkout.AnyItem): item is App.Checkout.BNBLLEHotelItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL && item.reservationType === RESERVATION_TYPE_BOOK_LATER
}

export function isBNBLHotelSetDatesItem(item: App.Checkout.AnyItem): item is App.Checkout.BNBLLEHotelSetDatesItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL && item.reservationType === RESERVATION_TYPE_INSTANT_BOOKING &&
         !!item.orderId && !!item.orderItemId
}

export function isInstantBookingHotelItem(item: App.Checkout.AnyItem): item is App.Checkout.InstantBookingItem {
  return !isBNBLLEHotelItem(item)
}

export function isBedbankItem(item: App.Checkout.AnyItem): item is App.Checkout.BedbankHotelItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_BEDBANK
}

export function isTourV1Item(item: App.Checkout.AnyItem): item is App.Checkout.LETourV1Item {
  return item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1
}

export function isTourV2Item(item: App.Checkout.AnyItem): item is App.Checkout.TourV2Item {
  return item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2
}

export function isTourV2ExperienceItem(item: App.Checkout.AnyItem): item is App.Checkout.TourV2ExperienceItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE
}

export function isTourItem(item: App.Checkout.AnyItem): item is App.Checkout.TourV2Item | App.Checkout.LETourV1Item {
  return item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2 || item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1
}

export function isTourV2ItemWithPackageUpgrade(item: App.Checkout.AnyItem): item is App.Checkout.TourV2Item {
  return item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2 && (!item.purchasableOption.roomType || hasRoomTypePackageUpgrade(item.purchasableOption.roomType))
}

export function isTourV2ItemWithoutRoom(item: App.Checkout.AnyItem): item is App.Checkout.TourV2Item {
  return item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2 && !item.purchasableOption.roomType
}

export function isCruiseItem(item: App.Checkout.AnyItem): item is App.Checkout.CruiseItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_CRUISE
}

export function isCarHireItem(item: App.Checkout.AnyItem): item is App.Checkout.CarHireItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_CAR_HIRE
}

export function isLEAccommodationItem(item: App.Checkout.AnyItem): item is App.Checkout.LEHotelItem | App.Checkout.LETourV1Item {
  return item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL || item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1
}

export function isExperienceItem(item: App.Checkout.AnyItem): item is App.Checkout.ExperienceItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_EXPERIENCE
}

export function isTransferItem(item: App.Checkout.AnyItem): item is App.Checkout.TransferItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_TRANSFER
}

export function isFlightItem(item: App.Checkout.AnyItem): item is App.Checkout.FlightItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_FLIGHT
}

export function isGiftCardItem(item: App.Checkout.AnyItem): item is App.Checkout.GiftCardItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_GIFT_CARD
}

export function isLuxPlusSubscriptionItem(item: App.Checkout.AnyItem): item is App.Checkout.LuxPlusSubscriptionItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION
}

export function isSubscriptionJoinItem(item: App.Checkout.AnyItem): item is App.Checkout.SubscriptionJoinItem {
  return item.itemType === 'subscription-join'
}

export function isOptOutLuxPlusSubscriptionItem(item: App.Checkout.AnyItem): item is App.Checkout.LuxPlusSubscriptionItem {
  return item.itemType === LUXURY_PLUS.NO_SUBSCRIPTION_TYPE
}

export function getConnectRoomsSpecialRequestMessage(contactName: string): string {
  return `All rooms under the name ${contactName}, are requested to be connecting rooms or within close proximity.`
}

export function getTourV2IdForCheckoutItem(item: App.Checkout.TourV2Item): string {
  return item.privateRequestKey ? `${item.offerId}-${item.privateRequestKey}` : item.offerId
}

export function isVillaItem(item: App.Checkout.AnyItem): item is App.Checkout.VillaItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_VILLA
}

export function isBookingProtectionItem(item: App.Checkout.AnyItem): item is App.Checkout.BookingProtectionItem {
  return item.itemType === CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION
}

export function isCruiseV1(item: App.Checkout.AnyItem, leOffers: Record<string, App.Offer | undefined>): boolean {
  if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1) {
    const offer = leOffers[item.offerId]
    return !!offer && !!offer.holidayTypes?.includes('Cruises')
  }
  return true
}

export function getItemOfferId(item?: App.Checkout.AccommodationItem) {
  if (!!item && isCruiseItem(item)) return `cruise:${item.offerId}`
  return item?.offerId
}
