import cn from 'clsx'
import OfferListOfferTile from 'components/OfferList/OfferListTiles/OfferListOfferTile'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useIsDesktopScreen } from 'lib/web/deviceUtils'
import { rem } from 'polished'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

const OfferCardTile = styled(OfferListOfferTile)`
  width: 75vw;

  ${mediaQueryUp.tablet} {
    width: 55vw;
  }

  ${mediaQueryUp.desktop} {
    width: 90vw;
    max-width: ${rem(1140)};
    min-height: ${rem(396)};

    &.non-interactive {
      pointer-events: none;

      /*
        We turn off pointer events to prevent users from interacting with items
        that are not fully visible but if they can see specific buttons (like 'view offer or 'save to trip')
        we still want them to be able to interact with those buttons.
      */
      button {
        pointer-events: auto;
      }
    }
  }
`

interface Props {
  position: number;
  offerId: string;
  className?: string;
  tileStyle?: 'search' | 'hero' | 'frontpage';
}

function OfferListOfferCard(props: Props) {
  const {
    offerId,
    position,
    className,
    tileStyle = 'hero',
  } = props
  const isDesktop = useIsDesktopScreen()
  const [inViewRef, inView] = useInView({
    skip: !isDesktop,
    threshold: 0.9,
  })

  return <OfferCardTile
    className={cn(className, { 'non-interactive': !inView })}
    offerId={offerId}
    tileStyle={tileStyle}
    position={position}
    lazyLoad={position > 1 ? 'offer-and-inview' : 'offer-or-inview'}
    eagerLoadImage
    ref={inViewRef}
  />
}

export default OfferListOfferCard
