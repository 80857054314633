import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS, CLEAR_LOYALTY_MEMBER_SUBSCRIPTION } from 'actions/actionConstants'
import { CREATE_LOYALTY_MEMBER_FREE_PREVIEW, FETCH_LOYALTY_MEMBER_FREE_PREVIEW_ELIGIBILITY, FETCH_LOYALTY_MEMBER_SUBSCRIPTION, FETCH_LOYALTY_PREFERRED_PAYMENT_METHOD, FETCH_LOYALTY_PRODUCT_SUBSCRIPTION, UPDATE_LOYALTY_MEMBER_SUBSCRIPTION } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialLoyaltyState: App.LoyaltyState = {
  member: {
    subscription: {
      item: undefined,
      fetching: false,
      error: undefined,
      preferredPaymentMethod: undefined,
    },
  },
  products: {
    subscription: {
      offers: undefined,
      fetching: false,
      error: undefined,
    },
    freePreview: {
      offer: undefined,
      fetching: false,
      error: undefined,
    },
  },
}

const apiRequests = reducerSwitch<App.LoyaltyState>({
  [FETCH_LOYALTY_PRODUCT_SUBSCRIPTION]: (state) => ({
    products: {
      ...state.products,
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LOYALTY_MEMBER_SUBSCRIPTION]: () => ({
    member: {
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [UPDATE_LOYALTY_MEMBER_SUBSCRIPTION]: () => ({
    member: {
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LOYALTY_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state) => ({
    products: {
      ...state.products,
      freePreview: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [CREATE_LOYALTY_MEMBER_FREE_PREVIEW]: () => ({
    member: {
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LOYALTY_PREFERRED_PAYMENT_METHOD]: (state) => ({
    member: {
      subscription: {
        ...state.member.subscription,
        preferredPaymentMethod: undefined,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.LoyaltyState>({
  [FETCH_LOYALTY_PRODUCT_SUBSCRIPTION]: (state, action) => ({
    products: {
      ...state.products,
      subscription: {
        offers: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LOYALTY_MEMBER_SUBSCRIPTION]: (_state, action) => ({
    member: {
      subscription: {
        item: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [UPDATE_LOYALTY_MEMBER_SUBSCRIPTION]: (_state, action) => ({
    member: {
      subscription: {
        item: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LOYALTY_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state, action) => ({
    products: {
      ...state.products,
      freePreview: {
        offer: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [CREATE_LOYALTY_MEMBER_FREE_PREVIEW]: (_state, action) => ({
    member: {
      subscription: {
        item: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LOYALTY_PREFERRED_PAYMENT_METHOD]: (state, action) => ({
    member: {
      subscription: {
        ...state.member.subscription,
        preferredPaymentMethod: action.data.paymentMethod,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.LoyaltyState>({
  [FETCH_LOYALTY_PRODUCT_SUBSCRIPTION]: (state, action) => ({
    products: {
      ...state.products,
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_LOYALTY_MEMBER_SUBSCRIPTION]: (_state, action) => ({
    member: {
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [UPDATE_LOYALTY_MEMBER_SUBSCRIPTION]: (_state, action) => ({
    member: {
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_LOYALTY_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state, action) => ({
    products: {
      ...state.products,
      freePreview: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [CREATE_LOYALTY_MEMBER_FREE_PREVIEW]: (_state, action) => ({
    member: {
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
})

export default createReducer<App.LoyaltyState>(initialLoyaltyState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_LOYALTY_MEMBER_SUBSCRIPTION]: (state, _action) => ({
    member: {
      ...initialLoyaltyState.member,
      subscription: {
        ...initialLoyaltyState.member.subscription,
      },
    },
    products: {
      ...state.products,
      freePreview: {
        ...initialLoyaltyState.products.freePreview,
      },
    },
  }),
})
