import { definitions } from '@luxuryescapes/contract-trip'
import moment, { Moment } from 'moment'

import { itemSectionId } from './itemSection'

import {
  DAY_MONTH_NAME_SHORT,
  DAY_ONLY,
  DMY_CASUAL_SHORT_FORMAT,
  DMY_DATE_FORMAT,
  ISO_DATE_FORMAT,
  SHORT_DAY_NAME_DAY_MONTH,
  SHORT_DAY_NAME_DAY_MONTH_YEAR,
} from 'constants/dateFormats'
import { OFFER_TYPE_BED_BANK, OFFER_TYPE_TOUR } from 'constants/offer'
import {
  queryKeyDate,
  queryKeyLanguageId,
  queryKeyPickupPointId,
  queryKeyRedemptionLocationId,
  queryKeyTime,
  urlHashDatePicker,
  urlHashPackageOptions,
} from 'constants/url'
import { nonNullable, sortBy, sum } from 'lib/array/arrayUtils'
import { calculateTimezoneOffset, convertTZ } from 'lib/datetime/dateUtils'
import { onlyDefinedProperties } from 'lib/object/objectUtils'
import offerPageURL, { getExperienceOfferPageURL } from 'lib/offer/offerPageURL'
import { isBundleAndSaveOrder } from 'lib/order/orderUtils'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { BookmarkRequest } from 'tripPlanner/api/bookmark/types'
import { CreateTripItemRequest } from 'tripPlanner/api/tripItem/types'
import { TRIP_IMAGE_IDS } from 'tripPlanner/config'
import {
  BasicTrip,
  FullTrip,
  Place,
  TravellerRoom,
  TripLocation,
  UserPermissions,
} from 'tripPlanner/types/common'
import {
  AccommodationItem,
  AttractionItem,
  CruiseItem,
  CustomItem,
  EventItem,
  ExperienceItem,
  FlightItem,
  LEFlightItem,
  LETripItem,
  MapItem,
  NoteItem,
  RestaurantBarItem,
  SavedHotelData,
  TourItem,
  TransportItem,
  TripItem,
} from 'tripPlanner/types/tripItem'
import { itemToCuratedBookmarkPayload } from 'tripPlanner/utils/curated/itemToCuratedBookmarkPayload'
import { itemToCuratedItemPayload } from 'tripPlanner/utils/curated/itemToCuratedItemPayload'
import { itemToPublicTripsBookmarkPayload } from 'tripPlanner/utils/publicTrips/itemToPublicTripBookmarkPayload'
import { itemToPublicTripItemPayload } from 'tripPlanner/utils/publicTrips/itemToPublicTripItemPayload'
import { isCruiseOffer, isTourV2Offer } from 'lib/offer/offerTypes'
import config from 'constants/config'

const MINUTES_IN_HOUR = 60

export const concatStringsWithComma = (
  ...stringValues: Array<string | undefined>
) => {
  const nonEmptyValues = stringValues.filter(
    (stringValue) => stringValue && stringValue?.trim().length > 0,
  )

  return nonEmptyValues.join(', ') || undefined
}

type CustomAirport = { code: string; name: string }

function airportHasLatLng(
  airport: App.Airport | CustomAirport,
): airport is App.Airport {
  return (
    'latitude' in airport &&
    'longitude' in airport &&
    typeof airport.latitude === 'number' &&
    typeof airport.longitude === 'number'
  )
}

export const convertPlaceFromAirport = (
  airport: App.Airport | CustomAirport,
): Place => {
  if (airportHasLatLng(airport)) {
    return {
      type: 'GEO',
      name: airport.name,
      // we don't have an address from existing details
      address: undefined,
      point: {
        lat: airport.latitude,
        lng: airport.longitude,
      },
      photoUrl: undefined,
    }
  }

  return {
    type: 'CUSTOM',
    name: airport.name,
    // we don't have an address with existing details
    address: undefined, // ToDo: Is there a way of fetching this?
    photoUrl: undefined,
  }
}

export function assertUnreachable(_x: never): never {
  // eslint-disable-next-line no-console
  console.trace()
  throw new Error("Something wasn't mapped correctly in Trip Planner.")
}

export const isTourOfferNoSummary = (offer: App.AnyOffer): offer is App.Offer =>
  offer.type === OFFER_TYPE_TOUR

export const isBedbankOfferNoSummary = (
  offer: App.AnyOffer,
): offer is App.BedbankOffer => offer.type === OFFER_TYPE_BED_BANK

export const isLEOfferNoSummary = (offer: App.AnyOffer): offer is App.Offer =>
  offer.type !== OFFER_TYPE_BED_BANK

export const isTour = (tripItem: TripItem): tripItem is TourItem =>
  tripItem.type === 'TOUR'

export const isCruise = (tripItem: TripItem): tripItem is CruiseItem =>
  tripItem.type === 'CRUISE'

export const isCruiseV2 = (tripItem: TripItem) =>
  isCruise(tripItem) &&
  !!tripItem.savedItemData &&
  tripItem.savedItemData.offerType === 'cruise'

const isFlight = (tripItem: TripItem): tripItem is FlightItem =>
  tripItem.type === 'FLIGHT'

const isTransport = (tripItem: TripItem): tripItem is TransportItem =>
  tripItem.type === 'BUS' ||
  tripItem.type === 'FERRY' ||
  tripItem.type === 'OTHER_TRANSPORT' ||
  tripItem.type === 'TRAIN'

export type NoEndItem =
  | ExperienceItem
  | EventItem
  | AttractionItem
  | RestaurantBarItem

export const isNoEndItem = (tripItem: TripItem): tripItem is NoEndItem =>
  tripItem.type === 'EXPERIENCE' ||
  tripItem.type === 'EVENT' ||
  tripItem.type === 'ATTRACTION' ||
  tripItem.type === 'RESTAURANT_BAR'

export const isCustomItem = (tripItem: TripItem): tripItem is CustomItem =>
  tripItem.sourceType === 'NA' || tripItem.sourceType === 'Explore'

export const isLeBooking = (tripItem: TripItem) =>
  tripItem.type !== 'NOTE' && !!tripItem.isBooked && !isCustomItem(tripItem)

export const isLeBookmark = (tripItem: TripItem): tripItem is LETripItem =>
  tripItem.type !== 'NOTE' &&
  !tripItem.isBooked &&
  !isCustomItem(tripItem) &&
  !!tripItem.savedItemData

export const isLeItem = (item: TripItem) =>
  isLeBooking(item) || isLeBookmark(item)

export const isLeFlightItem = (
  tripItem: FlightItem,
): tripItem is LEFlightItem =>
  !isCustomItem(tripItem) && !!tripItem.savedItemData

export const getOrderId = (tripItem: TripItem) => {
  if (!isLeBooking(tripItem)) return undefined

  if (!('confirmationCode' in tripItem)) return undefined

  if (!tripItem.confirmationCode) return undefined

  return tripItem.confirmationCode
}

const GOOGLE_MAPS_BASE_URI = 'https://www.google.com/maps/search/?'

export const convertBedbankPropertyAddressToString = (
  address: App.BedbankPropertyAddress,
) => {
  return `${address.line1}  ${address.line2}, ${address.stateProvinceName}, ${address.countryName}`
}

export const googleMapsQueryUrl = (address: string, placeId?: string) => {
  const query = new URLSearchParams(
    onlyDefinedProperties({
      api: '1',
      query: address,
      query_place_id: placeId,
    }),
  )

  // Documentation for URL format: https://developers.google.com/maps/documentation/urls/get-started
  return `${GOOGLE_MAPS_BASE_URI}${query.toString()}`
}

export const maybePlaceId = (place: Place): string | undefined => {
  if (place.type === 'GEO') return place.id
  return undefined
}

export const getDurationBetweenDates = (
  startDateTime: Moment,
  endDateTime: Moment,
): moment.Duration => {
  return moment.duration(calculateDays(startDateTime, endDateTime))
}

export const calculateTripDaysCount = (
  startDate?: Moment,
  endDate?: Moment,
): string | undefined => {
  if (startDate && endDate) {
    let diff = calculateDays(startDate, endDate)
    if (diff !== undefined) {
      diff += 1
      return pluralizeToString('day', diff)
    }
  }

  return undefined
}

export const makeDate = (dateString: string): Moment => {
  return moment(dateString)
}

export const makeDateTime = (
  dateString: string,
  timeString: string,
): Moment => {
  return moment(dateString + ' ' + timeString)
}

export function calculateNights(
  startDate?: moment.Moment,
  endDate?: moment.Moment,
): number | undefined {
  if (!startDate || !endDate) return
  return endDate
    .clone()
    .startOf('day')
    .diff(startDate.clone().startOf('day'), 'days')
}

export function calculateDays(
  startDate?: moment.Moment,
  endDate?: moment.Moment,
): number | undefined {
  if (!startDate || !endDate) return
  const start = startDate.clone()
  const end = endDate.clone()
  return end.startOf('day').diff(start.startOf('day'), 'days')
}

export const calculateTravelTimeLabel = (
  startUtcDateTime: moment.Moment | undefined,
  endUtcDateTime: moment.Moment | undefined,
): string | undefined => {
  if (!startUtcDateTime || !endUtcDateTime) {
    return undefined
  }

  const hours = calculateDiff(
    startUtcDateTime,
    endUtcDateTime,
    'hours',
  )
  const minutes = calculateDiff(
    startUtcDateTime,
    endUtcDateTime,
    'minutes',
  )

  const leftoverMinutes = minutes % MINUTES_IN_HOUR

  if (leftoverMinutes) {
    const hoursAndMinutesStrings: Array<string> = []
    if (hours) {
      hoursAndMinutesStrings.push(`${hours}h`)
    }

    hoursAndMinutesStrings.push(`${leftoverMinutes}m`)

    return hoursAndMinutesStrings.join(' ')
  } else {
    return `${hours} hours`
  }
}

export function calculateDiff(
  startDateTime: moment.Moment,
  endDateTime: moment.Moment,
  unitOfTime: 'hours' | 'minutes',
): number
export function calculateDiff(
  startDateTime: moment.Moment | undefined,
  endDateTime: moment.Moment | undefined,
  unitOfTime: 'hours' | 'minutes',
): number | undefined {
  if (startDateTime && endDateTime) {
    return endDateTime.diff(startDateTime, unitOfTime)
  }
}

export const calculateHours = (
  startDate: moment.Moment,
  startTime: moment.Moment | undefined,
  endDate: moment.Moment,
  endTime: moment.Moment | undefined,
): number => {
  const startDateTime = startDate.set({
    hour: startTime?.get('hour'),
    minute: startTime?.get('minute'),
  })

  const endDateTime = endDate.set({
    hour: endTime?.get('hour'),
    minute: endTime?.get('minute'),
  })
  return endDateTime.diff(startDateTime, 'hours')
}

export const calculateMinutes = (
  startDate: moment.Moment,
  startTime: moment.Moment | undefined,
  endDate: moment.Moment,
  endTime: moment.Moment | undefined,
): number => {
  const startDateTime = startDate.set({
    hour: startTime?.get('hour'),
    minute: startTime?.get('minute'),
  })

  const endDateTime = endDate.set({
    hour: endTime?.get('hour'),
    minute: endTime?.get('minute'),
  })
  return endDateTime.diff(startDateTime, 'minutes')
}

export const getDurationNights = (
  startDate?: moment.Moment,
  endDate?: moment.Moment,
): string | undefined => {
  const nights = calculateNights(startDate, endDate)
  if (nights) {
    return pluralizeToString('night', nights)
  }
}

export const getDurationDays = (
  startDate?: moment.Moment,
  endDate?: moment.Moment,
): string | undefined => {
  const days = calculateDays(startDate, endDate)
  if (typeof days === 'number') {
    return pluralizeToString('day', days + 1)
  }
}

export const calculateEndDate = (
  startDate: moment.Moment,
  nights: number,
): moment.Moment => startDate.clone().add(nights, 'days')

export const getTripItemStartDate = (item: TripItem): Moment | undefined => {
  return item.startDate
}

export const getTripItemUTCStartTime = (item: TripItem): Moment | undefined => {
  return item.startUtcDateTime
}

export const getTripItemEndDate = (item: TripItem): Moment | undefined => {
  return 'endDate' in item ? item.endDate : undefined
}

export const getTripItemUTCEndTime = (item: TripItem): Moment | undefined => {
  return 'endUtcDateTime' in item ? item.endUtcDateTime : undefined
}

type RecommendationData = {
  type: 'FLIGHT' | 'HOTEL' | 'EXPERIENCE' | 'ACCOMMODATION'
  location: TripLocation
}

export type ItemSlot = {
  type: 'ITEM'
  item: TripItem
}

export type RecommendationSlot = {
  type: 'RECOMMENDATION'
  recommendation: RecommendationData
}

/** Sort by start time. Put items with no time at the end. */
export const simpleSortTripItems = (items: Array<TripItem>) =>
  sortBy(
    items,
    (item) =>
      getTripItemUTCStartTime(item)?.valueOf() || Number.MAX_SAFE_INTEGER,
    'asc',
  )

export function formatDmytoIsoDate(
  dateString: string | undefined,
): string | undefined {
  if (!dateString) return undefined
  const dateFormat = !dateString.includes('-') ?
    DMY_DATE_FORMAT :
    ISO_DATE_FORMAT

  return moment(dateString, dateFormat).format(ISO_DATE_FORMAT)
}

export function formatDateRange(
  startDate: moment.Moment,
  endDate: moment.Moment,
): string {
  if (!startDate || !endDate) {
    throw new Error(
      'Could not format date - start or end date was null for destination',
    )
  }
  let startDateFormat = SHORT_DAY_NAME_DAY_MONTH_YEAR
  if (endDate.isSame(startDate, 'day')) {
    return startDate.format(startDateFormat)
  } else if (endDate.isSame(startDate, 'year')) {
    startDateFormat = SHORT_DAY_NAME_DAY_MONTH
  }
  return [
    startDate.format(startDateFormat),
    endDate.format(SHORT_DAY_NAME_DAY_MONTH_YEAR),
  ].join(' - ')
}

export function formatCasualDateRange(
  startDate: moment.Moment,
  endDate?: moment.Moment,
): string {
  if (!endDate || endDate.isSame(startDate, 'day')) {
    return startDate.format(DMY_CASUAL_SHORT_FORMAT)
  }

  let startDateFormat = DMY_CASUAL_SHORT_FORMAT
  if (startDate.isSame(endDate, 'month')) {
    startDateFormat = DAY_ONLY
  } else if (startDate.isSame(endDate, 'year')) {
    startDateFormat = DAY_MONTH_NAME_SHORT
  }

  return [
    startDate.format(startDateFormat),
    endDate.format(DMY_CASUAL_SHORT_FORMAT),
  ].join(' - ')
}

export function formatCasualDateTime(
  dateTime: moment.Moment,
  timeFormat: string,
): string {
  const isThisYear = dateTime.isSame(moment(), 'year')

  return dateTime.format(
    isThisYear ?
      `${DAY_MONTH_NAME_SHORT}, ${timeFormat}` :
      `${DMY_CASUAL_SHORT_FORMAT}, ${timeFormat}`,
  )
}

export const upperCaseFirstCharOnly = (s: string): string => {
  if (s.length > 0) {
    return `${s[0].toUpperCase()}${s.slice(1)}`
  }

  return s
}

// ToDo: This logic is very crude I need to revisit it once we know it's the right thing to do.
export const convertGoogleAddressComponentsToRegion = (
  addressComponents: Array<google.maps.GeocoderAddressComponent>,
): string | undefined => {
  const addressComponentMap = new Map<
    string,
    Array<google.maps.GeocoderAddressComponent>
  >()

  // Google returns an array of types for every addressComponent, let's group them together and can use it later.
  addressComponents?.forEach((addressComponent) => {
    addressComponent.types.forEach((type) => {
      if (addressComponentMap.has(type)) {
        const currentValues = addressComponentMap.get(type) ?? []
        currentValues.push(addressComponent)
        addressComponentMap.set(type, currentValues)
      } else {
        addressComponentMap.set(type, [addressComponent])
      }
    })
  })

  if (addressComponentMap.size === 0) {
    return
  }

  // Are there any other kind of fields we can look at.
  const possibleCityFields = [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'colloquial_area',
    'sublocality',
  ]

  // The very first thing we want to try is
  const regionToken: Array<string> = []

  for (let i = 0; i < possibleCityFields.length; i++) {
    const cityField = possibleCityFields[i]
    if (addressComponentMap.has(cityField)) {
      const addressComponentForCityField = addressComponentMap.get(cityField)

      // ToDo: Is it okay to always first the very first match, not sure how Google orders it.
      if (addressComponentForCityField?.[0]?.long_name) {
        regionToken.push(addressComponentForCityField[0].long_name)
        break
      }
    }
  }

  const countryAddressComponent = addressComponentMap.get('country')
  if (countryAddressComponent) {
    regionToken.push(countryAddressComponent[0].long_name)
  }

  return regionToken.join(', ')
}

export const checkIfTimeIsMidnight = (dateTime: Moment): boolean => {
  return (
    dateTime.hours() === 0 &&
    dateTime.minutes() === 0 &&
    dateTime.seconds() === 0
  )
}

export function isFlightSearchBookmark(item: TripItem): item is FlightItem & { isBooked: false, startTime: undefined, endTime: undefined } {
  return (
    item.sourceType === 'LE_Flight' &&
    !item.isBooked &&
    !item.startTime &&
    !item.endTime
  )
}

/**
 * Return flight search bookmarks are kind of a special case when displaying itinerary items,
 * because they are a single item with a start and end date but actually represent two
 * discrete events: departing flight and returning flight. So we must detect this case and
 * change how the item presentations are generated, so we don't get meaningless intermediary
 * items in the itinerary between the two flights.
 * At some point we should probably rework how these are represented, to have two separate
 * trip items which are tied together in some way.
 */
export function isReturnFlightSearchBookmark(item: TripItem) {
  return (
    isFlightSearchBookmark(item) &&
    item.savedItemData?.fareType === 'return'
  )
}

export const getItemPlace = (
  item: TripItem,
  type: 'start' | 'end',
): Place | undefined => {
  switch (item.type) {
    case 'EVENT':
    case 'EXPERIENCE':
    case 'ACCOMMODATION':
    case 'RESTAURANT_BAR':
    case 'ATTRACTION':
      return item.place
    case 'TOUR':
    case 'CRUISE':
    case 'CAR_RENTAL':
    case 'FERRY':
    case 'BUS':
    case 'OTHER_TRANSPORT':
    case 'TRAIN':
    case 'FLIGHT':
    case 'THING_TO_DO':
      return type == 'start' ? item.startPlace : item.endPlace
    case 'NOTE':
      return undefined
    default:
      return assertUnreachable(item)
  }
}

export const getItemConfirmationCode = (item: TripItem): string | undefined => {
  return 'confirmationCode' in item ? item.confirmationCode : undefined
}

export const itemHasPlace = (
  item: TripItem,
): item is Exclude<TripItem, NoteItem> =>
  'place' in item || 'startPlace' in item

export const itemHasLatLng = (item: MapItem): item is MapItem =>
  'place' in item || 'startPlace' in item

export const validateOrderTripAddition = (order?: App.Order): boolean =>
  !!order &&
  order.orderSuccess &&
  [
    order.items,
    order.flightItems,
    order.bedbankItems,
    order.tourItems,
    order.experienceItems,
    order.cruiseItems,
  ].some((items) => items.some(itemNotCancelled)) &&
  !isBundleAndSaveOrder(order)

type TripOrderItem =
  | App.OrderBedbankItem
  | Tours.TourV2OrderItem
  | App.OrderExperienceItem
  | App.OrderFlightItem
  | App.OrderItem
  | App.CruiseOrderItem

const itemNotCancelled = (item: TripOrderItem): boolean =>
  item.status !== 'cancelled'

export const typeConstToTitle = (type: TripItem['type']) => {
  return type
    .split('_')
    .map((word: string) => upperCaseFirstCharOnly(word.toLocaleLowerCase()))
    .join(' ')
}

export function formatSingleDateAndTimeRange(
  {
    startDate,
    startDay,
    startTime,
    endTime,
  }: {
    startDate: Moment | undefined
    startDay?: number
    startTime: Moment | undefined
    endTime?: Moment | undefined
  },
  timeFormat: string,
) {
  let dateTimeString = ''

  if (startDay) {
    dateTimeString = `Day ${startDay}`
  } else if (startDate) {
    dateTimeString = startDate.format(DMY_CASUAL_SHORT_FORMAT)
  }

  if (startTime) {
    if (dateTimeString) dateTimeString += ' · '
    dateTimeString += startTime.format(timeFormat)

    if (endTime) {
      dateTimeString += ` - ${endTime.format(timeFormat)}`
    }
  }

  return dateTimeString
}

export function formatShortDateAndTimeRange({
  startDate,
  startTime,
  endDate,
  endTime,
  timeFormat,
}: {
  startDate: moment.Moment | undefined
  startTime: moment.Moment | undefined
  endDate: moment.Moment | undefined
  endTime: moment.Moment | undefined
  timeFormat: string
}) {
  let dateTimeString = ''

  if (startDate) {
    dateTimeString = startDate.format(DAY_MONTH_NAME_SHORT)
  }

  if (startTime) {
    dateTimeString = [dateTimeString, startTime.format(timeFormat)]
      .filter(Boolean)
      .join(' ')
  }

  if (endDate && endDate.toISOString() !== startDate?.toISOString()) {
    dateTimeString = [dateTimeString, endDate.format(DAY_MONTH_NAME_SHORT)]
      .filter(Boolean)
      .join(' - ')

    if (endTime) {
      dateTimeString = [dateTimeString, endTime.format(timeFormat)]
        .filter(Boolean)
        .join(' ')
    }
  } else {
    if (endTime) {
      dateTimeString = [dateTimeString, endTime.format(timeFormat)]
        .filter(Boolean)
        .join(' - ')
    }
  }
  return dateTimeString
}

export function formatShortDateRange({
  startDate,
  endDate,
  startDay,
  endDay,
}: {
  startDate?: moment.Moment
  endDate?: moment.Moment
  startDay?: number
  endDay?: number
}) {
  let dateTimeString = ''

  if (startDay) {
    dateTimeString = `Day ${startDay}`
    if (endDay && endDay !== startDay) {
      dateTimeString = `${dateTimeString} - Day ${endDay}`
    }
    return dateTimeString
  }

  if (startDate) {
    if (!endDate) {
      dateTimeString = startDate.format(DMY_CASUAL_SHORT_FORMAT)
    } else if (startDate.isSame(endDate, 'date')) {
      return startDate.format(DMY_CASUAL_SHORT_FORMAT)
    } else if (startDate.isSame(endDate, 'month')) {
      dateTimeString = startDate.format(DAY_ONLY)
    } else if (startDate.isSame(endDate, 'year')) {
      dateTimeString = startDate.format(DAY_MONTH_NAME_SHORT)
    } else {
      dateTimeString = startDate.format(DMY_CASUAL_SHORT_FORMAT)
    }
  }

  if (endDate) {
    dateTimeString += ` - ${endDate.format(DMY_CASUAL_SHORT_FORMAT)}`
  }

  return dateTimeString
}

export function formatDateTimeOrDateRange(item: TripItem, timeFormat: string) {
  if (item.startDay) {
    return formatDaysAndTime({
      startDay: item.startDay,
      endDay: item.endDay,
      startTime: item.startTime,
      endTime: item.startTime,
      timeFormat,
    })
  }

  if (
    'endDate' in item &&
    item.startDate &&
    item.endDate &&
    !item.startDate?.isSame(item.endDate, 'date')
  ) {
    return formatShortDateRange(item)
  }
  return formatDatesAndDays(item, timeFormat)
}

export function formatDatesAndDays(item: TripItem, timeFormat: string) {
  if (!('endDate' in item) || !item.endDate) {
    return formatSingleDateAndTimeRange(item, timeFormat)
  }

  let formattedString = ''

  if (item.startDate) {
    formattedString = item.startDate.format(DAY_MONTH_NAME_SHORT)
  }

  if (item.startTime) {
    if (
      !item.endDate.isSame(item.startDate) ||
      (item.endTime && !item.endTime.isSame(item.startTime))
    ) {
      formattedString += ` · ${item.startTime.format(timeFormat)}`
    }
  }

  if (!item.endDate.isSame(item.startDate)) {
    formattedString += ` - ${item.endDate.format(DMY_CASUAL_SHORT_FORMAT)}`
  }

  if (item.endTime) {
    if (
      !item.endDate.isSame(item.startDate) ||
      !item.endTime.isSame(item.startTime)
    ) {
      formattedString += ` - ${item.endTime.format(timeFormat)}`
    }
  }

  return formattedString
}

// This might be a duplicate of the above now that we've removed PlaceMultiDates
export function formatMultiDatesDays({
  item,
  timeFormat,
}: {
  item: TripItem
  timeFormat: string
}) {
  if (item.startDay) {
    return formatDaysAndTime({
      startDay: item.startDay,
      endDay: item.endDay,
      startTime: item.startTime,
      endTime: item.startTime,
      timeFormat,
    })
  }
  let formattedString = ''

  if (!('endDate' in item) || !item.endDate) {
    return formatSingleDateAndTimeRange(item, timeFormat)
  }

  if (item.startDate) {
    if (item.startDate.isSame(item.endDate, 'year')) {
      formattedString = item.startDate.format(DAY_MONTH_NAME_SHORT)
    } else {
      formattedString = item.startDate.format(DMY_CASUAL_SHORT_FORMAT)
    }
  }

  if (item.startTime) {
    if (
      !item.endDate.isSame(item.startDate) ||
      (item.endTime && !item.endTime.isSame(item.startTime))
    ) {
      formattedString += ` · ${item.startTime.format(timeFormat)}`
    }
  }

  if (!item.endDate.isSame(item.startDate)) {
    formattedString += ` - ${item.endDate.format(DMY_CASUAL_SHORT_FORMAT)}`
  }

  if (item.endTime) {
    if (!item.endDate.isSame(item.startDate)) {
      formattedString += ` · ${item.endTime.format(timeFormat)}`
    } else if (!item.endTime.isSame(item.startTime)) {
      formattedString += ` - ${item.endTime.format(timeFormat)}`
    }
  }

  return formattedString
}

export function formatDurationDaysIfMultipleDays({
  startDate,
  endDate,
}: {
  startDate?: moment.Moment
  endDate?: moment.Moment
}) {
  if (startDate && endDate && !endDate.isSame(startDate, 'day')) {
    return getDurationDays(startDate, endDate)
  }
  return undefined
}

export function formatStartAndEndLocation({
  startPlace,
  endPlace,
}: {
  startPlace: Place
  endPlace?: Place
}) {
  if (!endPlace || startPlace.name === endPlace.name) {
    return startPlace.name
  } else {
    return `Starts in ${startPlace.name} and ends in ${endPlace.name}`
  }
}

export function getDayOfText(item: TripItem, date: moment.Moment) {
  if (isTransport(item) || isFlight(item)) return 'In transit'
  const startDate = getTripItemStartDate(item)
  const endDate = getTripItemEndDate(item)
  if (!startDate || !endDate) return
  const days = calculateDays(startDate, endDate)! + 1
  const currentDay = calculateDays(startDate, date)! + 1
  return `Day ${currentDay} of ${days}`
}

export function getNightOfText(
  item: TripItem,
  date: moment.Moment,
): string | undefined {
  if (isTransport(item) || isFlight(item)) return 'In transit'
  const startDate = getTripItemStartDate(item)
  const endDate = getTripItemEndDate(item)
  if (!startDate || !endDate) return
  const nights = calculateNights(startDate, endDate)!
  const currentNight = calculateNights(startDate, date)! + 1
  return `Night ${currentNight} of ${nights}`
}

export function convertOfferPropertyGeoDataToRegion(
  geoData: App.OfferPropertyGeoData,
): string {
  let region = ''
  if (geoData.country) {
    if (geoData.locality) {
      region = geoData.locality
    } else if (geoData.administrativeAreaLevel1) {
      region = `${geoData.administrativeAreaLevel1},`
    } else if (geoData.administrativeAreaLevel2) {
      region = `${geoData.administrativeAreaLevel2},`
    } else if (geoData.administrativeAreaLevel3) {
      region = `${geoData.administrativeAreaLevel3},`
    } else if (geoData.administrativeAreaLevel4) {
      region = `${geoData.administrativeAreaLevel4},`
    }

    region += ` ${geoData.country}`
  }

  return region
}

export function convertBedbankPropertyAddressToRegion(
  bedbankPropertyAddress: App.BedbankPropertyAddress,
): string {
  let region = ''
  if (bedbankPropertyAddress.countryName) {
    if (bedbankPropertyAddress.stateProvinceName) {
      region = `${bedbankPropertyAddress.stateProvinceName},`
    } else if (bedbankPropertyAddress.city) {
      region = `${bedbankPropertyAddress.city},`
    }

    region += ` ${bedbankPropertyAddress.countryName}`
  }

  return region
}

export const concatStringWithDot = (...strings: Array<string>): string => {
  const stringsCanBeConcatenated = strings.filter(
    (str) => str.trim().length > 0,
  )

  return stringsCanBeConcatenated.join(' · ')
}

export function getDefaultTripImageId(trips?: Array<BasicTrip>) {
  let imageOptions = TRIP_IMAGE_IDS

  if (trips) {
    // Try to find images that haven't already been used
    const usedImages = new Set(trips.map((trip) => trip.imageId))
    const unusedImages = TRIP_IMAGE_IDS.filter(
      (imageId) => !usedImages.has(imageId),
    )
    if (unusedImages.length > 0) {
      imageOptions = unusedImages
    }
  }

  const defaultImageIndex = Math.floor(Math.random() * imageOptions.length)

  return imageOptions[defaultImageIndex]
}

export function getOfferId(
  item: AccommodationItem | TourItem | CruiseItem,
): string | undefined {
  return 'code' in item ? item.code : undefined
}

interface OfferPagePrefilledStateOptions {
  scrollToOptions?: boolean
}

export const getOfferPagePrefilledStateParams = (
  item: Extract<
    LETripItem,
    TourItem | CruiseItem | AccommodationItem
  >,
  options: OfferPagePrefilledStateOptions = {},
): { searchParams: URLSearchParams; hash: string } => {
  const { scrollToOptions = false } = options
  let startDate: string | undefined
  let endDate: string | undefined
  let occupancies: SavedHotelData['occupancies'] | undefined
  let hash = ''

  if (item.type === 'ACCOMMODATION') {
    startDate = item.savedItemData.checkIn
    endDate = item.savedItemData.checkOut
    occupancies = item.savedItemData.occupancies
    if (scrollToOptions) {
      // Scroll to package options, or open calendar if no dates
      hash = urlHashPackageOptions
      if (!startDate || !endDate) {
        hash = urlHashDatePicker
      }
    }
  } else if (item.type === 'TOUR') {
    startDate = item.savedItemData.startDate
    endDate = item.savedItemData.endDate
    occupancies = item.savedItemData.occupancies
  } else if (item.savedItemData.offerType === 'tour') {
    startDate = item.savedItemData.startDate
    endDate = item.savedItemData.endDate
    occupancies = item.savedItemData.occupancies
  } else {
    throw new Error("Shouldn't be called without associated Legacy Tours")
  }

  const searchParams = new URLSearchParams(
    buildSearchParamsFromFilters({
      checkIn: startDate,
      checkOut: endDate,
      rooms: occupancies,
    }),
  )

  return { searchParams, hash }
}

export const offerPageUrlWithPrefilledState = (
  offer: App.AnyOffer,
  item: Extract<
    LETripItem,
    TourItem | CruiseItem | AccommodationItem
  >,
  options: OfferPagePrefilledStateOptions = {},
): string => {
  const { searchParams, hash } = getOfferPagePrefilledStateParams(item, options)
  return `${offerPageURL(offer, searchParams)}${hash}`
}

export const experiencePageUrlWithPrefilledState = (
  experience: App.ExperienceOffer,
  savedItemData?: definitions['savedExperienceData'],
  scrollToOptions: boolean = true,
): string => {
  const searchParams = new URLSearchParams(
    onlyDefinedProperties({
      [queryKeyDate]: savedItemData?.date,
      [queryKeyTime]: savedItemData?.time,
      [queryKeyPickupPointId]: savedItemData?.pickupPointId,
      [queryKeyRedemptionLocationId]: savedItemData?.redemptionLocationId,
      [queryKeyLanguageId]: savedItemData?.languageId,
    }),
  )

  const hash = scrollToOptions ? '#ticket-options' : ''

  return getExperienceOfferPageURL(experience, searchParams) + hash
}

export function getTimezoneAdjustedTime(
  date: moment.Moment,
  tzString: string,
  amount: moment.DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor,
) {
  const timezoneOffset = calculateTimezoneOffset(
    date.toDate(),
    convertTZ(date.toDate(), tzString),
  )
  return moment
    .utc(date.format(ISO_DATE_FORMAT), ISO_DATE_FORMAT)
    .subtract(timezoneOffset, 'hours')
    .add(amount, unit)
}

const defaultTripTab = config.TRIP_PLANNER_ENABLE_PLAN_VIEW ? 'itinerary' : 'summary'

export function getTripURL(tripId: string, sectionId?: string): string {
  return `/trip-planner/trip/${tripId}/${defaultTripTab}` + (sectionId ? `#${itemSectionId(sectionId)}` : '')
}

export function getCuratedTripURL(tripId: string): string {
  return `/trip-planner/curated/${tripId}/summary`
}

export function getTripPermissions(
  trip: BasicTrip | FullTrip,
): UserPermissions {
  return {
    canEdit: trip.role !== 'VIEWER',
    canAdmin: trip.role === 'OWNER' || trip.role === 'ADMIN',
    canPublish: trip.role === 'ADMIN',
  }
}

export function getCruiseV2OfferUrl(item: CruiseItem) {
  if (!isCruiseV2(item)) {
    throw new Error('Expecting a Cruise V2 item, but got a Cruise V1 item')
  }
  return `/cruises/${item.code}`
}

export function formatDaysAndTime({
  startDay,
  endDay,
  startTime,
  endTime,
  timeFormat,
}: {
  startDay: number | undefined
  endDay: number | undefined
  startTime: moment.Moment | undefined
  endTime: moment.Moment | undefined
  timeFormat: string
}) {
  if (endDay && endDay !== startDay) {
    if (startTime && endTime) {
      return `Day ${startDay} ${startTime.format(
        timeFormat,
      )} - Day ${endDay} ${endTime.format(timeFormat)}`
    }
    return `Day ${startDay} - Day ${endDay}`
  } else {
    if (startTime && endTime && !endTime.isSame(startTime, 'minute')) {
      return `Day ${startDay} · ${startTime.format(
        timeFormat,
      )} - ${endTime.format(timeFormat)}`
    } else if (startTime) {
      return `Day ${startDay} · ${startTime.format(timeFormat)}`
    }
  }
  return `Day ${startDay}`
}

export function itemHasDate(item: TripItem) {
  return !!item.startDate || ('endDate' in item && !!item.endDate)
}

export function itemHasTime(item: TripItem) {
  return !!item.startTime || ('endDate' in item && !!item.endTime)
}

export function getItemLatLng(item: MapItem): { lat?: number; lng?: number } {
  switch (item.type) {
    case 'CRUISE':
    case 'BUS':
    case 'CAR_RENTAL':
    case 'FERRY':
    case 'FLIGHT':
    case 'OTHER_TRANSPORT':
    case 'TOUR':
    case 'TRAIN':
    case 'THING_TO_DO':
      if (item.startPlace.type !== 'GEO') {
        return {
          lat: undefined,
          lng: undefined,
        }
      }

      return {
        lat: item.startPlace.point.lat,
        lng: item.startPlace.point.lng,
      }
    case 'ATTRACTION':
    case 'EVENT':
    case 'ACCOMMODATION':
    case 'RESTAURANT_BAR':
    case 'EXPERIENCE':
      if (item.place.type !== 'GEO') {
        return {
          lat: undefined,
          lng: undefined,
        }
      }

      return {
        lat: item.place.point.lat,
        lng: item.place.point.lng,
      }
  }
}

export function itemIsBooked(item: TripItem): boolean {
  return 'isBooked' in item && item.isBooked
}

/** @description [D MMM, 17:00 / 5:00pm, YYYY] */
export const getDateTimeComponents = ({
  date,
  time,
  timeFormat,
}: {
  date: Moment | undefined
  time: Moment | undefined
  timeFormat: string
}) => {
  const formattedDate = date?.format(DAY_MONTH_NAME_SHORT) // D MMM
  const formattedYear = date?.year().toString() // YYYY
  const formattedTime = time?.format(timeFormat) // 17:00 or 5:00pm

  return [formattedDate, formattedTime, formattedYear] as const
}

export const formatDatesAndTimes = <
  T extends {
    startDate?: Moment
    endDate?: Moment
    startTime?: Moment
    endTime?: Moment
    timeFormat: string
  },
>({
    startDate,
    endDate,
    startTime,
    endTime,
    timeFormat,
  }: T): string | undefined => {
  const [formattedStartDate, formattedStartTime, formattedStartYear] =
    getDateTimeComponents({
      date: startDate,
      time: startTime,
      timeFormat,
    })

  const [formattedEndDate, formattedEndTime, formattedEndYear] =
    getDateTimeComponents({
      date: endDate,
      time: endTime,
      timeFormat,
    })

  let formattedStartString: string = nonNullable([
    formattedStartDate,
    formattedStartTime,
  ]).join(' · ')

  let formattedEndString: string = nonNullable([
    formattedEndDate,
    formattedEndTime,
  ]).join(' · ')

  if (formattedStartString.length === 0) {
    // We don't allow end datetime to display without start datetime
    return undefined
  }

  // Once we have valid start and end strings, we can append the year/years appropriately
  const sameYear = formattedStartYear === formattedEndYear
  if (sameYear && formattedStartYear) {
    return (
      nonNullable([formattedStartString, formattedEndString]).join(' - ') +
      ` ${formattedStartYear}`
    )
  }

  if (formattedStartYear) {
    formattedStartString += ` ${formattedStartYear}`
  }

  if (formattedEndYear) {
    formattedEndString += ` ${formattedEndYear}`
  }

  return nonNullable([
    formattedStartString,
    formattedEndString.length > 0 ? formattedEndString : undefined,
  ]).join(' - ')
}

export function getOfferImage(
  offer:
    | App.BedbankOffer
    | Tours.TourV2Offer
    | Tours.TourV2OfferSummary
    | App.Offer
    | App.OfferSummary
    | App.CruiseOffer
    | undefined,
  otherParameters: {
    tourVariation?: Tours.TourV2OfferVariation
  } = {},
): App.Image {
  if (!offer) {
    return {}
  }
  if (isTourV2Offer(offer)) {
    const variationForDisplay = otherParameters.tourVariation ?? Object.values(offer.variations)[0]
    return variationForDisplay?.images[0] ?? {}
  } else if (isCruiseOffer(offer)) {
    return offer.images[0] ?? {}
  } else {
    return offer.image ?? {}
  }
}

export function countTravellers(travellerRooms: Array<TravellerRoom>) {
  const adults = sum(travellerRooms, (travellers) => travellers.adults)
  const children = sum(
    travellerRooms,
    (travellers) => travellers.childAges.length,
  )
  return { adults, children }
}

export function formatTripTravellers(travellerRooms: Array<TravellerRoom>) {
  const { adults, children } = countTravellers(travellerRooms)
  if (children === 0) {
    return pluralizeToString('adult', adults)
  }
  return pluralizeToString('traveller', adults + children)
}

export function hasTravellers(
  tripTravellers: Array<TravellerRoom> | undefined,
): tripTravellers is [TravellerRoom, ...Array<TravellerRoom>] {
  return (
    !!tripTravellers &&
    tripTravellers.length > 0 &&
    tripTravellers[0].adults > 0
  )
}

export const bookmarkPayloadMappers = (
  item,
  isPublicTrip: boolean | undefined,
): BookmarkRequest['items'][number] => {
  return isPublicTrip ?
    itemToPublicTripsBookmarkPayload(item) :
    itemToCuratedBookmarkPayload(item)
}

export const customItemPayloadMapper = (
  item,
  isPublicTrip: boolean | undefined,
): CreateTripItemRequest => {
  return isPublicTrip ?
    itemToPublicTripItemPayload(item) :
    itemToCuratedItemPayload(item)
}

export function isFullTrip(trip: BasicTrip | FullTrip): trip is FullTrip {
  return 'items' in trip
}

export function buildMyEscapesOrderLink(item: TripItem): string | undefined {
  const orderId: string | undefined = getOrderId(item)
  if (orderId) {
    return `/account/my-escapes/order/${orderId}`
  }
  return undefined
}
