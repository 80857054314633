import React, { useMemo } from 'react'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import { isUltraLuxTour } from 'lib/offer/offerUtils'
import ProductPaletteProvider, { ProductPalette } from 'contexts/ProductPaletteContext'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import PriceRowSale from 'components/Luxkit/PricePoints/PriceRowSale'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { isTourV2Offer } from 'lib/offer/offerTypes'

interface Props {
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary;
  className?: string;
}

function HeroTourOfferTilePricing(props: Props) {
  const { offer } = props
  const {
    cheapestPurchasableOption,
    cheapestVariation,
  } = useCheapestOfTourV2Offer(offer)

  const {
    durationInDaysCount,
  } = useTourV2VariationDetails(cheapestVariation)

  const {
    pricePerPerson,
    roomTypeName,
    valuedAt,
    hasDiscount,
    fullPricePerPerson,
    memberPrice,
  } = useTourV2PurchasableOptionDetails(cheapestPurchasableOption) || {}
  const isUltraLux = isUltraLuxTour(offer)
  let paletteValue: ProductPalette
  if (isUltraLux) {
    paletteValue = 'ultralux'
  } else {
    paletteValue = 'default'
  }
  const isTourV2 = isTourV2Offer(offer)

  const offerLocations: Array<string> = useMemo(() => {
    const variations = Object.values(offer.variations)
    if (variations.length === 0) return ['']

    const variation = variations[0]

    return [variation.startLocation || '']
  }, [offer.variations])

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <VerticalSpacer gap={8}>
        <Group direction="vertical">
          <PriceRowPriceCaption>{durationInDaysCount} from</PriceRowPriceCaption>
          <LuxPlusPriceStack
            size="L"
            price={Number(pricePerPerson)}
            saleUnit="person"
            memberPrice={Number(memberPrice)}
            isTour={isTourV2}
          />
          {hasDiscount && <PriceRowSale value={fullPricePerPerson!} />}
          <PriceRowTaxesAndFees />
          {valuedAt && <PriceRowValueDiscountWithCaption
            data-testid="value-box"
            size="M"
            originalValue={Math.ceil(valuedAt.price)}
            discountPercentage={valuedAt.savingPercentage}
          />}
          <PriceRowCaption>{roomTypeName}</PriceRowCaption>
        </Group>
        <PriceRowAgentHubCommission
          offerId={offer.id}
          size="L"
          productType={offer.productType}
          offerLocations={offerLocations}
        />
      </VerticalSpacer>
      <ProductPaletteProvider palette={paletteValue}>
        <CSSBreakpoint min="desktop">
          <TextButton kind="primary">
            View offer
          </TextButton>
        </CSSBreakpoint>
      </ProductPaletteProvider>
    </Group>
  )
}

export default HeroTourOfferTilePricing
