import request from 'api/requestUtils'
import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { paths, definitions } from '@luxuryescapes/contract-cart'
import { reportClientError } from 'services/errorReportingService'

export type CartItem = definitions['cartItem']
export type CartRequestV2 = paths['/api/carts/{version}/{transactionKey}']['put']['parameters']['body']['payload']
export type Brand = CartRequestV2['brand']
export type CartItemResponse = {
  items: Array<App.Checkout.AnyItem>
  total: number | undefined
}
export type CartTemplateResponse = {
  id: string,
  template: {
    items: Array<CartItem>
  },
  createdAt: string
  updatedAt: string
}

type CartSuccessResponseV2 = paths['/api/carts/{version}/{transactionKey}']['put']['responses']['200']

/**
 * @deprecated use putNewCheckoutCart instead, looking to remove this from svc-cart
 */
export function putCart(transactionKey, cart) {
  return request.put(`/api/carts/${transactionKey}`, { ...cart, domainUserId: getDomainUserId() }, { credentials: 'include' })
}

export function finalizeCart(transactionKey) {
  return request.patch(`/api/carts/${transactionKey}`, { op: 'finalize' }, { credentials: 'include' })
}

export function putNewCheckoutCart(transactionKey, cart:CartRequestV2) {
  return request.put<CartSuccessResponseV2, CartRequestV2>(`/api/carts/v2/${transactionKey}`, { ...cart, domainUserId: getDomainUserId() }, { credentials: 'include' })
    .catch((err) => reportClientError('PUT Cart Request Failed', {
      cartPayloadItems: cart.items,
      transactionKey,
      error: err.message,
    }))
}

export function getCartItems(cartId): Promise<CartItemResponse> {
  return request.get<CartItemResponse>(`/api/carts/v2/${cartId}/items`, { credentials: 'include' }).then((res) => ({ items: res.items, total: res.total }))
}

export function createCartTemplate(items): Promise<CartTemplateResponse> {
  return request.post('/api/carts/templates?without_brand=1', { items }, { credentials: 'include' })
}

export function createQuote(cartId: string, callbackDate?: string) {
  return request.post('/api/carts/quotes?without_brand=1', { cartId, callbackDate }, { credentials: 'include' })
}

export function saleAttribution(cartId: string, orderId: string) {
  return request.post('/api/carts/attribution?without_brand=1', { cartId, orderId }, { credentials: 'include' })
}

export function updateQuote(quoteId: string, customerEmailDedupeKey: string) {
  return request.patch(`/api/carts/quotes/${quoteId}?without_brand=1`, { customerEmailDedupeKey }, { credentials: 'include' })
}
