import config from 'constants/config'
import { OFFER_TYPE_TOUR } from 'constants/offer'
import { isLEOffer, isCruiseV1Offer, isTourV2Offer } from 'lib/offer/offerTypes'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import { Place } from 'tripPlanner/api/types'
import { SavedTourData } from 'tripPlanner/types/tripItem'
import { convertOfferPropertyGeoDataToRegion } from 'tripPlanner/utils'
import {
  CreateCruiseRequestBody,
  CreateTourRequestBody,
} from 'tripPlanner/utils/itemConversions/common'

function createPayloadForAppOffer(
  offer: App.AnyOffer,
  currentRegionCode: string,
  currencyCode: string,
  templateId?: string,
  templateItemId?: string,
  cartId?: string,
): CreateCruiseRequestBody | CreateTourRequestBody {
  if (isLEOffer(offer)) {
    // ToDo: Are all the location same for the all the packages? Assuming they would be, picking the very first package.
    const offerPackage = ('packages' in offer && offer?.packages[0]) ?? null

    let startPlace: Place = {
      type: 'CUSTOM',
      name: offer.location,
      address: offer.locationHeading,
      photoUrl: offer?.image?.id ?
        `${config.IMAGE_HOST}/q_auto:eco,c_fill,g_auto,w_520,ar_16:9/${offer.image.id}.jpeg` :
        undefined,
    }

    let timezone: string | undefined = undefined

    if (offerPackage && offerPackage?.tour) {
      let region = ''
      if (offerPackage.tour.geoData) {
        region = convertOfferPropertyGeoDataToRegion(offerPackage.tour.geoData)
      }

      startPlace = {
        ...startPlace,
        type: 'GEO',
        point: {
          lat: offerPackage.tour.latitude,
          lng: offerPackage.tour.longitude,
        },
        region,
      }
      timezone = offerPackage.tour.timezone
    }

    const savedItemData: SavedTourData = {
      offerType: OFFER_TYPE_TOUR,
      currencyCode,
      regionCode: currentRegionCode,
      price: offer.lowestPricePackage?.price,
      offerId: offer.id,
      cartId,
    }

    if (isCruiseV1Offer(offer)) {
      const payload: CreateCruiseRequestBody = {
        type: 'CRUISE',
        isBooked: false,
        name: offer.name,
        sourceType: 'LE_Cruise',
        startPlace,
        startTimezone: timezone,
        code: offer.id,
        savedItemData,
        templateId,
        templateItemId,
      }

      return payload
    }

    const payload: CreateTourRequestBody = {
      type: 'TOUR',
      isBooked: false,
      name: offer.name,
      sourceType: 'LE_Tour',
      startPlace,
      startTimezone: timezone,
      code: offer.id,
      savedItemData,
      templateId,
      templateItemId,
    }

    return payload
  } else {
    throw new Error('Invalid offer type provided.')
  }
}

function createPayloadForAppTourV2Offer(
  offer: Tours.TourV2Offer,
  currentRegionCode: string,
  currencyCode: string,
  selectedVariation?: Tours.TourV2OfferVariation,
  templateId?: string,
  templateItemId?: string,
  cartId?: string,
): CreateTourRequestBody | CreateCruiseRequestBody {
  let startLocation: string | null = null
  const cheapestPurchasableOption: Tours.TourV2OfferPurchasableOption | undefined =
    findCheapestTourV2PurchasableOption(offer)

  if (
    selectedVariation &&
    offer.variations[selectedVariation.id]?.startLocation
  ) {
    startLocation = offer.variations[selectedVariation.id].startLocation
  } else if (cheapestPurchasableOption) {
    startLocation =
      offer.variations[cheapestPurchasableOption?.fkVariationId].startLocation
  }

  if (startLocation) {
    const payload: CreateTourRequestBody = {
      isBooked: false,
      type: 'TOUR',
      name: offer.name,
      sourceType: 'LE_Tour',
      startPlace: {
        type: 'CUSTOM',
        name: startLocation || offer.name,
        photoUrl: offer?.image?.id ?
          `${config.IMAGE_HOST}/q_auto:eco,c_fill,g_auto,w_520,ar_16:9/${offer.image.id}.jpeg` :
          undefined,
      },
      code: offer.id,
      savedItemData: {
        regionCode: currentRegionCode,
        currencyCode,
        offerType: 'tour_v2',
        offerId: offer.id,
        value: cheapestPurchasableOption?.valuedAt!,
        price: cheapestPurchasableOption?.price!,
        cartId,
      },
      templateId,
      templateItemId,
    }
    return payload
  }

  throw Error('Failed to save Tour missing start location')
}

interface Params {
  offer: App.AnyOffer
  currentRegionCode: string
  currencyCode: string
  selectedVariation?: Tours.TourV2OfferVariation
  templateId?: string
  templateItemId?: string
  cartId?: string
}

export function createTourItemPayload({
  offer,
  currentRegionCode,
  currencyCode,
  selectedVariation,
  templateId,
  templateItemId,
  cartId,
}: Params): CreateTourRequestBody | CreateCruiseRequestBody {
  if (isTourV2Offer(offer)) {
    return createPayloadForAppTourV2Offer(
      offer,
      currentRegionCode,
      currencyCode,
      selectedVariation,
      templateId,
      templateItemId,
      cartId,
    )
  }

  return createPayloadForAppOffer(
    offer,
    currentRegionCode,
    currencyCode,
    templateId,
    templateItemId,
    cartId,
  )
}
