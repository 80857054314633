import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineBanIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18a8.0003 8.0003 0 0 1-8-8 7.92 7.92 0 0 1 1.69-4.9L16.9 18.31A7.9198 7.9198 0 0 1 12 20Zm6.31-3.1L7.1 5.69A7.92 7.92 0 0 1 12 4a8.0001 8.0001 0 0 1 8 8 7.9198 7.9198 0 0 1-1.69 4.9Z"/>
  </SvgIcon>
}

export default LineBanIcon
